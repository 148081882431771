import { useEffect, useState } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";

import AssetDetails from "../AssetDetails/AssetDetails";
import AggregationDetails from "../AggregationDetails/AggregationDetails";
import CollectionDetails from "../CollectionDetails/CollectionDetails";
import Charts from "../Charts/Charts";
import Description from "../Description/Description";
import Header from "../Header/Header";
import OrderBook from "../OrderBook/OrderBook";
import TradingBox from "../TradingBox/TradingBox";
import RecentTransactions from "../RecentTransactions/recentTransactions";

//const client = new W3CWebSocket("ws://127.0.0.1:8000");

function App() {
  /*
  useEffect(() => {
    client.onopen = () => {
      console.log("WebSocket Client Connected");
    };
    client.onmessage = (message: any) => {};
  }, []);
*/
  return (
    <div>
      <Header />
      <div className="flex flex-col px-32">
        <Description />
        <CollectionDetails />
        <div className="flex flex-row w-full mt-5">
          <div className="flex flex-col w-3/12 h-full">
            <OrderBook />
            <RecentTransactions />
            <AggregationDetails />
          </div>
          <div className="flex flex-col w-9/12 h-full ml-5">
            <Charts />
            <TradingBox />
            <AssetDetails />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

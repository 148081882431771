import React, { useState, useEffect } from "react";


function TradingBox() {
  return (
    <div>
      trading box 
    </div>
  );
}

export default TradingBox;

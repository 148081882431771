import React, { useState, useEffect } from "react";

function AggregationDetails() {
  return (
    <div className="flex flex-row w-full h-10 mt-5 border border-b-400 min-w-min">
      AggregationDetails
    </div>
  );
}

export default AggregationDetails;

import React, { useState, useEffect } from "react";
import { SellOrderBook, BuyOrderBook } from "../../interfaces/interfaces";
import DepthChart from "../DepthChart/DepthChart";
import StackedDepthChart from "../StackedDepthChart/StackedDepthChart";

function Charts() {
  const [mode, setMode] = useState("stacked");

  const setToSumMode = () => {   
    setMode("sum");
  };

  const setToStackedMode = () => {    
    setMode("stacked");
  };

  return (
    <div className="flex flex-col w-full p-5 border border-b-400 min-w-min">
      <div className="flex flex-row w-full">
        <button className="m-2 border border-black " onClick={setToSumMode}>
          Sum
        </button>
        <button className="m-2 border border-black" onClick={setToStackedMode}>
          Stacked
        </button>
      </div>

      {mode === "sum" ? (
        <div id="depth-chart" className="flex flex-col w-full mt-5 border border-b-400 min-w-min">
          <DepthChart />
        </div>
      ) : (
        <div
          id="stacked-depth-chart"
          className="flex flex-col w-full mt-5 border border-b-400 min-w-min"
        >
          <StackedDepthChart />
        </div>
      )}
    </div>
  );
}

export default Charts;

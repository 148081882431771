import React, { useState, useEffect } from "react";

function AssetDetails() {
  return (
    <div className="flex flex-col w-full p-5 mt-5 border h-96 border-b-400 min-w-min">
      Asset Details
    </div>
  );
}

export default AssetDetails;

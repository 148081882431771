// @ts-nocheck

import React, { useState, useEffect } from "react";
import * as d3 from "d3";
import { debug } from "console";

let data = {
    "bids": [
        {
            "price": 20,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "20500000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652198106,
                    "expirationTime": 1652284496,
                    "makerAddr": "0x7A349a913243C84B428CCAE73f1a6636235B151a"
                }
            ],
            "total": 20500000000000000000,
            "looksrare": 1
        },
        {
            "price": 19,
            "amount": 4,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "19400000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652195143,
                    "expirationTime": 1652281533,
                    "makerAddr": "0x575e7770D5E5b2Cd1AD12A8BEEae6382784AB156"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "19100000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652194705,
                    "expirationTime": 1652281087,
                    "makerAddr": "0x7A349a913243C84B428CCAE73f1a6636235B151a"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "19088054493700000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652198134,
                    "expirationTime": 1652198733,
                    "makerAddr": "0x809CA3f5FFb4b371733064b9C9f32F44bB969583"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "19050000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652192385,
                    "expirationTime": 1652221200,
                    "makerAddr": "0x17331428346E388f32013e6bEc0Aba29303857FD"
                }
            ],
            "total": 76638054493700000000,
            "looksrare": 4
        },
        {
            "price": 18,
            "amount": 5,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "18900000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652191879,
                    "expirationTime": 1652278271,
                    "makerAddr": "0x17331428346E388f32013e6bEc0Aba29303857FD"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "18800000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652179218,
                    "expirationTime": 1652200800,
                    "makerAddr": "0x31cefaB5F3D8FF656C1642dfAcCF9FA764714AD4"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "18700000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652178167,
                    "expirationTime": 1652199720,
                    "makerAddr": "0x31cefaB5F3D8FF656C1642dfAcCF9FA764714AD4"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "18500000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652169039,
                    "expirationTime": 1654761028,
                    "makerAddr": "0x4b7B2E734E8bE67b9bD5d5aBa39ede22CF5eDFF1"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "18300000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652168845,
                    "expirationTime": 1654760838,
                    "makerAddr": "0x4b7B2E734E8bE67b9bD5d5aBa39ede22CF5eDFF1"
                }
            ],
            "total": 93200000000000000000,
            "looksrare": 5
        },
        {
            "price": 17,
            "amount": 7,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "17850000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652156071,
                    "expirationTime": 1652249700,
                    "makerAddr": "0xA0aCD401Dde7cAF2683C9Bc03B0F093570D2575D"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "17502000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652151016,
                    "expirationTime": 1652237414,
                    "makerAddr": "0xFb976882336A54328dC0b5cC5C771068e780F8E0"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "17501000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652150762,
                    "expirationTime": 1652237159,
                    "makerAddr": "0xFb976882336A54328dC0b5cC5C771068e780F8E0"
                },
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 17235326787902032000,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "17030000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652133565,
                    "expirationTime": 1652219959,
                    "makerAddr": "0x5A0a6310C13375892cf64331008Dd038F28B8E1d"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "17020000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652132900,
                    "expirationTime": 1652219295,
                    "makerAddr": "0x5A0a6310C13375892cf64331008Dd038F28B8E1d"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "17010000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652132226,
                    "expirationTime": 1652218618,
                    "makerAddr": "0x5A0a6310C13375892cf64331008Dd038F28B8E1d"
                }
            ],
            "total": 121148326787902030000,
            "looksrare": 6,
            "nftx": 1
        },
        {
            "price": 16,
            "amount": 6,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "16924600000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652198270,
                    "expirationTime": 1652198570,
                    "makerAddr": "0xE0A50615A1316C5671b138Dfa7E02BD5954Cf07B"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "16900000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652130283,
                    "expirationTime": 1652216679,
                    "makerAddr": "0x8A53b410031c6C606CD495DFE1F3e65003dC384C"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "16500000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652156036,
                    "expirationTime": 1652242428,
                    "makerAddr": "0x82176bB7e477B84Dc816A182dE447E5320E75E34"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "16300000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652140670,
                    "expirationTime": 1652227060,
                    "makerAddr": "0x5F6AF6b4F8778175BeC97468592A913FA98A163c"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "16300000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652127907,
                    "expirationTime": 1652214297,
                    "makerAddr": "0x3AAFb3888e6D4B07B6250B0327C94F5dDC991196"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "16000000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652122378,
                    "expirationTime": 1652381562,
                    "makerAddr": "0x5F6AF6b4F8778175BeC97468592A913FA98A163c"
                }
            ],
            "total": 98924600000000000000,
            "looksrare": 6
        },
        {
            "price": 15,
            "amount": 8,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "15900000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652121816,
                    "expirationTime": 1654713804,
                    "makerAddr": "0x5F6AF6b4F8778175BeC97468592A913FA98A163c"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "15690000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652120435,
                    "expirationTime": 1652206833,
                    "makerAddr": "0xFa89ec40699Bbfd749c4eb6643dC2B22fF0e2aa6"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "15514000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652073645,
                    "expirationTime": 1652678439,
                    "makerAddr": "0x473bb8e29F9A309Cb23741cE57A6970017AD58Ff"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "15500000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652169496,
                    "expirationTime": 1654761457,
                    "makerAddr": "0xa7E9C4029d6a29c3Ad3C07e7F5E0608fF581Dcf9"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "15500000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652162676,
                    "expirationTime": 1652249049,
                    "makerAddr": "0x9d6D32c225D85f701b72A2Cf5183B867f3C14858"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "15000000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652138833,
                    "expirationTime": 1652225228,
                    "makerAddr": "0x9d6D32c225D85f701b72A2Cf5183B867f3C14858"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "15000000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651948926,
                    "expirationTime": 1652208114,
                    "makerAddr": "0xd55Cf95fc44eE64927faEeB57708BEC5a7aFD410"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "15000000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652178019,
                    "expirationTime": 1652264308,
                    "makerAddr": "0x7C3b610Bfeb03948Ea27e191E9497be7d2dfCAD2"
                }
            ],
            "total": 123104000000000000000,
            "looksrare": 8
        },
        {
            "price": 14,
            "amount": 3,
            "orders": [
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 14802856052753201000,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "14000000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650160305,
                    "expirationTime": 1652752229,
                    "makerAddr": "0x80D882Cf8C7B3549fe54c6b570375f555DD228DC"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "14000000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652194009,
                    "expirationTime": 1652280405,
                    "makerAddr": "0xcDa94Fbd4843624a50238bbb2F53bE18bA54256e"
                }
            ],
            "total": 42802856052753200000,
            "nftx": 1,
            "looksrare": 2
        },
        {
            "price": 13,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "13000000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651465904,
                    "expirationTime": 1654057879,
                    "makerAddr": "0x5fa1c32dDBb419a5505115702e86De9Cee2609f2"
                }
            ],
            "total": 13000000000000000000,
            "looksrare": 1
        },
        {
            "price": 12,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 12851394499442074000,
                    "assetImageUrl": ""
                }
            ],
            "total": 12851394499442074000,
            "nftx": 1
        },
        {
            "price": 11,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 11261961773733593000,
                    "assetImageUrl": ""
                }
            ],
            "total": 11261961773733593000,
            "nftx": 1
        },
        {
            "price": 10,
            "amount": 2,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "10300000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651959947,
                    "expirationTime": 1652219144,
                    "makerAddr": "0xAd13404F2f2DA9A95D34AfAFf660903D1496B7e3"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "10000000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652120256,
                    "expirationTime": 1652725001,
                    "makerAddr": "0x00c0F1E2B164D625bBEc7C5264147bed9f36a654"
                }
            ],
            "total": 20300000000000000000,
            "looksrare": 2
        },
        {
            "price": 9,
            "amount": 3,
            "orders": [
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 9950223156080410000,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "9000000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652127036,
                    "expirationTime": 1652731787,
                    "makerAddr": "0x1da75Fb0DA32653Fdab9DdecDb42F2ed61F8A28b"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "9000000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652151984,
                    "expirationTime": 1652756779,
                    "makerAddr": "0x9a1e8645DEDec6d45E2058D031B1CA97F815bd2E"
                }
            ],
            "total": 27950223156080410000,
            "nftx": 1,
            "looksrare": 2
        },
        {
            "price": 8,
            "amount": 2,
            "orders": [
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 8855049724129632000,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "8000000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651863123,
                    "expirationTime": 1652467920,
                    "makerAddr": "0xAd13404F2f2DA9A95D34AfAFf660903D1496B7e3"
                }
            ],
            "total": 16855049724129632000,
            "nftx": 1,
            "looksrare": 1
        },
        {
            "price": 7,
            "amount": 3,
            "orders": [
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 7931255588527620000,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 7144855540929626000,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "7000000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652132974,
                    "expirationTime": 1652219364,
                    "makerAddr": "0xBBcf2F33Fa00f2e9e4062Dd91c458a2f1D1C3f38"
                }
            ],
            "total": 22076111129457246000,
            "nftx": 2,
            "looksrare": 1
        },
        {
            "price": 6,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 6469890714503217000,
                    "assetImageUrl": ""
                }
            ],
            "total": 6469890714503217000,
            "nftx": 1
        },
        {
            "price": 5,
            "amount": 4,
            "orders": [
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 5886256469448278000,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 5378186476844925000,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "5000000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652122902,
                    "expirationTime": 1654714898,
                    "makerAddr": "0x76DDF4145B7C7757AFCc9354cE9E15E5A2B35eCC"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "5000000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651920905,
                    "expirationTime": 1652525700,
                    "makerAddr": "0xA87Dc7dc8890c519502415D5CDf9C7a3D32fAaE8"
                }
            ],
            "total": 21264442946293203000,
            "nftx": 2,
            "looksrare": 2
        },
        {
            "price": 4,
            "amount": 6,
            "orders": [
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 4933175815909376000,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 4541203555003629600,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "4200000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1646836961,
                    "expirationTime": 1662385354,
                    "makerAddr": "0xc2aEE730530ae26571d868743c4e1ad401f30f22"
                },
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 4194163269806342000,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "4100000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650056478,
                    "expirationTime": 1652648464,
                    "makerAddr": "0x81465Ae33349C85b171C37Bc07304370d74A8Db4"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "4000000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651506074,
                    "expirationTime": 1654098049,
                    "makerAddr": "0x9C3a559e9F091A11B7C49BE74E0Ce9592D93775C"
                }
            ],
            "total": 25968542640719348000,
            "nftx": 3,
            "looksrare": 3
        },
        {
            "price": 3,
            "amount": 6,
            "orders": [
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 3885440275485966300,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 3609593909101314000,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "3460000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652012757,
                    "expirationTime": 1654604746,
                    "makerAddr": "0xF5f16440F1569A835400af06cF1187297badFF45"
                },
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 3362116052781416400,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "3330000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652144151,
                    "expirationTime": 1652748935,
                    "makerAddr": "0x7AA01C68a2478Dac75500B0aea174c292F5b2F0c"
                },
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 3139245682066850000,
                    "assetImageUrl": ""
                }
            ],
            "total": 20786395919435547000,
            "nftx": 4,
            "looksrare": 2
        },
        {
            "price": 2,
            "amount": 12,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "2960000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652082672,
                    "expirationTime": 1654674667,
                    "makerAddr": "0xF5f16440F1569A835400af06cF1187297badFF45"
                },
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 2937825060645110000,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 2755187225235407000,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 2589067214248165400,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "2500000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651038405,
                    "expirationTime": 1652247999,
                    "makerAddr": "0x99b8e697e25B677F024f1EE288f13f01CAb22Ea2"
                },
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 2437531481412403000,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 2298921358022049800,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 2171807456648528000,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "2100000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651927104,
                    "expirationTime": 1654519098,
                    "makerAddr": "0x0014ea9BBE130c8af7f00C8E61Fc07368bDaAf7d"
                },
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 2054952661483126800,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "2000000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652118739,
                    "expirationTime": 1652723533,
                    "makerAddr": "0xE1De31587d1c52199F804eaDED4b59dA50c51611"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "2000000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652152641,
                    "expirationTime": 1667704637,
                    "makerAddr": "0x20D45B645a2f42b7f11BD86d0CD0d34b3FC9EA01"
                }
            ],
            "total": 28805292457694790000,
            "looksrare": 5,
            "nftx": 7
        },
        {
            "price": 1,
            "amount": 9,
            "orders": [
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 1947281905698635800,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 1847857349699010600,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 1755857884681011200,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 1670562121048162300,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "1500000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1642819201,
                    "expirationTime": 1658367588,
                    "makerAddr": "0x939153efae9811AD71BC1174AF3007861968Dd87"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "1006900000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651284907,
                    "expirationTime": 1653876898,
                    "makerAddr": "0x512AC3bc989fC48505F18170d297193708a329D8"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "1000000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650989714,
                    "expirationTime": 1666541707,
                    "makerAddr": "0x12C1c70a4ADCE3Cbc8fe374f79F01e3fCa1FBB21"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "1000000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651882372,
                    "expirationTime": 1654474366,
                    "makerAddr": "0x97E27c55De1aEc7058C3c796597E5468220771E1"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "1000000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652154247,
                    "expirationTime": 1652454000,
                    "makerAddr": "0x5Ed9274A7622E1Fac6ea254f13C7ec2d27c95F02"
                }
            ],
            "total": 12728459261126820000,
            "nftx": 4,
            "looksrare": 5
        },
        {
            "price": 0,
            "amount": 84,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "900000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650405789,
                    "expirationTime": 1652997756,
                    "makerAddr": "0xf7f35236420fc6bc572d308B3f154F875385CcD5"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "820000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651900481,
                    "expirationTime": 1654492476,
                    "makerAddr": "0x7E3F7C2fF206fAB5F0dfdEF4491aB651798Ef3d4"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "720000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650308410,
                    "expirationTime": 1665860404,
                    "makerAddr": "0x90418A409d74C65c49b03Ee919593dA3e66A5118"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "700000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652125476,
                    "expirationTime": 1652384666,
                    "makerAddr": "0xE1D3f63fCA173565534238a4Ac74E2CF1056E17f"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "680000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650705719,
                    "expirationTime": 1653297582,
                    "makerAddr": "0xe67CE5d8c0fAE4742127A7A3f0205a00a9754515"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "600000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650836510,
                    "expirationTime": 1653428498,
                    "makerAddr": "0x426e82225bc9CFE2Ff5C8CeFa389A67f660cF3d5"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "580000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650478250,
                    "expirationTime": 1653070237,
                    "makerAddr": "0x426e82225bc9CFE2Ff5C8CeFa389A67f660cF3d5"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "564000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650448378,
                    "expirationTime": 1666000375,
                    "makerAddr": "0xE55E02c6Aac7763Da91B612F6A6282F572086165"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "500000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650671992,
                    "expirationTime": 1653263981,
                    "makerAddr": "0x6BC775135a138BF8839e3137c93E9B3B75345707"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "500000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650318592,
                    "expirationTime": 1652910586,
                    "makerAddr": "0x52ABe6A75eaaED6545b615eC4e0d08E689e84cC5"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "500000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651886847,
                    "expirationTime": 1654478841,
                    "makerAddr": "0x97E27c55De1aEc7058C3c796597E5468220771E1"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "492000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652137289,
                    "expirationTime": 1652396487,
                    "makerAddr": "0x64B8d68DFd04a68667b20c67a9b3CDbd49B3809A"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "471257000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1649719057,
                    "expirationTime": 1652311038,
                    "makerAddr": "0x77cF213153b942b32A0DCB1FB4dB1FCC21b334AF"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "470000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651950369,
                    "expirationTime": 1652209559,
                    "makerAddr": "0x3ED19b81d61A4e2DCe90Fd31DD4fa5aA4d93C63e"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "437000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651568535,
                    "expirationTime": 1654160528,
                    "makerAddr": "0xD378C18A9B83c4A26Bf0dda250D4aeFD4e938A21"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "430000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651670827,
                    "expirationTime": 1654262824,
                    "makerAddr": "0xC7563980c065baf282f0fe037D0254c1aB048c69"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "420000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651192887,
                    "expirationTime": 1666744882,
                    "makerAddr": "0x52ABe6A75eaaED6545b615eC4e0d08E689e84cC5"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "410000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650470631,
                    "expirationTime": 1653062617,
                    "makerAddr": "0x426e82225bc9CFE2Ff5C8CeFa389A67f660cF3d5"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "400000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651568523,
                    "expirationTime": 1654160519,
                    "makerAddr": "0xD378C18A9B83c4A26Bf0dda250D4aeFD4e938A21"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "400000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650933040,
                    "expirationTime": 1653525037,
                    "makerAddr": "0x1cccEAf45873e1A9464B493EE813315a8DE06707"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "400000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651938064,
                    "expirationTime": 1654530060,
                    "makerAddr": "0x62CBa5545dcd9B24aE094454394083840a0Ef82D"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "380000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651129523,
                    "expirationTime": 1653721517,
                    "makerAddr": "0xe43192C078E391D6243e7e45828C0669162bB9e7"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "366000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650120241,
                    "expirationTime": 1652712234,
                    "makerAddr": "0xC47a5CECf46DeF8bdc90b0dbCbF3fbDe0595C635"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "360000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651830782,
                    "expirationTime": 1667386380,
                    "makerAddr": "0x40E09D59fcbd4a8ca69D4C2CdCCB718da83961E6"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "347000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650577192,
                    "expirationTime": 1653169184,
                    "makerAddr": "0xB330f0b48820c6B519d41ea833438e69d866927A"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "345600000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650136952,
                    "expirationTime": 1665688948,
                    "makerAddr": "0xBe5e46D3Fe27614d2BE7E92dC969c4fD0e9eeFcb"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "322000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1645211522,
                    "expirationTime": 1660759918,
                    "makerAddr": "0xf7198b19F1CCc6371884894f4Af84b8F2CF68CA0"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "320000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650428776,
                    "expirationTime": 1653020768,
                    "makerAddr": "0xEc523dF44584c78b4eD331f14b6827eEcf29a7d1"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "300000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1642218501,
                    "expirationTime": 1657766872,
                    "makerAddr": "0x5F90da95d4C6b24B3292cCdF7Ae652eD4cB341FA"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "280000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650210817,
                    "expirationTime": 1652802812,
                    "makerAddr": "0x426e82225bc9CFE2Ff5C8CeFa389A67f660cF3d5"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "250000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651513661,
                    "expirationTime": 1654105561,
                    "makerAddr": "0xa948f2B0DADa3521cFb55030B8470119F571d19d"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "250000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650975684,
                    "expirationTime": 1653567680,
                    "makerAddr": "0xC7563980c065baf282f0fe037D0254c1aB048c69"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "245000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650830286,
                    "expirationTime": 1653422277,
                    "makerAddr": "0xD378C18A9B83c4A26Bf0dda250D4aeFD4e938A21"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "244000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650883968,
                    "expirationTime": 1653475963,
                    "makerAddr": "0xD378C18A9B83c4A26Bf0dda250D4aeFD4e938A21"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "240000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650883959,
                    "expirationTime": 1653475956,
                    "makerAddr": "0xD378C18A9B83c4A26Bf0dda250D4aeFD4e938A21"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "234500000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650136939,
                    "expirationTime": 1665688935,
                    "makerAddr": "0xBe5e46D3Fe27614d2BE7E92dC969c4fD0e9eeFcb"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "186900000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1649204909,
                    "expirationTime": 1656980904,
                    "makerAddr": "0x72d7ee8DcD75a69119211650Bd7F81fDe66D3651"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "152000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1646840225,
                    "expirationTime": 1654612619,
                    "makerAddr": "0xe43192C078E391D6243e7e45828C0669162bB9e7"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "150000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651752067,
                    "expirationTime": 1654344057,
                    "makerAddr": "0x245c31F9cADCb9F4a0907Ab6d90D1dD1D063fa97"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "123450000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650136926,
                    "expirationTime": 1665688920,
                    "makerAddr": "0xBe5e46D3Fe27614d2BE7E92dC969c4fD0e9eeFcb"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "116000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650548115,
                    "expirationTime": 1653140093,
                    "makerAddr": "0x19c882b04Fa380933FD8711c0236DB91afC060Fc"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "108770000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650908001,
                    "expirationTime": 1653499983,
                    "makerAddr": "0x7f538566f85310C901172142E8a9a892f0EAf946"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "100000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652180875,
                    "expirationTime": 1654772836,
                    "makerAddr": "0xEAf60c6E0c8D2A28E8C3ACA96dbB8d2C2c4e4C0C"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "100000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650071440,
                    "expirationTime": 1665623436,
                    "makerAddr": "0x9E036444f7980133Cc251B2FED1E5880c986493E"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "100000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650026674,
                    "expirationTime": 1652618669,
                    "makerAddr": "0x067D3E5459Fa588aA5a5001FC9608b314E7e4A01"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "100000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1648321921,
                    "expirationTime": 1663873914,
                    "makerAddr": "0x650B0E3864aFa96877A2493B495FE2436f725adB"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "100000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1644672965,
                    "expirationTime": 1660224960,
                    "makerAddr": "0x5D5820Daa520cb3d934f349Cf164ddfc002e477f"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "90000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650981947,
                    "expirationTime": 1653573918,
                    "makerAddr": "0x127BC8347f17EFD05558f3d718B8aA602187d6c1"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "89790000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650897605,
                    "expirationTime": 1653489585,
                    "makerAddr": "0x7f538566f85310C901172142E8a9a892f0EAf946"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "66660000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650624309,
                    "expirationTime": 1653216304,
                    "makerAddr": "0xBD49Fcf9197C4D70e5AcC0B0C5deFAc280b419a4"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651445479,
                    "expirationTime": 1666997475,
                    "makerAddr": "0xBD8E44A30438f02A215D828f25999A8b9E6D2B25"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650831816,
                    "expirationTime": 1653423811,
                    "makerAddr": "0xD378C18A9B83c4A26Bf0dda250D4aeFD4e938A21"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "49195000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651302525,
                    "expirationTime": 1653894516,
                    "makerAddr": "0x16dBC3174C1b824acC026983b08114f397a2Fa5b"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "49000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652161805,
                    "expirationTime": 1667713801,
                    "makerAddr": "0x16dBC3174C1b824acC026983b08114f397a2Fa5b"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651425048,
                    "expirationTime": 1654017043,
                    "makerAddr": "0xc7b333Dd05577C5242d2cb7535dD413e534DD327"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42069000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650627401,
                    "expirationTime": 1666179395,
                    "makerAddr": "0x9BCd78AE10965c28ED1d60f1963ad55f245BD353"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652023772,
                    "expirationTime": 1654615761,
                    "makerAddr": "0xc49E0aA098162b846F38Db82FE4Fd0576f776FeA"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1648768912,
                    "expirationTime": 1664320909,
                    "makerAddr": "0x12E7336DFB8A872bB49cf8C94D766848Dbc41ECD"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38690000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650830751,
                    "expirationTime": 1653422740,
                    "makerAddr": "0xD378C18A9B83c4A26Bf0dda250D4aeFD4e938A21"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650830272,
                    "expirationTime": 1653422264,
                    "makerAddr": "0xD378C18A9B83c4A26Bf0dda250D4aeFD4e938A21"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34123000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651189427,
                    "expirationTime": 1666741421,
                    "makerAddr": "0xb00154e628C7880cEdDF01c36888Fa9e6FD3ecb9"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652193436,
                    "expirationTime": 1652452633,
                    "makerAddr": "0x7c69B3260333424f07F43939d28669ECAcBc2dC2"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651507392,
                    "expirationTime": 1654099383,
                    "makerAddr": "0xC5e801CD7A48a418F8a947dfFBe1152BA48d24Ed"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652047564,
                    "expirationTime": 1654639556,
                    "makerAddr": "0x107919b050b9dBd751B66e8a3e6053aD54c796Be"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24500000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650830295,
                    "expirationTime": 1653422290,
                    "makerAddr": "0xD378C18A9B83c4A26Bf0dda250D4aeFD4e938A21"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22200000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1644442148,
                    "expirationTime": 1652214537,
                    "makerAddr": "0x1eB2b9D3566dA877335CaE8befD0F44752Bc9E8b"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "21000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1648568368,
                    "expirationTime": 1664120363,
                    "makerAddr": "0x60F4c86457c1954c0ca963dc03534C3311967bEB"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "20000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652099543,
                    "expirationTime": 1654691531,
                    "makerAddr": "0xc3a051D0E8E0C84e276C8301A5fEb3D440E40Ba6"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "20000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651021219,
                    "expirationTime": 1666573215,
                    "makerAddr": "0x3AF56e7928dB481c881B8a63B8699fB7a1b2459f"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "17900000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651075411,
                    "expirationTime": 1666627406,
                    "makerAddr": "0x31FAa96EA12584Fe3D9bf27A14cDfA7922bB5AF9"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "17900000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651073081,
                    "expirationTime": 1666625037,
                    "makerAddr": "0x83E8EdF9Cc365cdf962FcD9Db69A56D56F5c98FA"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "15000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652047539,
                    "expirationTime": 1654639529,
                    "makerAddr": "0x107919b050b9dBd751B66e8a3e6053aD54c796Be"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "13000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651250683,
                    "expirationTime": 1653842670,
                    "makerAddr": "0x31FAa96EA12584Fe3D9bf27A14cDfA7922bB5AF9"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "12500000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651072886,
                    "expirationTime": 1666624824,
                    "makerAddr": "0x83E8EdF9Cc365cdf962FcD9Db69A56D56F5c98FA"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "12300000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651456563,
                    "expirationTime": 1654048554,
                    "makerAddr": "0xD28044E4AEcB73153888d962Fa7320282a0EFdd1"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "10000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651993051,
                    "expirationTime": 1652597804,
                    "makerAddr": "0xe61776e70D7AbAc70c169751947d76917514C211"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "10000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1648391849,
                    "expirationTime": 1663943845,
                    "makerAddr": "0x60F4c86457c1954c0ca963dc03534C3311967bEB"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "10000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651147098,
                    "expirationTime": 1666699093,
                    "makerAddr": "0x59d935Ef10D48821f5E2dbf89499aC440cA5761C"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "10000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650089587,
                    "expirationTime": 1652681582,
                    "makerAddr": "0x60fbAFCafc4057179C32C3cF55A8214f75C1234d"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "10000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652047510,
                    "expirationTime": 1654639503,
                    "makerAddr": "0x107919b050b9dBd751B66e8a3e6053aD54c796Be"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "10000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651886854,
                    "expirationTime": 1667438848,
                    "makerAddr": "0x28184DC70CF71a94a8E56c87bB8a4c6Ae9a699B0"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "10000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651087115,
                    "expirationTime": 1653678869,
                    "makerAddr": "0xbE71Ac1E70af0a4DAcb924763192234f6902F27f"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "10000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651092576,
                    "expirationTime": 1666644571,
                    "makerAddr": "0xe7d4d5aEa169afcaA903Bbf1f8bbB1e514645DF7"
                },
                {
                    "marketplaceName": "looksrare",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "10000000000000000",
                    "paymentToken": "ETH",
                    "listingTime": 1645610892,
                    "expirationTime": 1661159286,
                    "makerAddr": "0x7B11AdDA4b3A4F5cA3c1fd41bED5735cF293fd27"
                }
            ],
            "total": 18305304000000000000,
            "looksrare": 84
        }
    ],
    "asks": [
        {
            "price": 0,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "892",
                    "price": "0E-17",
                    "paymentToken": "WETH",
                    "listingTime": 1652230898,
                    "expirationTime": 1652835698,
                    "makerAddr": "0x2bd0cbb03af55e337aabb179a7bf092218b8367c",
                    "assetImageUrl": "https://img.seadn.io/files/4d2f3880ad4a0ebeee6e92d97e9249b5.png?auto=format"
                }
            ],
            "total": 0,
            "opensea": 1
        },
        {
            "price": 15,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "963",
                    "price": "15000000000000000000.00000000",
                    "paymentToken": "WETH",
                    "listingTime": 1652570400,
                    "expirationTime": 1653175200,
                    "makerAddr": "0xd5ae539870f87cf12e816a3cba55b4deb25d8e17",
                    "assetImageUrl": "https://img.seadn.io/files/eaff9b61d0197a6820f428e305bd9b88.png?auto=format"
                }
            ],
            "total": 15000000000000000000,
            "opensea": 1
        },
        {
            "price": 21,
            "amount": 8,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "21750000000000000000",
                    "tokenId": "29178",
                    "listingTime": 1652189897,
                    "expirationTime": 1652276270,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29178",
                    "makerAddr": "0x67b46Bd09D1671Faaa0DBCaF0aC278481ccC591F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "21790000000000000000",
                    "tokenId": "26092",
                    "listingTime": 1652196861,
                    "expirationTime": 1654788020,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26092",
                    "makerAddr": "0x418BA49c59d44434faC605F4C45D31BFC88E6787",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "21900000000000000000",
                    "tokenId": "1025",
                    "listingTime": 1652193242,
                    "expirationTime": 1652452430,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1025",
                    "makerAddr": "0xA15aAD03A4F9c46161783398FE6d93BA18FDa366",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "21900000000000000000",
                    "tokenId": "21016",
                    "listingTime": 1652122225,
                    "expirationTime": 1652206680,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21016",
                    "makerAddr": "0xc3a1528dF68DB9b0e1f28edd7B3c4958550702D1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "21900000000000000000",
                    "tokenId": "7584",
                    "listingTime": 1652193234,
                    "expirationTime": 1652452430,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7584",
                    "makerAddr": "0xA15aAD03A4F9c46161783398FE6d93BA18FDa366",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "21900000000000000000",
                    "tokenId": "22246",
                    "listingTime": 1652126632,
                    "expirationTime": 1652212966,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22246",
                    "makerAddr": "0x2450273B90b28D47A969E72D66518ED3e131DC26",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "21900000000000000000",
                    "tokenId": "19736",
                    "listingTime": 1652193249,
                    "expirationTime": 1652452430,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19736",
                    "makerAddr": "0xA15aAD03A4F9c46161783398FE6d93BA18FDa366",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "21990000000000000000",
                    "tokenId": "10112",
                    "listingTime": 1652044795,
                    "expirationTime": 1654416003,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10112",
                    "makerAddr": "0x2D124b390f35ed1736aD5556d51A2f9b59C1fe74",
                    "assetImageUrl": ""
                }
            ],
            "total": 175030000000000000000,
            "looksrare": 8
        },
        {
            "price": 22,
            "amount": 28,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22000000000000000000",
                    "tokenId": "12952",
                    "listingTime": 1652117055,
                    "expirationTime": 1652203453,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12952",
                    "makerAddr": "0x7B83f4AEf8377f3f6A54Ae6728A8a3ECcb3F6b3C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22000000000000000000",
                    "tokenId": "22246",
                    "listingTime": 1652119086,
                    "expirationTime": 1652378248,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22246",
                    "makerAddr": "0x2450273B90b28D47A969E72D66518ED3e131DC26",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22000000000000000000",
                    "tokenId": "27620",
                    "listingTime": 1652104154,
                    "expirationTime": 1654114763,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27620",
                    "makerAddr": "0x3a69648665ADDf5D4E633CE5E6c8774241487041",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22000000000000000000",
                    "tokenId": "20016",
                    "listingTime": 1652149641,
                    "expirationTime": 1652236036,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20016",
                    "makerAddr": "0xE0e82310b21c06eF9c1E92489a9CB542B54ff728",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22000000000000000000",
                    "tokenId": "26092",
                    "listingTime": 1652196028,
                    "expirationTime": 1654788020,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26092",
                    "makerAddr": "0x418BA49c59d44434faC605F4C45D31BFC88E6787",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22180000000000000000",
                    "tokenId": "10418",
                    "listingTime": 1652135429,
                    "expirationTime": 1652221819,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10418",
                    "makerAddr": "0x46D5eA84A4342F02BC3C20cC40d3CE8e74DaDcFd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22200000000000000000",
                    "tokenId": "6141",
                    "listingTime": 1652071590,
                    "expirationTime": 1652676373,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6141",
                    "makerAddr": "0xB9Ea03E69e4983c4Ab303cF3379864DC8FB9e462",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22200000000000000000",
                    "tokenId": "28742",
                    "listingTime": 1652071609,
                    "expirationTime": 1652676373,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28742",
                    "makerAddr": "0xB9Ea03E69e4983c4Ab303cF3379864DC8FB9e462",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22200000000000000000",
                    "tokenId": "28848",
                    "listingTime": 1652071612,
                    "expirationTime": 1652676373,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28848",
                    "makerAddr": "0xB9Ea03E69e4983c4Ab303cF3379864DC8FB9e462",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22200000000000000000",
                    "tokenId": "812",
                    "listingTime": 1652071604,
                    "expirationTime": 1652676373,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/812",
                    "makerAddr": "0xB9Ea03E69e4983c4Ab303cF3379864DC8FB9e462",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22200000000000000000",
                    "tokenId": "9133",
                    "listingTime": 1652071596,
                    "expirationTime": 1652676373,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9133",
                    "makerAddr": "0xB9Ea03E69e4983c4Ab303cF3379864DC8FB9e462",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22200000000000000000",
                    "tokenId": "8255",
                    "listingTime": 1652071587,
                    "expirationTime": 1652676373,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8255",
                    "makerAddr": "0xB9Ea03E69e4983c4Ab303cF3379864DC8FB9e462",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22200000000000000000",
                    "tokenId": "15784",
                    "listingTime": 1652071601,
                    "expirationTime": 1652676373,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15784",
                    "makerAddr": "0xB9Ea03E69e4983c4Ab303cF3379864DC8FB9e462",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22200000000000000000",
                    "tokenId": "2921",
                    "listingTime": 1652071584,
                    "expirationTime": 1652676373,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2921",
                    "makerAddr": "0xB9Ea03E69e4983c4Ab303cF3379864DC8FB9e462",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22200000000000000000",
                    "tokenId": "2240",
                    "listingTime": 1652071598,
                    "expirationTime": 1652676373,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2240",
                    "makerAddr": "0xB9Ea03E69e4983c4Ab303cF3379864DC8FB9e462",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22200000000000000000",
                    "tokenId": "9538",
                    "listingTime": 1652071580,
                    "expirationTime": 1652676373,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9538",
                    "makerAddr": "0xB9Ea03E69e4983c4Ab303cF3379864DC8FB9e462",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22200000000000000000",
                    "tokenId": "337",
                    "listingTime": 1652071606,
                    "expirationTime": 1652676373,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/337",
                    "makerAddr": "0xB9Ea03E69e4983c4Ab303cF3379864DC8FB9e462",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22200000000000000000",
                    "tokenId": "2784",
                    "listingTime": 1652071593,
                    "expirationTime": 1652676373,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2784",
                    "makerAddr": "0xB9Ea03E69e4983c4Ab303cF3379864DC8FB9e462",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22500000000000000000",
                    "tokenId": "3702",
                    "listingTime": 1652196620,
                    "expirationTime": 1652283014,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3702",
                    "makerAddr": "0xB48a56C9E52087364f4ec750B32e49209eb5b7e4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22500000000000000000",
                    "tokenId": "6203",
                    "listingTime": 1652147769,
                    "expirationTime": 1652234163,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6203",
                    "makerAddr": "0x5C17E38c76Dfa078FBD7CE07c742476eeCA93712",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22500000000000000000",
                    "tokenId": "6250",
                    "listingTime": 1652145279,
                    "expirationTime": 1653485399,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6250",
                    "makerAddr": "0x989cE0d5f551d94EC87E638Dc643c014e63a0c0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22580000000000000000",
                    "tokenId": "6893",
                    "listingTime": 1652195907,
                    "expirationTime": 1654787572,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6893",
                    "makerAddr": "0xcEe37b3BCf1EfeD1c1E30D59e5f4015Ea16f2509",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22580000000000000000",
                    "tokenId": "210",
                    "listingTime": 1652023702,
                    "expirationTime": 1654342271,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/210",
                    "makerAddr": "0xa61f21e490cadeEC26D48E1bFED6393b753420a9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22700000000000000000",
                    "tokenId": "6893",
                    "listingTime": 1652195647,
                    "expirationTime": 1654787572,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6893",
                    "makerAddr": "0xcEe37b3BCf1EfeD1c1E30D59e5f4015Ea16f2509",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "price": 22817264152037270000,
                    "tokenId": "25340@-@25256@-@6927@-@24896@-@3176@-@3364@-@8201@-@9725@-@12630@-@17492@-@21640@-@22488@-@24406@-@5512@-@25706@-@833@-@29676@-@16656",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22900000000000000000",
                    "tokenId": "29948",
                    "listingTime": 1652193459,
                    "expirationTime": 1652204220,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29948",
                    "makerAddr": "0xf342EaBa3eAAc1EC8dBc1d07496F4FC4b4CB7Ad3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22900000000000000000",
                    "tokenId": "8986",
                    "listingTime": 1652194743,
                    "expirationTime": 1654786725,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8986",
                    "makerAddr": "0xD8670E0ad4C12449a44e4208c7A40fCDcFe40B20",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "22990000000000000000",
                    "tokenId": "28960",
                    "listingTime": 1652194102,
                    "expirationTime": 1652280471,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28960",
                    "makerAddr": "0xc52aBE15B7C4126401F6B451cF482837372D06F5",
                    "assetImageUrl": ""
                }
            ],
            "total": 625547264152037200000,
            "looksrare": 27,
            "nftx": 1
        },
        {
            "price": 23,
            "amount": 64,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "28018",
                    "listingTime": 1652134346,
                    "expirationTime": 1652284833,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28018",
                    "makerAddr": "0xa5855f4F40a409fFDe3a171eB616926AEc5D7B9C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "9077",
                    "listingTime": 1652128224,
                    "expirationTime": 1652214610,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9077",
                    "makerAddr": "0xc34c302Cdd1540533c1158Fc64e659E58e1Ed2e6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "18940",
                    "listingTime": 1652116347,
                    "expirationTime": 1652670135,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18940",
                    "makerAddr": "0xB827e57ADe128b1476EA1b45532f48406e2A7008",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "6712",
                    "listingTime": 1652129504,
                    "expirationTime": 1654721499,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6712",
                    "makerAddr": "0xb5c476f9319F345912aF05A4491775FB5aBA635E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "11270",
                    "listingTime": 1652106652,
                    "expirationTime": 1654304342,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11270",
                    "makerAddr": "0x843f2C19bc6df9E32B482E2F9ad6C078001088b1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "8672",
                    "listingTime": 1652098574,
                    "expirationTime": 1652357742,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8672",
                    "makerAddr": "0x9f28152AE399a6f1B9760c04972DE4f9303C0C9f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "2706",
                    "listingTime": 1652090176,
                    "expirationTime": 1652349321,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2706",
                    "makerAddr": "0x998f20a7f456690F2983E5D4639288C0A1f33810",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "29918",
                    "listingTime": 1652180779,
                    "expirationTime": 1652267173,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29918",
                    "makerAddr": "0x8418A464f0f51C958D1b57A5fb88D1A9896E8232",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "2226",
                    "listingTime": 1652144776,
                    "expirationTime": 1652268073,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2226",
                    "makerAddr": "0x36602d9F655E88fF539bef1Acc4A731050C8346F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "13420",
                    "listingTime": 1652117354,
                    "expirationTime": 1652203749,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13420",
                    "makerAddr": "0xEDe64a571CFe98B936271B935a955620f387E05A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "19856",
                    "listingTime": 1652085818,
                    "expirationTime": 1654441986,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19856",
                    "makerAddr": "0x391e3A871E75E041FC16c70D8204eF7c6E327401",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "1635",
                    "listingTime": 1652135817,
                    "expirationTime": 1652220060,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1635",
                    "makerAddr": "0xf1aaBD530C11eB9385e790d98EbB2B793A3d8165",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "6558",
                    "listingTime": 1652023786,
                    "expirationTime": 1654342271,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6558",
                    "makerAddr": "0xa61f21e490cadeEC26D48E1bFED6393b753420a9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "7746",
                    "listingTime": 1652115941,
                    "expirationTime": 1652202331,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7746",
                    "makerAddr": "0x06fDAA3045904D500b883fED2fa64694eD9e8AA2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "18404",
                    "listingTime": 1652195670,
                    "expirationTime": 1652454848,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18404",
                    "makerAddr": "0x9c08432086DcC1b1Ce6701cF64209afdde433Bce",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "6250",
                    "listingTime": 1652087144,
                    "expirationTime": 1653485399,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6250",
                    "makerAddr": "0x989cE0d5f551d94EC87E638Dc643c014e63a0c0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "5513",
                    "listingTime": 1652109338,
                    "expirationTime": 1654125141,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5513",
                    "makerAddr": "0xEC4762fb859a53c15D1689c08FD5E48C5c1C47Fa",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "238",
                    "listingTime": 1652194278,
                    "expirationTime": 1652280674,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/238",
                    "makerAddr": "0xdf76118683918B3e9f70c49b90bc55061393ee4E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "9539",
                    "listingTime": 1652128283,
                    "expirationTime": 1652214678,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9539",
                    "makerAddr": "0x5aF8d46c7bc379501E08Ed8E06AeD47596D440B8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "23652",
                    "listingTime": 1652115973,
                    "expirationTime": 1652202366,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23652",
                    "makerAddr": "0x06fDAA3045904D500b883fED2fa64694eD9e8AA2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "23160",
                    "listingTime": 1652163158,
                    "expirationTime": 1654755152,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23160",
                    "makerAddr": "0x85BC03678220009E82206ddCCbf713228750F759",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "15874",
                    "listingTime": 1652150533,
                    "expirationTime": 1652236929,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15874",
                    "makerAddr": "0xBafC5b52c21a655D6eFf4f496543fFcB105b7FAB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "3399",
                    "listingTime": 1651900356,
                    "expirationTime": 1654492313,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3399",
                    "makerAddr": "0x5D44Ab2337c44e118b7E3Ee564F7b7430F4a5968",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "6681",
                    "listingTime": 1652077979,
                    "expirationTime": 1652603066,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6681",
                    "makerAddr": "0xE65CD95bC3A66CcDC15584aB90Dfe2Fb5E0157fF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "12862",
                    "listingTime": 1652118285,
                    "expirationTime": 1652377480,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12862",
                    "makerAddr": "0xe9E7F10a129Ed34be0cAB9e1Fb0c8a309D3526D9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23000000000000000000",
                    "tokenId": "2486",
                    "listingTime": 1652192989,
                    "expirationTime": 1652259906,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2486",
                    "makerAddr": "0x5D5af22Bc64059860D4e1104Bfc3FbC82f03e8E0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2013",
                    "price": "23000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652137000,
                    "expirationTime": 1654212564,
                    "makerAddr": "0x0b67a3d8708e3375a5226208eb3764e345b9ada2",
                    "assetImageUrl": "https://img.seadn.io/files/36abb22a44b54b8c804a414138818495.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2606",
                    "price": "23000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652182915,
                    "expirationTime": 1652269402,
                    "makerAddr": "0xfa377eed778f74266cfabca542e61dccb1215bc9",
                    "assetImageUrl": "https://img.seadn.io/files/4f0eaf4396f708a415f04de247cb83a6.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23200000000000000000",
                    "tokenId": "7496",
                    "listingTime": 1652102576,
                    "expirationTime": 1652361771,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7496",
                    "makerAddr": "0x052afB1C639a8c47bF596C98bB064a6d76F33F8b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23200000000000000000",
                    "tokenId": "6403",
                    "listingTime": 1652084640,
                    "expirationTime": 1652360963,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6403",
                    "makerAddr": "0xF59742B240426FDc41d61954a49f55f2A377313D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23320000000000000000",
                    "tokenId": "8645",
                    "listingTime": 1652128374,
                    "expirationTime": 1652214662,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8645",
                    "makerAddr": "0x955475B2620cBded30b019Df4C04e3cD02Fa3eEa",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23350000000000000000",
                    "tokenId": "23804",
                    "listingTime": 1652197800,
                    "expirationTime": 1654789795,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23804",
                    "makerAddr": "0x068c0b630Fddb7E5D1e3Dc0D5A0fD3eE64e88e51",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23350000000000000000",
                    "tokenId": "16986",
                    "listingTime": 1652197815,
                    "expirationTime": 1654789795,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16986",
                    "makerAddr": "0x068c0b630Fddb7E5D1e3Dc0D5A0fD3eE64e88e51",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23400000000000000000",
                    "tokenId": "22130",
                    "listingTime": 1652198190,
                    "expirationTime": 1652241600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22130",
                    "makerAddr": "0xd4BAa5B1cfd74512a4243B09b544E6fb19832389",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23440000000000000000",
                    "tokenId": "8886",
                    "listingTime": 1652197200,
                    "expirationTime": 1654789196,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8886",
                    "makerAddr": "0x165896732df25087eba4e736604559CEaFdfC82F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23440000000000000000",
                    "tokenId": "8499",
                    "listingTime": 1652197216,
                    "expirationTime": 1654789196,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8499",
                    "makerAddr": "0x165896732df25087eba4e736604559CEaFdfC82F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23490000000000000000",
                    "tokenId": "27006",
                    "listingTime": 1652188959,
                    "expirationTime": 1652793746,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27006",
                    "makerAddr": "0x7dE1170B6114610AD842594ff0f007Db9E4e24cc",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2486",
                    "price": "23490000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652192809,
                    "expirationTime": 1652351706,
                    "makerAddr": "0x5d5af22bc64059860d4e1104bfc3fbc82f03e8e0",
                    "assetImageUrl": "https://img.seadn.io/files/f0c3adcee7025b1e71cb17a91343cc60.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23500000000000000000",
                    "tokenId": "3900",
                    "listingTime": 1652077480,
                    "expirationTime": 1667608343,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3900",
                    "makerAddr": "0x53d16d913005E9D9cb21f27a7899C558e28Ac459",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23500000000000000000",
                    "tokenId": "6316",
                    "listingTime": 1652190791,
                    "expirationTime": 1652277160,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6316",
                    "makerAddr": "0x24d1e52e43Ba0a98bdc7EdA0FE0dcD96dd0F7821",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23500000000000000000",
                    "tokenId": "5376",
                    "listingTime": 1652186529,
                    "expirationTime": 1652240460,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5376",
                    "makerAddr": "0x3Ad773EA809460cbBA8fD71C5d576b2A942a5245",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23500000000000000000",
                    "tokenId": "1079",
                    "listingTime": 1652189987,
                    "expirationTime": 1652202060,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1079",
                    "makerAddr": "0xAdFE3f955bE1e2e70c25Af72BbF9260B6153f9Cd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23500000000000000000",
                    "tokenId": "21590",
                    "listingTime": 1652181170,
                    "expirationTime": 1652281200,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21590",
                    "makerAddr": "0xb1D762d61Ac255C602375aad0b0fc57Bea3D3Ba3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23500000000000000000",
                    "tokenId": "665",
                    "listingTime": 1652116094,
                    "expirationTime": 1667668093,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/665",
                    "makerAddr": "0xF7480A958bFA8EF59C52CA995C1d3518968ce2d9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23500000000000000000",
                    "tokenId": "5859",
                    "listingTime": 1652189977,
                    "expirationTime": 1652202060,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5859",
                    "makerAddr": "0xAdFE3f955bE1e2e70c25Af72BbF9260B6153f9Cd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23510000000000000000",
                    "tokenId": "3708",
                    "listingTime": 1652192905,
                    "expirationTime": 1652279297,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3708",
                    "makerAddr": "0xe92Cb01c7849917556A62D0d8dEf60B333466892",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23560000000000000000",
                    "tokenId": "10112",
                    "listingTime": 1651828135,
                    "expirationTime": 1654416003,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10112",
                    "makerAddr": "0x2D124b390f35ed1736aD5556d51A2f9b59C1fe74",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23580000000000000000",
                    "tokenId": "210",
                    "listingTime": 1651838983,
                    "expirationTime": 1654342271,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/210",
                    "makerAddr": "0xa61f21e490cadeEC26D48E1bFED6393b753420a9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23600000000000000000",
                    "tokenId": "25620",
                    "listingTime": 1651862202,
                    "expirationTime": 1654449472,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25620",
                    "makerAddr": "0x7685274EeE0D73BB916F1e1c8d126967CA63dC21",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23600000000000000000",
                    "tokenId": "8816",
                    "listingTime": 1652167870,
                    "expirationTime": 1652456438,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8816",
                    "makerAddr": "0x1851119E9a3CA4f64aC9aB3766b86f735b8c8094",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23600000000000000000",
                    "tokenId": "25974",
                    "listingTime": 1651885091,
                    "expirationTime": 1652472121,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25974",
                    "makerAddr": "0x6B92686c40747C85809a6772D0eda8e22a77C60c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23619000000000000000",
                    "tokenId": "8187",
                    "listingTime": 1652186648,
                    "expirationTime": 1652208000,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8187",
                    "makerAddr": "0xB3A52bB1B496289b86136d6aD386caE86B2C71c3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23660000000000000000",
                    "tokenId": "4676",
                    "listingTime": 1652189229,
                    "expirationTime": 1652207100,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4676",
                    "makerAddr": "0xF3E437fa4f110992432fa9c85F5b26995FE2423D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23700000000000000000",
                    "tokenId": "3976",
                    "listingTime": 1652123735,
                    "expirationTime": 1652210127,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3976",
                    "makerAddr": "0x7973716321d05968Dd13ed701728fAbb872c92C0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23700000000000000000",
                    "tokenId": "3840",
                    "listingTime": 1651963021,
                    "expirationTime": 1654554997,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3840",
                    "makerAddr": "0xC54C08b6A112B3621f429bB42053dB85295B9446",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23750000000000000000",
                    "tokenId": "10252",
                    "listingTime": 1652127591,
                    "expirationTime": 1653337188,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10252",
                    "makerAddr": "0x73a6A002B9538F636FbfE6bfB8b7440b8b3A510E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23750000000000000000",
                    "tokenId": "5631",
                    "listingTime": 1652117491,
                    "expirationTime": 1652203888,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5631",
                    "makerAddr": "0x1b3746CE4aaECa85fC0e7992578BEcB69575F5EA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23750000000000000000",
                    "tokenId": "4079",
                    "listingTime": 1652117502,
                    "expirationTime": 1652203888,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4079",
                    "makerAddr": "0x1b3746CE4aaECa85fC0e7992578BEcB69575F5EA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23800000000000000000",
                    "tokenId": "11834",
                    "listingTime": 1652129763,
                    "expirationTime": 1652216146,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11834",
                    "makerAddr": "0x990D69a7541c73F853F1c75dB6374A9d75Cd9CD9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23900000000000000000",
                    "tokenId": "28640",
                    "listingTime": 1652179441,
                    "expirationTime": 1652262506,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28640",
                    "makerAddr": "0x4aD3Bf9138973F0cA5273Cc17ae8a63eE2B5D6dB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23900000000000000000",
                    "tokenId": "934",
                    "listingTime": 1652164998,
                    "expirationTime": 1652769783,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/934",
                    "makerAddr": "0x864d2437ED789CCEc3107727cC0Cf65b0Cd0A3b9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23900000000000000000",
                    "tokenId": "17800",
                    "listingTime": 1651893737,
                    "expirationTime": 1654067857,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17800",
                    "makerAddr": "0x63b2c57D8AD53397388E16787bd641507cDdb8d0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23990000000000000000",
                    "tokenId": "27006",
                    "listingTime": 1652152720,
                    "expirationTime": 1652411917,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27006",
                    "makerAddr": "0x7dE1170B6114610AD842594ff0f007Db9E4e24cc",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "23990000000000000000",
                    "tokenId": "8279",
                    "listingTime": 1652166923,
                    "expirationTime": 1653376516,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8279",
                    "makerAddr": "0xAE418c8FE6180DA0bBAE6742915452Ff75186cE0",
                    "assetImageUrl": ""
                }
            ],
            "total": 1.493039e+21,
            "looksrare": 61,
            "opensea": 3
        },
        {
            "price": 24,
            "amount": 108,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "20880",
                    "listingTime": 1652195428,
                    "expirationTime": 1652281811,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20880",
                    "makerAddr": "0x7A349a913243C84B428CCAE73f1a6636235B151a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "29678",
                    "listingTime": 1652099803,
                    "expirationTime": 1652358900,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29678",
                    "makerAddr": "0xE65E8219E86A5233C47f518e9582caa2EB3F19Cb",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "3676",
                    "listingTime": 1652082039,
                    "expirationTime": 1652370324,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3676",
                    "makerAddr": "0x458c1Adad9979b1eDa96a79C53ce5203762d5F18",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "1051",
                    "listingTime": 1651961442,
                    "expirationTime": 1652536586,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1051",
                    "makerAddr": "0xdD5991b9747d1F6D292eD2c57C4D5DC48b9f8e3a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "2606",
                    "listingTime": 1652099167,
                    "expirationTime": 1652254899,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2606",
                    "makerAddr": "0xFa377EeD778F74266cFabCa542e61dCCb1215BC9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "190",
                    "listingTime": 1652121520,
                    "expirationTime": 1652328346,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/190",
                    "makerAddr": "0x2e5EE0f23b930b4C12217d914ce0d1C9F794e284",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "7470",
                    "listingTime": 1652111013,
                    "expirationTime": 1652715806,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7470",
                    "makerAddr": "0x811B889a5B073713E6591ee05b50c867A9722005",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "14942",
                    "listingTime": 1652157899,
                    "expirationTime": 1652244281,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14942",
                    "makerAddr": "0x84dBaB98a5Fc8b6f0d963A086369259Af3F003a9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "2164",
                    "listingTime": 1652091308,
                    "expirationTime": 1667643305,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2164",
                    "makerAddr": "0x804604F55FE79aD0237696465c57D3dE997C14C1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "1063",
                    "listingTime": 1652098823,
                    "expirationTime": 1654567869,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1063",
                    "makerAddr": "0x7C8a7A76569ed447F2a5FDF6F0390EcCc5bd323E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "7380",
                    "listingTime": 1652160808,
                    "expirationTime": 1652202908,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7380",
                    "makerAddr": "0x99B937DB7E11f1Abe6ee1795317912BE46E20140",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "26446",
                    "listingTime": 1652149799,
                    "expirationTime": 1652236192,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26446",
                    "makerAddr": "0xE0e82310b21c06eF9c1E92489a9CB542B54ff728",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "8733",
                    "listingTime": 1652181165,
                    "expirationTime": 1654773099,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8733",
                    "makerAddr": "0xe51416eF43f4820Aaa2b36ddD9CfE1278106190f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "8074",
                    "listingTime": 1652099713,
                    "expirationTime": 1652358900,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8074",
                    "makerAddr": "0x3C4d21243F55f0Ee12F9ddDe1296d08529503311",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "3044",
                    "listingTime": 1652117773,
                    "expirationTime": 1652204167,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3044",
                    "makerAddr": "0x9ebe628D4552b18a947eDf977E89FaB7310dDf3A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "2199",
                    "listingTime": 1652186329,
                    "expirationTime": 1652214600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2199",
                    "makerAddr": "0xb08126344f4f78fFc09AC4a5A6DdadB5d1CFC23F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "3339",
                    "listingTime": 1652099623,
                    "expirationTime": 1652358900,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3339",
                    "makerAddr": "0x093F92f5327Af6E8F9Cd4E265A1F66aBF5aD5b34",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "1756",
                    "listingTime": 1652181217,
                    "expirationTime": 1654773099,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1756",
                    "makerAddr": "0xe51416eF43f4820Aaa2b36ddD9CfE1278106190f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "2775",
                    "listingTime": 1652162400,
                    "expirationTime": 1652767189,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2775",
                    "makerAddr": "0xaeDB040Ebe18edA43905f38423E5b37980add36a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "6110",
                    "listingTime": 1652117742,
                    "expirationTime": 1652204108,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6110",
                    "makerAddr": "0x9ebe628D4552b18a947eDf977E89FaB7310dDf3A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "9071",
                    "listingTime": 1652122600,
                    "expirationTime": 1652208998,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9071",
                    "makerAddr": "0x407a30Af48d6523873C5027610a57F5D02B6252C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "375",
                    "listingTime": 1652181206,
                    "expirationTime": 1654773099,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/375",
                    "makerAddr": "0xe51416eF43f4820Aaa2b36ddD9CfE1278106190f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "24518",
                    "listingTime": 1652166225,
                    "expirationTime": 1652241540,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24518",
                    "makerAddr": "0xe6B4cd8D3F0DE86836f74c9e6eA24325219F2347",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "403",
                    "listingTime": 1652127917,
                    "expirationTime": 1652214311,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/403",
                    "makerAddr": "0xD0E68256bD8a15C096CAa5fAC9f369a45c511Ad1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "7165",
                    "listingTime": 1652195423,
                    "expirationTime": 1652281811,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7165",
                    "makerAddr": "0x7A349a913243C84B428CCAE73f1a6636235B151a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "2677",
                    "listingTime": 1652132546,
                    "expirationTime": 1654080054,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2677",
                    "makerAddr": "0xA6c5d40c321412D0A16E46a59B23C1Dfd9D54eE8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "4617",
                    "listingTime": 1652181190,
                    "expirationTime": 1654773099,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4617",
                    "makerAddr": "0xe51416eF43f4820Aaa2b36ddD9CfE1278106190f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "2891",
                    "listingTime": 1652181176,
                    "expirationTime": 1654773099,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2891",
                    "makerAddr": "0xe51416eF43f4820Aaa2b36ddD9CfE1278106190f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "22690",
                    "listingTime": 1652118497,
                    "expirationTime": 1652377693,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22690",
                    "makerAddr": "0xe9E7F10a129Ed34be0cAB9e1Fb0c8a309D3526D9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "6189",
                    "listingTime": 1652057043,
                    "expirationTime": 1652413514,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6189",
                    "makerAddr": "0xE82151c961E7f91839417a236a45E8E560655B1a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "2034",
                    "listingTime": 1652069079,
                    "expirationTime": 1652232600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2034",
                    "makerAddr": "0x8b331D46A5f306E45d0Af12DDab91DcE46c93F27",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "17192",
                    "listingTime": 1652176305,
                    "expirationTime": 1652201400,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17192",
                    "makerAddr": "0x1e79ecB9E2E2E1882d4F4d4651F07D386062440B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "4622",
                    "listingTime": 1652090242,
                    "expirationTime": 1653834728,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4622",
                    "makerAddr": "0x998f20a7f456690F2983E5D4639288C0A1f33810",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "23812",
                    "listingTime": 1652195427,
                    "expirationTime": 1652281811,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23812",
                    "makerAddr": "0x7A349a913243C84B428CCAE73f1a6636235B151a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "7667",
                    "listingTime": 1652166219,
                    "expirationTime": 1652241540,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7667",
                    "makerAddr": "0xe6B4cd8D3F0DE86836f74c9e6eA24325219F2347",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "27734",
                    "listingTime": 1652176328,
                    "expirationTime": 1652201400,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27734",
                    "makerAddr": "0x1e79ecB9E2E2E1882d4F4d4651F07D386062440B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "29896",
                    "listingTime": 1652099534,
                    "expirationTime": 1652358600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29896",
                    "makerAddr": "0x32f7b29Eb742F77E24c6919f6cf7B019a30981AE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "3192",
                    "listingTime": 1652195425,
                    "expirationTime": 1652281811,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3192",
                    "makerAddr": "0x7A349a913243C84B428CCAE73f1a6636235B151a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "1338",
                    "listingTime": 1652158226,
                    "expirationTime": 1652241540,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1338",
                    "makerAddr": "0xD2d18409949978F22948F76Ae584ebc09115145b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "22748",
                    "listingTime": 1652104361,
                    "expirationTime": 1652591644,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22748",
                    "makerAddr": "0x2155D5C0A63AF068a00443483C4CD572ae73b09b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "17048",
                    "listingTime": 1651855896,
                    "expirationTime": 1652460068,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17048",
                    "makerAddr": "0xb504B80CC4C940988542A212a85928432eFb8760",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "19772",
                    "listingTime": 1652125339,
                    "expirationTime": 1652730137,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19772",
                    "makerAddr": "0xE480A57E33A9d6d2e29a4F420A019185BA81BA56",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "7008",
                    "listingTime": 1652191825,
                    "expirationTime": 1654783789,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7008",
                    "makerAddr": "0x2904790cdb329BBB292d1b79F6528A79EBD067eE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "8783",
                    "listingTime": 1652128063,
                    "expirationTime": 1652281773,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8783",
                    "makerAddr": "0xFA0f59502986865db617DA07CfeAa299729a70f8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "17228",
                    "listingTime": 1652106712,
                    "expirationTime": 1652365908,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17228",
                    "makerAddr": "0x907854aC2768A1a923F15A1345872C149b774E4d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "29146",
                    "listingTime": 1652156918,
                    "expirationTime": 1653982098,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29146",
                    "makerAddr": "0xE3813D26d44BcB6C40f29e5487dfF5434c629051",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "23800",
                    "listingTime": 1652106678,
                    "expirationTime": 1652365874,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23800",
                    "makerAddr": "0x907854aC2768A1a923F15A1345872C149b774E4d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "3944",
                    "listingTime": 1652195421,
                    "expirationTime": 1652281811,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3944",
                    "makerAddr": "0x7A349a913243C84B428CCAE73f1a6636235B151a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24000000000000000000",
                    "tokenId": "4725",
                    "listingTime": 1652106638,
                    "expirationTime": 1652365807,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4725",
                    "makerAddr": "0x907854aC2768A1a923F15A1345872C149b774E4d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "676",
                    "price": "24000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651870730,
                    "expirationTime": 1654549219,
                    "makerAddr": "0x64f1acc17c971a78a98546df0d29bc7c6d840b8b",
                    "assetImageUrl": "https://img.seadn.io/files/4d3624fd78514d11b6af193d0eb9258f.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3158",
                    "price": "24000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652085164,
                    "expirationTime": 1652513607,
                    "makerAddr": "0x122207a1ea3c9e1651e6039b236f419fc7add5cb",
                    "assetImageUrl": "https://img.seadn.io/files/927e420a7042d2acfc498baad790896a.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24020000000000000000",
                    "tokenId": "14348",
                    "listingTime": 1652133126,
                    "expirationTime": 1652219522,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14348",
                    "makerAddr": "0x1bEb3F9ebc98d9a50215D867529968Ba1cF9Ed61",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24080000000000000000",
                    "tokenId": "7038",
                    "listingTime": 1652116763,
                    "expirationTime": 1652203160,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7038",
                    "makerAddr": "0x561270e707283C4f94D24d0632d7724b00C9F054",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24100000000000000000",
                    "tokenId": "663",
                    "listingTime": 1652106537,
                    "expirationTime": 1654666515,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/663",
                    "makerAddr": "0x51A0c3aECAfDfBD9873E21173012d7520d8C6A35",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24100000000000000000",
                    "tokenId": "5992",
                    "listingTime": 1652011060,
                    "expirationTime": 1652270256,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5992",
                    "makerAddr": "0x42C3C3Bf1AB8EdffEb7990f3F1645d34299451A5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24100000000000000000",
                    "tokenId": "6250",
                    "listingTime": 1652058449,
                    "expirationTime": 1653485399,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6250",
                    "makerAddr": "0x989cE0d5f551d94EC87E638Dc643c014e63a0c0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24200000000000000000",
                    "tokenId": "21300",
                    "listingTime": 1652100086,
                    "expirationTime": 1654086210,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21300",
                    "makerAddr": "0xB53167fD81a9270b4Dea3F9eBF96742722cdCe50",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24240000000000000000",
                    "tokenId": "17258",
                    "listingTime": 1652120270,
                    "expirationTime": 1652206666,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17258",
                    "makerAddr": "0xFE274ff2846a414e690606c2ed2CCC4Ad6bB9C53",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24300000000000000000",
                    "tokenId": "2629",
                    "listingTime": 1652067587,
                    "expirationTime": 1654212884,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2629",
                    "makerAddr": "0x779bD28eA4f6268e02B397736cc2d6537118182D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24400000000000000000",
                    "tokenId": "5444",
                    "listingTime": 1652155858,
                    "expirationTime": 1652242252,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5444",
                    "makerAddr": "0xC7DC8C41159F5AD32191914017e7781190615011",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24500000000000000000",
                    "tokenId": "8390",
                    "listingTime": 1652115728,
                    "expirationTime": 1652202124,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8390",
                    "makerAddr": "0x87c57CDbc50dF7A48efa10eEAf68BF8c0Af068eA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24500000000000000000",
                    "tokenId": "28560",
                    "listingTime": 1651904480,
                    "expirationTime": 1654582360,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28560",
                    "makerAddr": "0x9cE52Bf87033C37849f2CEDA72B9D25C226E5265",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24500000000000000000",
                    "tokenId": "4015",
                    "listingTime": 1652115695,
                    "expirationTime": 1652202092,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4015",
                    "makerAddr": "0x87c57CDbc50dF7A48efa10eEAf68BF8c0Af068eA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24500000000000000000",
                    "tokenId": "18940",
                    "listingTime": 1652065343,
                    "expirationTime": 1652670135,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18940",
                    "makerAddr": "0xB827e57ADe128b1476EA1b45532f48406e2A7008",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24500000000000000000",
                    "tokenId": "5307",
                    "listingTime": 1652115775,
                    "expirationTime": 1652202173,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5307",
                    "makerAddr": "0x87c57CDbc50dF7A48efa10eEAf68BF8c0Af068eA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24500000000000000000",
                    "tokenId": "18432",
                    "listingTime": 1652105115,
                    "expirationTime": 1654697112,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18432",
                    "makerAddr": "0x2FE64f74Df8F5bef5DB73622bb58da79CA8C8B3b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24500000000000000000",
                    "tokenId": "12162",
                    "listingTime": 1652150614,
                    "expirationTime": 1652237009,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12162",
                    "makerAddr": "0x021AfDa9886DCe1cCa8cEcD130e4f2891b2B91d1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24500000000000000000",
                    "tokenId": "6925",
                    "listingTime": 1652120911,
                    "expirationTime": 1652207306,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6925",
                    "makerAddr": "0x0Bc6bCdda2356b42fFD79bB9914f3aF5d1aad07e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24500000000000000000",
                    "tokenId": "5383",
                    "listingTime": 1652125090,
                    "expirationTime": 1653823241,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5383",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24500000000000000000",
                    "tokenId": "11886",
                    "listingTime": 1652196600,
                    "expirationTime": 1652253600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11886",
                    "makerAddr": "0xC485E340587629edd2b14634Bd03F2A01DAd8dFD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24500000000000000000",
                    "tokenId": "11374",
                    "listingTime": 1651899134,
                    "expirationTime": 1652872623,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11374",
                    "makerAddr": "0x879783dA21c3365dC94a07aCDbB45dB75cf47400",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24500000000000000000",
                    "tokenId": "6046",
                    "listingTime": 1652125154,
                    "expirationTime": 1653401649,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6046",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24500000000000000000",
                    "tokenId": "2074",
                    "listingTime": 1652182264,
                    "expirationTime": 1652268644,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2074",
                    "makerAddr": "0xCF2e56d316bF92ffb3B4853996DEF56ae944C23F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24500000000000000000",
                    "tokenId": "6551",
                    "listingTime": 1652102536,
                    "expirationTime": 1652707315,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6551",
                    "makerAddr": "0x08eb8C48F1C34D1eFd8c6b2e885Ad3f5b0AB8586",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24500000000000000000",
                    "tokenId": "7619",
                    "listingTime": 1652012596,
                    "expirationTime": 1659788564,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7619",
                    "makerAddr": "0x476bc70cdDD2DfC0B11449B4dC026AbF9770FF56",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24500000000000000000",
                    "tokenId": "3315",
                    "listingTime": 1652195304,
                    "expirationTime": 1652800099,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3315",
                    "makerAddr": "0x4F4C15c696642F3be1a4127A03eB09b5DDc9c749",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24500000000000000000",
                    "tokenId": "665",
                    "listingTime": 1652090997,
                    "expirationTime": 1667642995,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/665",
                    "makerAddr": "0xF7480A958bFA8EF59C52CA995C1d3518968ce2d9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24500000000000000000",
                    "tokenId": "26942",
                    "listingTime": 1652122635,
                    "expirationTime": 1652209031,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26942",
                    "makerAddr": "0x407a30Af48d6523873C5027610a57F5D02B6252C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3178",
                    "price": "24500000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652197140,
                    "expirationTime": 1654875632,
                    "makerAddr": "0x9b059f4d2199e73aa86f8938167a1a6067474cf4",
                    "assetImageUrl": "https://img.seadn.io/files/d90f6a72f7f10a09f0fb5fb6f65980a0.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24600000000000000000",
                    "tokenId": "5241",
                    "listingTime": 1652012715,
                    "expirationTime": 1659788709,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5241",
                    "makerAddr": "0x476bc70cdDD2DfC0B11449B4dC026AbF9770FF56",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24690000000000000000",
                    "tokenId": "6664",
                    "listingTime": 1652165198,
                    "expirationTime": 1652424235,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6664",
                    "makerAddr": "0xB279F56B1F71F85d59Dc1499E697E4F005298c14",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24690000000000000000",
                    "tokenId": "5278",
                    "listingTime": 1652164665,
                    "expirationTime": 1652200500,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5278",
                    "makerAddr": "0x074396D67D04c48fdbEf50af95df216185B63cD5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24690000000000000000",
                    "tokenId": "4755",
                    "listingTime": 1652186901,
                    "expirationTime": 1652273287,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4755",
                    "makerAddr": "0x9d6DaAE4A034decC5D5780e4909a0A6FCbA090D7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24700000000000000000",
                    "tokenId": "5509",
                    "listingTime": 1652189967,
                    "expirationTime": 1652205600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5509",
                    "makerAddr": "0xc2f79E508cee14F99b6D30Ea3dfd71b5D5423621",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24740000000000000000",
                    "tokenId": "5327",
                    "listingTime": 1652127637,
                    "expirationTime": 1654652695,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5327",
                    "makerAddr": "0xbb392CaFEd4feba7452376CBc880b04A1760a5c4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24750000000000000000",
                    "tokenId": "3526",
                    "listingTime": 1652167509,
                    "expirationTime": 1652341341,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3526",
                    "makerAddr": "0xAE418c8FE6180DA0bBAE6742915452Ff75186cE0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24769000000000000000",
                    "tokenId": "28472",
                    "listingTime": 1652189100,
                    "expirationTime": 1652275494,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28472",
                    "makerAddr": "0x723BC33B12e33445d1d156E38e049024bB1807cE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24800000000000000000",
                    "tokenId": "8604",
                    "listingTime": 1651972532,
                    "expirationTime": 1654564526,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8604",
                    "makerAddr": "0xF670ed31671eeA84Ce7550aDED0eD1fb4d78C76a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24800000000000000000",
                    "tokenId": "9573",
                    "listingTime": 1652112697,
                    "expirationTime": 1652199092,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9573",
                    "makerAddr": "0xEBC6d7eD591caAA959ed3A733a70Ee063cd3CC96",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24800000000000000000",
                    "tokenId": "20726",
                    "listingTime": 1652105882,
                    "expirationTime": 1654094550,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20726",
                    "makerAddr": "0x08fB01893eBD994A01f9d6F822AF7a1dDE8544A8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24800000000000000000",
                    "tokenId": "10112",
                    "listingTime": 1651824058,
                    "expirationTime": 1654416003,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10112",
                    "makerAddr": "0x2D124b390f35ed1736aD5556d51A2f9b59C1fe74",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24800000000000000000",
                    "tokenId": "23962",
                    "listingTime": 1652121279,
                    "expirationTime": 1652207637,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23962",
                    "makerAddr": "0x0453C6B22E841fAa8a05f56311c7615a605cE197",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24870000000000000000",
                    "tokenId": "28318",
                    "listingTime": 1652188914,
                    "expirationTime": 1652448095,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28318",
                    "makerAddr": "0x87d0D4f67CF534FFbdd75aE0472Ca8951642c9BF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24880000000000000000",
                    "tokenId": "4138",
                    "listingTime": 1651969682,
                    "expirationTime": 1652562475,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4138",
                    "makerAddr": "0x51A9F0788c6BEBE7d723f3374Dd707ffBbdBD788",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24900000000000000000",
                    "tokenId": "19519",
                    "listingTime": 1652151087,
                    "expirationTime": 1652410278,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19519",
                    "makerAddr": "0xe3f118AF1C96abf08b3463480838DEa8b59cf710",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24900000000000000000",
                    "tokenId": "5647",
                    "listingTime": 1652196820,
                    "expirationTime": 1654788811,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5647",
                    "makerAddr": "0x26E4A3768d13D8Fe6D2B2DE32F81635292F79746",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24900000000000000000",
                    "tokenId": "3093",
                    "listingTime": 1652194433,
                    "expirationTime": 1654786416,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3093",
                    "makerAddr": "0xfBD483A0E8859e455922B03a052bC3bcdC78F957",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24900000000000000000",
                    "tokenId": "23054",
                    "listingTime": 1652120298,
                    "expirationTime": 1654652724,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23054",
                    "makerAddr": "0xbb392CaFEd4feba7452376CBc880b04A1760a5c4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24900000000000000000",
                    "tokenId": "2486",
                    "listingTime": 1652000722,
                    "expirationTime": 1652259906,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2486",
                    "makerAddr": "0x5D5af22Bc64059860D4e1104Bfc3FbC82f03e8E0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24900000000000000000",
                    "tokenId": "21366",
                    "listingTime": 1652120316,
                    "expirationTime": 1654653159,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21366",
                    "makerAddr": "0xbb392CaFEd4feba7452376CBc880b04A1760a5c4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24900000000000000000",
                    "tokenId": "1840",
                    "listingTime": 1652101570,
                    "expirationTime": 1653995626,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1840",
                    "makerAddr": "0x112D352E59ed2165052F2fB00e00D3B4B87552fD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24900000000000000000",
                    "tokenId": "5327",
                    "listingTime": 1652120280,
                    "expirationTime": 1654652695,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5327",
                    "makerAddr": "0xbb392CaFEd4feba7452376CBc880b04A1760a5c4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24900000000000000000",
                    "tokenId": "22185",
                    "listingTime": 1652118830,
                    "expirationTime": 1652205212,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22185",
                    "makerAddr": "0x3ec12480b617391ebEc062d6117d47873d8F39eD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24980000000000000000",
                    "tokenId": "6175",
                    "listingTime": 1652178826,
                    "expirationTime": 1652265216,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6175",
                    "makerAddr": "0x7f6c34D03BfE845b946beeb86dd7669151A4dE28",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "419",
                    "price": "24980000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652097232,
                    "expirationTime": 1654690746,
                    "makerAddr": "0x7a9dc8eeaf5022cecd60c54a042343484ce6c065",
                    "assetImageUrl": "https://img.seadn.io/files/218e178ae19c22545753858b1ba993f8.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24990000000000000000",
                    "tokenId": "8166",
                    "listingTime": 1652104361,
                    "expirationTime": 1652227022,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8166",
                    "makerAddr": "0x356c938a1d889D87999c75dC4e7aF1263b93e45D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "24990000000000000000",
                    "tokenId": "27006",
                    "listingTime": 1652061665,
                    "expirationTime": 1654653659,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27006",
                    "makerAddr": "0x7dE1170B6114610AD842594ff0f007Db9E4e24cc",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1494",
                    "price": "24990000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652118480,
                    "expirationTime": 1652377762,
                    "makerAddr": "0x81c1aa4bf93eca02292cb0dfa8d6d86d2fb3ba35",
                    "assetImageUrl": "https://img.seadn.io/files/582d327d399137252a3038ed88641b7b.png?auto=format"
                }
            ],
            "total": 2.627448999999995e+21,
            "looksrare": 103,
            "opensea": 5
        },
        {
            "price": 25,
            "amount": 155,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "8416",
                    "listingTime": 1652118234,
                    "expirationTime": 1652266503,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8416",
                    "makerAddr": "0xf1f8145518c19Bf9BADFaEF4977d35B96DB74c09",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "7885",
                    "listingTime": 1652198418,
                    "expirationTime": 1652216400,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7885",
                    "makerAddr": "0xf2a6f332daC44FAE0Fb7816D8b1362027a4B29C4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "19722",
                    "listingTime": 1652196778,
                    "expirationTime": 1652283166,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19722",
                    "makerAddr": "0x60577490F99712525E4A36D559256c6F99c788f3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "28724",
                    "listingTime": 1652168070,
                    "expirationTime": 1652254466,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28724",
                    "makerAddr": "0x68c4D9E03D7D902053C428Ca2D74b612Db7F583A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "5246",
                    "listingTime": 1652149912,
                    "expirationTime": 1652236308,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5246",
                    "makerAddr": "0xa4f0670468DFb825E6c2B5571e2c97D0844190a3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "7960",
                    "listingTime": 1652114025,
                    "expirationTime": 1653869864,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7960",
                    "makerAddr": "0xED525b5D5CD5BE436c026ee127A85A682213ae3B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "5148",
                    "listingTime": 1652115176,
                    "expirationTime": 1653942302,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5148",
                    "makerAddr": "0x75Cf2D77d40abd92756c704A963098a87b4e1aEC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "17752",
                    "listingTime": 1652168268,
                    "expirationTime": 1652254663,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17752",
                    "makerAddr": "0x68c4D9E03D7D902053C428Ca2D74b612Db7F583A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "11536",
                    "listingTime": 1652102558,
                    "expirationTime": 1654217185,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11536",
                    "makerAddr": "0x0D7D9AcF85FA0Bb5eE25bd8016B7c0f5A711c893",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "2526",
                    "listingTime": 1652188211,
                    "expirationTime": 1652274607,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2526",
                    "makerAddr": "0x29d6a4D57e71128c33f3BED6F31d2E10d086a16E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "16690",
                    "listingTime": 1652172800,
                    "expirationTime": 1652259197,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16690",
                    "makerAddr": "0x6C8Ee01F1f8B62E987b3D18F6F28b22a0Ada755f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "7557",
                    "listingTime": 1652114804,
                    "expirationTime": 1653449297,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7557",
                    "makerAddr": "0x534c98c85714Df298524CF94c3d2Cd230B1EEa5B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "12568",
                    "listingTime": 1652107081,
                    "expirationTime": 1652200332,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12568",
                    "makerAddr": "0xe14206171b48b0399Cb6eD519C87b7D07be1Af67",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "28120",
                    "listingTime": 1652188338,
                    "expirationTime": 1652274734,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28120",
                    "makerAddr": "0x29d6a4D57e71128c33f3BED6F31d2E10d086a16E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "20360",
                    "listingTime": 1652030521,
                    "expirationTime": 1653716530,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20360",
                    "makerAddr": "0x5eb01242b23A2A53cd5Dc612F07B5468F95f72cB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "5072",
                    "listingTime": 1651996806,
                    "expirationTime": 1652255994,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5072",
                    "makerAddr": "0x619e83EE7D22A29d2c808f2A790A6cdeA4e745FD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "8531",
                    "listingTime": 1652193387,
                    "expirationTime": 1652279741,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8531",
                    "makerAddr": "0x4B1fE4Dc40F2EEB4929cb6EE05877CD0cD6F4a6b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "15244",
                    "listingTime": 1652114204,
                    "expirationTime": 1654219385,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15244",
                    "makerAddr": "0x28570D9d66627e0733dFE4FCa79B8fD5b8128636",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "8295",
                    "listingTime": 1652195245,
                    "expirationTime": 1652800038,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8295",
                    "makerAddr": "0x4F4C15c696642F3be1a4127A03eB09b5DDc9c749",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "17048",
                    "listingTime": 1651855434,
                    "expirationTime": 1652460068,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17048",
                    "makerAddr": "0xb504B80CC4C940988542A212a85928432eFb8760",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "1642",
                    "listingTime": 1651918652,
                    "expirationTime": 1666388866,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1642",
                    "makerAddr": "0xfb363b7D7a1c065356442829F03C84F9660CE1e2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "9637",
                    "listingTime": 1652135894,
                    "expirationTime": 1652222292,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9637",
                    "makerAddr": "0x64932F86d69F2717307F41b4c6b8198000583c63",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "14030",
                    "listingTime": 1652139578,
                    "expirationTime": 1654446233,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14030",
                    "makerAddr": "0xAB44B030A1e9908f2b6751205C89fDcbe2f0a7Ed",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "25826",
                    "listingTime": 1652102537,
                    "expirationTime": 1654205485,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25826",
                    "makerAddr": "0x0D7D9AcF85FA0Bb5eE25bd8016B7c0f5A711c893",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "8783",
                    "listingTime": 1652101172,
                    "expirationTime": 1652281773,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8783",
                    "makerAddr": "0xFA0f59502986865db617DA07CfeAa299729a70f8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "825",
                    "listingTime": 1652103485,
                    "expirationTime": 1652585020,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/825",
                    "makerAddr": "0xa7e4f12B7Cd97387e8607daC17B439ca7cad58e9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "22470",
                    "listingTime": 1652188317,
                    "expirationTime": 1652274713,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22470",
                    "makerAddr": "0x29d6a4D57e71128c33f3BED6F31d2E10d086a16E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "24448",
                    "listingTime": 1652133564,
                    "expirationTime": 1653405104,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24448",
                    "makerAddr": "0x3922B064DcC34289B2c04f82B92977c413a0dAd2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "22290",
                    "listingTime": 1652108987,
                    "expirationTime": 1653205772,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22290",
                    "makerAddr": "0x493dd66Ff1e2484Fe21A31CD72B08292488A81A4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "3408",
                    "listingTime": 1651900272,
                    "expirationTime": 1653795043,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3408",
                    "makerAddr": "0x5D44Ab2337c44e118b7E3Ee564F7b7430F4a5968",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "24472",
                    "listingTime": 1652113960,
                    "expirationTime": 1654109351,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24472",
                    "makerAddr": "0x15053b688f69d742AD18f13f47d15E097E4A77e7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "16460",
                    "listingTime": 1652187708,
                    "expirationTime": 1654779682,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16460",
                    "makerAddr": "0x713F275ba51Dec8a166991F8fd44d468b62B1558",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "29110",
                    "listingTime": 1652079987,
                    "expirationTime": 1652339176,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29110",
                    "makerAddr": "0x245B51B64bE7ea3bf5cF71FdeDedd4f7550eB54B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "16546",
                    "listingTime": 1652194983,
                    "expirationTime": 1652223300,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16546",
                    "makerAddr": "0x26a1e46832bd5ffd7D21481D185FC258A7080316",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "17054",
                    "listingTime": 1652099775,
                    "expirationTime": 1654691767,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17054",
                    "makerAddr": "0x37e06Ba4b0403D33BbaBaFD63662B1F0fbfdf2cd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "6403",
                    "listingTime": 1651895615,
                    "expirationTime": 1652360963,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6403",
                    "makerAddr": "0xF59742B240426FDc41d61954a49f55f2A377313D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "27806",
                    "listingTime": 1652195020,
                    "expirationTime": 1652198700,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27806",
                    "makerAddr": "0x945191ab90d5B511F80c297681e5dE54E55c50B8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "6314",
                    "listingTime": 1652109260,
                    "expirationTime": 1654701187,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6314",
                    "makerAddr": "0x95BbDBF8C7E8D0527071e2D64eC68376DE2C6d65",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "5582",
                    "listingTime": 1652106372,
                    "expirationTime": 1653603931,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5582",
                    "makerAddr": "0xA25D7e569393583f2a17007b5619452E25fB40b9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "7787",
                    "listingTime": 1652107039,
                    "expirationTime": 1652200221,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7787",
                    "makerAddr": "0xe14206171b48b0399Cb6eD519C87b7D07be1Af67",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "17414",
                    "listingTime": 1652175893,
                    "expirationTime": 1652435085,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17414",
                    "makerAddr": "0x78E6c4866d058ef05275E3AEf3E1906da54525af",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "1797",
                    "listingTime": 1652172818,
                    "expirationTime": 1652259216,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1797",
                    "makerAddr": "0x6C8Ee01F1f8B62E987b3D18F6F28b22a0Ada755f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "26162",
                    "listingTime": 1652152189,
                    "expirationTime": 1652238582,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26162",
                    "makerAddr": "0x6Dc0df809b9566B3F041433714AaEf03114cb713",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "18108",
                    "listingTime": 1652099817,
                    "expirationTime": 1654267015,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18108",
                    "makerAddr": "0xeBf7c7932D86907C3BC1Bd6d486308637a8264A3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "1908",
                    "listingTime": 1652188296,
                    "expirationTime": 1652274693,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1908",
                    "makerAddr": "0x29d6a4D57e71128c33f3BED6F31d2E10d086a16E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "14846",
                    "listingTime": 1652154297,
                    "expirationTime": 1653821019,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14846",
                    "makerAddr": "0x6189e05a28bEF26Bcf76F22aB311282d5e843dF4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "319",
                    "listingTime": 1651975474,
                    "expirationTime": 1652580239,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/319",
                    "makerAddr": "0xEB07a7A5ec09d3C9B9AcC2d5110C38D58771c104",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "23158",
                    "listingTime": 1652149574,
                    "expirationTime": 1652235968,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23158",
                    "makerAddr": "0xcC99489Be87F4Efa26919d918995c09a7A688677",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "5103",
                    "listingTime": 1652190606,
                    "expirationTime": 1652205600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5103",
                    "makerAddr": "0x4e2e67836d10B02B1DCe78591af6DFd1e2d7BCBA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "21404",
                    "listingTime": 1652151246,
                    "expirationTime": 1652316520,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21404",
                    "makerAddr": "0x2e9BC111159cC4FE5479eCC81F1825cFD577E077",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "29332",
                    "listingTime": 1652149831,
                    "expirationTime": 1654741708,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29332",
                    "makerAddr": "0xB1B544CB9CB6cda24D7B248B2570f576f82A5e5A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "5783",
                    "listingTime": 1652101110,
                    "expirationTime": 1667215195,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5783",
                    "makerAddr": "0xCD750f5854eCE8252FB7d59D4a8Be7864241d50D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "5893",
                    "listingTime": 1652104160,
                    "expirationTime": 1653803334,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5893",
                    "makerAddr": "0x1FEACcB479834998BD7750754062347A6FaD8F9F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "20976",
                    "listingTime": 1652148853,
                    "expirationTime": 1652753520,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20976",
                    "makerAddr": "0xB446cC48DCc35A91De1490c338103c904f568850",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "27604",
                    "listingTime": 1652106136,
                    "expirationTime": 1652365328,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27604",
                    "makerAddr": "0x7599a1f7D44EEA1CFe86C75ceea54146719C9361",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "7276",
                    "listingTime": 1652111880,
                    "expirationTime": 1654407394,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7276",
                    "makerAddr": "0xc1F50e993a84F0B7C07daE664fE52986Bd32b106",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "6094",
                    "listingTime": 1652188192,
                    "expirationTime": 1652274588,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6094",
                    "makerAddr": "0x29d6a4D57e71128c33f3BED6F31d2E10d086a16E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "20148",
                    "listingTime": 1652114740,
                    "expirationTime": 1654112029,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20148",
                    "makerAddr": "0x534c98c85714Df298524CF94c3d2Cd230B1EEa5B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "28660",
                    "listingTime": 1652111394,
                    "expirationTime": 1652272891,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28660",
                    "makerAddr": "0xF117AeB3a34BA3af790b274979a68e6Ffc394a30",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "6906",
                    "listingTime": 1652188230,
                    "expirationTime": 1652274626,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6906",
                    "makerAddr": "0x29d6a4D57e71128c33f3BED6F31d2E10d086a16E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "8780",
                    "listingTime": 1652187691,
                    "expirationTime": 1654779682,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8780",
                    "makerAddr": "0x713F275ba51Dec8a166991F8fd44d468b62B1558",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "11772",
                    "listingTime": 1652140233,
                    "expirationTime": 1652270400,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11772",
                    "makerAddr": "0xcB6b7C4334E3083c354B3a0f2B29d22d9A4bb2D4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "5503",
                    "listingTime": 1652151721,
                    "expirationTime": 1652238116,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5503",
                    "makerAddr": "0x8e3DA7C7e530e0E3204b74FC4495Fc918dFd26ac",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "16540",
                    "listingTime": 1652158438,
                    "expirationTime": 1652417628,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16540",
                    "makerAddr": "0x1851119E9a3CA4f64aC9aB3766b86f735b8c8094",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "6629",
                    "listingTime": 1652085299,
                    "expirationTime": 1654575908,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6629",
                    "makerAddr": "0x7c744ae8a87fF460E7860EF344612383b1863182",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "7611",
                    "listingTime": 1652129954,
                    "expirationTime": 1652216348,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7611",
                    "makerAddr": "0x16A0edf95c600B9FF5982F79284bFf3a5Cf2D7F7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "1304",
                    "listingTime": 1652100086,
                    "expirationTime": 1654091917,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1304",
                    "makerAddr": "0x6b3d3fCDB08d0813FDf0E1267C2eca056EAB3415",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "6788",
                    "listingTime": 1652098755,
                    "expirationTime": 1652279720,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6788",
                    "makerAddr": "0x4199F2400fb5Ad164A065815B1ab386fD09016a0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "20768",
                    "listingTime": 1652137876,
                    "expirationTime": 1659913761,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20768",
                    "makerAddr": "0x0F7b1a42921119e013cE6797410d548B355ab18c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "16204",
                    "listingTime": 1652097261,
                    "expirationTime": 1652516344,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16204",
                    "makerAddr": "0x705a651b5611dEc423A6e5Cb07Ecb6E64F1fba5b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "9763",
                    "listingTime": 1652088641,
                    "expirationTime": 1652693277,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9763",
                    "makerAddr": "0x2A9a072924b2216BdfDbDF043d9acF0cC9c6b6F2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "378",
                    "listingTime": 1652193395,
                    "expirationTime": 1652279741,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/378",
                    "makerAddr": "0x4B1fE4Dc40F2EEB4929cb6EE05877CD0cD6F4a6b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "18822",
                    "listingTime": 1652167960,
                    "expirationTime": 1652254355,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18822",
                    "makerAddr": "0x68c4D9E03D7D902053C428Ca2D74b612Db7F583A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "4032",
                    "listingTime": 1651953502,
                    "expirationTime": 1653458429,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4032",
                    "makerAddr": "0xD3860466ddeb2580285F3A8843e464012F171694",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "10032",
                    "listingTime": 1652100396,
                    "expirationTime": 1653351474,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10032",
                    "makerAddr": "0x5126bEd5a2B3619D5e5afab8d6c888004AA5e8C7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "9357",
                    "listingTime": 1652188252,
                    "expirationTime": 1652274646,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9357",
                    "makerAddr": "0x29d6a4D57e71128c33f3BED6F31d2E10d086a16E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "28516",
                    "listingTime": 1652190890,
                    "expirationTime": 1653487398,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28516",
                    "makerAddr": "0x8b3477Ca7445ec524dd534fA4A596C993E93675a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "28116",
                    "listingTime": 1652099164,
                    "expirationTime": 1652296887,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28116",
                    "makerAddr": "0x6AA73c8049675fEc5EBD7C5B6dEAf4ca10B8f500",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "1925",
                    "listingTime": 1652131792,
                    "expirationTime": 1654151300,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1925",
                    "makerAddr": "0xB17D5DB0EC93331271Ed2f3fFfEBE4E5b790D97E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "14982",
                    "listingTime": 1652102517,
                    "expirationTime": 1652401200,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14982",
                    "makerAddr": "0x0D7D9AcF85FA0Bb5eE25bd8016B7c0f5A711c893",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "9584",
                    "listingTime": 1652086082,
                    "expirationTime": 1653995241,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9584",
                    "makerAddr": "0x3dE262F19795845534A6e8D604d0Da383C2FCC9D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "6787",
                    "listingTime": 1652187704,
                    "expirationTime": 1654779682,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6787",
                    "makerAddr": "0x713F275ba51Dec8a166991F8fd44d468b62B1558",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "8812",
                    "listingTime": 1652110563,
                    "expirationTime": 1652451907,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8812",
                    "makerAddr": "0xf6c9385Bc7764b31923B07BaC900ACDf886381a5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "5418",
                    "listingTime": 1652187700,
                    "expirationTime": 1654779682,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5418",
                    "makerAddr": "0x713F275ba51Dec8a166991F8fd44d468b62B1558",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "24258",
                    "listingTime": 1652149696,
                    "expirationTime": 1652236091,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24258",
                    "makerAddr": "0xE0e82310b21c06eF9c1E92489a9CB542B54ff728",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "6057",
                    "listingTime": 1652099697,
                    "expirationTime": 1654691615,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6057",
                    "makerAddr": "0x37e06Ba4b0403D33BbaBaFD63662B1F0fbfdf2cd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "7495",
                    "listingTime": 1652193378,
                    "expirationTime": 1652279741,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7495",
                    "makerAddr": "0x4B1fE4Dc40F2EEB4929cb6EE05877CD0cD6F4a6b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "4503",
                    "listingTime": 1652182988,
                    "expirationTime": 1652355000,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4503",
                    "makerAddr": "0x2f21C4E02077A61f7E1f6De37bc14d234cb569a9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "22334",
                    "listingTime": 1652030711,
                    "expirationTime": 1653407905,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22334",
                    "makerAddr": "0x1abEd56b27691Ba3b5c0c68D2B0Be0f1638e3D4f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "15262",
                    "listingTime": 1652078480,
                    "expirationTime": 1652297228,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15262",
                    "makerAddr": "0x98C098A155a65D9B5d23F113a1B53930BC19fF32",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "9807",
                    "listingTime": 1652194649,
                    "expirationTime": 1652281046,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9807",
                    "makerAddr": "0x1373BAb4cAAD155ff7aC9Df93518966681e6E55B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "6189",
                    "listingTime": 1652019337,
                    "expirationTime": 1652413514,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6189",
                    "makerAddr": "0xE82151c961E7f91839417a236a45E8E560655B1a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "9916",
                    "listingTime": 1652054269,
                    "expirationTime": 1654646203,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9916",
                    "makerAddr": "0x64E547986bFBFd1CdFfa15493b0A83d6B4b36b18",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "26348",
                    "listingTime": 1652080003,
                    "expirationTime": 1652339197,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26348",
                    "makerAddr": "0x245B51B64bE7ea3bf5cF71FdeDedd4f7550eB54B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "27864",
                    "listingTime": 1652188274,
                    "expirationTime": 1652274670,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27864",
                    "makerAddr": "0x29d6a4D57e71128c33f3BED6F31d2E10d086a16E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000",
                    "tokenId": "29822",
                    "listingTime": 1652191207,
                    "expirationTime": 1652277584,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29822",
                    "makerAddr": "0xB16B4016F3ca82Fae2E4fEB2c6F5E8D414d274c4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "319",
                    "price": "25000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651975466,
                    "expirationTime": 1652493900,
                    "makerAddr": "0xeb07a7a5ec09d3c9b9acc2d5110c38d58771c104",
                    "assetImageUrl": "https://img.seadn.io/files/f669ea61ba25430d368c88a4f51c9b39.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2560",
                    "price": "25000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651979146,
                    "expirationTime": 1652238434,
                    "makerAddr": "0xf8c75c5e9ec6875c57c0dbc30b59934b37908c4e",
                    "assetImageUrl": "https://img.seadn.io/files/7845d8f655c9706af5d00ba0378e5921.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2800",
                    "price": "25000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652146735,
                    "expirationTime": 1654825224,
                    "makerAddr": "0xc5fc3f9864d91c4506435a826c0506e163d798e2",
                    "assetImageUrl": "https://img.seadn.io/files/2778dff2402cd427605f9c2e8c983156.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3044",
                    "price": "25000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652117568,
                    "expirationTime": 1652203980,
                    "makerAddr": "0x9ebe628d4552b18a947edf977e89fab7310ddf3a",
                    "assetImageUrl": "https://img.seadn.io/files/51a6eddc602557066bbd71e3b5b57c0a.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25050000000000000000",
                    "tokenId": "9984",
                    "listingTime": 1651895658,
                    "expirationTime": 1652362542,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9984",
                    "makerAddr": "0x5Ec0D096f8ef2Ac2dBd3536e3dFE2db1361BA6a7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25050000000000000000",
                    "tokenId": "3160",
                    "listingTime": 1652195666,
                    "expirationTime": 1654787572,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3160",
                    "makerAddr": "0xcEe37b3BCf1EfeD1c1E30D59e5f4015Ea16f2509",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3160",
                    "price": "25050000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652197065,
                    "expirationTime": 1654874002,
                    "makerAddr": "0xcee37b3bcf1efed1c1e30d59e5f4015ea16f2509",
                    "assetImageUrl": "https://img.seadn.io/files/9659df0c25580b56fb0d15a605a5880b.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25090000000000000000",
                    "tokenId": "2402",
                    "listingTime": 1652085931,
                    "expirationTime": 1654170888,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2402",
                    "makerAddr": "0xD9502fb95c72b118a4Ec6d060297cB3aeb7c50BA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25100000000000000000",
                    "tokenId": "491",
                    "listingTime": 1652196723,
                    "expirationTime": 1652283119,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/491",
                    "makerAddr": "0x3443C780b8F101F5a4CcE1d1b3B414c999a79fc9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25180000000000000000",
                    "tokenId": "82",
                    "listingTime": 1652197069,
                    "expirationTime": 1652202060,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/82",
                    "makerAddr": "0xC5b2c6d523498FFB86b80db3bD1a800439Ba35A7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25200000000000000000",
                    "tokenId": "10430",
                    "listingTime": 1652188253,
                    "expirationTime": 1652274645,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10430",
                    "makerAddr": "0x909C47Be9E9766691bA0a1253980097cEBf73ACb",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25200000000000000000",
                    "tokenId": "28640",
                    "listingTime": 1652176150,
                    "expirationTime": 1652262506,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28640",
                    "makerAddr": "0x4aD3Bf9138973F0cA5273Cc17ae8a63eE2B5D6dB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2377",
                    "price": "25200000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652155595,
                    "expirationTime": 1654834068,
                    "makerAddr": "0x7f5bc50b539dc282e82a72a517c8acc73f161431",
                    "assetImageUrl": "https://img.seadn.io/files/4a13e149fc24eea302b03e7f872bc522.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25400000000000000000",
                    "tokenId": "21016",
                    "listingTime": 1651947484,
                    "expirationTime": 1652206680,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21016",
                    "makerAddr": "0xc3a1528dF68DB9b0e1f28edd7B3c4958550702D1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25420000000000000000",
                    "tokenId": "6250",
                    "listingTime": 1651891509,
                    "expirationTime": 1653485399,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6250",
                    "makerAddr": "0x989cE0d5f551d94EC87E638Dc643c014e63a0c0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25500000000000000000",
                    "tokenId": "7455",
                    "listingTime": 1652146924,
                    "expirationTime": 1653408852,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7455",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25500000000000000000",
                    "tokenId": "3147",
                    "listingTime": 1652197911,
                    "expirationTime": 1652457107,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3147",
                    "makerAddr": "0x2EFF303377B9E01e042B7F80fB42836B35FB1a49",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25500000000000000000",
                    "tokenId": "21758",
                    "listingTime": 1652190057,
                    "expirationTime": 1652205600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21758",
                    "makerAddr": "0x96D2f13C453A7d210EA3CFc55f16C86cBC940cda",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25500000000000000000",
                    "tokenId": "8670",
                    "listingTime": 1652190043,
                    "expirationTime": 1652205600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8670",
                    "makerAddr": "0x96D2f13C453A7d210EA3CFc55f16C86cBC940cda",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25500000000000000000",
                    "tokenId": "11928",
                    "listingTime": 1652185559,
                    "expirationTime": 1652226300,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11928",
                    "makerAddr": "0x5eE0dF1fA841c84646cd49F0753B5A86F1d07F4C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25500000000000000000",
                    "tokenId": "3827",
                    "listingTime": 1652146909,
                    "expirationTime": 1653408280,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3827",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25500000000000000000",
                    "tokenId": "23590",
                    "listingTime": 1652189983,
                    "expirationTime": 1652205600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23590",
                    "makerAddr": "0xc2f79E508cee14F99b6D30Ea3dfd71b5D5423621",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25500000000000000000",
                    "tokenId": "18172",
                    "listingTime": 1652188324,
                    "expirationTime": 1652274687,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18172",
                    "makerAddr": "0x6E2895DA30af2c11192A8A5C220aB95AF770A1bE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25500000000000000000",
                    "tokenId": "5536",
                    "listingTime": 1652190050,
                    "expirationTime": 1652205600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5536",
                    "makerAddr": "0x96D2f13C453A7d210EA3CFc55f16C86cBC940cda",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25500000000000000000",
                    "tokenId": "9496",
                    "listingTime": 1651814067,
                    "expirationTime": 1652861709,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9496",
                    "makerAddr": "0x7dd3705b1aBc05Cf736f6FDce4BCaC1069058a63",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25500000000000000000",
                    "tokenId": "1958",
                    "listingTime": 1652132793,
                    "expirationTime": 1653523783,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1958",
                    "makerAddr": "0xc00667d8B00f35B3565A5c4458Dff1Cd718E3527",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25500000000000000000",
                    "tokenId": "11832",
                    "listingTime": 1652197915,
                    "expirationTime": 1652457107,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11832",
                    "makerAddr": "0x2EFF303377B9E01e042B7F80fB42836B35FB1a49",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25500000000000000000",
                    "tokenId": "2218",
                    "listingTime": 1652110404,
                    "expirationTime": 1653418374,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2218",
                    "makerAddr": "0x49ca963Ef75BCEBa8E4A5F4cEAB5Fd326beF6123",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25500000000000000000",
                    "tokenId": "3508",
                    "listingTime": 1651861546,
                    "expirationTime": 1654453467,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3508",
                    "makerAddr": "0xA7dd0f5db0c5d1Ff7dec4f2EF74D62ff9a73a367",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25500000000000000000",
                    "tokenId": "3676",
                    "listingTime": 1652071603,
                    "expirationTime": 1652370324,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3676",
                    "makerAddr": "0x458c1Adad9979b1eDa96a79C53ce5203762d5F18",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25500000000000000000",
                    "tokenId": "8852",
                    "listingTime": 1652146885,
                    "expirationTime": 1653852863,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8852",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1296",
                    "price": "25500000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652124855,
                    "expirationTime": 1654803345,
                    "makerAddr": "0x3371f5c5ca2b7921e92f77177853f4f7341c8792",
                    "assetImageUrl": "https://img.seadn.io/files/b7114a0901d2aa894792977792d9e6c2.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25600000000000000000",
                    "tokenId": "10224",
                    "listingTime": 1652197943,
                    "expirationTime": 1652284326,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10224",
                    "makerAddr": "0x4c00aaAe552De7476122687b05589c25D61312Df",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25600000000000000000",
                    "tokenId": "210",
                    "listingTime": 1651803294,
                    "expirationTime": 1654342271,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/210",
                    "makerAddr": "0xa61f21e490cadeEC26D48E1bFED6393b753420a9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25690000000000000000",
                    "tokenId": "29960",
                    "listingTime": 1652102831,
                    "expirationTime": 1653738891,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29960",
                    "makerAddr": "0x55013e79Dc8fB573588B22447633c049e13ff1f3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25690000000000000000",
                    "tokenId": "26032",
                    "listingTime": 1652081819,
                    "expirationTime": 1652227452,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26032",
                    "makerAddr": "0xc94893b6D1CC1d7ce09Fec6abC9B5949A903d2C9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25690000000000000000",
                    "tokenId": "2279",
                    "listingTime": 1652196167,
                    "expirationTime": 1654788141,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2279",
                    "makerAddr": "0x4F4C15c696642F3be1a4127A03eB09b5DDc9c749",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25690000000000000000",
                    "tokenId": "7452",
                    "listingTime": 1652178296,
                    "expirationTime": 1652437470,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7452",
                    "makerAddr": "0xB279F56B1F71F85d59Dc1499E697E4F005298c14",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25700000000000000000",
                    "tokenId": "19586",
                    "listingTime": 1652167886,
                    "expirationTime": 1652264400,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19586",
                    "makerAddr": "0x1cAdA5A9CBe2aa39ad0Bc26f70c184e1e724F566",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25700000000000000000",
                    "tokenId": "27444",
                    "listingTime": 1652167710,
                    "expirationTime": 1652264700,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27444",
                    "makerAddr": "0x1cAdA5A9CBe2aa39ad0Bc26f70c184e1e724F566",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25720000000000000000",
                    "tokenId": "5927",
                    "listingTime": 1652137756,
                    "expirationTime": 1652224148,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5927",
                    "makerAddr": "0x10a2DFb788a57587E6deAd219fb2829B8eAd9D7b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25720000000000000000",
                    "tokenId": "8623",
                    "listingTime": 1652137760,
                    "expirationTime": 1652224148,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8623",
                    "makerAddr": "0x10a2DFb788a57587E6deAd219fb2829B8eAd9D7b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25720000000000000000",
                    "tokenId": "5926",
                    "listingTime": 1652137752,
                    "expirationTime": 1652224148,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5926",
                    "makerAddr": "0x10a2DFb788a57587E6deAd219fb2829B8eAd9D7b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25720000000000000000",
                    "tokenId": "12388",
                    "listingTime": 1652137763,
                    "expirationTime": 1652224148,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12388",
                    "makerAddr": "0x10a2DFb788a57587E6deAd219fb2829B8eAd9D7b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25770000000000000000",
                    "tokenId": "28472",
                    "listingTime": 1652081712,
                    "expirationTime": 1652268342,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28472",
                    "makerAddr": "0x723BC33B12e33445d1d156E38e049024bB1807cE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25800000000000000000",
                    "tokenId": "3938",
                    "listingTime": 1652140865,
                    "expirationTime": 1652227262,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3938",
                    "makerAddr": "0xB43fB4286267c006db33C5b6ccF497EEDa773340",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1944",
                    "price": "25800000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652185681,
                    "expirationTime": 1652267896,
                    "makerAddr": "0x9f4ebcb22447c64a21c687c1db6849ec55b8f016",
                    "assetImageUrl": "https://img.seadn.io/files/35b4d3c0588b96db3e093fcf93e4a26b.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25801000000000000000",
                    "tokenId": "2444",
                    "listingTime": 1652149304,
                    "expirationTime": 1652235665,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2444",
                    "makerAddr": "0xec9D9e58c5A91351339eB34E8B8572961Ba218F5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25880000000000000000",
                    "tokenId": "28318",
                    "listingTime": 1652081763,
                    "expirationTime": 1652268494,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28318",
                    "makerAddr": "0x87d0D4f67CF534FFbdd75aE0472Ca8951642c9BF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25880000000000000000",
                    "tokenId": "16800",
                    "listingTime": 1652136237,
                    "expirationTime": 1652395431,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16800",
                    "makerAddr": "0x2c3c28C8B928B85fe8cCb74B80BA22d300e35155",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25886000000000000000",
                    "tokenId": "9850",
                    "listingTime": 1652135009,
                    "expirationTime": 1652221405,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9850",
                    "makerAddr": "0x4f2988270e1ffa69b4b3B3ED38B55AAc8F68d349",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25886000000000000000",
                    "tokenId": "16534",
                    "listingTime": 1652135092,
                    "expirationTime": 1652221488,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16534",
                    "makerAddr": "0x4f2988270e1ffa69b4b3B3ED38B55AAc8F68d349",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25900000000000000000",
                    "tokenId": "15640",
                    "listingTime": 1652155174,
                    "expirationTime": 1652241568,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15640",
                    "makerAddr": "0x22777214fC28007F3ffa76C1d57E40A06E41ca3F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25900000000000000000",
                    "tokenId": "825",
                    "listingTime": 1652097871,
                    "expirationTime": 1652585020,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/825",
                    "makerAddr": "0xa7e4f12B7Cd97387e8607daC17B439ca7cad58e9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25990000000000000000",
                    "tokenId": "27006",
                    "listingTime": 1652058750,
                    "expirationTime": 1652663546,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27006",
                    "makerAddr": "0x7dE1170B6114610AD842594ff0f007Db9E4e24cc",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25990000000000000000",
                    "tokenId": "28836",
                    "listingTime": 1652090090,
                    "expirationTime": 1652349286,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28836",
                    "makerAddr": "0x0946bC5c2F9848665CC3811458De403d0A78AD8E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25990000000000000000",
                    "tokenId": "19126",
                    "listingTime": 1651960171,
                    "expirationTime": 1654026598,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19126",
                    "makerAddr": "0x385809f08e80693E1EDa54c9524a2995483Ab16E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1921",
                    "price": "25990000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651849367,
                    "expirationTime": 1654527862,
                    "makerAddr": "0x47c2ac06520722aaa3e32d99ec6a2352b48b1b8a",
                    "assetImageUrl": "https://img.seadn.io/files/e7f501454806955669be451028576f58.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2996",
                    "price": "25990000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651754232,
                    "expirationTime": 1654351041,
                    "makerAddr": "0xaeffb9749a27ae7298bfe75ec6005ed56f1e013f",
                    "assetImageUrl": "https://img.seadn.io/files/4db6bf966cbb9613355eaf99718253df.png?auto=format"
                }
            ],
            "total": 3.907133000000001e+21,
            "looksrare": 145,
            "opensea": 10
        },
        {
            "price": 26,
            "amount": 132,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "26562",
                    "listingTime": 1652097789,
                    "expirationTime": 1652356987,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26562",
                    "makerAddr": "0xAA05563B84844f8f8bB1C9ab9ca1eA5F4fAF6fce",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "2818",
                    "listingTime": 1652147276,
                    "expirationTime": 1654271927,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2818",
                    "makerAddr": "0x822a16309A9eE40f15e196898f11A010ecb1C963",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "5377",
                    "listingTime": 1652193896,
                    "expirationTime": 1652366640,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5377",
                    "makerAddr": "0x3522D315C2193b254777f579742aB1e20097E92b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "17148",
                    "listingTime": 1651983611,
                    "expirationTime": 1652242808,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17148",
                    "makerAddr": "0xf7a1AeEEB30faF0ba85C9BA865b84F09DFa8053f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "7380",
                    "listingTime": 1651943713,
                    "expirationTime": 1652202908,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7380",
                    "makerAddr": "0x99B937DB7E11f1Abe6ee1795317912BE46E20140",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "24108",
                    "listingTime": 1652196349,
                    "expirationTime": 1652223600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24108",
                    "makerAddr": "0x24Dd89DDAC25Dfe1324C83fD7bDbAa6ae5ACCD6e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "10246",
                    "listingTime": 1652168227,
                    "expirationTime": 1652254623,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10246",
                    "makerAddr": "0x68c4D9E03D7D902053C428Ca2D74b612Db7F583A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "14064",
                    "listingTime": 1652078314,
                    "expirationTime": 1654274060,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14064",
                    "makerAddr": "0x57E7917a016c25227387b1feAF8B73C49784f563",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "2606",
                    "listingTime": 1652093469,
                    "expirationTime": 1652254899,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2606",
                    "makerAddr": "0xFa377EeD778F74266cFabCa542e61dCCb1215BC9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "6558",
                    "listingTime": 1651803371,
                    "expirationTime": 1654342271,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6558",
                    "makerAddr": "0xa61f21e490cadeEC26D48E1bFED6393b753420a9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "26012",
                    "listingTime": 1652156029,
                    "expirationTime": 1652415191,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26012",
                    "makerAddr": "0x4ac9a97b3edb3f11b5ca91d8dE9468CF4882A7af",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "23466",
                    "listingTime": 1652154701,
                    "expirationTime": 1652241093,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23466",
                    "makerAddr": "0x44C4107E53a5e5e483a9e528B6Ed54508aB0c812",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "1135",
                    "listingTime": 1652158686,
                    "expirationTime": 1653809444,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1135",
                    "makerAddr": "0x2efBc44A485F13858A17CE33623e6C4c6AE7d01a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "12800",
                    "listingTime": 1652159148,
                    "expirationTime": 1652245544,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12800",
                    "makerAddr": "0x9f4A15E48675C16fdFA9b5e4a4CD6f315aeDB24E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "6189",
                    "listingTime": 1651861909,
                    "expirationTime": 1652413514,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6189",
                    "makerAddr": "0xE82151c961E7f91839417a236a45E8E560655B1a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "12974",
                    "listingTime": 1652147130,
                    "expirationTime": 1654119332,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12974",
                    "makerAddr": "0xc7f1916b741A7966Bd65144cb2B0Ba9eD1B29984",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "665",
                    "listingTime": 1652087107,
                    "expirationTime": 1667639104,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/665",
                    "makerAddr": "0xF7480A958bFA8EF59C52CA995C1d3518968ce2d9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "18062",
                    "listingTime": 1652155522,
                    "expirationTime": 1652328300,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18062",
                    "makerAddr": "0x633Fb030794B8508Cb27fB1eA6B45Df95bDdBbD1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "25072",
                    "listingTime": 1651932415,
                    "expirationTime": 1652310500,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25072",
                    "makerAddr": "0x98bdFE7174Ed431dA22604AAF87d66Fe2bd8621E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "6823",
                    "listingTime": 1652197297,
                    "expirationTime": 1652283694,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6823",
                    "makerAddr": "0x2584Cd4EaC415B8fc5Acc02Cc6E552b13110592F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "14236",
                    "listingTime": 1652164533,
                    "expirationTime": 1652250908,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14236",
                    "makerAddr": "0x13928eB9A86c8278a45B6fF2935c7730b58AC675",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "21540",
                    "listingTime": 1651991247,
                    "expirationTime": 1659258851,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21540",
                    "makerAddr": "0xD099B763fa289F3eB695Cf69fd4102C6bf266E0d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "7647",
                    "listingTime": 1652081707,
                    "expirationTime": 1652597866,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7647",
                    "makerAddr": "0x1E675330b76970F16C46Bc1e79249271677b0244",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "6836",
                    "listingTime": 1652185154,
                    "expirationTime": 1652668200,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6836",
                    "makerAddr": "0xB827e57ADe128b1476EA1b45532f48406e2A7008",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "6326",
                    "listingTime": 1652056873,
                    "expirationTime": 1652661670,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6326",
                    "makerAddr": "0xAEbD5bbDdB31C7800A59cFd7382AbCA3878CD1Ea",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "4584",
                    "listingTime": 1652168900,
                    "expirationTime": 1653992259,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4584",
                    "makerAddr": "0xab36Ef38d02E5aeC0fCcBc6B76772A0CF3E69A38",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "8392",
                    "listingTime": 1652187626,
                    "expirationTime": 1652206176,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8392",
                    "makerAddr": "0x3A70344c268cD039B107D9f65705F6092303c919",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "26451",
                    "listingTime": 1652177390,
                    "expirationTime": 1654769388,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26451",
                    "makerAddr": "0xDC089B4c366B780460a0C4c0e19e2D70AC7B3f7E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "26982",
                    "listingTime": 1652147537,
                    "expirationTime": 1652406565,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26982",
                    "makerAddr": "0x129311A1b838F10586AE0cd69C62a35871707cF2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "17826",
                    "listingTime": 1652168915,
                    "expirationTime": 1653992284,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17826",
                    "makerAddr": "0xab36Ef38d02E5aeC0fCcBc6B76772A0CF3E69A38",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "7710",
                    "listingTime": 1652154704,
                    "expirationTime": 1652223000,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7710",
                    "makerAddr": "0x0BE8684878dB3B4660B94A998157e88268829AdF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "8816",
                    "listingTime": 1651851651,
                    "expirationTime": 1652456438,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8816",
                    "makerAddr": "0x1851119E9a3CA4f64aC9aB3766b86f735b8c8094",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "4865",
                    "listingTime": 1652164522,
                    "expirationTime": 1652250908,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4865",
                    "makerAddr": "0x13928eB9A86c8278a45B6fF2935c7730b58AC675",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "24472",
                    "listingTime": 1652083913,
                    "expirationTime": 1654109351,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24472",
                    "makerAddr": "0x15053b688f69d742AD18f13f47d15E097E4A77e7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "4498",
                    "listingTime": 1652145564,
                    "expirationTime": 1654052907,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4498",
                    "makerAddr": "0xf29D4D7B3270beB3C54EE703fE2d9d17BF806C81",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "15702",
                    "listingTime": 1652095067,
                    "expirationTime": 1654169119,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15702",
                    "makerAddr": "0x4328D5d49dB3D1824a9C3A52013057E7cD5EDc12",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "27900",
                    "listingTime": 1652155277,
                    "expirationTime": 1652241637,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27900",
                    "makerAddr": "0xaB2Ecce87363531f0b9368B7A838117b9c57664b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "10748",
                    "listingTime": 1652168029,
                    "expirationTime": 1652254423,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10748",
                    "makerAddr": "0x68c4D9E03D7D902053C428Ca2D74b612Db7F583A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "28766",
                    "listingTime": 1652164538,
                    "expirationTime": 1652250908,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28766",
                    "makerAddr": "0x13928eB9A86c8278a45B6fF2935c7730b58AC675",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "1021",
                    "listingTime": 1652152767,
                    "expirationTime": 1652205600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1021",
                    "makerAddr": "0xB6A0026e788c5d259d88640450c3CAEa2B4D6a01",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "6373",
                    "listingTime": 1652129247,
                    "expirationTime": 1653391192,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6373",
                    "makerAddr": "0xE9FbEbb23e5CCC02EEe6E751adA3f3BbD20C630D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "28116",
                    "listingTime": 1652080407,
                    "expirationTime": 1652296887,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28116",
                    "makerAddr": "0x6AA73c8049675fEc5EBD7C5B6dEAf4ca10B8f500",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "23892",
                    "listingTime": 1652149239,
                    "expirationTime": 1652754015,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23892",
                    "makerAddr": "0x37Ef6420531D9ee6d662f1470fCe6a47e246D346",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "733",
                    "listingTime": 1652195873,
                    "expirationTime": 1652250600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/733",
                    "makerAddr": "0x2678154F769968FA676DD1C37F173F97B7d0b808",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "29630",
                    "listingTime": 1652150032,
                    "expirationTime": 1652236425,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29630",
                    "makerAddr": "0xde731672533D772C4a21bfA0353299C320151de8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "18432",
                    "listingTime": 1652078343,
                    "expirationTime": 1659854334,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18432",
                    "makerAddr": "0x2FE64f74Df8F5bef5DB73622bb58da79CA8C8B3b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "12840",
                    "listingTime": 1652153679,
                    "expirationTime": 1652758446,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12840",
                    "makerAddr": "0xB12Bf1701D8f9336bc89ca83Ee42f33aC21Cb18A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "205",
                    "listingTime": 1652095056,
                    "expirationTime": 1653981354,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/205",
                    "makerAddr": "0x53f42691DF74AD573F5B8a461EBEFe09ECddf920",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "9199",
                    "listingTime": 1652153697,
                    "expirationTime": 1653449081,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9199",
                    "makerAddr": "0x99d73D76f0058F4ae24882A49A0a9eA0f5a41268",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "26748",
                    "listingTime": 1652118344,
                    "expirationTime": 1654271043,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26748",
                    "makerAddr": "0x7502dE34c9ad924C6F0F4aa4A8113168cE5bB596",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "17366",
                    "listingTime": 1652173224,
                    "expirationTime": 1652432405,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17366",
                    "makerAddr": "0x71a19BC8338702EC60fcb04A057Cd44E177A2116",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "7142",
                    "listingTime": 1652091229,
                    "expirationTime": 1652774548,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7142",
                    "makerAddr": "0x5853dC7fE370FB7c600C3eAf0e27b800849cBB7e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "27620",
                    "listingTime": 1652068840,
                    "expirationTime": 1654114763,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27620",
                    "makerAddr": "0x3a69648665ADDf5D4E633CE5E6c8774241487041",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "8783",
                    "listingTime": 1652101123,
                    "expirationTime": 1652281773,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8783",
                    "makerAddr": "0xFA0f59502986865db617DA07CfeAa299729a70f8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "20534",
                    "listingTime": 1652089855,
                    "expirationTime": 1652349048,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20534",
                    "makerAddr": "0xd4745B170DeDe5eF451D46Fce6A111eE41f4047f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "20652",
                    "listingTime": 1652070257,
                    "expirationTime": 1652329439,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20652",
                    "makerAddr": "0x1338901827e3dee9506d0514F7746F306310F488",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "5582",
                    "listingTime": 1652105523,
                    "expirationTime": 1653603931,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5582",
                    "makerAddr": "0xA25D7e569393583f2a17007b5619452E25fB40b9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "7766",
                    "listingTime": 1652164527,
                    "expirationTime": 1652250908,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7766",
                    "makerAddr": "0x13928eB9A86c8278a45B6fF2935c7730b58AC675",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "2091",
                    "listingTime": 1652190064,
                    "expirationTime": 1652205600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2091",
                    "makerAddr": "0x96D2f13C453A7d210EA3CFc55f16C86cBC940cda",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "367",
                    "listingTime": 1651991224,
                    "expirationTime": 1659258851,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/367",
                    "makerAddr": "0xD099B763fa289F3eB695Cf69fd4102C6bf266E0d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "19856",
                    "listingTime": 1651850047,
                    "expirationTime": 1654441986,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19856",
                    "makerAddr": "0x391e3A871E75E041FC16c70D8204eF7c6E327401",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "10455",
                    "listingTime": 1652155013,
                    "expirationTime": 1652223300,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10455",
                    "makerAddr": "0xe00cbC035a7d0AE3c4327DFCA12B76407908D4D6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "20408",
                    "listingTime": 1652183951,
                    "expirationTime": 1652495458,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20408",
                    "makerAddr": "0xd3A9dccb4Cfd983fF69e688dDB6491E010301b7a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "5036",
                    "listingTime": 1652159858,
                    "expirationTime": 1652246251,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5036",
                    "makerAddr": "0x603C3DD337BB7a43E2Be1E88540a7E7Fc721D2c5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "2226",
                    "listingTime": 1651884062,
                    "expirationTime": 1652268073,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2226",
                    "makerAddr": "0x36602d9F655E88fF539bef1Acc4A731050C8346F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26000000000000000000",
                    "tokenId": "9",
                    "listingTime": 1652122035,
                    "expirationTime": 1652289299,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9",
                    "makerAddr": "0xc3a1528dF68DB9b0e1f28edd7B3c4958550702D1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26020000000000000000",
                    "tokenId": "4244",
                    "listingTime": 1651884729,
                    "expirationTime": 1653094254,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4244",
                    "makerAddr": "0x45B4dd02b7F855562534a8D84Ea74dd006e5Bb09",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26028000000000000000",
                    "tokenId": "1886",
                    "listingTime": 1651951785,
                    "expirationTime": 1652437306,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1886",
                    "makerAddr": "0x4089c8cFD527e14721e2066e24180B2103A2F514",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26220000000000000000",
                    "tokenId": "663",
                    "listingTime": 1652074703,
                    "expirationTime": 1654666515,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/663",
                    "makerAddr": "0x51A0c3aECAfDfBD9873E21173012d7520d8C6A35",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26250000000000000000",
                    "tokenId": "14898",
                    "listingTime": 1652091420,
                    "expirationTime": 1654653332,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14898",
                    "makerAddr": "0x8F6DB32F1175400Cb647CC33322B38397d5C75B8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26250000000000000000",
                    "tokenId": "5534",
                    "listingTime": 1652091465,
                    "expirationTime": 1654653255,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5534",
                    "makerAddr": "0x8F6DB32F1175400Cb647CC33322B38397d5C75B8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26300000000000000000",
                    "tokenId": "2037",
                    "listingTime": 1652157460,
                    "expirationTime": 1652540721,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2037",
                    "makerAddr": "0x77B691DeC38da63B60b4adB4c153A17C18e29Ce7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26300000000000000000",
                    "tokenId": "882",
                    "listingTime": 1652165774,
                    "expirationTime": 1654757744,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/882",
                    "makerAddr": "0x91F38a0cBEe27d8403f424C8C5383391b8f8265e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26300000000000000000",
                    "tokenId": "4089",
                    "listingTime": 1652165791,
                    "expirationTime": 1654757744,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4089",
                    "makerAddr": "0x91F38a0cBEe27d8403f424C8C5383391b8f8265e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26300000000000000000",
                    "tokenId": "12248",
                    "listingTime": 1652159564,
                    "expirationTime": 1652243473,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12248",
                    "makerAddr": "0xcA1bCc5AfDcc45E87B1B73AdCCa5863f01C46629",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26300000000000000000",
                    "tokenId": "20256",
                    "listingTime": 1652192728,
                    "expirationTime": 1652234400,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20256",
                    "makerAddr": "0xE4E4c9bb32e504e1264Cb27044f672725e949Fe2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26300000000000000000",
                    "tokenId": "23626",
                    "listingTime": 1652159514,
                    "expirationTime": 1652243473,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23626",
                    "makerAddr": "0xcA1bCc5AfDcc45E87B1B73AdCCa5863f01C46629",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26320000000000000000",
                    "tokenId": "17456",
                    "listingTime": 1652159422,
                    "expirationTime": 1652418511,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17456",
                    "makerAddr": "0x84ee9A0e7c7f979E2B7c51cD02C919aE9669f27e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26380000000000000000",
                    "tokenId": "9641",
                    "listingTime": 1652177122,
                    "expirationTime": 1652436319,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9641",
                    "makerAddr": "0x2f919f0EB53BecFEf31033CF68f23a57723a27c5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26400000000000000000",
                    "tokenId": "969",
                    "listingTime": 1652089614,
                    "expirationTime": 1652348791,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/969",
                    "makerAddr": "0xD73f73Ea3571a91093E491fe013298b2D7cAF230",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26400000000000000000",
                    "tokenId": "9984",
                    "listingTime": 1651853214,
                    "expirationTime": 1652362542,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9984",
                    "makerAddr": "0x5Ec0D096f8ef2Ac2dBd3536e3dFE2db1361BA6a7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26400000000000000000",
                    "tokenId": "8576",
                    "listingTime": 1651814203,
                    "expirationTime": 1653986165,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8576",
                    "makerAddr": "0x8f197EB762aAa442CA6e5207e43E719D7EdBb872",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26400000000000000000",
                    "tokenId": "77",
                    "listingTime": 1652096691,
                    "expirationTime": 1654082376,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/77",
                    "makerAddr": "0xAffca680e6dcbb1bb336108D5aCcA9c545885260",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26400000000000000000",
                    "tokenId": "21822",
                    "listingTime": 1652122282,
                    "expirationTime": 1652206589,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21822",
                    "makerAddr": "0xc3a1528dF68DB9b0e1f28edd7B3c4958550702D1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26460000000000000000",
                    "tokenId": "14638",
                    "listingTime": 1652095801,
                    "expirationTime": 1652273747,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14638",
                    "makerAddr": "0x7BaeA2342Cbd1009B65f259Ee574f6E8B0AB9DC8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26500000000000000000",
                    "tokenId": "14574",
                    "listingTime": 1652193393,
                    "expirationTime": 1652279400,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14574",
                    "makerAddr": "0x037ac7Fa0039DA18D9bDa7fA946c684358be4b52",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26500000000000000000",
                    "tokenId": "3676",
                    "listingTime": 1652038184,
                    "expirationTime": 1652370324,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3676",
                    "makerAddr": "0x458c1Adad9979b1eDa96a79C53ce5203762d5F18",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26500000000000000000",
                    "tokenId": "10580",
                    "listingTime": 1651913339,
                    "expirationTime": 1652518137,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10580",
                    "makerAddr": "0x3371f5c5cA2B7921e92F77177853F4F7341C8792",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26500000000000000000",
                    "tokenId": "20788",
                    "listingTime": 1652122072,
                    "expirationTime": 1652381264,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20788",
                    "makerAddr": "0xc3a1528dF68DB9b0e1f28edd7B3c4958550702D1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26500000000000000000",
                    "tokenId": "21820",
                    "listingTime": 1652186626,
                    "expirationTime": 1652273007,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21820",
                    "makerAddr": "0x79C58393c40f6498c5DA80336907e7B5594e92bC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26500000000000000000",
                    "tokenId": "8205",
                    "listingTime": 1652085319,
                    "expirationTime": 1652344513,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8205",
                    "makerAddr": "0xCaF07631229bdF0751D90fB55F6Bd9339dDb327c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26500000000000000000",
                    "tokenId": "9560",
                    "listingTime": 1652167030,
                    "expirationTime": 1652252400,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9560",
                    "makerAddr": "0x804388A4c3f5C3f5FaddDE0822cFDcD521505318",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26500000000000000000",
                    "tokenId": "16744",
                    "listingTime": 1651736925,
                    "expirationTime": 1654328918,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16744",
                    "makerAddr": "0x0F18C529B51851079b348BcFfD1A45C9426Fa65D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26500000000000000000",
                    "tokenId": "20534",
                    "listingTime": 1652011444,
                    "expirationTime": 1653396846,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20534",
                    "makerAddr": "0xd4745B170DeDe5eF451D46Fce6A111eE41f4047f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26500000000000000000",
                    "tokenId": "24482",
                    "listingTime": 1652045731,
                    "expirationTime": 1652304920,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24482",
                    "makerAddr": "0x84C94cC2fde3E20d9E8Dcb388c45a1fD6068cbf4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26500000000000000000",
                    "tokenId": "5783",
                    "listingTime": 1652080512,
                    "expirationTime": 1667215195,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5783",
                    "makerAddr": "0xCD750f5854eCE8252FB7d59D4a8Be7864241d50D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26500000000000000000",
                    "tokenId": "7654",
                    "listingTime": 1652055829,
                    "expirationTime": 1653265425,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7654",
                    "makerAddr": "0xAEbD5bbDdB31C7800A59cFd7382AbCA3878CD1Ea",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26500000000000000000",
                    "tokenId": "2156",
                    "listingTime": 1652167150,
                    "expirationTime": 1652253545,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2156",
                    "makerAddr": "0xaF3570DC9E88c8822bb71389840BA267aA060e13",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26500000000000000000",
                    "tokenId": "17614",
                    "listingTime": 1652186611,
                    "expirationTime": 1652273007,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17614",
                    "makerAddr": "0x79C58393c40f6498c5DA80336907e7B5594e92bC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26500000000000000000",
                    "tokenId": "17610",
                    "listingTime": 1652164358,
                    "expirationTime": 1652217358,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17610",
                    "makerAddr": "0x13928eB9A86c8278a45B6fF2935c7730b58AC675",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26500000000000000000",
                    "tokenId": "12531",
                    "listingTime": 1651915720,
                    "expirationTime": 1653467422,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12531",
                    "makerAddr": "0x4BC6fE8c58926f64388EE522757774C97787BCE0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26500000000000000000",
                    "tokenId": "6391",
                    "listingTime": 1652192000,
                    "expirationTime": 1652735045,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6391",
                    "makerAddr": "0x7Bb19A33ec9B3D501c0a6374f084682E9E000c43",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26500000000000000000",
                    "tokenId": "2119",
                    "listingTime": 1652146685,
                    "expirationTime": 1652233080,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2119",
                    "makerAddr": "0x369615Bc57975B06C418f42d3FDA3754A385B97b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "186",
                    "price": "26500000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651901703,
                    "expirationTime": 1654355185,
                    "makerAddr": "0x4295090f6ae11f6a1840db0bd1ed4d36f0d36a30",
                    "assetImageUrl": "https://img.seadn.io/files/66325d05f3e8d75fe2c452d1b74eab0c.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26550000000000000000",
                    "tokenId": "6403",
                    "listingTime": 1651800421,
                    "expirationTime": 1652360963,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6403",
                    "makerAddr": "0xF59742B240426FDc41d61954a49f55f2A377313D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26570000000000000000",
                    "tokenId": "9975",
                    "listingTime": 1652141536,
                    "expirationTime": 1652400708,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9975",
                    "makerAddr": "0xb38E8De8f3c2D2C9463C62826e1E9eEf4Be11B12",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26600000000000000000",
                    "tokenId": "3252",
                    "listingTime": 1652030139,
                    "expirationTime": 1653382631,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3252",
                    "makerAddr": "0x6B92686c40747C85809a6772D0eda8e22a77C60c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26600000000000000000",
                    "tokenId": "12462",
                    "listingTime": 1652169089,
                    "expirationTime": 1652255438,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12462",
                    "makerAddr": "0x7B036cd1dc0000639149f4889C23EB0a0dc99bBd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26600000000000000000",
                    "tokenId": "5454",
                    "listingTime": 1652152363,
                    "expirationTime": 1652219756,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5454",
                    "makerAddr": "0xA2B04c4221d7c6F66b22e535049ad5A72122d32C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26600000000000000000",
                    "tokenId": "8952",
                    "listingTime": 1652169068,
                    "expirationTime": 1652255438,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8952",
                    "makerAddr": "0x7B036cd1dc0000639149f4889C23EB0a0dc99bBd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "285",
                    "price": "26660000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651911734,
                    "expirationTime": 1654590206,
                    "makerAddr": "0x061ce8fa4b840afd7097983bbe6af5b036a13e9c",
                    "assetImageUrl": "https://img.seadn.io/files/a8cdc14549e1df80c434a258d7581538.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26690000000000000000",
                    "tokenId": "3091",
                    "listingTime": 1652082139,
                    "expirationTime": 1654095552,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3091",
                    "makerAddr": "0x1E4d44f00D42052326fBA19ecaD73846875B0557",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26690000000000000000",
                    "tokenId": "20516",
                    "listingTime": 1652082154,
                    "expirationTime": 1654264978,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20516",
                    "makerAddr": "0x1E4d44f00D42052326fBA19ecaD73846875B0557",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26700000000000000000",
                    "tokenId": "2025",
                    "listingTime": 1652166802,
                    "expirationTime": 1652253172,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2025",
                    "makerAddr": "0x0bF6b00518bDF72f4c68F9323369b0bE2FE7F9Aa",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26750000000000000000",
                    "tokenId": "5513",
                    "listingTime": 1652061489,
                    "expirationTime": 1654125141,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5513",
                    "makerAddr": "0xEC4762fb859a53c15D1689c08FD5E48C5c1C47Fa",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26760000000000000000",
                    "tokenId": "10752",
                    "listingTime": 1651963448,
                    "expirationTime": 1652568222,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10752",
                    "makerAddr": "0xa1870d3CaB6e9C4971e6286B2813CbC9E034E08c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26800000000000000000",
                    "tokenId": "14492",
                    "listingTime": 1652163870,
                    "expirationTime": 1652250257,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14492",
                    "makerAddr": "0x34E920051fD5a0D41D45d1490261F71d3677a688",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26800000000000000000",
                    "tokenId": "3503",
                    "listingTime": 1652195143,
                    "expirationTime": 1652259600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3503",
                    "makerAddr": "0x2DcC8aC3ca3A8771EE9F8F3d2ee2A1B485A737DA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26800000000000000000",
                    "tokenId": "13056",
                    "listingTime": 1652162844,
                    "expirationTime": 1653782871,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13056",
                    "makerAddr": "0xe315eB04Fc42bEa83Ed213582e66051050A92F1c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26800000000000000000",
                    "tokenId": "25702",
                    "listingTime": 1652162869,
                    "expirationTime": 1653799437,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25702",
                    "makerAddr": "0xe315eB04Fc42bEa83Ed213582e66051050A92F1c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26850000000000000000",
                    "tokenId": "16048",
                    "listingTime": 1652186037,
                    "expirationTime": 1652272408,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16048",
                    "makerAddr": "0xbBb9CC71EBd0057f3996d579B8710C5f6B7498Bf",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26880000000000000000",
                    "tokenId": "15978",
                    "listingTime": 1652066633,
                    "expirationTime": 1652228926,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15978",
                    "makerAddr": "0x435a35aaF01f70c486CB4F8Fb56d265DC41a0068",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "336",
                    "price": "26890000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652197027,
                    "expirationTime": 1652358691,
                    "makerAddr": "0x7176aa19e4b0c084c3114213d3fb94e526fd77f0",
                    "assetImageUrl": "https://img.seadn.io/files/c0319f983f8e8cad36fae926ea4a2597.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1411",
                    "price": "26890000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652193671,
                    "expirationTime": 1652358865,
                    "makerAddr": "0x7176aa19e4b0c084c3114213d3fb94e526fd77f0",
                    "assetImageUrl": "https://img.seadn.io/files/a694bec6094b0bfcc9dfde2e41673a21.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26900000000000000000",
                    "tokenId": "25620",
                    "listingTime": 1651857479,
                    "expirationTime": 1654449472,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25620",
                    "makerAddr": "0x7685274EeE0D73BB916F1e1c8d126967CA63dC21",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26900000000000000000",
                    "tokenId": "8815",
                    "listingTime": 1652166374,
                    "expirationTime": 1652771168,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8815",
                    "makerAddr": "0xa916Cb710Bf0D2c94011458C9496e99b62F725a4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26900000000000000000",
                    "tokenId": "2233",
                    "listingTime": 1652115421,
                    "expirationTime": 1652374615,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2233",
                    "makerAddr": "0x7151E5282bc1C952700fF070A933f17c644A66f6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3149",
                    "price": "26900000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652131575,
                    "expirationTime": 1652218061,
                    "makerAddr": "0x3333e1790a30fefb7d0c8e22675f0392c05fa640",
                    "assetImageUrl": "https://img.seadn.io/files/79ec7585687fc116067e13a4d8359e09.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "92",
                    "price": "26950000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652028656,
                    "expirationTime": 1654119633,
                    "makerAddr": "0x5407f352df3356b5b779e88ebf9908c920b0df69",
                    "assetImageUrl": "https://img.seadn.io/files/e2734c6a68ac8e652001d7a420feaa2e.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26960000000000000000",
                    "tokenId": "1944",
                    "listingTime": 1651995631,
                    "expirationTime": 1654587582,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1944",
                    "makerAddr": "0x9F4EbCb22447C64A21C687C1DB6849eC55B8F016",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26990000000000000000",
                    "tokenId": "29422",
                    "listingTime": 1652194182,
                    "expirationTime": 1652798975,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29422",
                    "makerAddr": "0xFb149F6d732B6061F67EBdf111d7828c31e40ff2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "26990000000000000000",
                    "tokenId": "8166",
                    "listingTime": 1652020439,
                    "expirationTime": 1652227022,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8166",
                    "makerAddr": "0x356c938a1d889D87999c75dC4e7aF1263b93e45D",
                    "assetImageUrl": ""
                }
            ],
            "total": 3.469197999999997e+21,
            "looksrare": 126,
            "opensea": 6
        },
        {
            "price": 27,
            "amount": 156,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "17490",
                    "listingTime": 1652046485,
                    "expirationTime": 1654638472,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17490",
                    "makerAddr": "0x8f197EB762aAa442CA6e5207e43E719D7EdBb872",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "4031",
                    "listingTime": 1651876527,
                    "expirationTime": 1652403841,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4031",
                    "makerAddr": "0xC3C42B7b4EC32682b3057B05b5117a76A774E166",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "5185",
                    "listingTime": 1652183569,
                    "expirationTime": 1652788343,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5185",
                    "makerAddr": "0xAA05563B84844f8f8bB1C9ab9ca1eA5F4fAF6fce",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "4810",
                    "listingTime": 1652076832,
                    "expirationTime": 1652617342,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4810",
                    "makerAddr": "0x68B7b6b1a2626AAEe01593eC8b5039f0d7Be1e47",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "11138",
                    "listingTime": 1652168610,
                    "expirationTime": 1654760601,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11138",
                    "makerAddr": "0x89AC0e87601e7606DF1d4a7364152E6165835197",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "2677",
                    "listingTime": 1651941475,
                    "expirationTime": 1654080054,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2677",
                    "makerAddr": "0xA6c5d40c321412D0A16E46a59B23C1Dfd9D54eE8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "7573",
                    "listingTime": 1652172748,
                    "expirationTime": 1652259135,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7573",
                    "makerAddr": "0x398851f0412decb07d5A1Ba900c352C1aAd7b432",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "28516",
                    "listingTime": 1651778567,
                    "expirationTime": 1653487398,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28516",
                    "makerAddr": "0x8b3477Ca7445ec524dd534fA4A596C993E93675a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "995",
                    "listingTime": 1652168001,
                    "expirationTime": 1652334284,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/995",
                    "makerAddr": "0xdc78107155918e230246439E4159FeA4c477eAE9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "1568",
                    "listingTime": 1652091955,
                    "expirationTime": 1653992137,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1568",
                    "makerAddr": "0x835ba4bA03713208Fa8404acd626466C9273F93c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "20355",
                    "listingTime": 1652173576,
                    "expirationTime": 1652206483,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20355",
                    "makerAddr": "0xd9d7046933Bd3d0A3E869959eF95e4C41522c1BF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "15226",
                    "listingTime": 1652190450,
                    "expirationTime": 1652449639,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15226",
                    "makerAddr": "0x8A4eDbd1A9004355038b54aec415EE797452851E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "5893",
                    "listingTime": 1652056637,
                    "expirationTime": 1653803334,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5893",
                    "makerAddr": "0x1FEACcB479834998BD7750754062347A6FaD8F9F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "3518",
                    "listingTime": 1652088418,
                    "expirationTime": 1652366170,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3518",
                    "makerAddr": "0x5d987F2F1689eCD4C21f1E73E2F12762570fdED5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "9088",
                    "listingTime": 1652027706,
                    "expirationTime": 1652292703,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9088",
                    "makerAddr": "0xf9bb1fe3dBcBA49F7Ab71485FA944eAE6605eD1f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "19406",
                    "listingTime": 1652189990,
                    "expirationTime": 1652276370,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19406",
                    "makerAddr": "0x3F985608005b6f7bc64B28313f7dA93eF99A557a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "1348",
                    "listingTime": 1652166284,
                    "expirationTime": 1652252678,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1348",
                    "makerAddr": "0x6a2Ad3B4E59CF63672f711FdC776227c76225Df4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "2574",
                    "listingTime": 1652086167,
                    "expirationTime": 1654075856,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2574",
                    "makerAddr": "0x3660d7E4Fd960a97239BC0655ef489E15E4d9151",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "4819",
                    "listingTime": 1652180245,
                    "expirationTime": 1654772242,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4819",
                    "makerAddr": "0xE7F53CE9421670AC2f11C5035E6f6f13d9829aa6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "9199",
                    "listingTime": 1652012105,
                    "expirationTime": 1653449081,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9199",
                    "makerAddr": "0x99d73D76f0058F4ae24882A49A0a9eA0f5a41268",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "9783",
                    "listingTime": 1652188171,
                    "expirationTime": 1653920342,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9783",
                    "makerAddr": "0xCF827110a45441ca7CE03dF4c590A56c48B5DE2f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "10754",
                    "listingTime": 1652187437,
                    "expirationTime": 1652273260,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10754",
                    "makerAddr": "0x9Fe176816F90145F8aEd9fFDedEE158ed8423bA2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "13842",
                    "listingTime": 1652187187,
                    "expirationTime": 1652223600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13842",
                    "makerAddr": "0x79905Ba011ff69423c65ef49D51ea64CAb49579F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "11774",
                    "listingTime": 1652029966,
                    "expirationTime": 1652634710,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11774",
                    "makerAddr": "0x5228bE0fDb6d86A7CCae080165F3Ac89aa14E9F9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "25218",
                    "listingTime": 1652173546,
                    "expirationTime": 1652259939,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25218",
                    "makerAddr": "0x71a19BC8338702EC60fcb04A057Cd44E177A2116",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "6448",
                    "listingTime": 1652167970,
                    "expirationTime": 1652334156,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6448",
                    "makerAddr": "0xdc78107155918e230246439E4159FeA4c477eAE9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "3903",
                    "listingTime": 1651904732,
                    "expirationTime": 1654276155,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3903",
                    "makerAddr": "0xe450D86A20CEAcF552F0E8e8ACbc573C7A91D80F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "2606",
                    "listingTime": 1652009608,
                    "expirationTime": 1652254899,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2606",
                    "makerAddr": "0xFa377EeD778F74266cFabCa542e61dCCb1215BC9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "10712",
                    "listingTime": 1652187266,
                    "expirationTime": 1654254566,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10712",
                    "makerAddr": "0x3d3FBE809eEA54E106E5B30EA2f5Dedbc94D8eE8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "9299",
                    "listingTime": 1652187708,
                    "expirationTime": 1653657671,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9299",
                    "makerAddr": "0xe7e4bfeEB83A5b752A4E90D4f89fa0B21FBB54b4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "398",
                    "listingTime": 1652170255,
                    "expirationTime": 1652245200,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/398",
                    "makerAddr": "0x8a039913605271CA31880E184dCC01FFc9dF87D0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "11204",
                    "listingTime": 1652193567,
                    "expirationTime": 1652259600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11204",
                    "makerAddr": "0xF4b753BC9b8DDE9EA6b1A061d2DC086b25885A06",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "10592",
                    "listingTime": 1651925343,
                    "expirationTime": 1654290631,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10592",
                    "makerAddr": "0xDF617Fc072215c638137B3038628b420064c06B2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "12456",
                    "listingTime": 1652168067,
                    "expirationTime": 1652427262,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12456",
                    "makerAddr": "0xdc78107155918e230246439E4159FeA4c477eAE9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "1135",
                    "listingTime": 1652078625,
                    "expirationTime": 1653809444,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1135",
                    "makerAddr": "0x2efBc44A485F13858A17CE33623e6C4c6AE7d01a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "6279",
                    "listingTime": 1652189940,
                    "expirationTime": 1652270757,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6279",
                    "makerAddr": "0x44c1D4AA76e9d83384f6BDdd547Ce30E4b7832a9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "4682",
                    "listingTime": 1652192718,
                    "expirationTime": 1667748186,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4682",
                    "makerAddr": "0x0a00D785fB387DAa35509aF75DC8b8517B842a55",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "10164",
                    "listingTime": 1652166576,
                    "expirationTime": 1654281405,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10164",
                    "makerAddr": "0x8c197dB132A18Cf2c0b46A1051D7c3E43F65a17b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "28770",
                    "listingTime": 1652172479,
                    "expirationTime": 1652431619,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28770",
                    "makerAddr": "0xD82a4DeB3D5B0A8d0B6742b6bD0Fc1F80efA08C7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "13006",
                    "listingTime": 1651845073,
                    "expirationTime": 1654223229,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13006",
                    "makerAddr": "0x56cfCAA14C2d63fef909E335733dE4C6330306a7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "23890",
                    "listingTime": 1652197850,
                    "expirationTime": 1652284237,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23890",
                    "makerAddr": "0xc1C9E5D91d212d1dFf008443b6C00a0f002b1A9C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "8245",
                    "listingTime": 1652166920,
                    "expirationTime": 1652253309,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8245",
                    "makerAddr": "0x6d7e93dC6D159BDAC80955BA90b40AdBF3aF2bc7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "3086",
                    "listingTime": 1651963848,
                    "expirationTime": 1652568635,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3086",
                    "makerAddr": "0x2D48024B86E16ac97928B2Af08791cb455546163",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "22290",
                    "listingTime": 1652077939,
                    "expirationTime": 1653205772,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22290",
                    "makerAddr": "0x493dd66Ff1e2484Fe21A31CD72B08292488A81A4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "20148",
                    "listingTime": 1652051732,
                    "expirationTime": 1654112029,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20148",
                    "makerAddr": "0x534c98c85714Df298524CF94c3d2Cd230B1EEa5B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "23316",
                    "listingTime": 1652185309,
                    "expirationTime": 1654600894,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23316",
                    "makerAddr": "0xaAB1e59A453f30eb52fdc2e5B3e13dcB3B1595c5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "8327",
                    "listingTime": 1652195480,
                    "expirationTime": 1652281875,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8327",
                    "makerAddr": "0x0C814c8afc6D8f4cb882B63Ea6fC19eE8E9E1dbD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "22017",
                    "listingTime": 1652143769,
                    "expirationTime": 1654735702,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22017",
                    "makerAddr": "0x45c572ED063815F784ca1987895DBD7AE8E611a0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "9742",
                    "listingTime": 1652166674,
                    "expirationTime": 1654758667,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9742",
                    "makerAddr": "0x914cD0594efADbA0ddabF56EE2e89317Bb6d9B93",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "9584",
                    "listingTime": 1652024418,
                    "expirationTime": 1653995241,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9584",
                    "makerAddr": "0x3dE262F19795845534A6e8D604d0Da383C2FCC9D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "7557",
                    "listingTime": 1652051661,
                    "expirationTime": 1653449297,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7557",
                    "makerAddr": "0x534c98c85714Df298524CF94c3d2Cd230B1EEa5B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "6189",
                    "listingTime": 1651861556,
                    "expirationTime": 1652413514,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6189",
                    "makerAddr": "0xE82151c961E7f91839417a236a45E8E560655B1a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "10308",
                    "listingTime": 1652173640,
                    "expirationTime": 1652260037,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10308",
                    "makerAddr": "0xd79a9865F5866760B77D7f82e35316662dEC6793",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "10602",
                    "listingTime": 1652197052,
                    "expirationTime": 1654789039,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10602",
                    "makerAddr": "0x8c2d18745E40681B585ddC82D2665B9073505A82",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "994",
                    "listingTime": 1652069182,
                    "expirationTime": 1652328374,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/994",
                    "makerAddr": "0xFa2618D0440aA98d12864D689F6577453F33D13D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "8894",
                    "listingTime": 1652194896,
                    "expirationTime": 1652281294,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8894",
                    "makerAddr": "0xCa230eA33613D4C775cbf2f4BD85959849257D25",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "19592",
                    "listingTime": 1652063110,
                    "expirationTime": 1654272206,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19592",
                    "makerAddr": "0xb10175B169292D36Fe11822653873171ad6Bb3DC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "25254",
                    "listingTime": 1652023715,
                    "expirationTime": 1652252043,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25254",
                    "makerAddr": "0x1256497A1b0729E167BC52E2b08EE23a888184C9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "3481",
                    "listingTime": 1651913023,
                    "expirationTime": 1654252816,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3481",
                    "makerAddr": "0x6A1BA3300800746eb55b84e7D1D913cEB76D489f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "8222",
                    "listingTime": 1652172827,
                    "expirationTime": 1652259222,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8222",
                    "makerAddr": "0x7E6A90d145bA102C09c83FDc8D7df84f8D1d818A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "15660",
                    "listingTime": 1652022449,
                    "expirationTime": 1652949997,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15660",
                    "makerAddr": "0x6fdd8c800CBb81790CD48A20F90ce7e725F89C27",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "26982",
                    "listingTime": 1652147372,
                    "expirationTime": 1652406565,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26982",
                    "makerAddr": "0x129311A1b838F10586AE0cd69C62a35871707cF2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "4378",
                    "listingTime": 1652166508,
                    "expirationTime": 1652252902,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4378",
                    "makerAddr": "0x926d2562Cd25E2a8988449F61271c19fF65d2C04",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "23902",
                    "listingTime": 1651881259,
                    "expirationTime": 1652486055,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23902",
                    "makerAddr": "0x4dCbB1fE5983ad5b44DC661273a4f11CA812f8B8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "4861",
                    "listingTime": 1652187219,
                    "expirationTime": 1652226900,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4861",
                    "makerAddr": "0x79905Ba011ff69423c65ef49D51ea64CAb49579F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "5547",
                    "listingTime": 1652166956,
                    "expirationTime": 1652253348,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5547",
                    "makerAddr": "0x6d7e93dC6D159BDAC80955BA90b40AdBF3aF2bc7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "4099",
                    "listingTime": 1651910368,
                    "expirationTime": 1654502286,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4099",
                    "makerAddr": "0x108794375c0D1EF0Bf3d52Eb3CD1f5c051628569",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "7635",
                    "listingTime": 1652190848,
                    "expirationTime": 1652795628,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7635",
                    "makerAddr": "0xF794159313D89e5338A425A0bFBDDB6cf69c3031",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "189",
                    "listingTime": 1652187310,
                    "expirationTime": 1654254566,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/189",
                    "makerAddr": "0x3d3FBE809eEA54E106E5B30EA2f5Dedbc94D8eE8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27000000000000000000",
                    "tokenId": "24110",
                    "listingTime": 1652190560,
                    "expirationTime": 1652449660,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24110",
                    "makerAddr": "0x287B4470439Bb96BccBfC97e956bdF9676b79674",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "764",
                    "price": "27000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652077799,
                    "expirationTime": 1667392137,
                    "makerAddr": "0xd867242ec03eeb70276025353ebad5dad7edb89f",
                    "assetImageUrl": "https://img.seadn.io/files/2f5e5490b0e5bf47acc7e5e482177749.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1050",
                    "price": "27000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651791486,
                    "expirationTime": 1654151325,
                    "makerAddr": "0xc56334c9c54d06dc73ad5576ea8a22c90806af8a",
                    "assetImageUrl": "https://img.seadn.io/files/bfd30a81b12e3453b69de5144bf943f2.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27010000000000000000",
                    "tokenId": "9685",
                    "listingTime": 1652172843,
                    "expirationTime": 1652868538,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9685",
                    "makerAddr": "0xF6a68db8A9912374657fE991Eae16dCF9f034e19",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27100000000000000000",
                    "tokenId": "1881",
                    "listingTime": 1652035107,
                    "expirationTime": 1652294033,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1881",
                    "makerAddr": "0x816F526075809713Edd8e9A344F8eBD44550904B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27100000000000000000",
                    "tokenId": "1840",
                    "listingTime": 1652084871,
                    "expirationTime": 1653995626,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1840",
                    "makerAddr": "0x112D352E59ed2165052F2fB00e00D3B4B87552fD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27100000000000000000",
                    "tokenId": "15214",
                    "listingTime": 1652194487,
                    "expirationTime": 1652277600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15214",
                    "makerAddr": "0x4C23E78D63db45315F30e418AC030A2986741Fd4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27100000000000000000",
                    "tokenId": "1441",
                    "listingTime": 1652193636,
                    "expirationTime": 1652280027,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1441",
                    "makerAddr": "0x8a92cd5f35Ca3C1c397C8c3abDC0ea65af74C491",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27100000000000000000",
                    "tokenId": "5579",
                    "listingTime": 1652173132,
                    "expirationTime": 1653397146,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5579",
                    "makerAddr": "0xD163bd16eaf4813D723F90f13fE4abeeECCdD3dd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27100000000000000000",
                    "tokenId": "16944",
                    "listingTime": 1652171382,
                    "expirationTime": 1652257778,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16944",
                    "makerAddr": "0x3e6dAE241C9e3Dd9B7746150c9F7778F828Dbd6d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27110000000000000000",
                    "tokenId": "20156",
                    "listingTime": 1652084790,
                    "expirationTime": 1652579538,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20156",
                    "makerAddr": "0xF9f02deF55E1ccEEDF017E0353c14Ba6F7F4d3Df",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27123000000000000000",
                    "tokenId": "6381",
                    "listingTime": 1652197250,
                    "expirationTime": 1652283601,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6381",
                    "makerAddr": "0xbf6c6b0d48f2c013f0C83a28A989C84BC1F64C66",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27160000000000000000",
                    "tokenId": "6236",
                    "listingTime": 1652176270,
                    "expirationTime": 1652262633,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6236",
                    "makerAddr": "0x335a5E6695a9D7Caf08547818f2F10b232aBBBBB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27160000000000000000",
                    "tokenId": "8461",
                    "listingTime": 1652170795,
                    "expirationTime": 1652257183,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8461",
                    "makerAddr": "0x9EC35C3499c092F2Be086C99F2745cab9Fd8bfBa",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27160000000000000000",
                    "tokenId": "15270",
                    "listingTime": 1652174647,
                    "expirationTime": 1652261039,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15270",
                    "makerAddr": "0xd6aa5A2E4646181CdF33367dEd5bAbf7F638880C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27200000000000000000",
                    "tokenId": "7074",
                    "listingTime": 1652196776,
                    "expirationTime": 1652214600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7074",
                    "makerAddr": "0x687124a1Ea04049A20f9a50B6722e1385C854262",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27200000000000000000",
                    "tokenId": "77",
                    "listingTime": 1652084463,
                    "expirationTime": 1654082376,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/77",
                    "makerAddr": "0xAffca680e6dcbb1bb336108D5aCcA9c545885260",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "177",
                    "price": "27200000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651677220,
                    "expirationTime": 1654355715,
                    "makerAddr": "0x8be35ca7f8cf057ef0857276acc578c0a2e7a027",
                    "assetImageUrl": "https://img.seadn.io/files/2fb888948a8c48a280ea21cd136a1e12.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27250000000000000000",
                    "tokenId": "17038",
                    "listingTime": 1652189505,
                    "expirationTime": 1652275877,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17038",
                    "makerAddr": "0x494eb06758e268aF745CC3133b6F5f6e8b9EcB33",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27290000000000000000",
                    "tokenId": "20206",
                    "listingTime": 1652191063,
                    "expirationTime": 1652449910,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20206",
                    "makerAddr": "0x28d8C3f9A65267475a273b861d475A42a8c3f8c3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27400000000000000000",
                    "tokenId": "18752",
                    "listingTime": 1652122410,
                    "expirationTime": 1652206433,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18752",
                    "makerAddr": "0xc3a1528dF68DB9b0e1f28edd7B3c4958550702D1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27500000000000000000",
                    "tokenId": "3714",
                    "listingTime": 1652181229,
                    "expirationTime": 1652218260,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3714",
                    "makerAddr": "0x55C16de0ffbDfc3d50c365149a2Fbc6539Cf0ef3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27500000000000000000",
                    "tokenId": "4400",
                    "listingTime": 1652077943,
                    "expirationTime": 1653170578,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4400",
                    "makerAddr": "0x61a4FC9028048e562b2a49d8dccf2378B644dF03",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27500000000000000000",
                    "tokenId": "19182",
                    "listingTime": 1651998914,
                    "expirationTime": 1652603659,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19182",
                    "makerAddr": "0x8eb2A1EFe86309c89d831Bdd33B4E19FFE903aD3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27500000000000000000",
                    "tokenId": "25262",
                    "listingTime": 1651940071,
                    "expirationTime": 1654022350,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25262",
                    "makerAddr": "0x80dE2F874067B092BcB914144EFBe1B54A731F4d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27500000000000000000",
                    "tokenId": "2482",
                    "listingTime": 1652079573,
                    "expirationTime": 1653895721,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2482",
                    "makerAddr": "0x8fa687316Ca25CC295F96806D29aa5075A4E5427",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27500000000000000000",
                    "tokenId": "6629",
                    "listingTime": 1652070653,
                    "expirationTime": 1654575908,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6629",
                    "makerAddr": "0x7c744ae8a87fF460E7860EF344612383b1863182",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27500000000000000000",
                    "tokenId": "9926",
                    "listingTime": 1652183032,
                    "expirationTime": 1652205600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9926",
                    "makerAddr": "0x5EdB20328701cA0a9e0d1bFAa87504e0df04856c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27500000000000000000",
                    "tokenId": "5271",
                    "listingTime": 1651923248,
                    "expirationTime": 1654320528,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5271",
                    "makerAddr": "0x302Fe0b28F7E8e545Ef3fD7C184e754e3C74b379",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27500000000000000000",
                    "tokenId": "21036",
                    "listingTime": 1652198033,
                    "expirationTime": 1652284430,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21036",
                    "makerAddr": "0x6F7aA46c31e9395677bB59AE7F984D9309A969fc",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27500000000000000000",
                    "tokenId": "10578",
                    "listingTime": 1652070720,
                    "expirationTime": 1652308590,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10578",
                    "makerAddr": "0x34cCc716Fa3f6c667dae0E6D9b1e0a9418873b8a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27500000000000000000",
                    "tokenId": "7342",
                    "listingTime": 1652132877,
                    "expirationTime": 1652219272,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7342",
                    "makerAddr": "0x0667640Ab57CB909B343157d718651eA49141A75",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27500000000000000000",
                    "tokenId": "10516",
                    "listingTime": 1652070705,
                    "expirationTime": 1652308590,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10516",
                    "makerAddr": "0x34cCc716Fa3f6c667dae0E6D9b1e0a9418873b8a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27500000000000000000",
                    "tokenId": "7415",
                    "listingTime": 1652197825,
                    "expirationTime": 1652284222,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7415",
                    "makerAddr": "0x6F7aA46c31e9395677bB59AE7F984D9309A969fc",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27500000000000000000",
                    "tokenId": "8416",
                    "listingTime": 1652007323,
                    "expirationTime": 1652266503,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8416",
                    "makerAddr": "0xf1f8145518c19Bf9BADFaEF4977d35B96DB74c09",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27500000000000000000",
                    "tokenId": "5513",
                    "listingTime": 1652050190,
                    "expirationTime": 1654125141,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5513",
                    "makerAddr": "0xEC4762fb859a53c15D1689c08FD5E48C5c1C47Fa",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27500000000000000000",
                    "tokenId": "79",
                    "listingTime": 1652068307,
                    "expirationTime": 1654047378,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/79",
                    "makerAddr": "0x1278Bf1cA85Bfe406e23502700C7Cb94eA36B21A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27500000000000000000",
                    "tokenId": "27580",
                    "listingTime": 1652185521,
                    "expirationTime": 1652226300,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27580",
                    "makerAddr": "0x5eE0dF1fA841c84646cd49F0753B5A86F1d07F4C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27500000000000000000",
                    "tokenId": "1652",
                    "listingTime": 1652187454,
                    "expirationTime": 1652792251,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1652",
                    "makerAddr": "0x2a8E8C123753973f4aD5801Bc05f86c2f6EeA172",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27500000000000000000",
                    "tokenId": "8133",
                    "listingTime": 1652030356,
                    "expirationTime": 1652635107,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8133",
                    "makerAddr": "0x9ec60b1557f9B66fC23f6e6A76988c866A812c39",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27500000000000000000",
                    "tokenId": "28638",
                    "listingTime": 1652180519,
                    "expirationTime": 1652266903,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28638",
                    "makerAddr": "0xcce55e8d17968af05c590c5C96342c297ed67A24",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27500000000000000000",
                    "tokenId": "11908",
                    "listingTime": 1652179691,
                    "expirationTime": 1652202180,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11908",
                    "makerAddr": "0x7bBCbe65F66FFAF252CCe8c973868C41Bd27B61A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1385",
                    "price": "27500000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652027931,
                    "expirationTime": 1654522777,
                    "makerAddr": "0x993da52a2a23ea8e3752d6739099c80d77f0b752",
                    "assetImageUrl": "https://img.seadn.io/files/008968378a72acf3d30076818cc1e533.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27600000000000000000",
                    "tokenId": "16198",
                    "listingTime": 1652183332,
                    "expirationTime": 1654775193,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16198",
                    "makerAddr": "0x955277A438d84D524a0FC96231A7eFb17194D6f9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27690000000000000000",
                    "tokenId": "9822",
                    "listingTime": 1652187500,
                    "expirationTime": 1652729539,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9822",
                    "makerAddr": "0x39071c01b1AD500f629a3Ef26871dCe3ae09aA10",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27690000000000000000",
                    "tokenId": "12248",
                    "listingTime": 1652157102,
                    "expirationTime": 1652243473,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12248",
                    "makerAddr": "0xcA1bCc5AfDcc45E87B1B73AdCCa5863f01C46629",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27690000000000000000",
                    "tokenId": "3518",
                    "listingTime": 1652079572,
                    "expirationTime": 1652366170,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3518",
                    "makerAddr": "0x5d987F2F1689eCD4C21f1E73E2F12762570fdED5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27690000000000000000",
                    "tokenId": "23626",
                    "listingTime": 1652157092,
                    "expirationTime": 1652243473,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23626",
                    "makerAddr": "0xcA1bCc5AfDcc45E87B1B73AdCCa5863f01C46629",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2723",
                    "price": "27690000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651899577,
                    "expirationTime": 1654484838,
                    "makerAddr": "0x8101a48a18e4a377a09028306d32fc2c9502cc4e",
                    "assetImageUrl": "https://img.seadn.io/files/16f20e5eba85ea711a8d80c353d91e67.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27700000000000000000",
                    "tokenId": "17052",
                    "listingTime": 1652197440,
                    "expirationTime": 1652456633,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17052",
                    "makerAddr": "0xe6b801fE379177024BFB2e586d81a0B068d36ec7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27700000000000000000",
                    "tokenId": "6879",
                    "listingTime": 1652079596,
                    "expirationTime": 1653364765,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6879",
                    "makerAddr": "0x0c338bFEF0805184a72FA462E60a51198802fc4A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27700000000000000000",
                    "tokenId": "12160",
                    "listingTime": 1652184013,
                    "expirationTime": 1652270407,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12160",
                    "makerAddr": "0xeD4d04BeC042c783840978D5E0013E1855573FB4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27700000000000000000",
                    "tokenId": "25408",
                    "listingTime": 1652184643,
                    "expirationTime": 1654776630,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25408",
                    "makerAddr": "0x619CEDb487f8001889c42672D7C772e3E671673b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27700000000000000000",
                    "tokenId": "2704",
                    "listingTime": 1652079545,
                    "expirationTime": 1653404223,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2704",
                    "makerAddr": "0x5181aAEfA633db3539382f8A19fc62E2Fb11975f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27750000000000000000",
                    "tokenId": "3526",
                    "listingTime": 1651949745,
                    "expirationTime": 1652341341,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3526",
                    "makerAddr": "0xAE418c8FE6180DA0bBAE6742915452Ff75186cE0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27770000000000000000",
                    "tokenId": "6403",
                    "listingTime": 1651756280,
                    "expirationTime": 1652360963,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6403",
                    "makerAddr": "0xF59742B240426FDc41d61954a49f55f2A377313D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27770000000000000000",
                    "tokenId": "28472",
                    "listingTime": 1652009148,
                    "expirationTime": 1652268342,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28472",
                    "makerAddr": "0x723BC33B12e33445d1d156E38e049024bB1807cE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27770000000000000000",
                    "tokenId": "1925",
                    "listingTime": 1652074589,
                    "expirationTime": 1654151300,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1925",
                    "makerAddr": "0xB17D5DB0EC93331271Ed2f3fFfEBE4E5b790D97E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27790000000000000000",
                    "tokenId": "20206",
                    "listingTime": 1652190714,
                    "expirationTime": 1652449910,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20206",
                    "makerAddr": "0x28d8C3f9A65267475a273b861d475A42a8c3f8c3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27800000000000000000",
                    "tokenId": "2482",
                    "listingTime": 1652079276,
                    "expirationTime": 1653895721,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2482",
                    "makerAddr": "0x8fa687316Ca25CC295F96806D29aa5075A4E5427",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27800000000000000000",
                    "tokenId": "21654",
                    "listingTime": 1652180610,
                    "expirationTime": 1654772554,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21654",
                    "makerAddr": "0xA5836A1f1B6dbD88248F960cD83dcb2BDE1C92ae",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27800000000000000000",
                    "tokenId": "1896",
                    "listingTime": 1652180599,
                    "expirationTime": 1654772554,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1896",
                    "makerAddr": "0xA5836A1f1B6dbD88248F960cD83dcb2BDE1C92ae",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27800000000000000000",
                    "tokenId": "9214",
                    "listingTime": 1652180503,
                    "expirationTime": 1654384804,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9214",
                    "makerAddr": "0x10F11718f2bb21ad75eBb006EdBc080907C45777",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27800000000000000000",
                    "tokenId": "15628",
                    "listingTime": 1652078098,
                    "expirationTime": 1654670072,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15628",
                    "makerAddr": "0x769c5E2b3FbD61943BE00EFbFe9A94C03E0bbD2c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27800000000000000000",
                    "tokenId": "3489",
                    "listingTime": 1652190916,
                    "expirationTime": 1652223600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3489",
                    "makerAddr": "0xB627d47F80974cf3eD50e00C15965909b1E07E15",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27800000000000000000",
                    "tokenId": "2667",
                    "listingTime": 1652190016,
                    "expirationTime": 1652651283,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2667",
                    "makerAddr": "0x44c1D4AA76e9d83384f6BDdd547Ce30E4b7832a9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27830000000000000000",
                    "tokenId": "1946",
                    "listingTime": 1652180441,
                    "expirationTime": 1652266823,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1946",
                    "makerAddr": "0x10a2DFb788a57587E6deAd219fb2829B8eAd9D7b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27830000000000000000",
                    "tokenId": "8619",
                    "listingTime": 1652180428,
                    "expirationTime": 1652266823,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8619",
                    "makerAddr": "0x10a2DFb788a57587E6deAd219fb2829B8eAd9D7b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27830000000000000000",
                    "tokenId": "8618",
                    "listingTime": 1652180437,
                    "expirationTime": 1652266823,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8618",
                    "makerAddr": "0x10a2DFb788a57587E6deAd219fb2829B8eAd9D7b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27830000000000000000",
                    "tokenId": "5925",
                    "listingTime": 1652180433,
                    "expirationTime": 1652266823,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5925",
                    "makerAddr": "0x10a2DFb788a57587E6deAd219fb2829B8eAd9D7b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27830000000000000000",
                    "tokenId": "8351",
                    "listingTime": 1652188182,
                    "expirationTime": 1652227800,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8351",
                    "makerAddr": "0xEde3A2a62C74F3CbD0512Fc15c9B0aBca4049e83",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27830000000000000000",
                    "tokenId": "24754",
                    "listingTime": 1652188216,
                    "expirationTime": 1652227800,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24754",
                    "makerAddr": "0xEde3A2a62C74F3CbD0512Fc15c9B0aBca4049e83",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27880000000000000000",
                    "tokenId": "28318",
                    "listingTime": 1652009298,
                    "expirationTime": 1652268494,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28318",
                    "makerAddr": "0x87d0D4f67CF534FFbdd75aE0472Ca8951642c9BF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27880000000000000000",
                    "tokenId": "21538",
                    "listingTime": 1652017882,
                    "expirationTime": 1652277077,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21538",
                    "makerAddr": "0x773D5532eDf521CE1FfDe48895930F0e97439cBc",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27880000000000000000",
                    "tokenId": "13622",
                    "listingTime": 1652017843,
                    "expirationTime": 1652277038,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13622",
                    "makerAddr": "0x773D5532eDf521CE1FfDe48895930F0e97439cBc",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "tokenId": "25340@-@25256@-@6927@-@24896@-@3176@-@3364@-@8201@-@9725@-@12630@-@17492@-@21640@-@22488@-@24406@-@5512@-@25706@-@833@-@29676@-@16656",
                    "price": 27899703883485830000,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27900000000000000000",
                    "tokenId": "3518",
                    "listingTime": 1652079180,
                    "expirationTime": 1652366170,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3518",
                    "makerAddr": "0x5d987F2F1689eCD4C21f1E73E2F12762570fdED5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27900000000000000000",
                    "tokenId": "3676",
                    "listingTime": 1651941001,
                    "expirationTime": 1652370324,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3676",
                    "makerAddr": "0x458c1Adad9979b1eDa96a79C53ce5203762d5F18",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27900000000000000000",
                    "tokenId": "5633",
                    "listingTime": 1652196247,
                    "expirationTime": 1654788206,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5633",
                    "makerAddr": "0xad73BB169866ac5d9542AF250574A1C3D83C6295",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27920000000000000000",
                    "tokenId": "1886",
                    "listingTime": 1651903778,
                    "expirationTime": 1652437306,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1886",
                    "makerAddr": "0x4089c8cFD527e14721e2066e24180B2103A2F514",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27950000000000000000",
                    "tokenId": "2642",
                    "listingTime": 1652082127,
                    "expirationTime": 1652341315,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2642",
                    "makerAddr": "0x5d23B4ea39E274b23fdEa9bFDb57b032AcEd47d4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27950000000000000000",
                    "tokenId": "2641",
                    "listingTime": 1652082124,
                    "expirationTime": 1652341315,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2641",
                    "makerAddr": "0x5d23B4ea39E274b23fdEa9bFDb57b032AcEd47d4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27950000000000000000",
                    "tokenId": "2636",
                    "listingTime": 1652082129,
                    "expirationTime": 1652341315,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2636",
                    "makerAddr": "0x5d23B4ea39E274b23fdEa9bFDb57b032AcEd47d4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27950000000000000000",
                    "tokenId": "2639",
                    "listingTime": 1652082125,
                    "expirationTime": 1652341315,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2639",
                    "makerAddr": "0x5d23B4ea39E274b23fdEa9bFDb57b032AcEd47d4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27950000000000000000",
                    "tokenId": "5270",
                    "listingTime": 1652082121,
                    "expirationTime": 1652341315,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5270",
                    "makerAddr": "0x5d23B4ea39E274b23fdEa9bFDb57b032AcEd47d4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "27990000000000000000",
                    "tokenId": "8166",
                    "listingTime": 1651967826,
                    "expirationTime": 1652227022,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8166",
                    "makerAddr": "0x356c938a1d889D87999c75dC4e7aF1263b93e45D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1458",
                    "price": "27990000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652193753,
                    "expirationTime": 1652280243,
                    "makerAddr": "0x8d48e5794e054001db3dc5c9e94050299e6d1636",
                    "assetImageUrl": "https://img.seadn.io/files/e728edf601d2694e94330ae57b84767a.png?auto=format"
                }
            ],
            "total": 4.2617327038834887e+21,
            "looksrare": 149,
            "opensea": 6,
            "nftx": 1
        },
        {
            "price": 28,
            "amount": 152,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "3900",
                    "listingTime": 1652056350,
                    "expirationTime": 1667608343,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3900",
                    "makerAddr": "0x53d16d913005E9D9cb21f27a7899C558e28Ac459",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "7960",
                    "listingTime": 1651923330,
                    "expirationTime": 1653869864,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7960",
                    "makerAddr": "0xED525b5D5CD5BE436c026ee127A85A682213ae3B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "26706",
                    "listingTime": 1652004597,
                    "expirationTime": 1654596585,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26706",
                    "makerAddr": "0x90a8734076d7A7Db4303feD64Cf46467069f11d8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "665",
                    "listingTime": 1651918721,
                    "expirationTime": 1667470718,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/665",
                    "makerAddr": "0xF7480A958bFA8EF59C52CA995C1d3518968ce2d9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "9584",
                    "listingTime": 1652008781,
                    "expirationTime": 1653995241,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9584",
                    "makerAddr": "0x3dE262F19795845534A6e8D604d0Da383C2FCC9D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "5118",
                    "listingTime": 1652024205,
                    "expirationTime": 1654327655,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5118",
                    "makerAddr": "0xb883C906332e94Cd8CE52C846921a220Bb8EDef2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "9320",
                    "listingTime": 1652192202,
                    "expirationTime": 1652277600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9320",
                    "makerAddr": "0x5d0465f641aD1efFDFEf661e7FD100c023d070F4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "8850",
                    "listingTime": 1652074303,
                    "expirationTime": 1652308620,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8850",
                    "makerAddr": "0x0c2dB7Cb6D59B0f68c72480460b1d225E71740B4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "29534",
                    "listingTime": 1652057460,
                    "expirationTime": 1654123279,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29534",
                    "makerAddr": "0x268993C1923Cf4d6db408f5109A53FCf17ed4039",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "5383",
                    "listingTime": 1652055411,
                    "expirationTime": 1653823241,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5383",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "2270",
                    "listingTime": 1651929766,
                    "expirationTime": 1654163422,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2270",
                    "makerAddr": "0x75872C384475117E8C19BA27E1fBE8473aF9ebC5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "5579",
                    "listingTime": 1651925606,
                    "expirationTime": 1653397146,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5579",
                    "makerAddr": "0xD163bd16eaf4813D723F90f13fE4abeeECCdD3dd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "18108",
                    "listingTime": 1651924620,
                    "expirationTime": 1654267015,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18108",
                    "makerAddr": "0xeBf7c7932D86907C3BC1Bd6d486308637a8264A3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "18982",
                    "listingTime": 1652196478,
                    "expirationTime": 1652231400,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18982",
                    "makerAddr": "0x6CF5b4D7132f34B73a6b4F3e35075f844e057529",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "17610",
                    "listingTime": 1652074398,
                    "expirationTime": 1652217358,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17610",
                    "makerAddr": "0x13928eB9A86c8278a45B6fF2935c7730b58AC675",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "20634",
                    "listingTime": 1652192294,
                    "expirationTime": 1652365020,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20634",
                    "makerAddr": "0xd8Acf684AE5321275C6Dc5B5661cA67E1949BF22",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "2007",
                    "listingTime": 1651845109,
                    "expirationTime": 1654223229,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2007",
                    "makerAddr": "0x56cfCAA14C2d63fef909E335733dE4C6330306a7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "14728",
                    "listingTime": 1651794188,
                    "expirationTime": 1654386127,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14728",
                    "makerAddr": "0x17bB4076ab59e0EF20AD5a873AB4b5341Bf01b78",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "6681",
                    "listingTime": 1651998350,
                    "expirationTime": 1652603066,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6681",
                    "makerAddr": "0xE65CD95bC3A66CcDC15584aB90Dfe2Fb5E0157fF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "6879",
                    "listingTime": 1652070432,
                    "expirationTime": 1653364765,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6879",
                    "makerAddr": "0x0c338bFEF0805184a72FA462E60a51198802fc4A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "3613",
                    "listingTime": 1651870876,
                    "expirationTime": 1666469638,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3613",
                    "makerAddr": "0x25Ca2e79391AD17A73395f88985d33C121dF7d06",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "5783",
                    "listingTime": 1651925055,
                    "expirationTime": 1667215195,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5783",
                    "makerAddr": "0xCD750f5854eCE8252FB7d59D4a8Be7864241d50D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "2704",
                    "listingTime": 1652068914,
                    "expirationTime": 1653404223,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2704",
                    "makerAddr": "0x5181aAEfA633db3539382f8A19fc62E2Fb11975f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "29734",
                    "listingTime": 1651923700,
                    "expirationTime": 1653621252,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29734",
                    "makerAddr": "0x49836dbdbEA45b90ED274F0EB688E3Cc7908D31D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "5726",
                    "listingTime": 1652158208,
                    "expirationTime": 1652241540,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5726",
                    "makerAddr": "0xD2d18409949978F22948F76Ae584ebc09115145b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "190",
                    "listingTime": 1652073299,
                    "expirationTime": 1652328346,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/190",
                    "makerAddr": "0x2e5EE0f23b930b4C12217d914ce0d1C9F794e284",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "995",
                    "listingTime": 1652075090,
                    "expirationTime": 1652334284,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/995",
                    "makerAddr": "0xdc78107155918e230246439E4159FeA4c477eAE9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "16266",
                    "listingTime": 1652192241,
                    "expirationTime": 1652277600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16266",
                    "makerAddr": "0xB4d76590E76259E5E5c754FCA12095a575E7FA97",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "15628",
                    "listingTime": 1652078070,
                    "expirationTime": 1654670063,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15628",
                    "makerAddr": "0x769c5E2b3FbD61943BE00EFbFe9A94C03E0bbD2c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "16198",
                    "listingTime": 1652183223,
                    "expirationTime": 1654775193,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16198",
                    "makerAddr": "0x955277A438d84D524a0FC96231A7eFb17194D6f9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "23356",
                    "listingTime": 1651966575,
                    "expirationTime": 1653591186,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23356",
                    "makerAddr": "0x8B9Cc341084337c0A5828BC0D5E20dADB197d991",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "26258",
                    "listingTime": 1652024973,
                    "expirationTime": 1654616958,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26258",
                    "makerAddr": "0x43E9F6F4651F69Cbeaf25d202915705BF63a4d8F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "6046",
                    "listingTime": 1652055358,
                    "expirationTime": 1653401649,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6046",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "28018",
                    "listingTime": 1652025638,
                    "expirationTime": 1652284833,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28018",
                    "makerAddr": "0xa5855f4F40a409fFDe3a171eB616926AEc5D7B9C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "8576",
                    "listingTime": 1651686436,
                    "expirationTime": 1653986165,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8576",
                    "makerAddr": "0x8f197EB762aAa442CA6e5207e43E719D7EdBb872",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "7647",
                    "listingTime": 1651993122,
                    "expirationTime": 1652597866,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7647",
                    "makerAddr": "0x1E675330b76970F16C46Bc1e79249271677b0244",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "29146",
                    "listingTime": 1651889544,
                    "expirationTime": 1653982098,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29146",
                    "makerAddr": "0xE3813D26d44BcB6C40f29e5487dfF5434c629051",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "12010",
                    "listingTime": 1651870838,
                    "expirationTime": 1667118871,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12010",
                    "makerAddr": "0x25Ca2e79391AD17A73395f88985d33C121dF7d06",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "6250",
                    "listingTime": 1651797736,
                    "expirationTime": 1653485399,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6250",
                    "makerAddr": "0x989cE0d5f551d94EC87E638Dc643c014e63a0c0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "25864",
                    "listingTime": 1652065308,
                    "expirationTime": 1654275661,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25864",
                    "makerAddr": "0x8cc24CF5970F9c7AA94aB00Fbf9e6E86fd01c41B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "23174",
                    "listingTime": 1652063100,
                    "expirationTime": 1658677747,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23174",
                    "makerAddr": "0x7e2225B3e54A7ad0f2D009237a4f136C02F90a52",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "6129",
                    "listingTime": 1652194314,
                    "expirationTime": 1652453447,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6129",
                    "makerAddr": "0x40b9e4662bdAD417DdC0B7eE7ecf11AdFD1a6E7f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "8783",
                    "listingTime": 1652056229,
                    "expirationTime": 1652281773,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8783",
                    "makerAddr": "0xFA0f59502986865db617DA07CfeAa299729a70f8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "11270",
                    "listingTime": 1651900657,
                    "expirationTime": 1654304342,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11270",
                    "makerAddr": "0x843f2C19bc6df9E32B482E2F9ad6C078001088b1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "7904",
                    "listingTime": 1651969334,
                    "expirationTime": 1654184803,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7904",
                    "makerAddr": "0x727F25672f4F2815831ED496c87B33faEb639238",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "556",
                    "listingTime": 1652193104,
                    "expirationTime": 1652277600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/556",
                    "makerAddr": "0xC8baD5520643067D1C313a94749ac0199a16A384",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "3724",
                    "listingTime": 1651922222,
                    "expirationTime": 1653995981,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3724",
                    "makerAddr": "0x31D447ccF5ed1905287Ca964b72F5fef01682944",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "14780",
                    "listingTime": 1651907718,
                    "expirationTime": 1652512511,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14780",
                    "makerAddr": "0xDaC920A79D2c49ffe2a88Aa3185a99d7B382E37a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "10164",
                    "listingTime": 1652071452,
                    "expirationTime": 1654281405,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10164",
                    "makerAddr": "0x8c197dB132A18Cf2c0b46A1051D7c3E43F65a17b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "15244",
                    "listingTime": 1652070269,
                    "expirationTime": 1654219385,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15244",
                    "makerAddr": "0x28570D9d66627e0733dFE4FCa79B8fD5b8128636",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "19210",
                    "listingTime": 1652052683,
                    "expirationTime": 1652886452,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19210",
                    "makerAddr": "0x3071A71194c5b128C6E96BfB55b35C1abd82e363",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "21546",
                    "listingTime": 1652193014,
                    "expirationTime": 1652277600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21546",
                    "makerAddr": "0xC8baD5520643067D1C313a94749ac0199a16A384",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "17800",
                    "listingTime": 1651893629,
                    "expirationTime": 1654067857,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17800",
                    "makerAddr": "0x63b2c57D8AD53397388E16787bd641507cDdb8d0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "872",
                    "listingTime": 1652197255,
                    "expirationTime": 1652283623,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/872",
                    "makerAddr": "0x039d3ECE7a18986661FB33d1e3E1f6a30C7F93e0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "2015",
                    "listingTime": 1652030686,
                    "expirationTime": 1653382378,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2015",
                    "makerAddr": "0x6B92686c40747C85809a6772D0eda8e22a77C60c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "295",
                    "listingTime": 1651875459,
                    "expirationTime": 1654467447,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/295",
                    "makerAddr": "0x6663EedFF31B797382F9020dBC16Fc6992CF32c7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "5449",
                    "listingTime": 1652055514,
                    "expirationTime": 1652314710,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5449",
                    "makerAddr": "0x09fD1ec7F2De0F0D497FdEf65361b436C7D53b83",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "2034",
                    "listingTime": 1652029218,
                    "expirationTime": 1652232600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2034",
                    "makerAddr": "0x8b331D46A5f306E45d0Af12DDab91DcE46c93F27",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "1051",
                    "listingTime": 1651931810,
                    "expirationTime": 1652536586,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1051",
                    "makerAddr": "0xdD5991b9747d1F6D292eD2c57C4D5DC48b9f8e3a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "26282",
                    "listingTime": 1651867234,
                    "expirationTime": 1654459191,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26282",
                    "makerAddr": "0xE61C2251b2641989F49bB5b73B2a8D0dbC6E40D8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "2677",
                    "listingTime": 1651871934,
                    "expirationTime": 1654080054,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2677",
                    "makerAddr": "0xA6c5d40c321412D0A16E46a59B23C1Dfd9D54eE8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "1881",
                    "listingTime": 1652034846,
                    "expirationTime": 1652294033,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1881",
                    "makerAddr": "0x816F526075809713Edd8e9A344F8eBD44550904B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "2309",
                    "listingTime": 1652141745,
                    "expirationTime": 1652400942,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2309",
                    "makerAddr": "0xE02Fd90f7bb205c7542A152Aaa14f7DDa005afdC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "4062",
                    "listingTime": 1651853690,
                    "expirationTime": 1653431426,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4062",
                    "makerAddr": "0xa4f38C92E2a3D3153075859f493083d05CA3540d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "28660",
                    "listingTime": 1652060263,
                    "expirationTime": 1652272891,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28660",
                    "makerAddr": "0xF117AeB3a34BA3af790b274979a68e6Ffc394a30",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "1656",
                    "listingTime": 1652124515,
                    "expirationTime": 1652210911,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1656",
                    "makerAddr": "0x9Ba866EE47ca7eAd76b0a290E9F37723ac76D477",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "7615",
                    "listingTime": 1652065425,
                    "expirationTime": 1654140133,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7615",
                    "makerAddr": "0x3FbEbbE2bD130da895411301cd8cEb834aF7cf83",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "15262",
                    "listingTime": 1652038068,
                    "expirationTime": 1652297228,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15262",
                    "makerAddr": "0x98C098A155a65D9B5d23F113a1B53930BC19fF32",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "14470",
                    "listingTime": 1652196612,
                    "expirationTime": 1652231400,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14470",
                    "makerAddr": "0x6CF5b4D7132f34B73a6b4F3e35075f844e057529",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "24472",
                    "listingTime": 1652083606,
                    "expirationTime": 1654109351,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24472",
                    "makerAddr": "0x15053b688f69d742AD18f13f47d15E097E4A77e7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "28116",
                    "listingTime": 1652037696,
                    "expirationTime": 1652296887,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28116",
                    "makerAddr": "0x6AA73c8049675fEc5EBD7C5B6dEAf4ca10B8f500",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "1434",
                    "listingTime": 1652155030,
                    "expirationTime": 1652414221,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1434",
                    "makerAddr": "0x1da302F5Ff7a731D488d75632E7E8eC51361057B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "8392",
                    "listingTime": 1651880696,
                    "expirationTime": 1652206176,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8392",
                    "makerAddr": "0x3A70344c268cD039B107D9f65705F6092303c919",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "9159",
                    "listingTime": 1651915042,
                    "expirationTime": 1654506995,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9159",
                    "makerAddr": "0xeF75F962A93811AfE467a573F6f4F9b5e8240483",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "6448",
                    "listingTime": 1652074961,
                    "expirationTime": 1652334156,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6448",
                    "makerAddr": "0xdc78107155918e230246439E4159FeA4c477eAE9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "9984",
                    "listingTime": 1651800380,
                    "expirationTime": 1652362542,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9984",
                    "makerAddr": "0x5Ec0D096f8ef2Ac2dBd3536e3dFE2db1361BA6a7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "11370",
                    "listingTime": 1652055557,
                    "expirationTime": 1652314750,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11370",
                    "makerAddr": "0x09fD1ec7F2De0F0D497FdEf65361b436C7D53b83",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "12862",
                    "listingTime": 1651953463,
                    "expirationTime": 1652212657,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12862",
                    "makerAddr": "0xe9E7F10a129Ed34be0cAB9e1Fb0c8a309D3526D9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "21300",
                    "listingTime": 1651880141,
                    "expirationTime": 1654086210,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21300",
                    "makerAddr": "0xB53167fD81a9270b4Dea3F9eBF96742722cdCe50",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "649",
                    "listingTime": 1652075739,
                    "expirationTime": 1654667688,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/649",
                    "makerAddr": "0x0539ad2e33e5AFB754665765C47d1812871737Eb",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "3286",
                    "listingTime": 1652195389,
                    "expirationTime": 1652281758,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3286",
                    "makerAddr": "0x56e1b883a7ee8c948d014aF899E2710B8B89dE6d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "27620",
                    "listingTime": 1652020035,
                    "expirationTime": 1654114763,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27620",
                    "makerAddr": "0x3a69648665ADDf5D4E633CE5E6c8774241487041",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "7276",
                    "listingTime": 1652066206,
                    "expirationTime": 1654407394,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7276",
                    "makerAddr": "0xc1F50e993a84F0B7C07daE664fE52986Bd32b106",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "4565",
                    "listingTime": 1652009813,
                    "expirationTime": 1652611775,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4565",
                    "makerAddr": "0x8657B52C85539Eb3843B679fF37Cd2980E04D96F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "28478",
                    "listingTime": 1652193066,
                    "expirationTime": 1652277600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28478",
                    "makerAddr": "0xC8baD5520643067D1C313a94749ac0199a16A384",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "3083",
                    "listingTime": 1652029345,
                    "expirationTime": 1653414972,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3083",
                    "makerAddr": "0xC344FCe3710d5971b24b11947535FAcE3eCd5655",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "5513",
                    "listingTime": 1651944836,
                    "expirationTime": 1654125141,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5513",
                    "makerAddr": "0xEC4762fb859a53c15D1689c08FD5E48C5c1C47Fa",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "20438",
                    "listingTime": 1652018146,
                    "expirationTime": 1654053758,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20438",
                    "makerAddr": "0xD9D728Eb0d7e4Ec9d256D458923eB64E59c5370f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "2491",
                    "listingTime": 1652193161,
                    "expirationTime": 1652279513,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2491",
                    "makerAddr": "0x1dE3B813D4FBc2FE394bc3D1C356F4321Aff20Ce",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28000000000000000000",
                    "tokenId": "20534",
                    "listingTime": 1652010959,
                    "expirationTime": 1653396846,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20534",
                    "makerAddr": "0xd4745B170DeDe5eF451D46Fce6A111eE41f4047f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1778",
                    "price": "28000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651940773,
                    "expirationTime": 1654619241,
                    "makerAddr": "0xa7a9edaad66fa17f2c632ae01ca320c2e3b2ba92",
                    "assetImageUrl": "https://img.seadn.io/files/e50692f0f0c9ec5da3b77c26b78243c8.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2369",
                    "price": "28000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652196618,
                    "expirationTime": 1652243498,
                    "makerAddr": "0x272b10e92ab4891fe6b706541157260b31458ea3",
                    "assetImageUrl": "https://img.seadn.io/files/386b990778dcde42bd1d4f9692e2bb8c.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28100000000000000000",
                    "tokenId": "190",
                    "listingTime": 1652073239,
                    "expirationTime": 1652328346,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/190",
                    "makerAddr": "0x2e5EE0f23b930b4C12217d914ce0d1C9F794e284",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28200000000000000000",
                    "tokenId": "14898",
                    "listingTime": 1652061344,
                    "expirationTime": 1654653332,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14898",
                    "makerAddr": "0x8F6DB32F1175400Cb647CC33322B38397d5C75B8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1563",
                    "price": "28200000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652140548,
                    "expirationTime": 1652252215,
                    "makerAddr": "0x6bd7df4f2e11ba853fdfb80e5a0ebab8993c9de3",
                    "assetImageUrl": "https://img.seadn.io/files/4b4d2db03fdf500b48b3d4ac8184b7c7.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28280000000000000000",
                    "tokenId": "3969",
                    "listingTime": 1652189451,
                    "expirationTime": 1652275848,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3969",
                    "makerAddr": "0x96503bA52cDa8228e3eA6e015e9279f1343558F7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28280000000000000000",
                    "tokenId": "6403",
                    "listingTime": 1651756166,
                    "expirationTime": 1652360963,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6403",
                    "makerAddr": "0xF59742B240426FDc41d61954a49f55f2A377313D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28400000000000000000",
                    "tokenId": "592",
                    "listingTime": 1652195117,
                    "expirationTime": 1652281500,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/592",
                    "makerAddr": "0xd511f7D8738E519820F4e4194bdDB253C1cE517b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28400000000000000000",
                    "tokenId": "22748",
                    "listingTime": 1651926418,
                    "expirationTime": 1652591644,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22748",
                    "makerAddr": "0x2155D5C0A63AF068a00443483C4CD572ae73b09b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28420000000000000000",
                    "tokenId": "4806",
                    "listingTime": 1652028684,
                    "expirationTime": 1652633349,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4806",
                    "makerAddr": "0xBeacA3595d0E023Cc726109b5BA38c7164daDdb5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28500000000000000000",
                    "tokenId": "25767",
                    "listingTime": 1651936830,
                    "expirationTime": 1652541603,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25767",
                    "makerAddr": "0x8EEA1d4C3C6d29C2E2B312FB0e7d4c81aF87DfdE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28500000000000000000",
                    "tokenId": "4337",
                    "listingTime": 1652031259,
                    "expirationTime": 1654145074,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4337",
                    "makerAddr": "0xdB2a2DF6DD352Cc1783B74de588406271fFc459D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28500000000000000000",
                    "tokenId": "2402",
                    "listingTime": 1651924364,
                    "expirationTime": 1654170888,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2402",
                    "makerAddr": "0xD9502fb95c72b118a4Ec6d060297cB3aeb7c50BA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28500000000000000000",
                    "tokenId": "79",
                    "listingTime": 1651920838,
                    "expirationTime": 1654047378,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/79",
                    "makerAddr": "0x1278Bf1cA85Bfe406e23502700C7Cb94eA36B21A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28500000000000000000",
                    "tokenId": "5534",
                    "listingTime": 1652061279,
                    "expirationTime": 1654653255,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5534",
                    "makerAddr": "0x8F6DB32F1175400Cb647CC33322B38397d5C75B8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28500000000000000000",
                    "tokenId": "2251",
                    "listingTime": 1652059440,
                    "expirationTime": 1652318634,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2251",
                    "makerAddr": "0x681E4fc5734ccFE814b03899efF744FC1e415Abe",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28500000000000000000",
                    "tokenId": "821",
                    "listingTime": 1652056408,
                    "expirationTime": 1652622687,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/821",
                    "makerAddr": "0xe9504835aC8A68178AAffd3145Fd7E4a48683d2a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28500000000000000000",
                    "tokenId": "3110",
                    "listingTime": 1651918750,
                    "expirationTime": 1654152457,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3110",
                    "makerAddr": "0xF0e9b336A772d9838356645097c6dBf9f8AFfDeE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28500000000000000000",
                    "tokenId": "17516",
                    "listingTime": 1651925146,
                    "expirationTime": 1654010796,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17516",
                    "makerAddr": "0xBEFE9091541d2FD2Bb2B931536Fbee0383C4c4E0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28500000000000000000",
                    "tokenId": "4371",
                    "listingTime": 1651921254,
                    "expirationTime": 1652443674,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4371",
                    "makerAddr": "0x746a3A6760f5e9442c748e6E811a2497dba52FAA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28500000000000000000",
                    "tokenId": "7787",
                    "listingTime": 1652019507,
                    "expirationTime": 1652200221,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7787",
                    "makerAddr": "0xe14206171b48b0399Cb6eD519C87b7D07be1Af67",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28500000000000000000",
                    "tokenId": "5568",
                    "listingTime": 1652065301,
                    "expirationTime": 1652670049,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5568",
                    "makerAddr": "0xE0F3A5f3064B609F7677c7B1Aa4dA0736746d64e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28500000000000000000",
                    "tokenId": "15244",
                    "listingTime": 1651925824,
                    "expirationTime": 1654219385,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15244",
                    "makerAddr": "0x28570D9d66627e0733dFE4FCa79B8fD5b8128636",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28500000000000000000",
                    "tokenId": "12568",
                    "listingTime": 1652019566,
                    "expirationTime": 1652200332,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12568",
                    "makerAddr": "0xe14206171b48b0399Cb6eD519C87b7D07be1Af67",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28500000000000000000",
                    "tokenId": "20652",
                    "listingTime": 1651760736,
                    "expirationTime": 1652365520,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20652",
                    "makerAddr": "0x1338901827e3dee9506d0514F7746F306310F488",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28500000000000000000",
                    "tokenId": "5582",
                    "listingTime": 1652019506,
                    "expirationTime": 1653603931,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5582",
                    "makerAddr": "0xA25D7e569393583f2a17007b5619452E25fB40b9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28550000000000000000",
                    "tokenId": "1930",
                    "listingTime": 1652162728,
                    "expirationTime": 1652201111,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1930",
                    "makerAddr": "0xB6f6B9aA69d6dfd8039B35F0C49463F27696d437",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28600000000000000000",
                    "tokenId": "21822",
                    "listingTime": 1651947393,
                    "expirationTime": 1652206589,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21822",
                    "makerAddr": "0xc3a1528dF68DB9b0e1f28edd7B3c4958550702D1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1930",
                    "price": "28600000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652162508,
                    "expirationTime": 1652201111,
                    "makerAddr": "0xb6f6b9aa69d6dfd8039b35f0c49463f27696d437",
                    "assetImageUrl": "https://img.seadn.io/files/e92bb6f514cef393a0ee112b0ae7affe.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28630000000000000000",
                    "tokenId": "12520",
                    "listingTime": 1652066995,
                    "expirationTime": 1652326156,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12520",
                    "makerAddr": "0x49c151707AbDa2de0c883dd93C49f253435794Bb",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28680000000000000000",
                    "tokenId": "3668",
                    "listingTime": 1652196693,
                    "expirationTime": 1652801487,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3668",
                    "makerAddr": "0x82e1EC93Be8B2a6a93Cd14a7Fd5326491FC06C94",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28690000000000000000",
                    "tokenId": "26032",
                    "listingTime": 1651906887,
                    "expirationTime": 1652227452,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26032",
                    "makerAddr": "0xc94893b6D1CC1d7ce09Fec6abC9B5949A903d2C9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28690000000000000000",
                    "tokenId": "20516",
                    "listingTime": 1652030558,
                    "expirationTime": 1654264978,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20516",
                    "makerAddr": "0x1E4d44f00D42052326fBA19ecaD73846875B0557",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28690000000000000000",
                    "tokenId": "3091",
                    "listingTime": 1652030524,
                    "expirationTime": 1654095552,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3091",
                    "makerAddr": "0x1E4d44f00D42052326fBA19ecaD73846875B0557",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28697000000000000000",
                    "tokenId": "7325",
                    "listingTime": 1652038030,
                    "expirationTime": 1653499413,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7325",
                    "makerAddr": "0xe794f5e304109E77FE7b7E6D5bD9B1D69C6560A8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28700000000000000000",
                    "tokenId": "21828",
                    "listingTime": 1652194676,
                    "expirationTime": 1654786651,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21828",
                    "makerAddr": "0x1921A04221e1687EB0Ed7609711E02AB7e8a9C52",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28750000000000000000",
                    "tokenId": "4138",
                    "listingTime": 1651957678,
                    "expirationTime": 1652562475,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4138",
                    "makerAddr": "0x51A9F0788c6BEBE7d723f3374Dd707ffBbdBD788",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28800000000000000000",
                    "tokenId": "4200",
                    "listingTime": 1652173014,
                    "expirationTime": 1652273657,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4200",
                    "makerAddr": "0x54Ca39290A74cACf4eB51a1E85710ecACb161222",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28800000000000000000",
                    "tokenId": "18752",
                    "listingTime": 1651947237,
                    "expirationTime": 1652206433,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18752",
                    "makerAddr": "0xc3a1528dF68DB9b0e1f28edd7B3c4958550702D1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28800000000000000000",
                    "tokenId": "1020",
                    "listingTime": 1652195397,
                    "expirationTime": 1652281580,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1020",
                    "makerAddr": "0xEb2A4839B75d0E18dA39c58E3804AF095193938b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28800000000000000000",
                    "tokenId": "9496",
                    "listingTime": 1651652116,
                    "expirationTime": 1652861709,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9496",
                    "makerAddr": "0x7dd3705b1aBc05Cf736f6FDce4BCaC1069058a63",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28800000000000000000",
                    "tokenId": "4831",
                    "listingTime": 1651939512,
                    "expirationTime": 1653148943,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4831",
                    "makerAddr": "0xbC4485554825b3Ef94700E179D9E8A98540887A1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28880000000000000000",
                    "tokenId": "15978",
                    "listingTime": 1651969736,
                    "expirationTime": 1652228926,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15978",
                    "makerAddr": "0x435a35aaF01f70c486CB4F8Fb56d265DC41a0068",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28880000000000000000",
                    "tokenId": "18432",
                    "listingTime": 1651900800,
                    "expirationTime": 1659676754,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18432",
                    "makerAddr": "0x2FE64f74Df8F5bef5DB73622bb58da79CA8C8B3b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28880000000000000000",
                    "tokenId": "6375",
                    "listingTime": 1652024818,
                    "expirationTime": 1652629611,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6375",
                    "makerAddr": "0x813A3005B071791B98292c74CC0700239C52dB25",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28880000000000000000",
                    "tokenId": "1948",
                    "listingTime": 1652012736,
                    "expirationTime": 1654112606,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1948",
                    "makerAddr": "0x7a704A51b9D9Dab9f3bFFBD0685a25f70661b64d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28880000000000000000",
                    "tokenId": "20314",
                    "listingTime": 1652181232,
                    "expirationTime": 1652245200,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20314",
                    "makerAddr": "0x5c9Ea568cE669850722428C3590AdBBbA5fc486d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28880000000000000000",
                    "tokenId": "10754",
                    "listingTime": 1652015889,
                    "expirationTime": 1652273260,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10754",
                    "makerAddr": "0x9Fe176816F90145F8aEd9fFDedEE158ed8423bA2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28880000000000000000",
                    "tokenId": "20156",
                    "listingTime": 1652062492,
                    "expirationTime": 1652579538,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20156",
                    "makerAddr": "0xF9f02deF55E1ccEEDF017E0353c14Ba6F7F4d3Df",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28900000000000000000",
                    "tokenId": "21366",
                    "listingTime": 1652061167,
                    "expirationTime": 1654653159,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21366",
                    "makerAddr": "0xbb392CaFEd4feba7452376CBc880b04A1760a5c4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28900000000000000000",
                    "tokenId": "9710",
                    "listingTime": 1651478831,
                    "expirationTime": 1653370191,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9710",
                    "makerAddr": "0xf528a90CC2b35065e78518F77962eC884deb7230",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28900000000000000000",
                    "tokenId": "77",
                    "listingTime": 1652029529,
                    "expirationTime": 1654082376,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/77",
                    "makerAddr": "0xAffca680e6dcbb1bb336108D5aCcA9c545885260",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28900000000000000000",
                    "tokenId": "9",
                    "listingTime": 1652030105,
                    "expirationTime": 1652289299,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9",
                    "makerAddr": "0xc3a1528dF68DB9b0e1f28edd7B3c4958550702D1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28900000000000000000",
                    "tokenId": "12316",
                    "listingTime": 1652064857,
                    "expirationTime": 1652302542,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12316",
                    "makerAddr": "0x69bAb6810fa99475854bca0A3DD72aE6a0728ECE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28900000000000000000",
                    "tokenId": "2402",
                    "listingTime": 1651922397,
                    "expirationTime": 1654170888,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2402",
                    "makerAddr": "0xD9502fb95c72b118a4Ec6d060297cB3aeb7c50BA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28900000000000000000",
                    "tokenId": "23054",
                    "listingTime": 1652060782,
                    "expirationTime": 1654652724,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23054",
                    "makerAddr": "0xbb392CaFEd4feba7452376CBc880b04A1760a5c4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28900000000000000000",
                    "tokenId": "5327",
                    "listingTime": 1652060712,
                    "expirationTime": 1654652695,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5327",
                    "makerAddr": "0xbb392CaFEd4feba7452376CBc880b04A1760a5c4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28950000000000000000",
                    "tokenId": "3526",
                    "listingTime": 1651736547,
                    "expirationTime": 1652341341,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3526",
                    "makerAddr": "0xAE418c8FE6180DA0bBAE6742915452Ff75186cE0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28960000000000000000",
                    "tokenId": "1497",
                    "listingTime": 1652064772,
                    "expirationTime": 1652302475,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1497",
                    "makerAddr": "0x69bAb6810fa99475854bca0A3DD72aE6a0728ECE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28980000000000000000",
                    "tokenId": "20314",
                    "listingTime": 1652161321,
                    "expirationTime": 1652245200,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20314",
                    "makerAddr": "0x5c9Ea568cE669850722428C3590AdBBbA5fc486d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28990000000000000000",
                    "tokenId": "3878",
                    "listingTime": 1651913871,
                    "expirationTime": 1666347219,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3878",
                    "makerAddr": "0xDC859b3da111A0Eb0AD3bB536dCB5d9f14f19F99",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "28990000000000000000",
                    "tokenId": "9573",
                    "listingTime": 1652010021,
                    "expirationTime": 1652269167,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9573",
                    "makerAddr": "0xEBC6d7eD591caAA959ed3A733a70Ee063cd3CC96",
                    "assetImageUrl": ""
                }
            ],
            "total": 4.2957869999999974e+21,
            "looksrare": 148,
            "opensea": 4
        },
        {
            "price": 29,
            "amount": 234,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "13250",
                    "listingTime": 1652084339,
                    "expirationTime": 1652689046,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13250",
                    "makerAddr": "0xd104Ce1266e5Fa0A31F7CA6dd16DEf1d62308C46",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "6046",
                    "listingTime": 1652027506,
                    "expirationTime": 1653401649,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6046",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "14622",
                    "listingTime": 1652196969,
                    "expirationTime": 1654788947,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14622",
                    "makerAddr": "0x0dBFD05fd48D62056aE630bb714E5D9F2255B5cB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "6682",
                    "listingTime": 1652014758,
                    "expirationTime": 1653398147,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6682",
                    "makerAddr": "0x71dfD2baB718F5bB30f1099494a68752B5d2f2e2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "9275",
                    "listingTime": 1652197568,
                    "expirationTime": 1652283964,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9275",
                    "makerAddr": "0x8B3638493e78ff4D245311422bc40D8a3aa6A451",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "15832",
                    "listingTime": 1652193817,
                    "expirationTime": 1652280212,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15832",
                    "makerAddr": "0x7880C2797F49ecA74907705a6e5102518b9DC53c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "5513",
                    "listingTime": 1651844681,
                    "expirationTime": 1654125141,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5513",
                    "makerAddr": "0xEC4762fb859a53c15D1689c08FD5E48C5c1C47Fa",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "10938",
                    "listingTime": 1652193670,
                    "expirationTime": 1652280065,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10938",
                    "makerAddr": "0x97cDD6B65cC7ed9Ce076312284FF1be5ae1E09A5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "2818",
                    "listingTime": 1651891855,
                    "expirationTime": 1654271927,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2818",
                    "makerAddr": "0x822a16309A9eE40f15e196898f11A010ecb1C963",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "11434",
                    "listingTime": 1651885617,
                    "expirationTime": 1654234432,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11434",
                    "makerAddr": "0x55d491e3DBCCDDcfd8E75568885908BDFeAaf069",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "3267",
                    "listingTime": 1652195165,
                    "expirationTime": 1654787115,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3267",
                    "makerAddr": "0x493a401122d858324517C5eC95145dc970c2C363",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "564",
                    "listingTime": 1651601481,
                    "expirationTime": 1652206184,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/564",
                    "makerAddr": "0xC7b72Bb1aCDcE3eB45f9B38F43cD31e4927C725b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "1568",
                    "listingTime": 1651955590,
                    "expirationTime": 1653992137,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1568",
                    "makerAddr": "0x835ba4bA03713208Fa8404acd626466C9273F93c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "1066",
                    "listingTime": 1652195257,
                    "expirationTime": 1654787115,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1066",
                    "makerAddr": "0x493a401122d858324517C5eC95145dc970c2C363",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "29292",
                    "listingTime": 1652195323,
                    "expirationTime": 1654787115,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29292",
                    "makerAddr": "0x493a401122d858324517C5eC95145dc970c2C363",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "18108",
                    "listingTime": 1651890872,
                    "expirationTime": 1654267015,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18108",
                    "makerAddr": "0xeBf7c7932D86907C3BC1Bd6d486308637a8264A3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "28660",
                    "listingTime": 1652013792,
                    "expirationTime": 1652272891,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28660",
                    "makerAddr": "0xF117AeB3a34BA3af790b274979a68e6Ffc394a30",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "5783",
                    "listingTime": 1651908176,
                    "expirationTime": 1667215195,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5783",
                    "makerAddr": "0xCD750f5854eCE8252FB7d59D4a8Be7864241d50D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "5134",
                    "listingTime": 1651922239,
                    "expirationTime": 1654047716,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5134",
                    "makerAddr": "0xEAA06F6242836cf6641a5d43C8f519224e5A4DD5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "585",
                    "listingTime": 1651621782,
                    "expirationTime": 1654213661,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/585",
                    "makerAddr": "0xDe112B77A1a72c726D8C914c23dCEaA53273c351",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "9774",
                    "listingTime": 1652195132,
                    "expirationTime": 1654787115,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9774",
                    "makerAddr": "0x493a401122d858324517C5eC95145dc970c2C363",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "23316",
                    "listingTime": 1652009151,
                    "expirationTime": 1654600894,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23316",
                    "makerAddr": "0xaAB1e59A453f30eb52fdc2e5B3e13dcB3B1595c5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "4076",
                    "listingTime": 1652024748,
                    "expirationTime": 1654453293,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4076",
                    "makerAddr": "0xB1Ec54030e383b9cE0cDAA988ba52364bFCe9c3C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "2105",
                    "listingTime": 1652195272,
                    "expirationTime": 1654787115,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2105",
                    "makerAddr": "0x493a401122d858324517C5eC95145dc970c2C363",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "14064",
                    "listingTime": 1651863160,
                    "expirationTime": 1654274060,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14064",
                    "makerAddr": "0x57E7917a016c25227387b1feAF8B73C49784f563",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "15016",
                    "listingTime": 1652195923,
                    "expirationTime": 1652455120,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15016",
                    "makerAddr": "0xF6bA5BbdCa87d9bEFa00c5340bcF4Be4B02fDD6F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "14982",
                    "listingTime": 1652039205,
                    "expirationTime": 1652401200,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14982",
                    "makerAddr": "0x0D7D9AcF85FA0Bb5eE25bd8016B7c0f5A711c893",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "3518",
                    "listingTime": 1651862650,
                    "expirationTime": 1652366170,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3518",
                    "makerAddr": "0x5d987F2F1689eCD4C21f1E73E2F12762570fdED5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "13726",
                    "listingTime": 1652195573,
                    "expirationTime": 1652281970,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13726",
                    "makerAddr": "0x99856f3d5fd808850C28B88467a75479CcB80A05",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "626",
                    "listingTime": 1651894862,
                    "expirationTime": 1653631418,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/626",
                    "makerAddr": "0xe90a804dB10c4Ffd52732A0da6a0dE9e4fab4cD4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "27766",
                    "listingTime": 1652195957,
                    "expirationTime": 1652455154,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27766",
                    "makerAddr": "0xF6bA5BbdCa87d9bEFa00c5340bcF4Be4B02fDD6F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "8981",
                    "listingTime": 1652026866,
                    "expirationTime": 1654414442,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8981",
                    "makerAddr": "0x87de18D54599A79354A69B0cBABc47eA988c0acD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "5136",
                    "listingTime": 1652196513,
                    "expirationTime": 1652455702,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5136",
                    "makerAddr": "0x9C74F1a06CEa6587029029f3dE875D08757B9960",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "10164",
                    "listingTime": 1651889964,
                    "expirationTime": 1654281405,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10164",
                    "makerAddr": "0x8c197dB132A18Cf2c0b46A1051D7c3E43F65a17b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "27604",
                    "listingTime": 1652001872,
                    "expirationTime": 1652261052,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27604",
                    "makerAddr": "0x7599a1f7D44EEA1CFe86C75ceea54146719C9361",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "20920",
                    "listingTime": 1651921924,
                    "expirationTime": 1653874229,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20920",
                    "makerAddr": "0xb97A377229bFf1BED964a82b09167D4CC98e2e27",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "2309",
                    "listingTime": 1652079231,
                    "expirationTime": 1652338419,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2309",
                    "makerAddr": "0xE02Fd90f7bb205c7542A152Aaa14f7DDa005afdC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "9628",
                    "listingTime": 1652193976,
                    "expirationTime": 1652280370,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9628",
                    "makerAddr": "0xE93E6F6477473393683C542a7A2CF9CE94536256",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "20664",
                    "listingTime": 1652022709,
                    "expirationTime": 1653437727,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20664",
                    "makerAddr": "0x545a2eD169EaC188638763539D30951488a9c8F7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "10944",
                    "listingTime": 1652196303,
                    "expirationTime": 1652482497,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10944",
                    "makerAddr": "0x7F37Cb4E1fF71193175B11c16FaA7d97aa851876",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "2127",
                    "listingTime": 1652196430,
                    "expirationTime": 1654260868,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2127",
                    "makerAddr": "0x964F690Def870B36f017F6127Ab1B929645333C0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "11536",
                    "listingTime": 1652039260,
                    "expirationTime": 1654217185,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11536",
                    "makerAddr": "0x0D7D9AcF85FA0Bb5eE25bd8016B7c0f5A711c893",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "807",
                    "listingTime": 1651912634,
                    "expirationTime": 1654431469,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/807",
                    "makerAddr": "0x70b30BE4e3E0406E016dA4b2ed8BE62cbc43bee0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "5310",
                    "listingTime": 1652033002,
                    "expirationTime": 1652292191,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5310",
                    "makerAddr": "0xA606b739D1DF1caf185f0beAd4592A08788701f1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "4614",
                    "listingTime": 1652195195,
                    "expirationTime": 1654787115,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4614",
                    "makerAddr": "0x493a401122d858324517C5eC95145dc970c2C363",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "7713",
                    "listingTime": 1652053692,
                    "expirationTime": 1654645675,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7713",
                    "makerAddr": "0x623C6A143c6eba9c6A43B2104B4b704BD52cD9eE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "8003",
                    "listingTime": 1651921889,
                    "expirationTime": 1654325732,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8003",
                    "makerAddr": "0xb97A377229bFf1BED964a82b09167D4CC98e2e27",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "1053",
                    "listingTime": 1652195237,
                    "expirationTime": 1654787115,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1053",
                    "makerAddr": "0x493a401122d858324517C5eC95145dc970c2C363",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "20292",
                    "listingTime": 1652195304,
                    "expirationTime": 1654787115,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20292",
                    "makerAddr": "0x493a401122d858324517C5eC95145dc970c2C363",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "9199",
                    "listingTime": 1651892496,
                    "expirationTime": 1653449081,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9199",
                    "makerAddr": "0x99d73D76f0058F4ae24882A49A0a9eA0f5a41268",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "1135",
                    "listingTime": 1652052861,
                    "expirationTime": 1653809444,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1135",
                    "makerAddr": "0x2efBc44A485F13858A17CE33623e6C4c6AE7d01a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "1687",
                    "listingTime": 1651873737,
                    "expirationTime": 1653569271,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1687",
                    "makerAddr": "0x2eA07698a88AD2a0Acfc82C229C5cBaDb7CC9c9C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "27203",
                    "listingTime": 1652195215,
                    "expirationTime": 1654787115,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27203",
                    "makerAddr": "0x493a401122d858324517C5eC95145dc970c2C363",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "2011",
                    "listingTime": 1651930244,
                    "expirationTime": 1652534986,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2011",
                    "makerAddr": "0x10F11efF8c75d3eF4F4b2F2AC40D1E1b433fc048",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "26206",
                    "listingTime": 1651955257,
                    "expirationTime": 1653525504,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26206",
                    "makerAddr": "0x0563b837bA1831066A10F923DeB91E8cc57608dd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "3417",
                    "listingTime": 1652196331,
                    "expirationTime": 1652239500,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3417",
                    "makerAddr": "0x7303343c380C959Ae0aC70253CF2980e1D7EaB14",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "6917",
                    "listingTime": 1651882864,
                    "expirationTime": 1653978444,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6917",
                    "makerAddr": "0x6beEF2B2fE00FDDCa12A8CDA2D4B00435b0ba3b6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "205",
                    "listingTime": 1652053352,
                    "expirationTime": 1653981354,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/205",
                    "makerAddr": "0x53f42691DF74AD573F5B8a461EBEFe09ECddf920",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "5666",
                    "listingTime": 1651927479,
                    "expirationTime": 1654519463,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5666",
                    "makerAddr": "0x1C59cDC6f89d7c436ed0Cc537Dcb195b38e6ebcD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "901",
                    "listingTime": 1652018903,
                    "expirationTime": 1652278092,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/901",
                    "makerAddr": "0x4eec052D5E496066CC20592A2825b90456615670",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "11548",
                    "listingTime": 1652195590,
                    "expirationTime": 1652281987,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11548",
                    "makerAddr": "0x6239804caBcbe5Dc827b41B9730DE73122110B4c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "24820",
                    "listingTime": 1651867768,
                    "expirationTime": 1654114745,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24820",
                    "makerAddr": "0x2C543BeE768EAf32a09D091E8239CDc9E33c75F5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "25826",
                    "listingTime": 1652039239,
                    "expirationTime": 1654205485,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25826",
                    "makerAddr": "0x0D7D9AcF85FA0Bb5eE25bd8016B7c0f5A711c893",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "11492",
                    "listingTime": 1652195293,
                    "expirationTime": 1654787115,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11492",
                    "makerAddr": "0x493a401122d858324517C5eC95145dc970c2C363",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "16204",
                    "listingTime": 1651911547,
                    "expirationTime": 1652516344,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16204",
                    "makerAddr": "0x705a651b5611dEc423A6e5Cb07Ecb6E64F1fba5b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "15702",
                    "listingTime": 1651894663,
                    "expirationTime": 1654169119,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15702",
                    "makerAddr": "0x4328D5d49dB3D1824a9C3A52013057E7cD5EDc12",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "28646",
                    "listingTime": 1652195226,
                    "expirationTime": 1654787115,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28646",
                    "makerAddr": "0x493a401122d858324517C5eC95145dc970c2C363",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "11898",
                    "listingTime": 1651933361,
                    "expirationTime": 1654371761,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11898",
                    "makerAddr": "0x4f940D90A643a27546C885B3165b31d624f80c15",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "13962",
                    "listingTime": 1651965675,
                    "expirationTime": 1652224865,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13962",
                    "makerAddr": "0x1E454cfCa1AcA1CC9288270A46430b118Ad1b4Da",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "4596",
                    "listingTime": 1651960503,
                    "expirationTime": 1654379252,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4596",
                    "makerAddr": "0x95F71D6424F2B9bfc29378Ea9539372c986F2E9b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "29534",
                    "listingTime": 1651889383,
                    "expirationTime": 1654123279,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29534",
                    "makerAddr": "0x268993C1923Cf4d6db408f5109A53FCf17ed4039",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "3301",
                    "listingTime": 1652048807,
                    "expirationTime": 1654232007,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3301",
                    "makerAddr": "0xe346388252fC3dF0a1bBcC446C03c36EE8001e3f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "12709",
                    "listingTime": 1652195283,
                    "expirationTime": 1654787115,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12709",
                    "makerAddr": "0x493a401122d858324517C5eC95145dc970c2C363",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "9922",
                    "listingTime": 1651537995,
                    "expirationTime": 1654129986,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9922",
                    "makerAddr": "0x964F690Def870B36f017F6127Ab1B929645333C0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "21904",
                    "listingTime": 1652197005,
                    "expirationTime": 1654789002,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21904",
                    "makerAddr": "0x0dBFD05fd48D62056aE630bb714E5D9F2255B5cB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "7540",
                    "listingTime": 1652194450,
                    "expirationTime": 1652259600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7540",
                    "makerAddr": "0xeae417B10fBC7901687e20d4553868d77546bB8e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "4584",
                    "listingTime": 1652038838,
                    "expirationTime": 1653992259,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4584",
                    "makerAddr": "0xab36Ef38d02E5aeC0fCcBc6B76772A0CF3E69A38",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "2391",
                    "listingTime": 1652196991,
                    "expirationTime": 1652801771,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2391",
                    "makerAddr": "0x3175125AEDfC43Ec8930541f6f0550596b82A5b2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "5383",
                    "listingTime": 1652027557,
                    "expirationTime": 1653823241,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5383",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "7775",
                    "listingTime": 1651601478,
                    "expirationTime": 1652206184,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7775",
                    "makerAddr": "0xC7b72Bb1aCDcE3eB45f9B38F43cD31e4927C725b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "6067",
                    "listingTime": 1652195155,
                    "expirationTime": 1654787115,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6067",
                    "makerAddr": "0x493a401122d858324517C5eC95145dc970c2C363",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "4810",
                    "listingTime": 1652012600,
                    "expirationTime": 1652617342,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4810",
                    "makerAddr": "0x68B7b6b1a2626AAEe01593eC8b5039f0d7Be1e47",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "344",
                    "listingTime": 1652026757,
                    "expirationTime": 1653232447,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/344",
                    "makerAddr": "0x014f3A8BBCC20c17428A8EE9214F4923e30ba4eE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "11374",
                    "listingTime": 1651663033,
                    "expirationTime": 1652872623,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11374",
                    "makerAddr": "0x879783dA21c3365dC94a07aCDbB45dB75cf47400",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "4781",
                    "listingTime": 1652042954,
                    "expirationTime": 1653655778,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4781",
                    "makerAddr": "0x75ecEc45CDdE8cEd19880e735a619fa7162B40e2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "2872",
                    "listingTime": 1652195143,
                    "expirationTime": 1654787115,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2872",
                    "makerAddr": "0x493a401122d858324517C5eC95145dc970c2C363",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "2568",
                    "listingTime": 1652195121,
                    "expirationTime": 1654787115,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2568",
                    "makerAddr": "0x493a401122d858324517C5eC95145dc970c2C363",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "17017",
                    "listingTime": 1651939542,
                    "expirationTime": 1654309720,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17017",
                    "makerAddr": "0x19fffB371b95574DF860E90b6A5b69256629B585",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "32",
                    "listingTime": 1651893505,
                    "expirationTime": 1654485503,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/32",
                    "makerAddr": "0x5bfde66bde613887dC0E32Ac1D95b259FeE9a2cA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "17826",
                    "listingTime": 1652038852,
                    "expirationTime": 1653992284,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17826",
                    "makerAddr": "0xab36Ef38d02E5aeC0fCcBc6B76772A0CF3E69A38",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "14846",
                    "listingTime": 1651906418,
                    "expirationTime": 1653821019,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14846",
                    "makerAddr": "0x6189e05a28bEF26Bcf76F22aB311282d5e843dF4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "3446",
                    "listingTime": 1652195175,
                    "expirationTime": 1654787115,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3446",
                    "makerAddr": "0x493a401122d858324517C5eC95145dc970c2C363",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "3767",
                    "listingTime": 1652194873,
                    "expirationTime": 1652281269,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3767",
                    "makerAddr": "0xb63883BE639aDdBD02CA1fB71EC99a9D99B18B5b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "23218",
                    "listingTime": 1652053467,
                    "expirationTime": 1654645456,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23218",
                    "makerAddr": "0x8f75dCb28B4101ED7568ce7A7d4eFB7dEe526DaF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "16150",
                    "listingTime": 1652193230,
                    "expirationTime": 1654785203,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16150",
                    "makerAddr": "0x2C1Fd0CCeeD2b8ac4F3D943417B251EB9b08E209",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "5893",
                    "listingTime": 1652026580,
                    "expirationTime": 1653803334,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5893",
                    "makerAddr": "0x1FEACcB479834998BD7750754062347A6FaD8F9F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "1958",
                    "listingTime": 1651894082,
                    "expirationTime": 1653523783,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1958",
                    "makerAddr": "0xc00667d8B00f35B3565A5c4458Dff1Cd718E3527",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "18805",
                    "listingTime": 1651939490,
                    "expirationTime": 1654309720,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18805",
                    "makerAddr": "0x19fffB371b95574DF860E90b6A5b69256629B585",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "7644",
                    "listingTime": 1651878662,
                    "expirationTime": 1654470657,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7644",
                    "makerAddr": "0x352E13A72Bea47D1d7C37f5a5E7Aee7b6e3c41Ea",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "21404",
                    "listingTime": 1652056323,
                    "expirationTime": 1652316520,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21404",
                    "makerAddr": "0x2e9BC111159cC4FE5479eCC81F1825cFD577E077",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "3711",
                    "listingTime": 1652197688,
                    "expirationTime": 1652284084,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3711",
                    "makerAddr": "0x8B3638493e78ff4D245311422bc40D8a3aa6A451",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "2629",
                    "listingTime": 1652051313,
                    "expirationTime": 1654212884,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2629",
                    "makerAddr": "0x779bD28eA4f6268e02B397736cc2d6537118182D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "16800",
                    "listingTime": 1651928332,
                    "expirationTime": 1654520327,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16800",
                    "makerAddr": "0x2c3c28C8B928B85fe8cCb74B80BA22d300e35155",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "2086",
                    "listingTime": 1652037793,
                    "expirationTime": 1652296983,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2086",
                    "makerAddr": "0x104F5EE548DDBd700E44889414967433f050d026",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "8941",
                    "listingTime": 1652195185,
                    "expirationTime": 1654787115,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8941",
                    "makerAddr": "0x493a401122d858324517C5eC95145dc970c2C363",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "1063",
                    "listingTime": 1651975873,
                    "expirationTime": 1654567869,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1063",
                    "makerAddr": "0x7C8a7A76569ed447F2a5FDF6F0390EcCc5bd323E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "19598",
                    "listingTime": 1652036003,
                    "expirationTime": 1653245571,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19598",
                    "makerAddr": "0xdc61fDA7bd33971990D80cfc81ee761a8746dFe9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "25130",
                    "listingTime": 1652195205,
                    "expirationTime": 1654787115,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25130",
                    "makerAddr": "0x493a401122d858324517C5eC95145dc970c2C363",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "14782",
                    "listingTime": 1651866591,
                    "expirationTime": 1654170788,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14782",
                    "makerAddr": "0x41F3a0bd4D9036082d199f592C617Bdc8Ea62fa7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "24472",
                    "listingTime": 1651912078,
                    "expirationTime": 1654109351,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24472",
                    "makerAddr": "0x15053b688f69d742AD18f13f47d15E097E4A77e7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "18164",
                    "listingTime": 1651927379,
                    "expirationTime": 1654519321,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18164",
                    "makerAddr": "0x519f16C727A122d75bEE25D484AbD4d3B6740121",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "12974",
                    "listingTime": 1651894184,
                    "expirationTime": 1654119332,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12974",
                    "makerAddr": "0xc7f1916b741A7966Bd65144cb2B0Ba9eD1B29984",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "14638",
                    "listingTime": 1652014558,
                    "expirationTime": 1652273747,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14638",
                    "makerAddr": "0x7BaeA2342Cbd1009B65f259Ee574f6E8B0AB9DC8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "6788",
                    "listingTime": 1651890044,
                    "expirationTime": 1652279720,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6788",
                    "makerAddr": "0x4199F2400fb5Ad164A065815B1ab386fD09016a0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "27268",
                    "listingTime": 1652042174,
                    "expirationTime": 1654255891,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27268",
                    "makerAddr": "0xaC8352e02660A4dA6732Ea27306B59760243eAD2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "25254",
                    "listingTime": 1651905678,
                    "expirationTime": 1652252043,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25254",
                    "makerAddr": "0x1256497A1b0729E167BC52E2b08EE23a888184C9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "25864",
                    "listingTime": 1652044350,
                    "expirationTime": 1654275661,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25864",
                    "makerAddr": "0x8cc24CF5970F9c7AA94aB00Fbf9e6E86fd01c41B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "4200",
                    "listingTime": 1652091557,
                    "expirationTime": 1652273657,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4200",
                    "makerAddr": "0x54Ca39290A74cACf4eB51a1E85710ecACb161222",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "2704",
                    "listingTime": 1652068793,
                    "expirationTime": 1653404223,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2704",
                    "makerAddr": "0x5181aAEfA633db3539382f8A19fc62E2Fb11975f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "22272",
                    "listingTime": 1651939516,
                    "expirationTime": 1654309720,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22272",
                    "makerAddr": "0x19fffB371b95574DF860E90b6A5b69256629B585",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "7029",
                    "listingTime": 1652195235,
                    "expirationTime": 1652454432,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7029",
                    "makerAddr": "0xF6bA5BbdCa87d9bEFa00c5340bcF4Be4B02fDD6F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29000000000000000000",
                    "tokenId": "19990",
                    "listingTime": 1652018195,
                    "expirationTime": 1654053758,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19990",
                    "makerAddr": "0xD9D728Eb0d7e4Ec9d256D458923eB64E59c5370f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1497",
                    "price": "29000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652064706,
                    "expirationTime": 1652669581,
                    "makerAddr": "0x69bab6810fa99475854bca0a3dd72ae6a0728ece",
                    "assetImageUrl": "https://img.seadn.io/files/75144ec3464cadaf018c01194446051f.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2646",
                    "price": "29000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652144029,
                    "expirationTime": 1652403316,
                    "makerAddr": "0xc14d426cc46bf08937c368c135b7908dd55d79a8",
                    "assetImageUrl": "https://img.seadn.io/files/fdefae04c4e91951f0e45ab2882064a5.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2716",
                    "price": "29000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652155990,
                    "expirationTime": 1654833985,
                    "makerAddr": "0x7f5bc50b539dc282e82a72a517c8acc73f161431",
                    "assetImageUrl": "https://img.seadn.io/files/c939f01c2015b1e928c8c3752adf945a.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3181",
                    "price": "29000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652122989,
                    "expirationTime": 1652306319,
                    "makerAddr": "0x6b17fb1a77dd4e3d3cc8c5e06876bb5275b669f0",
                    "assetImageUrl": "https://img.seadn.io/files/7a594ed1439260e25483e54ea9f0a9d2.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29010000000000000000",
                    "tokenId": "9685",
                    "listingTime": 1652041424,
                    "expirationTime": 1652868538,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9685",
                    "makerAddr": "0xF6a68db8A9912374657fE991Eae16dCF9f034e19",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29100000000000000000",
                    "tokenId": "12670",
                    "listingTime": 1652193677,
                    "expirationTime": 1652280072,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12670",
                    "makerAddr": "0x8a92cd5f35Ca3C1c397C8c3abDC0ea65af74C491",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29100000000000000000",
                    "tokenId": "2037",
                    "listingTime": 1651935952,
                    "expirationTime": 1652540721,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2037",
                    "makerAddr": "0x77B691DeC38da63B60b4adB4c153A17C18e29Ce7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29200000000000000000",
                    "tokenId": "5148",
                    "listingTime": 1652035567,
                    "expirationTime": 1653942302,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5148",
                    "makerAddr": "0x75Cf2D77d40abd92756c704A963098a87b4e1aEC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29200000000000000000",
                    "tokenId": "825",
                    "listingTime": 1652040812,
                    "expirationTime": 1652585020,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/825",
                    "makerAddr": "0xa7e4f12B7Cd97387e8607daC17B439ca7cad58e9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29200000000000000000",
                    "tokenId": "13734",
                    "listingTime": 1652197351,
                    "expirationTime": 1652240520,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13734",
                    "makerAddr": "0x83994F7AB59ba007Bd5D6CAb13D8820b508C1bC4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29250000000000000000",
                    "tokenId": "16382",
                    "listingTime": 1651798805,
                    "expirationTime": 1652403602,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16382",
                    "makerAddr": "0xC493F04351c8f965957fecb5B71C3d1AC49A6C55",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29250000000000000000",
                    "tokenId": "6104",
                    "listingTime": 1652197470,
                    "expirationTime": 1652283865,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6104",
                    "makerAddr": "0x6B99DaEE1A77e3bddB52d2e23Fa442DAb701F2e7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29260000000000000000",
                    "tokenId": "25817",
                    "listingTime": 1652197198,
                    "expirationTime": 1652254260,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25817",
                    "makerAddr": "0x46873897F487119f34Ffde1e6819dbdA90Cf8acd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29260000000000000000",
                    "tokenId": "25816",
                    "listingTime": 1652197188,
                    "expirationTime": 1652254260,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25816",
                    "makerAddr": "0x46873897F487119f34Ffde1e6819dbdA90Cf8acd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29260000000000000000",
                    "tokenId": "20270",
                    "listingTime": 1652024950,
                    "expirationTime": 1652629747,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20270",
                    "makerAddr": "0x59a64B88dCb2d3504D585e5f70fDB5adF599BAbC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29260000000000000000",
                    "tokenId": "6315",
                    "listingTime": 1652040906,
                    "expirationTime": 1652645701,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6315",
                    "makerAddr": "0xaE88F8a1640e7813BA6b8c6f8d3ca6De021c6345",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29260000000000000000",
                    "tokenId": "27421",
                    "listingTime": 1652031194,
                    "expirationTime": 1654487554,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27421",
                    "makerAddr": "0xdbFbe8C7b4a6328a2bC45aBa79F032C6d14bDe6b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29260000000000000000",
                    "tokenId": "13734",
                    "listingTime": 1652197287,
                    "expirationTime": 1652240460,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13734",
                    "makerAddr": "0x83994F7AB59ba007Bd5D6CAb13D8820b508C1bC4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29260000000000000000",
                    "tokenId": "10820",
                    "listingTime": 1652194077,
                    "expirationTime": 1652798865,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10820",
                    "makerAddr": "0x13E2B15a146A2000BDFfE847185e6a0d527B2c6d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29300000000000000000",
                    "tokenId": "25478",
                    "listingTime": 1652196521,
                    "expirationTime": 1652801310,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25478",
                    "makerAddr": "0x35eEa8f1e8B75da3E0b99187faB064b2e343e367",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29300000000000000000",
                    "tokenId": "9088",
                    "listingTime": 1651992537,
                    "expirationTime": 1652292703,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9088",
                    "makerAddr": "0xf9bb1fe3dBcBA49F7Ab71485FA944eAE6605eD1f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29300000000000000000",
                    "tokenId": "9821",
                    "listingTime": 1652018933,
                    "expirationTime": 1652214106,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9821",
                    "makerAddr": "0x72caF7c477CCAB3F95913b9D8CDf35a1CaF25556",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29300000000000000000",
                    "tokenId": "11324",
                    "listingTime": 1652198485,
                    "expirationTime": 1652284880,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11324",
                    "makerAddr": "0x90c62a1342dCa4f165D03435192d45De4E330EC8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29300000000000000000",
                    "tokenId": "2482",
                    "listingTime": 1651893911,
                    "expirationTime": 1653895721,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2482",
                    "makerAddr": "0x8fa687316Ca25CC295F96806D29aa5075A4E5427",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29300000000000000000",
                    "tokenId": "2446",
                    "listingTime": 1651938864,
                    "expirationTime": 1654065234,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2446",
                    "makerAddr": "0x39aE891de01e34b54247D27f2a5469011b6CfbE9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29300000000000000000",
                    "tokenId": "18688",
                    "listingTime": 1652196701,
                    "expirationTime": 1652283096,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18688",
                    "makerAddr": "0xACDCE49f8C24F9adc366d1dc8c7b51ceb84b02aa",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29300000000000000000",
                    "tokenId": "20178",
                    "listingTime": 1651933411,
                    "expirationTime": 1659461641,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20178",
                    "makerAddr": "0x307E1cd1e0f955FeEB032CD7AB1df3367dc34b2B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29350000000000000000",
                    "tokenId": "7136",
                    "listingTime": 1652017441,
                    "expirationTime": 1652222179,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7136",
                    "makerAddr": "0x9bfE249bD946D71454F26f42d14305426a5776B3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29380000000000000000",
                    "tokenId": "24962",
                    "listingTime": 1652017371,
                    "expirationTime": 1659241622,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24962",
                    "makerAddr": "0xb638aE936edff5A81F8e9b252894aeCcF7C8ef77",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29380000000000000000",
                    "tokenId": "209",
                    "listingTime": 1651916171,
                    "expirationTime": 1654045890,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/209",
                    "makerAddr": "0x568d5132D2a2FAC4D5671509Ab9D787Af54D0810",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29380000000000000000",
                    "tokenId": "821",
                    "listingTime": 1652017928,
                    "expirationTime": 1652622687,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/821",
                    "makerAddr": "0xe9504835aC8A68178AAffd3145Fd7E4a48683d2a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29386000000000000000",
                    "tokenId": "14935",
                    "listingTime": 1652196063,
                    "expirationTime": 1652282417,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14935",
                    "makerAddr": "0x2c83484b7998B0374A033Aa0a7aF42de2e325C93",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29400000000000000000",
                    "tokenId": "17878",
                    "listingTime": 1652197556,
                    "expirationTime": 1652283953,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17878",
                    "makerAddr": "0x2D4A4466b114BE5fecFBf7DDeA1Fb576Ba513296",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29400000000000000000",
                    "tokenId": "21148",
                    "listingTime": 1652195279,
                    "expirationTime": 1652454433,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21148",
                    "makerAddr": "0x40b060A0Ac95dB3D5211b687511632b46c5d3bB7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29400000000000000000",
                    "tokenId": "825",
                    "listingTime": 1651928775,
                    "expirationTime": 1652585020,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/825",
                    "makerAddr": "0xa7e4f12B7Cd97387e8607daC17B439ca7cad58e9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29400000000000000000",
                    "tokenId": "1886",
                    "listingTime": 1651832511,
                    "expirationTime": 1652437306,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1886",
                    "makerAddr": "0x4089c8cFD527e14721e2066e24180B2103A2F514",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29400000000000000000",
                    "tokenId": "741",
                    "listingTime": 1652024011,
                    "expirationTime": 1652283208,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/741",
                    "makerAddr": "0x6c10579Cb5b7Ec51aDC31247B3c72670Ac9F7F8C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29400000000000000000",
                    "tokenId": "3465",
                    "listingTime": 1651909911,
                    "expirationTime": 1652514706,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3465",
                    "makerAddr": "0x9b72ca489D6F764f1c298A715Eb58502A0c07E62",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29500000000000000000",
                    "tokenId": "1047",
                    "listingTime": 1652198456,
                    "expirationTime": 1654790449,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1047",
                    "makerAddr": "0x2c7FA34CEaA12AfD4A63e08C4aa642F808B5531f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29500000000000000000",
                    "tokenId": "4337",
                    "listingTime": 1651891114,
                    "expirationTime": 1654145074,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4337",
                    "makerAddr": "0xdB2a2DF6DD352Cc1783B74de588406271fFc459D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29500000000000000000",
                    "tokenId": "5579",
                    "listingTime": 1651901930,
                    "expirationTime": 1653397146,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5579",
                    "makerAddr": "0xD163bd16eaf4813D723F90f13fE4abeeECCdD3dd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29500000000000000000",
                    "tokenId": "9088",
                    "listingTime": 1651952719,
                    "expirationTime": 1652292703,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9088",
                    "makerAddr": "0xf9bb1fe3dBcBA49F7Ab71485FA944eAE6605eD1f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29500000000000000000",
                    "tokenId": "9633",
                    "listingTime": 1652011590,
                    "expirationTime": 1652270743,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9633",
                    "makerAddr": "0x6386061EC25e56561de629c32910717453C546f0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29500000000000000000",
                    "tokenId": "7787",
                    "listingTime": 1651941046,
                    "expirationTime": 1652200221,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7787",
                    "makerAddr": "0xe14206171b48b0399Cb6eD519C87b7D07be1Af67",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29500000000000000000",
                    "tokenId": "29956",
                    "listingTime": 1651893792,
                    "expirationTime": 1654449376,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29956",
                    "makerAddr": "0x7685274EeE0D73BB916F1e1c8d126967CA63dC21",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29500000000000000000",
                    "tokenId": "6573",
                    "listingTime": 1651541829,
                    "expirationTime": 1654002156,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6573",
                    "makerAddr": "0x90290322426dD5e36becAc4B01Fc1E2613aaAEEE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29500000000000000000",
                    "tokenId": "4352",
                    "listingTime": 1652099940,
                    "expirationTime": 1652216459,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4352",
                    "makerAddr": "0xe73e90fc400c0256Bc271C83e576ACb97603452b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29500000000000000000",
                    "tokenId": "22860",
                    "listingTime": 1652193143,
                    "expirationTime": 1654785097,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22860",
                    "makerAddr": "0xA882Ef6c8314527A85598d11D825D37d6443D3eE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29500000000000000000",
                    "tokenId": "12568",
                    "listingTime": 1651941146,
                    "expirationTime": 1652200332,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12568",
                    "makerAddr": "0xe14206171b48b0399Cb6eD519C87b7D07be1Af67",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29500000000000000000",
                    "tokenId": "77",
                    "listingTime": 1651893657,
                    "expirationTime": 1654082376,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/77",
                    "makerAddr": "0xAffca680e6dcbb1bb336108D5aCcA9c545885260",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29500000000000000000",
                    "tokenId": "7613",
                    "listingTime": 1652198334,
                    "expirationTime": 1654790324,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7613",
                    "makerAddr": "0x2c7FA34CEaA12AfD4A63e08C4aa642F808B5531f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29500000000000000000",
                    "tokenId": "6867",
                    "listingTime": 1651834177,
                    "expirationTime": 1653043704,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6867",
                    "makerAddr": "0xD4E9F779be6936dEF54c4B2a4Cfcbdce54Dc302A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29500000000000000000",
                    "tokenId": "9468",
                    "listingTime": 1652065334,
                    "expirationTime": 1654657316,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9468",
                    "makerAddr": "0xE0F3A5f3064B609F7677c7B1Aa4dA0736746d64e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29529000000000000000",
                    "tokenId": "6502",
                    "listingTime": 1651587268,
                    "expirationTime": 1654178928,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6502",
                    "makerAddr": "0xAF5d712bC931e0F6DBFfd1bBe0673c7CE16Ad22D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29550000000000000000",
                    "tokenId": "3854",
                    "listingTime": 1652162708,
                    "expirationTime": 1652201155,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3854",
                    "makerAddr": "0xB6f6B9aA69d6dfd8039B35F0C49463F27696d437",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29600000000000000000",
                    "tokenId": "9148",
                    "listingTime": 1652197942,
                    "expirationTime": 1652227260,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9148",
                    "makerAddr": "0x1Dad1d2C851DC9A0622Dbb0876f12daB68eDC0C9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29600000000000000000",
                    "tokenId": "9101",
                    "listingTime": 1652197946,
                    "expirationTime": 1652227260,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9101",
                    "makerAddr": "0x1Dad1d2C851DC9A0622Dbb0876f12daB68eDC0C9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29600000000000000000",
                    "tokenId": "8256",
                    "listingTime": 1652197933,
                    "expirationTime": 1652227260,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8256",
                    "makerAddr": "0x1Dad1d2C851DC9A0622Dbb0876f12daB68eDC0C9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29600000000000000000",
                    "tokenId": "4367",
                    "listingTime": 1652197927,
                    "expirationTime": 1652227260,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4367",
                    "makerAddr": "0x1Dad1d2C851DC9A0622Dbb0876f12daB68eDC0C9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29600000000000000000",
                    "tokenId": "3829",
                    "listingTime": 1652197938,
                    "expirationTime": 1652227260,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3829",
                    "makerAddr": "0x1Dad1d2C851DC9A0622Dbb0876f12daB68eDC0C9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29600000000000000000",
                    "tokenId": "8131",
                    "listingTime": 1652197949,
                    "expirationTime": 1652227260,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8131",
                    "makerAddr": "0x1Dad1d2C851DC9A0622Dbb0876f12daB68eDC0C9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29600000000000000000",
                    "tokenId": "20212",
                    "listingTime": 1652197953,
                    "expirationTime": 1652227260,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20212",
                    "makerAddr": "0x1Dad1d2C851DC9A0622Dbb0876f12daB68eDC0C9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29600000000000000000",
                    "tokenId": "25562",
                    "listingTime": 1652197957,
                    "expirationTime": 1652227260,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25562",
                    "makerAddr": "0x1Dad1d2C851DC9A0622Dbb0876f12daB68eDC0C9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29650000000000000000",
                    "tokenId": "6682",
                    "listingTime": 1651889612,
                    "expirationTime": 1653398147,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6682",
                    "makerAddr": "0x71dfD2baB718F5bB30f1099494a68752B5d2f2e2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29680000000000000000",
                    "tokenId": "8413",
                    "listingTime": 1652188751,
                    "expirationTime": 1654780729,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8413",
                    "makerAddr": "0xfDA939603cbf1633a875e832b05685567446c622",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29690000000000000000",
                    "tokenId": "29837",
                    "listingTime": 1652084336,
                    "expirationTime": 1652282547,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29837",
                    "makerAddr": "0x5e525f13EBec9A839e7a5D69A901c726110ef179",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29690000000000000000",
                    "tokenId": "10842",
                    "listingTime": 1651943657,
                    "expirationTime": 1652202846,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10842",
                    "makerAddr": "0xcBa6A2C3f59c8efD9C823Db34BAc55f64454F171",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29690000000000000000",
                    "tokenId": "2255",
                    "listingTime": 1651665349,
                    "expirationTime": 1654257247,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2255",
                    "makerAddr": "0x8BD8Ec6284111958adA00F9574F0e3d7D4fb41C8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29690000000000000000",
                    "tokenId": "26032",
                    "listingTime": 1651877343,
                    "expirationTime": 1652227452,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26032",
                    "makerAddr": "0xc94893b6D1CC1d7ce09Fec6abC9B5949A903d2C9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29690000000000000000",
                    "tokenId": "9822",
                    "listingTime": 1652124744,
                    "expirationTime": 1652729539,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9822",
                    "makerAddr": "0x39071c01b1AD500f629a3Ef26871dCe3ae09aA10",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29690000000000000000",
                    "tokenId": "7325",
                    "listingTime": 1651863248,
                    "expirationTime": 1653499413,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7325",
                    "makerAddr": "0xe794f5e304109E77FE7b7E6D5bD9B1D69C6560A8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29700000000000000000",
                    "tokenId": "3618",
                    "listingTime": 1651975527,
                    "expirationTime": 1652234717,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3618",
                    "makerAddr": "0x399cfD2B89a6C0bD12b8E905dC75F6dD2752D902",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29750000000000000000",
                    "tokenId": "24129",
                    "listingTime": 1652195157,
                    "expirationTime": 1652540700,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24129",
                    "makerAddr": "0xd2525DAc5BE205069E631A9d3465Ab49155d8769",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29800000000000000000",
                    "tokenId": "2446",
                    "listingTime": 1651473299,
                    "expirationTime": 1654065234,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2446",
                    "makerAddr": "0x39aE891de01e34b54247D27f2a5469011b6CfbE9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29800000000000000000",
                    "tokenId": "1840",
                    "listingTime": 1651966167,
                    "expirationTime": 1653995626,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1840",
                    "makerAddr": "0x112D352E59ed2165052F2fB00e00D3B4B87552fD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29800000000000000000",
                    "tokenId": "3264",
                    "listingTime": 1651997260,
                    "expirationTime": 1654359719,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3264",
                    "makerAddr": "0xfBb3669A32E0814ee3491a1581a40b2Bf0F512a2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29800000000000000000",
                    "tokenId": "674",
                    "listingTime": 1652197484,
                    "expirationTime": 1652456628,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/674",
                    "makerAddr": "0x43A35CBcd9034D2aD57c3Df8Ea63C5B643f9125a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29800000000000000000",
                    "tokenId": "4200",
                    "listingTime": 1652089511,
                    "expirationTime": 1652273657,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4200",
                    "makerAddr": "0x54Ca39290A74cACf4eB51a1E85710ecACb161222",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29800000000000000000",
                    "tokenId": "7085",
                    "listingTime": 1651953820,
                    "expirationTime": 1653980042,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7085",
                    "makerAddr": "0x2D66B7eA0c89158d7274aeF1019423d968F7864F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29800000000000000000",
                    "tokenId": "487",
                    "listingTime": 1652106688,
                    "expirationTime": 1654698536,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/487",
                    "makerAddr": "0x4F72881fddBD01E1445741B7F33c45C2D4A53718",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29800000000000000000",
                    "tokenId": "554",
                    "listingTime": 1652197964,
                    "expirationTime": 1652802743,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/554",
                    "makerAddr": "0xd0A18B8BeB20518B98F068D7D44B11Ba2b141B39",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29800000000000000000",
                    "tokenId": "218",
                    "listingTime": 1652011423,
                    "expirationTime": 1652275048,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/218",
                    "makerAddr": "0x5C7Af7bFD94cb9dAEbdE66Ed922a34d68949Ee20",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "674",
                    "price": "29800000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652197360,
                    "expirationTime": 1652456628,
                    "makerAddr": "0x43a35cbcd9034d2ad57c3df8ea63c5b643f9125a",
                    "assetImageUrl": "https://img.seadn.io/files/0502b50516a9e52656f81f792806664b.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2446",
                    "price": "29800000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651472917,
                    "expirationTime": 1654151403,
                    "makerAddr": "0x39ae891de01e34b54247d27f2a5469011b6cfbe9",
                    "assetImageUrl": "https://img.seadn.io/files/8c0ccc7b5832e4aea3ee3b8ec98e7081.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29880000000000000000",
                    "tokenId": "20156",
                    "listingTime": 1652000210,
                    "expirationTime": 1652579538,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20156",
                    "makerAddr": "0xF9f02deF55E1ccEEDF017E0353c14Ba6F7F4d3Df",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29880000000000000000",
                    "tokenId": "1948",
                    "listingTime": 1652010948,
                    "expirationTime": 1654112606,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1948",
                    "makerAddr": "0x7a704A51b9D9Dab9f3bFFBD0685a25f70661b64d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29890000000000000000",
                    "tokenId": "20082",
                    "listingTime": 1652197780,
                    "expirationTime": 1652543640,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20082",
                    "makerAddr": "0x9Ce5950704892324F1C9a93aACEc436Fb460A4A1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29890000000000000000",
                    "tokenId": "1152",
                    "listingTime": 1652010811,
                    "expirationTime": 1652270007,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1152",
                    "makerAddr": "0x87A426503c541876F86907600184B29dA462078a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29900000000000000000",
                    "tokenId": "8208",
                    "listingTime": 1652137122,
                    "expirationTime": 1652396300,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8208",
                    "makerAddr": "0x0E3F8387D87A2B3981AC8B32a9db78E37a6f53c9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29900000000000000000",
                    "tokenId": "22690",
                    "listingTime": 1651953523,
                    "expirationTime": 1652212718,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22690",
                    "makerAddr": "0xe9E7F10a129Ed34be0cAB9e1Fb0c8a309D3526D9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29900000000000000000",
                    "tokenId": "6795",
                    "listingTime": 1651977951,
                    "expirationTime": 1652198940,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6795",
                    "makerAddr": "0xFBe36362c0F2ffAA3c436a95F959Ae7F8ddd26b2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29900000000000000000",
                    "tokenId": "3351",
                    "listingTime": 1651943271,
                    "expirationTime": 1654534728,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3351",
                    "makerAddr": "0xDbb3F1Bdc0353Eba1C57Dce84FC6A5C3975b321f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29900000000000000000",
                    "tokenId": "23174",
                    "listingTime": 1651942480,
                    "expirationTime": 1658677747,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23174",
                    "makerAddr": "0x7e2225B3e54A7ad0f2D009237a4f136C02F90a52",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29900000000000000000",
                    "tokenId": "9749",
                    "listingTime": 1651988401,
                    "expirationTime": 1652247573,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9749",
                    "makerAddr": "0x19f01a2EE918DC26B740B34ae0D8A4986Be271b1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "487",
                    "price": "29900000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652067378,
                    "expirationTime": 1654657745,
                    "makerAddr": "0x4f72881fddbd01e1445741b7f33c45c2d4a53718",
                    "assetImageUrl": "https://img.seadn.io/files/98e0994ba198aee7eb5e41b2bb19e53d.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2596",
                    "price": "29910000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652094642,
                    "expirationTime": 1652345730,
                    "makerAddr": "0x14ba3316bdf0471879e91c6d5fd5aca9d36613ec",
                    "assetImageUrl": "https://img.seadn.io/files/574e0d748edbc6a34ff9477772c08a54.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29960000000000000000",
                    "tokenId": "23316",
                    "listingTime": 1652009115,
                    "expirationTime": 1654600894,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23316",
                    "makerAddr": "0xaAB1e59A453f30eb52fdc2e5B3e13dcB3B1595c5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29990000000000000000",
                    "tokenId": "11480",
                    "listingTime": 1651846604,
                    "expirationTime": 1654438595,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11480",
                    "makerAddr": "0xb2AB83B605bf7b9E70cA18415A16dC6239BA14a8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29990000000000000000",
                    "tokenId": "800",
                    "listingTime": 1652003335,
                    "expirationTime": 1653950368,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/800",
                    "makerAddr": "0xC03BDA89a4CA08036058E6e111C4beacd7246051",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29990000000000000000",
                    "tokenId": "8847",
                    "listingTime": 1652194012,
                    "expirationTime": 1652280340,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8847",
                    "makerAddr": "0xc670D2A9C2B7c4910160625323E747480AeaE584",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29990000000000000000",
                    "tokenId": "22768",
                    "listingTime": 1651860250,
                    "expirationTime": 1654184618,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22768",
                    "makerAddr": "0xCF215c0a4A88003288c855536776666709025E62",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29990000000000000000",
                    "tokenId": "5399",
                    "listingTime": 1651866086,
                    "expirationTime": 1654045200,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5399",
                    "makerAddr": "0x13c3258823fF9C506cb72291672dc9ea1E5517E4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29990000000000000000",
                    "tokenId": "22960",
                    "listingTime": 1651962453,
                    "expirationTime": 1654554354,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22960",
                    "makerAddr": "0x5e55424a256C5D1e73F981a8116c390f670BD756",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29990000000000000000",
                    "tokenId": "819",
                    "listingTime": 1652195560,
                    "expirationTime": 1652281726,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/819",
                    "makerAddr": "0xe0b2A5594266585558d3A88cB3299e721524641E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29990000000000000000",
                    "tokenId": "14826",
                    "listingTime": 1651878938,
                    "expirationTime": 1653401558,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14826",
                    "makerAddr": "0xdBE2258624F94aB8EE30CeB67B2a078b24bB6d6d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29990000000000000000",
                    "tokenId": "6274",
                    "listingTime": 1652194794,
                    "expirationTime": 1652453934,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6274",
                    "makerAddr": "0x0002A983D14F932480a5cb8f8a896697b892529A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29990000000000000000",
                    "tokenId": "26288",
                    "listingTime": 1651777713,
                    "expirationTime": 1654025613,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26288",
                    "makerAddr": "0x2dc23418844EACD31a7D91A5571E000960553283",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29990000000000000000",
                    "tokenId": "9573",
                    "listingTime": 1651999883,
                    "expirationTime": 1652259041,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9573",
                    "makerAddr": "0xEBC6d7eD591caAA959ed3A733a70Ee063cd3CC96",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "819",
                    "price": "29990000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652195240,
                    "expirationTime": 1652281726,
                    "makerAddr": "0xe0b2a5594266585558d3a88cb3299e721524641e",
                    "assetImageUrl": "https://img.seadn.io/files/ed2f1b75a97edfe24c41bf752c6dee11.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1491",
                    "price": "29990000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652105440,
                    "expirationTime": 1654783349,
                    "makerAddr": "0x4a95836eae95b286e20b3296aa49ae59712a0d87",
                    "assetImageUrl": "https://img.seadn.io/files/8334bbd226a6aba07bf0eb3b0d37e3e0.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29998000000000000000",
                    "tokenId": "18313",
                    "listingTime": 1652182511,
                    "expirationTime": 1652268903,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18313",
                    "makerAddr": "0x5d21D9dB0062aE015B19Fc6116095071f45e0FCA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "29999000000000000000",
                    "tokenId": "10944",
                    "listingTime": 1651877698,
                    "expirationTime": 1652482497,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10944",
                    "makerAddr": "0x7F37Cb4E1fF71193175B11c16FaA7d97aa851876",
                    "assetImageUrl": ""
                }
            ],
            "total": 6.851481999999983e+21,
            "looksrare": 224,
            "opensea": 10
        },
        {
            "price": 30,
            "amount": 200,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "8192",
                    "listingTime": 1652128517,
                    "expirationTime": 1652214914,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8192",
                    "makerAddr": "0xc578958DD1880cF00BFfbb7Feb9c28cbbbcaD3bF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "2226",
                    "listingTime": 1651748860,
                    "expirationTime": 1652268073,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2226",
                    "makerAddr": "0x36602d9F655E88fF539bef1Acc4A731050C8346F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "190",
                    "listingTime": 1652069160,
                    "expirationTime": 1652328346,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/190",
                    "makerAddr": "0x2e5EE0f23b930b4C12217d914ce0d1C9F794e284",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "22334",
                    "listingTime": 1652011128,
                    "expirationTime": 1653407905,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22334",
                    "makerAddr": "0x1abEd56b27691Ba3b5c0c68D2B0Be0f1638e3D4f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "7250",
                    "listingTime": 1651768260,
                    "expirationTime": 1659543989,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7250",
                    "makerAddr": "0xf44Ba99a809DEff71fA376fA971a659E5b5DC17c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "5893",
                    "listingTime": 1651812442,
                    "expirationTime": 1653803334,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5893",
                    "makerAddr": "0x1FEACcB479834998BD7750754062347A6FaD8F9F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "1020",
                    "listingTime": 1652195228,
                    "expirationTime": 1652281580,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1020",
                    "makerAddr": "0xEb2A4839B75d0E18dA39c58E3804AF095193938b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "8981",
                    "listingTime": 1651822668,
                    "expirationTime": 1654414442,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8981",
                    "makerAddr": "0x87de18D54599A79354A69B0cBABc47eA988c0acD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "5936",
                    "listingTime": 1652135799,
                    "expirationTime": 1652209200,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5936",
                    "makerAddr": "0xC47c12d52f18B864f5DA34586fc396f69bc2F70a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "2426",
                    "listingTime": 1651973486,
                    "expirationTime": 1652926732,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2426",
                    "makerAddr": "0xEa0ed16746257eb2BC11DE2FefD63cDEECe23A98",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "2186",
                    "listingTime": 1651882239,
                    "expirationTime": 1652398964,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2186",
                    "makerAddr": "0x5682035b6b1D04924C7661b09A974fF0695De6bE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "7752",
                    "listingTime": 1651767512,
                    "expirationTime": 1652372297,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7752",
                    "makerAddr": "0x7AC8BfC6f1842C1A7c1b9a1e8D60972e79075766",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "2345",
                    "listingTime": 1651832355,
                    "expirationTime": 1653924638,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2345",
                    "makerAddr": "0xD05Defee18E718F21337F5355317f915a34A101B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "7014",
                    "listingTime": 1651901398,
                    "expirationTime": 1654254689,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7014",
                    "makerAddr": "0x908c6143512b4Ec00170c56e8954746EF7b5A577",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "10578",
                    "listingTime": 1652049546,
                    "expirationTime": 1652308590,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10578",
                    "makerAddr": "0x34cCc716Fa3f6c667dae0E6D9b1e0a9418873b8a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "9436",
                    "listingTime": 1651901307,
                    "expirationTime": 1653366760,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9436",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "5650",
                    "listingTime": 1651997961,
                    "expirationTime": 1653502779,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5650",
                    "makerAddr": "0x7b9CDCC6831796fCcB21b5f7241e2cEd813Efe92",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "6250",
                    "listingTime": 1651766972,
                    "expirationTime": 1653485399,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6250",
                    "makerAddr": "0x989cE0d5f551d94EC87E638Dc643c014e63a0c0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "10032",
                    "listingTime": 1651955342,
                    "expirationTime": 1653351474,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10032",
                    "makerAddr": "0x5126bEd5a2B3619D5e5afab8d6c888004AA5e8C7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "28070",
                    "listingTime": 1651901330,
                    "expirationTime": 1653366831,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28070",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "10754",
                    "listingTime": 1651936591,
                    "expirationTime": 1652273260,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10754",
                    "makerAddr": "0x9Fe176816F90145F8aEd9fFDedEE158ed8423bA2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "209",
                    "listingTime": 1651916100,
                    "expirationTime": 1654045890,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/209",
                    "makerAddr": "0x568d5132D2a2FAC4D5671509Ab9D787Af54D0810",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "2127",
                    "listingTime": 1651945717,
                    "expirationTime": 1654260868,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2127",
                    "makerAddr": "0x964F690Def870B36f017F6127Ab1B929645333C0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "3126",
                    "listingTime": 1651864034,
                    "expirationTime": 1653397648,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3126",
                    "makerAddr": "0x135400Da57c70Eb39Bd6A48a452e5A9961e8FE04",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "27421",
                    "listingTime": 1651953507,
                    "expirationTime": 1654487554,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27421",
                    "makerAddr": "0xdbFbe8C7b4a6328a2bC45aBa79F032C6d14bDe6b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "3827",
                    "listingTime": 1651869307,
                    "expirationTime": 1653408280,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3827",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "9088",
                    "listingTime": 1651859003,
                    "expirationTime": 1652292703,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9088",
                    "makerAddr": "0xf9bb1fe3dBcBA49F7Ab71485FA944eAE6605eD1f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "7351",
                    "listingTime": 1651884868,
                    "expirationTime": 1654011882,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7351",
                    "makerAddr": "0x56767eD9B7e23607Ba62a1CF41B8708b9Dbb5555",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "5287",
                    "listingTime": 1651901464,
                    "expirationTime": 1654254653,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5287",
                    "makerAddr": "0xCaE7bF3715CeeC1fC2863D9CE81E042136b34E80",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "25826",
                    "listingTime": 1651772348,
                    "expirationTime": 1654205485,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25826",
                    "makerAddr": "0x0D7D9AcF85FA0Bb5eE25bd8016B7c0f5A711c893",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "996",
                    "listingTime": 1651944945,
                    "expirationTime": 1652204140,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/996",
                    "makerAddr": "0x734242d7CC8735C12b56f0170A673296e22a142c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "11536",
                    "listingTime": 1651956003,
                    "expirationTime": 1654217185,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11536",
                    "makerAddr": "0x0D7D9AcF85FA0Bb5eE25bd8016B7c0f5A711c893",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "18494",
                    "listingTime": 1652075063,
                    "expirationTime": 1652679854,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18494",
                    "makerAddr": "0x7D176A8FF5b038A85923aE80b420534B9CB3B40F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "29578",
                    "listingTime": 1651964136,
                    "expirationTime": 1652223333,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29578",
                    "makerAddr": "0xE0A50615A1316C5671b138Dfa7E02BD5954Cf07B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "26532",
                    "listingTime": 1651964114,
                    "expirationTime": 1652223311,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26532",
                    "makerAddr": "0xE0A50615A1316C5671b138Dfa7E02BD5954Cf07B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "8783",
                    "listingTime": 1652022577,
                    "expirationTime": 1652281773,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8783",
                    "makerAddr": "0xFA0f59502986865db617DA07CfeAa299729a70f8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "27290",
                    "listingTime": 1651937213,
                    "expirationTime": 1652219588,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27290",
                    "makerAddr": "0x168109aeA51511c3787e56608804196FAa28CE24",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "4400",
                    "listingTime": 1651960982,
                    "expirationTime": 1653170578,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4400",
                    "makerAddr": "0x61a4FC9028048e562b2a49d8dccf2378B644dF03",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "25864",
                    "listingTime": 1651888943,
                    "expirationTime": 1654275661,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25864",
                    "makerAddr": "0x8cc24CF5970F9c7AA94aB00Fbf9e6E86fd01c41B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "8739",
                    "listingTime": 1651986683,
                    "expirationTime": 1652349246,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8739",
                    "makerAddr": "0x182Db69f652149ddda92a1756731b94CF9fb9413",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "5015",
                    "listingTime": 1651963997,
                    "expirationTime": 1652223188,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5015",
                    "makerAddr": "0xE0A50615A1316C5671b138Dfa7E02BD5954Cf07B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "26420",
                    "listingTime": 1651963925,
                    "expirationTime": 1653493647,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26420",
                    "makerAddr": "0x407CAe1E3E4C30Bfd038FC8c686ACE87B59D33f4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "2270",
                    "listingTime": 1651837256,
                    "expirationTime": 1654163422,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2270",
                    "makerAddr": "0x75872C384475117E8C19BA27E1fBE8473aF9ebC5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "28600",
                    "listingTime": 1651801919,
                    "expirationTime": 1654393858,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28600",
                    "makerAddr": "0xA02D00397b74B4eCA509CF73347E5346fC5Ff0b7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "1363",
                    "listingTime": 1651986731,
                    "expirationTime": 1652349071,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1363",
                    "makerAddr": "0x182Db69f652149ddda92a1756731b94CF9fb9413",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "5134",
                    "listingTime": 1651875324,
                    "expirationTime": 1654047716,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5134",
                    "makerAddr": "0xEAA06F6242836cf6641a5d43C8f519224e5A4DD5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "2606",
                    "listingTime": 1651995704,
                    "expirationTime": 1652254899,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2606",
                    "makerAddr": "0xFa377EeD778F74266cFabCa542e61dCCb1215BC9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "5261",
                    "listingTime": 1652193228,
                    "expirationTime": 1654785203,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5261",
                    "makerAddr": "0x2C1Fd0CCeeD2b8ac4F3D943417B251EB9b08E209",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "10204",
                    "listingTime": 1651838035,
                    "expirationTime": 1653998400,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10204",
                    "makerAddr": "0x0b73F759868A249671ff2170F831f08E7Ea83721",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "17610",
                    "listingTime": 1651958164,
                    "expirationTime": 1652217358,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17610",
                    "makerAddr": "0x13928eB9A86c8278a45B6fF2935c7730b58AC675",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "5658",
                    "listingTime": 1651453061,
                    "expirationTime": 1653256997,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5658",
                    "makerAddr": "0xB4B000628E8e9e4391DF97052792F349aB9F593E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "28405",
                    "listingTime": 1651918023,
                    "expirationTime": 1652518472,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28405",
                    "makerAddr": "0x8113c79742A49332444bDd044e8aEd33A5B1DeBC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "2667",
                    "listingTime": 1652046487,
                    "expirationTime": 1652651283,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2667",
                    "makerAddr": "0x44c1D4AA76e9d83384f6BDdd547Ce30E4b7832a9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "4565",
                    "listingTime": 1652006980,
                    "expirationTime": 1652611775,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4565",
                    "makerAddr": "0x8657B52C85539Eb3843B679fF37Cd2980E04D96F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "11270",
                    "listingTime": 1651801947,
                    "expirationTime": 1654304342,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11270",
                    "makerAddr": "0x843f2C19bc6df9E32B482E2F9ad6C078001088b1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "5182",
                    "listingTime": 1651888179,
                    "expirationTime": 1653424803,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5182",
                    "makerAddr": "0xE4F9a6Bb8C34166ef8D7b9bCe6B6F7177549E367",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "15838",
                    "listingTime": 1652065569,
                    "expirationTime": 1654104112,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15838",
                    "makerAddr": "0x278BfD54e7E71346F92eF36C6e34145d14F017Cd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "26562",
                    "listingTime": 1651971802,
                    "expirationTime": 1652231000,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26562",
                    "makerAddr": "0xAA05563B84844f8f8bB1C9ab9ca1eA5F4fAF6fce",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "7142",
                    "listingTime": 1651850469,
                    "expirationTime": 1652774548,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7142",
                    "makerAddr": "0x5853dC7fE370FB7c600C3eAf0e27b800849cBB7e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "25262",
                    "listingTime": 1651939992,
                    "expirationTime": 1654022350,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25262",
                    "makerAddr": "0x80dE2F874067B092BcB914144EFBe1B54A731F4d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "23926",
                    "listingTime": 1651886108,
                    "expirationTime": 1653579875,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23926",
                    "makerAddr": "0xf1b1cF1a44CadE3d7a0962d61abDC475f1f3D093",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "2914",
                    "listingTime": 1651901273,
                    "expirationTime": 1653366800,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2914",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "13493",
                    "listingTime": 1652065585,
                    "expirationTime": 1654036421,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13493",
                    "makerAddr": "0x278BfD54e7E71346F92eF36C6e34145d14F017Cd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "1122",
                    "listingTime": 1651806572,
                    "expirationTime": 1653443992,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1122",
                    "makerAddr": "0xBe547aA1bf50FEcCa7d853adEa3aB6D5F85953F7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "3676",
                    "listingTime": 1651765652,
                    "expirationTime": 1652370324,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3676",
                    "makerAddr": "0x458c1Adad9979b1eDa96a79C53ce5203762d5F18",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "4556",
                    "listingTime": 1651838423,
                    "expirationTime": 1659614399,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4556",
                    "makerAddr": "0x391258B51619812800a2e24A53ff6d39eb34C50d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "20902",
                    "listingTime": 1652038225,
                    "expirationTime": 1652297398,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20902",
                    "makerAddr": "0xc99F958eDA1F3c7E173B8187525dC0C71A0b371A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "21280",
                    "listingTime": 1651947198,
                    "expirationTime": 1653402254,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21280",
                    "makerAddr": "0x57be93fB6a01E435aaC84E2f52566E6f7296f578",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "7545",
                    "listingTime": 1652135828,
                    "expirationTime": 1654254358,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7545",
                    "makerAddr": "0x8d599028E270798c349ddE3570E696E6D2d2A4F5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "3481",
                    "listingTime": 1651820921,
                    "expirationTime": 1654252816,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3481",
                    "makerAddr": "0x6A1BA3300800746eb55b84e7D1D913cEB76D489f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "10516",
                    "listingTime": 1652049535,
                    "expirationTime": 1652308590,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10516",
                    "makerAddr": "0x34cCc716Fa3f6c667dae0E6D9b1e0a9418873b8a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "5026",
                    "listingTime": 1651901290,
                    "expirationTime": 1654076029,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5026",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "5783",
                    "listingTime": 1651879260,
                    "expirationTime": 1667215195,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5783",
                    "makerAddr": "0xCD750f5854eCE8252FB7d59D4a8Be7864241d50D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "9759",
                    "listingTime": 1651943208,
                    "expirationTime": 1652591196,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9759",
                    "makerAddr": "0x4F33D206eAEccC0674158bCbCf44711B9454d7aC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "14982",
                    "listingTime": 1651955971,
                    "expirationTime": 1652401200,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14982",
                    "makerAddr": "0x0D7D9AcF85FA0Bb5eE25bd8016B7c0f5A711c893",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "24054",
                    "listingTime": 1651990416,
                    "expirationTime": 1654091474,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24054",
                    "makerAddr": "0xC3d34cf9d526db02b8a10Fa72897a57FeaBdf82A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "20360",
                    "listingTime": 1651727866,
                    "expirationTime": 1653716530,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20360",
                    "makerAddr": "0x5eb01242b23A2A53cd5Dc612F07B5468F95f72cB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "4584",
                    "listingTime": 1651856110,
                    "expirationTime": 1653992259,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4584",
                    "makerAddr": "0xab36Ef38d02E5aeC0fCcBc6B76772A0CF3E69A38",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "5274",
                    "listingTime": 1651901537,
                    "expirationTime": 1654254510,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5274",
                    "makerAddr": "0xaBeA9711C5672C870f65835Ff84Cea14c353EE57",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "665",
                    "listingTime": 1651890922,
                    "expirationTime": 1667442918,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/665",
                    "makerAddr": "0xF7480A958bFA8EF59C52CA995C1d3518968ce2d9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "3149",
                    "listingTime": 1652190739,
                    "expirationTime": 1652277137,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3149",
                    "makerAddr": "0x3333e1790A30FeFb7d0c8e22675f0392C05Fa640",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "205",
                    "listingTime": 1651898749,
                    "expirationTime": 1653981354,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/205",
                    "makerAddr": "0x53f42691DF74AD573F5B8a461EBEFe09ECddf920",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "11898",
                    "listingTime": 1651862179,
                    "expirationTime": 1654371761,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11898",
                    "makerAddr": "0x4f940D90A643a27546C885B3165b31d624f80c15",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "8850",
                    "listingTime": 1652049535,
                    "expirationTime": 1652308620,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8850",
                    "makerAddr": "0x0c2dB7Cb6D59B0f68c72480460b1d225E71740B4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "3835",
                    "listingTime": 1652089629,
                    "expirationTime": 1653299207,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3835",
                    "makerAddr": "0x21A0F52DAa934786A5EF80c34B9De8b94Fdc9822",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "32",
                    "listingTime": 1651847756,
                    "expirationTime": 1654439752,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/32",
                    "makerAddr": "0x5bfde66bde613887dC0E32Ac1D95b259FeE9a2cA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "9214",
                    "listingTime": 1651792808,
                    "expirationTime": 1654384804,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9214",
                    "makerAddr": "0x10F11718f2bb21ad75eBb006EdBc080907C45777",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "7455",
                    "listingTime": 1651869324,
                    "expirationTime": 1653408852,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7455",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "17826",
                    "listingTime": 1651856120,
                    "expirationTime": 1653992284,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17826",
                    "makerAddr": "0xab36Ef38d02E5aeC0fCcBc6B76772A0CF3E69A38",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "27656",
                    "listingTime": 1651851154,
                    "expirationTime": 1654260118,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27656",
                    "makerAddr": "0xD880c6dcF441b6C26c2701174926F54bC1BAbDF0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "22748",
                    "listingTime": 1651862108,
                    "expirationTime": 1652591644,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22748",
                    "makerAddr": "0x2155D5C0A63AF068a00443483C4CD572ae73b09b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "2347",
                    "listingTime": 1651859153,
                    "expirationTime": 1654451146,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2347",
                    "makerAddr": "0x7b4CEB9fe3A9ECce763Fb6727E9554E2723DbD69",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "5582",
                    "listingTime": 1651931165,
                    "expirationTime": 1653603931,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5582",
                    "makerAddr": "0xA25D7e569393583f2a17007b5619452E25fB40b9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "9967",
                    "listingTime": 1652050765,
                    "expirationTime": 1653871848,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9967",
                    "makerAddr": "0x440c7504C2FCaC49bA1cb429C6883F1E15819b30",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "22290",
                    "listingTime": 1651996186,
                    "expirationTime": 1653205772,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22290",
                    "makerAddr": "0x493dd66Ff1e2484Fe21A31CD72B08292488A81A4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "25254",
                    "listingTime": 1651862541,
                    "expirationTime": 1652252043,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25254",
                    "makerAddr": "0x1256497A1b0729E167BC52E2b08EE23a888184C9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "28104",
                    "listingTime": 1651970468,
                    "expirationTime": 1653409436,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28104",
                    "makerAddr": "0xF83FC598fD45171d86f81ba032C46DC07aCc415f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "1339",
                    "listingTime": 1651939648,
                    "expirationTime": 1652198841,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1339",
                    "makerAddr": "0x3938bE78A8C76c181dEf844713913f3295aad16D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "3083",
                    "listingTime": 1651851661,
                    "expirationTime": 1653414972,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3083",
                    "makerAddr": "0xC344FCe3710d5971b24b11947535FAcE3eCd5655",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "8852",
                    "listingTime": 1651869291,
                    "expirationTime": 1653852863,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8852",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "11114",
                    "listingTime": 1651970481,
                    "expirationTime": 1653409494,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11114",
                    "makerAddr": "0xF83FC598fD45171d86f81ba032C46DC07aCc415f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "12762",
                    "listingTime": 1651851176,
                    "expirationTime": 1654260159,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12762",
                    "makerAddr": "0xD880c6dcF441b6C26c2701174926F54bC1BAbDF0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "1304",
                    "listingTime": 1651885453,
                    "expirationTime": 1654091917,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1304",
                    "makerAddr": "0x6b3d3fCDB08d0813FDf0E1267C2eca056EAB3415",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "11036",
                    "listingTime": 1651988493,
                    "expirationTime": 1652254586,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11036",
                    "makerAddr": "0xa75927010c128F218D7046156ef2f3C68CcEd056",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "5188",
                    "listingTime": 1651901495,
                    "expirationTime": 1654254589,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5188",
                    "makerAddr": "0xb1d802566C228D1cB0484f29Fc7CaA009e75A1ef",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "17198",
                    "listingTime": 1651986709,
                    "expirationTime": 1652349028,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17198",
                    "makerAddr": "0x182Db69f652149ddda92a1756731b94CF9fb9413",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "5640",
                    "listingTime": 1652077205,
                    "expirationTime": 1654669204,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5640",
                    "makerAddr": "0x54950EEf3B0e890D0bf17Fdbbdee8e4920BE7c13",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "9118",
                    "listingTime": 1651901566,
                    "expirationTime": 1654254448,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9118",
                    "makerAddr": "0x44bdd22FFb03b5077A44b8E01d23170CA07E77a5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "29534",
                    "listingTime": 1651882294,
                    "expirationTime": 1654123279,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29534",
                    "makerAddr": "0x268993C1923Cf4d6db408f5109A53FCf17ed4039",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "26646",
                    "listingTime": 1652117169,
                    "expirationTime": 1652203546,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26646",
                    "makerAddr": "0x9ebe628D4552b18a947eDf977E89FaB7310dDf3A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "21404",
                    "listingTime": 1651903535,
                    "expirationTime": 1652316520,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21404",
                    "makerAddr": "0x2e9BC111159cC4FE5479eCC81F1825cFD577E077",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "23035",
                    "listingTime": 1652119207,
                    "expirationTime": 1652723986,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23035",
                    "makerAddr": "0xeA0591576BfF20C6CD220F0DCA550eB7EB9Fd077",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "14966",
                    "listingTime": 1651949062,
                    "expirationTime": 1653405602,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14966",
                    "makerAddr": "0x2319b02CA79f3253339b06baCB9a1de9C00A61e9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "9584",
                    "listingTime": 1651847577,
                    "expirationTime": 1653995241,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9584",
                    "makerAddr": "0x3dE262F19795845534A6e8D604d0Da383C2FCC9D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "8962",
                    "listingTime": 1651539012,
                    "expirationTime": 1653527152,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8962",
                    "makerAddr": "0xB644CbB404aAa273ad0a8A8c5Fb5A9C0b3302Bb4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "233",
                    "listingTime": 1651756658,
                    "expirationTime": 1654258193,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/233",
                    "makerAddr": "0xAfB3116Ce35Db24b4163e0Bb3dA0252240434F56",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "4371",
                    "listingTime": 1651838930,
                    "expirationTime": 1652443674,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4371",
                    "makerAddr": "0x746a3A6760f5e9442c748e6E811a2497dba52FAA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "18864",
                    "listingTime": 1651899989,
                    "expirationTime": 1654491437,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18864",
                    "makerAddr": "0x123432244443B54409430979DF8333f9308A6040",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "6391",
                    "listingTime": 1652021461,
                    "expirationTime": 1652735045,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6391",
                    "makerAddr": "0x7Bb19A33ec9B3D501c0a6374f084682E9E000c43",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "905",
                    "listingTime": 1651959513,
                    "expirationTime": 1652218491,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/905",
                    "makerAddr": "0xF838F115EfB293D64389F6A1DB96ba6f61894401",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "3724",
                    "listingTime": 1651902479,
                    "expirationTime": 1653995981,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3724",
                    "makerAddr": "0x31D447ccF5ed1905287Ca964b72F5fef01682944",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "2034",
                    "listingTime": 1651973404,
                    "expirationTime": 1652232600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2034",
                    "makerAddr": "0x8b331D46A5f306E45d0Af12DDab91DcE46c93F27",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "9984",
                    "listingTime": 1651757745,
                    "expirationTime": 1652362542,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9984",
                    "makerAddr": "0x5Ec0D096f8ef2Ac2dBd3536e3dFE2db1361BA6a7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "331",
                    "listingTime": 1651800494,
                    "expirationTime": 1654023884,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/331",
                    "makerAddr": "0x0d4428C8E62e894999d8E4c1845f23cb5F9d919d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "5090",
                    "listingTime": 1651899967,
                    "expirationTime": 1654491437,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5090",
                    "makerAddr": "0x123432244443B54409430979DF8333f9308A6040",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "15702",
                    "listingTime": 1651884760,
                    "expirationTime": 1654169119,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15702",
                    "makerAddr": "0x4328D5d49dB3D1824a9C3A52013057E7cD5EDc12",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "25846",
                    "listingTime": 1651962246,
                    "expirationTime": 1652567027,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25846",
                    "makerAddr": "0xc0728553cb34a11728cBF02e4E888b2180B7E16c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "14030",
                    "listingTime": 1651854264,
                    "expirationTime": 1654446233,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14030",
                    "makerAddr": "0xAB44B030A1e9908f2b6751205C89fDcbe2f0a7Ed",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "79",
                    "listingTime": 1651865465,
                    "expirationTime": 1654047378,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/79",
                    "makerAddr": "0x1278Bf1cA85Bfe406e23502700C7Cb94eA36B21A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "6046",
                    "listingTime": 1651901232,
                    "expirationTime": 1653401649,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6046",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "5383",
                    "listingTime": 1651901257,
                    "expirationTime": 1653823241,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5383",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "6571",
                    "listingTime": 1651953717,
                    "expirationTime": 1652558507,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6571",
                    "makerAddr": "0xc22bf68E69d4eA6a8436195b96c0b79f508Fb6ff",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "6629",
                    "listingTime": 1652050419,
                    "expirationTime": 1654575908,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6629",
                    "makerAddr": "0x7c744ae8a87fF460E7860EF344612383b1863182",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "28752",
                    "listingTime": 1651885840,
                    "expirationTime": 1652490631,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28752",
                    "makerAddr": "0x707582bFF3f58f168be346d224a9693B0Ae3d56e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30000000000000000000",
                    "tokenId": "4735",
                    "listingTime": 1651878230,
                    "expirationTime": 1654470140,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4735",
                    "makerAddr": "0x57a4342ED31c8f53e164EF28B0182620d20290A5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "430",
                    "price": "30000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652102139,
                    "expirationTime": 1659229962,
                    "makerAddr": "0xa0cc020fa78924d8048beb69afc80517c0b9e888",
                    "assetImageUrl": "https://img.seadn.io/files/7bf6966e3fb8da48bddcf54cdf602582.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "545",
                    "price": "30000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652012785,
                    "expirationTime": 1652267748,
                    "makerAddr": "0x9f08b507410725ab74ed3d61ed471235f1ee7d16",
                    "assetImageUrl": "https://img.seadn.io/files/c7fe2e436b4b492c0a258595a3348c0d.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "639",
                    "price": "30000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652123833,
                    "expirationTime": 1659286185,
                    "makerAddr": "0x8bf2b644c73133899aceb3039ea79107db52cb62",
                    "assetImageUrl": "https://img.seadn.io/files/026216e7f4d71ca676ba1231837771c3.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1256",
                    "price": "30000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652192973,
                    "expirationTime": 1652279445,
                    "makerAddr": "0xa0fad79fb48640e8a5e2458e7e31011690d35262",
                    "assetImageUrl": "https://img.seadn.io/files/f9907e557ac2e744b24e175a76ba14b4.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1867",
                    "price": "30000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651804054,
                    "expirationTime": 1654482539,
                    "makerAddr": "0xa66c503b43399d2d8c3bf3d957e2713fece3e286",
                    "assetImageUrl": "https://img.seadn.io/files/42535f69f075d4f3d8d74ad3fb04ea04.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2347",
                    "price": "30000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651859237,
                    "expirationTime": 1654537541,
                    "makerAddr": "0x7b4ceb9fe3a9ecce763fb6727e9554e2723dbd69",
                    "assetImageUrl": "https://img.seadn.io/files/a154f6eb6a836b7926859cd834597295.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2704",
                    "price": "30000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651739206,
                    "expirationTime": 1654417682,
                    "makerAddr": "0x5181aaefa633db3539382f8a19fc62e2fb11975f",
                    "assetImageUrl": "https://img.seadn.io/files/abb65b86aaa6f9cfbbaf88d7ee6b5f9a.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2876",
                    "price": "30000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652108448,
                    "expirationTime": 1653411353,
                    "makerAddr": "0x0023107592fbba07b981b33a0a9660084003cb23",
                    "assetImageUrl": "https://img.seadn.io/files/220db68ec74cbb05100fd6464122145f.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2914",
                    "price": "30000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651756038,
                    "expirationTime": 1654434523,
                    "makerAddr": "0x66dda7b9c769171f2904a6050988746779360961",
                    "assetImageUrl": "https://img.seadn.io/files/ac49f1de1e64f7af0ba7e24a081af11a.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30050000000000000000",
                    "tokenId": "23814",
                    "listingTime": 1651753561,
                    "expirationTime": 1654345556,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23814",
                    "makerAddr": "0xBE2d830CBD4628b06DE78B4eE660E1385a9152A6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30100000000000000000",
                    "tokenId": "3903",
                    "listingTime": 1651852924,
                    "expirationTime": 1654276155,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3903",
                    "makerAddr": "0xe450D86A20CEAcF552F0E8e8ACbc573C7A91D80F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30100000000000000000",
                    "tokenId": "19592",
                    "listingTime": 1651935296,
                    "expirationTime": 1654272206,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19592",
                    "makerAddr": "0xb10175B169292D36Fe11822653873171ad6Bb3DC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30100000000000000000",
                    "tokenId": "7136",
                    "listingTime": 1651962989,
                    "expirationTime": 1652222179,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7136",
                    "makerAddr": "0x9bfE249bD946D71454F26f42d14305426a5776B3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30100000000000000000",
                    "tokenId": "2402",
                    "listingTime": 1651853897,
                    "expirationTime": 1654170888,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2402",
                    "makerAddr": "0xD9502fb95c72b118a4Ec6d060297cB3aeb7c50BA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30100000000000000000",
                    "tokenId": "20082",
                    "listingTime": 1651938851,
                    "expirationTime": 1652543640,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20082",
                    "makerAddr": "0x9Ce5950704892324F1C9a93aACEc436Fb460A4A1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30100000000000000000",
                    "tokenId": "344",
                    "listingTime": 1652022975,
                    "expirationTime": 1653232447,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/344",
                    "makerAddr": "0x014f3A8BBCC20c17428A8EE9214F4923e30ba4eE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30100000000000000000",
                    "tokenId": "7298",
                    "listingTime": 1651955274,
                    "expirationTime": 1652214439,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7298",
                    "makerAddr": "0x562Ab7DCA86F8947F5b066A663D83452954A71a2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30100000000000000000",
                    "tokenId": "23928",
                    "listingTime": 1652045183,
                    "expirationTime": 1653254772,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23928",
                    "makerAddr": "0x2Dae3C96d5b8A872Dda683Bd65f34F36d18fe194",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30169000000000000000",
                    "tokenId": "26000",
                    "listingTime": 1651956351,
                    "expirationTime": 1652561096,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26000",
                    "makerAddr": "0x6Be4cE49cCaB9B252b892597b9C568144C08714A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30180000000000000000",
                    "tokenId": "126",
                    "listingTime": 1651959508,
                    "expirationTime": 1652383541,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/126",
                    "makerAddr": "0x407F622FA81a8bB56ff4E17786A6A581E4Abb7e0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30200000000000000000",
                    "tokenId": "6629",
                    "listingTime": 1651997020,
                    "expirationTime": 1654575908,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6629",
                    "makerAddr": "0x7c744ae8a87fF460E7860EF344612383b1863182",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30240000000000000000",
                    "tokenId": "5454",
                    "listingTime": 1652019938,
                    "expirationTime": 1652219756,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5454",
                    "makerAddr": "0xA2B04c4221d7c6F66b22e535049ad5A72122d32C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30250000000000000000",
                    "tokenId": "20156",
                    "listingTime": 1651974752,
                    "expirationTime": 1652579538,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20156",
                    "makerAddr": "0xF9f02deF55E1ccEEDF017E0353c14Ba6F7F4d3Df",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30250000000000000000",
                    "tokenId": "4378",
                    "listingTime": 1651949311,
                    "expirationTime": 1652553996,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4378",
                    "makerAddr": "0x926d2562Cd25E2a8988449F61271c19fF65d2C04",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30300000000000000000",
                    "tokenId": "6279",
                    "listingTime": 1651932113,
                    "expirationTime": 1652270757,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6279",
                    "makerAddr": "0x44c1D4AA76e9d83384f6BDdd547Ce30E4b7832a9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30300000000000000000",
                    "tokenId": "8115",
                    "listingTime": 1651953498,
                    "expirationTime": 1654067758,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8115",
                    "makerAddr": "0x6BB56b82baD32B9950cCD84794a196c9e28f19cF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30300000000000000000",
                    "tokenId": "26520",
                    "listingTime": 1651932649,
                    "expirationTime": 1654524302,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26520",
                    "makerAddr": "0xCB6D8c47B0e33D990962d66E43321427c52c0c9F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30300000000000000000",
                    "tokenId": "29784",
                    "listingTime": 1651953408,
                    "expirationTime": 1653710988,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29784",
                    "makerAddr": "0xaDF631c4F37052B9F5eE63aD88975dDaaef0F7aA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30300000000000000000",
                    "tokenId": "14297",
                    "listingTime": 1651888336,
                    "expirationTime": 1653565894,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14297",
                    "makerAddr": "0x517b1c4B8C259255EE7b7aB85e6Ea0dc9a235c3E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30300000000000000000",
                    "tokenId": "6629",
                    "listingTime": 1651984206,
                    "expirationTime": 1654575908,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6629",
                    "makerAddr": "0x7c744ae8a87fF460E7860EF344612383b1863182",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30330000000000000000",
                    "tokenId": "26478",
                    "listingTime": 1651968650,
                    "expirationTime": 1652573432,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26478",
                    "makerAddr": "0x32b4116DB2b1268b2A97aBfd17e777A2286771ce",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30350000000000000000",
                    "tokenId": "24982",
                    "listingTime": 1651994797,
                    "expirationTime": 1653981900,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24982",
                    "makerAddr": "0x06b45bFDabD04C8f9E468FF8B94f025d7ae7d20e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30350000000000000000",
                    "tokenId": "12452",
                    "listingTime": 1651994775,
                    "expirationTime": 1653981900,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12452",
                    "makerAddr": "0x06b45bFDabD04C8f9E468FF8B94f025d7ae7d20e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30360000000000000000",
                    "tokenId": "22002",
                    "listingTime": 1652052852,
                    "expirationTime": 1653900248,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22002",
                    "makerAddr": "0x5c35046B6D3440d9784D279aAe33B04A9A691BE1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30366000000000000000",
                    "tokenId": "5454",
                    "listingTime": 1651960560,
                    "expirationTime": 1652219756,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5454",
                    "makerAddr": "0xA2B04c4221d7c6F66b22e535049ad5A72122d32C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30366000000000000000",
                    "tokenId": "7536",
                    "listingTime": 1651979144,
                    "expirationTime": 1654027892,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7536",
                    "makerAddr": "0x913782500cc8acDfC8AfA2591222d4Ad8771A9d1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30366000000000000000",
                    "tokenId": "9821",
                    "listingTime": 1651954909,
                    "expirationTime": 1652214106,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9821",
                    "makerAddr": "0x72caF7c477CCAB3F95913b9D8CDf35a1CaF25556",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30366000000000000000",
                    "tokenId": "921",
                    "listingTime": 1651979194,
                    "expirationTime": 1654027923,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/921",
                    "makerAddr": "0x913782500cc8acDfC8AfA2591222d4Ad8771A9d1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30366000000000000000",
                    "tokenId": "8357",
                    "listingTime": 1651975290,
                    "expirationTime": 1654567269,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8357",
                    "makerAddr": "0x01A59E630Dc2C5ac30BeE0E23d7024AD57aE67BF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30366000000000000000",
                    "tokenId": "1965",
                    "listingTime": 1651885454,
                    "expirationTime": 1654269857,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1965",
                    "makerAddr": "0x2BC52900a118390ea8A97B29eB5e7E75F1Cd912c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30370000000000000000",
                    "tokenId": "13056",
                    "listingTime": 1651900685,
                    "expirationTime": 1653782871,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13056",
                    "makerAddr": "0xe315eB04Fc42bEa83Ed213582e66051050A92F1c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30380000000000000000",
                    "tokenId": "25702",
                    "listingTime": 1651900723,
                    "expirationTime": 1653799437,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25702",
                    "makerAddr": "0xe315eB04Fc42bEa83Ed213582e66051050A92F1c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30380000000000000000",
                    "tokenId": "11036",
                    "listingTime": 1651899366,
                    "expirationTime": 1652254586,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11036",
                    "makerAddr": "0xa75927010c128F218D7046156ef2f3C68CcEd056",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30400000000000000000",
                    "tokenId": "9524",
                    "listingTime": 1651643991,
                    "expirationTime": 1652248789,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9524",
                    "makerAddr": "0xc9BB325Af49cF65511360984b54fD0fE02BA8905",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2707",
                    "price": "30420000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652155731,
                    "expirationTime": 1654834219,
                    "makerAddr": "0x7f5bc50b539dc282e82a72a517c8acc73f161431",
                    "assetImageUrl": "https://img.seadn.io/files/8bd13a4a1709cb59559568bf92aebc19.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30450000000000000000",
                    "tokenId": "9037",
                    "listingTime": 1652187709,
                    "expirationTime": 1654779690,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9037",
                    "makerAddr": "0x8096Da6cEd12B75684054ef16e1bf7e376353c29",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30500000000000000000",
                    "tokenId": "6046",
                    "listingTime": 1651860367,
                    "expirationTime": 1653401649,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6046",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30500000000000000000",
                    "tokenId": "8812",
                    "listingTime": 1651852215,
                    "expirationTime": 1652451907,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8812",
                    "makerAddr": "0xf6c9385Bc7764b31923B07BaC900ACDf886381a5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30500000000000000000",
                    "tokenId": "6442",
                    "listingTime": 1652194866,
                    "expirationTime": 1652454058,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6442",
                    "makerAddr": "0x0002A983D14F932480a5cb8f8a896697b892529A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30500000000000000000",
                    "tokenId": "14982",
                    "listingTime": 1651853137,
                    "expirationTime": 1652401200,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14982",
                    "makerAddr": "0x0D7D9AcF85FA0Bb5eE25bd8016B7c0f5A711c893",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30500000000000000000",
                    "tokenId": "5383",
                    "listingTime": 1651860461,
                    "expirationTime": 1653823241,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5383",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30500000000000000000",
                    "tokenId": "5582",
                    "listingTime": 1651853767,
                    "expirationTime": 1653603931,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5582",
                    "makerAddr": "0xA25D7e569393583f2a17007b5619452E25fB40b9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30500000000000000000",
                    "tokenId": "11536",
                    "listingTime": 1651853170,
                    "expirationTime": 1654217185,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11536",
                    "makerAddr": "0x0D7D9AcF85FA0Bb5eE25bd8016B7c0f5A711c893",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30660000000000000000",
                    "tokenId": "3349",
                    "listingTime": 1651934790,
                    "expirationTime": 1653089554,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3349",
                    "makerAddr": "0x604eB5D4126E3318EC27721Bd5059307684F5C89",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30690000000000000000",
                    "tokenId": "3091",
                    "listingTime": 1652030367,
                    "expirationTime": 1654095552,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3091",
                    "makerAddr": "0x1E4d44f00D42052326fBA19ecaD73846875B0557",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30690000000000000000",
                    "tokenId": "26032",
                    "listingTime": 1651833372,
                    "expirationTime": 1652227452,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26032",
                    "makerAddr": "0xc94893b6D1CC1d7ce09Fec6abC9B5949A903d2C9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30690000000000000000",
                    "tokenId": "20516",
                    "listingTime": 1652030417,
                    "expirationTime": 1654264978,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20516",
                    "makerAddr": "0x1E4d44f00D42052326fBA19ecaD73846875B0557",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30750000000000000000",
                    "tokenId": "5513",
                    "listingTime": 1651819591,
                    "expirationTime": 1654125141,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5513",
                    "makerAddr": "0xEC4762fb859a53c15D1689c08FD5E48C5c1C47Fa",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2129",
                    "price": "30750000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651765886,
                    "expirationTime": 1654099195,
                    "makerAddr": "0xfbcedd02cc16f95729abc2b2cd6a04f3afce2170",
                    "assetImageUrl": "https://img.seadn.io/files/55c118e3a4cc9819a4d817807a5b2f88.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30770000000000000000",
                    "tokenId": "7296",
                    "listingTime": 1651870811,
                    "expirationTime": 1653973345,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7296",
                    "makerAddr": "0x4c8F62f1498FA55D4158CdBFEA7783f84556a68e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30800000000000000000",
                    "tokenId": "4076",
                    "listingTime": 1651861329,
                    "expirationTime": 1654453293,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4076",
                    "makerAddr": "0xB1Ec54030e383b9cE0cDAA988ba52364bFCe9c3C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30800000000000000000",
                    "tokenId": "1965",
                    "listingTime": 1651861213,
                    "expirationTime": 1654269857,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1965",
                    "makerAddr": "0x2BC52900a118390ea8A97B29eB5e7E75F1Cd912c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30800000000000000000",
                    "tokenId": "14314",
                    "listingTime": 1651819302,
                    "expirationTime": 1654411195,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14314",
                    "makerAddr": "0x404203D185E9aeA3f07769498c147044592548dB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30900000000000000000",
                    "tokenId": "22548",
                    "listingTime": 1651420289,
                    "expirationTime": 1654012273,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22548",
                    "makerAddr": "0xb104371D5a2680fB0d47eA9A3aA2348392454186",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "30990000000000000000",
                    "tokenId": "8765",
                    "listingTime": 1651387964,
                    "expirationTime": 1653979962,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8765",
                    "makerAddr": "0xbE3418aE6D38A6B6927704600D258cD353A94215",
                    "assetImageUrl": ""
                }
            ],
            "total": 6.022714999999999e+21,
            "looksrare": 189,
            "opensea": 11
        },
        {
            "price": 31,
            "amount": 66,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "15244",
                    "listingTime": 1651875438,
                    "expirationTime": 1654219385,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15244",
                    "makerAddr": "0x28570D9d66627e0733dFE4FCa79B8fD5b8128636",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "15776",
                    "listingTime": 1651620305,
                    "expirationTime": 1654212296,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15776",
                    "makerAddr": "0xFbB6e5E82d6a688381714B5B680096D717a1f031",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "28328",
                    "listingTime": 1652082116,
                    "expirationTime": 1652270918,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28328",
                    "makerAddr": "0xb7e659C522115Cfef11559EE10220875C6CFFE02",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "24448",
                    "listingTime": 1651873937,
                    "expirationTime": 1653405104,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24448",
                    "makerAddr": "0x3922B064DcC34289B2c04f82B92977c413a0dAd2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "825",
                    "listingTime": 1651853160,
                    "expirationTime": 1652585020,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/825",
                    "makerAddr": "0xa7e4f12B7Cd97387e8607daC17B439ca7cad58e9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "1568",
                    "listingTime": 1651828173,
                    "expirationTime": 1653992137,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1568",
                    "makerAddr": "0x835ba4bA03713208Fa8404acd626466C9273F93c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "8739",
                    "listingTime": 1651871067,
                    "expirationTime": 1652349246,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8739",
                    "makerAddr": "0x182Db69f652149ddda92a1756731b94CF9fb9413",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "665",
                    "listingTime": 1651854641,
                    "expirationTime": 1667406631,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/665",
                    "makerAddr": "0xF7480A958bFA8EF59C52CA995C1d3518968ce2d9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "18864",
                    "listingTime": 1651899458,
                    "expirationTime": 1654491437,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18864",
                    "makerAddr": "0x123432244443B54409430979DF8333f9308A6040",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "5274",
                    "listingTime": 1651850839,
                    "expirationTime": 1654254510,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5274",
                    "makerAddr": "0xaBeA9711C5672C870f65835Ff84Cea14c353EE57",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "7142",
                    "listingTime": 1651846528,
                    "expirationTime": 1652774548,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7142",
                    "makerAddr": "0x5853dC7fE370FB7c600C3eAf0e27b800849cBB7e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "3817",
                    "listingTime": 1651857935,
                    "expirationTime": 1658344451,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3817",
                    "makerAddr": "0xFe6eC7350dE7742FAD9717545bB46cC9a7abCeCB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "20438",
                    "listingTime": 1651872443,
                    "expirationTime": 1654053758,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20438",
                    "makerAddr": "0xD9D728Eb0d7e4Ec9d256D458923eB64E59c5370f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "29956",
                    "listingTime": 1651872942,
                    "expirationTime": 1654449376,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29956",
                    "makerAddr": "0x7685274EeE0D73BB916F1e1c8d126967CA63dC21",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "18108",
                    "listingTime": 1651853068,
                    "expirationTime": 1654267015,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18108",
                    "makerAddr": "0xeBf7c7932D86907C3BC1Bd6d486308637a8264A3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "5783",
                    "listingTime": 1651846805,
                    "expirationTime": 1667215195,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5783",
                    "makerAddr": "0xCD750f5854eCE8252FB7d59D4a8Be7864241d50D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "5579",
                    "listingTime": 1651832386,
                    "expirationTime": 1653397146,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5579",
                    "makerAddr": "0xD163bd16eaf4813D723F90f13fE4abeeECCdD3dd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "3351",
                    "listingTime": 1651942763,
                    "expirationTime": 1654534728,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3351",
                    "makerAddr": "0xDbb3F1Bdc0353Eba1C57Dce84FC6A5C3975b321f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "5090",
                    "listingTime": 1651899447,
                    "expirationTime": 1654491437,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5090",
                    "makerAddr": "0x123432244443B54409430979DF8333f9308A6040",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "19990",
                    "listingTime": 1651872409,
                    "expirationTime": 1654053758,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19990",
                    "makerAddr": "0xD9D728Eb0d7e4Ec9d256D458923eB64E59c5370f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "22748",
                    "listingTime": 1651843310,
                    "expirationTime": 1652591644,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22748",
                    "makerAddr": "0x2155D5C0A63AF068a00443483C4CD572ae73b09b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "5287",
                    "listingTime": 1651851016,
                    "expirationTime": 1654254653,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5287",
                    "makerAddr": "0xCaE7bF3715CeeC1fC2863D9CE81E042136b34E80",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "26420",
                    "listingTime": 1651851485,
                    "expirationTime": 1653493647,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26420",
                    "makerAddr": "0x407CAe1E3E4C30Bfd038FC8c686ACE87B59D33f4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "9981",
                    "listingTime": 1651550428,
                    "expirationTime": 1652760022,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9981",
                    "makerAddr": "0x379CAdAb8F98bF10121E2e9B7fc02CA802345E8b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "25254",
                    "listingTime": 1651853369,
                    "expirationTime": 1652252043,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25254",
                    "makerAddr": "0x1256497A1b0729E167BC52E2b08EE23a888184C9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "15702",
                    "listingTime": 1651844363,
                    "expirationTime": 1654169119,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15702",
                    "makerAddr": "0x4328D5d49dB3D1824a9C3A52013057E7cD5EDc12",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "10164",
                    "listingTime": 1651842309,
                    "expirationTime": 1654281405,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10164",
                    "makerAddr": "0x8c197dB132A18Cf2c0b46A1051D7c3E43F65a17b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "29784",
                    "listingTime": 1651851764,
                    "expirationTime": 1653710988,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29784",
                    "makerAddr": "0xaDF631c4F37052B9F5eE63aD88975dDaaef0F7aA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "17198",
                    "listingTime": 1651871125,
                    "expirationTime": 1652349028,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17198",
                    "makerAddr": "0x182Db69f652149ddda92a1756731b94CF9fb9413",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "5188",
                    "listingTime": 1651850978,
                    "expirationTime": 1654254589,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5188",
                    "makerAddr": "0xb1d802566C228D1cB0484f29Fc7CaA009e75A1ef",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "7014",
                    "listingTime": 1651851050,
                    "expirationTime": 1654254689,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7014",
                    "makerAddr": "0x908c6143512b4Ec00170c56e8954746EF7b5A577",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "9918",
                    "listingTime": 1651856892,
                    "expirationTime": 1654448835,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9918",
                    "makerAddr": "0x850536558E6584cAdFBC7B9DB7551F9BB7c122cD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "8180",
                    "listingTime": 1651402423,
                    "expirationTime": 1653994405,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8180",
                    "makerAddr": "0xd83e662B83880081741D0E810c3Fe8630fa6Cb4d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "1497",
                    "listingTime": 1651697680,
                    "expirationTime": 1652302475,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1497",
                    "makerAddr": "0x69bAb6810fa99475854bca0A3DD72aE6a0728ECE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "6788",
                    "listingTime": 1651843761,
                    "expirationTime": 1652279720,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6788",
                    "makerAddr": "0x4199F2400fb5Ad164A065815B1ab386fD09016a0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "21404",
                    "listingTime": 1651874930,
                    "expirationTime": 1652316520,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21404",
                    "makerAddr": "0x2e9BC111159cC4FE5479eCC81F1825cFD577E077",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "4008",
                    "listingTime": 1651852630,
                    "expirationTime": 1654015899,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4008",
                    "makerAddr": "0xe0e5D2d2AE5a629928D452fFa325D1083AAC3997",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "8392",
                    "listingTime": 1651796382,
                    "expirationTime": 1652206176,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8392",
                    "makerAddr": "0x3A70344c268cD039B107D9f65705F6092303c919",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "9118",
                    "listingTime": 1651850804,
                    "expirationTime": 1654254448,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9118",
                    "makerAddr": "0x44bdd22FFb03b5077A44b8E01d23170CA07E77a5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "1363",
                    "listingTime": 1651871149,
                    "expirationTime": 1652349071,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1363",
                    "makerAddr": "0x182Db69f652149ddda92a1756731b94CF9fb9413",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "4498",
                    "listingTime": 1651851220,
                    "expirationTime": 1654052907,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4498",
                    "makerAddr": "0xf29D4D7B3270beB3C54EE703fE2d9d17BF806C81",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "12316",
                    "listingTime": 1651697746,
                    "expirationTime": 1652302542,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12316",
                    "makerAddr": "0x69bAb6810fa99475854bca0A3DD72aE6a0728ECE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "1304",
                    "listingTime": 1651844463,
                    "expirationTime": 1654091917,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1304",
                    "makerAddr": "0x6b3d3fCDB08d0813FDf0E1267C2eca056EAB3415",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "6279",
                    "listingTime": 1651842596,
                    "expirationTime": 1652270757,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6279",
                    "makerAddr": "0x44c1D4AA76e9d83384f6BDdd547Ce30E4b7832a9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "8217",
                    "listingTime": 1651853674,
                    "expirationTime": 1652458432,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8217",
                    "makerAddr": "0xD435b22D96bFE40752e8b01596C1E95b3FeF3aFA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31000000000000000000",
                    "tokenId": "1626",
                    "listingTime": 1651806624,
                    "expirationTime": 1653443769,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1626",
                    "makerAddr": "0xBe547aA1bf50FEcCa7d853adEa3aB6D5F85953F7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1244",
                    "price": "31000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651727345,
                    "expirationTime": 1654062102,
                    "makerAddr": "0x73f7ab72aba02a933f51168f36b2fa0bc7d75c99",
                    "assetImageUrl": "https://img.seadn.io/files/32859233c5f5bd996f31f0e18616d0c3.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31180000000000000000",
                    "tokenId": "6682",
                    "listingTime": 1651863305,
                    "expirationTime": 1653398147,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6682",
                    "makerAddr": "0x71dfD2baB718F5bB30f1099494a68752B5d2f2e2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31190000000000000000",
                    "tokenId": "11898",
                    "listingTime": 1651858591,
                    "expirationTime": 1654371761,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11898",
                    "makerAddr": "0x4f940D90A643a27546C885B3165b31d624f80c15",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31276000000000000000",
                    "tokenId": "15182",
                    "listingTime": 1651854384,
                    "expirationTime": 1652459155,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15182",
                    "makerAddr": "0x4aB16A42C6d06AED8bF46911267c40aFc37e2270",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31300000000000000000",
                    "tokenId": "21126",
                    "listingTime": 1651737713,
                    "expirationTime": 1659513456,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21126",
                    "makerAddr": "0xD9563E7CbA955b5A1f95BCA4bA264f071489766C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31400000000000000000",
                    "tokenId": "20178",
                    "listingTime": 1651833909,
                    "expirationTime": 1659461641,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20178",
                    "makerAddr": "0x307E1cd1e0f955FeEB032CD7AB1df3367dc34b2B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31500000000000000000",
                    "tokenId": "25942",
                    "listingTime": 1651848055,
                    "expirationTime": 1652383907,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25942",
                    "makerAddr": "0xdbf049b470e7cdb1BFc19D9Ca15B1C8C1128aa20",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31500000000000000000",
                    "tokenId": "14982",
                    "listingTime": 1651841963,
                    "expirationTime": 1652401200,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14982",
                    "makerAddr": "0x0D7D9AcF85FA0Bb5eE25bd8016B7c0f5A711c893",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31500000000000000000",
                    "tokenId": "18108",
                    "listingTime": 1651842604,
                    "expirationTime": 1654267015,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18108",
                    "makerAddr": "0xeBf7c7932D86907C3BC1Bd6d486308637a8264A3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31500000000000000000",
                    "tokenId": "15244",
                    "listingTime": 1651845627,
                    "expirationTime": 1654219385,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15244",
                    "makerAddr": "0x28570D9d66627e0733dFE4FCa79B8fD5b8128636",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31500000000000000000",
                    "tokenId": "800",
                    "listingTime": 1651845206,
                    "expirationTime": 1653950368,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/800",
                    "makerAddr": "0xC03BDA89a4CA08036058E6e111C4beacd7246051",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31500000000000000000",
                    "tokenId": "11536",
                    "listingTime": 1651841939,
                    "expirationTime": 1654217185,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11536",
                    "makerAddr": "0x0D7D9AcF85FA0Bb5eE25bd8016B7c0f5A711c893",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31500000000000000000",
                    "tokenId": "6249",
                    "listingTime": 1651974874,
                    "expirationTime": 1652234071,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6249",
                    "makerAddr": "0x827Ad805e6A673Af9d7262b27e2A81707A8186a9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31500000000000000000",
                    "tokenId": "12531",
                    "listingTime": 1651438499,
                    "expirationTime": 1653467422,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12531",
                    "makerAddr": "0x4BC6fE8c58926f64388EE522757774C97787BCE0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31500000000000000000",
                    "tokenId": "20355",
                    "listingTime": 1652120094,
                    "expirationTime": 1652206483,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20355",
                    "makerAddr": "0xd9d7046933Bd3d0A3E869959eF95e4C41522c1BF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31690000000000000000",
                    "tokenId": "9929",
                    "listingTime": 1652195575,
                    "expirationTime": 1652454757,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9929",
                    "makerAddr": "0xE3636D969fE448ae835c8984D6c3A4E1AcE27232",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31690000000000000000",
                    "tokenId": "20482",
                    "listingTime": 1651938557,
                    "expirationTime": 1652543347,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20482",
                    "makerAddr": "0x4Ee132b17d4d938a7150bD826606B8FfF060D70c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31700000000000000000",
                    "tokenId": "825",
                    "listingTime": 1651831837,
                    "expirationTime": 1652585020,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/825",
                    "makerAddr": "0xa7e4f12B7Cd97387e8607daC17B439ca7cad58e9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31800000000000000000",
                    "tokenId": "2037",
                    "listingTime": 1651809656,
                    "expirationTime": 1652414432,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2037",
                    "makerAddr": "0x77B691DeC38da63B60b4adB4c153A17C18e29Ce7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "31900000000000000000",
                    "tokenId": "2218",
                    "listingTime": 1651599622,
                    "expirationTime": 1653418374,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2218",
                    "makerAddr": "0x49ca963Ef75BCEBa8E4A5F4cEAB5Fd326beF6123",
                    "assetImageUrl": ""
                }
            ],
            "total": 2.0556260000000005e+21,
            "looksrare": 65,
            "opensea": 1
        },
        {
            "price": 32,
            "amount": 83,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "7276",
                    "listingTime": 1651815424,
                    "expirationTime": 1654407394,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7276",
                    "makerAddr": "0xc1F50e993a84F0B7C07daE664fE52986Bd32b106",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "5579",
                    "listingTime": 1651548689,
                    "expirationTime": 1653397146,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5579",
                    "makerAddr": "0xD163bd16eaf4813D723F90f13fE4abeeECCdD3dd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "20920",
                    "listingTime": 1651797494,
                    "expirationTime": 1653874229,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20920",
                    "makerAddr": "0xb97A377229bFf1BED964a82b09167D4CC98e2e27",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "9874",
                    "listingTime": 1652077057,
                    "expirationTime": 1654669053,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9874",
                    "makerAddr": "0x54950EEf3B0e890D0bf17Fdbbdee8e4920BE7c13",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "25254",
                    "listingTime": 1651817413,
                    "expirationTime": 1652252043,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25254",
                    "makerAddr": "0x1256497A1b0729E167BC52E2b08EE23a888184C9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "11536",
                    "listingTime": 1651837684,
                    "expirationTime": 1654217185,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11536",
                    "makerAddr": "0x0D7D9AcF85FA0Bb5eE25bd8016B7c0f5A711c893",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "2593",
                    "listingTime": 1651806522,
                    "expirationTime": 1653444064,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2593",
                    "makerAddr": "0xBe547aA1bf50FEcCa7d853adEa3aB6D5F85953F7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "25864",
                    "listingTime": 1651848120,
                    "expirationTime": 1654275661,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25864",
                    "makerAddr": "0x8cc24CF5970F9c7AA94aB00Fbf9e6E86fd01c41B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "5134",
                    "listingTime": 1651848692,
                    "expirationTime": 1654047716,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5134",
                    "makerAddr": "0xEAA06F6242836cf6641a5d43C8f519224e5A4DD5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "26648",
                    "listingTime": 1651829127,
                    "expirationTime": 1654421114,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26648",
                    "makerAddr": "0xB2D69eA8405326fdc3f3936fD45826D8613B8045",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "5783",
                    "listingTime": 1651836579,
                    "expirationTime": 1667215195,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5783",
                    "makerAddr": "0xCD750f5854eCE8252FB7d59D4a8Be7864241d50D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "29734",
                    "listingTime": 1651552676,
                    "expirationTime": 1653621252,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29734",
                    "makerAddr": "0x49836dbdbEA45b90ED274F0EB688E3Cc7908D31D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "6046",
                    "listingTime": 1651809990,
                    "expirationTime": 1653401649,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6046",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "18108",
                    "listingTime": 1651837209,
                    "expirationTime": 1654267015,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18108",
                    "makerAddr": "0xeBf7c7932D86907C3BC1Bd6d486308637a8264A3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "15838",
                    "listingTime": 1651512143,
                    "expirationTime": 1654104112,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15838",
                    "makerAddr": "0x278BfD54e7E71346F92eF36C6e34145d14F017Cd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "1935",
                    "listingTime": 1651540685,
                    "expirationTime": 1666885368,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1935",
                    "makerAddr": "0x7B220358c3EC9Ec03b08120DcCEC56F16Fbf33a4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "2369",
                    "listingTime": 1651991017,
                    "expirationTime": 1654583014,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2369",
                    "makerAddr": "0x272b10e92Ab4891Fe6B706541157260B31458ea3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "10712",
                    "listingTime": 1651835592,
                    "expirationTime": 1654254566,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10712",
                    "makerAddr": "0x3d3FBE809eEA54E106E5B30EA2f5Dedbc94D8eE8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "7582",
                    "listingTime": 1651609158,
                    "expirationTime": 1654052278,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7582",
                    "makerAddr": "0xA89C95f3b69c6435fa022fcE66EFa46289E24476",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "1529",
                    "listingTime": 1651840084,
                    "expirationTime": 1654171618,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1529",
                    "makerAddr": "0x23bA9404e59fe3F013091B5a0bb4a0Fa882FADE9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "218",
                    "listingTime": 1651865528,
                    "expirationTime": 1652275048,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/218",
                    "makerAddr": "0x5C7Af7bFD94cb9dAEbdE66Ed922a34d68949Ee20",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "3790",
                    "listingTime": 1652060217,
                    "expirationTime": 1652319414,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3790",
                    "makerAddr": "0xe876387B8246663Bc0f3c76114aD75d58CFD2b46",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "3827",
                    "listingTime": 1651860888,
                    "expirationTime": 1653408280,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3827",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "2818",
                    "listingTime": 1651827304,
                    "expirationTime": 1654271927,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2818",
                    "makerAddr": "0x822a16309A9eE40f15e196898f11A010ecb1C963",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "7455",
                    "listingTime": 1651860901,
                    "expirationTime": 1653408852,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7455",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "2543",
                    "listingTime": 1651683894,
                    "expirationTime": 1654275830,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2543",
                    "makerAddr": "0x1e5d9EaC71bc099151B55742070576df1DB0a811",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "825",
                    "listingTime": 1651797118,
                    "expirationTime": 1652585020,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/825",
                    "makerAddr": "0xa7e4f12B7Cd97387e8607daC17B439ca7cad58e9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "25826",
                    "listingTime": 1651613533,
                    "expirationTime": 1654205485,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25826",
                    "makerAddr": "0x0D7D9AcF85FA0Bb5eE25bd8016B7c0f5A711c893",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "6432",
                    "listingTime": 1651767556,
                    "expirationTime": 1652372351,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6432",
                    "makerAddr": "0x7AC8BfC6f1842C1A7c1b9a1e8D60972e79075766",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "23014",
                    "listingTime": 1652187208,
                    "expirationTime": 1652230800,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23014",
                    "makerAddr": "0x9adFF48358391c510B0ABD9f41e02108Ce847503",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "25192",
                    "listingTime": 1651589448,
                    "expirationTime": 1654181441,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25192",
                    "makerAddr": "0x1863fC8cEb7e90934c9ABa3cf8B0f7132cEaee6b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "10754",
                    "listingTime": 1651817958,
                    "expirationTime": 1652273260,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10754",
                    "makerAddr": "0x9Fe176816F90145F8aEd9fFDedEE158ed8423bA2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "26806",
                    "listingTime": 1651602637,
                    "expirationTime": 1654194553,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26806",
                    "makerAddr": "0x4322AA46ffEd67809862Da613725728e2fb8eAe3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "807",
                    "listingTime": 1651839565,
                    "expirationTime": 1654431469,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/807",
                    "makerAddr": "0x70b30BE4e3E0406E016dA4b2ed8BE62cbc43bee0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "189",
                    "listingTime": 1651835634,
                    "expirationTime": 1654254566,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/189",
                    "makerAddr": "0x3d3FBE809eEA54E106E5B30EA2f5Dedbc94D8eE8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "8576",
                    "listingTime": 1651472844,
                    "expirationTime": 1653986165,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8576",
                    "makerAddr": "0x8f197EB762aAa442CA6e5207e43E719D7EdBb872",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "6250",
                    "listingTime": 1651539466,
                    "expirationTime": 1653485399,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6250",
                    "makerAddr": "0x989cE0d5f551d94EC87E638Dc643c014e63a0c0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "7351",
                    "listingTime": 1651623412,
                    "expirationTime": 1654011882,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7351",
                    "makerAddr": "0x56767eD9B7e23607Ba62a1CF41B8708b9Dbb5555",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "665",
                    "listingTime": 1651838328,
                    "expirationTime": 1667390325,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/665",
                    "makerAddr": "0xF7480A958bFA8EF59C52CA995C1d3518968ce2d9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "8003",
                    "listingTime": 1651797462,
                    "expirationTime": 1654325732,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8003",
                    "makerAddr": "0xb97A377229bFf1BED964a82b09167D4CC98e2e27",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "5118",
                    "listingTime": 1651735663,
                    "expirationTime": 1654327655,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5118",
                    "makerAddr": "0xb883C906332e94Cd8CE52C846921a220Bb8EDef2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "16800",
                    "listingTime": 1651836136,
                    "expirationTime": 1654428135,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16800",
                    "makerAddr": "0x2c3c28C8B928B85fe8cCb74B80BA22d300e35155",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "14982",
                    "listingTime": 1651837714,
                    "expirationTime": 1652401200,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14982",
                    "makerAddr": "0x0D7D9AcF85FA0Bb5eE25bd8016B7c0f5A711c893",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "3126",
                    "listingTime": 1651546334,
                    "expirationTime": 1653397648,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3126",
                    "makerAddr": "0x135400Da57c70Eb39Bd6A48a452e5A9961e8FE04",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "5513",
                    "listingTime": 1651812191,
                    "expirationTime": 1654125141,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5513",
                    "makerAddr": "0xEC4762fb859a53c15D1689c08FD5E48C5c1C47Fa",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "8852",
                    "listingTime": 1651860875,
                    "expirationTime": 1653852863,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8852",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "2629",
                    "listingTime": 1651620935,
                    "expirationTime": 1654212884,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2629",
                    "makerAddr": "0x779bD28eA4f6268e02B397736cc2d6537118182D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "3613",
                    "listingTime": 1651537503,
                    "expirationTime": 1666469638,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3613",
                    "makerAddr": "0x25Ca2e79391AD17A73395f88985d33C121dF7d06",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "22660",
                    "listingTime": 1651708519,
                    "expirationTime": 1654300455,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22660",
                    "makerAddr": "0x584f4BcBC22aD3f005056B42E991211cEDE5d832",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32000000000000000000",
                    "tokenId": "11476",
                    "listingTime": 1652165449,
                    "expirationTime": 1652424643,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11476",
                    "makerAddr": "0xc9ca66FB145E146812De286aC78a533718c493d4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "26",
                    "price": "32000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651904093,
                    "expirationTime": 1654582567,
                    "makerAddr": "0x352e13a72bea47d1d7c37f5a5e7aee7b6e3c41ea",
                    "assetImageUrl": "https://img.seadn.io/files/1c10c2840ca6400ba74b2f704f8c5d7d.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "122",
                    "price": "32000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652170254,
                    "expirationTime": 1652429540,
                    "makerAddr": "0x64b771408c67a83e0e725324329ac82b8a6658f7",
                    "assetImageUrl": "https://img.seadn.io/files/4cf65c2b7f88fadc19f1ac3af29f3f26.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "324",
                    "price": "32000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651909045,
                    "expirationTime": 1654321363,
                    "makerAddr": "0xe1b13c828ad870a4ce9d61e357997d4e4471d8b1",
                    "assetImageUrl": "https://img.seadn.io/files/f61cf88e26299e68bf42bc313fc1072a.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "995",
                    "price": "32000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651653026,
                    "expirationTime": 1652257893,
                    "makerAddr": "0xdc78107155918e230246439e4159fea4c477eae9",
                    "assetImageUrl": "https://img.seadn.io/files/6429805350959d96c13b34f54d808182.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32090000000000000000",
                    "tokenId": "6055",
                    "listingTime": 1651840540,
                    "expirationTime": 1666871396,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6055",
                    "makerAddr": "0x02b2bab8458D1a47F9789580495284670b5f4299",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32230000000000000000",
                    "tokenId": "3518",
                    "listingTime": 1651828468,
                    "expirationTime": 1652366170,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3518",
                    "makerAddr": "0x5d987F2F1689eCD4C21f1E73E2F12762570fdED5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32333000000000000000",
                    "tokenId": "669",
                    "listingTime": 1652179368,
                    "expirationTime": 1652438543,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/669",
                    "makerAddr": "0x18e20088C9aEa2D58b12E1B2e28d54afaF8D36f1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32400000000000000000",
                    "tokenId": "100",
                    "listingTime": 1651946952,
                    "expirationTime": 1653416589,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/100",
                    "makerAddr": "0xc9ca66FB145E146812De286aC78a533718c493d4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32490000000000000000",
                    "tokenId": "29837",
                    "listingTime": 1652023359,
                    "expirationTime": 1652282547,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29837",
                    "makerAddr": "0x5e525f13EBec9A839e7a5D69A901c726110ef179",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32500000000000000000",
                    "tokenId": "21300",
                    "listingTime": 1651794093,
                    "expirationTime": 1654086210,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21300",
                    "makerAddr": "0xB53167fD81a9270b4Dea3F9eBF96742722cdCe50",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32500000000000000000",
                    "tokenId": "9356",
                    "listingTime": 1651601475,
                    "expirationTime": 1652206184,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9356",
                    "makerAddr": "0xC7b72Bb1aCDcE3eB45f9B38F43cD31e4927C725b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32500000000000000000",
                    "tokenId": "4082",
                    "listingTime": 1651814227,
                    "expirationTime": 1652302910,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4082",
                    "makerAddr": "0x8f197EB762aAa442CA6e5207e43E719D7EdBb872",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32500000000000000000",
                    "tokenId": "20182",
                    "listingTime": 1652192681,
                    "expirationTime": 1652451875,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20182",
                    "makerAddr": "0x40D91c40b23c27946b6b0438E678b6eB2a68a1a5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32500000000000000000",
                    "tokenId": "15660",
                    "listingTime": 1651740441,
                    "expirationTime": 1652949997,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15660",
                    "makerAddr": "0x6fdd8c800CBb81790CD48A20F90ce7e725F89C27",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32500000000000000000",
                    "tokenId": "3903",
                    "listingTime": 1651769601,
                    "expirationTime": 1654276155,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3903",
                    "makerAddr": "0xe450D86A20CEAcF552F0E8e8ACbc573C7A91D80F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32500000000000000000",
                    "tokenId": "4352",
                    "listingTime": 1652025463,
                    "expirationTime": 1652216459,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4352",
                    "makerAddr": "0xe73e90fc400c0256Bc271C83e576ACb97603452b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32500000000000000000",
                    "tokenId": "5513",
                    "listingTime": 1651797599,
                    "expirationTime": 1654125141,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5513",
                    "makerAddr": "0xEC4762fb859a53c15D1689c08FD5E48C5c1C47Fa",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32500000000000000000",
                    "tokenId": "29122",
                    "listingTime": 1651555227,
                    "expirationTime": 1653986126,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29122",
                    "makerAddr": "0x8f197EB762aAa442CA6e5207e43E719D7EdBb872",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32500000000000000000",
                    "tokenId": "2753",
                    "listingTime": 1651814246,
                    "expirationTime": 1652302875,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2753",
                    "makerAddr": "0x8f197EB762aAa442CA6e5207e43E719D7EdBb872",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32500000000000000000",
                    "tokenId": "18108",
                    "listingTime": 1651814603,
                    "expirationTime": 1654267015,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18108",
                    "makerAddr": "0xeBf7c7932D86907C3BC1Bd6d486308637a8264A3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1602",
                    "price": "32500000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651977183,
                    "expirationTime": 1652274823,
                    "makerAddr": "0xeb084dcdc39fd367700861f873cc5655bc3b52c0",
                    "assetImageUrl": "https://img.seadn.io/files/9dd360dc6e78cb0a14c30537a655f08e.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32690000000000000000",
                    "tokenId": "15727",
                    "listingTime": 1651806268,
                    "expirationTime": 1654152762,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15727",
                    "makerAddr": "0x15D19e3F3af0D4ac2E0891065c2724C8bf98BBB6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32690000000000000000",
                    "tokenId": "1304",
                    "listingTime": 1651783063,
                    "expirationTime": 1654091917,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1304",
                    "makerAddr": "0x6b3d3fCDB08d0813FDf0E1267C2eca056EAB3415",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32690000000000000000",
                    "tokenId": "26032",
                    "listingTime": 1651799162,
                    "expirationTime": 1652227452,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26032",
                    "makerAddr": "0xc94893b6D1CC1d7ce09Fec6abC9B5949A903d2C9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32700000000000000000",
                    "tokenId": "9906",
                    "listingTime": 1652193646,
                    "expirationTime": 1652211600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9906",
                    "makerAddr": "0xAbA31A1327c669FE881ED77ce1041Edc360db715",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32800000000000000000",
                    "tokenId": "14297",
                    "listingTime": 1651749848,
                    "expirationTime": 1653565894,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14297",
                    "makerAddr": "0x517b1c4B8C259255EE7b7aB85e6Ea0dc9a235c3E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32800000000000000000",
                    "tokenId": "5783",
                    "listingTime": 1651827773,
                    "expirationTime": 1667215195,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5783",
                    "makerAddr": "0xCD750f5854eCE8252FB7d59D4a8Be7864241d50D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32800000000000000000",
                    "tokenId": "3264",
                    "listingTime": 1651807221,
                    "expirationTime": 1654359719,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3264",
                    "makerAddr": "0xfBb3669A32E0814ee3491a1581a40b2Bf0F512a2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32888000000000000000",
                    "tokenId": "126",
                    "listingTime": 1651778754,
                    "expirationTime": 1652383541,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/126",
                    "makerAddr": "0x407F622FA81a8bB56ff4E17786A6A581E4Abb7e0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32900000000000000000",
                    "tokenId": "3857",
                    "listingTime": 1651874177,
                    "expirationTime": 1654466176,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3857",
                    "makerAddr": "0x08BD65c8d7308fef47eb6F0c78351fb2b6B49033",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32900000000000000000",
                    "tokenId": "1890",
                    "listingTime": 1651816809,
                    "expirationTime": 1654169857,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1890",
                    "makerAddr": "0xD60BB8cF18aD76D60e7b3A40e72116f1F053b2e9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32900000000000000000",
                    "tokenId": "6279",
                    "listingTime": 1651776283,
                    "expirationTime": 1652270757,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6279",
                    "makerAddr": "0x44c1D4AA76e9d83384f6BDdd547Ce30E4b7832a9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "32990000000000000000",
                    "tokenId": "11064",
                    "listingTime": 1651853376,
                    "expirationTime": 1653405091,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11064",
                    "makerAddr": "0x5fB998B8024E38867558fD5E2b87B6D13Da730f8",
                    "assetImageUrl": ""
                }
            ],
            "total": 2.673290999999998e+21,
            "looksrare": 78,
            "opensea": 5
        },
        {
            "price": 33,
            "amount": 113,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "9118",
                    "listingTime": 1651801467,
                    "expirationTime": 1654254448,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9118",
                    "makerAddr": "0x44bdd22FFb03b5077A44b8E01d23170CA07E77a5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "2493",
                    "listingTime": 1651500705,
                    "expirationTime": 1654092701,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2493",
                    "makerAddr": "0xC6c943c101cAAD4DB436ef2A7716b516b74A98DE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "6167",
                    "listingTime": 1651561233,
                    "expirationTime": 1654062967,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6167",
                    "makerAddr": "0xB4f011d8cE90E13CdEc209DEb06025fBD9C0cbA5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "7499",
                    "listingTime": 1652125943,
                    "expirationTime": 1654717917,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7499",
                    "makerAddr": "0x45632D932579861ca72dc93b50677795253F9bAB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "29534",
                    "listingTime": 1651557329,
                    "expirationTime": 1654123279,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29534",
                    "makerAddr": "0x268993C1923Cf4d6db408f5109A53FCf17ed4039",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "20148",
                    "listingTime": 1651541236,
                    "expirationTime": 1654112029,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20148",
                    "makerAddr": "0x534c98c85714Df298524CF94c3d2Cd230B1EEa5B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "28516",
                    "listingTime": 1651624912,
                    "expirationTime": 1653487398,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28516",
                    "makerAddr": "0x8b3477Ca7445ec524dd534fA4A596C993E93675a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "29592",
                    "listingTime": 1651664635,
                    "expirationTime": 1654255006,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29592",
                    "makerAddr": "0x1452bf4Ec9eB7063b4b2365783e38095477926C1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "17826",
                    "listingTime": 1651560468,
                    "expirationTime": 1653992284,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17826",
                    "makerAddr": "0xab36Ef38d02E5aeC0fCcBc6B76772A0CF3E69A38",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "14846",
                    "listingTime": 1651651587,
                    "expirationTime": 1653821019,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14846",
                    "makerAddr": "0x6189e05a28bEF26Bcf76F22aB311282d5e843dF4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "2230",
                    "listingTime": 1652099056,
                    "expirationTime": 1654691012,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2230",
                    "makerAddr": "0x2AA3458Ea3885031F45A16aA50211ac17FB44E9b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "1840",
                    "listingTime": 1651539215,
                    "expirationTime": 1653995626,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1840",
                    "makerAddr": "0x112D352E59ed2165052F2fB00e00D3B4B87552fD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "8812",
                    "listingTime": 1651847171,
                    "expirationTime": 1652451907,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8812",
                    "makerAddr": "0xf6c9385Bc7764b31923B07BaC900ACDf886381a5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "5287",
                    "listingTime": 1651801346,
                    "expirationTime": 1654254653,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5287",
                    "makerAddr": "0xCaE7bF3715CeeC1fC2863D9CE81E042136b34E80",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "21280",
                    "listingTime": 1651802675,
                    "expirationTime": 1653402254,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21280",
                    "makerAddr": "0x57be93fB6a01E435aaC84E2f52566E6f7296f578",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "19146",
                    "listingTime": 1651885518,
                    "expirationTime": 1653212985,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19146",
                    "makerAddr": "0x3c145Da6cbbB4F391CC23AaBd979656362A9BB9B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "15702",
                    "listingTime": 1651804633,
                    "expirationTime": 1654169119,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15702",
                    "makerAddr": "0x4328D5d49dB3D1824a9C3A52013057E7cD5EDc12",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "6513",
                    "listingTime": 1651843546,
                    "expirationTime": 1659341540,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6513",
                    "makerAddr": "0xb3b3195F08004eBcD43f5f40AF68C9fa18B2C669",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "7557",
                    "listingTime": 1651541300,
                    "expirationTime": 1653449297,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7557",
                    "makerAddr": "0x534c98c85714Df298524CF94c3d2Cd230B1EEa5B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "869",
                    "listingTime": 1651770403,
                    "expirationTime": 1653989476,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/869",
                    "makerAddr": "0xaFe7DF0F7A454dBb89BE9ee0c3AA731744890cCe",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "7085",
                    "listingTime": 1651557694,
                    "expirationTime": 1653980042,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7085",
                    "makerAddr": "0x2D66B7eA0c89158d7274aeF1019423d968F7864F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "9392",
                    "listingTime": 1651572419,
                    "expirationTime": 1654164390,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9392",
                    "makerAddr": "0x553eA23c17a9A472d6a853093aF29BC185536Ffa",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "9120",
                    "listingTime": 1651960401,
                    "expirationTime": 1653401169,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9120",
                    "makerAddr": "0x9f2301960dF3D3c97D891362d716F42e93D35D1E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "4498",
                    "listingTime": 1651818805,
                    "expirationTime": 1654052907,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4498",
                    "makerAddr": "0xf29D4D7B3270beB3C54EE703fE2d9d17BF806C81",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "12828",
                    "listingTime": 1651756983,
                    "expirationTime": 1654269431,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12828",
                    "makerAddr": "0x495F45452c2B47bA1089E72BdBFe71c081E7B306",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "1897",
                    "listingTime": 1652197068,
                    "expirationTime": 1654788906,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1897",
                    "makerAddr": "0x7b3aFc18936f9d0410002CDc9c4F28d772ca41AF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "6788",
                    "listingTime": 1651795731,
                    "expirationTime": 1652279720,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6788",
                    "makerAddr": "0x4199F2400fb5Ad164A065815B1ab386fD09016a0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "5783",
                    "listingTime": 1651795872,
                    "expirationTime": 1667215195,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5783",
                    "makerAddr": "0xCD750f5854eCE8252FB7d59D4a8Be7864241d50D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "9584",
                    "listingTime": 1651588538,
                    "expirationTime": 1653995241,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9584",
                    "makerAddr": "0x3dE262F19795845534A6e8D604d0Da383C2FCC9D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "79",
                    "listingTime": 1651539636,
                    "expirationTime": 1654047378,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/79",
                    "makerAddr": "0x1278Bf1cA85Bfe406e23502700C7Cb94eA36B21A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "18108",
                    "listingTime": 1651802504,
                    "expirationTime": 1654267015,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18108",
                    "makerAddr": "0xeBf7c7932D86907C3BC1Bd6d486308637a8264A3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "12974",
                    "listingTime": 1651547150,
                    "expirationTime": 1654119332,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12974",
                    "makerAddr": "0xc7f1916b741A7966Bd65144cb2B0Ba9eD1B29984",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "32",
                    "listingTime": 1651543388,
                    "expirationTime": 1654135374,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/32",
                    "makerAddr": "0x5bfde66bde613887dC0E32Ac1D95b259FeE9a2cA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "13056",
                    "listingTime": 1651559103,
                    "expirationTime": 1653782871,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13056",
                    "makerAddr": "0xe315eB04Fc42bEa83Ed213582e66051050A92F1c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "23150",
                    "listingTime": 1651536025,
                    "expirationTime": 1653430416,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23150",
                    "makerAddr": "0xbb980fd226AC348A842875A029e6A7b25De9a447",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "10592",
                    "listingTime": 1651831469,
                    "expirationTime": 1654290631,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10592",
                    "makerAddr": "0xDF617Fc072215c638137B3038628b420064c06B2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "5026",
                    "listingTime": 1651823252,
                    "expirationTime": 1654076029,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5026",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "5188",
                    "listingTime": 1651801391,
                    "expirationTime": 1654254589,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5188",
                    "makerAddr": "0xb1d802566C228D1cB0484f29Fc7CaA009e75A1ef",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "5387",
                    "listingTime": 1651412282,
                    "expirationTime": 1653998527,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5387",
                    "makerAddr": "0x599727A2E1E6D8493c5B71800D4b44d7aDC9d9AE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "23356",
                    "listingTime": 1651531761,
                    "expirationTime": 1653591186,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23356",
                    "makerAddr": "0x8B9Cc341084337c0A5828BC0D5E20dADB197d991",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "11304",
                    "listingTime": 1651800701,
                    "expirationTime": 1654023884,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11304",
                    "makerAddr": "0x0d4428C8E62e894999d8E4c1845f23cb5F9d919d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "6726",
                    "listingTime": 1651541762,
                    "expirationTime": 1653404128,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6726",
                    "makerAddr": "0x43f44Ad26a18777F500Fb7496D1aF795cc1d3543",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "7142",
                    "listingTime": 1651820277,
                    "expirationTime": 1652774548,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7142",
                    "makerAddr": "0x5853dC7fE370FB7c600C3eAf0e27b800849cBB7e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "6584",
                    "listingTime": 1652158436,
                    "expirationTime": 1652230568,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6584",
                    "makerAddr": "0x9Cd90876e44afC5B07FB57c7ed50DA5B3F5d1Ca3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "13434",
                    "listingTime": 1651543853,
                    "expirationTime": 1654101862,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13434",
                    "makerAddr": "0x282A40a6dF1534cf5943fc63A9c57ebaDEE240a5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "7170",
                    "listingTime": 1651418259,
                    "expirationTime": 1653493696,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7170",
                    "makerAddr": "0x6D39B2F97bE14DeBc633C3A466A5134Bfe13a36b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "21130",
                    "listingTime": 1651543845,
                    "expirationTime": 1654135844,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21130",
                    "makerAddr": "0xCdECf6e5202561B92664F53B4dE60B57f45E0cbC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "27604",
                    "listingTime": 1651808879,
                    "expirationTime": 1653662309,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27604",
                    "makerAddr": "0x7599a1f7D44EEA1CFe86C75ceea54146719C9361",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "9234",
                    "listingTime": 1651568461,
                    "expirationTime": 1652518200,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9234",
                    "makerAddr": "0xFDe4947f3c73CBFdd0744Ef0202878170754FD28",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "7615",
                    "listingTime": 1651548150,
                    "expirationTime": 1654140133,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7615",
                    "makerAddr": "0x3FbEbbE2bD130da895411301cd8cEb834aF7cf83",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "5274",
                    "listingTime": 1651801429,
                    "expirationTime": 1654254510,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5274",
                    "makerAddr": "0xaBeA9711C5672C870f65835Ff84Cea14c353EE57",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "9015",
                    "listingTime": 1651385293,
                    "expirationTime": 1653977228,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9015",
                    "makerAddr": "0xFb8C0777051218D3EcB6ADe85783B64Ce54ad409",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "1462",
                    "listingTime": 1652075090,
                    "expirationTime": 1652679885,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1462",
                    "makerAddr": "0x7D176A8FF5b038A85923aE80b420534B9CB3B40F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "11036",
                    "listingTime": 1651649891,
                    "expirationTime": 1652254586,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11036",
                    "makerAddr": "0xa75927010c128F218D7046156ef2f3C68CcEd056",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "10164",
                    "listingTime": 1651799124,
                    "expirationTime": 1654281405,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10164",
                    "makerAddr": "0x8c197dB132A18Cf2c0b46A1051D7c3E43F65a17b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "2345",
                    "listingTime": 1651753418,
                    "expirationTime": 1653924638,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2345",
                    "makerAddr": "0xD05Defee18E718F21337F5355317f915a34A101B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "9436",
                    "listingTime": 1651823270,
                    "expirationTime": 1653366760,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9436",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "5383",
                    "listingTime": 1651809949,
                    "expirationTime": 1653823241,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5383",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "28070",
                    "listingTime": 1651823318,
                    "expirationTime": 1653366831,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28070",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "24472",
                    "listingTime": 1651558252,
                    "expirationTime": 1654109351,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24472",
                    "makerAddr": "0x15053b688f69d742AD18f13f47d15E097E4A77e7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "4337",
                    "listingTime": 1651805974,
                    "expirationTime": 1654145074,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4337",
                    "makerAddr": "0xdB2a2DF6DD352Cc1783B74de588406271fFc459D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "8017",
                    "listingTime": 1651945713,
                    "expirationTime": 1652204903,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8017",
                    "makerAddr": "0x11d2932D443629136E147a47f7ff99B156b8827E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "3083",
                    "listingTime": 1651539382,
                    "expirationTime": 1653414972,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3083",
                    "makerAddr": "0xC344FCe3710d5971b24b11947535FAcE3eCd5655",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "2309",
                    "listingTime": 1651945742,
                    "expirationTime": 1652204937,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2309",
                    "makerAddr": "0xE02Fd90f7bb205c7542A152Aaa14f7DDa005afdC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "24820",
                    "listingTime": 1651780581,
                    "expirationTime": 1654114745,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24820",
                    "makerAddr": "0x2C543BeE768EAf32a09D091E8239CDc9E33c75F5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "12531",
                    "listingTime": 1651422499,
                    "expirationTime": 1653467422,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12531",
                    "makerAddr": "0x4BC6fE8c58926f64388EE522757774C97787BCE0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "4008",
                    "listingTime": 1651819357,
                    "expirationTime": 1654015899,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4008",
                    "makerAddr": "0xe0e5D2d2AE5a629928D452fFa325D1083AAC3997",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "29146",
                    "listingTime": 1651823307,
                    "expirationTime": 1653982098,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29146",
                    "makerAddr": "0xE3813D26d44BcB6C40f29e5487dfF5434c629051",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "25254",
                    "listingTime": 1651798152,
                    "expirationTime": 1652252043,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25254",
                    "makerAddr": "0x1256497A1b0729E167BC52E2b08EE23a888184C9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "2914",
                    "listingTime": 1651823231,
                    "expirationTime": 1653366800,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2914",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "7014",
                    "listingTime": 1651801294,
                    "expirationTime": 1654254689,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7014",
                    "makerAddr": "0x908c6143512b4Ec00170c56e8954746EF7b5A577",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "21404",
                    "listingTime": 1651874827,
                    "expirationTime": 1652316520,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21404",
                    "makerAddr": "0x2e9BC111159cC4FE5479eCC81F1825cFD577E077",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "4584",
                    "listingTime": 1651560443,
                    "expirationTime": 1653992259,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4584",
                    "makerAddr": "0xab36Ef38d02E5aeC0fCcBc6B76772A0CF3E69A38",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "1524",
                    "listingTime": 1651850685,
                    "expirationTime": 1659356410,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1524",
                    "makerAddr": "0x6e548F76F3d1A30b57dd773a52F44fEcEb1d0800",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33000000000000000000",
                    "tokenId": "1928",
                    "listingTime": 1651904429,
                    "expirationTime": 1654496417,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1928",
                    "makerAddr": "0xA576538788efAbAb9d5ceFBD3339Ca0B13C94758",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1462",
                    "price": "33000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652074790,
                    "expirationTime": 1652679667,
                    "makerAddr": "0x7d176a8ff5b038a85923ae80b420534b9cb3b40f",
                    "assetImageUrl": "https://img.seadn.io/files/329980e939e051c1cd154010721ee5ac.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2161",
                    "price": "33000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651441884,
                    "expirationTime": 1654120370,
                    "makerAddr": "0x195ebd4a72a91c8e627c8cd7f6b6ebf215d35c60",
                    "assetImageUrl": "https://img.seadn.io/files/808cd56be034ea1d989642bd5aa2de58.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2230",
                    "price": "33000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652098978,
                    "expirationTime": 1654777336,
                    "makerAddr": "0x2aa3458ea3885031f45a16aa50211ac17fb44e9b",
                    "assetImageUrl": "https://img.seadn.io/files/2dcae127c42c3c97a49493f73e51b898.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2811",
                    "price": "33000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651652897,
                    "expirationTime": 1652257773,
                    "makerAddr": "0xdc78107155918e230246439e4159fea4c477eae9",
                    "assetImageUrl": "https://img.seadn.io/files/3ad34e7edacc45ca4d627bd006157d6a.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3081",
                    "price": "33000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652082729,
                    "expirationTime": 1654688451,
                    "makerAddr": "0x7a8feff71b781cf0bf635cb520c4239f540fb5f5",
                    "assetImageUrl": "https://img.seadn.io/files/c8c44c8ebb9f9da3ac3364ac6c86141c.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33100000000000000000",
                    "tokenId": "9992",
                    "listingTime": 1651808189,
                    "expirationTime": 1654264937,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9992",
                    "makerAddr": "0x4fF8EAD0fde851aE23Df19b438DfFb2686AF6dcc",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33200000000000000000",
                    "tokenId": "9985",
                    "listingTime": 1652124114,
                    "expirationTime": 1652383305,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9985",
                    "makerAddr": "0x250425A869d967C8C64B2bC153456f49bBa423E3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33200000000000000000",
                    "tokenId": "25942",
                    "listingTime": 1651803253,
                    "expirationTime": 1652383907,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25942",
                    "makerAddr": "0xdbf049b470e7cdb1BFc19D9Ca15B1C8C1128aa20",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33250000000000000000",
                    "tokenId": "5182",
                    "listingTime": 1651804093,
                    "expirationTime": 1653424803,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5182",
                    "makerAddr": "0xE4F9a6Bb8C34166ef8D7b9bCe6B6F7177549E367",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33300000000000000000",
                    "tokenId": "15244",
                    "listingTime": 1651803077,
                    "expirationTime": 1654219385,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15244",
                    "makerAddr": "0x28570D9d66627e0733dFE4FCa79B8fD5b8128636",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33300000000000000000",
                    "tokenId": "5906",
                    "listingTime": 1651736751,
                    "expirationTime": 1654328675,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5906",
                    "makerAddr": "0x03e0E647051d14eC16B44438893913f048810Cdd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33330000000000000000",
                    "tokenId": "23286",
                    "listingTime": 1651729370,
                    "expirationTime": 1659505322,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23286",
                    "makerAddr": "0xfa1Abd63caBe3EE17DAACc4F3E4c18567f3ee1bc",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33330000000000000000",
                    "tokenId": "11064",
                    "listingTime": 1651541700,
                    "expirationTime": 1653405091,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11064",
                    "makerAddr": "0x5fB998B8024E38867558fD5E2b87B6D13Da730f8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "669",
                    "price": "33330000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652160755,
                    "expirationTime": 1652420036,
                    "makerAddr": "0x18e20088c9aea2d58b12e1b2e28d54afaf8d36f1",
                    "assetImageUrl": "https://img.seadn.io/files/778b8501179069f7851747d25609e864.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33333000000000000000",
                    "tokenId": "17705",
                    "listingTime": 1651855309,
                    "expirationTime": 1654103827,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17705",
                    "makerAddr": "0x8B702421BF56Cc3886341b31FAD9b78F8C962A8A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33500000000000000000",
                    "tokenId": "3857",
                    "listingTime": 1651845596,
                    "expirationTime": 1654437596,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3857",
                    "makerAddr": "0x08BD65c8d7308fef47eb6F0c78351fb2b6B49033",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33500000000000000000",
                    "tokenId": "6682",
                    "listingTime": 1651546963,
                    "expirationTime": 1653398147,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6682",
                    "makerAddr": "0x71dfD2baB718F5bB30f1099494a68752B5d2f2e2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33500000000000000000",
                    "tokenId": "11434",
                    "listingTime": 1651642467,
                    "expirationTime": 1654234432,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11434",
                    "makerAddr": "0x55d491e3DBCCDDcfd8E75568885908BDFeAaf069",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33500000000000000000",
                    "tokenId": "4337",
                    "listingTime": 1651553095,
                    "expirationTime": 1654145074,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4337",
                    "makerAddr": "0xdB2a2DF6DD352Cc1783B74de588406271fFc459D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33500000000000000000",
                    "tokenId": "22748",
                    "listingTime": 1651548522,
                    "expirationTime": 1652591644,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22748",
                    "makerAddr": "0x2155D5C0A63AF068a00443483C4CD572ae73b09b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33500000000000000000",
                    "tokenId": "24448",
                    "listingTime": 1651780378,
                    "expirationTime": 1653405104,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24448",
                    "makerAddr": "0x3922B064DcC34289B2c04f82B92977c413a0dAd2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2493",
                    "price": "33500000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651506853,
                    "expirationTime": 1654185246,
                    "makerAddr": "0xc6c943c101caad4db436ef2a7716b516b74a98de",
                    "assetImageUrl": "https://img.seadn.io/files/416428f0b6e84211026ff237b713138f.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33600000000000000000",
                    "tokenId": "9685",
                    "listingTime": 1651659004,
                    "expirationTime": 1652868538,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9685",
                    "makerAddr": "0xF6a68db8A9912374657fE991Eae16dCF9f034e19",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33690000000000000000",
                    "tokenId": "15727",
                    "listingTime": 1651794197,
                    "expirationTime": 1654152762,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15727",
                    "makerAddr": "0x15D19e3F3af0D4ac2E0891065c2724C8bf98BBB6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33690000000000000000",
                    "tokenId": "9783",
                    "listingTime": 1651676640,
                    "expirationTime": 1653920342,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9783",
                    "makerAddr": "0xCF827110a45441ca7CE03dF4c590A56c48B5DE2f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "135",
                    "price": "33690000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652036434,
                    "expirationTime": 1652295720,
                    "makerAddr": "0x7785082d0d265f7fc1107d17af5ba102d1dbb2e8",
                    "assetImageUrl": "https://img.seadn.io/files/92170a8b7515fdf7f707b19ad7278a56.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2717",
                    "price": "33690000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652155696,
                    "expirationTime": 1654834190,
                    "makerAddr": "0x7f5bc50b539dc282e82a72a517c8acc73f161431",
                    "assetImageUrl": "https://img.seadn.io/files/71d9eb774f02153fc4bf6635ab5f4eef.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33700000000000000000",
                    "tokenId": "26108",
                    "listingTime": 1651666568,
                    "expirationTime": 1654258540,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26108",
                    "makerAddr": "0x9C996076A85B46061D9a70ff81F013853A86b619",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33800000000000000000",
                    "tokenId": "6558",
                    "listingTime": 1651750298,
                    "expirationTime": 1654342271,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6558",
                    "makerAddr": "0xa61f21e490cadeEC26D48E1bFED6393b753420a9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33800000000000000000",
                    "tokenId": "210",
                    "listingTime": 1651750287,
                    "expirationTime": 1654342271,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/210",
                    "makerAddr": "0xa61f21e490cadeEC26D48E1bFED6393b753420a9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33888000000000000000",
                    "tokenId": "2994",
                    "listingTime": 1651382530,
                    "expirationTime": 1653945424,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2994",
                    "makerAddr": "0x4eD9c6193ede88A5D41b2E833E46508800420780",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33900000000000000000",
                    "tokenId": "4031",
                    "listingTime": 1651799049,
                    "expirationTime": 1652403841,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4031",
                    "makerAddr": "0xC3C42B7b4EC32682b3057B05b5117a76A774E166",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33990000000000000000",
                    "tokenId": "9417",
                    "listingTime": 1651737937,
                    "expirationTime": 1652342731,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9417",
                    "makerAddr": "0x9Ba37491340F7110a304642Bb441De01f1391507",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33990000000000000000",
                    "tokenId": "708",
                    "listingTime": 1651452113,
                    "expirationTime": 1654044104,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/708",
                    "makerAddr": "0xD5b4A12083B7dAC81B06510877FA45232C771BC8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33990000000000000000",
                    "tokenId": "9573",
                    "listingTime": 1651767085,
                    "expirationTime": 1654359067,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9573",
                    "makerAddr": "0xEBC6d7eD591caAA959ed3A733a70Ee063cd3CC96",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33990000000000000000",
                    "tokenId": "800",
                    "listingTime": 1651710732,
                    "expirationTime": 1653950368,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/800",
                    "makerAddr": "0xC03BDA89a4CA08036058E6e111C4beacd7246051",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "33990000000000000000",
                    "tokenId": "22762",
                    "listingTime": 1651542462,
                    "expirationTime": 1654072310,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22762",
                    "makerAddr": "0xCF215c0a4A88003288c855536776666709025E62",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "708",
                    "price": "33990000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651452283,
                    "expirationTime": 1654130777,
                    "makerAddr": "0xd5b4a12083b7dac81b06510877fa45232c771bc8",
                    "assetImageUrl": "https://img.seadn.io/files/308a88a7ed46326908e79166f0bd34e1.png?auto=format"
                }
            ],
            "total": 3.748560999999999e+21,
            "looksrare": 103,
            "opensea": 10
        },
        {
            "price": 34,
            "amount": 93,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "7370",
                    "listingTime": 1651780815,
                    "expirationTime": 1652385486,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7370",
                    "makerAddr": "0xA01e30f2Df3e89F841Fa33B8cAC536b13942E443",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "14982",
                    "listingTime": 1651623552,
                    "expirationTime": 1652401200,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14982",
                    "makerAddr": "0x0D7D9AcF85FA0Bb5eE25bd8016B7c0f5A711c893",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "665",
                    "listingTime": 1651777201,
                    "expirationTime": 1667220949,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/665",
                    "makerAddr": "0xF7480A958bFA8EF59C52CA995C1d3518968ce2d9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "9961",
                    "listingTime": 1651535966,
                    "expirationTime": 1659311959,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9961",
                    "makerAddr": "0x70BAb06BA6b16387Ceb09B53F3c74D8809E3051a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "25254",
                    "listingTime": 1651651711,
                    "expirationTime": 1652252043,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25254",
                    "makerAddr": "0x1256497A1b0729E167BC52E2b08EE23a888184C9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "2776",
                    "listingTime": 1651766282,
                    "expirationTime": 1653834710,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2776",
                    "makerAddr": "0x2DFCC264b08a181BC7c38b9901059Bab369a5a4e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "17198",
                    "listingTime": 1651793359,
                    "expirationTime": 1652349028,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17198",
                    "makerAddr": "0x182Db69f652149ddda92a1756731b94CF9fb9413",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "9199",
                    "listingTime": 1651653352,
                    "expirationTime": 1653449081,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9199",
                    "makerAddr": "0x99d73D76f0058F4ae24882A49A0a9eA0f5a41268",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "20178",
                    "listingTime": 1651776810,
                    "expirationTime": 1659461641,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20178",
                    "makerAddr": "0x307E1cd1e0f955FeEB032CD7AB1df3367dc34b2B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "29734",
                    "listingTime": 1651539751,
                    "expirationTime": 1653621252,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29734",
                    "makerAddr": "0x49836dbdbEA45b90ED274F0EB688E3Cc7908D31D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "5026",
                    "listingTime": 1651558725,
                    "expirationTime": 1654076029,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5026",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "5800",
                    "listingTime": 1652065041,
                    "expirationTime": 1652324233,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5800",
                    "makerAddr": "0x7EFf7eeE42Dc0Bd27081A78fE23CFE2a72697f2B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "3724",
                    "listingTime": 1651545532,
                    "expirationTime": 1653995981,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3724",
                    "makerAddr": "0x31D447ccF5ed1905287Ca964b72F5fef01682944",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "10754",
                    "listingTime": 1651763936,
                    "expirationTime": 1652273260,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10754",
                    "makerAddr": "0x9Fe176816F90145F8aEd9fFDedEE158ed8423bA2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "2753",
                    "listingTime": 1651698082,
                    "expirationTime": 1652302875,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2753",
                    "makerAddr": "0x8f197EB762aAa442CA6e5207e43E719D7EdBb872",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "19506",
                    "listingTime": 1651773763,
                    "expirationTime": 1654269408,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19506",
                    "makerAddr": "0x7792bF1aB8bBfc74078aFAC293acBeF2e00F8625",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "21404",
                    "listingTime": 1651711822,
                    "expirationTime": 1652316520,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21404",
                    "makerAddr": "0x2e9BC111159cC4FE5479eCC81F1825cFD577E077",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "4596",
                    "listingTime": 1651787350,
                    "expirationTime": 1654379252,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4596",
                    "makerAddr": "0x95F71D6424F2B9bfc29378Ea9539372c986F2E9b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "1363",
                    "listingTime": 1651793379,
                    "expirationTime": 1652349071,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1363",
                    "makerAddr": "0x182Db69f652149ddda92a1756731b94CF9fb9413",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "7632",
                    "listingTime": 1651639683,
                    "expirationTime": 1654163494,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7632",
                    "makerAddr": "0x3873613abeaFCBd662dD7516D4bb55C235c812c5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "4352",
                    "listingTime": 1651957262,
                    "expirationTime": 1652216459,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4352",
                    "makerAddr": "0xe73e90fc400c0256Bc271C83e576ACb97603452b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "4253",
                    "listingTime": 1651741016,
                    "expirationTime": 1654332993,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4253",
                    "makerAddr": "0xF34455620ACE080469819C4aC3334e9bC9abC55F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "3001",
                    "listingTime": 1651534160,
                    "expirationTime": 1653672254,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3001",
                    "makerAddr": "0x827a399Bd350F25B6bF9F82424D2b8F9F9252d24",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "2677",
                    "listingTime": 1651544357,
                    "expirationTime": 1654080054,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2677",
                    "makerAddr": "0xA6c5d40c321412D0A16E46a59B23C1Dfd9D54eE8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "3804",
                    "listingTime": 1651609732,
                    "expirationTime": 1659385720,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3804",
                    "makerAddr": "0x1c66f8A8fa9D34D26b6767cca81E4f0fb8F0692f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "5690",
                    "listingTime": 1651536281,
                    "expirationTime": 1653351649,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5690",
                    "makerAddr": "0x57EAdd1811affBD06C03787CAA973E626C090c62",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "25942",
                    "listingTime": 1651779113,
                    "expirationTime": 1652383907,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25942",
                    "makerAddr": "0xdbf049b470e7cdb1BFc19D9Ca15B1C8C1128aa20",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "2914",
                    "listingTime": 1651558680,
                    "expirationTime": 1653366800,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2914",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "8852",
                    "listingTime": 1651643424,
                    "expirationTime": 1653852863,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8852",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "9436",
                    "listingTime": 1651558706,
                    "expirationTime": 1653366760,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9436",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "19126",
                    "listingTime": 1651540295,
                    "expirationTime": 1654026598,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19126",
                    "makerAddr": "0x385809f08e80693E1EDa54c9524a2995483Ab16E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "29956",
                    "listingTime": 1651857404,
                    "expirationTime": 1654449376,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29956",
                    "makerAddr": "0x7685274EeE0D73BB916F1e1c8d126967CA63dC21",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "18941",
                    "listingTime": 1652175539,
                    "expirationTime": 1652779993,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18941",
                    "makerAddr": "0xa0fec1A6b8453873041c7529906De5c1ACc1b26a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "19990",
                    "listingTime": 1651537996,
                    "expirationTime": 1654053758,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19990",
                    "makerAddr": "0xD9D728Eb0d7e4Ec9d256D458923eB64E59c5370f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "4082",
                    "listingTime": 1651698116,
                    "expirationTime": 1652302910,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4082",
                    "makerAddr": "0x8f197EB762aAa442CA6e5207e43E719D7EdBb872",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "5893",
                    "listingTime": 1651779616,
                    "expirationTime": 1653803334,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5893",
                    "makerAddr": "0x1FEACcB479834998BD7750754062347A6FaD8F9F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "6143",
                    "listingTime": 1652145853,
                    "expirationTime": 1654737786,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6143",
                    "makerAddr": "0x8D1C8Ee01FFe38CFD3616dCe9215a2A68b4C4008",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "7455",
                    "listingTime": 1651643360,
                    "expirationTime": 1653408852,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7455",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "3110",
                    "listingTime": 1651560473,
                    "expirationTime": 1654152457,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3110",
                    "makerAddr": "0xF0e9b336A772d9838356645097c6dBf9f8AFfDeE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "1562",
                    "listingTime": 1651453819,
                    "expirationTime": 1654045810,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1562",
                    "makerAddr": "0x7DdD4BD7213C8a8F034AA8bE336F15A1999817e5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "8739",
                    "listingTime": 1651793334,
                    "expirationTime": 1652349246,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8739",
                    "makerAddr": "0x182Db69f652149ddda92a1756731b94CF9fb9413",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "3827",
                    "listingTime": 1651643337,
                    "expirationTime": 1653408280,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3827",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "9222",
                    "listingTime": 1651536304,
                    "expirationTime": 1653351649,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9222",
                    "makerAddr": "0x57EAdd1811affBD06C03787CAA973E626C090c62",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "1958",
                    "listingTime": 1651635084,
                    "expirationTime": 1653523783,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1958",
                    "makerAddr": "0xc00667d8B00f35B3565A5c4458Dff1Cd718E3527",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "12010",
                    "listingTime": 1651566889,
                    "expirationTime": 1667118871,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12010",
                    "makerAddr": "0x25Ca2e79391AD17A73395f88985d33C121dF7d06",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "28070",
                    "listingTime": 1651558743,
                    "expirationTime": 1653366831,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28070",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "5513",
                    "listingTime": 1651533144,
                    "expirationTime": 1654125141,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5513",
                    "makerAddr": "0xEC4762fb859a53c15D1689c08FD5E48C5c1C47Fa",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "205",
                    "listingTime": 1651542023,
                    "expirationTime": 1653981354,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/205",
                    "makerAddr": "0x53f42691DF74AD573F5B8a461EBEFe09ECddf920",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "14240",
                    "listingTime": 1651546124,
                    "expirationTime": 1653736434,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14240",
                    "makerAddr": "0x7324b1202a7eB60ea969D94690ABB25f8e92c5a8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "1925",
                    "listingTime": 1651559320,
                    "expirationTime": 1654151300,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1925",
                    "makerAddr": "0xB17D5DB0EC93331271Ed2f3fFfEBE4E5b790D97E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "12974",
                    "listingTime": 1651541472,
                    "expirationTime": 1654119332,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12974",
                    "makerAddr": "0xc7f1916b741A7966Bd65144cb2B0Ba9eD1B29984",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "5650",
                    "listingTime": 1651778213,
                    "expirationTime": 1653502779,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5650",
                    "makerAddr": "0x7b9CDCC6831796fCcB21b5f7241e2cEd813Efe92",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "3726",
                    "listingTime": 1651541426,
                    "expirationTime": 1652750917,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3726",
                    "makerAddr": "0x6cE185aF62dF2Bf4d8c0fd17E0cF65A1F7c3F7a2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "18078",
                    "listingTime": 1651665359,
                    "expirationTime": 1654253825,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18078",
                    "makerAddr": "0x2Efb798F5F77B6aa5d9BDF620228817C842cF36D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "16800",
                    "listingTime": 1651541748,
                    "expirationTime": 1654133746,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16800",
                    "makerAddr": "0x2c3c28C8B928B85fe8cCb74B80BA22d300e35155",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "20438",
                    "listingTime": 1651538013,
                    "expirationTime": 1654053758,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20438",
                    "makerAddr": "0xD9D728Eb0d7e4Ec9d256D458923eB64E59c5370f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "3518",
                    "listingTime": 1651761377,
                    "expirationTime": 1652366170,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3518",
                    "makerAddr": "0x5d987F2F1689eCD4C21f1E73E2F12762570fdED5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34000000000000000000",
                    "tokenId": "18108",
                    "listingTime": 1651784012,
                    "expirationTime": 1654267015,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18108",
                    "makerAddr": "0xeBf7c7932D86907C3BC1Bd6d486308637a8264A3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "989",
                    "price": "34000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652147254,
                    "expirationTime": 1654560670,
                    "makerAddr": "0x79c91f03590fceba9b42acec18375cb279e0881e",
                    "assetImageUrl": "https://img.seadn.io/files/fd527586e112a708d0eac1973aa71a4a.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2252",
                    "price": "34000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651974784,
                    "expirationTime": 1652234071,
                    "makerAddr": "0x9ba7dbc966718bd15827b2c7119c059cd5e8a517",
                    "assetImageUrl": "https://img.seadn.io/files/e0bf09484ba9070be9b9d986eeb5cf8a.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34100000000000000000",
                    "tokenId": "6046",
                    "listingTime": 1651546273,
                    "expirationTime": 1653401649,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6046",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34100000000000000000",
                    "tokenId": "25702",
                    "listingTime": 1651559152,
                    "expirationTime": 1653799437,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25702",
                    "makerAddr": "0xe315eB04Fc42bEa83Ed213582e66051050A92F1c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34200000000000000000",
                    "tokenId": "14297",
                    "listingTime": 1651560791,
                    "expirationTime": 1653565894,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14297",
                    "makerAddr": "0x517b1c4B8C259255EE7b7aB85e6Ea0dc9a235c3E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34400000000000000000",
                    "tokenId": "5180",
                    "listingTime": 1651541746,
                    "expirationTime": 1652751339,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5180",
                    "makerAddr": "0xb10175B169292D36Fe11822653873171ad6Bb3DC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34485000000000000000",
                    "tokenId": "19694",
                    "listingTime": 1651552136,
                    "expirationTime": 1654144128,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19694",
                    "makerAddr": "0x8C11E74e933f0348C1DEE77455b6d30F00642E56",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34500000000000000000",
                    "tokenId": "6279",
                    "listingTime": 1651665963,
                    "expirationTime": 1652270757,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6279",
                    "makerAddr": "0x44c1D4AA76e9d83384f6BDdd547Ce30E4b7832a9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34500000000000000000",
                    "tokenId": "7499",
                    "listingTime": 1652102054,
                    "expirationTime": 1654694043,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7499",
                    "makerAddr": "0x45632D932579861ca72dc93b50677795253F9bAB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34500000000000000000",
                    "tokenId": "5783",
                    "listingTime": 1651785883,
                    "expirationTime": 1667215195,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5783",
                    "makerAddr": "0xCD750f5854eCE8252FB7d59D4a8Be7864241d50D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34500000000000000000",
                    "tokenId": "29534",
                    "listingTime": 1651544028,
                    "expirationTime": 1654123279,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29534",
                    "makerAddr": "0x268993C1923Cf4d6db408f5109A53FCf17ed4039",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34500000000000000000",
                    "tokenId": "20178",
                    "listingTime": 1651776725,
                    "expirationTime": 1659461641,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20178",
                    "makerAddr": "0x307E1cd1e0f955FeEB032CD7AB1df3367dc34b2B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34500000000000000000",
                    "tokenId": "9088",
                    "listingTime": 1651761542,
                    "expirationTime": 1652292703,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9088",
                    "makerAddr": "0xf9bb1fe3dBcBA49F7Ab71485FA944eAE6605eD1f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34640000000000000000",
                    "tokenId": "2688",
                    "listingTime": 1651781547,
                    "expirationTime": 1654208621,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2688",
                    "makerAddr": "0x39A7Fb978b1F43219F096d7eb0836fd08CD74e15",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34680000000000000000",
                    "tokenId": "13862",
                    "listingTime": 1652188839,
                    "expirationTime": 1654514521,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13862",
                    "makerAddr": "0xfDA939603cbf1633a875e832b05685567446c622",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34690000000000000000",
                    "tokenId": "2722",
                    "listingTime": 1651755377,
                    "expirationTime": 1654347313,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2722",
                    "makerAddr": "0xE6baCd4c2400390709513f6e4Cd141a65cf1dC55",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34690000000000000000",
                    "tokenId": "26032",
                    "listingTime": 1651637522,
                    "expirationTime": 1652227452,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26032",
                    "makerAddr": "0xc94893b6D1CC1d7ce09Fec6abC9B5949A903d2C9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34700000000000000000",
                    "tokenId": "11898",
                    "listingTime": 1651779873,
                    "expirationTime": 1654371761,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11898",
                    "makerAddr": "0x4f940D90A643a27546C885B3165b31d624f80c15",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34800000000000000000",
                    "tokenId": "9992",
                    "listingTime": 1651785204,
                    "expirationTime": 1654264937,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9992",
                    "makerAddr": "0x4fF8EAD0fde851aE23Df19b438DfFb2686AF6dcc",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34800000000000000000",
                    "tokenId": "19592",
                    "listingTime": 1651780723,
                    "expirationTime": 1654272206,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19592",
                    "makerAddr": "0xb10175B169292D36Fe11822653873171ad6Bb3DC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1510",
                    "price": "34800000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652015771,
                    "expirationTime": 1654514241,
                    "makerAddr": "0x6caccf6df062634f6fdf7581b5c3fb0ff2d993cd",
                    "assetImageUrl": "https://img.seadn.io/files/cf1b53fd46b77735336069a5bcdbaa67.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2854",
                    "price": "34800000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652003712,
                    "expirationTime": 1652608590,
                    "makerAddr": "0xa814acd9360c23e00a52306d4de1d139fae768ff",
                    "assetImageUrl": "https://img.seadn.io/files/2a78290f29ca676ee999224f58c2baa9.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34880000000000000000",
                    "tokenId": "2050",
                    "listingTime": 1652165904,
                    "expirationTime": 1652248800,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2050",
                    "makerAddr": "0xBcf9401833Aa1B79109F9D66808Ca2c9A0ab7104",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2050",
                    "price": "34880000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652165542,
                    "expirationTime": 1652252031,
                    "makerAddr": "0xbcf9401833aa1b79109f9d66808ca2c9a0ab7104",
                    "assetImageUrl": "https://img.seadn.io/files/906b8a155bbf2a5f945f4572a0cd687a.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34890000000000000000",
                    "tokenId": "23506",
                    "listingTime": 1651675182,
                    "expirationTime": 1652279970,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23506",
                    "makerAddr": "0xEc0b3df4704665b41Bc6d9488725bb939743ced8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "tokenId": "25340@-@25256@-@6927@-@24896@-@3176@-@3364@-@8201@-@9725@-@12630@-@17492@-@21640@-@22488@-@24406@-@5512@-@25706@-@833@-@29676@-@16656",
                    "price": 34893107602217927000,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34900000000000000000",
                    "tokenId": "4200",
                    "listingTime": 1652082882,
                    "expirationTime": 1652273657,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4200",
                    "makerAddr": "0x54Ca39290A74cACf4eB51a1E85710ecACb161222",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34900000000000000000",
                    "tokenId": "26288",
                    "listingTime": 1651691714,
                    "expirationTime": 1654025613,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26288",
                    "makerAddr": "0x2dc23418844EACD31a7D91A5571E000960553283",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34900000000000000000",
                    "tokenId": "24602",
                    "listingTime": 1651758031,
                    "expirationTime": 1654282797,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24602",
                    "makerAddr": "0xb21f6845648F39395f94f5C840658F793C452612",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1810",
                    "price": "34900000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651592208,
                    "expirationTime": 1663339416,
                    "makerAddr": "0x86f58ad823e50e34190b597290a547301dba994e",
                    "assetImageUrl": "https://img.seadn.io/files/e598ff0ccfd00c128c50d42938b431fd.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34980000000000000000",
                    "tokenId": "26292",
                    "listingTime": 1651593290,
                    "expirationTime": 1654185248,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26292",
                    "makerAddr": "0x19904955198DC6Cb7bEa0f7DC93959D8aCf4f2C7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34990000000000000000",
                    "tokenId": "11480",
                    "listingTime": 1651807075,
                    "expirationTime": 1654399073,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11480",
                    "makerAddr": "0xb2AB83B605bf7b9E70cA18415A16dC6239BA14a8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34990000000000000000",
                    "tokenId": "5399",
                    "listingTime": 1651618350,
                    "expirationTime": 1654045200,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5399",
                    "makerAddr": "0x13c3258823fF9C506cb72291672dc9ea1E5517E4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "34990000000000000000",
                    "tokenId": "6055",
                    "listingTime": 1651759738,
                    "expirationTime": 1666871396,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6055",
                    "makerAddr": "0x02b2bab8458D1a47F9789580495284670b5f4299",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1455",
                    "price": "34990000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651863475,
                    "expirationTime": 1652468339,
                    "makerAddr": "0xe3ff4aa7134d8568813a7f5f3622462d512904f0",
                    "assetImageUrl": "https://img.seadn.io/files/7d52e05f1f2cc94b4a8fa756d777b89d.png?auto=format"
                }
            ],
            "total": 3.1849681076022165e+21,
            "looksrare": 85,
            "opensea": 7,
            "nftx": 1
        },
        {
            "price": 35,
            "amount": 165,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "19386",
                    "listingTime": 1651594175,
                    "expirationTime": 1652458080,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19386",
                    "makerAddr": "0x6a91B82a9Abd58963b164d01CBF793CF60c089fd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "4434",
                    "listingTime": 1651668638,
                    "expirationTime": 1654172565,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4434",
                    "makerAddr": "0x252C69df96a8AcFEe60eF5ADc6d3e2a9888eFa7A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "2186",
                    "listingTime": 1651794169,
                    "expirationTime": 1652398964,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2186",
                    "makerAddr": "0x5682035b6b1D04924C7661b09A974fF0695De6bE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "27290",
                    "listingTime": 1651614795,
                    "expirationTime": 1652219588,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27290",
                    "makerAddr": "0x168109aeA51511c3787e56608804196FAa28CE24",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "16368",
                    "listingTime": 1651750860,
                    "expirationTime": 1653917473,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16368",
                    "makerAddr": "0xF6f65D23A9Fd1C907749EC9b3b34f8b838c0D278",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "27656",
                    "listingTime": 1651668125,
                    "expirationTime": 1654260118,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27656",
                    "makerAddr": "0xD880c6dcF441b6C26c2701174926F54bC1BAbDF0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "2270",
                    "listingTime": 1651571486,
                    "expirationTime": 1654163422,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2270",
                    "makerAddr": "0x75872C384475117E8C19BA27E1fBE8473aF9ebC5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "15554",
                    "listingTime": 1651471855,
                    "expirationTime": 1653386528,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15554",
                    "makerAddr": "0xc20490E8306257Df636E23BE02a291B553f341EC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "7455",
                    "listingTime": 1651631444,
                    "expirationTime": 1653408852,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7455",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "14846",
                    "listingTime": 1651481524,
                    "expirationTime": 1653821019,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14846",
                    "makerAddr": "0x6189e05a28bEF26Bcf76F22aB311282d5e843dF4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "1322",
                    "listingTime": 1651507016,
                    "expirationTime": 1653523113,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1322",
                    "makerAddr": "0x4064023610A479F9A0415eE98f87895A0e2e0884",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "77",
                    "listingTime": 1651748240,
                    "expirationTime": 1654082376,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/77",
                    "makerAddr": "0xAffca680e6dcbb1bb336108D5aCcA9c545885260",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "6178",
                    "listingTime": 1652172215,
                    "expirationTime": 1652776970,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6178",
                    "makerAddr": "0x1F06841c923e2F049aC221C3A7a0bd34a9b4b6c6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "5783",
                    "listingTime": 1651740650,
                    "expirationTime": 1667215195,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5783",
                    "makerAddr": "0xCD750f5854eCE8252FB7d59D4a8Be7864241d50D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "20408",
                    "listingTime": 1651890668,
                    "expirationTime": 1652495458,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20408",
                    "makerAddr": "0xd3A9dccb4Cfd983fF69e688dDB6491E010301b7a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "14874",
                    "listingTime": 1651507313,
                    "expirationTime": 1653430252,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14874",
                    "makerAddr": "0x0CFCD5950999F3f39A307Fd67E6442F9199EAe1A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "3301",
                    "listingTime": 1651640038,
                    "expirationTime": 1654232007,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3301",
                    "makerAddr": "0xe346388252fC3dF0a1bBcC446C03c36EE8001e3f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "27778",
                    "listingTime": 1651750845,
                    "expirationTime": 1653917279,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27778",
                    "makerAddr": "0xF6f65D23A9Fd1C907749EC9b3b34f8b838c0D278",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "28104",
                    "listingTime": 1651548362,
                    "expirationTime": 1653409436,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28104",
                    "makerAddr": "0xF83FC598fD45171d86f81ba032C46DC07aCc415f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "12974",
                    "listingTime": 1651533928,
                    "expirationTime": 1654119332,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12974",
                    "makerAddr": "0xc7f1916b741A7966Bd65144cb2B0Ba9eD1B29984",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "2226",
                    "listingTime": 1651663280,
                    "expirationTime": 1652268073,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2226",
                    "makerAddr": "0x36602d9F655E88fF539bef1Acc4A731050C8346F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "19066",
                    "listingTime": 1652150790,
                    "expirationTime": 1654742780,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19066",
                    "makerAddr": "0x0EE7e2b956da0098F8633439991F6FD8DdE5F029",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "19978",
                    "listingTime": 1651458083,
                    "expirationTime": 1654050075,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19978",
                    "makerAddr": "0x2817d099e7C4284ed5D23Af6271e9256c529BD49",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "21300",
                    "listingTime": 1651532006,
                    "expirationTime": 1654086210,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21300",
                    "makerAddr": "0xB53167fD81a9270b4Dea3F9eBF96742722cdCe50",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "29168",
                    "listingTime": 1651526097,
                    "expirationTime": 1654118019,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29168",
                    "makerAddr": "0x3fC1a2270D371D236aCeC87b2dA324d0dD69FA78",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "825",
                    "listingTime": 1651566825,
                    "expirationTime": 1652585020,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/825",
                    "makerAddr": "0xa7e4f12B7Cd97387e8607daC17B439ca7cad58e9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "23790",
                    "listingTime": 1651750636,
                    "expirationTime": 1654271001,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23790",
                    "makerAddr": "0x34687Dc0c2080b37f0f33C0EE3a88290Cee3F791",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "7104",
                    "listingTime": 1651812131,
                    "expirationTime": 1654263383,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7104",
                    "makerAddr": "0xec374C2BeAa0F8856B8Cd96D3eDbCD6d0c76E991",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "27421",
                    "listingTime": 1651895586,
                    "expirationTime": 1654487554,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27421",
                    "makerAddr": "0xdbFbe8C7b4a6328a2bC45aBa79F032C6d14bDe6b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "26702",
                    "listingTime": 1651753229,
                    "expirationTime": 1654295199,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26702",
                    "makerAddr": "0x22Ac42bC3079BC52A0B4aF56cCa588026a664544",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "21130",
                    "listingTime": 1651464188,
                    "expirationTime": 1654056183,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21130",
                    "makerAddr": "0xCdECf6e5202561B92664F53B4dE60B57f45E0cbC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "7545",
                    "listingTime": 1651866322,
                    "expirationTime": 1654254358,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7545",
                    "makerAddr": "0x8d599028E270798c349ddE3570E696E6D2d2A4F5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "27710",
                    "listingTime": 1651821524,
                    "expirationTime": 1654413394,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27710",
                    "makerAddr": "0x3e65EA82Fb012661dFEA9674E4D8C9A362BC2307",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "7077",
                    "listingTime": 1651765721,
                    "expirationTime": 1654357716,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7077",
                    "makerAddr": "0x237dd5e3a76bA4ee8ACC007CD35033c49eF966C5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "17198",
                    "listingTime": 1651744236,
                    "expirationTime": 1652349028,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17198",
                    "makerAddr": "0x182Db69f652149ddda92a1756731b94CF9fb9413",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "3132",
                    "listingTime": 1651601416,
                    "expirationTime": 1654193414,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3132",
                    "makerAddr": "0x989Cd4a3A88c86300726Edb5d5516A517eD47599",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "1363",
                    "listingTime": 1651744278,
                    "expirationTime": 1652349071,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1363",
                    "makerAddr": "0x182Db69f652149ddda92a1756731b94CF9fb9413",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "24820",
                    "listingTime": 1651522755,
                    "expirationTime": 1654114745,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24820",
                    "makerAddr": "0x2C543BeE768EAf32a09D091E8239CDc9E33c75F5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "7960",
                    "listingTime": 1651548270,
                    "expirationTime": 1653869864,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7960",
                    "makerAddr": "0xED525b5D5CD5BE436c026ee127A85A682213ae3B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "11114",
                    "listingTime": 1651548378,
                    "expirationTime": 1653409494,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11114",
                    "makerAddr": "0xF83FC598fD45171d86f81ba032C46DC07aCc415f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "4576",
                    "listingTime": 1651649662,
                    "expirationTime": 1652859256,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4576",
                    "makerAddr": "0x71bC99eFdF05D5afe4411144732C424CaB044228",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "2574",
                    "listingTime": 1651826840,
                    "expirationTime": 1654075856,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2574",
                    "makerAddr": "0x3660d7E4Fd960a97239BC0655ef489E15E4d9151",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "9992",
                    "listingTime": 1651757422,
                    "expirationTime": 1654264937,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9992",
                    "makerAddr": "0x4fF8EAD0fde851aE23Df19b438DfFb2686AF6dcc",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "18168",
                    "listingTime": 1651537493,
                    "expirationTime": 1653374529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18168",
                    "makerAddr": "0x35dF9413f56332E389b41Be14c98E63500bE0Bc6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "69",
                    "listingTime": 1651860276,
                    "expirationTime": 1654452173,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/69",
                    "makerAddr": "0xb47c55f00A31dEDA8415e67d587168e05D41f92C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "1532",
                    "listingTime": 1651753300,
                    "expirationTime": 1654345297,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1532",
                    "makerAddr": "0x8a677474dEC43FCAeB80a2Dd2B1bc5D945aB61d1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "5582",
                    "listingTime": 1651584686,
                    "expirationTime": 1653603931,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5582",
                    "makerAddr": "0xA25D7e569393583f2a17007b5619452E25fB40b9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "17800",
                    "listingTime": 1651475870,
                    "expirationTime": 1654067857,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17800",
                    "makerAddr": "0x63b2c57D8AD53397388E16787bd641507cDdb8d0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "14136",
                    "listingTime": 1651821630,
                    "expirationTime": 1654413394,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14136",
                    "makerAddr": "0x3e65EA82Fb012661dFEA9674E4D8C9A362BC2307",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "23910",
                    "listingTime": 1651455133,
                    "expirationTime": 1654047099,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23910",
                    "makerAddr": "0x8Fe1d101E65066B9D2bEacCfD96f2B6838079904",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "10646",
                    "listingTime": 1651704857,
                    "expirationTime": 1654296827,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10646",
                    "makerAddr": "0xa42057b23cAaba78F51C8A6f9DE2Bb112Bb6a0Ff",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "3817",
                    "listingTime": 1651771167,
                    "expirationTime": 1658344451,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3817",
                    "makerAddr": "0xFe6eC7350dE7742FAD9717545bB46cC9a7abCeCB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "9259",
                    "listingTime": 1651620317,
                    "expirationTime": 1654212314,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9259",
                    "makerAddr": "0xFbB6e5E82d6a688381714B5B680096D717a1f031",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "5134",
                    "listingTime": 1651631861,
                    "expirationTime": 1654047716,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5134",
                    "makerAddr": "0xEAA06F6242836cf6641a5d43C8f519224e5A4DD5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "28044",
                    "listingTime": 1651753208,
                    "expirationTime": 1654295199,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28044",
                    "makerAddr": "0x22Ac42bC3079BC52A0B4aF56cCa588026a664544",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "4400",
                    "listingTime": 1651649308,
                    "expirationTime": 1652858885,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4400",
                    "makerAddr": "0x61a4FC9028048e562b2a49d8dccf2378B644dF03",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "20684",
                    "listingTime": 1651664212,
                    "expirationTime": 1653648255,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20684",
                    "makerAddr": "0x69b38da50A977Dc61C7571c9DC8192Ea1A884DCB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "1304",
                    "listingTime": 1651499942,
                    "expirationTime": 1654091917,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1304",
                    "makerAddr": "0x6b3d3fCDB08d0813FDf0E1267C2eca056EAB3415",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "5579",
                    "listingTime": 1651532665,
                    "expirationTime": 1653397146,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5579",
                    "makerAddr": "0xD163bd16eaf4813D723F90f13fE4abeeECCdD3dd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "2127",
                    "listingTime": 1651668877,
                    "expirationTime": 1654260868,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2127",
                    "makerAddr": "0x964F690Def870B36f017F6127Ab1B929645333C0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "2007",
                    "listingTime": 1651631489,
                    "expirationTime": 1654223229,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2007",
                    "makerAddr": "0x56cfCAA14C2d63fef909E335733dE4C6330306a7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "11536",
                    "listingTime": 1651625200,
                    "expirationTime": 1654217185,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11536",
                    "makerAddr": "0x0D7D9AcF85FA0Bb5eE25bd8016B7c0f5A711c893",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "18108",
                    "listingTime": 1651766074,
                    "expirationTime": 1654267015,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18108",
                    "makerAddr": "0xeBf7c7932D86907C3BC1Bd6d486308637a8264A3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "12452",
                    "listingTime": 1651738808,
                    "expirationTime": 1654330794,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12452",
                    "makerAddr": "0x06b45bFDabD04C8f9E468FF8B94f025d7ae7d20e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "26142",
                    "listingTime": 1651751441,
                    "expirationTime": 1659527408,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26142",
                    "makerAddr": "0x416219965D7F470b5CFefB29D92FAb6b24469E02",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "40",
                    "listingTime": 1651637177,
                    "expirationTime": 1653446978,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/40",
                    "makerAddr": "0xc13a9B21B421B3111a3c79F4700734f35a71FaCD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "25262",
                    "listingTime": 1651430356,
                    "expirationTime": 1654022350,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25262",
                    "makerAddr": "0x80dE2F874067B092BcB914144EFBe1B54A731F4d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "18624",
                    "listingTime": 1651564754,
                    "expirationTime": 1653989290,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18624",
                    "makerAddr": "0xC88646ba27442A507c965Fef65bb870Da9B5Fd4f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "1524",
                    "listingTime": 1651843392,
                    "expirationTime": 1659356410,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1524",
                    "makerAddr": "0x6e548F76F3d1A30b57dd773a52F44fEcEb1d0800",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "23418",
                    "listingTime": 1651538360,
                    "expirationTime": 1654130359,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23418",
                    "makerAddr": "0xf98fF587fEb37db9e6934DDaf1aB377240430753",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "5893",
                    "listingTime": 1651641855,
                    "expirationTime": 1653803334,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5893",
                    "makerAddr": "0x1FEACcB479834998BD7750754062347A6FaD8F9F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "20534",
                    "listingTime": 1651468961,
                    "expirationTime": 1653396846,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20534",
                    "makerAddr": "0xd4745B170DeDe5eF451D46Fce6A111eE41f4047f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "6408",
                    "listingTime": 1651767593,
                    "expirationTime": 1652372387,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6408",
                    "makerAddr": "0x7AC8BfC6f1842C1A7c1b9a1e8D60972e79075766",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "12531",
                    "listingTime": 1651418914,
                    "expirationTime": 1653467422,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12531",
                    "makerAddr": "0x4BC6fE8c58926f64388EE522757774C97787BCE0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "8995",
                    "listingTime": 1651538345,
                    "expirationTime": 1654130342,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8995",
                    "makerAddr": "0xf98fF587fEb37db9e6934DDaf1aB377240430753",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "4032",
                    "listingTime": 1651531623,
                    "expirationTime": 1653458429,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4032",
                    "makerAddr": "0xD3860466ddeb2580285F3A8843e464012F171694",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "7784",
                    "listingTime": 1651530295,
                    "expirationTime": 1654122280,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7784",
                    "makerAddr": "0x7e0892263efABe46Cf1C2774327643806E3ec324",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "9299",
                    "listingTime": 1651751390,
                    "expirationTime": 1653657671,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9299",
                    "makerAddr": "0xe7e4bfeEB83A5b752A4E90D4f89fa0B21FBB54b4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "8988",
                    "listingTime": 1651747096,
                    "expirationTime": 1654339088,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8988",
                    "makerAddr": "0x2a198F10FD12dA7d6C055c0fCB0cd055cAfA20Bc",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "1529",
                    "listingTime": 1651579634,
                    "expirationTime": 1654171618,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1529",
                    "makerAddr": "0x23bA9404e59fe3F013091B5a0bb4a0Fa882FADE9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "4008",
                    "listingTime": 1651765324,
                    "expirationTime": 1654015899,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4008",
                    "makerAddr": "0xe0e5D2d2AE5a629928D452fFa325D1083AAC3997",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "1154",
                    "listingTime": 1651614754,
                    "expirationTime": 1652219545,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1154",
                    "makerAddr": "0x168109aeA51511c3787e56608804196FAa28CE24",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "1148",
                    "listingTime": 1651501881,
                    "expirationTime": 1654093858,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1148",
                    "makerAddr": "0xf65c185C86972919DE22d36ef4625CDb79725B52",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "8739",
                    "listingTime": 1651744454,
                    "expirationTime": 1652349246,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8739",
                    "makerAddr": "0x182Db69f652149ddda92a1756731b94CF9fb9413",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "6909",
                    "listingTime": 1651521748,
                    "expirationTime": 1653626475,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6909",
                    "makerAddr": "0x57C2955C0d0fC319dDF6110eEdFCC81AF3caDD72",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "2203",
                    "listingTime": 1651639713,
                    "expirationTime": 1654055433,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2203",
                    "makerAddr": "0xCbdE38eC5A7DEA3526B6209010c64B44Ee91b6c1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "1958",
                    "listingTime": 1651633933,
                    "expirationTime": 1653523783,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1958",
                    "makerAddr": "0xc00667d8B00f35B3565A5c4458Dff1Cd718E3527",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "12762",
                    "listingTime": 1651668172,
                    "expirationTime": 1654260159,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12762",
                    "makerAddr": "0xD880c6dcF441b6C26c2701174926F54bC1BAbDF0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "29592",
                    "listingTime": 1651663011,
                    "expirationTime": 1654255006,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29592",
                    "makerAddr": "0x1452bf4Ec9eB7063b4b2365783e38095477926C1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "4267",
                    "listingTime": 1651714676,
                    "expirationTime": 1654306662,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4267",
                    "makerAddr": "0x11D160BE3a2CE51fCae652202499616cb5cf3CE4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "11575",
                    "listingTime": 1652125951,
                    "expirationTime": 1652299336,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11575",
                    "makerAddr": "0x40b060A0Ac95dB3D5211b687511632b46c5d3bB7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "8852",
                    "listingTime": 1651631414,
                    "expirationTime": 1653852863,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8852",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "665",
                    "listingTime": 1651668980,
                    "expirationTime": 1667220949,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/665",
                    "makerAddr": "0xF7480A958bFA8EF59C52CA995C1d3518968ce2d9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "3827",
                    "listingTime": 1651631429,
                    "expirationTime": 1653408280,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3827",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "3885",
                    "listingTime": 1651756436,
                    "expirationTime": 1652361182,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3885",
                    "makerAddr": "0x752EdB0D23Bc11232ACaB3A3AD9Bc0Aa544aB0bD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "7557",
                    "listingTime": 1651459894,
                    "expirationTime": 1653449297,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7557",
                    "makerAddr": "0x534c98c85714Df298524CF94c3d2Cd230B1EEa5B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "497",
                    "listingTime": 1651538302,
                    "expirationTime": 1654130298,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/497",
                    "makerAddr": "0xf98fF587fEb37db9e6934DDaf1aB377240430753",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "8115",
                    "listingTime": 1651642592,
                    "expirationTime": 1654067758,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8115",
                    "makerAddr": "0x6BB56b82baD32B9950cCD84794a196c9e28f19cF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "22334",
                    "listingTime": 1651391951,
                    "expirationTime": 1653407905,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22334",
                    "makerAddr": "0x1abEd56b27691Ba3b5c0c68D2B0Be0f1638e3D4f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "14064",
                    "listingTime": 1651773090,
                    "expirationTime": 1654274060,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14064",
                    "makerAddr": "0x57E7917a016c25227387b1feAF8B73C49784f563",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "13006",
                    "listingTime": 1651631466,
                    "expirationTime": 1654223229,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13006",
                    "makerAddr": "0x56cfCAA14C2d63fef909E335733dE4C6330306a7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "29274",
                    "listingTime": 1651510107,
                    "expirationTime": 1654012992,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29274",
                    "makerAddr": "0xC9132a3f53C1c80C87d5d645d8dAFAe9DD3b8981",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "8293",
                    "listingTime": 1651772715,
                    "expirationTime": 1654364676,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8293",
                    "makerAddr": "0x02d197839712d3b6DecB9E913fF820bb2b41d337",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "22155",
                    "listingTime": 1652105036,
                    "expirationTime": 1654697027,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22155",
                    "makerAddr": "0x9A968a4E20612cD26f09246358316eFfc19219E5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "25254",
                    "listingTime": 1651647337,
                    "expirationTime": 1652252043,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25254",
                    "makerAddr": "0x1256497A1b0729E167BC52E2b08EE23a888184C9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "9520",
                    "listingTime": 1651660726,
                    "expirationTime": 1654101563,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9520",
                    "makerAddr": "0x8eC44E4d6B0b4625d9161a647507730272002a6E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "29048",
                    "listingTime": 1651847820,
                    "expirationTime": 1654090167,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29048",
                    "makerAddr": "0x9f8534b8d266C136d52428Ec0647fa0F02EBF377",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "19146",
                    "listingTime": 1651655586,
                    "expirationTime": 1653212985,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19146",
                    "makerAddr": "0x3c145Da6cbbB4F391CC23AaBd979656362A9BB9B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "6788",
                    "listingTime": 1651745523,
                    "expirationTime": 1652279720,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6788",
                    "makerAddr": "0x4199F2400fb5Ad164A065815B1ab386fD09016a0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "1965",
                    "listingTime": 1651781189,
                    "expirationTime": 1654269857,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1965",
                    "makerAddr": "0x2BC52900a118390ea8A97B29eB5e7E75F1Cd912c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "10164",
                    "listingTime": 1651763029,
                    "expirationTime": 1654281405,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10164",
                    "makerAddr": "0x8c197dB132A18Cf2c0b46A1051D7c3E43F65a17b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "4687",
                    "listingTime": 1651646859,
                    "expirationTime": 1654197301,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4687",
                    "makerAddr": "0xd28dC4e51eCD35C9D90002222bB8872cD0ff0Ea9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "9199",
                    "listingTime": 1651453048,
                    "expirationTime": 1653449081,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9199",
                    "makerAddr": "0x99d73D76f0058F4ae24882A49A0a9eA0f5a41268",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "6940",
                    "listingTime": 1651590778,
                    "expirationTime": 1654182768,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6940",
                    "makerAddr": "0x59582C789f8043D7aDA399f09e9f8a6F58dc5016",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "7945",
                    "listingTime": 1651741609,
                    "expirationTime": 1654333603,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7945",
                    "makerAddr": "0x580ab43f920C1a5180e7013C90aF0E5465D16E07",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "10754",
                    "listingTime": 1651668467,
                    "expirationTime": 1652273260,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10754",
                    "makerAddr": "0x9Fe176816F90145F8aEd9fFDedEE158ed8423bA2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "233",
                    "listingTime": 1651666205,
                    "expirationTime": 1654258193,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/233",
                    "makerAddr": "0xAfB3116Ce35Db24b4163e0Bb3dA0252240434F56",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35000000000000000000",
                    "tokenId": "7471",
                    "listingTime": 1652135164,
                    "expirationTime": 1652221558,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7471",
                    "makerAddr": "0x730Ca4dFec76805297bAD7E51F2cC5bCb8c2D6A1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "344",
                    "price": "35000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652110257,
                    "expirationTime": 1654092776,
                    "makerAddr": "0x014f3a8bbcc20c17428a8ee9214f4923e30ba4ee",
                    "assetImageUrl": "https://img.seadn.io/files/c41e0d655fe918397b56356231dc5bce.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "971",
                    "price": "35000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652139744,
                    "expirationTime": 1654818229,
                    "makerAddr": "0x1dcdda173ef0405e7ff3bb2433b58ce1aa92eccc",
                    "assetImageUrl": "https://img.seadn.io/files/4423c670e1641037ffb21d2e6fc9b1fb.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1524",
                    "price": "35000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651781614,
                    "expirationTime": 1667257735,
                    "makerAddr": "0x6e548f76f3d1a30b57dd773a52f44feceb1d0800",
                    "assetImageUrl": "https://img.seadn.io/files/41451ff1b6f9fb7c9bf065a59b53040e.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1580",
                    "price": "35000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651903578,
                    "expirationTime": 1652508468,
                    "makerAddr": "0xf50ea6e87b449400606c1ced7a7552fc704dbbd8",
                    "assetImageUrl": "https://img.seadn.io/files/55f59e3289e226bd91ddbe2b5924eb31.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1897",
                    "price": "35000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652197677,
                    "expirationTime": 1654876163,
                    "makerAddr": "0x7b3afc18936f9d0410002cdc9c4f28d772ca41af",
                    "assetImageUrl": "https://img.seadn.io/files/389137d2e6021be3508e78512de4b254.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2603",
                    "price": "35000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651687497,
                    "expirationTime": 1652292238,
                    "makerAddr": "0xc706e6fd04aa2e5c09bddcdc8ec2ea83c3f28d97",
                    "assetImageUrl": "https://img.seadn.io/files/22a5dcb8456058f982da4485a8e8ebd9.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2753",
                    "price": "35000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651644939,
                    "expirationTime": 1652249815,
                    "makerAddr": "0x8f197eb762aaa442ca6e5207e43e719d7edbb872",
                    "assetImageUrl": "https://img.seadn.io/files/426aa64bd502bb07c18ea9b7e672f6c6.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2779",
                    "price": "35000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651833726,
                    "expirationTime": 1653399920,
                    "makerAddr": "0xa6148251a4471e73ea69b161c06105e8d55c4f18",
                    "assetImageUrl": "https://img.seadn.io/files/9f17c29ee2996d2c7988808d23b36b95.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3132",
                    "price": "35000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651601485,
                    "expirationTime": 1654279979,
                    "makerAddr": "0x989cd4a3a88c86300726edb5d5516a517ed47599",
                    "assetImageUrl": "https://img.seadn.io/files/79d493021240ad436fd0af076b9d24fc.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35100000000000000000",
                    "tokenId": "15244",
                    "listingTime": 1651627403,
                    "expirationTime": 1654219385,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15244",
                    "makerAddr": "0x28570D9d66627e0733dFE4FCa79B8fD5b8128636",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35200000000000000000",
                    "tokenId": "16954",
                    "listingTime": 1651751543,
                    "expirationTime": 1659513831,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16954",
                    "makerAddr": "0xD9563E7CbA955b5A1f95BCA4bA264f071489766C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35200000000000000000",
                    "tokenId": "8995",
                    "listingTime": 1651538391,
                    "expirationTime": 1654130387,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8995",
                    "makerAddr": "0xf98fF587fEb37db9e6934DDaf1aB377240430753",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35200000000000000000",
                    "tokenId": "9440",
                    "listingTime": 1651751569,
                    "expirationTime": 1659513910,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9440",
                    "makerAddr": "0xD9563E7CbA955b5A1f95BCA4bA264f071489766C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35250000000000000000",
                    "tokenId": "8963",
                    "listingTime": 1651768336,
                    "expirationTime": 1654360303,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8963",
                    "makerAddr": "0xA6C88571d0028f47ADba983A7240Bf12Af94633e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35333000000000000000",
                    "tokenId": "17705",
                    "listingTime": 1651625057,
                    "expirationTime": 1654103827,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17705",
                    "makerAddr": "0x8B702421BF56Cc3886341b31FAD9b78F8C962A8A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35350000000000000000",
                    "tokenId": "1948",
                    "listingTime": 1651764075,
                    "expirationTime": 1654112606,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1948",
                    "makerAddr": "0x7a704A51b9D9Dab9f3bFFBD0685a25f70661b64d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35350000000000000000",
                    "tokenId": "2456",
                    "listingTime": 1651766818,
                    "expirationTime": 1652293203,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2456",
                    "makerAddr": "0x6D70fE2C48BD2fa5c30eC4C7708DD1472EB29847",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35400000000000000000",
                    "tokenId": "9745",
                    "listingTime": 1651755999,
                    "expirationTime": 1653450204,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9745",
                    "makerAddr": "0x74D8D562CafcF710C7f950Cf32501EF14b2642b1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35420000000000000000",
                    "tokenId": "2650",
                    "listingTime": 1651751287,
                    "expirationTime": 1653977591,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2650",
                    "makerAddr": "0x6978d5039DBd1aEb184954e659E9988dA208C787",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35480000000000000000",
                    "tokenId": "29466",
                    "listingTime": 1651745523,
                    "expirationTime": 1667296996,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29466",
                    "makerAddr": "0x42bbf9250F73A3Be4811e3405A62EE408aB251cF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35490000000000000000",
                    "tokenId": "9623",
                    "listingTime": 1651669855,
                    "expirationTime": 1652637254,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9623",
                    "makerAddr": "0x13aff6B6d6Ad092e8D552Af8FbdE6203770ad0eB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35500000000000000000",
                    "tokenId": "18962",
                    "listingTime": 1651646964,
                    "expirationTime": 1653406554,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18962",
                    "makerAddr": "0xfa861eE098C87c78AB96d780C5211d9557869920",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35500000000000000000",
                    "tokenId": "408",
                    "listingTime": 1651646911,
                    "expirationTime": 1653405721,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/408",
                    "makerAddr": "0x98d31E06789783d5C1DD2E18013f04C9dAb78656",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35500000000000000000",
                    "tokenId": "1754",
                    "listingTime": 1651964489,
                    "expirationTime": 1652568792,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1754",
                    "makerAddr": "0x62Be43C314f05De97856776180A8042447BEEd6E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35500000000000000000",
                    "tokenId": "4745",
                    "listingTime": 1651647051,
                    "expirationTime": 1653405548,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4745",
                    "makerAddr": "0xdF41003BcdC9aa917Fdae801BD02b3167368D5CD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35500000000000000000",
                    "tokenId": "9651",
                    "listingTime": 1651635648,
                    "expirationTime": 1654227638,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9651",
                    "makerAddr": "0x2082c5a3C08A4D5ADe0cE779e24B463daA241646",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35500000000000000000",
                    "tokenId": "4008",
                    "listingTime": 1651639426,
                    "expirationTime": 1654015899,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4008",
                    "makerAddr": "0xe0e5D2d2AE5a629928D452fFa325D1083AAC3997",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35500000000000000000",
                    "tokenId": "458",
                    "listingTime": 1652153492,
                    "expirationTime": 1654745485,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/458",
                    "makerAddr": "0x22Ac42bC3079BC52A0B4aF56cCa588026a664544",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35500000000000000000",
                    "tokenId": "79",
                    "listingTime": 1651455488,
                    "expirationTime": 1654047378,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/79",
                    "makerAddr": "0x1278Bf1cA85Bfe406e23502700C7Cb94eA36B21A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35500000000000000000",
                    "tokenId": "5383",
                    "listingTime": 1651638613,
                    "expirationTime": 1653823241,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5383",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35500000000000000000",
                    "tokenId": "1687",
                    "listingTime": 1651532586,
                    "expirationTime": 1653569271,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1687",
                    "makerAddr": "0x2eA07698a88AD2a0Acfc82C229C5cBaDb7CC9c9C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35500000000000000000",
                    "tokenId": "4088",
                    "listingTime": 1651636449,
                    "expirationTime": 1653407259,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4088",
                    "makerAddr": "0x3e9b7732616A6153D2Ba41f2198702299DB7cCa6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35500000000000000000",
                    "tokenId": "16630",
                    "listingTime": 1651522630,
                    "expirationTime": 1653569406,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16630",
                    "makerAddr": "0x007075fAb8b3bd4227392A7caABF1c9eFfa56111",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1754",
                    "price": "35500000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651963336,
                    "expirationTime": 1652568169,
                    "makerAddr": "0x62be43c314f05de97856776180a8042447beed6e",
                    "assetImageUrl": "https://img.seadn.io/files/c7c25ec1cf40581e6f3a5233ad78dbe1.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35600000000000000000",
                    "tokenId": "7014",
                    "listingTime": 1651800865,
                    "expirationTime": 1654254689,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7014",
                    "makerAddr": "0x908c6143512b4Ec00170c56e8954746EF7b5A577",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35600000000000000000",
                    "tokenId": "2738",
                    "listingTime": 1651649179,
                    "expirationTime": 1652698222,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2738",
                    "makerAddr": "0x4D7646bFCB29fa194267aA60382bA82671dF3195",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35625000000000000000",
                    "tokenId": "6087",
                    "listingTime": 1651647515,
                    "expirationTime": 1654236655,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6087",
                    "makerAddr": "0x659e8BE7d5B030e9f3483F43750570F973B0858B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35690000000000000000",
                    "tokenId": "3091",
                    "listingTime": 1651503566,
                    "expirationTime": 1654095552,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3091",
                    "makerAddr": "0x1E4d44f00D42052326fBA19ecaD73846875B0557",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35690000000000000000",
                    "tokenId": "8395",
                    "listingTime": 1651532835,
                    "expirationTime": 1653412294,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8395",
                    "makerAddr": "0x4df2CDB9AdDc77f7f198b26deE5E77f4278bf28f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35690000000000000000",
                    "tokenId": "15727",
                    "listingTime": 1651560777,
                    "expirationTime": 1654152762,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15727",
                    "makerAddr": "0x15D19e3F3af0D4ac2E0891065c2724C8bf98BBB6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35690000000000000000",
                    "tokenId": "3264",
                    "listingTime": 1651767750,
                    "expirationTime": 1654359719,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3264",
                    "makerAddr": "0xfBb3669A32E0814ee3491a1581a40b2Bf0F512a2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35690000000000000000",
                    "tokenId": "20516",
                    "listingTime": 1651672995,
                    "expirationTime": 1654264978,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20516",
                    "makerAddr": "0x1E4d44f00D42052326fBA19ecaD73846875B0557",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35750000000000000000",
                    "tokenId": "5582",
                    "listingTime": 1651517145,
                    "expirationTime": 1653603931,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5582",
                    "makerAddr": "0xA25D7e569393583f2a17007b5619452E25fB40b9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1782",
                    "price": "35800000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651817231,
                    "expirationTime": 1654186495,
                    "makerAddr": "0xcc1ca37601164a9b77e60ad301abb28dfbc4f1bd",
                    "assetImageUrl": "https://img.seadn.io/files/0ea25e7a3c184b8faa0d4ec526eb7769.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35850000000000000000",
                    "tokenId": "26032",
                    "listingTime": 1651622657,
                    "expirationTime": 1652227452,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26032",
                    "makerAddr": "0xc94893b6D1CC1d7ce09Fec6abC9B5949A903d2C9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35990000000000000000",
                    "tokenId": "5987",
                    "listingTime": 1651670596,
                    "expirationTime": 1652275323,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5987",
                    "makerAddr": "0x32CD5366146D909eC9e9533aAa363f273956E1C1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "35990000000000000000",
                    "tokenId": "22768",
                    "listingTime": 1651592702,
                    "expirationTime": 1654184618,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22768",
                    "makerAddr": "0xCF215c0a4A88003288c855536776666709025E62",
                    "assetImageUrl": ""
                }
            ],
            "total": 5.794928000000016e+21,
            "looksrare": 154,
            "opensea": 11
        },
        {
            "price": 36,
            "amount": 114,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "2112",
                    "listingTime": 1651703195,
                    "expirationTime": 1652307985,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2112",
                    "makerAddr": "0xC2A6934EDEc5332c63b09C50E3E85fDd2A83B0E2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "3984",
                    "listingTime": 1651565363,
                    "expirationTime": 1652700605,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3984",
                    "makerAddr": "0xfc51d083940480135406Fa84D2b30F7E8857080D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "15078",
                    "listingTime": 1651565270,
                    "expirationTime": 1653474819,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15078",
                    "makerAddr": "0x14210914D6A65Fa2C7747579A7aa287B027fD092",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "14966",
                    "listingTime": 1651530698,
                    "expirationTime": 1653405602,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14966",
                    "makerAddr": "0x2319b02CA79f3253339b06baCB9a1de9C00A61e9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "6513",
                    "listingTime": 1651565554,
                    "expirationTime": 1659341540,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6513",
                    "makerAddr": "0xb3b3195F08004eBcD43f5f40AF68C9fa18B2C669",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "15774",
                    "listingTime": 1652100540,
                    "expirationTime": 1652273550,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15774",
                    "makerAddr": "0xca7adC0614c3Ea4Dc6625E20cdA60AdB8Ef3dFEc",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "205",
                    "listingTime": 1651533145,
                    "expirationTime": 1653981354,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/205",
                    "makerAddr": "0x53f42691DF74AD573F5B8a461EBEFe09ECddf920",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "3001",
                    "listingTime": 1651514428,
                    "expirationTime": 1653672254,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3001",
                    "makerAddr": "0x827a399Bd350F25B6bF9F82424D2b8F9F9252d24",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "16440",
                    "listingTime": 1651530707,
                    "expirationTime": 1654057841,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16440",
                    "makerAddr": "0x72f835E889705966dB801419B1CC580F17Db1d02",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "23150",
                    "listingTime": 1651515880,
                    "expirationTime": 1653430416,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23150",
                    "makerAddr": "0xbb980fd226AC348A842875A029e6A7b25De9a447",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "24692",
                    "listingTime": 1651563356,
                    "expirationTime": 1652772950,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24692",
                    "makerAddr": "0xc602bbe267bb6D68EFBC394E54D7B6Faf0355695",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "4320",
                    "listingTime": 1651673677,
                    "expirationTime": 1652883272,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4320",
                    "makerAddr": "0x5853dC7fE370FB7c600C3eAf0e27b800849cBB7e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "2426",
                    "listingTime": 1651717138,
                    "expirationTime": 1652926732,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2426",
                    "makerAddr": "0xEa0ed16746257eb2BC11DE2FefD63cDEECe23A98",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "20528",
                    "listingTime": 1651633544,
                    "expirationTime": 1654047209,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20528",
                    "makerAddr": "0x1F189D44905466e5f6302b48b7e85Fea174aEcAd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "5893",
                    "listingTime": 1651533245,
                    "expirationTime": 1653803334,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5893",
                    "makerAddr": "0x1FEACcB479834998BD7750754062347A6FaD8F9F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "7545",
                    "listingTime": 1651785843,
                    "expirationTime": 1654254358,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7545",
                    "makerAddr": "0x8d599028E270798c349ddE3570E696E6D2d2A4F5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "27268",
                    "listingTime": 1651663918,
                    "expirationTime": 1654255891,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27268",
                    "makerAddr": "0xaC8352e02660A4dA6732Ea27306B59760243eAD2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "27316",
                    "listingTime": 1651675140,
                    "expirationTime": 1654267129,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27316",
                    "makerAddr": "0xBdba988D4e2c6B1A3BF645B93A08d12Ce5C52f8d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "19126",
                    "listingTime": 1651500835,
                    "expirationTime": 1654026598,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19126",
                    "makerAddr": "0x385809f08e80693E1EDa54c9524a2995483Ab16E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "1245",
                    "listingTime": 1651442494,
                    "expirationTime": 1652652009,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1245",
                    "makerAddr": "0x14E2deCA153ccFB209728e584C4264abF3155f78",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "25794",
                    "listingTime": 1651523586,
                    "expirationTime": 1654115547,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25794",
                    "makerAddr": "0xf88c3Be78Fb163e61d106ae44fF73f86A5AA36cE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "22272",
                    "listingTime": 1651717841,
                    "expirationTime": 1654309720,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22272",
                    "makerAddr": "0x19fffB371b95574DF860E90b6A5b69256629B585",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "1568",
                    "listingTime": 1651473520,
                    "expirationTime": 1653992137,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1568",
                    "makerAddr": "0x835ba4bA03713208Fa8404acd626466C9273F93c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "11304",
                    "listingTime": 1651580111,
                    "expirationTime": 1654023884,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11304",
                    "makerAddr": "0x0d4428C8E62e894999d8E4c1845f23cb5F9d919d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "2574",
                    "listingTime": 1651564856,
                    "expirationTime": 1654075856,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2574",
                    "makerAddr": "0x3660d7E4Fd960a97239BC0655ef489E15E4d9151",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "6882",
                    "listingTime": 1651568824,
                    "expirationTime": 1653060022,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6882",
                    "makerAddr": "0xC10F5F82B7B0392DB11Bbf06CDE93Fc0aeA120Cb",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "3857",
                    "listingTime": 1651808853,
                    "expirationTime": 1654400852,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3857",
                    "makerAddr": "0x08BD65c8d7308fef47eb6F0c78351fb2b6B49033",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "20148",
                    "listingTime": 1651520054,
                    "expirationTime": 1654112029,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20148",
                    "makerAddr": "0x534c98c85714Df298524CF94c3d2Cd230B1EEa5B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "17826",
                    "listingTime": 1651484084,
                    "expirationTime": 1653992284,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17826",
                    "makerAddr": "0xab36Ef38d02E5aeC0fCcBc6B76772A0CF3E69A38",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "1573",
                    "listingTime": 1651528247,
                    "expirationTime": 1658593707,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1573",
                    "makerAddr": "0x683EbbA7A833A809071afb4819C0a2Dfd6e07333",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "2738",
                    "listingTime": 1651569791,
                    "expirationTime": 1652698222,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2738",
                    "makerAddr": "0x4D7646bFCB29fa194267aA60382bA82671dF3195",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "29734",
                    "listingTime": 1651480674,
                    "expirationTime": 1653621252,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29734",
                    "makerAddr": "0x49836dbdbEA45b90ED274F0EB688E3Cc7908D31D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "1541",
                    "listingTime": 1651608348,
                    "expirationTime": 1654200297,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1541",
                    "makerAddr": "0xE07e19F63b3e0f426c102Ea5f5A30DD0C72Ad807",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "26748",
                    "listingTime": 1651679071,
                    "expirationTime": 1654271043,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26748",
                    "makerAddr": "0x7502dE34c9ad924C6F0F4aa4A8113168cE5bB596",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "7632",
                    "listingTime": 1651571503,
                    "expirationTime": 1654163494,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7632",
                    "makerAddr": "0x3873613abeaFCBd662dD7516D4bb55C235c812c5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "26066",
                    "listingTime": 1651699638,
                    "expirationTime": 1654291618,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26066",
                    "makerAddr": "0x04fa5c3869dEd7D00f36f13597A2f46aEe4C9220",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "17516",
                    "listingTime": 1651481246,
                    "expirationTime": 1654010796,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17516",
                    "makerAddr": "0xBEFE9091541d2FD2Bb2B931536Fbee0383C4c4E0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "10510",
                    "listingTime": 1651636582,
                    "expirationTime": 1659412422,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10510",
                    "makerAddr": "0x27A8a7aBF271d86BcBCf07933F579c6d12E0293F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "7904",
                    "listingTime": 1651592812,
                    "expirationTime": 1654184803,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7904",
                    "makerAddr": "0x727F25672f4F2815831ED496c87B33faEb639238",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "6250",
                    "listingTime": 1651454180,
                    "expirationTime": 1653485399,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6250",
                    "makerAddr": "0x989cE0d5f551d94EC87E638Dc643c014e63a0c0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "19506",
                    "listingTime": 1651677422,
                    "expirationTime": 1654269408,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19506",
                    "makerAddr": "0x7792bF1aB8bBfc74078aFAC293acBeF2e00F8625",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "2689",
                    "listingTime": 1652198390,
                    "expirationTime": 1652284785,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2689",
                    "makerAddr": "0x45B0f81150Ff50975165D47d2020598987F71f31",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "331",
                    "listingTime": 1651580056,
                    "expirationTime": 1654023884,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/331",
                    "makerAddr": "0x0d4428C8E62e894999d8E4c1845f23cb5F9d919d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "24448",
                    "listingTime": 1651611137,
                    "expirationTime": 1653405104,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24448",
                    "makerAddr": "0x3922B064DcC34289B2c04f82B92977c413a0dAd2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "3481",
                    "listingTime": 1651662780,
                    "expirationTime": 1654252816,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3481",
                    "makerAddr": "0x6A1BA3300800746eb55b84e7D1D913cEB76D489f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "4877",
                    "listingTime": 1651527931,
                    "expirationTime": 1654094665,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4877",
                    "makerAddr": "0x7485a56F2b65569a0808F3eB5DF55A99b9E62F32",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "18168",
                    "listingTime": 1651478777,
                    "expirationTime": 1653374529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18168",
                    "makerAddr": "0x35dF9413f56332E389b41Be14c98E63500bE0Bc6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "6391",
                    "listingTime": 1651525453,
                    "expirationTime": 1652735045,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6391",
                    "makerAddr": "0x7Bb19A33ec9B3D501c0a6374f084682E9E000c43",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "18688",
                    "listingTime": 1651628034,
                    "expirationTime": 1652232823,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18688",
                    "makerAddr": "0xACDCE49f8C24F9adc366d1dc8c7b51ceb84b02aa",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "4584",
                    "listingTime": 1651484068,
                    "expirationTime": 1653992259,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4584",
                    "makerAddr": "0xab36Ef38d02E5aeC0fCcBc6B76772A0CF3E69A38",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "21648",
                    "listingTime": 1651569785,
                    "expirationTime": 1654011503,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21648",
                    "makerAddr": "0x5aD68B984Dd528715A54895D32d1CF9115E7aB7B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "1958",
                    "listingTime": 1651633508,
                    "expirationTime": 1653523783,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1958",
                    "makerAddr": "0xc00667d8B00f35B3565A5c4458Dff1Cd718E3527",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "29122",
                    "listingTime": 1651462359,
                    "expirationTime": 1653986126,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29122",
                    "makerAddr": "0x8f197EB762aAa442CA6e5207e43E719D7EdBb872",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "14782",
                    "listingTime": 1651578807,
                    "expirationTime": 1654170788,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14782",
                    "makerAddr": "0x41F3a0bd4D9036082d199f592C617Bdc8Ea62fa7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "7178",
                    "listingTime": 1651512033,
                    "expirationTime": 1654104024,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7178",
                    "makerAddr": "0xf22cB833a12Ee0EF4Fa6F78Fe463784d7fd3b061",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "11480",
                    "listingTime": 1651452003,
                    "expirationTime": 1654008738,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11480",
                    "makerAddr": "0xb2AB83B605bf7b9E70cA18415A16dC6239BA14a8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "4088",
                    "listingTime": 1651636265,
                    "expirationTime": 1653407259,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4088",
                    "makerAddr": "0x3e9b7732616A6153D2Ba41f2198702299DB7cCa6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "12974",
                    "listingTime": 1651529312,
                    "expirationTime": 1654119332,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12974",
                    "makerAddr": "0xc7f1916b741A7966Bd65144cb2B0Ba9eD1B29984",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "8177",
                    "listingTime": 1651532879,
                    "expirationTime": 1653412065,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8177",
                    "makerAddr": "0x4df2CDB9AdDc77f7f198b26deE5E77f4278bf28f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "26702",
                    "listingTime": 1651703277,
                    "expirationTime": 1654295199,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26702",
                    "makerAddr": "0x22Ac42bC3079BC52A0B4aF56cCa588026a664544",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "5951",
                    "listingTime": 1651737845,
                    "expirationTime": 1652342640,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5951",
                    "makerAddr": "0x3E86eAC93A2dbB4faEB8709d77d451aeDA710eF9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "1390",
                    "listingTime": 1651715132,
                    "expirationTime": 1652319927,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1390",
                    "makerAddr": "0xdC8aa824091014f97377Ad69D2069d9e22A4BBE6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "20726",
                    "listingTime": 1651502565,
                    "expirationTime": 1654094550,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20726",
                    "makerAddr": "0x08fB01893eBD994A01f9d6F822AF7a1dDE8544A8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "3817",
                    "listingTime": 1651607016,
                    "expirationTime": 1658344451,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3817",
                    "makerAddr": "0xFe6eC7350dE7742FAD9717545bB46cC9a7abCeCB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "16430",
                    "listingTime": 1651586085,
                    "expirationTime": 1653405467,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16430",
                    "makerAddr": "0x3fdcCb1837602F5e9B5E55db80E298AD5b262276",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "7781",
                    "listingTime": 1651452234,
                    "expirationTime": 1654044222,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7781",
                    "makerAddr": "0xD5b4A12083B7dAC81B06510877FA45232C771BC8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "28044",
                    "listingTime": 1651703284,
                    "expirationTime": 1654295199,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28044",
                    "makerAddr": "0x22Ac42bC3079BC52A0B4aF56cCa588026a664544",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "17017",
                    "listingTime": 1651717855,
                    "expirationTime": 1654309720,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17017",
                    "makerAddr": "0x19fffB371b95574DF860E90b6A5b69256629B585",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "1755",
                    "listingTime": 1652121177,
                    "expirationTime": 1653241525,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1755",
                    "makerAddr": "0xa3C2F38B394c64670997ED5D2Cf8Ccd12d57F5Db",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "5271",
                    "listingTime": 1651728536,
                    "expirationTime": 1654320528,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5271",
                    "makerAddr": "0x302Fe0b28F7E8e545Ef3fD7C184e754e3C74b379",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "29534",
                    "listingTime": 1651531328,
                    "expirationTime": 1654123279,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29534",
                    "makerAddr": "0x268993C1923Cf4d6db408f5109A53FCf17ed4039",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "15844",
                    "listingTime": 1651469239,
                    "expirationTime": 1653512002,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15844",
                    "makerAddr": "0x56E1E208fAc771d39486aCa9146B2c71C30751C5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "7409",
                    "listingTime": 1652139775,
                    "expirationTime": 1652398941,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7409",
                    "makerAddr": "0xa1446746F2e3f184B5a06CEEB99d68624f008A8e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "7142",
                    "listingTime": 1651564955,
                    "expirationTime": 1652774548,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7142",
                    "makerAddr": "0x5853dC7fE370FB7c600C3eAf0e27b800849cBB7e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "8392",
                    "listingTime": 1651601387,
                    "expirationTime": 1652206176,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8392",
                    "makerAddr": "0x3A70344c268cD039B107D9f65705F6092303c919",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "21970",
                    "listingTime": 1651482658,
                    "expirationTime": 1653473921,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21970",
                    "makerAddr": "0xBF21B6B77B893E11e96d79157Eb417F3e4E9538e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "18805",
                    "listingTime": 1651717829,
                    "expirationTime": 1654309720,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18805",
                    "makerAddr": "0x19fffB371b95574DF860E90b6A5b69256629B585",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "4687",
                    "listingTime": 1651605311,
                    "expirationTime": 1654197301,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4687",
                    "makerAddr": "0xd28dC4e51eCD35C9D90002222bB8872cD0ff0Ea9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "9967",
                    "listingTime": 1651626331,
                    "expirationTime": 1653871848,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9967",
                    "makerAddr": "0x440c7504C2FCaC49bA1cb429C6883F1E15819b30",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "1853",
                    "listingTime": 1651452301,
                    "expirationTime": 1654044290,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1853",
                    "makerAddr": "0xD5b4A12083B7dAC81B06510877FA45232C771BC8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36000000000000000000",
                    "tokenId": "19992",
                    "listingTime": 1651604611,
                    "expirationTime": 1654196578,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19992",
                    "makerAddr": "0x0f2b9C4487794A86DDA0F08E1787cdFdbD023cDf",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36100000000000000000",
                    "tokenId": "9745",
                    "listingTime": 1651566920,
                    "expirationTime": 1653450204,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9745",
                    "makerAddr": "0x74D8D562CafcF710C7f950Cf32501EF14b2642b1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36100000000000000000",
                    "tokenId": "4912",
                    "listingTime": 1651662614,
                    "expirationTime": 1652267398,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4912",
                    "makerAddr": "0x3aff23b34571F74dCc5A31eb8120c7536a7F2de9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36300000000000000000",
                    "tokenId": "1782",
                    "listingTime": 1651771389,
                    "expirationTime": 1654157603,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1782",
                    "makerAddr": "0xcc1CA37601164A9B77e60AD301Abb28dfbC4F1bD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36400000000000000000",
                    "tokenId": "100",
                    "listingTime": 1651567639,
                    "expirationTime": 1653416589,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/100",
                    "makerAddr": "0xc9ca66FB145E146812De286aC78a533718c493d4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36500000000000000000",
                    "tokenId": "6046",
                    "listingTime": 1651527641,
                    "expirationTime": 1653401649,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6046",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36500000000000000000",
                    "tokenId": "23924",
                    "listingTime": 1651482695,
                    "expirationTime": 1653475571,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23924",
                    "makerAddr": "0xBF21B6B77B893E11e96d79157Eb417F3e4E9538e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36500000000000000000",
                    "tokenId": "14826",
                    "listingTime": 1651452121,
                    "expirationTime": 1653401558,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14826",
                    "makerAddr": "0xdBE2258624F94aB8EE30CeB67B2a078b24bB6d6d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36500000000000000000",
                    "tokenId": "13434",
                    "listingTime": 1651509955,
                    "expirationTime": 1654101862,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13434",
                    "makerAddr": "0x282A40a6dF1534cf5943fc63A9c57ebaDEE240a5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36500000000000000000",
                    "tokenId": "7097",
                    "listingTime": 1651528317,
                    "expirationTime": 1653447935,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7097",
                    "makerAddr": "0x683EbbA7A833A809071afb4819C0a2Dfd6e07333",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36500000000000000000",
                    "tokenId": "9520",
                    "listingTime": 1651509627,
                    "expirationTime": 1654101563,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9520",
                    "makerAddr": "0x8eC44E4d6B0b4625d9161a647507730272002a6E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36500000000000000000",
                    "tokenId": "12620",
                    "listingTime": 1651579751,
                    "expirationTime": 1654171641,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12620",
                    "makerAddr": "0x1B9E3b253339c7505a09644e5bF7be4BfB4F981A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36500000000000000000",
                    "tokenId": "7203",
                    "listingTime": 1651594257,
                    "expirationTime": 1654186223,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7203",
                    "makerAddr": "0x30CF55BF0A7b5011083E8B02bEe6b14B6f5193f2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36500000000000000000",
                    "tokenId": "5383",
                    "listingTime": 1651527701,
                    "expirationTime": 1653823241,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5383",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36500000000000000000",
                    "tokenId": "2574",
                    "listingTime": 1651483987,
                    "expirationTime": 1654075856,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2574",
                    "makerAddr": "0x3660d7E4Fd960a97239BC0655ef489E15E4d9151",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "458",
                    "price": "36500000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652182223,
                    "expirationTime": 1654860699,
                    "makerAddr": "0x22ac42bc3079bc52a0b4af56cca588026a664544",
                    "assetImageUrl": "https://img.seadn.io/files/8d300b22bb16b8730d408363b9e485ec.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36660000000000000000",
                    "tokenId": "11064",
                    "listingTime": 1651451918,
                    "expirationTime": 1653405091,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11064",
                    "makerAddr": "0x5fB998B8024E38867558fD5E2b87B6D13Da730f8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36690000000000000000",
                    "tokenId": "7325",
                    "listingTime": 1651514059,
                    "expirationTime": 1653499413,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7325",
                    "makerAddr": "0xe794f5e304109E77FE7b7E6D5bD9B1D69C6560A8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36690000000000000000",
                    "tokenId": "8914",
                    "listingTime": 1651518392,
                    "expirationTime": 1653827606,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8914",
                    "makerAddr": "0x300772D881e58A4eb2BB0844D5FC9B2e5476B31b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36700000000000000000",
                    "tokenId": "24602",
                    "listingTime": 1651734558,
                    "expirationTime": 1654282797,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24602",
                    "makerAddr": "0xb21f6845648F39395f94f5C840658F793C452612",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36700000000000000000",
                    "tokenId": "16954",
                    "listingTime": 1651737839,
                    "expirationTime": 1659513831,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16954",
                    "makerAddr": "0xD9563E7CbA955b5A1f95BCA4bA264f071489766C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36700000000000000000",
                    "tokenId": "9440",
                    "listingTime": 1651737922,
                    "expirationTime": 1659513910,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9440",
                    "makerAddr": "0xD9563E7CbA955b5A1f95BCA4bA264f071489766C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36740000000000000000",
                    "tokenId": "13888",
                    "listingTime": 1651622502,
                    "expirationTime": 1652227286,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13888",
                    "makerAddr": "0xb4888d62EaE94d810045e0F450E1cfd2F38D1519",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36750000000000000000",
                    "tokenId": "18630",
                    "listingTime": 1651529585,
                    "expirationTime": 1652739152,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18630",
                    "makerAddr": "0x45fA95D4bb1b3c74507a32c785476e739f832D99",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36750000000000000000",
                    "tokenId": "6402",
                    "listingTime": 1651625939,
                    "expirationTime": 1653444204,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6402",
                    "makerAddr": "0xD83556df9Ff191750eB8816B7Eb6126A9A245608",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36750000000000000000",
                    "tokenId": "6584",
                    "listingTime": 1651625803,
                    "expirationTime": 1652230568,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6584",
                    "makerAddr": "0x9Cd90876e44afC5B07FB57c7ed50DA5B3F5d1Ca3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36750000000000000000",
                    "tokenId": "7768",
                    "listingTime": 1651626013,
                    "expirationTime": 1653444836,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7768",
                    "makerAddr": "0x2264303067Db0d5bfa3746F409B5A5129eDdB3d9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36880000000000000000",
                    "tokenId": "15600",
                    "listingTime": 1652165974,
                    "expirationTime": 1652418473,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15600",
                    "makerAddr": "0xBcf9401833Aa1B79109F9D66808Ca2c9A0ab7104",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36900000000000000000",
                    "tokenId": "1782",
                    "listingTime": 1651736811,
                    "expirationTime": 1654157603,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1782",
                    "makerAddr": "0xcc1CA37601164A9B77e60AD301Abb28dfbC4F1bD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36900000000000000000",
                    "tokenId": "12698",
                    "listingTime": 1652109614,
                    "expirationTime": 1652538240,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12698",
                    "makerAddr": "0x1610634C1fCda56b9934d67f885b30dC73f938CB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36900000000000000000",
                    "tokenId": "26288",
                    "listingTime": 1651433625,
                    "expirationTime": 1654025613,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26288",
                    "makerAddr": "0x2dc23418844EACD31a7D91A5571E000960553283",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2715",
                    "price": "36900000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652155762,
                    "expirationTime": 1654834251,
                    "makerAddr": "0x7f5bc50b539dc282e82a72a517c8acc73f161431",
                    "assetImageUrl": "https://img.seadn.io/files/bbff04a099090a494e0ebe191c06670f.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2386",
                    "price": "36942000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652155544,
                    "expirationTime": 1654834026,
                    "makerAddr": "0x7f5bc50b539dc282e82a72a517c8acc73f161431",
                    "assetImageUrl": "https://img.seadn.io/files/3f63346caa89f0d4a00d17c0358bffd4.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "36990000000000000000",
                    "tokenId": "6055",
                    "listingTime": 1651480488,
                    "expirationTime": 1666871396,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6055",
                    "makerAddr": "0x02b2bab8458D1a47F9789580495284670b5f4299",
                    "assetImageUrl": ""
                }
            ],
            "total": 4.124691999999997e+21,
            "looksrare": 111,
            "opensea": 3
        },
        {
            "price": 37,
            "amount": 143,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "460",
                    "listingTime": 1651588860,
                    "expirationTime": 1654010197,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/460",
                    "makerAddr": "0x77d8d216380A532Bd3238dA63e3788e4150BcEd8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "189",
                    "listingTime": 1651662964,
                    "expirationTime": 1654254566,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/189",
                    "makerAddr": "0x3d3FBE809eEA54E106E5B30EA2f5Dedbc94D8eE8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "6616",
                    "listingTime": 1651821494,
                    "expirationTime": 1654413394,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6616",
                    "makerAddr": "0x3e65EA82Fb012661dFEA9674E4D8C9A362BC2307",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "3984",
                    "listingTime": 1651491011,
                    "expirationTime": 1652700605,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3984",
                    "makerAddr": "0xfc51d083940480135406Fa84D2b30F7E8857080D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "7499",
                    "listingTime": 1651797265,
                    "expirationTime": 1654389259,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7499",
                    "makerAddr": "0x45632D932579861ca72dc93b50677795253F9bAB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "1967",
                    "listingTime": 1651469269,
                    "expirationTime": 1653499354,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1967",
                    "makerAddr": "0x56E1E208fAc771d39486aCa9146B2c71C30751C5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "4400",
                    "listingTime": 1651585184,
                    "expirationTime": 1659361119,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4400",
                    "makerAddr": "0x61a4FC9028048e562b2a49d8dccf2378B644dF03",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "4498",
                    "listingTime": 1651592436,
                    "expirationTime": 1654052907,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4498",
                    "makerAddr": "0xf29D4D7B3270beB3C54EE703fE2d9d17BF806C81",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "20066",
                    "listingTime": 1651512919,
                    "expirationTime": 1658259241,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20066",
                    "makerAddr": "0x65ebb279694E4ff517a11C92c9C57a9A017eA4F0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "15702",
                    "listingTime": 1651577139,
                    "expirationTime": 1654169119,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15702",
                    "makerAddr": "0x4328D5d49dB3D1824a9C3A52013057E7cD5EDc12",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "825",
                    "listingTime": 1651476514,
                    "expirationTime": 1652585020,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/825",
                    "makerAddr": "0xa7e4f12B7Cd97387e8607daC17B439ca7cad58e9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "9299",
                    "listingTime": 1651454704,
                    "expirationTime": 1653657671,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9299",
                    "makerAddr": "0xe7e4bfeEB83A5b752A4E90D4f89fa0B21FBB54b4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "367",
                    "listingTime": 1651482859,
                    "expirationTime": 1659258851,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/367",
                    "makerAddr": "0xD099B763fa289F3eB695Cf69fd4102C6bf266E0d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "8852",
                    "listingTime": 1651511453,
                    "expirationTime": 1653852863,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8852",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "12974",
                    "listingTime": 1651527352,
                    "expirationTime": 1654119332,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12974",
                    "makerAddr": "0xc7f1916b741A7966Bd65144cb2B0Ba9eD1B29984",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "5383",
                    "listingTime": 1651526627,
                    "expirationTime": 1653823241,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5383",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "9150",
                    "listingTime": 1651485835,
                    "expirationTime": 1653465468,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9150",
                    "makerAddr": "0x17b128B6771F5D26de3348db0cd222328cc1Eb47",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "1687",
                    "listingTime": 1651469354,
                    "expirationTime": 1653569271,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1687",
                    "makerAddr": "0x2eA07698a88AD2a0Acfc82C229C5cBaDb7CC9c9C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "9180",
                    "listingTime": 1651675578,
                    "expirationTime": 1654267536,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9180",
                    "makerAddr": "0x277a67708887100e9330f12585FB1D62507D36eC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "15106",
                    "listingTime": 1651487046,
                    "expirationTime": 1653463301,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15106",
                    "makerAddr": "0xc741EB22eD5500DdFb587D7bE04d22c0aDe3851C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "1642",
                    "listingTime": 1651581032,
                    "expirationTime": 1666388866,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1642",
                    "makerAddr": "0xfb363b7D7a1c065356442829F03C84F9660CE1e2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "205",
                    "listingTime": 1651451234,
                    "expirationTime": 1653981354,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/205",
                    "makerAddr": "0x53f42691DF74AD573F5B8a461EBEFe09ECddf920",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "5783",
                    "listingTime": 1651663201,
                    "expirationTime": 1667215195,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5783",
                    "makerAddr": "0xCD750f5854eCE8252FB7d59D4a8Be7864241d50D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "20981",
                    "listingTime": 1651601484,
                    "expirationTime": 1652206184,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20981",
                    "makerAddr": "0xC7b72Bb1aCDcE3eB45f9B38F43cD31e4927C725b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "15896",
                    "listingTime": 1651687445,
                    "expirationTime": 1654279436,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15896",
                    "makerAddr": "0x54A06E9DB5e8bC2a60Cbb83AD4F8755283d330e9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "2442",
                    "listingTime": 1651525463,
                    "expirationTime": 1654117442,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2442",
                    "makerAddr": "0x28fA50E0D4Be750DB69A745403DD1395471C9ED0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "14730",
                    "listingTime": 1651483371,
                    "expirationTime": 1666809771,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14730",
                    "makerAddr": "0x497402DC41E79523500BE7C35193a5879298091e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "3066",
                    "listingTime": 1651508765,
                    "expirationTime": 1654100758,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3066",
                    "makerAddr": "0x54C25c35Bc53C8821bF71F87d3dee852EeB22760",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "22748",
                    "listingTime": 1651453461,
                    "expirationTime": 1652591644,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22748",
                    "makerAddr": "0x2155D5C0A63AF068a00443483C4CD572ae73b09b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "8144",
                    "listingTime": 1651732673,
                    "expirationTime": 1652337416,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8144",
                    "makerAddr": "0x6B7568C994ED1a25524F89BC4Fd459a3bC0a982C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "23356",
                    "listingTime": 1651445572,
                    "expirationTime": 1653591186,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23356",
                    "makerAddr": "0x8B9Cc341084337c0A5828BC0D5E20dADB197d991",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "4929",
                    "listingTime": 1651566878,
                    "expirationTime": 1654055138,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4929",
                    "makerAddr": "0x766D87345908d18C0e79187be9a291E08531B2c6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "24472",
                    "listingTime": 1651517362,
                    "expirationTime": 1654109351,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24472",
                    "makerAddr": "0x15053b688f69d742AD18f13f47d15E097E4A77e7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "8062",
                    "listingTime": 1652114968,
                    "expirationTime": 1652201340,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8062",
                    "makerAddr": "0x19A1Dac1043Bf873C6A5BE78f59c64378c2E87C1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "23814",
                    "listingTime": 1651708429,
                    "expirationTime": 1654300418,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23814",
                    "makerAddr": "0xBE2d830CBD4628b06DE78B4eE660E1385a9152A6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "14468",
                    "listingTime": 1651470586,
                    "expirationTime": 1652680177,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14468",
                    "makerAddr": "0xB69570317FE6C96495298Ca5237C5f57F6B313D6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "21300",
                    "listingTime": 1651494235,
                    "expirationTime": 1654086210,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21300",
                    "makerAddr": "0xB53167fD81a9270b4Dea3F9eBF96742722cdCe50",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "1524",
                    "listingTime": 1651580418,
                    "expirationTime": 1659356410,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1524",
                    "makerAddr": "0x6e548F76F3d1A30b57dd773a52F44fEcEb1d0800",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "7455",
                    "listingTime": 1651591303,
                    "expirationTime": 1653408852,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7455",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "20664",
                    "listingTime": 1651510968,
                    "expirationTime": 1653437727,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20664",
                    "makerAddr": "0x545a2eD169EaC188638763539D30951488a9c8F7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "20528",
                    "listingTime": 1651455241,
                    "expirationTime": 1654047209,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20528",
                    "makerAddr": "0x1F189D44905466e5f6302b48b7e85Fea174aEcAd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "9961",
                    "listingTime": 1651488786,
                    "expirationTime": 1667040781,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9961",
                    "makerAddr": "0x70BAb06BA6b16387Ceb09B53F3c74D8809E3051a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "18962",
                    "listingTime": 1651584943,
                    "expirationTime": 1653406554,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18962",
                    "makerAddr": "0xfa861eE098C87c78AB96d780C5211d9557869920",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "10592",
                    "listingTime": 1651698637,
                    "expirationTime": 1654290631,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10592",
                    "makerAddr": "0xDF617Fc072215c638137B3038628b420064c06B2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "3827",
                    "listingTime": 1651511468,
                    "expirationTime": 1653408280,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3827",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "5134",
                    "listingTime": 1651455731,
                    "expirationTime": 1654047716,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5134",
                    "makerAddr": "0xEAA06F6242836cf6641a5d43C8f519224e5A4DD5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "530",
                    "listingTime": 1651604868,
                    "expirationTime": 1654196830,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/530",
                    "makerAddr": "0x38c9fA85A85A59D95c3B1102961Af946eF3DF11B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "667",
                    "listingTime": 1651523421,
                    "expirationTime": 1653903639,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/667",
                    "makerAddr": "0x38d7eAd5550D20C518453C55feb8992B0F8BFfB2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "16800",
                    "listingTime": 1651452417,
                    "expirationTime": 1654044415,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16800",
                    "makerAddr": "0x2c3c28C8B928B85fe8cCb74B80BA22d300e35155",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "21540",
                    "listingTime": 1651482857,
                    "expirationTime": 1659258851,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21540",
                    "makerAddr": "0xD099B763fa289F3eB695Cf69fd4102C6bf266E0d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "20782",
                    "listingTime": 1651698318,
                    "expirationTime": 1652303106,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20782",
                    "makerAddr": "0x3527C3c2A66C1959502171c935c734C9E91A16E2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "23224",
                    "listingTime": 1651525616,
                    "expirationTime": 1653536693,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23224",
                    "makerAddr": "0xeDb400039b6678e468F03c1637AF625121062250",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "7296",
                    "listingTime": 1651613579,
                    "expirationTime": 1653973345,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7296",
                    "makerAddr": "0x4c8F62f1498FA55D4158CdBFEA7783f84556a68e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "10712",
                    "listingTime": 1651662922,
                    "expirationTime": 1654254566,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10712",
                    "makerAddr": "0x3d3FBE809eEA54E106E5B30EA2f5Dedbc94D8eE8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "5893",
                    "listingTime": 1651450061,
                    "expirationTime": 1653803334,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5893",
                    "makerAddr": "0x1FEACcB479834998BD7750754062347A6FaD8F9F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "5387",
                    "listingTime": 1651406598,
                    "expirationTime": 1653998527,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5387",
                    "makerAddr": "0x599727A2E1E6D8493c5B71800D4b44d7aDC9d9AE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "8949",
                    "listingTime": 1651747446,
                    "expirationTime": 1654126248,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8949",
                    "makerAddr": "0x709bF4aC7ED6Bb2F9d60b1215d983496AB68efbc",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "8115",
                    "listingTime": 1651475765,
                    "expirationTime": 1654067758,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8115",
                    "makerAddr": "0x6BB56b82baD32B9950cCD84794a196c9e28f19cF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "5650",
                    "listingTime": 1651515852,
                    "expirationTime": 1653502779,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5650",
                    "makerAddr": "0x7b9CDCC6831796fCcB21b5f7241e2cEd813Efe92",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "100",
                    "listingTime": 1651517564,
                    "expirationTime": 1653416589,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/100",
                    "makerAddr": "0xc9ca66FB145E146812De286aC78a533718c493d4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "2902",
                    "listingTime": 1651511633,
                    "expirationTime": 1652479116,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2902",
                    "makerAddr": "0x85FD8d14AF7bf91e2f1799f20AA64E5C1FC58357",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "20926",
                    "listingTime": 1651573312,
                    "expirationTime": 1654162856,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20926",
                    "makerAddr": "0xB5E14DCf2520d154606254094f32C8D32D509115",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "6046",
                    "listingTime": 1651526371,
                    "expirationTime": 1653401649,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6046",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "408",
                    "listingTime": 1651585084,
                    "expirationTime": 1653405721,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/408",
                    "makerAddr": "0x98d31E06789783d5C1DD2E18013f04C9dAb78656",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "29784",
                    "listingTime": 1651475909,
                    "expirationTime": 1653710988,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29784",
                    "makerAddr": "0xaDF631c4F37052B9F5eE63aD88975dDaaef0F7aA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "11006",
                    "listingTime": 1651706705,
                    "expirationTime": 1652916297,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11006",
                    "makerAddr": "0x68CD95b4c5b6A84ba7F8dDFCB52C334E98de7A25",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "6087",
                    "listingTime": 1651644726,
                    "expirationTime": 1654236655,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6087",
                    "makerAddr": "0x659e8BE7d5B030e9f3483F43750570F973B0858B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "26206",
                    "listingTime": 1651467861,
                    "expirationTime": 1653525504,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26206",
                    "makerAddr": "0x0563b837bA1831066A10F923DeB91E8cc57608dd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "7351",
                    "listingTime": 1651500974,
                    "expirationTime": 1654011882,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7351",
                    "makerAddr": "0x56767eD9B7e23607Ba62a1CF41B8708b9Dbb5555",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "24982",
                    "listingTime": 1651738000,
                    "expirationTime": 1654329988,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24982",
                    "makerAddr": "0x06b45bFDabD04C8f9E468FF8B94f025d7ae7d20e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "4030",
                    "listingTime": 1651588906,
                    "expirationTime": 1654010197,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4030",
                    "makerAddr": "0x77d8d216380A532Bd3238dA63e3788e4150BcEd8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "18599",
                    "listingTime": 1652045660,
                    "expirationTime": 1652650452,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18599",
                    "makerAddr": "0x8f197EB762aAa442CA6e5207e43E719D7EdBb872",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "23926",
                    "listingTime": 1651450171,
                    "expirationTime": 1653579875,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23926",
                    "makerAddr": "0xf1b1cF1a44CadE3d7a0962d61abDC475f1f3D093",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "9967",
                    "listingTime": 1651518918,
                    "expirationTime": 1653871848,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9967",
                    "makerAddr": "0x440c7504C2FCaC49bA1cb429C6883F1E15819b30",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "77",
                    "listingTime": 1651490383,
                    "expirationTime": 1654082376,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/77",
                    "makerAddr": "0xAffca680e6dcbb1bb336108D5aCcA9c545885260",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "6189",
                    "listingTime": 1651445966,
                    "expirationTime": 1652413514,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6189",
                    "makerAddr": "0xE82151c961E7f91839417a236a45E8E560655B1a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "4200",
                    "listingTime": 1652014464,
                    "expirationTime": 1652273657,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4200",
                    "makerAddr": "0x54Ca39290A74cACf4eB51a1E85710ecACb161222",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "1755",
                    "listingTime": 1652031939,
                    "expirationTime": 1653241525,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1755",
                    "makerAddr": "0xa3C2F38B394c64670997ED5D2Cf8Ccd12d57F5Db",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "1009",
                    "listingTime": 1652180513,
                    "expirationTime": 1654772469,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1009",
                    "makerAddr": "0xA5836A1f1B6dbD88248F960cD83dcb2BDE1C92ae",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "1699",
                    "listingTime": 1651574290,
                    "expirationTime": 1654166277,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1699",
                    "makerAddr": "0x4D686ED00a56e84BEc29EF236ED5eaF4d9C57F28",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "4088",
                    "listingTime": 1651511933,
                    "expirationTime": 1653407259,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4088",
                    "makerAddr": "0x3e9b7732616A6153D2Ba41f2198702299DB7cCa6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "5579",
                    "listingTime": 1651451887,
                    "expirationTime": 1653397146,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5579",
                    "makerAddr": "0xD163bd16eaf4813D723F90f13fE4abeeECCdD3dd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "3083",
                    "listingTime": 1651461936,
                    "expirationTime": 1653414972,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3083",
                    "makerAddr": "0xC344FCe3710d5971b24b11947535FAcE3eCd5655",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "7582",
                    "listingTime": 1651524305,
                    "expirationTime": 1654052278,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7582",
                    "makerAddr": "0xA89C95f3b69c6435fa022fcE66EFa46289E24476",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "4622",
                    "listingTime": 1651520123,
                    "expirationTime": 1653834728,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4622",
                    "makerAddr": "0x998f20a7f456690F2983E5D4639288C0A1f33810",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "23166",
                    "listingTime": 1651588950,
                    "expirationTime": 1654010197,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23166",
                    "makerAddr": "0x77d8d216380A532Bd3238dA63e3788e4150BcEd8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37000000000000000000",
                    "tokenId": "6373",
                    "listingTime": 1651530757,
                    "expirationTime": 1653391192,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6373",
                    "makerAddr": "0xE9FbEbb23e5CCC02EEe6E751adA3f3BbD20C630D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2705",
                    "price": "37000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651882309,
                    "expirationTime": 1652487194,
                    "makerAddr": "0x79c91f03590fceba9b42acec18375cb279e0881e",
                    "assetImageUrl": "https://img.seadn.io/files/f5c6fe853e1ae4e978590e4dfbe26cd7.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37300000000000000000",
                    "tokenId": "4008",
                    "listingTime": 1651615362,
                    "expirationTime": 1654015899,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4008",
                    "makerAddr": "0xe0e5D2d2AE5a629928D452fFa325D1083AAC3997",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37300000000000000000",
                    "tokenId": "15544",
                    "listingTime": 1651524031,
                    "expirationTime": 1654116017,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15544",
                    "makerAddr": "0x6E404D8eBf475e196E0581Df3B5C1D43478ad40C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37330000000000000000",
                    "tokenId": "17705",
                    "listingTime": 1651511904,
                    "expirationTime": 1654103827,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17705",
                    "makerAddr": "0x8B702421BF56Cc3886341b31FAD9b78F8C962A8A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37350000000000000000",
                    "tokenId": "1935",
                    "listingTime": 1651502346,
                    "expirationTime": 1666885368,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1935",
                    "makerAddr": "0x7B220358c3EC9Ec03b08120DcCEC56F16Fbf33a4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37400000000000000000",
                    "tokenId": "6682",
                    "listingTime": 1651490603,
                    "expirationTime": 1653398147,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6682",
                    "makerAddr": "0x71dfD2baB718F5bB30f1099494a68752B5d2f2e2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37400000000000000000",
                    "tokenId": "22836",
                    "listingTime": 1651613122,
                    "expirationTime": 1653354516,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22836",
                    "makerAddr": "0x0f28862bb9cC96f865c8047268230fF1248C7721",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37400000000000000000",
                    "tokenId": "1782",
                    "listingTime": 1651661783,
                    "expirationTime": 1654157603,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1782",
                    "makerAddr": "0xcc1CA37601164A9B77e60AD301Abb28dfbC4F1bD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37400000000000000000",
                    "tokenId": "20178",
                    "listingTime": 1651774770,
                    "expirationTime": 1659461641,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20178",
                    "makerAddr": "0x307E1cd1e0f955FeEB032CD7AB1df3367dc34b2B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37400000000000000000",
                    "tokenId": "24054",
                    "listingTime": 1651613290,
                    "expirationTime": 1654091474,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24054",
                    "makerAddr": "0xC3d34cf9d526db02b8a10Fa72897a57FeaBdf82A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37485000000000000000",
                    "tokenId": "209",
                    "listingTime": 1651453982,
                    "expirationTime": 1654045890,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/209",
                    "makerAddr": "0x568d5132D2a2FAC4D5671509Ab9D787Af54D0810",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37490000000000000000",
                    "tokenId": "27620",
                    "listingTime": 1651522770,
                    "expirationTime": 1654114763,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27620",
                    "makerAddr": "0x3a69648665ADDf5D4E633CE5E6c8774241487041",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37490000000000000000",
                    "tokenId": "22762",
                    "listingTime": 1651480523,
                    "expirationTime": 1654072310,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22762",
                    "makerAddr": "0xCF215c0a4A88003288c855536776666709025E62",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37490000000000000000",
                    "tokenId": "23012",
                    "listingTime": 1651922844,
                    "expirationTime": 1653979393,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23012",
                    "makerAddr": "0x298d5537C181766D22a27D40b90A9c8ccA5b5aD5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37490000000000000000",
                    "tokenId": "1948",
                    "listingTime": 1651520615,
                    "expirationTime": 1654112606,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1948",
                    "makerAddr": "0x7a704A51b9D9Dab9f3bFFBD0685a25f70661b64d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "10164",
                    "listingTime": 1651717008,
                    "expirationTime": 1654281405,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10164",
                    "makerAddr": "0x8c197dB132A18Cf2c0b46A1051D7c3E43F65a17b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "21774",
                    "listingTime": 1651720662,
                    "expirationTime": 1652325301,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21774",
                    "makerAddr": "0x50471203a2be347d16E300E6875AdF3509118C5B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "20684",
                    "listingTime": 1651589863,
                    "expirationTime": 1653648255,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20684",
                    "makerAddr": "0x69b38da50A977Dc61C7571c9DC8192Ea1A884DCB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "28070",
                    "listingTime": 1651484069,
                    "expirationTime": 1653366831,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28070",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "1889",
                    "listingTime": 1651584503,
                    "expirationTime": 1653369564,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1889",
                    "makerAddr": "0x33F60a805bFd4009249b4318abcDe2952dB92d46",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "2976",
                    "listingTime": 1651523454,
                    "expirationTime": 1653903673,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2976",
                    "makerAddr": "0x38d7eAd5550D20C518453C55feb8992B0F8BFfB2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "3724",
                    "listingTime": 1651448965,
                    "expirationTime": 1653995981,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3724",
                    "makerAddr": "0x31D447ccF5ed1905287Ca964b72F5fef01682944",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "3878",
                    "listingTime": 1651506039,
                    "expirationTime": 1666347219,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3878",
                    "makerAddr": "0xDC859b3da111A0Eb0AD3bB536dCB5d9f14f19F99",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "2914",
                    "listingTime": 1651483919,
                    "expirationTime": 1653366800,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2914",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "1890",
                    "listingTime": 1651604564,
                    "expirationTime": 1654169857,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1890",
                    "makerAddr": "0xD60BB8cF18aD76D60e7b3A40e72116f1F053b2e9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "27260",
                    "listingTime": 1651487249,
                    "expirationTime": 1653144752,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27260",
                    "makerAddr": "0x96a3ba33255a96634E98D108cc111DDC87376d7a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "2799",
                    "listingTime": 1651667813,
                    "expirationTime": 1653482039,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2799",
                    "makerAddr": "0xC0B956baD990CF94C46df2c2fc2BF189B89f9DcA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "20178",
                    "listingTime": 1651755491,
                    "expirationTime": 1659461641,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20178",
                    "makerAddr": "0x307E1cd1e0f955FeEB032CD7AB1df3367dc34b2B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "18884",
                    "listingTime": 1651515943,
                    "expirationTime": 1654107930,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18884",
                    "makerAddr": "0x5Da169E7D08B3F0fa675BB88736898DF884502A0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "5026",
                    "listingTime": 1651484043,
                    "expirationTime": 1654076029,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5026",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "7731",
                    "listingTime": 1651751653,
                    "expirationTime": 1654001233,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7731",
                    "makerAddr": "0x5a72504387807cC22a5B5F65148e7D6D83b9ae80",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "6423",
                    "listingTime": 1651585191,
                    "expirationTime": 1653627080,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6423",
                    "makerAddr": "0xdF41003BcdC9aa917Fdae801BD02b3167368D5CD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "20438",
                    "listingTime": 1651461778,
                    "expirationTime": 1654053758,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20438",
                    "makerAddr": "0xD9D728Eb0d7e4Ec9d256D458923eB64E59c5370f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "2677",
                    "listingTime": 1651488082,
                    "expirationTime": 1654080054,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2677",
                    "makerAddr": "0xA6c5d40c321412D0A16E46a59B23C1Dfd9D54eE8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "5148",
                    "listingTime": 1651600634,
                    "expirationTime": 1653942302,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5148",
                    "makerAddr": "0x75Cf2D77d40abd92756c704A963098a87b4e1aEC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "4745",
                    "listingTime": 1651585173,
                    "expirationTime": 1653405548,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4745",
                    "makerAddr": "0xdF41003BcdC9aa917Fdae801BD02b3167368D5CD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "19990",
                    "listingTime": 1651461775,
                    "expirationTime": 1654053758,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19990",
                    "makerAddr": "0xD9D728Eb0d7e4Ec9d256D458923eB64E59c5370f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "23174",
                    "listingTime": 1651590437,
                    "expirationTime": 1658677747,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23174",
                    "makerAddr": "0x7e2225B3e54A7ad0f2D009237a4f136C02F90a52",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "2638",
                    "listingTime": 1651727019,
                    "expirationTime": 1652331813,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2638",
                    "makerAddr": "0x9FAC4Ad57f467B57aBEd7b8F6f87e76062333DB2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "7907",
                    "listingTime": 1651485129,
                    "expirationTime": 1653446066,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7907",
                    "makerAddr": "0x8DD037eB27dBF4E464b1Bba1A014B956314DaD21",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "9436",
                    "listingTime": 1651484362,
                    "expirationTime": 1653366760,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9436",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "2078",
                    "listingTime": 1651667751,
                    "expirationTime": 1654259746,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2078",
                    "makerAddr": "0x0d973069308A5b8D3473fEFA3cf585bB9Af4d7BC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37500000000000000000",
                    "tokenId": "25864",
                    "listingTime": 1651683724,
                    "expirationTime": 1654275661,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25864",
                    "makerAddr": "0x8cc24CF5970F9c7AA94aB00Fbf9e6E86fd01c41B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1132",
                    "price": "37500000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652074969,
                    "expirationTime": 1667391710,
                    "makerAddr": "0xd867242ec03eeb70276025353ebad5dad7edb89f",
                    "assetImageUrl": "https://img.seadn.io/files/ce0984ac6e463dd8dcfb6a0ac655181f.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37750000000000000000",
                    "tokenId": "6905",
                    "listingTime": 1651512773,
                    "expirationTime": 1654088301,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6905",
                    "makerAddr": "0x09382e9bBdF4A47d48d83D56857704cF6faB1F82",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1860",
                    "price": "37750000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651454191,
                    "expirationTime": 1654132685,
                    "makerAddr": "0xaa621b960f22911462550c078df678493c22b2ae",
                    "assetImageUrl": "https://img.seadn.io/files/205a3558280633688d97030bd344e95b.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37800000000000000000",
                    "tokenId": "12828",
                    "listingTime": 1651714242,
                    "expirationTime": 1654269431,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12828",
                    "makerAddr": "0x495F45452c2B47bA1089E72BdBFe71c081E7B306",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37800000000000000000",
                    "tokenId": "4008",
                    "listingTime": 1651600599,
                    "expirationTime": 1654015899,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4008",
                    "makerAddr": "0xe0e5D2d2AE5a629928D452fFa325D1083AAC3997",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37800000000000000000",
                    "tokenId": "1532",
                    "listingTime": 1651733316,
                    "expirationTime": 1654325295,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1532",
                    "makerAddr": "0x8a677474dEC43FCAeB80a2Dd2B1bc5D945aB61d1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37800000000000000000",
                    "tokenId": "8158",
                    "listingTime": 1651512308,
                    "expirationTime": 1653784904,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8158",
                    "makerAddr": "0x35EF89E19faa86fC306fC30f6Ea4Bd6b89a21310",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37800000000000000000",
                    "tokenId": "1782",
                    "listingTime": 1651565659,
                    "expirationTime": 1654157603,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1782",
                    "makerAddr": "0xcc1CA37601164A9B77e60AD301Abb28dfbC4F1bD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37800000000000000000",
                    "tokenId": "8995",
                    "listingTime": 1651511529,
                    "expirationTime": 1654103516,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8995",
                    "makerAddr": "0xf98fF587fEb37db9e6934DDaf1aB377240430753",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37800000000000000000",
                    "tokenId": "6788",
                    "listingTime": 1651708252,
                    "expirationTime": 1652279720,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6788",
                    "makerAddr": "0x4199F2400fb5Ad164A065815B1ab386fD09016a0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37900000000000000000",
                    "tokenId": "9992",
                    "listingTime": 1651672950,
                    "expirationTime": 1654264937,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9992",
                    "makerAddr": "0x4fF8EAD0fde851aE23Df19b438DfFb2686AF6dcc",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "37990000000000000000",
                    "tokenId": "2650",
                    "listingTime": 1651469237,
                    "expirationTime": 1653977591,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2650",
                    "makerAddr": "0x6978d5039DBd1aEb184954e659E9988dA208C787",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2650",
                    "price": "37990000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651747585,
                    "expirationTime": 1652352448,
                    "makerAddr": "0x6978d5039dbd1aeb184954e659e9988da208c787",
                    "assetImageUrl": "https://img.seadn.io/files/b1cdea0a3fde9c610eba1230235d8886.png?auto=format"
                }
            ],
            "total": 5.321204999999995e+21,
            "looksrare": 139,
            "opensea": 4
        },
        {
            "price": 38,
            "amount": 104,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "4384",
                    "listingTime": 1651793112,
                    "expirationTime": 1652397903,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4384",
                    "makerAddr": "0x5b3C2035F87ECd710D363A8C9C982f53259c6edD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "19102",
                    "listingTime": 1651587732,
                    "expirationTime": 1654179615,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19102",
                    "makerAddr": "0x04F53C463e2ef0371897dd54b45848d2c82219E5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "2914",
                    "listingTime": 1651471524,
                    "expirationTime": 1653366800,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2914",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "24914",
                    "listingTime": 1652050201,
                    "expirationTime": 1652654990,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24914",
                    "makerAddr": "0xFEAfF63e94268c2dd6170794Bc7dDd5007910CbD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "27659",
                    "listingTime": 1651976547,
                    "expirationTime": 1652494273,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27659",
                    "makerAddr": "0x2f670A086b5b426BA56086149Ca1904027E403b9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "7545",
                    "listingTime": 1651662370,
                    "expirationTime": 1654254358,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7545",
                    "makerAddr": "0x8d599028E270798c349ddE3570E696E6D2d2A4F5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "19210",
                    "listingTime": 1651676875,
                    "expirationTime": 1652886452,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19210",
                    "makerAddr": "0x3071A71194c5b128C6E96BfB55b35C1abd82e363",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "20182",
                    "listingTime": 1651588910,
                    "expirationTime": 1654180888,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20182",
                    "makerAddr": "0x40D91c40b23c27946b6b0438E678b6eB2a68a1a5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "4051",
                    "listingTime": 1651503193,
                    "expirationTime": 1654095113,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4051",
                    "makerAddr": "0xCE9B0fa3E9aD1408e983efFb601716a2C80f9046",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "3613",
                    "listingTime": 1651499200,
                    "expirationTime": 1666469638,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3613",
                    "makerAddr": "0x25Ca2e79391AD17A73395f88985d33C121dF7d06",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "14064",
                    "listingTime": 1651682070,
                    "expirationTime": 1654274060,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14064",
                    "makerAddr": "0x57E7917a016c25227387b1feAF8B73C49784f563",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "14320",
                    "listingTime": 1651579295,
                    "expirationTime": 1652447540,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14320",
                    "makerAddr": "0x1711D0178C219F33428614163F50275A8954793B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "18108",
                    "listingTime": 1651715378,
                    "expirationTime": 1654267015,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18108",
                    "makerAddr": "0xeBf7c7932D86907C3BC1Bd6d486308637a8264A3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "5274",
                    "listingTime": 1651678215,
                    "expirationTime": 1654254510,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5274",
                    "makerAddr": "0xaBeA9711C5672C870f65835Ff84Cea14c353EE57",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "3817",
                    "listingTime": 1651468675,
                    "expirationTime": 1658344451,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3817",
                    "makerAddr": "0xFe6eC7350dE7742FAD9717545bB46cC9a7abCeCB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "20178",
                    "listingTime": 1651717808,
                    "expirationTime": 1659461641,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20178",
                    "makerAddr": "0x307E1cd1e0f955FeEB032CD7AB1df3367dc34b2B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "9118",
                    "listingTime": 1651678248,
                    "expirationTime": 1654254448,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9118",
                    "makerAddr": "0x44bdd22FFb03b5077A44b8E01d23170CA07E77a5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "6795",
                    "listingTime": 1651594187,
                    "expirationTime": 1652198940,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6795",
                    "makerAddr": "0xFBe36362c0F2ffAA3c436a95F959Ae7F8ddd26b2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "29734",
                    "listingTime": 1651387049,
                    "expirationTime": 1653621252,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29734",
                    "makerAddr": "0x49836dbdbEA45b90ED274F0EB688E3Cc7908D31D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "27988",
                    "listingTime": 1651669182,
                    "expirationTime": 1654261171,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27988",
                    "makerAddr": "0xc163C864dAe306bF3033b0439c7644C668AF2a4E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "1165",
                    "listingTime": 1651713157,
                    "expirationTime": 1653440000,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1165",
                    "makerAddr": "0x8214Fc296391513943f7CA58733bb2074Bac807C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "14694",
                    "listingTime": 1651895606,
                    "expirationTime": 1653105200,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14694",
                    "makerAddr": "0xF3fCd156D11D2dE9eF85834E1D26Bb9a7c4AF3F9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "8576",
                    "listingTime": 1651394183,
                    "expirationTime": 1653986165,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8576",
                    "makerAddr": "0x8f197EB762aAa442CA6e5207e43E719D7EdBb872",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "3903",
                    "listingTime": 1651684165,
                    "expirationTime": 1654276155,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3903",
                    "makerAddr": "0xe450D86A20CEAcF552F0E8e8ACbc573C7A91D80F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "7014",
                    "listingTime": 1651678117,
                    "expirationTime": 1654254689,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7014",
                    "makerAddr": "0x908c6143512b4Ec00170c56e8954746EF7b5A577",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "24054",
                    "listingTime": 1651499545,
                    "expirationTime": 1654091474,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24054",
                    "makerAddr": "0xC3d34cf9d526db02b8a10Fa72897a57FeaBdf82A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "1890",
                    "listingTime": 1651577881,
                    "expirationTime": 1654169857,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1890",
                    "makerAddr": "0xD60BB8cF18aD76D60e7b3A40e72116f1F053b2e9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "19126",
                    "listingTime": 1651500802,
                    "expirationTime": 1654026598,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19126",
                    "makerAddr": "0x385809f08e80693E1EDa54c9524a2995483Ab16E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "218",
                    "listingTime": 1651670259,
                    "expirationTime": 1652275048,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/218",
                    "makerAddr": "0x5C7Af7bFD94cb9dAEbdE66Ed922a34d68949Ee20",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "9967",
                    "listingTime": 1651442220,
                    "expirationTime": 1653871848,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9967",
                    "makerAddr": "0x440c7504C2FCaC49bA1cb429C6883F1E15819b30",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "8771",
                    "listingTime": 1651674741,
                    "expirationTime": 1652279533,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8771",
                    "makerAddr": "0x0972531164Ec8bb37F7196ff007d4D5a3970B71f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "28070",
                    "listingTime": 1651471656,
                    "expirationTime": 1653366831,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28070",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "26067",
                    "listingTime": 1651699647,
                    "expirationTime": 1654291618,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26067",
                    "makerAddr": "0x04fa5c3869dEd7D00f36f13597A2f46aEe4C9220",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "14966",
                    "listingTime": 1651468509,
                    "expirationTime": 1653405602,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14966",
                    "makerAddr": "0x2319b02CA79f3253339b06baCB9a1de9C00A61e9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "5287",
                    "listingTime": 1651678154,
                    "expirationTime": 1654254653,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5287",
                    "makerAddr": "0xCaE7bF3715CeeC1fC2863D9CE81E042136b34E80",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "869",
                    "listingTime": 1651703768,
                    "expirationTime": 1653989476,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/869",
                    "makerAddr": "0xaFe7DF0F7A454dBb89BE9ee0c3AA731744890cCe",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "8003",
                    "listingTime": 1651733740,
                    "expirationTime": 1654325732,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8003",
                    "makerAddr": "0xb97A377229bFf1BED964a82b09167D4CC98e2e27",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "14099",
                    "listingTime": 1651639827,
                    "expirationTime": 1654055501,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14099",
                    "makerAddr": "0xCbdE38eC5A7DEA3526B6209010c64B44Ee91b6c1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "18962",
                    "listingTime": 1651491658,
                    "expirationTime": 1653406554,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18962",
                    "makerAddr": "0xfa861eE098C87c78AB96d780C5211d9557869920",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "408",
                    "listingTime": 1651491597,
                    "expirationTime": 1653405721,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/408",
                    "makerAddr": "0x98d31E06789783d5C1DD2E18013f04C9dAb78656",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "9436",
                    "listingTime": 1651471597,
                    "expirationTime": 1653366760,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9436",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "7085",
                    "listingTime": 1651464382,
                    "expirationTime": 1653980042,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7085",
                    "makerAddr": "0x2D66B7eA0c89158d7274aeF1019423d968F7864F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "626",
                    "listingTime": 1651595468,
                    "expirationTime": 1653631418,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/626",
                    "makerAddr": "0xe90a804dB10c4Ffd52732A0da6a0dE9e4fab4cD4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "9088",
                    "listingTime": 1651687999,
                    "expirationTime": 1652292703,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9088",
                    "makerAddr": "0xf9bb1fe3dBcBA49F7Ab71485FA944eAE6605eD1f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "10132",
                    "listingTime": 1651735594,
                    "expirationTime": 1652340323,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10132",
                    "makerAddr": "0x79880Cf894005b45b21aE5AE9D98a797F4597D8B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "13146",
                    "listingTime": 1651682568,
                    "expirationTime": 1667234556,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13146",
                    "makerAddr": "0x46bbc6445893947F2BeF5D9170054c0568865c77",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "4088",
                    "listingTime": 1651506231,
                    "expirationTime": 1653407259,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4088",
                    "makerAddr": "0x3e9b7732616A6153D2Ba41f2198702299DB7cCa6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "2203",
                    "listingTime": 1651463471,
                    "expirationTime": 1654055433,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2203",
                    "makerAddr": "0xCbdE38eC5A7DEA3526B6209010c64B44Ee91b6c1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "20920",
                    "listingTime": 1651733720,
                    "expirationTime": 1653874229,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20920",
                    "makerAddr": "0xb97A377229bFf1BED964a82b09167D4CC98e2e27",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "28516",
                    "listingTime": 1651513749,
                    "expirationTime": 1653487398,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28516",
                    "makerAddr": "0x8b3477Ca7445ec524dd534fA4A596C993E93675a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "9584",
                    "listingTime": 1651491459,
                    "expirationTime": 1653995241,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9584",
                    "makerAddr": "0x3dE262F19795845534A6e8D604d0Da383C2FCC9D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "7104",
                    "listingTime": 1651672188,
                    "expirationTime": 1654263383,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7104",
                    "makerAddr": "0xec374C2BeAa0F8856B8Cd96D3eDbCD6d0c76E991",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "40",
                    "listingTime": 1651583160,
                    "expirationTime": 1653446978,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/40",
                    "makerAddr": "0xc13a9B21B421B3111a3c79F4700734f35a71FaCD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "4745",
                    "listingTime": 1651491808,
                    "expirationTime": 1653405548,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4745",
                    "makerAddr": "0xdF41003BcdC9aa917Fdae801BD02b3167368D5CD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "5188",
                    "listingTime": 1651678186,
                    "expirationTime": 1654254589,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5188",
                    "makerAddr": "0xb1d802566C228D1cB0484f29Fc7CaA009e75A1ef",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "1407",
                    "listingTime": 1651685495,
                    "expirationTime": 1652290276,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1407",
                    "makerAddr": "0x261EBf1D3615c65cB80392970c837376913f1178",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "3857",
                    "listingTime": 1651675649,
                    "expirationTime": 1654267631,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3857",
                    "makerAddr": "0x08BD65c8d7308fef47eb6F0c78351fb2b6B49033",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "7420",
                    "listingTime": 1651696876,
                    "expirationTime": 1654288800,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7420",
                    "makerAddr": "0xD50f0F8aa18EA615154Ac9dB4170F069C75FCd6C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "24668",
                    "listingTime": 1651496622,
                    "expirationTime": 1653364881,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24668",
                    "makerAddr": "0x412F2661344078140BCE0fA74ffFC3bf5b4Fa7Dd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "23418",
                    "listingTime": 1651468064,
                    "expirationTime": 1654060059,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23418",
                    "makerAddr": "0xf98fF587fEb37db9e6934DDaf1aB377240430753",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "9961",
                    "listingTime": 1651449134,
                    "expirationTime": 1667001128,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9961",
                    "makerAddr": "0x70BAb06BA6b16387Ceb09B53F3c74D8809E3051a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "6423",
                    "listingTime": 1651491791,
                    "expirationTime": 1653627080,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6423",
                    "makerAddr": "0xdF41003BcdC9aa917Fdae801BD02b3167368D5CD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "13608",
                    "listingTime": 1651749950,
                    "expirationTime": 1667305508,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13608",
                    "makerAddr": "0x4Ff05543D3A53Ce52Da701fbA2Af11255f0a066C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38000000000000000000",
                    "tokenId": "6373",
                    "listingTime": 1651482338,
                    "expirationTime": 1653391192,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6373",
                    "makerAddr": "0xE9FbEbb23e5CCC02EEe6E751adA3f3BbD20C630D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "589",
                    "price": "38000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651390078,
                    "expirationTime": 1654068498,
                    "makerAddr": "0xcdb31d80a0667373e1fef8fe18b0578e25e0c7a1",
                    "assetImageUrl": "https://img.seadn.io/files/2009448c3e638b767d725407a783bd1c.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1105",
                    "price": "38000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652033847,
                    "expirationTime": 1655037052,
                    "makerAddr": "0x49b13cd6e0ededd6eb93b627cc8364d65de37176",
                    "assetImageUrl": "https://img.seadn.io/files/ba45cb0d766dc7a5cdc428df88b3ab75.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1165",
                    "price": "38000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651721373,
                    "expirationTime": 1654063507,
                    "makerAddr": "0x8214fc296391513943f7ca58733bb2074bac807c",
                    "assetImageUrl": "https://img.seadn.io/files/6b3c86b1b58b8efb5de13134e15793d0.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1648",
                    "price": "38000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651937709,
                    "expirationTime": 1653418110,
                    "makerAddr": "0xf2c06d4148ff5420d6519b578be20de5d473c7b6",
                    "assetImageUrl": "https://img.seadn.io/files/e0ca037d44f10f040440646d9e22b435.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38020000000000000000",
                    "tokenId": "28780",
                    "listingTime": 1651741780,
                    "expirationTime": 1652346484,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28780",
                    "makerAddr": "0xf868a2DE45bc38844B16C9e63FdA5E1DE1d54A22",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38100000000000000000",
                    "tokenId": "2402",
                    "listingTime": 1651578906,
                    "expirationTime": 1654170888,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2402",
                    "makerAddr": "0xD9502fb95c72b118a4Ec6d060297cB3aeb7c50BA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38200000000000000000",
                    "tokenId": "8995",
                    "listingTime": 1651467906,
                    "expirationTime": 1654059874,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8995",
                    "makerAddr": "0xf98fF587fEb37db9e6934DDaf1aB377240430753",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38200000000000000000",
                    "tokenId": "497",
                    "listingTime": 1651468088,
                    "expirationTime": 1654060084,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/497",
                    "makerAddr": "0xf98fF587fEb37db9e6934DDaf1aB377240430753",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38240000000000000000",
                    "tokenId": "7296",
                    "listingTime": 1651509271,
                    "expirationTime": 1653973345,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7296",
                    "makerAddr": "0x4c8F62f1498FA55D4158CdBFEA7783f84556a68e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38250000000000000000",
                    "tokenId": "25852",
                    "listingTime": 1651596685,
                    "expirationTime": 1654150873,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25852",
                    "makerAddr": "0x309caB02E85817660F3f66855B5cA51312264C7D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38250000000000000000",
                    "tokenId": "13493",
                    "listingTime": 1651444452,
                    "expirationTime": 1654036421,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13493",
                    "makerAddr": "0x278BfD54e7E71346F92eF36C6e34145d14F017Cd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38250000000000000000",
                    "tokenId": "3104",
                    "listingTime": 1651462930,
                    "expirationTime": 1653493849,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3104",
                    "makerAddr": "0xDfaf4022d9B6a08896569933D8F38F67E720CeB3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38300000000000000000",
                    "tokenId": "14572",
                    "listingTime": 1651593912,
                    "expirationTime": 1652198711,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14572",
                    "makerAddr": "0x8d80802E2A5BDFBB4291e847108802677305D1F6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38300000000000000000",
                    "tokenId": "668",
                    "listingTime": 1651671814,
                    "expirationTime": 1654263773,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/668",
                    "makerAddr": "0xCA6CDB387c868C38cFAF4499C40B3BF28af0ADFB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38330000000000000000",
                    "tokenId": "19592",
                    "listingTime": 1651680284,
                    "expirationTime": 1654272206,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19592",
                    "makerAddr": "0xb10175B169292D36Fe11822653873171ad6Bb3DC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38400000000000000000",
                    "tokenId": "1532",
                    "listingTime": 1651724525,
                    "expirationTime": 1654316510,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1532",
                    "makerAddr": "0x8a677474dEC43FCAeB80a2Dd2B1bc5D945aB61d1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38400000000000000000",
                    "tokenId": "28802",
                    "listingTime": 1651723384,
                    "expirationTime": 1652872201,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28802",
                    "makerAddr": "0xf980066d815593FCD5aD257abe849Ae89436dced",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38400000000000000000",
                    "tokenId": "14730",
                    "listingTime": 1651474057,
                    "expirationTime": 1666809771,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14730",
                    "makerAddr": "0x497402DC41E79523500BE7C35193a5879298091e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38500000000000000000",
                    "tokenId": "1538",
                    "listingTime": 1651665709,
                    "expirationTime": 1654257652,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1538",
                    "makerAddr": "0x0D5B12B86e85358fDACf216598c75863F954C66D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38500000000000000000",
                    "tokenId": "4622",
                    "listingTime": 1651487817,
                    "expirationTime": 1653834728,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4622",
                    "makerAddr": "0x998f20a7f456690F2983E5D4639288C0A1f33810",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38500000000000000000",
                    "tokenId": "1532",
                    "listingTime": 1651720778,
                    "expirationTime": 1654312773,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1532",
                    "makerAddr": "0x8a677474dEC43FCAeB80a2Dd2B1bc5D945aB61d1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38500000000000000000",
                    "tokenId": "23174",
                    "listingTime": 1651458438,
                    "expirationTime": 1658677747,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23174",
                    "makerAddr": "0x7e2225B3e54A7ad0f2D009237a4f136C02F90a52",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38500000000000000000",
                    "tokenId": "9404",
                    "listingTime": 1651504856,
                    "expirationTime": 1653425364,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9404",
                    "makerAddr": "0xD64d9F5B9050a634ef9D0089d22766a36Ca8a2AA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38500000000000000000",
                    "tokenId": "9688",
                    "listingTime": 1651666086,
                    "expirationTime": 1653816417,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9688",
                    "makerAddr": "0x52d53E394a5FA689359dE855aA0ABcEe4D8921bb",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38500000000000000000",
                    "tokenId": "1043",
                    "listingTime": 1651954720,
                    "expirationTime": 1652213883,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1043",
                    "makerAddr": "0xD076d2664dfBef324181cbBA00bCbD2369f777fA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38500000000000000000",
                    "tokenId": "5156",
                    "listingTime": 1651509224,
                    "expirationTime": 1654101207,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5156",
                    "makerAddr": "0x7fc9743d7aEF4ad2705b405f5ae46F595cD44860",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1853",
                    "price": "38500000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651452402,
                    "expirationTime": 1654130893,
                    "makerAddr": "0xd5b4a12083b7dac81b06510877fa45232c771bc8",
                    "assetImageUrl": "https://img.seadn.io/files/75a1e61b836a1996fec6c6103080943f.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38600000000000000000",
                    "tokenId": "17682",
                    "listingTime": 1651716067,
                    "expirationTime": 1654308059,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17682",
                    "makerAddr": "0x6a1C25a33C4e1f844AF37324271de384508189f7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38690000000000000000",
                    "tokenId": "5501",
                    "listingTime": 1652161874,
                    "expirationTime": 1652248270,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5501",
                    "makerAddr": "0xaee70243d70E403Cc04A02d230D45921F953DbD1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38690000000000000000",
                    "tokenId": "5175",
                    "listingTime": 1651505203,
                    "expirationTime": 1654000295,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5175",
                    "makerAddr": "0x0ade490244a34C69c1e31e7c2c95E20B42Daf988",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38700000000000000000",
                    "tokenId": "24602",
                    "listingTime": 1651690806,
                    "expirationTime": 1654282797,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24602",
                    "makerAddr": "0xb21f6845648F39395f94f5C840658F793C452612",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38750000000000000000",
                    "tokenId": "7499",
                    "listingTime": 1651716906,
                    "expirationTime": 1654049640,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7499",
                    "makerAddr": "0x45632D932579861ca72dc93b50677795253F9bAB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38800000000000000000",
                    "tokenId": "5051",
                    "listingTime": 1651680680,
                    "expirationTime": 1652285475,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5051",
                    "makerAddr": "0xD936830b51Ae9CA406e3B025b313bB709d6ab999",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1043",
                    "price": "38800000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651954388,
                    "expirationTime": 1652249670,
                    "makerAddr": "0xd076d2664dfbef324181cbba00bcbd2369f777fa",
                    "assetImageUrl": "https://img.seadn.io/files/8ab96603a5df768ea61f8335caf23174.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38900000000000000000",
                    "tokenId": "15550",
                    "listingTime": 1651662611,
                    "expirationTime": 1652267246,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15550",
                    "makerAddr": "0x160C1e92aBeAd0B18A1326Cb43DAE456d8086d6E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38900000000000000000",
                    "tokenId": "12828",
                    "listingTime": 1651677441,
                    "expirationTime": 1654269431,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12828",
                    "makerAddr": "0x495F45452c2B47bA1089E72BdBFe71c081E7B306",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38900000000000000000",
                    "tokenId": "4877",
                    "listingTime": 1651502856,
                    "expirationTime": 1654094665,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4877",
                    "makerAddr": "0x7485a56F2b65569a0808F3eB5DF55A99b9E62F32",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38900000000000000000",
                    "tokenId": "17032",
                    "listingTime": 1651674205,
                    "expirationTime": 1652279000,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17032",
                    "makerAddr": "0x9fF57F608deB3344F32e0f49BEEb69b1A090a56E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "38920000000000000000",
                    "tokenId": "24448",
                    "listingTime": 1651506556,
                    "expirationTime": 1653405104,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24448",
                    "makerAddr": "0x3922B064DcC34289B2c04f82B92977c413a0dAd2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "582",
                    "price": "38990000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652026835,
                    "expirationTime": 1652631707,
                    "makerAddr": "0xf98985f9e773ba855c15824feccd63297920bd8c",
                    "assetImageUrl": "https://img.seadn.io/files/6e7d37d92eb16c32d90972628d1bf801.png?auto=format"
                }
            ],
            "total": 3.9706799999999976e+21,
            "looksrare": 97,
            "opensea": 7
        },
        {
            "price": 39,
            "amount": 90,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "10164",
                    "listingTime": 1651689429,
                    "expirationTime": 1654281405,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10164",
                    "makerAddr": "0x8c197dB132A18Cf2c0b46A1051D7c3E43F65a17b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "1122",
                    "listingTime": 1651445618,
                    "expirationTime": 1653443992,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1122",
                    "makerAddr": "0xBe547aA1bf50FEcCa7d853adEa3aB6D5F85953F7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "1626",
                    "listingTime": 1651445731,
                    "expirationTime": 1653443769,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1626",
                    "makerAddr": "0xBe547aA1bf50FEcCa7d853adEa3aB6D5F85953F7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "9377",
                    "listingTime": 1651467219,
                    "expirationTime": 1653459459,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9377",
                    "makerAddr": "0x0B5489942688F16e6cd80C4Bd7100CBf9ecdC161",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "3427",
                    "listingTime": 1651859620,
                    "expirationTime": 1652464416,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3427",
                    "makerAddr": "0x7972bC68FaB9b552963977077B4CdE82Da1722e0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "578",
                    "listingTime": 1651621770,
                    "expirationTime": 1654213661,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/578",
                    "makerAddr": "0xDe112B77A1a72c726D8C914c23dCEaA53273c351",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "6046",
                    "listingTime": 1651495385,
                    "expirationTime": 1653401649,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6046",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "24034",
                    "listingTime": 1651663283,
                    "expirationTime": 1652267578,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24034",
                    "makerAddr": "0x0709c80c7A3979088695009Fee3CEe2e3C9d4e5e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "20887",
                    "listingTime": 1651825253,
                    "expirationTime": 1652430052,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20887",
                    "makerAddr": "0xCB2Df089d65dD72589C209Daf7893F03C1ab677F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "20926",
                    "listingTime": 1651570910,
                    "expirationTime": 1654162856,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20926",
                    "makerAddr": "0xB5E14DCf2520d154606254094f32C8D32D509115",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "11114",
                    "listingTime": 1651482227,
                    "expirationTime": 1653409494,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11114",
                    "makerAddr": "0xF83FC598fD45171d86f81ba032C46DC07aCc415f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "2593",
                    "listingTime": 1651445688,
                    "expirationTime": 1653444064,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2593",
                    "makerAddr": "0xBe547aA1bf50FEcCa7d853adEa3aB6D5F85953F7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "4929",
                    "listingTime": 1651463149,
                    "expirationTime": 1654055138,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4929",
                    "makerAddr": "0x766D87345908d18C0e79187be9a291E08531B2c6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "3827",
                    "listingTime": 1651506176,
                    "expirationTime": 1653408280,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3827",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "29160",
                    "listingTime": 1651495476,
                    "expirationTime": 1653712544,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29160",
                    "makerAddr": "0x6c10E56522cafCAf220A9e5bb666E1D84102AcCb",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "25072",
                    "listingTime": 1651705718,
                    "expirationTime": 1652310500,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25072",
                    "makerAddr": "0x98bdFE7174Ed431dA22604AAF87d66Fe2bd8621E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "11270",
                    "listingTime": 1651712613,
                    "expirationTime": 1654304342,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11270",
                    "makerAddr": "0x843f2C19bc6df9E32B482E2F9ad6C078001088b1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "7768",
                    "listingTime": 1651500373,
                    "expirationTime": 1653444836,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7768",
                    "makerAddr": "0x2264303067Db0d5bfa3746F409B5A5129eDdB3d9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "21189",
                    "listingTime": 1651964868,
                    "expirationTime": 1652986661,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21189",
                    "makerAddr": "0x8Dc1eFcf99f0ac1CDcde18A5F596CFb99d50e89B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "8419",
                    "listingTime": 1652068551,
                    "expirationTime": 1654660495,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8419",
                    "makerAddr": "0xee3b3CC4e1a6782824202e48c2D8b93922882A3A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "5470",
                    "listingTime": 1651472428,
                    "expirationTime": 1654064418,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5470",
                    "makerAddr": "0xdA3E7E2A36050600e269d6b41c116ECe32EcdA0d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "1752",
                    "listingTime": 1651646656,
                    "expirationTime": 1652251297,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1752",
                    "makerAddr": "0x7693D10e127d52c671c5A868453E3507fCD76b4D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "20178",
                    "listingTime": 1651685644,
                    "expirationTime": 1659461641,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20178",
                    "makerAddr": "0x307E1cd1e0f955FeEB032CD7AB1df3367dc34b2B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "17940",
                    "listingTime": 1651502631,
                    "expirationTime": 1654001045,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17940",
                    "makerAddr": "0x4B3B34A1512376c58633537C96812D1FD256f3f3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "8852",
                    "listingTime": 1651506343,
                    "expirationTime": 1653852863,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8852",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "1648",
                    "listingTime": 1651496286,
                    "expirationTime": 1654088260,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1648",
                    "makerAddr": "0xf2c06d4148Ff5420d6519b578bE20DE5D473C7B6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "6402",
                    "listingTime": 1651500333,
                    "expirationTime": 1653444204,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6402",
                    "makerAddr": "0xD83556df9Ff191750eB8816B7Eb6126A9A245608",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "2266",
                    "listingTime": 1651713183,
                    "expirationTime": 1653440044,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2266",
                    "makerAddr": "0x8214Fc296391513943f7CA58733bb2074Bac807C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "5704",
                    "listingTime": 1651499335,
                    "expirationTime": 1653990533,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5704",
                    "makerAddr": "0x4A1536C56a7076f91663A1df9f99d9120152740a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "8882",
                    "listingTime": 1651698375,
                    "expirationTime": 1654290317,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8882",
                    "makerAddr": "0x4f647756dfae6ABF390440B0b801Ab584e6cD878",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "19564",
                    "listingTime": 1651663080,
                    "expirationTime": 1652267873,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19564",
                    "makerAddr": "0x45A9098492530a2499Ba848608105A74D72A1224",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "13915",
                    "listingTime": 1651498715,
                    "expirationTime": 1654090708,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13915",
                    "makerAddr": "0x148089038088cC49CDcF26e0f96776c25e5CfACd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "9671",
                    "listingTime": 1651682708,
                    "expirationTime": 1652287454,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9671",
                    "makerAddr": "0x2856d4077826E19C8782856A749B40B59b14067d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "6905",
                    "listingTime": 1651496306,
                    "expirationTime": 1654088301,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6905",
                    "makerAddr": "0x09382e9bBdF4A47d48d83D56857704cF6faB1F82",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "2759",
                    "listingTime": 1651686492,
                    "expirationTime": 1659462479,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2759",
                    "makerAddr": "0x683EbbA7A833A809071afb4819C0a2Dfd6e07333",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "1568",
                    "listingTime": 1651439899,
                    "expirationTime": 1653992137,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1568",
                    "makerAddr": "0x835ba4bA03713208Fa8404acd626466C9273F93c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "23790",
                    "listingTime": 1651679009,
                    "expirationTime": 1654271001,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23790",
                    "makerAddr": "0x34687Dc0c2080b37f0f33C0EE3a88290Cee3F791",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "4081",
                    "listingTime": 1651621706,
                    "expirationTime": 1654213661,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4081",
                    "makerAddr": "0xDe112B77A1a72c726D8C914c23dCEaA53273c351",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "5383",
                    "listingTime": 1651495466,
                    "expirationTime": 1653823241,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5383",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "19126",
                    "listingTime": 1651464860,
                    "expirationTime": 1654026598,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19126",
                    "makerAddr": "0x385809f08e80693E1EDa54c9524a2995483Ab16E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "6167",
                    "listingTime": 1651470985,
                    "expirationTime": 1654062967,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6167",
                    "makerAddr": "0xB4f011d8cE90E13CdEc209DEb06025fBD9C0cbA5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "9710",
                    "listingTime": 1651391718,
                    "expirationTime": 1653370191,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9710",
                    "makerAddr": "0xf528a90CC2b35065e78518F77962eC884deb7230",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "16440",
                    "listingTime": 1651465942,
                    "expirationTime": 1654057841,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16440",
                    "makerAddr": "0x72f835E889705966dB801419B1CC580F17Db1d02",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "29146",
                    "listingTime": 1651713920,
                    "expirationTime": 1653982098,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29146",
                    "makerAddr": "0xE3813D26d44BcB6C40f29e5487dfF5434c629051",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "28104",
                    "listingTime": 1651482213,
                    "expirationTime": 1653409436,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28104",
                    "makerAddr": "0xF83FC598fD45171d86f81ba032C46DC07aCc415f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "19224",
                    "listingTime": 1651675715,
                    "expirationTime": 1654267698,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19224",
                    "makerAddr": "0x22294b2fEA0E5866f59383B31F31B16b7fC86DF6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39000000000000000000",
                    "tokenId": "2818",
                    "listingTime": 1651679959,
                    "expirationTime": 1654271927,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2818",
                    "makerAddr": "0x822a16309A9eE40f15e196898f11A010ecb1C963",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "525",
                    "price": "39000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652046297,
                    "expirationTime": 1659822361,
                    "makerAddr": "0x421789d625ce587ab9f545cc778502883f90442e",
                    "assetImageUrl": "https://img.seadn.io/files/ae1507112f46143d27b55ecff8482036.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1036",
                    "price": "39000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651446742,
                    "expirationTime": 1654125211,
                    "makerAddr": "0xc997cd38e441841e084c8895b81ee12063e9bbb2",
                    "assetImageUrl": "https://img.seadn.io/files/94d3f4caf51d66ed4b02a1f4715fb223.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1153",
                    "price": "39000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652194910,
                    "expirationTime": 1654786995,
                    "makerAddr": "0xbea2bde77dded2336fa2a86bcf0e2a5b167ec67e",
                    "assetImageUrl": "https://img.seadn.io/files/0fe80a7ab63eca6fc1e2c7d862c24734.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1409",
                    "price": "39000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652086316,
                    "expirationTime": 1654764793,
                    "makerAddr": "0xb6164af04291d3aa4051ee78943618318e3f5cef",
                    "assetImageUrl": "https://img.seadn.io/files/306542252cb95b9b38c96e8a786dad4e.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1752",
                    "price": "39000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651646726,
                    "expirationTime": 1652251588,
                    "makerAddr": "0x7693d10e127d52c671c5a868453e3507fcd76b4d",
                    "assetImageUrl": "https://img.seadn.io/files/6543d1831f2f2764f58d3c335c41d7d7.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1922",
                    "price": "39000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651745511,
                    "expirationTime": 1654423902,
                    "makerAddr": "0xf7685f885c4378848752b6fb406b06978e48cb50",
                    "assetImageUrl": "https://img.seadn.io/files/9553a645556e0602f12207adec04db14.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2689",
                    "price": "39000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652197926,
                    "expirationTime": 1652457204,
                    "makerAddr": "0x45b0f81150ff50975165d47d2020598987f71f31",
                    "assetImageUrl": "https://img.seadn.io/files/5f6f8dc5f56b26042e968cf5436ec35a.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3202",
                    "price": "39000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652050870,
                    "expirationTime": 1652310124,
                    "makerAddr": "0xdc96ab6b2756332af772dec6bc95475f0957f690",
                    "assetImageUrl": "https://img.seadn.io/files/4e70f462f9a2f5dc00c8c9212ace7223.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39044400000000000000",
                    "tokenId": "3449",
                    "listingTime": 1651702526,
                    "expirationTime": 1654294501,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3449",
                    "makerAddr": "0x6822be48863a5185AB1e1cd522950F75E1c28923",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39200000000000000000",
                    "tokenId": "28802",
                    "listingTime": 1651674311,
                    "expirationTime": 1652872201,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28802",
                    "makerAddr": "0xf980066d815593FCD5aD257abe849Ae89436dced",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39250000000000000000",
                    "tokenId": "9681",
                    "listingTime": 1651703316,
                    "expirationTime": 1654295310,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9681",
                    "makerAddr": "0x0d973069308A5b8D3473fEFA3cf585bB9Af4d7BC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39300000000000000000",
                    "tokenId": "6882",
                    "listingTime": 1651504824,
                    "expirationTime": 1653060022,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6882",
                    "makerAddr": "0xC10F5F82B7B0392DB11Bbf06CDE93Fc0aeA120Cb",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39300000000000000000",
                    "tokenId": "9332",
                    "listingTime": 1651502928,
                    "expirationTime": 1654094780,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9332",
                    "makerAddr": "0x9b92142Bc7080e88ADeabaF093608f1701210486",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39400000000000000000",
                    "tokenId": "2688",
                    "listingTime": 1651705114,
                    "expirationTime": 1654208621,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2688",
                    "makerAddr": "0x39A7Fb978b1F43219F096d7eb0836fd08CD74e15",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39404400000000000000",
                    "tokenId": "1843",
                    "listingTime": 1651702879,
                    "expirationTime": 1654294846,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1843",
                    "makerAddr": "0x6822be48863a5185AB1e1cd522950F75E1c28923",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39426900000000000000",
                    "tokenId": "7034",
                    "listingTime": 1651806485,
                    "expirationTime": 1658541706,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7034",
                    "makerAddr": "0x6Cd68E8f04490Cd1A5A21cc97CC8BC15b47Dc9eb",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39490000000000000000",
                    "tokenId": "6055",
                    "listingTime": 1651460751,
                    "expirationTime": 1666871396,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6055",
                    "makerAddr": "0x02b2bab8458D1a47F9789580495284670b5f4299",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39498900000000000000",
                    "tokenId": "16732",
                    "listingTime": 1651922811,
                    "expirationTime": 1653979047,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16732",
                    "makerAddr": "0x298d5537C181766D22a27D40b90A9c8ccA5b5aD5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39500000000000000000",
                    "tokenId": "4498",
                    "listingTime": 1651460927,
                    "expirationTime": 1654052907,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4498",
                    "makerAddr": "0xf29D4D7B3270beB3C54EE703fE2d9d17BF806C81",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39500000000000000000",
                    "tokenId": "7582",
                    "listingTime": 1651460292,
                    "expirationTime": 1654052278,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7582",
                    "makerAddr": "0xA89C95f3b69c6435fa022fcE66EFa46289E24476",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39600000000000000000",
                    "tokenId": "128",
                    "listingTime": 1651685901,
                    "expirationTime": 1652895408,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/128",
                    "makerAddr": "0x82e1EC93Be8B2a6a93Cd14a7Fd5326491FC06C94",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39690000000000000000",
                    "tokenId": "4328",
                    "listingTime": 1651553854,
                    "expirationTime": 1654145678,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4328",
                    "makerAddr": "0x4cD3A35EE7EeC89ae168180EA4D0fb6cf8B2c72E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39690000000000000000",
                    "tokenId": "1965",
                    "listingTime": 1651677977,
                    "expirationTime": 1654269857,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1965",
                    "makerAddr": "0x2BC52900a118390ea8A97B29eB5e7E75F1Cd912c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39690000000000000000",
                    "tokenId": "4162",
                    "listingTime": 1651553864,
                    "expirationTime": 1654145678,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4162",
                    "makerAddr": "0x4cD3A35EE7EeC89ae168180EA4D0fb6cf8B2c72E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39690000000000000000",
                    "tokenId": "8767",
                    "listingTime": 1651553877,
                    "expirationTime": 1654145678,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8767",
                    "makerAddr": "0x4cD3A35EE7EeC89ae168180EA4D0fb6cf8B2c72E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39750000000000000000",
                    "tokenId": "2456",
                    "listingTime": 1651688406,
                    "expirationTime": 1652293203,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2456",
                    "makerAddr": "0x6D70fE2C48BD2fa5c30eC4C7708DD1472EB29847",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39750000000000000000",
                    "tokenId": "24962",
                    "listingTime": 1651465743,
                    "expirationTime": 1659241622,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24962",
                    "makerAddr": "0xb638aE936edff5A81F8e9b252894aeCcF7C8ef77",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39750000000000000000",
                    "tokenId": "17844",
                    "listingTime": 1651697951,
                    "expirationTime": 1652302744,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17844",
                    "makerAddr": "0xfc7355bf612fb59043B0AC3d728942F41A3e3905",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39750000000000000000",
                    "tokenId": "19672",
                    "listingTime": 1651697394,
                    "expirationTime": 1652302012,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19672",
                    "makerAddr": "0x3D4f46202fF13A906bE73CD8cdF762fb0D752C2F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39800000000000000000",
                    "tokenId": "4036",
                    "listingTime": 1652198543,
                    "expirationTime": 1652803326,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4036",
                    "makerAddr": "0xCeE749F1CFc66cd3FB57CEfDe8A9c5999FbE7b8F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39800000000000000000",
                    "tokenId": "8443",
                    "listingTime": 1651676629,
                    "expirationTime": 1653445244,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8443",
                    "makerAddr": "0x29E21cC44b5DBC594542940D5658dEA2Cb500907",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39800000000000000000",
                    "tokenId": "23882",
                    "listingTime": 1651676665,
                    "expirationTime": 1654268614,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23882",
                    "makerAddr": "0xD9Bc1fC51b8D8369980505aa96e975DA03346B4A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39800000000000000000",
                    "tokenId": "12328",
                    "listingTime": 1651685479,
                    "expirationTime": 1652290272,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12328",
                    "makerAddr": "0x950CC17C7173933CB2EA0D353e3D76B3483313f8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39840000000000000000",
                    "tokenId": "2688",
                    "listingTime": 1651689450,
                    "expirationTime": 1654208621,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2688",
                    "makerAddr": "0x39A7Fb978b1F43219F096d7eb0836fd08CD74e15",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39880000000000000000",
                    "tokenId": "25962",
                    "listingTime": 1651676194,
                    "expirationTime": 1654268173,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25962",
                    "makerAddr": "0xAEC600E4B4B662D74144845A1C6eE045728dbb52",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39900000000000000000",
                    "tokenId": "6788",
                    "listingTime": 1651674948,
                    "expirationTime": 1652279720,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6788",
                    "makerAddr": "0x4199F2400fb5Ad164A065815B1ab386fD09016a0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39900000000000000000",
                    "tokenId": "1642",
                    "listingTime": 1651494540,
                    "expirationTime": 1666388866,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1642",
                    "makerAddr": "0xfb363b7D7a1c065356442829F03C84F9660CE1e2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "907",
                    "price": "39900000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652123016,
                    "expirationTime": 1654365398,
                    "makerAddr": "0x3de53471c5aadaac0bebebb37268b7a7257d85f5",
                    "assetImageUrl": "https://img.seadn.io/files/5b6e5b124ee7db926c5e5bf060a293f7.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2352",
                    "price": "39900000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652123065,
                    "expirationTime": 1654365372,
                    "makerAddr": "0x3de53471c5aadaac0bebebb37268b7a7257d85f5",
                    "assetImageUrl": "https://img.seadn.io/files/00efaa19fadf2959f86e2c22cdb7808f.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3005",
                    "price": "39950000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651762422,
                    "expirationTime": 1652367307,
                    "makerAddr": "0xef98ab0316e3d36ef12af9b00a31b443da372d22",
                    "assetImageUrl": "https://img.seadn.io/files/caa29aa41f059a3aadb76d1e145ce0f2.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39990000000000000000",
                    "tokenId": "11064",
                    "listingTime": 1651392876,
                    "expirationTime": 1653405091,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11064",
                    "makerAddr": "0x5fB998B8024E38867558fD5E2b87B6D13Da730f8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39990000000000000000",
                    "tokenId": "2650",
                    "listingTime": 1651387999,
                    "expirationTime": 1653977591,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2650",
                    "makerAddr": "0x6978d5039DBd1aEb184954e659E9988dA208C787",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "39990000000000000000",
                    "tokenId": "17852",
                    "listingTime": 1652041510,
                    "expirationTime": 1654633500,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17852",
                    "makerAddr": "0x1ef1b95b3dD0d10B55C54aF4cB10B9f332AF9b1d",
                    "assetImageUrl": ""
                }
            ],
            "total": 3.533114600000001e+21,
            "looksrare": 79,
            "opensea": 11
        },
        {
            "price": 40,
            "amount": 68,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "1165",
                    "listingTime": 1651385087,
                    "expirationTime": 1653440000,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1165",
                    "makerAddr": "0x8214Fc296391513943f7CA58733bb2074Bac807C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "11114",
                    "listingTime": 1651445517,
                    "expirationTime": 1653409494,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11114",
                    "makerAddr": "0xF83FC598fD45171d86f81ba032C46DC07aCc415f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "2345",
                    "listingTime": 1651477665,
                    "expirationTime": 1653924638,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2345",
                    "makerAddr": "0xD05Defee18E718F21337F5355317f915a34A101B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "15876",
                    "listingTime": 1651447760,
                    "expirationTime": 1654039719,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15876",
                    "makerAddr": "0xdb3fF3567157210AD445d7bc50ffa41f63De7F28",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "4400",
                    "listingTime": 1651442895,
                    "expirationTime": 1658954135,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4400",
                    "makerAddr": "0x61a4FC9028048e562b2a49d8dccf2378B644dF03",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "3481",
                    "listingTime": 1651660855,
                    "expirationTime": 1654252816,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3481",
                    "makerAddr": "0x6A1BA3300800746eb55b84e7D1D913cEB76D489f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "4352",
                    "listingTime": 1651523900,
                    "expirationTime": 1654100161,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4352",
                    "makerAddr": "0xe73e90fc400c0256Bc271C83e576ACb97603452b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "20887",
                    "listingTime": 1651613808,
                    "expirationTime": 1652218607,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20887",
                    "makerAddr": "0xCB2Df089d65dD72589C209Daf7893F03C1ab677F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "10148",
                    "listingTime": 1651481893,
                    "expirationTime": 1654073887,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10148",
                    "makerAddr": "0xd4066e28265691d6C83086a2e72Bdc39fb02C6c7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "8049",
                    "listingTime": 1651429400,
                    "expirationTime": 1654021313,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8049",
                    "makerAddr": "0x1d5255B85F1c7a2783199f767D269dEC313c474f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "14846",
                    "listingTime": 1651400899,
                    "expirationTime": 1653821019,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14846",
                    "makerAddr": "0x6189e05a28bEF26Bcf76F22aB311282d5e843dF4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "5893",
                    "listingTime": 1651446669,
                    "expirationTime": 1653803334,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5893",
                    "makerAddr": "0x1FEACcB479834998BD7750754062347A6FaD8F9F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "1935",
                    "listingTime": 1651457243,
                    "expirationTime": 1666885368,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1935",
                    "makerAddr": "0x7B220358c3EC9Ec03b08120DcCEC56F16Fbf33a4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "13310",
                    "listingTime": 1651463663,
                    "expirationTime": 1659239635,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13310",
                    "makerAddr": "0x69d59A5fEcf167c27aF37b1316875366708C0E76",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "14099",
                    "listingTime": 1651463528,
                    "expirationTime": 1654055501,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14099",
                    "makerAddr": "0xCbdE38eC5A7DEA3526B6209010c64B44Ee91b6c1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "29048",
                    "listingTime": 1651498171,
                    "expirationTime": 1654090167,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29048",
                    "makerAddr": "0x9f8534b8d266C136d52428Ec0647fa0F02EBF377",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "29122",
                    "listingTime": 1651394139,
                    "expirationTime": 1653986126,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29122",
                    "makerAddr": "0x8f197EB762aAa442CA6e5207e43E719D7EdBb872",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "5579",
                    "listingTime": 1651444545,
                    "expirationTime": 1653397146,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5579",
                    "makerAddr": "0xD163bd16eaf4813D723F90f13fE4abeeECCdD3dd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "9516",
                    "listingTime": 1651428915,
                    "expirationTime": 1653399390,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9516",
                    "makerAddr": "0x1E7dff312eB06F1A314BF8e7FFb5CdDe32A1821B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "1036",
                    "listingTime": 1651444641,
                    "expirationTime": 1654036510,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1036",
                    "makerAddr": "0xC997Cd38e441841e084c8895b81EE12063e9bBb2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "6987",
                    "listingTime": 1652050197,
                    "expirationTime": 1652654990,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6987",
                    "makerAddr": "0xFEAfF63e94268c2dd6170794Bc7dDd5007910CbD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "28070",
                    "listingTime": 1651463587,
                    "expirationTime": 1653366831,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28070",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "15574",
                    "listingTime": 1651675470,
                    "expirationTime": 1652280250,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15574",
                    "makerAddr": "0x98baC2424874fB647CE5F063b2F3215cE381FFff",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "28104",
                    "listingTime": 1651445502,
                    "expirationTime": 1653409436,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28104",
                    "makerAddr": "0xF83FC598fD45171d86f81ba032C46DC07aCc415f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "5980",
                    "listingTime": 1652147705,
                    "expirationTime": 1652752504,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5980",
                    "makerAddr": "0x155FF976d658dfbf54c6D36998f8090AcE5B1443",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "18078",
                    "listingTime": 1651661836,
                    "expirationTime": 1654253825,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18078",
                    "makerAddr": "0x2Efb798F5F77B6aa5d9BDF620228817C842cF36D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "3083",
                    "listingTime": 1651436827,
                    "expirationTime": 1653414972,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3083",
                    "makerAddr": "0xC344FCe3710d5971b24b11947535FAcE3eCd5655",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "1044",
                    "listingTime": 1651679628,
                    "expirationTime": 1654271545,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1044",
                    "makerAddr": "0x68D7aE1FF3580e942ee4A69B00fC1f315581A5cD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "7235",
                    "listingTime": 1652031673,
                    "expirationTime": 1654623667,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7235",
                    "makerAddr": "0x64597fd32302D26EA7f35Cacf0EF2CBa56F5D7A8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "2914",
                    "listingTime": 1651463503,
                    "expirationTime": 1653366800,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2914",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "27989",
                    "listingTime": 1651785509,
                    "expirationTime": 1654377507,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27989",
                    "makerAddr": "0x13883Dc4326fE65a3Ae0383D509166dFAd620CC4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "27659",
                    "listingTime": 1651889478,
                    "expirationTime": 1652494273,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27659",
                    "makerAddr": "0x2f670A086b5b426BA56086149Ca1904027E403b9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "7351",
                    "listingTime": 1651448011,
                    "expirationTime": 1654011882,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7351",
                    "makerAddr": "0x56767eD9B7e23607Ba62a1CF41B8708b9Dbb5555",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "1322",
                    "listingTime": 1651423628,
                    "expirationTime": 1653523113,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1322",
                    "makerAddr": "0x4064023610A479F9A0415eE98f87895A0e2e0884",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "23316",
                    "listingTime": 1652008982,
                    "expirationTime": 1654600894,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23316",
                    "makerAddr": "0xaAB1e59A453f30eb52fdc2e5B3e13dcB3B1595c5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "11606",
                    "listingTime": 1651666497,
                    "expirationTime": 1654258492,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11606",
                    "makerAddr": "0x852459D22dcd0aB2b6a9C802128Ac0c3E048b9F2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "3639",
                    "listingTime": 1652147766,
                    "expirationTime": 1652752566,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3639",
                    "makerAddr": "0x155FF976d658dfbf54c6D36998f8090AcE5B1443",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "9509",
                    "listingTime": 1651965695,
                    "expirationTime": 1652570491,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9509",
                    "makerAddr": "0x6D1e441fd465Ca0038F4c20BCa32Cce600E254b8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "6227",
                    "listingTime": 1652034554,
                    "expirationTime": 1652552940,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6227",
                    "makerAddr": "0x8DEF019F89740c60e9855fc838549170cCe1e107",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "6889",
                    "listingTime": 1651431081,
                    "expirationTime": 1654023069,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6889",
                    "makerAddr": "0xF16122D79788B5C3f068Db11af8238155c0f1fF8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "10204",
                    "listingTime": 1651824345,
                    "expirationTime": 1653998400,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10204",
                    "makerAddr": "0x0b73F759868A249671ff2170F831f08E7Ea83721",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "28398",
                    "listingTime": 1651768196,
                    "expirationTime": 1652371457,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28398",
                    "makerAddr": "0xFEFc2850B25FE1AA3e9Aa154DF61C6B9bC7AA374",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "28802",
                    "listingTime": 1651662607,
                    "expirationTime": 1652872201,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28802",
                    "makerAddr": "0xf980066d815593FCD5aD257abe849Ae89436dced",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "18108",
                    "listingTime": 1651675037,
                    "expirationTime": 1654267015,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18108",
                    "makerAddr": "0xeBf7c7932D86907C3BC1Bd6d486308637a8264A3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "7557",
                    "listingTime": 1651398759,
                    "expirationTime": 1653449297,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7557",
                    "makerAddr": "0x534c98c85714Df298524CF94c3d2Cd230B1EEa5B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "16890",
                    "listingTime": 1651709974,
                    "expirationTime": 1654301971,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16890",
                    "makerAddr": "0x6485338dd7dd0C0bDc552Cc78d836dD737D7a14E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "15465",
                    "listingTime": 1651827343,
                    "expirationTime": 1652432129,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15465",
                    "makerAddr": "0xcB05165B1768856796831e749D7DeF83095B150D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "9436",
                    "listingTime": 1651463567,
                    "expirationTime": 1653366760,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9436",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "9961",
                    "listingTime": 1651402762,
                    "expirationTime": 1666954753,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9961",
                    "makerAddr": "0x70BAb06BA6b16387Ceb09B53F3c74D8809E3051a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "9292",
                    "listingTime": 1651436047,
                    "expirationTime": 1654028001,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9292",
                    "makerAddr": "0xD6DFB6e9E2577217921dffE1E79DABC52e443327",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40000000000000000000",
                    "tokenId": "20664",
                    "listingTime": 1651435298,
                    "expirationTime": 1653437727,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20664",
                    "makerAddr": "0x545a2eD169EaC188638763539D30951488a9c8F7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "69",
                    "price": "40000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651886274,
                    "expirationTime": 1654564766,
                    "makerAddr": "0xb47c55f00a31deda8415e67d587168e05d41f92c",
                    "assetImageUrl": "https://img.seadn.io/files/b1b74c2a61e04e5daac2f72050ce0faf.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "242",
                    "price": "40000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651779747,
                    "expirationTime": 1653451030,
                    "makerAddr": "0x8dbb75c576b71b43eea54398f8606aec530181dc",
                    "assetImageUrl": "https://img.seadn.io/files/d3dda69fed5db36e46e1cfd59efdcb91.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1038",
                    "price": "40000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651453046,
                    "expirationTime": 1654131540,
                    "makerAddr": "0x4011652b6bcbbaf95e486937f434bb580510bbf6",
                    "assetImageUrl": "https://img.seadn.io/files/79e7d031d70675b75dc2ccd5c0d8c5ac.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1375",
                    "price": "40000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651605095,
                    "expirationTime": 1654283586,
                    "makerAddr": "0x37f03ba11b42dbc2626ff637855e3cc8f99995c6",
                    "assetImageUrl": "https://img.seadn.io/files/0e50d0a5cc8c0784064030aefbb2a7ec.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1405",
                    "price": "40000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652020318,
                    "expirationTime": 1652625209,
                    "makerAddr": "0x7785082d0d265f7fc1107d17af5ba102d1dbb2e8",
                    "assetImageUrl": "https://img.seadn.io/files/49bca7ab2779f1deb7221f25402f8923.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1585",
                    "price": "40000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651442606,
                    "expirationTime": 1653398191,
                    "makerAddr": "0xa6cd59048b91f6149245d7ee80ac14f38d112817",
                    "assetImageUrl": "https://img.seadn.io/files/cf5b9d1a48ddecfc3c5bec27a497c15d.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1773",
                    "price": "40000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651552627,
                    "expirationTime": 1654231111,
                    "makerAddr": "0x85912b39de6857351ce89b7af959995ae137d996",
                    "assetImageUrl": "https://img.seadn.io/files/94d5400aeb932f4be6223202f18468d2.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1845",
                    "price": "40000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652184673,
                    "expirationTime": 1654531159,
                    "makerAddr": "0x7e6a90d145ba102c09c83fdc8d7df84f8d1d818a",
                    "assetImageUrl": "https://img.seadn.io/files/64ffdf96f128fc2dfd93c2e3ea6c654c.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2688",
                    "price": "40000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651894657,
                    "expirationTime": 1654278259,
                    "makerAddr": "0x39a7fb978b1f43219f096d7eb0836fd08cd74e15",
                    "assetImageUrl": "https://img.seadn.io/files/472b508851a7b93ca4ff33b40044e0dd.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40111000000000000000",
                    "tokenId": "2994",
                    "listingTime": 1651382232,
                    "expirationTime": 1653945424,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2994",
                    "makerAddr": "0x4eD9c6193ede88A5D41b2E833E46508800420780",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40200000000000000000",
                    "tokenId": "3814",
                    "listingTime": 1651448267,
                    "expirationTime": 1654040203,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3814",
                    "makerAddr": "0xc26cBbD3e319D5969E068018050a4797318a6D14",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40250000000000000000",
                    "tokenId": "3878",
                    "listingTime": 1651446796,
                    "expirationTime": 1666347219,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3878",
                    "makerAddr": "0xDC859b3da111A0Eb0AD3bB536dCB5d9f14f19F99",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40300000000000000000",
                    "tokenId": "7296",
                    "listingTime": 1651447616,
                    "expirationTime": 1653973345,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7296",
                    "makerAddr": "0x4c8F62f1498FA55D4158CdBFEA7783f84556a68e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2994",
                    "price": "40400000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651382099,
                    "expirationTime": 1653945571,
                    "makerAddr": "0x4ed9c6193ede88a5d41b2e833e46508800420780",
                    "assetImageUrl": "https://img.seadn.io/files/f25792be6fd5139e10d8bc30f74e3b0e.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40500000000000000000",
                    "tokenId": "24034",
                    "listingTime": 1651662784,
                    "expirationTime": 1652267578,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24034",
                    "makerAddr": "0x0709c80c7A3979088695009Fee3CEe2e3C9d4e5e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40750000000000000000",
                    "tokenId": "6573",
                    "listingTime": 1651410250,
                    "expirationTime": 1654002156,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6573",
                    "makerAddr": "0x90290322426dD5e36becAc4B01Fc1E2613aaAEEE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "40950000000000000000",
                    "tokenId": "5911",
                    "listingTime": 1651457683,
                    "expirationTime": 1654049671,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5911",
                    "makerAddr": "0x630CF545B04b6458faDC98654681456009f19478",
                    "assetImageUrl": ""
                }
            ],
            "total": 2.7234609999999994e+21,
            "looksrare": 58,
            "opensea": 10
        },
        {
            "price": 41,
            "amount": 61,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "15774",
                    "listingTime": 1652014364,
                    "expirationTime": 1652273550,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15774",
                    "makerAddr": "0xca7adC0614c3Ea4Dc6625E20cdA60AdB8Ef3dFEc",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "5013",
                    "listingTime": 1651393800,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5013",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "5287",
                    "listingTime": 1651662661,
                    "expirationTime": 1654254653,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5287",
                    "makerAddr": "0xCaE7bF3715CeeC1fC2863D9CE81E042136b34E80",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "1568",
                    "listingTime": 1651400149,
                    "expirationTime": 1653992137,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1568",
                    "makerAddr": "0x835ba4bA03713208Fa8404acd626466C9273F93c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "22996",
                    "listingTime": 1651402844,
                    "expirationTime": 1653760881,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22996",
                    "makerAddr": "0x7082dB9Ce4f520253217d8EB1fD6b6097B74c483",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "20422",
                    "listingTime": 1651394650,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20422",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "5274",
                    "listingTime": 1651662520,
                    "expirationTime": 1654254510,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5274",
                    "makerAddr": "0xaBeA9711C5672C870f65835Ff84Cea14c353EE57",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "5742",
                    "listingTime": 1651393657,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5742",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "10712",
                    "listingTime": 1651662608,
                    "expirationTime": 1654254566,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10712",
                    "makerAddr": "0x3d3FBE809eEA54E106E5B30EA2f5Dedbc94D8eE8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "13550",
                    "listingTime": 1651394597,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13550",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "12080",
                    "listingTime": 1651394433,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12080",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "331",
                    "listingTime": 1651431901,
                    "expirationTime": 1654023884,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/331",
                    "makerAddr": "0x0d4428C8E62e894999d8E4c1845f23cb5F9d919d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "9436",
                    "listingTime": 1651390727,
                    "expirationTime": 1653366760,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9436",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "189",
                    "listingTime": 1651662626,
                    "expirationTime": 1654254566,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/189",
                    "makerAddr": "0x3d3FBE809eEA54E106E5B30EA2f5Dedbc94D8eE8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "2159",
                    "listingTime": 1651393886,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2159",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "14562",
                    "listingTime": 1651394303,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14562",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "29338",
                    "listingTime": 1651394454,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29338",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "27942",
                    "listingTime": 1651394407,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27942",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "9299",
                    "listingTime": 1651433522,
                    "expirationTime": 1653657671,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9299",
                    "makerAddr": "0xe7e4bfeEB83A5b752A4E90D4f89fa0B21FBB54b4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "1681",
                    "listingTime": 1651394325,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1681",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "5483",
                    "listingTime": 1651393763,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5483",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "28070",
                    "listingTime": 1651390699,
                    "expirationTime": 1653366831,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28070",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "23924",
                    "listingTime": 1651398543,
                    "expirationTime": 1653475571,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23924",
                    "makerAddr": "0xBF21B6B77B893E11e96d79157Eb417F3e4E9538e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "14380",
                    "listingTime": 1651394043,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14380",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "20790",
                    "listingTime": 1651394349,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20790",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "1532",
                    "listingTime": 1651662068,
                    "expirationTime": 1654254057,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1532",
                    "makerAddr": "0x8a677474dEC43FCAeB80a2Dd2B1bc5D945aB61d1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "5188",
                    "listingTime": 1651662597,
                    "expirationTime": 1654254589,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5188",
                    "makerAddr": "0xb1d802566C228D1cB0484f29Fc7CaA009e75A1ef",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "2137",
                    "listingTime": 1651394226,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2137",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "7517",
                    "listingTime": 1651491747,
                    "expirationTime": 1654083735,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7517",
                    "makerAddr": "0x51b2C29c40Ea3d1431491eFb4E1B5d1F018C844c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "16312",
                    "listingTime": 1651393956,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16312",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "9796",
                    "listingTime": 1651393563,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9796",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "27100",
                    "listingTime": 1651394385,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27100",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "9118",
                    "listingTime": 1651662476,
                    "expirationTime": 1654254448,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9118",
                    "makerAddr": "0x44bdd22FFb03b5077A44b8E01d23170CA07E77a5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "9370",
                    "listingTime": 1651393720,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9370",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "4080",
                    "listingTime": 1651393850,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4080",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "15552",
                    "listingTime": 1651394263,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15552",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "18624",
                    "listingTime": 1651397310,
                    "expirationTime": 1653989290,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18624",
                    "makerAddr": "0xC88646ba27442A507c965Fef65bb870Da9B5Fd4f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "17722",
                    "listingTime": 1651394283,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17722",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "2914",
                    "listingTime": 1651390670,
                    "expirationTime": 1653366800,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2914",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "5447",
                    "listingTime": 1651393585,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5447",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "7014",
                    "listingTime": 1651662701,
                    "expirationTime": 1654254689,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7014",
                    "makerAddr": "0x908c6143512b4Ec00170c56e8954746EF7b5A577",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "9199",
                    "listingTime": 1651428442,
                    "expirationTime": 1653449081,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9199",
                    "makerAddr": "0x99d73D76f0058F4ae24882A49A0a9eA0f5a41268",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "4247",
                    "listingTime": 1651393923,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4247",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "19820",
                    "listingTime": 1651394616,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19820",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "12956",
                    "listingTime": 1651394489,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12956",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "11304",
                    "listingTime": 1651431914,
                    "expirationTime": 1654023884,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11304",
                    "makerAddr": "0x0d4428C8E62e894999d8E4c1845f23cb5F9d919d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41000000000000000000",
                    "tokenId": "12810",
                    "listingTime": 1651394554,
                    "expirationTime": 1653985529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12810",
                    "makerAddr": "0xF8c9CF133252a1a0B2c4B527381eaa94eAEf62a6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "497",
                    "price": "41000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651664416,
                    "expirationTime": 1654225120,
                    "makerAddr": "0xf98ff587feb37db9e6934ddaf1ab377240430753",
                    "assetImageUrl": "https://img.seadn.io/files/d83d6499095706c3af63e01153665cfc.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41111000000000000000",
                    "tokenId": "2994",
                    "listingTime": 1651382091,
                    "expirationTime": 1653945424,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2994",
                    "makerAddr": "0x4eD9c6193ede88A5D41b2E833E46508800420780",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41250000000000000000",
                    "tokenId": "9653",
                    "listingTime": 1651382431,
                    "expirationTime": 1653974241,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9653",
                    "makerAddr": "0x27DB134012676a0542c667c610920e269AFE89B9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41500000000000000000",
                    "tokenId": "9623",
                    "listingTime": 1651427670,
                    "expirationTime": 1652637254,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9623",
                    "makerAddr": "0x13aff6B6d6Ad092e8D552Af8FbdE6203770ad0eB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41500000000000000000",
                    "tokenId": "18168",
                    "listingTime": 1651394036,
                    "expirationTime": 1653374529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18168",
                    "makerAddr": "0x35dF9413f56332E389b41Be14c98E63500bE0Bc6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41690000000000000000",
                    "tokenId": "3878",
                    "listingTime": 1651392246,
                    "expirationTime": 1666347219,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3878",
                    "makerAddr": "0xDC859b3da111A0Eb0AD3bB536dCB5d9f14f19F99",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41690000000000000000",
                    "tokenId": "1467",
                    "listingTime": 1651390004,
                    "expirationTime": 1653811913,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1467",
                    "makerAddr": "0x34f3802366A59d96565ffDEd9BB601d5e28A3b82",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "128",
                    "price": "41690000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651685971,
                    "expirationTime": 1652895571,
                    "makerAddr": "0x82e1ec93be8b2a6a93cd14a7fd5326491fc06c94",
                    "assetImageUrl": "https://img.seadn.io/files/81fa6834853ab6c1d43be8f65d15f0d4.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41850000000000000000",
                    "tokenId": "7536",
                    "listingTime": 1651435902,
                    "expirationTime": 1654027892,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7536",
                    "makerAddr": "0x913782500cc8acDfC8AfA2591222d4Ad8771A9d1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41850000000000000000",
                    "tokenId": "921",
                    "listingTime": 1651435931,
                    "expirationTime": 1654027923,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/921",
                    "makerAddr": "0x913782500cc8acDfC8AfA2591222d4Ad8771A9d1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "369",
                    "price": "41950000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652121947,
                    "expirationTime": 1652381245,
                    "makerAddr": "0xc32c0022ce7ea8b571165c29f84763c2791f7225",
                    "assetImageUrl": "https://img.seadn.io/files/989068fc50db452ba78182f78c056fd3.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41990000000000000000",
                    "tokenId": "23012",
                    "listingTime": 1651387406,
                    "expirationTime": 1653979393,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23012",
                    "makerAddr": "0x298d5537C181766D22a27D40b90A9c8ccA5b5aD5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41990000000000000000",
                    "tokenId": "5399",
                    "listingTime": 1651425270,
                    "expirationTime": 1654045200,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5399",
                    "makerAddr": "0x13c3258823fF9C506cb72291672dc9ea1E5517E4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "41990000000000000000",
                    "tokenId": "2650",
                    "listingTime": 1651386073,
                    "expirationTime": 1653977591,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2650",
                    "makerAddr": "0x6978d5039DBd1aEb184954e659E9988dA208C787",
                    "assetImageUrl": ""
                }
            ],
            "total": 2.510051e+21,
            "looksrare": 58,
            "opensea": 3
        },
        {
            "price": 42,
            "amount": 70,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "1658",
                    "listingTime": 1651965354,
                    "expirationTime": 1652224550,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1658",
                    "makerAddr": "0x41ab08bED89c31619Aa831e005DAC6beDCed7B46",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "825",
                    "listingTime": 1651421990,
                    "expirationTime": 1652585020,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/825",
                    "makerAddr": "0xa7e4f12B7Cd97387e8607daC17B439ca7cad58e9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "9584",
                    "listingTime": 1651403299,
                    "expirationTime": 1653995241,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9584",
                    "makerAddr": "0x3dE262F19795845534A6e8D604d0Da383C2FCC9D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "26445",
                    "listingTime": 1651818936,
                    "expirationTime": 1652423700,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26445",
                    "makerAddr": "0xD5bF1cbef858e8dd1Efe8747ceF7bA56eC175BC6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "19114",
                    "listingTime": 1651434382,
                    "expirationTime": 1653410322,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19114",
                    "makerAddr": "0x88889778402dcf6cF363bB9Ac47f7A2D8E77A2AF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "6685",
                    "listingTime": 1651434404,
                    "expirationTime": 1653410322,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6685",
                    "makerAddr": "0x88889778402dcf6cF363bB9Ac47f7A2D8E77A2AF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "5579",
                    "listingTime": 1651405703,
                    "expirationTime": 1653397146,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5579",
                    "makerAddr": "0xD163bd16eaf4813D723F90f13fE4abeeECCdD3dd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "4622",
                    "listingTime": 1651403850,
                    "expirationTime": 1653834728,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4622",
                    "makerAddr": "0x998f20a7f456690F2983E5D4639288C0A1f33810",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "14730",
                    "listingTime": 1651391565,
                    "expirationTime": 1666809771,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14730",
                    "makerAddr": "0x497402DC41E79523500BE7C35193a5879298091e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "22234",
                    "listingTime": 1651396520,
                    "expirationTime": 1653988099,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22234",
                    "makerAddr": "0x1AB6769a6289465D827567Cdb05e07B068598C21",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "6046",
                    "listingTime": 1651433894,
                    "expirationTime": 1653401649,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6046",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "8852",
                    "listingTime": 1651432223,
                    "expirationTime": 1653852863,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8852",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "3827",
                    "listingTime": 1651432202,
                    "expirationTime": 1653408280,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3827",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "5614",
                    "listingTime": 1651434440,
                    "expirationTime": 1653410322,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5614",
                    "makerAddr": "0x88889778402dcf6cF363bB9Ac47f7A2D8E77A2AF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "205",
                    "listingTime": 1651389369,
                    "expirationTime": 1653981354,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/205",
                    "makerAddr": "0x53f42691DF74AD573F5B8a461EBEFe09ECddf920",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "6250",
                    "listingTime": 1651396923,
                    "expirationTime": 1653485399,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6250",
                    "makerAddr": "0x989cE0d5f551d94EC87E638Dc643c014e63a0c0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "6423",
                    "listingTime": 1651384570,
                    "expirationTime": 1653627080,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6423",
                    "makerAddr": "0xdF41003BcdC9aa917Fdae801BD02b3167368D5CD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "23418",
                    "listingTime": 1651458328,
                    "expirationTime": 1653658673,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23418",
                    "makerAddr": "0xf98fF587fEb37db9e6934DDaf1aB377240430753",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "5383",
                    "listingTime": 1651434109,
                    "expirationTime": 1653823241,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5383",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "1301",
                    "listingTime": 1651418514,
                    "expirationTime": 1654010475,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1301",
                    "makerAddr": "0xc8771e16ADFc9F4b9F6B0473eAfe2058A23C8b9c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "16056",
                    "listingTime": 1651431298,
                    "expirationTime": 1666242212,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16056",
                    "makerAddr": "0x55A5A4b75B5B2E0c1FdFe5c5Fb67103C32f50013",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "3724",
                    "listingTime": 1651404342,
                    "expirationTime": 1653995981,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3724",
                    "makerAddr": "0x31D447ccF5ed1905287Ca964b72F5fef01682944",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "4781",
                    "listingTime": 1651385167,
                    "expirationTime": 1653655778,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4781",
                    "makerAddr": "0x75ecEc45CDdE8cEd19880e735a619fa7162B40e2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "1700",
                    "listingTime": 1651403029,
                    "expirationTime": 1653995012,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1700",
                    "makerAddr": "0x3e6527De2CFec82CE5240d5559A8b09d256D5D6d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "4008",
                    "listingTime": 1651423951,
                    "expirationTime": 1654015899,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4008",
                    "makerAddr": "0xe0e5D2d2AE5a629928D452fFa325D1083AAC3997",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "7295",
                    "listingTime": 1652109442,
                    "expirationTime": 1652367730,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7295",
                    "makerAddr": "0xb0D11De0F31a33684FBdbA3dbf008992ff418810",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "21970",
                    "listingTime": 1651398504,
                    "expirationTime": 1653473921,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21970",
                    "makerAddr": "0xBF21B6B77B893E11e96d79157Eb417F3e4E9538e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "4745",
                    "listingTime": 1651384587,
                    "expirationTime": 1653405548,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4745",
                    "makerAddr": "0xdF41003BcdC9aa917Fdae801BD02b3167368D5CD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "667",
                    "listingTime": 1651404489,
                    "expirationTime": 1653903639,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/667",
                    "makerAddr": "0x38d7eAd5550D20C518453C55feb8992B0F8BFfB2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "7455",
                    "listingTime": 1651512289,
                    "expirationTime": 1653408852,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7455",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "29146",
                    "listingTime": 1651390102,
                    "expirationTime": 1653982098,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29146",
                    "makerAddr": "0xE3813D26d44BcB6C40f29e5487dfF5434c629051",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "408",
                    "listingTime": 1651384534,
                    "expirationTime": 1653405721,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/408",
                    "makerAddr": "0x98d31E06789783d5C1DD2E18013f04C9dAb78656",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "19126",
                    "listingTime": 1651434628,
                    "expirationTime": 1654026598,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19126",
                    "makerAddr": "0x385809f08e80693E1EDa54c9524a2995483Ab16E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "20684",
                    "listingTime": 1651412666,
                    "expirationTime": 1653648255,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20684",
                    "makerAddr": "0x69b38da50A977Dc61C7571c9DC8192Ea1A884DCB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "869",
                    "listingTime": 1651397486,
                    "expirationTime": 1653989476,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/869",
                    "makerAddr": "0xaFe7DF0F7A454dBb89BE9ee0c3AA731744890cCe",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "15688",
                    "listingTime": 1651402874,
                    "expirationTime": 1653471215,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15688",
                    "makerAddr": "0x7082dB9Ce4f520253217d8EB1fD6b6097B74c483",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "626",
                    "listingTime": 1651390841,
                    "expirationTime": 1653631418,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/626",
                    "makerAddr": "0xe90a804dB10c4Ffd52732A0da6a0dE9e4fab4cD4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "29402",
                    "listingTime": 1651418536,
                    "expirationTime": 1654010475,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29402",
                    "makerAddr": "0xc8771e16ADFc9F4b9F6B0473eAfe2058A23C8b9c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "20576",
                    "listingTime": 1652105451,
                    "expirationTime": 1659881446,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20576",
                    "makerAddr": "0xD204e260ac2b6295298151E6AaadC2C6aF29f780",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42000000000000000000",
                    "tokenId": "6917",
                    "listingTime": 1651386468,
                    "expirationTime": 1653978444,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6917",
                    "makerAddr": "0x6beEF2B2fE00FDDCa12A8CDA2D4B00435b0ba3b6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "273",
                    "price": "42000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651505197,
                    "expirationTime": 1654183687,
                    "makerAddr": "0xd8676ddcb2724e695c68d264737f290e47258c41",
                    "assetImageUrl": "https://img.seadn.io/files/2e61cc1eb4fe2349c4c4f5b9e9cdb7aa.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "801",
                    "price": "42000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651526053,
                    "expirationTime": 1653840270,
                    "makerAddr": "0x709bf4ac7ed6bb2f9d60b1215d983496ab68efbc",
                    "assetImageUrl": "https://img.seadn.io/files/1cf465d324375eedbfa1513eda8b3134.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "872",
                    "price": "42000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652162219,
                    "expirationTime": 1654626341,
                    "makerAddr": "0x039d3ece7a18986661fb33d1e3e1f6a30c7f93e0",
                    "assetImageUrl": "https://img.seadn.io/files/624c6fde58f6d6422f5a95a2beac6dea.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1288",
                    "price": "42000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651848536,
                    "expirationTime": 1659624634,
                    "makerAddr": "0x21a7c27f41131cd9c2d1616bacb73998a8c86974",
                    "assetImageUrl": "https://img.seadn.io/files/80b3b6a8968dc6422e93590aa7977ded.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1928",
                    "price": "42000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651381947,
                    "expirationTime": 1654060428,
                    "makerAddr": "0xa576538788efabab9d5cefbd3339ca0b13c94758",
                    "assetImageUrl": "https://img.seadn.io/files/f0e388d77b5a1ce22e895b65401ab9de.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2214",
                    "price": "42000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651383628,
                    "expirationTime": 1659159713,
                    "makerAddr": "0xa3485d31895e6642a2cb6a8bea99b402ca577dfd",
                    "assetImageUrl": "https://img.seadn.io/files/42f3cf371e4f4f8a953111120be7aa08.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2801",
                    "price": "42000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652074158,
                    "expirationTime": 1654752613,
                    "makerAddr": "0x0b6a1a87d6e689072257f590a06361a27f6628ea",
                    "assetImageUrl": "https://img.seadn.io/files/276ac52ebbafd5b404a95c372c9151ba.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42069000000000000000",
                    "tokenId": "1514",
                    "listingTime": 1652178512,
                    "expirationTime": 1652368859,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1514",
                    "makerAddr": "0xd7CdB08EaDd3D730540817a348b62D15BF45DF10",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42069000000000000000",
                    "tokenId": "9333",
                    "listingTime": 1651565714,
                    "expirationTime": 1654243585,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9333",
                    "makerAddr": "0xD2840c5DE424E67B9d872bb04F8F131ae0990749",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42069000000000000000",
                    "tokenId": "28042",
                    "listingTime": 1651395378,
                    "expirationTime": 1653987235,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28042",
                    "makerAddr": "0xbbaC4eE68652560c04BF8211b4ae7Ddc5c5F51C3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1514",
                    "price": "42069000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652178370,
                    "expirationTime": 1652368828,
                    "makerAddr": "0xd7cdb08eadd3d730540817a348b62d15bf45df10",
                    "assetImageUrl": "https://img.seadn.io/files/8ae11724ea6e8470a975cc8d022c130e.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1574",
                    "price": "42069000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652109609,
                    "expirationTime": 1654217123,
                    "makerAddr": "0xb0cd110791db97601cfa6c38ad38952ebd739ba5",
                    "assetImageUrl": "https://img.seadn.io/files/a5c783c469771411a94a04865fa1c5a7.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42300000000000000000",
                    "tokenId": "16800",
                    "listingTime": 1651427928,
                    "expirationTime": 1654019927,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16800",
                    "makerAddr": "0x2c3c28C8B928B85fe8cCb74B80BA22d300e35155",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42500000000000000000",
                    "tokenId": "18962",
                    "listingTime": 1651384652,
                    "expirationTime": 1653406554,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18962",
                    "makerAddr": "0xfa861eE098C87c78AB96d780C5211d9557869920",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42500000000000000000",
                    "tokenId": "20303",
                    "listingTime": 1651922746,
                    "expirationTime": 1652694617,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20303",
                    "makerAddr": "0x298d5537C181766D22a27D40b90A9c8ccA5b5aD5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42500000000000000000",
                    "tokenId": "4703",
                    "listingTime": 1651390230,
                    "expirationTime": 1653944599,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4703",
                    "makerAddr": "0xdD929d6341B18f77a6a11Eb8c8db66df0C1a04D7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42500000000000000000",
                    "tokenId": "5148",
                    "listingTime": 1651396408,
                    "expirationTime": 1653942302,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5148",
                    "makerAddr": "0x75Cf2D77d40abd92756c704A963098a87b4e1aEC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42500000000000000000",
                    "tokenId": "26444",
                    "listingTime": 1651402902,
                    "expirationTime": 1653471315,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26444",
                    "makerAddr": "0x7082dB9Ce4f520253217d8EB1fD6b6097B74c483",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42500000000000000000",
                    "tokenId": "11685",
                    "listingTime": 1652095985,
                    "expirationTime": 1652700779,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11685",
                    "makerAddr": "0x1F4D23b9f1E368af1c901319f328eC4547AA845a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2127",
                    "price": "42500000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651381987,
                    "expirationTime": 1654060478,
                    "makerAddr": "0x964f690def870b36f017f6127ab1b929645333c0",
                    "assetImageUrl": "https://img.seadn.io/files/18844c34b9e55053fea3f15dbf29ecf7.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42690000000000000000",
                    "tokenId": "1642",
                    "listingTime": 1651399071,
                    "expirationTime": 1666388866,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1642",
                    "makerAddr": "0xfb363b7D7a1c065356442829F03C84F9660CE1e2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42690000000000000000",
                    "tokenId": "2738",
                    "listingTime": 1651488630,
                    "expirationTime": 1652698222,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2738",
                    "makerAddr": "0x4D7646bFCB29fa194267aA60382bA82671dF3195",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42690000000000000000",
                    "tokenId": "8914",
                    "listingTime": 1651428310,
                    "expirationTime": 1653827606,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8914",
                    "makerAddr": "0x300772D881e58A4eb2BB0844D5FC9B2e5476B31b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2233",
                    "price": "42690000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651892320,
                    "expirationTime": 1652497209,
                    "makerAddr": "0x7151e5282bc1c952700ff070a933f17c644a66f6",
                    "assetImageUrl": "https://img.seadn.io/files/4f45806224cb84372bf376a2025b2468.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2552",
                    "price": "42690000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652121530,
                    "expirationTime": 1653672779,
                    "makerAddr": "0x24519bb072e7303fe40793a7627cd1dd08eaeecc",
                    "assetImageUrl": "https://img.seadn.io/files/c5908af8d713cb41c245e32eb5c3a9d6.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3136",
                    "price": "42690000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652178681,
                    "expirationTime": 1654784319,
                    "makerAddr": "0x6203bfce667bf30aca161a727f3476c36904258b",
                    "assetImageUrl": "https://img.seadn.io/files/ffa468b82e0713393bc791351195707b.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42735000000000000000",
                    "tokenId": "7296",
                    "listingTime": 1651423691,
                    "expirationTime": 1653973345,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7296",
                    "makerAddr": "0x4c8F62f1498FA55D4158CdBFEA7783f84556a68e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42750000000000000000",
                    "tokenId": "13055",
                    "listingTime": 1651637778,
                    "expirationTime": 1654229728,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13055",
                    "makerAddr": "0x60A10d2C74608C3b341EcBEb42F3D08bBa921aa3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42750000000000000000",
                    "tokenId": "22836",
                    "listingTime": 1651424034,
                    "expirationTime": 1653354516,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22836",
                    "makerAddr": "0x0f28862bb9cC96f865c8047268230fF1248C7721",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "42990000000000000000",
                    "tokenId": "2650",
                    "listingTime": 1651385618,
                    "expirationTime": 1653977591,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2650",
                    "makerAddr": "0x6978d5039DBd1aEb184954e659E9988dA208C787",
                    "assetImageUrl": ""
                }
            ],
            "total": 2.9515099999999995e+21,
            "looksrare": 57,
            "opensea": 13
        },
        {
            "price": 43,
            "amount": 31,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43000000000000000000",
                    "tokenId": "5704",
                    "listingTime": 1651398538,
                    "expirationTime": 1653990533,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5704",
                    "makerAddr": "0x4A1536C56a7076f91663A1df9f99d9120152740a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43000000000000000000",
                    "tokenId": "4584",
                    "listingTime": 1651400265,
                    "expirationTime": 1653992259,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4584",
                    "makerAddr": "0xab36Ef38d02E5aeC0fCcBc6B76772A0CF3E69A38",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43000000000000000000",
                    "tokenId": "2125",
                    "listingTime": 1651407332,
                    "expirationTime": 1653999331,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2125",
                    "makerAddr": "0x175e393539e93720B962D186c70fdf1a362c8fd7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43000000000000000000",
                    "tokenId": "11381",
                    "listingTime": 1652110714,
                    "expirationTime": 1652643185,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11381",
                    "makerAddr": "0x95dFDF0306945282016eb50212edf82054c00F96",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43000000000000000000",
                    "tokenId": "10631",
                    "listingTime": 1651428454,
                    "expirationTime": 1654020441,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10631",
                    "makerAddr": "0x18F45eD6e66E124a22975Cda498e825f29f0f3e3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43000000000000000000",
                    "tokenId": "17826",
                    "listingTime": 1651400288,
                    "expirationTime": 1653992284,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17826",
                    "makerAddr": "0xab36Ef38d02E5aeC0fCcBc6B76772A0CF3E69A38",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43000000000000000000",
                    "tokenId": "17516",
                    "listingTime": 1651418805,
                    "expirationTime": 1654010796,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17516",
                    "makerAddr": "0xBEFE9091541d2FD2Bb2B931536Fbee0383C4c4E0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43000000000000000000",
                    "tokenId": "7351",
                    "listingTime": 1651419967,
                    "expirationTime": 1654011882,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7351",
                    "makerAddr": "0x56767eD9B7e23607Ba62a1CF41B8708b9Dbb5555",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43000000000000000000",
                    "tokenId": "7085",
                    "listingTime": 1651388047,
                    "expirationTime": 1653980042,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7085",
                    "makerAddr": "0x2D66B7eA0c89158d7274aeF1019423d968F7864F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43000000000000000000",
                    "tokenId": "9120",
                    "listingTime": 1651414428,
                    "expirationTime": 1653401169,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9120",
                    "makerAddr": "0x9f2301960dF3D3c97D891362d716F42e93D35D1E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43000000000000000000",
                    "tokenId": "5893",
                    "listingTime": 1651399365,
                    "expirationTime": 1653803334,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5893",
                    "makerAddr": "0x1FEACcB479834998BD7750754062347A6FaD8F9F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43000000000000000000",
                    "tokenId": "29325",
                    "listingTime": 1652178127,
                    "expirationTime": 1652782917,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29325",
                    "makerAddr": "0x5d37431b1356D8CC54f3dd77657e566830D73186",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43000000000000000000",
                    "tokenId": "2914",
                    "listingTime": 1651389087,
                    "expirationTime": 1653366800,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2914",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43000000000000000000",
                    "tokenId": "16940",
                    "listingTime": 1652129565,
                    "expirationTime": 1652388747,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16940",
                    "makerAddr": "0x0666b542D3cCF9Be414431f3e3864e024F156e60",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43000000000000000000",
                    "tokenId": "2342",
                    "listingTime": 1651398443,
                    "expirationTime": 1653990438,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2342",
                    "makerAddr": "0xDd0e3B2056eb98b9EE1cc3aD4954C8a4c24c1b05",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43000000000000000000",
                    "tokenId": "1687",
                    "listingTime": 1651383877,
                    "expirationTime": 1653569271,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1687",
                    "makerAddr": "0x2eA07698a88AD2a0Acfc82C229C5cBaDb7CC9c9C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43000000000000000000",
                    "tokenId": "5364",
                    "listingTime": 1651407315,
                    "expirationTime": 1653999313,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5364",
                    "makerAddr": "0x175e393539e93720B962D186c70fdf1a362c8fd7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43000000000000000000",
                    "tokenId": "9759",
                    "listingTime": 1651425472,
                    "expirationTime": 1652591196,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9759",
                    "makerAddr": "0x4F33D206eAEccC0674158bCbCf44711B9454d7aC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43000000000000000000",
                    "tokenId": "8580",
                    "listingTime": 1651419365,
                    "expirationTime": 1653806048,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8580",
                    "makerAddr": "0x80B23AeCB95535d09077E5FBe4b09805e2a9F58F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43000000000000000000",
                    "tokenId": "7628",
                    "listingTime": 1651435143,
                    "expirationTime": 1654027123,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7628",
                    "makerAddr": "0xeD54AA06eCf1DD2439De259c07510938809730bE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "251",
                    "price": "43000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652185287,
                    "expirationTime": 1652271767,
                    "makerAddr": "0x2707b8785885be4bf54f5eeb38cfbf0fc70ae039",
                    "assetImageUrl": "https://img.seadn.io/files/807bba20daddc2a78cc7d5b277e0961e.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43210000000000000000",
                    "tokenId": "3878",
                    "listingTime": 1651390932,
                    "expirationTime": 1666347219,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3878",
                    "makerAddr": "0xDC859b3da111A0Eb0AD3bB536dCB5d9f14f19F99",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1057",
                    "price": "43230000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651454276,
                    "expirationTime": 1654132772,
                    "makerAddr": "0xaa621b960f22911462550c078df678493c22b2ae",
                    "assetImageUrl": "https://img.seadn.io/files/9eecfaff6fe3da2a41d90073dbe2caa3.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1528",
                    "price": "43230000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651454265,
                    "expirationTime": 1654132759,
                    "makerAddr": "0xaa621b960f22911462550c078df678493c22b2ae",
                    "assetImageUrl": "https://img.seadn.io/files/a898d9c787658ba0320bb46c1ab8b87c.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43500000000000000000",
                    "tokenId": "28070",
                    "listingTime": 1651389529,
                    "expirationTime": 1653366831,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28070",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43500000000000000000",
                    "tokenId": "2976",
                    "listingTime": 1651404522,
                    "expirationTime": 1653903673,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2976",
                    "makerAddr": "0x38d7eAd5550D20C518453C55feb8992B0F8BFfB2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43500000000000000000",
                    "tokenId": "6112",
                    "listingTime": 1651388900,
                    "expirationTime": 1653980889,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6112",
                    "makerAddr": "0xdd0D1B8aD874A15d020E7b2AF83e213f1f25F066",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43690000000000000000",
                    "tokenId": "21648",
                    "listingTime": 1651419534,
                    "expirationTime": 1654011503,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21648",
                    "makerAddr": "0x5aD68B984Dd528715A54895D32d1CF9115E7aB7B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1658",
                    "price": "43690000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651793784,
                    "expirationTime": 1654472228,
                    "makerAddr": "0x41ab08bed89c31619aa831e005dac6bedced7b46",
                    "assetImageUrl": "https://img.seadn.io/files/113591a6554e42e67eb95b4cac90aaea.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43900000000000000000",
                    "tokenId": "11480",
                    "listingTime": 1651416740,
                    "expirationTime": 1654008738,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11480",
                    "makerAddr": "0xb2AB83B605bf7b9E70cA18415A16dC6239BA14a8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "43940000000000000000",
                    "tokenId": "21209",
                    "listingTime": 1651388986,
                    "expirationTime": 1653980972,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21209",
                    "makerAddr": "0xff0288877e2b1186c701e024C02f17DA04b2b913",
                    "assetImageUrl": ""
                }
            ],
            "total": 1.33839e+21,
            "looksrare": 27,
            "opensea": 4
        },
        {
            "price": 44,
            "amount": 48,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "460",
                    "listingTime": 1651418241,
                    "expirationTime": 1654010197,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/460",
                    "makerAddr": "0x77d8d216380A532Bd3238dA63e3788e4150BcEd8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "17940",
                    "listingTime": 1651409062,
                    "expirationTime": 1654001045,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17940",
                    "makerAddr": "0x4B3B34A1512376c58633537C96812D1FD256f3f3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "21189",
                    "listingTime": 1651777069,
                    "expirationTime": 1652986661,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21189",
                    "makerAddr": "0x8Dc1eFcf99f0ac1CDcde18A5F596CFb99d50e89B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "23174",
                    "listingTime": 1651414071,
                    "expirationTime": 1658677747,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23174",
                    "makerAddr": "0x7e2225B3e54A7ad0f2D009237a4f136C02F90a52",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "27350",
                    "listingTime": 1651406868,
                    "expirationTime": 1653998843,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27350",
                    "makerAddr": "0x775490b4C406658C425F9A1ca76199463dCe9483",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "4030",
                    "listingTime": 1651418219,
                    "expirationTime": 1654010197,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4030",
                    "makerAddr": "0x77d8d216380A532Bd3238dA63e3788e4150BcEd8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "6682",
                    "listingTime": 1651417684,
                    "expirationTime": 1653398147,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6682",
                    "makerAddr": "0x71dfD2baB718F5bB30f1099494a68752B5d2f2e2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "23356",
                    "listingTime": 1651386504,
                    "expirationTime": 1653591186,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23356",
                    "makerAddr": "0x8B9Cc341084337c0A5828BC0D5E20dADB197d991",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "7731",
                    "listingTime": 1651409306,
                    "expirationTime": 1654001233,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7731",
                    "makerAddr": "0x5a72504387807cC22a5B5F65148e7D6D83b9ae80",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "9150",
                    "listingTime": 1651406995,
                    "expirationTime": 1653465468,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9150",
                    "makerAddr": "0x17b128B6771F5D26de3348db0cd222328cc1Eb47",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "20674",
                    "listingTime": 1651401985,
                    "expirationTime": 1653830600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20674",
                    "makerAddr": "0x2dA054af93D06Dc140Ef84403A67Ae7ceA89df1C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "5579",
                    "listingTime": 1651395245,
                    "expirationTime": 1653397146,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5579",
                    "makerAddr": "0xD163bd16eaf4813D723F90f13fE4abeeECCdD3dd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "27024",
                    "listingTime": 1650480630,
                    "expirationTime": 1653072573,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27024",
                    "makerAddr": "0x714188baE91548cc3f9a02407A487FD8316A9640",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "2345",
                    "listingTime": 1651417709,
                    "expirationTime": 1653924638,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2345",
                    "makerAddr": "0xD05Defee18E718F21337F5355317f915a34A101B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "4352",
                    "listingTime": 1651508190,
                    "expirationTime": 1654100161,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4352",
                    "makerAddr": "0xe73e90fc400c0256Bc271C83e576ACb97603452b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "100",
                    "listingTime": 1651387321,
                    "expirationTime": 1653416589,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/100",
                    "makerAddr": "0xc9ca66FB145E146812De286aC78a533718c493d4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "16056",
                    "listingTime": 1650690281,
                    "expirationTime": 1666242212,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16056",
                    "makerAddr": "0x55A5A4b75B5B2E0c1FdFe5c5Fb67103C32f50013",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "3827",
                    "listingTime": 1651419196,
                    "expirationTime": 1653408280,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3827",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "8852",
                    "listingTime": 1651419614,
                    "expirationTime": 1653852863,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8852",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "5650",
                    "listingTime": 1651387320,
                    "expirationTime": 1653502779,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5650",
                    "makerAddr": "0x7b9CDCC6831796fCcB21b5f7241e2cEd813Efe92",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "9436",
                    "listingTime": 1651389233,
                    "expirationTime": 1653366760,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9436",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "23166",
                    "listingTime": 1651418253,
                    "expirationTime": 1654010197,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23166",
                    "makerAddr": "0x77d8d216380A532Bd3238dA63e3788e4150BcEd8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "6049",
                    "listingTime": 1651401972,
                    "expirationTime": 1653830600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6049",
                    "makerAddr": "0x2dA054af93D06Dc140Ef84403A67Ae7ceA89df1C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "24072",
                    "listingTime": 1651410800,
                    "expirationTime": 1654002791,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24072",
                    "makerAddr": "0xC8077C19E3b4dc188aD62a67dC3aEa216e8725BD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "1185",
                    "listingTime": 1652028792,
                    "expirationTime": 1652633588,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1185",
                    "makerAddr": "0x03f065A8786eCd7dcf0F995EAAE6e5131A7E492f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44000000000000000000",
                    "tokenId": "1935",
                    "listingTime": 1651409927,
                    "expirationTime": 1666885368,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1935",
                    "makerAddr": "0x7B220358c3EC9Ec03b08120DcCEC56F16Fbf33a4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1185",
                    "price": "44000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652028425,
                    "expirationTime": 1652633299,
                    "makerAddr": "0x03f065a8786ecd7dcf0f995eaae6e5131a7e492f",
                    "assetImageUrl": "https://img.seadn.io/files/1ff8e3ac99051e3e5c2c0648755ec398.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3256",
                    "price": "44000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651854895,
                    "expirationTime": 1654533378,
                    "makerAddr": "0xc7b72bb1acdce3eb45f9b38f43cd31e4927c725b",
                    "assetImageUrl": "https://img.seadn.io/files/9a33660c165cbbedb54c347723529be7.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44069000000000000000",
                    "tokenId": "7551",
                    "listingTime": 1651494137,
                    "expirationTime": 1654035948,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7551",
                    "makerAddr": "0x0af691F5533C2d1FBeca5Cb86705307f225F8bd3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44100000000000000000",
                    "tokenId": "2986",
                    "listingTime": 1651388489,
                    "expirationTime": 1652598080,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2986",
                    "makerAddr": "0x5e11534344B8C1fDa947B37Dc57B8734232a6B1C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44250000000000000000",
                    "tokenId": "5319",
                    "listingTime": 1651625614,
                    "expirationTime": 1654217591,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5319",
                    "makerAddr": "0x1ef1b95b3dD0d10B55C54aF4cB10B9f332AF9b1d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44250000000000000000",
                    "tokenId": "4340",
                    "listingTime": 1651388231,
                    "expirationTime": 1652597823,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4340",
                    "makerAddr": "0x5e11534344B8C1fDa947B37Dc57B8734232a6B1C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44440000000000000000",
                    "tokenId": "5175",
                    "listingTime": 1651408307,
                    "expirationTime": 1654000295,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5175",
                    "makerAddr": "0x0ade490244a34C69c1e31e7c2c95E20B42Daf988",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44440000000000000000",
                    "tokenId": "3252",
                    "listingTime": 1650790637,
                    "expirationTime": 1653382631,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3252",
                    "makerAddr": "0x6B92686c40747C85809a6772D0eda8e22a77C60c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44500000000000000000",
                    "tokenId": "23926",
                    "listingTime": 1651407968,
                    "expirationTime": 1653579875,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23926",
                    "makerAddr": "0xf1b1cF1a44CadE3d7a0962d61abDC475f1f3D093",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44500000000000000000",
                    "tokenId": "3083",
                    "listingTime": 1651408844,
                    "expirationTime": 1653414972,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3083",
                    "makerAddr": "0xC344FCe3710d5971b24b11947535FAcE3eCd5655",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44500000000000000000",
                    "tokenId": "6373",
                    "listingTime": 1651395887,
                    "expirationTime": 1653391192,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6373",
                    "makerAddr": "0xE9FbEbb23e5CCC02EEe6E751adA3f3BbD20C630D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44500000000000000000",
                    "tokenId": "323",
                    "listingTime": 1651218279,
                    "expirationTime": 1658994272,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/323",
                    "makerAddr": "0x5A677d9871E673Cea7B4f947028477dE8084242f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44500000000000000000",
                    "tokenId": "4537",
                    "listingTime": 1651410146,
                    "expirationTime": 1653958648,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4537",
                    "makerAddr": "0xEd041E633f0e86500c1c25CeB75a5D4c7cb6fFf0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2218",
                    "price": "44500000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651894240,
                    "expirationTime": 1654181930,
                    "makerAddr": "0x49ca963ef75bceba8e4a5f4ceab5fd326bef6123",
                    "assetImageUrl": "https://img.seadn.io/files/99eb230a4f93e87a783ae500a8cde1bb.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44600000000000000000",
                    "tokenId": "1840",
                    "listingTime": 1651403639,
                    "expirationTime": 1653995626,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1840",
                    "makerAddr": "0x112D352E59ed2165052F2fB00e00D3B4B87552fD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1840",
                    "price": "44600000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651403662,
                    "expirationTime": 1654082138,
                    "makerAddr": "0x112d352e59ed2165052f2fb00e00d3b4b87552fd",
                    "assetImageUrl": "https://img.seadn.io/files/3408e59faa905a3cecc3c7679c774c65.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44700000000000000000",
                    "tokenId": "17649",
                    "listingTime": 1652162322,
                    "expirationTime": 1652770362,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17649",
                    "makerAddr": "0x0B4f05137beaE5d828b0649DC6ba184572D5480C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44800000000000000000",
                    "tokenId": "15106",
                    "listingTime": 1651417868,
                    "expirationTime": 1653463301,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15106",
                    "makerAddr": "0xc741EB22eD5500DdFb587D7bE04d22c0aDe3851C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44800000000000000000",
                    "tokenId": "14297",
                    "listingTime": 1651385403,
                    "expirationTime": 1653565894,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14297",
                    "makerAddr": "0x517b1c4B8C259255EE7b7aB85e6Ea0dc9a235c3E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "44888000000000000000",
                    "tokenId": "2994",
                    "listingTime": 1651353512,
                    "expirationTime": 1653945424,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2994",
                    "makerAddr": "0x4eD9c6193ede88A5D41b2E833E46508800420780",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "tokenId": "25340@-@25256@-@6927@-@24896@-@3176@-@3364@-@8201@-@9725@-@12630@-@17492@-@21640@-@22488@-@24406@-@5512@-@25706@-@833@-@29676@-@16656",
                    "price": 44892762080067880000,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1281",
                    "price": "44900000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651386990,
                    "expirationTime": 1653974258,
                    "makerAddr": "0x0489726421f3b7b3eb2ff98b29c6cfc5aa54f3e2",
                    "assetImageUrl": "https://img.seadn.io/files/e6a72dd5fec8a6243d9d28a20a0e8820.png?auto=format"
                }
            ],
            "total": 2.122729762080067e+21,
            "looksrare": 42,
            "opensea": 5,
            "nftx": 1
        },
        {
            "price": 45,
            "amount": 55,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "18710",
                    "listingTime": 1651952961,
                    "expirationTime": 1655495340,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18710",
                    "makerAddr": "0x1F525122D520a5ee513bC03ECcAB3d66646e7E6e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "14208",
                    "listingTime": 1651815419,
                    "expirationTime": 1654407386,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14208",
                    "makerAddr": "0x02954549A3E81884f020607D6A7D30Eea96008Ba",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "1244",
                    "listingTime": 1651493288,
                    "expirationTime": 1652702875,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1244",
                    "makerAddr": "0x73f7AB72AbA02A933f51168F36b2Fa0BC7d75c99",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "25852",
                    "listingTime": 1651559116,
                    "expirationTime": 1654150873,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25852",
                    "makerAddr": "0x309caB02E85817660F3f66855B5cA51312264C7D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "9199",
                    "listingTime": 1651413480,
                    "expirationTime": 1653449081,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9199",
                    "makerAddr": "0x99d73D76f0058F4ae24882A49A0a9eA0f5a41268",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "20674",
                    "listingTime": 1651401938,
                    "expirationTime": 1653830600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20674",
                    "makerAddr": "0x2dA054af93D06Dc140Ef84403A67Ae7ceA89df1C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "14702",
                    "listingTime": 1651675310,
                    "expirationTime": 1652280110,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14702",
                    "makerAddr": "0xFdD766042D95A47800D500F71e0f55267c783f50",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "1181",
                    "listingTime": 1650494027,
                    "expirationTime": 1666045973,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1181",
                    "makerAddr": "0x9a797c3fF687fE8C8F597d53f46e47625F445a2d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "7946",
                    "listingTime": 1650493192,
                    "expirationTime": 1653085154,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7946",
                    "makerAddr": "0xD88d4F99ADC42A57e5949c94fDd984f43811f344",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "7455",
                    "listingTime": 1651431648,
                    "expirationTime": 1653408852,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7455",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "552",
                    "listingTime": 1651797444,
                    "expirationTime": 1652402242,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/552",
                    "makerAddr": "0xc71974724C4B009c65c722AF061900d7D26F5Fed",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "229",
                    "listingTime": 1651384854,
                    "expirationTime": 1653445649,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/229",
                    "makerAddr": "0x4b58F249B2B390125158237ec350C54E690AF477",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "8550",
                    "listingTime": 1651522829,
                    "expirationTime": 1652728048,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8550",
                    "makerAddr": "0x3677d0371F3217a054d4C40D43cab80fa9F7CBED",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "6049",
                    "listingTime": 1651401924,
                    "expirationTime": 1653830600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6049",
                    "makerAddr": "0x2dA054af93D06Dc140Ef84403A67Ae7ceA89df1C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "8489",
                    "listingTime": 1650493377,
                    "expirationTime": 1653085368,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8489",
                    "makerAddr": "0xD88d4F99ADC42A57e5949c94fDd984f43811f344",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "3008",
                    "listingTime": 1651815427,
                    "expirationTime": 1654407386,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3008",
                    "makerAddr": "0x02954549A3E81884f020607D6A7D30Eea96008Ba",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "5446",
                    "listingTime": 1650611469,
                    "expirationTime": 1653203355,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5446",
                    "makerAddr": "0x1B90c95Cde4c33d6b30721FD4B0cdDE3176f9391",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "16015",
                    "listingTime": 1651952928,
                    "expirationTime": 1655495280,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16015",
                    "makerAddr": "0x1F525122D520a5ee513bC03ECcAB3d66646e7E6e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "6793",
                    "listingTime": 1650596578,
                    "expirationTime": 1653188568,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6793",
                    "makerAddr": "0x253a7D6b33077450Af8c5F51582f08cF5BdF2fB0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "422",
                    "listingTime": 1651441249,
                    "expirationTime": 1654033176,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/422",
                    "makerAddr": "0x4f592C357436E68e754D98E2495788F3e3cade7A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "15554",
                    "listingTime": 1650794533,
                    "expirationTime": 1653386528,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15554",
                    "makerAddr": "0xc20490E8306257Df636E23BE02a291B553f341EC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "29049",
                    "listingTime": 1651847879,
                    "expirationTime": 1654090388,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29049",
                    "makerAddr": "0x9f8534b8d266C136d52428Ec0647fa0F02EBF377",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "7818",
                    "listingTime": 1651796549,
                    "expirationTime": 1654388408,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7818",
                    "makerAddr": "0xA89C95f3b69c6435fa022fcE66EFa46289E24476",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "26618",
                    "listingTime": 1652104650,
                    "expirationTime": 1659880637,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26618",
                    "makerAddr": "0xD204e260ac2b6295298151E6AaadC2C6aF29f780",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "14224",
                    "listingTime": 1650468050,
                    "expirationTime": 1653059954,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14224",
                    "makerAddr": "0x8611bF824EfDf0e0F675D7Fc26a8745342a77Ee5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "16606",
                    "listingTime": 1651848688,
                    "expirationTime": 1654008420,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16606",
                    "makerAddr": "0x6F7DeA27e1f6f2727FE70a3D2c39c9b7F40C75A2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "2634",
                    "listingTime": 1651962571,
                    "expirationTime": 1656100454,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2634",
                    "makerAddr": "0xfAD18cC656a0261c154647f125cFFED27Fbfc083",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "18780",
                    "listingTime": 1650487804,
                    "expirationTime": 1653079735,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18780",
                    "makerAddr": "0x786F36cd772b831D28AecC4Ba1823Bdec190D72C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "10279",
                    "listingTime": 1651781496,
                    "expirationTime": 1652386285,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10279",
                    "makerAddr": "0x87885AaEEdED51C7e3858a782644F5d89759f245",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "11381",
                    "listingTime": 1652038391,
                    "expirationTime": 1652643185,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11381",
                    "makerAddr": "0x95dFDF0306945282016eb50212edf82054c00F96",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "16418",
                    "listingTime": 1651675325,
                    "expirationTime": 1652280124,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16418",
                    "makerAddr": "0xFdD766042D95A47800D500F71e0f55267c783f50",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "13504",
                    "listingTime": 1650561338,
                    "expirationTime": 1653153148,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13504",
                    "makerAddr": "0xC912232B5d0AcBB2BDcd3F1ED747DbBdC414D659",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "24940",
                    "listingTime": 1651675295,
                    "expirationTime": 1652280094,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24940",
                    "makerAddr": "0xFdD766042D95A47800D500F71e0f55267c783f50",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "5571",
                    "listingTime": 1651447378,
                    "expirationTime": 1653444739,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5571",
                    "makerAddr": "0x4b58F249B2B390125158237ec350C54E690AF477",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "19120",
                    "listingTime": 1651409331,
                    "expirationTime": 1654001277,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19120",
                    "makerAddr": "0xA6cCDf057ffB80Bc91474FbB11Fc53D26944Ff32",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "11575",
                    "listingTime": 1652040149,
                    "expirationTime": 1652299336,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11575",
                    "makerAddr": "0x40b060A0Ac95dB3D5211b687511632b46c5d3bB7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "8490",
                    "listingTime": 1650493384,
                    "expirationTime": 1653085368,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8490",
                    "makerAddr": "0xD88d4F99ADC42A57e5949c94fDd984f43811f344",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "6527",
                    "listingTime": 1651407755,
                    "expirationTime": 1652617220,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6527",
                    "makerAddr": "0x9297F8621A6C3c6B020b4Baa32F0dD4770eaDC52",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45000000000000000000",
                    "tokenId": "13124",
                    "listingTime": 1651392587,
                    "expirationTime": 1654070904,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13124",
                    "makerAddr": "0x8010a53664c7A691968E7E87515cCA84B8c49a8A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "455",
                    "price": "45000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651779809,
                    "expirationTime": 1653451130,
                    "makerAddr": "0x8dbb75c576b71b43eea54398f8606aec530181dc",
                    "assetImageUrl": "https://img.seadn.io/files/c9b78bb751e4ebc2c140826429656399.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "552",
                    "price": "45000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651797436,
                    "expirationTime": 1652402236,
                    "makerAddr": "0xc71974724c4b009c65c722af061900d7d26f5fed",
                    "assetImageUrl": "https://img.seadn.io/files/6ddd3f40b0df10bfceccc601febe8b49.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1136",
                    "price": "45000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651752867,
                    "expirationTime": 1652357750,
                    "makerAddr": "0x129f5058afa493fc262f4dfad734daa5b3ddee1c",
                    "assetImageUrl": "https://img.seadn.io/files/48692b63796be61c8d85293af21b888b.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1255",
                    "price": "45000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652145977,
                    "expirationTime": 1652232453,
                    "makerAddr": "0x8d1db0c2fce84a914c7d9bc291980d79d0d8e8c3",
                    "assetImageUrl": "https://img.seadn.io/files/513d3b4f678595dea72975c5e5fb985a.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1340",
                    "price": "45000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651432670,
                    "expirationTime": 1654111161,
                    "makerAddr": "0xda75f63b9cb1cf7668bb0ee34fa718bc1cb5bbc1",
                    "assetImageUrl": "https://img.seadn.io/files/c2eaddef7261cc86fc69cd7dcd44a943.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1532",
                    "price": "45000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651426621,
                    "expirationTime": 1654105107,
                    "makerAddr": "0x8a677474dec43fcaeb80a2dd2b1bc5d945ab61d1",
                    "assetImageUrl": "https://img.seadn.io/files/f60dcca33731cafe89f82b4238116a11.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1751",
                    "price": "45000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652095310,
                    "expirationTime": 1654773779,
                    "makerAddr": "0xd591b27bf7742348c7d63fb8165facfee492af6c",
                    "assetImageUrl": "https://img.seadn.io/files/f9b0ee6cf939fa1abffc1b6e2ee29ee7.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1791",
                    "price": "45000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651525600,
                    "expirationTime": 1654204068,
                    "makerAddr": "0xdcab864808ee2277880f3b56af81f594a7ab656e",
                    "assetImageUrl": "https://img.seadn.io/files/d42f621f996764cee59cacc4fcc380b2.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2609",
                    "price": "45000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651956631,
                    "expirationTime": 1652215912,
                    "makerAddr": "0xd54117b9735881d9800655807fcb9b49184e76a4",
                    "assetImageUrl": "https://img.seadn.io/files/329ea111050af7485518acf97629a048.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2634",
                    "price": "45000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651962428,
                    "expirationTime": 1656100454,
                    "makerAddr": "0xfad18cc656a0261c154647f125cffed27fbfc083",
                    "assetImageUrl": "https://img.seadn.io/files/583ac76a953e25d9b1589a9b5282859f.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3350",
                    "price": "45000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651600509,
                    "expirationTime": 1652205392,
                    "makerAddr": "0xc27091475944fb7bf417aaaeb2d3ec59443ea013",
                    "assetImageUrl": "https://img.seadn.io/files/f0d774503dc02a773bb2b2eb0716206f.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3351",
                    "price": "45000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651382195,
                    "expirationTime": 1654060685,
                    "makerAddr": "0xdbb3f1bdc0353eba1c57dce84fc6a5c3975b321f",
                    "assetImageUrl": "https://img.seadn.io/files/80606b9b21ccacc0f3deb97b211a2108.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45550000000000000000",
                    "tokenId": "11064",
                    "listingTime": 1651383950,
                    "expirationTime": 1653405091,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11064",
                    "makerAddr": "0x5fB998B8024E38867558fD5E2b87B6D13Da730f8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "45690000000000000000",
                    "tokenId": "18572",
                    "listingTime": 1651809029,
                    "expirationTime": 1652413777,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18572",
                    "makerAddr": "0x0af853431830A330694a5eAeB56E6CC958dF1DC8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3095",
                    "price": "45690000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651774675,
                    "expirationTime": 1657675551,
                    "makerAddr": "0xb2f4b652553f6d39dc07366367b28d7aa81b9e32",
                    "assetImageUrl": "https://img.seadn.io/files/33d4182f90f117904a807820b5c3f86a.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2528",
                    "price": "45950000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651453799,
                    "expirationTime": 1654132292,
                    "makerAddr": "0xaa621b960f22911462550c078df678493c22b2ae",
                    "assetImageUrl": "https://img.seadn.io/files/e64513b7f5c497f1e6103eb0d31b6a8b.png?auto=format"
                }
            ],
            "total": 2.4778799999999997e+21,
            "looksrare": 41,
            "opensea": 14
        },
        {
            "price": 46,
            "amount": 11,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "46000000000000000000",
                    "tokenId": "14654",
                    "listingTime": 1650469499,
                    "expirationTime": 1653061437,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14654",
                    "makerAddr": "0x37b45293Aaf561a2CdF9E260Bf984b643Dd3699A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "46000000000000000000",
                    "tokenId": "4112",
                    "listingTime": 1651394188,
                    "expirationTime": 1653986162,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4112",
                    "makerAddr": "0x4593d72b3A7CD192e684a167a315208aae9FCCc8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "46000000000000000000",
                    "tokenId": "1669",
                    "listingTime": 1650916485,
                    "expirationTime": 1653508441,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1669",
                    "makerAddr": "0x5894F97466030c2455c89BFB1b93Fdd6e28b085D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "46000000000000000000",
                    "tokenId": "4343",
                    "listingTime": 1651384340,
                    "expirationTime": 1653976313,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4343",
                    "makerAddr": "0x7D9063f8EC74588cea572F5E6298E2b2645Ca887",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "46690000000000000000",
                    "tokenId": "14261",
                    "listingTime": 1652180822,
                    "expirationTime": 1652267214,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14261",
                    "makerAddr": "0xc73452eC2A7aBC1462959C227B83F3F7547b531B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "46690000000000000000",
                    "tokenId": "7551",
                    "listingTime": 1651459942,
                    "expirationTime": 1654035948,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7551",
                    "makerAddr": "0x0af691F5533C2d1FBeca5Cb86705307f225F8bd3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "46690000000000000000",
                    "tokenId": "2015",
                    "listingTime": 1650790406,
                    "expirationTime": 1653382378,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2015",
                    "makerAddr": "0x6B92686c40747C85809a6772D0eda8e22a77C60c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "46690000000000000000",
                    "tokenId": "25974",
                    "listingTime": 1651262529,
                    "expirationTime": 1652472121,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25974",
                    "makerAddr": "0x6B92686c40747C85809a6772D0eda8e22a77C60c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "46800000000000000000",
                    "tokenId": "468",
                    "listingTime": 1651507148,
                    "expirationTime": 1652716739,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/468",
                    "makerAddr": "0x3Ee3d0cceF50bc9a6Ac43e763180C0c870fd2787",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1496",
                    "price": "46900000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651706081,
                    "expirationTime": 1654384558,
                    "makerAddr": "0xe4bc73a45c9892b56d16e3a21aae062b7a7035ce",
                    "assetImageUrl": "https://img.seadn.io/files/1a7e3b3ff969bbb366b1254b7c1179e6.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "46942000000000000000",
                    "tokenId": "19780",
                    "listingTime": 1651862734,
                    "expirationTime": 1654540556,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19780",
                    "makerAddr": "0xaC0f76A8c30E23bc197D26465e7bE319713c5224",
                    "assetImageUrl": ""
                }
            ],
            "total": 511402000000000000000,
            "looksrare": 10,
            "opensea": 1
        },
        {
            "price": 47,
            "amount": 14,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "47000000000000000000",
                    "tokenId": "9818",
                    "listingTime": 1650568725,
                    "expirationTime": 1653160638,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9818",
                    "makerAddr": "0x48861410a2B3A0Ca94d4055aED35288Bd00F8bAE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "47000000000000000000",
                    "tokenId": "19374",
                    "listingTime": 1650623925,
                    "expirationTime": 1653215811,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19374",
                    "makerAddr": "0xaf929AFd0f8B3353fdD04A0B955F3F89034e617e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "47000000000000000000",
                    "tokenId": "8807",
                    "listingTime": 1650469938,
                    "expirationTime": 1653060759,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8807",
                    "makerAddr": "0xec385C6A991CAd9fE23e5B855526e1E44a1d5b9f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "47000000000000000000",
                    "tokenId": "8950",
                    "listingTime": 1650623918,
                    "expirationTime": 1653215811,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8950",
                    "makerAddr": "0xaf929AFd0f8B3353fdD04A0B955F3F89034e617e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "47000000000000000000",
                    "tokenId": "21295",
                    "listingTime": 1652115455,
                    "expirationTime": 1652380788,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21295",
                    "makerAddr": "0x5054A3283e57Cc1bd1109F596a0a5a31887D56FB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "47500000000000000000",
                    "tokenId": "2570",
                    "listingTime": 1651853619,
                    "expirationTime": 1653413357,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2570",
                    "makerAddr": "0x685Cb9E2035d915331379Dcbf09D18084f126bA7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "47500000000000000000",
                    "tokenId": "2776",
                    "listingTime": 1651242718,
                    "expirationTime": 1653834710,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2776",
                    "makerAddr": "0x2DFCC264b08a181BC7c38b9901059Bab369a5a4e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2570",
                    "price": "47500000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651495387,
                    "expirationTime": 1653406542,
                    "makerAddr": "0x685cb9e2035d915331379dcbf09d18084f126ba7",
                    "assetImageUrl": "https://img.seadn.io/files/1353393d7b3d0a6d9cd9179ec97a94dc.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2845",
                    "price": "47500000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651453714,
                    "expirationTime": 1654132207,
                    "makerAddr": "0xaa621b960f22911462550c078df678493c22b2ae",
                    "assetImageUrl": "https://img.seadn.io/files/531ac52e66dec4f34b7ed9ef6d7b58b8.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "47620000000000000000",
                    "tokenId": "3817",
                    "listingTime": 1650568507,
                    "expirationTime": 1658344451,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3817",
                    "makerAddr": "0xFe6eC7350dE7742FAD9717545bB46cC9a7abCeCB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "47690000000000000000",
                    "tokenId": "8395",
                    "listingTime": 1650820302,
                    "expirationTime": 1653412294,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8395",
                    "makerAddr": "0x4df2CDB9AdDc77f7f198b26deE5E77f4278bf28f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "47690000000000000000",
                    "tokenId": "7551",
                    "listingTime": 1651444059,
                    "expirationTime": 1654035948,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7551",
                    "makerAddr": "0x0af691F5533C2d1FBeca5Cb86705307f225F8bd3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "47750000000000000000",
                    "tokenId": "27260",
                    "listingTime": 1650552864,
                    "expirationTime": 1653144752,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27260",
                    "makerAddr": "0x96a3ba33255a96634E98D108cc111DDC87376d7a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "47920000000000000000",
                    "tokenId": "3349",
                    "listingTime": 1650497639,
                    "expirationTime": 1653089554,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3349",
                    "makerAddr": "0x604eB5D4126E3318EC27721Bd5059307684F5C89",
                    "assetImageUrl": ""
                }
            ],
            "total": 663670000000000000000,
            "looksrare": 12,
            "opensea": 2
        },
        {
            "price": 48,
            "amount": 19,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "48000000000000000000",
                    "tokenId": "1501",
                    "listingTime": 1650805814,
                    "expirationTime": 1653397798,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1501",
                    "makerAddr": "0xE3b9C10706659eAE046A30E90e676117aF0C38AA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "48000000000000000000",
                    "tokenId": "19146",
                    "listingTime": 1650620993,
                    "expirationTime": 1653212985,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19146",
                    "makerAddr": "0x3c145Da6cbbB4F391CC23AaBd979656362A9BB9B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "48000000000000000000",
                    "tokenId": "21425",
                    "listingTime": 1652010484,
                    "expirationTime": 1652269678,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21425",
                    "makerAddr": "0xF8E6992a9448ED888d99198E441c2777FBB799dE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "48000000000000000000",
                    "tokenId": "1781",
                    "listingTime": 1651863122,
                    "expirationTime": 1652467906,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1781",
                    "makerAddr": "0x2D1B780304bB9C7C3937BfEc89E0a55DAd12a35E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "48000000000000000000",
                    "tokenId": "3946",
                    "listingTime": 1652172201,
                    "expirationTime": 1659948063,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3946",
                    "makerAddr": "0x9745FDb2ff05941f388B2dEE475111fd922fcCfE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "48000000000000000000",
                    "tokenId": "18926",
                    "listingTime": 1651485067,
                    "expirationTime": 1667037041,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18926",
                    "makerAddr": "0x6522A8Bd72eB221e6a8C6ddcAE42ba767Ca4dE56",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "48000000000000000000",
                    "tokenId": "7477",
                    "listingTime": 1651451834,
                    "expirationTime": 1652661412,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7477",
                    "makerAddr": "0x1b16B382027b4DA128f6e8A57eC5C522FA0bE747",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "48000000000000000000",
                    "tokenId": "7455",
                    "listingTime": 1651421390,
                    "expirationTime": 1653408852,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7455",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "48000000000000000000",
                    "tokenId": "10032",
                    "listingTime": 1650759505,
                    "expirationTime": 1653351474,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10032",
                    "makerAddr": "0x5126bEd5a2B3619D5e5afab8d6c888004AA5e8C7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "48000000000000000000",
                    "tokenId": "5571",
                    "listingTime": 1651384695,
                    "expirationTime": 1653444739,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5571",
                    "makerAddr": "0x4b58F249B2B390125158237ec350C54E690AF477",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "48000000000000000000",
                    "tokenId": "13056",
                    "listingTime": 1651190947,
                    "expirationTime": 1653782871,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13056",
                    "makerAddr": "0xe315eB04Fc42bEa83Ed213582e66051050A92F1c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "48000000000000000000",
                    "tokenId": "17254",
                    "listingTime": 1651666210,
                    "expirationTime": 1654258164,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17254",
                    "makerAddr": "0x5BF417C296e232b96ec11160C4955F43C07b6f7b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "867",
                    "price": "48000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650175743,
                    "expirationTime": 1652767833,
                    "makerAddr": "0xbc2a21a50d88e0a48b75c0a5516ae51bfa45ce6e",
                    "assetImageUrl": "https://img.seadn.io/files/d21318821dd496c592741ac8345a0705.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3134",
                    "price": "48000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652050014,
                    "expirationTime": 1652654902,
                    "makerAddr": "0x70001e0d068fce00492c86d64fe20e8b326c8f5c",
                    "assetImageUrl": "https://img.seadn.io/files/b0670fd710c9a70ff9fdf19025f7657c.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "48100000000000000000",
                    "tokenId": "19258",
                    "listingTime": 1650501099,
                    "expirationTime": 1653093051,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19258",
                    "makerAddr": "0x038C87F764cCD44A4d2bC97F6Cf02C80bA6807e0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "48300000000000000000",
                    "tokenId": "2149",
                    "listingTime": 1650556906,
                    "expirationTime": 1653148825,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2149",
                    "makerAddr": "0x67a49a456Bc5d72f8670dDA8bD8efa83c8691e01",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "48690000000000000000",
                    "tokenId": "5640",
                    "listingTime": 1650135936,
                    "expirationTime": 1652727840,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5640",
                    "makerAddr": "0x54950EEf3B0e890D0bf17Fdbbdee8e4920BE7c13",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "48850000000000000000",
                    "tokenId": "6409",
                    "listingTime": 1652048743,
                    "expirationTime": 1652653543,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6409",
                    "makerAddr": "0x8BF7aCA815e43447fA0c26C15B9866EA508199f6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1566",
                    "price": "48880000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651414629,
                    "expirationTime": 1666966705,
                    "makerAddr": "0xfcd57d54d609bd84681abfddc7c9c14b3635cb7a",
                    "assetImageUrl": "https://img.seadn.io/files/5269efe2222c94105f3886cb3e04c9cc.png?auto=format"
                }
            ],
            "total": 914819999999999900000,
            "looksrare": 16,
            "opensea": 3
        },
        {
            "price": 49,
            "amount": 26,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "49000000000000000000",
                    "tokenId": "16358",
                    "listingTime": 1651398379,
                    "expirationTime": 1666646522,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16358",
                    "makerAddr": "0x46A13b188365C40E5F4114a4CBd0D2425472d8D2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "49000000000000000000",
                    "tokenId": "9098",
                    "listingTime": 1652044569,
                    "expirationTime": 1654483227,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9098",
                    "makerAddr": "0x3Bb3c1ca15FF712c80c20e99d157597746304c52",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "49000000000000000000",
                    "tokenId": "9874",
                    "listingTime": 1650662069,
                    "expirationTime": 1652727995,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9874",
                    "makerAddr": "0x54950EEf3B0e890D0bf17Fdbbdee8e4920BE7c13",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "49000000000000000000",
                    "tokenId": "5658",
                    "listingTime": 1650665134,
                    "expirationTime": 1653256997,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5658",
                    "makerAddr": "0xB4B000628E8e9e4391DF97052792F349aB9F593E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "49000000000000000000",
                    "tokenId": "9225",
                    "listingTime": 1650818399,
                    "expirationTime": 1653410372,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9225",
                    "makerAddr": "0x9EFB768C0aCe889ebae30A929AbC3607d8536AaC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "49000000000000000000",
                    "tokenId": "7960",
                    "listingTime": 1651277890,
                    "expirationTime": 1653869864,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7960",
                    "makerAddr": "0xED525b5D5CD5BE436c026ee127A85A682213ae3B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "49000000000000000000",
                    "tokenId": "9567",
                    "listingTime": 1650694414,
                    "expirationTime": 1653286246,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9567",
                    "makerAddr": "0x2f27be19d682f21D037e7072C1b22b7d98a276B6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "49000000000000000000",
                    "tokenId": "11038",
                    "listingTime": 1650984189,
                    "expirationTime": 1653576112,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11038",
                    "makerAddr": "0xbE3418aE6D38A6B6927704600D258cD353A94215",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "49000000000000000000",
                    "tokenId": "22047",
                    "listingTime": 1651621757,
                    "expirationTime": 1654213661,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22047",
                    "makerAddr": "0xDe112B77A1a72c726D8C914c23dCEaA53273c351",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "49000000000000000000",
                    "tokenId": "15345",
                    "listingTime": 1650806152,
                    "expirationTime": 1653398011,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15345",
                    "makerAddr": "0xDf231A4559C5DfaC89cCf796D203BD470EE8549a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "49000000000000000000",
                    "tokenId": "4033",
                    "listingTime": 1650531395,
                    "expirationTime": 1653123348,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4033",
                    "makerAddr": "0xF9DC6D3885ff2c31FA6B69D4Fc588306885431FF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "49000000000000000000",
                    "tokenId": "22996",
                    "listingTime": 1651168900,
                    "expirationTime": 1653760881,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22996",
                    "makerAddr": "0x7082dB9Ce4f520253217d8EB1fD6b6097B74c483",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "841",
                    "price": "49000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652197885,
                    "expirationTime": 1654876370,
                    "makerAddr": "0xe369d2f6f5b920b85d456d0707f4cb9e2b4b52b8",
                    "assetImageUrl": "https://img.seadn.io/files/5107d96ed2920b31dabba04db9f9fe19.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1976",
                    "price": "49000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651513158,
                    "expirationTime": 1654105256,
                    "makerAddr": "0xc7a0773924777dab183743fd8e3b859e95f0c5b4",
                    "assetImageUrl": "https://img.seadn.io/files/f3483c3a5368cb011718b7a8af2d180e.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1893",
                    "price": "49500000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651660164,
                    "expirationTime": 1654338656,
                    "makerAddr": "0x17cfef5635724c1f69fd3dd9772c93b29f8fadaa",
                    "assetImageUrl": "https://img.seadn.io/files/88962fae891e4d12fd74d4665dc93361.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "49690000000000000000",
                    "tokenId": "4816",
                    "listingTime": 1652044481,
                    "expirationTime": 1652646120,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4816",
                    "makerAddr": "0xd7D941ff2890BdA98F40A5DDA0593D239a603F09",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3099",
                    "price": "49690000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651863649,
                    "expirationTime": 1653073317,
                    "makerAddr": "0xe3ff4aa7134d8568813a7f5f3622462d512904f0",
                    "assetImageUrl": "https://img.seadn.io/files/7c005dcdf07ed9918d38cc31e9d83aa2.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "49699000000000000000",
                    "tokenId": "12531",
                    "listingTime": 1650875486,
                    "expirationTime": 1653467422,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12531",
                    "makerAddr": "0x4BC6fE8c58926f64388EE522757774C97787BCE0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "49900000000000000000",
                    "tokenId": "21958",
                    "listingTime": 1651831549,
                    "expirationTime": 1654423528,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21958",
                    "makerAddr": "0xE0d751fC845fA0191dA155787Fc62C56490e8686",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "49900000000000000000",
                    "tokenId": "26010",
                    "listingTime": 1651550861,
                    "expirationTime": 1654142825,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26010",
                    "makerAddr": "0x5Af278B1C423a320425Cd46f3f6E9C08c814bf86",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "49900000000000000000",
                    "tokenId": "11935",
                    "listingTime": 1652190986,
                    "expirationTime": 1652450177,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11935",
                    "makerAddr": "0x957a32E2aaC364B8A251f288486d2Bd91FA512D1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1503",
                    "price": "49900000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651839959,
                    "expirationTime": 1654518439,
                    "makerAddr": "0xbce2f2a6b2c6829893f7cda5ae65edc06f3b2e53",
                    "assetImageUrl": "https://img.seadn.io/files/beaf2a1e44d22c35de845bdc2133afe9.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "49990000000000000000",
                    "tokenId": "16732",
                    "listingTime": 1651387432,
                    "expirationTime": 1653979047,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16732",
                    "makerAddr": "0x298d5537C181766D22a27D40b90A9c8ccA5b5aD5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "640",
                    "price": "49990000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652058868,
                    "expirationTime": 1652663748,
                    "makerAddr": "0x9273eb21bc9854d0e06432b5b8c7634ba824ae49",
                    "assetImageUrl": "https://img.seadn.io/files/279d1c8993974482dc7d76b876fc1ba3.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2267",
                    "price": "49990000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652140036,
                    "expirationTime": 1666764854,
                    "makerAddr": "0x672cf94e8fb4230983650ff2787db09ae5a7860e",
                    "assetImageUrl": "https://img.seadn.io/files/0d0eef858582b0cc3f82743a918daa18.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3377",
                    "price": "49990000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1648459542,
                    "expirationTime": 1664011621,
                    "makerAddr": "0xcc981ad3c5260efdeaa034fb63ec9b241cecfd27",
                    "assetImageUrl": "https://img.seadn.io/files/5322d0190cac6982558c32278d7580bb.png?auto=format"
                }
            ],
            "total": 1.2841390000000003e+21,
            "looksrare": 18,
            "opensea": 8
        },
        {
            "price": 50,
            "amount": 61,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "849",
                    "listingTime": 1651032483,
                    "expirationTime": 1653624466,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/849",
                    "makerAddr": "0xaf469C4a0914938e6149CF621c54FB4b1EC0c202",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "2690",
                    "listingTime": 1651112179,
                    "expirationTime": 1653704177,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2690",
                    "makerAddr": "0x31f5afe73E35De36Ec0B99aB683883Af4DfC8Dce",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "7104",
                    "listingTime": 1651671485,
                    "expirationTime": 1654263383,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7104",
                    "makerAddr": "0xec374C2BeAa0F8856B8Cd96D3eDbCD6d0c76E991",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "9299",
                    "listingTime": 1651146837,
                    "expirationTime": 1653657671,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9299",
                    "makerAddr": "0xe7e4bfeEB83A5b752A4E90D4f89fa0B21FBB54b4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "5122",
                    "listingTime": 1650955883,
                    "expirationTime": 1653547870,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5122",
                    "makerAddr": "0xc3462CDbafa34e27901885E08931d659E3D3FfCF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "28966",
                    "listingTime": 1651255097,
                    "expirationTime": 1653847041,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28966",
                    "makerAddr": "0x6F1f99d3F555B92F282629512fD56cBD81324182",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "9120",
                    "listingTime": 1650809175,
                    "expirationTime": 1653401169,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9120",
                    "makerAddr": "0x9f2301960dF3D3c97D891362d716F42e93D35D1E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "13450",
                    "listingTime": 1651700959,
                    "expirationTime": 1653857576,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13450",
                    "makerAddr": "0xEBFb3B263f11BB1A9D6b14CaDE9A5E6C8EA4e07c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "9961",
                    "listingTime": 1651049148,
                    "expirationTime": 1666407713,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9961",
                    "makerAddr": "0x70BAb06BA6b16387Ceb09B53F3c74D8809E3051a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "26602",
                    "listingTime": 1650967739,
                    "expirationTime": 1653559337,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26602",
                    "makerAddr": "0xAd31c65d25Aad616479DEcdbc746efd597219d14",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "4964",
                    "listingTime": 1651486550,
                    "expirationTime": 1654078313,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4964",
                    "makerAddr": "0x5559CE0745cBcFdF8Ef968d6e04a0fc791d44983",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "8550",
                    "listingTime": 1651518514,
                    "expirationTime": 1652728048,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8550",
                    "makerAddr": "0x3677d0371F3217a054d4C40D43cab80fa9F7CBED",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "20684",
                    "listingTime": 1651059887,
                    "expirationTime": 1653648255,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20684",
                    "makerAddr": "0x69b38da50A977Dc61C7571c9DC8192Ea1A884DCB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "800",
                    "listingTime": 1651358416,
                    "expirationTime": 1653950368,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/800",
                    "makerAddr": "0xC03BDA89a4CA08036058E6e111C4beacd7246051",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "20238",
                    "listingTime": 1650874918,
                    "expirationTime": 1666424984,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20238",
                    "makerAddr": "0x3aF3A432D194C16b68f1a4a96dC59d92b6e13b19",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "6682",
                    "listingTime": 1651417629,
                    "expirationTime": 1653398147,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6682",
                    "makerAddr": "0x71dfD2baB718F5bB30f1099494a68752B5d2f2e2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "9172",
                    "listingTime": 1650874894,
                    "expirationTime": 1666425045,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9172",
                    "makerAddr": "0x3aF3A432D194C16b68f1a4a96dC59d92b6e13b19",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "6160",
                    "listingTime": 1650855233,
                    "expirationTime": 1653447227,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6160",
                    "makerAddr": "0xed57aB560290A0609436cF78Cf8BA1f7BDcb4395",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "26877",
                    "listingTime": 1652111688,
                    "expirationTime": 1652716471,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26877",
                    "makerAddr": "0xa786137b469f6A5e41Cb753e52A8dA409fA989a5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "7455",
                    "listingTime": 1651419764,
                    "expirationTime": 1653408852,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7455",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "9516",
                    "listingTime": 1650807402,
                    "expirationTime": 1653399390,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9516",
                    "makerAddr": "0x1E7dff312eB06F1A314BF8e7FFb5CdDe32A1821B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "4874",
                    "listingTime": 1652105262,
                    "expirationTime": 1662049588,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4874",
                    "makerAddr": "0x39C4B7E09Bb76eB59FB817D90057413265C1Fe51",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "23356",
                    "listingTime": 1650999191,
                    "expirationTime": 1653591186,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23356",
                    "makerAddr": "0x8B9Cc341084337c0A5828BC0D5E20dADB197d991",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "11114",
                    "listingTime": 1651035873,
                    "expirationTime": 1653409494,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11114",
                    "makerAddr": "0xF83FC598fD45171d86f81ba032C46DC07aCc415f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "3409",
                    "listingTime": 1651223662,
                    "expirationTime": 1653815647,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3409",
                    "makerAddr": "0xedf8B0947769e7a4663A58900D287892e3B46a61",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "9222",
                    "listingTime": 1650759747,
                    "expirationTime": 1653351649,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9222",
                    "makerAddr": "0x57EAdd1811affBD06C03787CAA973E626C090c62",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "8580",
                    "listingTime": 1651214077,
                    "expirationTime": 1653806048,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8580",
                    "makerAddr": "0x80B23AeCB95535d09077E5FBe4b09805e2a9F58F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "5472",
                    "listingTime": 1646762451,
                    "expirationTime": 1662310823,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5472",
                    "makerAddr": "0x42f84b1f43E4213E337768E376dA87FF99A5b58b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "5690",
                    "listingTime": 1650759760,
                    "expirationTime": 1653351649,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5690",
                    "makerAddr": "0x57EAdd1811affBD06C03787CAA973E626C090c62",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "28104",
                    "listingTime": 1651035846,
                    "expirationTime": 1653409436,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28104",
                    "makerAddr": "0xF83FC598fD45171d86f81ba032C46DC07aCc415f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "20664",
                    "listingTime": 1651037405,
                    "expirationTime": 1653437727,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20664",
                    "makerAddr": "0x545a2eD169EaC188638763539D30951488a9c8F7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "9636",
                    "listingTime": 1651098713,
                    "expirationTime": 1653690618,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9636",
                    "makerAddr": "0x3D0f7fE2653eF7537F97f2393f06D0288083C1fA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "28496",
                    "listingTime": 1652193008,
                    "expirationTime": 1667748603,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28496",
                    "makerAddr": "0x62204b53b84d32A2220b079153B974F94cAE3a17",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "14874",
                    "listingTime": 1650838270,
                    "expirationTime": 1653430252,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14874",
                    "makerAddr": "0x0CFCD5950999F3f39A307Fd67E6442F9199EAe1A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "6423",
                    "listingTime": 1651035096,
                    "expirationTime": 1653627080,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6423",
                    "makerAddr": "0xdF41003BcdC9aa917Fdae801BD02b3167368D5CD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "9328",
                    "listingTime": 1650889983,
                    "expirationTime": 1653481973,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9328",
                    "makerAddr": "0x834c0017B118C8C7A4216eb504d865C0467FE1BC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "23174",
                    "listingTime": 1651164956,
                    "expirationTime": 1658677747,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23174",
                    "makerAddr": "0x7e2225B3e54A7ad0f2D009237a4f136C02F90a52",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "964",
                    "listingTime": 1651075943,
                    "expirationTime": 1653455855,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/964",
                    "makerAddr": "0x04C074ea23094feB7B343de3f4d2AB110e4C6Dd1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "20800",
                    "listingTime": 1651098740,
                    "expirationTime": 1653690618,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20800",
                    "makerAddr": "0x3D0f7fE2653eF7537F97f2393f06D0288083C1fA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "1917",
                    "listingTime": 1651958621,
                    "expirationTime": 1652563410,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1917",
                    "makerAddr": "0x6270D239aC3dd7bA9192367e50780031c82C6be1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "10482",
                    "listingTime": 1651735261,
                    "expirationTime": 1654327047,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10482",
                    "makerAddr": "0xA6CFb6472Ca56E3Cdd695996ea8D8bD747dE131e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "3126",
                    "listingTime": 1650805659,
                    "expirationTime": 1653397648,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3126",
                    "makerAddr": "0x135400Da57c70Eb39Bd6A48a452e5A9961e8FE04",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "2786",
                    "listingTime": 1650877873,
                    "expirationTime": 1653469756,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2786",
                    "makerAddr": "0xE1939Fe81A15D927D397f387C3B92bf5E38B6cAD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "16654",
                    "listingTime": 1651406864,
                    "expirationTime": 1653998863,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16654",
                    "makerAddr": "0x456a48B9060ac9d955A648516FD4Bb8d48042F5A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "28260",
                    "listingTime": 1650874933,
                    "expirationTime": 1666424948,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28260",
                    "makerAddr": "0x3aF3A432D194C16b68f1a4a96dC59d92b6e13b19",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "24674",
                    "listingTime": 1651859330,
                    "expirationTime": 1652464115,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24674",
                    "makerAddr": "0x1BF22a19a8d0B9cdFDd5c0859b5BD72c05F2d6a2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50000000000000000000",
                    "tokenId": "22334",
                    "listingTime": 1650815927,
                    "expirationTime": 1653407905,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22334",
                    "makerAddr": "0x1abEd56b27691Ba3b5c0c68D2B0Be0f1638e3D4f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "27",
                    "price": "50000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651759538,
                    "expirationTime": 1652340126,
                    "makerAddr": "0xeaf9f4f9afe7a1e61549fed36b4f0c4021a44a8a",
                    "assetImageUrl": "https://img.seadn.io/files/781251da647687bca6105ea0b38da047.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "151",
                    "price": "50000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651563097,
                    "expirationTime": 1654241578,
                    "makerAddr": "0x60cc0349d1151af21fd25f608429c63c6b1a9448",
                    "assetImageUrl": "https://img.seadn.io/files/75dc7f12c39f1ca24b4c64f347d134c6.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "171",
                    "price": "50000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652197445,
                    "expirationTime": 1654875878,
                    "makerAddr": "0x8551f533d244c928c6a0eceab2ee6ef265c3a987",
                    "assetImageUrl": "https://img.seadn.io/files/84dc60f7a47e7f5d9d7625998bcd6174.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "379",
                    "price": "50000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1647109833,
                    "expirationTime": 1662658308,
                    "makerAddr": "0xced3c2dd37083bc0acc311da0e2478e2fc58291a",
                    "assetImageUrl": "https://img.seadn.io/files/4cfba35b2c393c1c862de1341d984a73.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "598",
                    "price": "50000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651867971,
                    "expirationTime": 1654546450,
                    "makerAddr": "0x4ad24959fe564e00baabe672a6cf7c4e0ddcce38",
                    "assetImageUrl": "https://img.seadn.io/files/f33a67a37cd1a2447235659ffd74a80d.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1332",
                    "price": "50000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652164478,
                    "expirationTime": 1654842903,
                    "makerAddr": "0x1bcd8be7263e3c8ceb47c13a8aab9c6d493a3f2e",
                    "assetImageUrl": "https://img.seadn.io/files/64bdff69c986b4d6e29fc268f53f0efe.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2083",
                    "price": "50000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651437325,
                    "expirationTime": 1654115810,
                    "makerAddr": "0x03f0fa359582b4fff833ec2457b28f726e59f341",
                    "assetImageUrl": "https://img.seadn.io/files/df8f7ffbd8dd6349ee1c7888d64e712a.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2441",
                    "price": "50000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651764952,
                    "expirationTime": 1654443445,
                    "makerAddr": "0x1d20d0f28267e47cfbb2c247f2ca6527c7244495",
                    "assetImageUrl": "https://img.seadn.io/files/cc80fe3724d2dfc6e5d051a852e2a02c.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2459",
                    "price": "50000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652066853,
                    "expirationTime": 1652671735,
                    "makerAddr": "0x83338c070cf9bb70a0a360bbb9e508dd8e5076f8",
                    "assetImageUrl": "https://img.seadn.io/files/73eeef53ae178f567ee6c818f0a2e3ad.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2690",
                    "price": "50000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650226048,
                    "expirationTime": 1658002086,
                    "makerAddr": "0x31f5afe73e35de36ec0b99ab683883af4dfc8dce",
                    "assetImageUrl": "https://img.seadn.io/files/6bd3681413bb110daea54ab4a7332019.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3235",
                    "price": "50000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650864008,
                    "expirationTime": 1653456101,
                    "makerAddr": "0x12f37431468eb75c2a825e2cf8fde773ad94c8ea",
                    "assetImageUrl": "https://img.seadn.io/files/eb92a6bb41eff1a147c86a0ff5416923.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3295",
                    "price": "50000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651945791,
                    "expirationTime": 1667501469,
                    "makerAddr": "0x8948781a195bd268177552ba1babae2955c2d18d",
                    "assetImageUrl": "https://img.seadn.io/files/3959b7ec922a11e6ab7f35af08d97a65.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3323",
                    "price": "50000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650775582,
                    "expirationTime": 1653367670,
                    "makerAddr": "0x26c6b2de47b2129fedb4657c610e4b176cf5f294",
                    "assetImageUrl": "https://img.seadn.io/files/48e90c610ebf2bd39e169d4fbf26be15.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "50690000000000000000",
                    "tokenId": "17333",
                    "listingTime": 1651870749,
                    "expirationTime": 1653080335,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17333",
                    "makerAddr": "0xfB4FD288fb13Ee17A1c7d616bcF6fab5FC562285",
                    "assetImageUrl": ""
                }
            ],
            "total": 3.05069e+21,
            "looksrare": 48,
            "opensea": 13
        },
        {
            "price": 51,
            "amount": 21,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "51000000000000000000",
                    "tokenId": "6189",
                    "listingTime": 1651203945,
                    "expirationTime": 1652413514,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6189",
                    "makerAddr": "0xE82151c961E7f91839417a236a45E8E560655B1a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "51000000000000000000",
                    "tokenId": "5960",
                    "listingTime": 1652105243,
                    "expirationTime": 1659881234,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5960",
                    "makerAddr": "0xD204e260ac2b6295298151E6AaadC2C6aF29f780",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "51000000000000000000",
                    "tokenId": "5582",
                    "listingTime": 1651011941,
                    "expirationTime": 1653603931,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5582",
                    "makerAddr": "0xA25D7e569393583f2a17007b5619452E25fB40b9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "51000000000000000000",
                    "tokenId": "5650",
                    "listingTime": 1651042912,
                    "expirationTime": 1653502779,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5650",
                    "makerAddr": "0x7b9CDCC6831796fCcB21b5f7241e2cEd813Efe92",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "51000000000000000000",
                    "tokenId": "15688",
                    "listingTime": 1651168933,
                    "expirationTime": 1653471215,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15688",
                    "makerAddr": "0x7082dB9Ce4f520253217d8EB1fD6b6097B74c483",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "51000000000000000000",
                    "tokenId": "6049",
                    "listingTime": 1651238609,
                    "expirationTime": 1653830600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6049",
                    "makerAddr": "0x2dA054af93D06Dc140Ef84403A67Ae7ceA89df1C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "51000000000000000000",
                    "tokenId": "9710",
                    "listingTime": 1650778209,
                    "expirationTime": 1653370191,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9710",
                    "makerAddr": "0xf528a90CC2b35065e78518F77962eC884deb7230",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "51000000000000000000",
                    "tokenId": "6882",
                    "listingTime": 1650468029,
                    "expirationTime": 1653060022,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6882",
                    "makerAddr": "0xC10F5F82B7B0392DB11Bbf06CDE93Fc0aeA120Cb",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "51000000000000000000",
                    "tokenId": "5642",
                    "listingTime": 1651118429,
                    "expirationTime": 1658894306,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5642",
                    "makerAddr": "0x171419bD2A09E7a80939b33360A8e8BfE79a1d9b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "51000000000000000000",
                    "tokenId": "5579",
                    "listingTime": 1650939935,
                    "expirationTime": 1653397146,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5579",
                    "makerAddr": "0xD163bd16eaf4813D723F90f13fE4abeeECCdD3dd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "51000000000000000000",
                    "tokenId": "20674",
                    "listingTime": 1651238617,
                    "expirationTime": 1653830600,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20674",
                    "makerAddr": "0x2dA054af93D06Dc140Ef84403A67Ae7ceA89df1C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "51000000000000000000",
                    "tokenId": "23224",
                    "listingTime": 1650944701,
                    "expirationTime": 1653536693,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23224",
                    "makerAddr": "0xeDb400039b6678e468F03c1637AF625121062250",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "51000000000000000000",
                    "tokenId": "7173",
                    "listingTime": 1650759770,
                    "expirationTime": 1653351649,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7173",
                    "makerAddr": "0x57EAdd1811affBD06C03787CAA973E626C090c62",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "51000000000000000000",
                    "tokenId": "9449",
                    "listingTime": 1651297688,
                    "expirationTime": 1653889680,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9449",
                    "makerAddr": "0x99fB85FdEa0651Cc1502603393dD7620aC49a72F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "51000000000000000000",
                    "tokenId": "17649",
                    "listingTime": 1651560770,
                    "expirationTime": 1652770362,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17649",
                    "makerAddr": "0x0B4f05137beaE5d828b0649DC6ba184572D5480C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "51000000000000000000",
                    "tokenId": "26444",
                    "listingTime": 1651168960,
                    "expirationTime": 1653471315,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26444",
                    "makerAddr": "0x7082dB9Ce4f520253217d8EB1fD6b6097B74c483",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "179",
                    "price": "51000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651541191,
                    "expirationTime": 1654219610,
                    "makerAddr": "0xcf4bac77a2f3b4b56d90a27071e85d6bda70d554",
                    "assetImageUrl": "https://img.seadn.io/files/66d46786ca0450d4430edda7b36e0714.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "51500000000000000000",
                    "tokenId": "20303",
                    "listingTime": 1650746090,
                    "expirationTime": 1652694617,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20303",
                    "makerAddr": "0x298d5537C181766D22a27D40b90A9c8ccA5b5aD5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "880",
                    "price": "51500000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650849729,
                    "expirationTime": 1653441443,
                    "makerAddr": "0x898bd919ad1bad4fd7b2a1672126c5df7bc0e03e",
                    "assetImageUrl": "https://img.seadn.io/files/b69a2256794013095a988820c507fec3.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "51690000000000000000",
                    "tokenId": "3878",
                    "listingTime": 1650943546,
                    "expirationTime": 1666347219,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3878",
                    "makerAddr": "0xDC859b3da111A0Eb0AD3bB536dCB5d9f14f19F99",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "51990000000000000000",
                    "tokenId": "24883",
                    "listingTime": 1650207957,
                    "expirationTime": 1652799951,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24883",
                    "makerAddr": "0x28F8ca3B0EDdd849c93986dF0fd194252C4e4B03",
                    "assetImageUrl": ""
                }
            ],
            "total": 1.07368e+21,
            "looksrare": 19,
            "opensea": 2
        },
        {
            "price": 52,
            "amount": 39,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "18196",
                    "listingTime": 1651013702,
                    "expirationTime": 1653605584,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18196",
                    "makerAddr": "0x51f7B5C69034D8A245aBbE19742Fec59789e702B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "1218",
                    "listingTime": 1650894488,
                    "expirationTime": 1653486480,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1218",
                    "makerAddr": "0x7C6A2644e5A42528cE28Aa05CC5081C24b3Dd812",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "24448",
                    "listingTime": 1651177862,
                    "expirationTime": 1653405104,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24448",
                    "makerAddr": "0x3922B064DcC34289B2c04f82B92977c413a0dAd2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "4062",
                    "listingTime": 1650839438,
                    "expirationTime": 1653431426,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4062",
                    "makerAddr": "0xa4f38C92E2a3D3153075859f493083d05CA3540d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "1797",
                    "listingTime": 1650779660,
                    "expirationTime": 1653366191,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1797",
                    "makerAddr": "0x6C8Ee01F1f8B62E987b3D18F6F28b22a0Ada755f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "9199",
                    "listingTime": 1650940919,
                    "expirationTime": 1653449081,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9199",
                    "makerAddr": "0x99d73D76f0058F4ae24882A49A0a9eA0f5a41268",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "8415",
                    "listingTime": 1651942933,
                    "expirationTime": 1654534929,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8415",
                    "makerAddr": "0xA742203fE554fFf075077b749f5402c62fC01BBA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "18168",
                    "listingTime": 1650782543,
                    "expirationTime": 1653374529,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18168",
                    "makerAddr": "0x35dF9413f56332E389b41Be14c98E63500bE0Bc6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "1514",
                    "listingTime": 1652109662,
                    "expirationTime": 1652368859,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1514",
                    "makerAddr": "0xd7CdB08EaDd3D730540817a348b62D15BF45DF10",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "15106",
                    "listingTime": 1650943546,
                    "expirationTime": 1653463301,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15106",
                    "makerAddr": "0xc741EB22eD5500DdFb587D7bE04d22c0aDe3851C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "626",
                    "listingTime": 1651039428,
                    "expirationTime": 1653631418,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/626",
                    "makerAddr": "0xe90a804dB10c4Ffd52732A0da6a0dE9e4fab4cD4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "1889",
                    "listingTime": 1650777579,
                    "expirationTime": 1653369564,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1889",
                    "makerAddr": "0x33F60a805bFd4009249b4318abcDe2952dB92d46",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "21939",
                    "listingTime": 1652100295,
                    "expirationTime": 1652359492,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21939",
                    "makerAddr": "0x14bA3316BdF0471879E91c6d5fD5acA9d36613eC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "3620",
                    "listingTime": 1651344930,
                    "expirationTime": 1653936888,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3620",
                    "makerAddr": "0xa27d926736CD8eb2Ffe918461BF84180B119f1f1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "16690",
                    "listingTime": 1650779631,
                    "expirationTime": 1653366246,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16690",
                    "makerAddr": "0x6C8Ee01F1f8B62E987b3D18F6F28b22a0Ada755f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "6617",
                    "listingTime": 1651344941,
                    "expirationTime": 1653936888,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6617",
                    "makerAddr": "0xa27d926736CD8eb2Ffe918461BF84180B119f1f1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "27694",
                    "listingTime": 1650807342,
                    "expirationTime": 1658583284,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27694",
                    "makerAddr": "0x1Ca58e323a375c2F2DE8a3993cfAd115d4EA155D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "16630",
                    "listingTime": 1650977509,
                    "expirationTime": 1653569406,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16630",
                    "makerAddr": "0x007075fAb8b3bd4227392A7caABF1c9eFfa56111",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "23926",
                    "listingTime": 1651052828,
                    "expirationTime": 1653579875,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23926",
                    "makerAddr": "0xf1b1cF1a44CadE3d7a0962d61abDC475f1f3D093",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "9098",
                    "listingTime": 1651891414,
                    "expirationTime": 1654483227,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9098",
                    "makerAddr": "0x3Bb3c1ca15FF712c80c20e99d157597746304c52",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "15844",
                    "listingTime": 1651026699,
                    "expirationTime": 1653512002,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15844",
                    "makerAddr": "0x56E1E208fAc771d39486aCa9146B2c71C30751C5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "14297",
                    "listingTime": 1651046465,
                    "expirationTime": 1653565894,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14297",
                    "makerAddr": "0x517b1c4B8C259255EE7b7aB85e6Ea0dc9a235c3E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "21295",
                    "listingTime": 1651775993,
                    "expirationTime": 1652380788,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21295",
                    "makerAddr": "0x5054A3283e57Cc1bd1109F596a0a5a31887D56FB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52000000000000000000",
                    "tokenId": "18995",
                    "listingTime": 1650761030,
                    "expirationTime": 1653353006,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18995",
                    "makerAddr": "0xC02F64ae2d5a84c860Db8FFd2e3ac88D182B3D6D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1218",
                    "price": "52000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650894427,
                    "expirationTime": 1653486519,
                    "makerAddr": "0x7c6a2644e5a42528ce28aa05cc5081c24b3dd812",
                    "assetImageUrl": "https://img.seadn.io/files/132691d00eeddad58791d76b7986e07c.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2419",
                    "price": "52000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650796171,
                    "expirationTime": 1653388262,
                    "makerAddr": "0x623a417dc561a3e5e9cbd5193183f4b8adce698b",
                    "assetImageUrl": "https://img.seadn.io/files/d56a9f437d6d451c7ef0050bc71a842c.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2406",
                    "price": "52230000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651453849,
                    "expirationTime": 1654132342,
                    "makerAddr": "0xaa621b960f22911462550c078df678493c22b2ae",
                    "assetImageUrl": "https://img.seadn.io/files/2f7922f0a1e2a8965885c6b0e1bc5073.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52320000000000000000",
                    "tokenId": "5232",
                    "listingTime": 1650812690,
                    "expirationTime": 1653404676,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5232",
                    "makerAddr": "0x4859f4091889a247C3F5fA2E4Fca18827Be18529",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52500000000000000000",
                    "tokenId": "18962",
                    "listingTime": 1650814564,
                    "expirationTime": 1653406554,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18962",
                    "makerAddr": "0xfa861eE098C87c78AB96d780C5211d9557869920",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52500000000000000000",
                    "tokenId": "408",
                    "listingTime": 1650813727,
                    "expirationTime": 1653405721,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/408",
                    "makerAddr": "0x98d31E06789783d5C1DD2E18013f04C9dAb78656",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52500000000000000000",
                    "tokenId": "21271",
                    "listingTime": 1651113766,
                    "expirationTime": 1653705760,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21271",
                    "makerAddr": "0x8ab83D869f2Bc250b781D26F6584fd5c562FdD9D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52500000000000000000",
                    "tokenId": "4745",
                    "listingTime": 1650813561,
                    "expirationTime": 1653405548,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4745",
                    "makerAddr": "0xdF41003BcdC9aa917Fdae801BD02b3167368D5CD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52500000000000000000",
                    "tokenId": "18505",
                    "listingTime": 1651906820,
                    "expirationTime": 1652511539,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18505",
                    "makerAddr": "0x4afD10E78c251C79586A67b1F7afDA6981883640",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52500000000000000000",
                    "tokenId": "14240",
                    "listingTime": 1651144532,
                    "expirationTime": 1653736434,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14240",
                    "makerAddr": "0x7324b1202a7eB60ea969D94690ABB25f8e92c5a8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52500000000000000000",
                    "tokenId": "4781",
                    "listingTime": 1651063788,
                    "expirationTime": 1653655778,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4781",
                    "makerAddr": "0x75ecEc45CDdE8cEd19880e735a619fa7162B40e2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52500000000000000000",
                    "tokenId": "22836",
                    "listingTime": 1650762533,
                    "expirationTime": 1653354516,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22836",
                    "makerAddr": "0x0f28862bb9cC96f865c8047268230fF1248C7721",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52500000000000000000",
                    "tokenId": "19486",
                    "listingTime": 1650863434,
                    "expirationTime": 1653455431,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19486",
                    "makerAddr": "0x89688542873276Cd7d9Cea936b25F37778a593fd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52690000000000000000",
                    "tokenId": "5399",
                    "listingTime": 1650833990,
                    "expirationTime": 1654045200,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5399",
                    "makerAddr": "0x13c3258823fF9C506cb72291672dc9ea1E5517E4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "52900000000000000000",
                    "tokenId": "18102",
                    "listingTime": 1650803701,
                    "expirationTime": 1653395493,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18102",
                    "makerAddr": "0x9Ba455Ad9aDd86D9F531593dd7c09e59D120625f",
                    "assetImageUrl": ""
                }
            ],
            "total": 2.034639999999999e+21,
            "looksrare": 36,
            "opensea": 3
        },
        {
            "price": 53,
            "amount": 33,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "2218",
                    "listingTime": 1650826381,
                    "expirationTime": 1653418374,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2218",
                    "makerAddr": "0x49ca963Ef75BCEBa8E4A5F4cEAB5Fd326beF6123",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "27659",
                    "listingTime": 1651041428,
                    "expirationTime": 1653442242,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27659",
                    "makerAddr": "0x2f670A086b5b426BA56086149Ca1904027E403b9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "2688",
                    "listingTime": 1651616676,
                    "expirationTime": 1654208621,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2688",
                    "makerAddr": "0x39A7Fb978b1F43219F096d7eb0836fd08CD74e15",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "5996",
                    "listingTime": 1650774166,
                    "expirationTime": 1653366044,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5996",
                    "makerAddr": "0x673394895B3654e81aEb229412c2a8Cf7955d7b4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "28070",
                    "listingTime": 1651042561,
                    "expirationTime": 1653366831,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28070",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "5566",
                    "listingTime": 1651905744,
                    "expirationTime": 1654066075,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5566",
                    "makerAddr": "0x4A5139eb46FB4De32D838D66c52846618C57Cb37",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "21970",
                    "listingTime": 1651169101,
                    "expirationTime": 1653473921,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21970",
                    "makerAddr": "0xBF21B6B77B893E11e96d79157Eb417F3e4E9538e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "14241",
                    "listingTime": 1651753067,
                    "expirationTime": 1653817382,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14241",
                    "makerAddr": "0x7324b1202a7eB60ea969D94690ABB25f8e92c5a8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "27386",
                    "listingTime": 1650810316,
                    "expirationTime": 1653402278,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27386",
                    "makerAddr": "0xb8bc655b69a848A2C7173180CD54A11A03b64493",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "9436",
                    "listingTime": 1651042534,
                    "expirationTime": 1653366760,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9436",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "2914",
                    "listingTime": 1650774813,
                    "expirationTime": 1653366800,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2914",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "23150",
                    "listingTime": 1650838435,
                    "expirationTime": 1653430416,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23150",
                    "makerAddr": "0xbb980fd226AC348A842875A029e6A7b25De9a447",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "6250",
                    "listingTime": 1650893425,
                    "expirationTime": 1653485399,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6250",
                    "makerAddr": "0x989cE0d5f551d94EC87E638Dc643c014e63a0c0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "16430",
                    "listingTime": 1650813495,
                    "expirationTime": 1653405467,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16430",
                    "makerAddr": "0x3fdcCb1837602F5e9B5E55db80E298AD5b262276",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "2125",
                    "listingTime": 1651406832,
                    "expirationTime": 1653998793,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2125",
                    "makerAddr": "0x175e393539e93720B962D186c70fdf1a362c8fd7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "4088",
                    "listingTime": 1650934459,
                    "expirationTime": 1653407259,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4088",
                    "makerAddr": "0x3e9b7732616A6153D2Ba41f2198702299DB7cCa6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "14297",
                    "listingTime": 1651032092,
                    "expirationTime": 1653565894,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14297",
                    "makerAddr": "0x517b1c4B8C259255EE7b7aB85e6Ea0dc9a235c3E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "1687",
                    "listingTime": 1650977404,
                    "expirationTime": 1653569271,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1687",
                    "makerAddr": "0x2eA07698a88AD2a0Acfc82C229C5cBaDb7CC9c9C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "6879",
                    "listingTime": 1650772787,
                    "expirationTime": 1653364765,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6879",
                    "makerAddr": "0x0c338bFEF0805184a72FA462E60a51198802fc4A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "29734",
                    "listingTime": 1651030567,
                    "expirationTime": 1653621252,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29734",
                    "makerAddr": "0x49836dbdbEA45b90ED274F0EB688E3Cc7908D31D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "5364",
                    "listingTime": 1651406829,
                    "expirationTime": 1653998793,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5364",
                    "makerAddr": "0x175e393539e93720B962D186c70fdf1a362c8fd7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "4400",
                    "listingTime": 1651178166,
                    "expirationTime": 1658954135,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4400",
                    "makerAddr": "0x61a4FC9028048e562b2a49d8dccf2378B644dF03",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "3256",
                    "listingTime": 1650833849,
                    "expirationTime": 1653425844,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3256",
                    "makerAddr": "0xC7b72Bb1aCDcE3eB45f9B38F43cD31e4927C725b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53000000000000000000",
                    "tokenId": "3613",
                    "listingTime": 1650943123,
                    "expirationTime": 1666469638,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3613",
                    "makerAddr": "0x25Ca2e79391AD17A73395f88985d33C121dF7d06",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53100000000000000000",
                    "tokenId": "23924",
                    "listingTime": 1651169130,
                    "expirationTime": 1653475571,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23924",
                    "makerAddr": "0xBF21B6B77B893E11e96d79157Eb417F3e4E9538e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53330000000000000000",
                    "tokenId": "11064",
                    "listingTime": 1650940029,
                    "expirationTime": 1653405091,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11064",
                    "makerAddr": "0x5fB998B8024E38867558fD5E2b87B6D13Da730f8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53350000000000000000",
                    "tokenId": "3214",
                    "listingTime": 1651031387,
                    "expirationTime": 1653508499,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3214",
                    "makerAddr": "0xfAE17d7D6c277F7964c2871fC0Cb11AC91e698F6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53500000000000000000",
                    "tokenId": "19791",
                    "listingTime": 1652044147,
                    "expirationTime": 1652648931,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19791",
                    "makerAddr": "0x31f2792889c0Bb79f7C9E8DED4f8c53C6C8F4765",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53500000000000000000",
                    "tokenId": "29784",
                    "listingTime": 1651119018,
                    "expirationTime": 1653710988,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29784",
                    "makerAddr": "0xaDF631c4F37052B9F5eE63aD88975dDaaef0F7aA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53500000000000000000",
                    "tokenId": "11100",
                    "listingTime": 1651042898,
                    "expirationTime": 1658661900,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11100",
                    "makerAddr": "0x61a4FC9028048e562b2a49d8dccf2378B644dF03",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53625000000000000000",
                    "tokenId": "6909",
                    "listingTime": 1651034554,
                    "expirationTime": 1653626475,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6909",
                    "makerAddr": "0x57C2955C0d0fC319dDF6110eEdFCC81AF3caDD72",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53690000000000000000",
                    "tokenId": "801",
                    "listingTime": 1651248398,
                    "expirationTime": 1653840270,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/801",
                    "makerAddr": "0x709bF4aC7ED6Bb2F9d60b1215d983496AB68efbc",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "53750000000000000000",
                    "tokenId": "3104",
                    "listingTime": 1651075168,
                    "expirationTime": 1653493849,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3104",
                    "makerAddr": "0xDfaf4022d9B6a08896569933D8F38F67E720CeB3",
                    "assetImageUrl": ""
                }
            ],
            "total": 1.7533450000000004e+21,
            "looksrare": 33
        },
        {
            "price": 54,
            "amount": 39,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "9436",
                    "listingTime": 1650774775,
                    "expirationTime": 1653366760,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9436",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "6324",
                    "listingTime": 1651848766,
                    "expirationTime": 1654008720,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6324",
                    "makerAddr": "0x6F7DeA27e1f6f2727FE70a3D2c39c9b7F40C75A2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "9199",
                    "listingTime": 1650940905,
                    "expirationTime": 1653449081,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9199",
                    "makerAddr": "0x99d73D76f0058F4ae24882A49A0a9eA0f5a41268",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "8021",
                    "listingTime": 1650941248,
                    "expirationTime": 1653415081,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8021",
                    "makerAddr": "0x852E45eD1a6502406Af4c409DF4e0Eef3CC0A0E3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "2035",
                    "listingTime": 1650814581,
                    "expirationTime": 1653406566,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2035",
                    "makerAddr": "0x7eDA755A7D4C25705f0B94E5efb755563e717Edc",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "8158",
                    "listingTime": 1651192916,
                    "expirationTime": 1653784904,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8158",
                    "makerAddr": "0x35EF89E19faa86fC306fC30f6Ea4Bd6b89a21310",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "7455",
                    "listingTime": 1651084270,
                    "expirationTime": 1653408852,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7455",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "4622",
                    "listingTime": 1651242734,
                    "expirationTime": 1653834728,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4622",
                    "makerAddr": "0x998f20a7f456690F2983E5D4639288C0A1f33810",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "22748",
                    "listingTime": 1651382052,
                    "expirationTime": 1652591644,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22748",
                    "makerAddr": "0x2155D5C0A63AF068a00443483C4CD572ae73b09b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "15078",
                    "listingTime": 1651134348,
                    "expirationTime": 1653474819,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15078",
                    "makerAddr": "0x14210914D6A65Fa2C7747579A7aa287B027fD092",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "2799",
                    "listingTime": 1651127867,
                    "expirationTime": 1653482039,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2799",
                    "makerAddr": "0xC0B956baD990CF94C46df2c2fc2BF189B89f9DcA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "3827",
                    "listingTime": 1651084254,
                    "expirationTime": 1653408280,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3827",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "23814",
                    "listingTime": 1650922798,
                    "expirationTime": 1653514111,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23814",
                    "makerAddr": "0xBE2d830CBD4628b06DE78B4eE660E1385a9152A6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "24668",
                    "listingTime": 1650772889,
                    "expirationTime": 1653364881,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24668",
                    "makerAddr": "0x412F2661344078140BCE0fA74ffFC3bf5b4Fa7Dd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "16690",
                    "listingTime": 1650774250,
                    "expirationTime": 1653366246,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16690",
                    "makerAddr": "0x6C8Ee01F1f8B62E987b3D18F6F28b22a0Ada755f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "1573",
                    "listingTime": 1650817735,
                    "expirationTime": 1658593707,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1573",
                    "makerAddr": "0x683EbbA7A833A809071afb4819C0a2Dfd6e07333",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "28070",
                    "listingTime": 1650774836,
                    "expirationTime": 1653366831,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28070",
                    "makerAddr": "0x66dDa7B9c769171F2904A6050988746779360961",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "1707",
                    "listingTime": 1650770262,
                    "expirationTime": 1653362139,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1707",
                    "makerAddr": "0x116335c3eE633EbA27C02bcFb7Ee5dE695E4D201",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "2637",
                    "listingTime": 1650770944,
                    "expirationTime": 1653362886,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2637",
                    "makerAddr": "0x41758bEb6011558EA86DFD68d5715a6e05f423ef",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "15510",
                    "listingTime": 1651087549,
                    "expirationTime": 1653419970,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15510",
                    "makerAddr": "0x1f0DB8e0408C4D242bBB26af0fbEeC4a068a19b0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "26206",
                    "listingTime": 1650933531,
                    "expirationTime": 1653525504,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26206",
                    "makerAddr": "0x0563b837bA1831066A10F923DeB91E8cc57608dd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "7564",
                    "listingTime": 1651085009,
                    "expirationTime": 1653416797,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7564",
                    "makerAddr": "0x598fDd699C874440EbD31fdCF5cFfc1500735a9f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "27659",
                    "listingTime": 1650940189,
                    "expirationTime": 1653442242,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27659",
                    "makerAddr": "0x2f670A086b5b426BA56086149Ca1904027E403b9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "26664",
                    "listingTime": 1650766324,
                    "expirationTime": 1653358291,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26664",
                    "makerAddr": "0xD8633185E23B3EC01b65Eba96011DCe45b597503",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "1797",
                    "listingTime": 1650774205,
                    "expirationTime": 1653366191,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1797",
                    "makerAddr": "0x6C8Ee01F1f8B62E987b3D18F6F28b22a0Ada755f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "23926",
                    "listingTime": 1650987941,
                    "expirationTime": 1653579875,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23926",
                    "makerAddr": "0xf1b1cF1a44CadE3d7a0962d61abDC475f1f3D093",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "7393",
                    "listingTime": 1651434228,
                    "expirationTime": 1653823312,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7393",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "1967",
                    "listingTime": 1651026662,
                    "expirationTime": 1653499354,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1967",
                    "makerAddr": "0x56E1E208fAc771d39486aCa9146B2c71C30751C5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "7034",
                    "listingTime": 1650765715,
                    "expirationTime": 1658541706,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7034",
                    "makerAddr": "0x6Cd68E8f04490Cd1A5A21cc97CC8BC15b47Dc9eb",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54000000000000000000",
                    "tokenId": "6239",
                    "listingTime": 1650816631,
                    "expirationTime": 1653408618,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6239",
                    "makerAddr": "0x7F25Ca87C69Eb2891A25dA9400cA9D289E1044fb",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54500000000000000000",
                    "tokenId": "21280",
                    "listingTime": 1650810271,
                    "expirationTime": 1653402254,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21280",
                    "makerAddr": "0x57be93fB6a01E435aaC84E2f52566E6f7296f578",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54500000000000000000",
                    "tokenId": "8177",
                    "listingTime": 1650820094,
                    "expirationTime": 1653412065,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8177",
                    "makerAddr": "0x4df2CDB9AdDc77f7f198b26deE5E77f4278bf28f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54500000000000000000",
                    "tokenId": "1122",
                    "listingTime": 1650936183,
                    "expirationTime": 1653443992,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1122",
                    "makerAddr": "0xBe547aA1bf50FEcCa7d853adEa3aB6D5F85953F7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54600000000000000000",
                    "tokenId": "9570",
                    "listingTime": 1651088544,
                    "expirationTime": 1653680470,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9570",
                    "makerAddr": "0xEc44d93329a3c999F9F19cB96D663fa3B2dA0A87",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54650000000000000000",
                    "tokenId": "3214",
                    "listingTime": 1650941465,
                    "expirationTime": 1653508499,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3214",
                    "makerAddr": "0xfAE17d7D6c277F7964c2871fC0Cb11AC91e698F6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54718200000000000000",
                    "tokenId": "327",
                    "listingTime": 1651162833,
                    "expirationTime": 1653754814,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/327",
                    "makerAddr": "0x07742033dd24009b0534D317c723Fc24958147d2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54880000000000000000",
                    "tokenId": "7360",
                    "listingTime": 1650809587,
                    "expirationTime": 1666361513,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7360",
                    "makerAddr": "0x6981378E696B0dd0e585D4D519C98B8A2c5bC020",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "54990000000000000000",
                    "tokenId": "3878",
                    "listingTime": 1650798853,
                    "expirationTime": 1666347219,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3878",
                    "makerAddr": "0xDC859b3da111A0Eb0AD3bB536dCB5d9f14f19F99",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "927",
                    "price": "54990000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651458675,
                    "expirationTime": 1654137162,
                    "makerAddr": "0x0ef563b0552c84399f82b1d4cb876db44846dcf2",
                    "assetImageUrl": "https://img.seadn.io/files/46998cb2946ad98b7e2cf9c2c32eb0d8.png?auto=format"
                }
            ],
            "total": 2.1123281999999997e+21,
            "looksrare": 38,
            "opensea": 1
        },
        {
            "price": 55,
            "amount": 109,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "14094",
                    "listingTime": 1650889795,
                    "expirationTime": 1653481683,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14094",
                    "makerAddr": "0x4310fb4B535cE2222812e389280E80833823C41a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "18943",
                    "listingTime": 1651621796,
                    "expirationTime": 1654213661,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18943",
                    "makerAddr": "0xDe112B77A1a72c726D8C914c23dCEaA53273c351",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "393",
                    "listingTime": 1650836303,
                    "expirationTime": 1653428299,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/393",
                    "makerAddr": "0x442D6426B2259fE000039C5D94a73F4e1f32a572",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "1322",
                    "listingTime": 1650931162,
                    "expirationTime": 1653523113,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1322",
                    "makerAddr": "0x4064023610A479F9A0415eE98f87895A0e2e0884",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "14297",
                    "listingTime": 1650973907,
                    "expirationTime": 1653565894,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14297",
                    "makerAddr": "0x517b1c4B8C259255EE7b7aB85e6Ea0dc9a235c3E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "3001",
                    "listingTime": 1651080261,
                    "expirationTime": 1653672254,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3001",
                    "makerAddr": "0x827a399Bd350F25B6bF9F82424D2b8F9F9252d24",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "8323",
                    "listingTime": 1650948743,
                    "expirationTime": 1653491183,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8323",
                    "makerAddr": "0x870C66e74c6691894b3718Fd51216E8940197504",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "5579",
                    "listingTime": 1650805166,
                    "expirationTime": 1653397146,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5579",
                    "makerAddr": "0xD163bd16eaf4813D723F90f13fE4abeeECCdD3dd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "24098",
                    "listingTime": 1650809958,
                    "expirationTime": 1658585953,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24098",
                    "makerAddr": "0x14A03CA8740A0044E63d3Bb0432540d9509473d1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "20664",
                    "listingTime": 1650845749,
                    "expirationTime": 1653437727,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20664",
                    "makerAddr": "0x545a2eD169EaC188638763539D30951488a9c8F7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "20534",
                    "listingTime": 1650804854,
                    "expirationTime": 1653396846,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20534",
                    "makerAddr": "0xd4745B170DeDe5eF451D46Fce6A111eE41f4047f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "29734",
                    "listingTime": 1651029262,
                    "expirationTime": 1653621252,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29734",
                    "makerAddr": "0x49836dbdbEA45b90ED274F0EB688E3Cc7908D31D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "22903",
                    "listingTime": 1650823328,
                    "expirationTime": 1653415324,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22903",
                    "makerAddr": "0xfb77A033CBF6980DF6b3acdFC5DF3f0a6D6225cf",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "29960",
                    "listingTime": 1651146948,
                    "expirationTime": 1653738891,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29960",
                    "makerAddr": "0x55013e79Dc8fB573588B22447633c049e13ff1f3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "4854",
                    "listingTime": 1651378149,
                    "expirationTime": 1653970147,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4854",
                    "makerAddr": "0x9bc9eC203baC06665EF7F8bdf8CEe986B21B5296",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "825",
                    "listingTime": 1651375429,
                    "expirationTime": 1652585020,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/825",
                    "makerAddr": "0xa7e4f12B7Cd97387e8607daC17B439ca7cad58e9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "229",
                    "listingTime": 1651170503,
                    "expirationTime": 1653445649,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/229",
                    "makerAddr": "0x4b58F249B2B390125158237ec350C54E690AF477",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "4423",
                    "listingTime": 1650810116,
                    "expirationTime": 1653402070,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4423",
                    "makerAddr": "0xAd7Bbe006c8D919Ffcf6148b227Bb692F7D1fbc7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "2345",
                    "listingTime": 1651332670,
                    "expirationTime": 1653924638,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2345",
                    "makerAddr": "0xD05Defee18E718F21337F5355317f915a34A101B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "9530",
                    "listingTime": 1651060785,
                    "expirationTime": 1653652687,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9530",
                    "makerAddr": "0x80FD7737E1f2D8ee2c829f9B3e618Da7c78da94b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "6206",
                    "listingTime": 1651151901,
                    "expirationTime": 1653406103,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6206",
                    "makerAddr": "0x4F582c02da18ce1B342C2EB38C015c8dc5C75c1c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "14966",
                    "listingTime": 1650813616,
                    "expirationTime": 1653405602,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14966",
                    "makerAddr": "0x2319b02CA79f3253339b06baCB9a1de9C00A61e9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "7097",
                    "listingTime": 1651077190,
                    "expirationTime": 1653447935,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7097",
                    "makerAddr": "0x683EbbA7A833A809071afb4819C0a2Dfd6e07333",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "8953",
                    "listingTime": 1650874945,
                    "expirationTime": 1666424869,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8953",
                    "makerAddr": "0x3aF3A432D194C16b68f1a4a96dC59d92b6e13b19",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "507",
                    "listingTime": 1650874882,
                    "expirationTime": 1666425078,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/507",
                    "makerAddr": "0x3aF3A432D194C16b68f1a4a96dC59d92b6e13b19",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "6373",
                    "listingTime": 1650965051,
                    "expirationTime": 1653391192,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6373",
                    "makerAddr": "0xE9FbEbb23e5CCC02EEe6E751adA3f3BbD20C630D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "1626",
                    "listingTime": 1650936325,
                    "expirationTime": 1653443769,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1626",
                    "makerAddr": "0xBe547aA1bf50FEcCa7d853adEa3aB6D5F85953F7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "6191",
                    "listingTime": 1650894055,
                    "expirationTime": 1653485800,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6191",
                    "makerAddr": "0xa551424f18c9B7536d64A48122fD58C619b7Ca2a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "7557",
                    "listingTime": 1650857334,
                    "expirationTime": 1653449297,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7557",
                    "makerAddr": "0x534c98c85714Df298524CF94c3d2Cd230B1EEa5B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "19912",
                    "listingTime": 1650967095,
                    "expirationTime": 1653559053,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19912",
                    "makerAddr": "0xAd31c65d25Aad616479DEcdbc746efd597219d14",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "1505",
                    "listingTime": 1650884912,
                    "expirationTime": 1653476841,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1505",
                    "makerAddr": "0xA91531E2b92265d071D60BbDc369612999b4DC2A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "20360",
                    "listingTime": 1651124590,
                    "expirationTime": 1653716530,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20360",
                    "makerAddr": "0x5eb01242b23A2A53cd5Dc612F07B5468F95f72cB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "40",
                    "listingTime": 1650855044,
                    "expirationTime": 1653446978,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/40",
                    "makerAddr": "0xc13a9B21B421B3111a3c79F4700734f35a71FaCD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "13852",
                    "listingTime": 1650920470,
                    "expirationTime": 1653512413,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13852",
                    "makerAddr": "0x1a0eB2A71E318cfd42c01D852eE15B58b0d45296",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "6726",
                    "listingTime": 1650812141,
                    "expirationTime": 1653404128,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6726",
                    "makerAddr": "0x43f44Ad26a18777F500Fb7496D1aF795cc1d3543",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "15510",
                    "listingTime": 1650828001,
                    "expirationTime": 1653419970,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15510",
                    "makerAddr": "0x1f0DB8e0408C4D242bBB26af0fbEeC4a068a19b0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "444",
                    "listingTime": 1651135120,
                    "expirationTime": 1653437047,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/444",
                    "makerAddr": "0x450C9CE305795162b21Ae20513127B3FCa61eeD4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "4291",
                    "listingTime": 1651164926,
                    "expirationTime": 1653756840,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4291",
                    "makerAddr": "0xfb77A033CBF6980DF6b3acdFC5DF3f0a6D6225cf",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "22000",
                    "listingTime": 1650910208,
                    "expirationTime": 1653502205,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22000",
                    "makerAddr": "0x8aE6422631292c31aeeB2efe154d6326f703F46b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "14846",
                    "listingTime": 1651229042,
                    "expirationTime": 1653821019,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14846",
                    "makerAddr": "0x6189e05a28bEF26Bcf76F22aB311282d5e843dF4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "15408",
                    "listingTime": 1651016471,
                    "expirationTime": 1653608453,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15408",
                    "makerAddr": "0xd67a3Ef7B1DEA4B2c3c5a13B976BCab021D10bed",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "22484",
                    "listingTime": 1650790211,
                    "expirationTime": 1653382164,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22484",
                    "makerAddr": "0xe910Cb56896277A05dDDFBEa9aA525511a175f68",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "7474",
                    "listingTime": 1650813228,
                    "expirationTime": 1653405193,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7474",
                    "makerAddr": "0xA734c64fC76CFFc782e63079BB6703179ea2326d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "2894",
                    "listingTime": 1651328585,
                    "expirationTime": 1659104522,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2894",
                    "makerAddr": "0xE5F47105e6939B917D0a0f95aE87D98Cf137051a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "9745",
                    "listingTime": 1650942976,
                    "expirationTime": 1653450204,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9745",
                    "makerAddr": "0x74D8D562CafcF710C7f950Cf32501EF14b2642b1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "1041",
                    "listingTime": 1650882890,
                    "expirationTime": 1653474883,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1041",
                    "makerAddr": "0x5Bb4FFFEF211f4aa96AB22A446fDEed2e9Fb457e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "1642",
                    "listingTime": 1650836897,
                    "expirationTime": 1666388866,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1642",
                    "makerAddr": "0xfb363b7D7a1c065356442829F03C84F9660CE1e2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "3083",
                    "listingTime": 1650824607,
                    "expirationTime": 1653414972,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3083",
                    "makerAddr": "0xC344FCe3710d5971b24b11947535FAcE3eCd5655",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "3827",
                    "listingTime": 1650816297,
                    "expirationTime": 1653408280,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3827",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "2593",
                    "listingTime": 1650936105,
                    "expirationTime": 1653444064,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2593",
                    "makerAddr": "0xBe547aA1bf50FEcCa7d853adEa3aB6D5F85953F7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "620",
                    "listingTime": 1650882853,
                    "expirationTime": 1653474827,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/620",
                    "makerAddr": "0x5Bb4FFFEF211f4aa96AB22A446fDEed2e9Fb457e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "17924",
                    "listingTime": 1652119664,
                    "expirationTime": 1659895609,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17924",
                    "makerAddr": "0x9af0aa8d04F02329B11B2847527Ce1309acAE2EC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "9729",
                    "listingTime": 1651092492,
                    "expirationTime": 1653684455,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9729",
                    "makerAddr": "0x9D1ff24773876B0051e76cAd36290E7b4D0071D5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "2157",
                    "listingTime": 1650797772,
                    "expirationTime": 1653389715,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2157",
                    "makerAddr": "0x685157E0624B779DB621c407041814D1763eC007",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "8852",
                    "listingTime": 1651260875,
                    "expirationTime": 1653852863,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8852",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "2054",
                    "listingTime": 1651125225,
                    "expirationTime": 1653666659,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2054",
                    "makerAddr": "0xb9b2355417d03F3716b6427E6407864F0b634744",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "4488",
                    "listingTime": 1650932115,
                    "expirationTime": 1654007700,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4488",
                    "makerAddr": "0xF7834A38c8De5539b45cE56cD3F968066Bff01dF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "9150",
                    "listingTime": 1651069290,
                    "expirationTime": 1653465468,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9150",
                    "makerAddr": "0x17b128B6771F5D26de3348db0cd222328cc1Eb47",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "17882",
                    "listingTime": 1649894915,
                    "expirationTime": 1652486911,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17882",
                    "makerAddr": "0x318Ec0B60750a5e06b3a0f654F79E4ad3f95BF8B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "7296",
                    "listingTime": 1651381364,
                    "expirationTime": 1653973345,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7296",
                    "makerAddr": "0x4c8F62f1498FA55D4158CdBFEA7783f84556a68e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "7768",
                    "listingTime": 1650852858,
                    "expirationTime": 1653444836,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7768",
                    "makerAddr": "0x2264303067Db0d5bfa3746F409B5A5129eDdB3d9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "4032",
                    "listingTime": 1650866443,
                    "expirationTime": 1653458429,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4032",
                    "makerAddr": "0xD3860466ddeb2580285F3A8843e464012F171694",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "18178",
                    "listingTime": 1650808459,
                    "expirationTime": 1653400292,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18178",
                    "makerAddr": "0xd7116bdfB69a032906De717968Df4E8566Eac0F5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "22001",
                    "listingTime": 1650909255,
                    "expirationTime": 1653501212,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22001",
                    "makerAddr": "0x8aE6422631292c31aeeB2efe154d6326f703F46b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "2570",
                    "listingTime": 1650987292,
                    "expirationTime": 1653413357,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2570",
                    "makerAddr": "0x685Cb9E2035d915331379Dcbf09D18084f126bA7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "26867",
                    "listingTime": 1651561326,
                    "expirationTime": 1652770921,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26867",
                    "makerAddr": "0x0B4f05137beaE5d828b0649DC6ba184572D5480C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "6402",
                    "listingTime": 1650852217,
                    "expirationTime": 1653444204,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6402",
                    "makerAddr": "0xD83556df9Ff191750eB8816B7Eb6126A9A245608",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "7455",
                    "listingTime": 1651026571,
                    "expirationTime": 1653408852,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7455",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "9665",
                    "listingTime": 1650874906,
                    "expirationTime": 1666425016,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9665",
                    "makerAddr": "0x3aF3A432D194C16b68f1a4a96dC59d92b6e13b19",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "10252",
                    "listingTime": 1651043030,
                    "expirationTime": 1653635024,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10252",
                    "makerAddr": "0x73a6A002B9538F636FbfE6bfB8b7440b8b3A510E",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "9199",
                    "listingTime": 1650857094,
                    "expirationTime": 1653449081,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9199",
                    "makerAddr": "0x99d73D76f0058F4ae24882A49A0a9eA0f5a41268",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "9377",
                    "listingTime": 1651089744,
                    "expirationTime": 1653459459,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9377",
                    "makerAddr": "0x0B5489942688F16e6cd80C4Bd7100CBf9ecdC161",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "3502",
                    "listingTime": 1650911284,
                    "expirationTime": 1653503267,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3502",
                    "makerAddr": "0x7cDD2909e630C4Aced7C9F65B1D24E2f0bD8f0F1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "13862",
                    "listingTime": 1651850145,
                    "expirationTime": 1654514521,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13862",
                    "makerAddr": "0xfDA939603cbf1633a875e832b05685567446c622",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "3897",
                    "listingTime": 1650912669,
                    "expirationTime": 1666464651,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3897",
                    "makerAddr": "0xeae6e6853FafA7C5f8E683e4E706CB378725319A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "13794",
                    "listingTime": 1650884699,
                    "expirationTime": 1653476694,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13794",
                    "makerAddr": "0xDd6Fd3596745eFec8c27159DF48C44B2EeEfa8D1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "26396",
                    "listingTime": 1650967218,
                    "expirationTime": 1653559174,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26396",
                    "makerAddr": "0xAd31c65d25Aad616479DEcdbc746efd597219d14",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "10735",
                    "listingTime": 1650890153,
                    "expirationTime": 1653481985,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10735",
                    "makerAddr": "0x7817946035C3dF3e3FEe461642fd575908d6Ea54",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "10033",
                    "listingTime": 1650841834,
                    "expirationTime": 1653433784,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10033",
                    "makerAddr": "0x7b5DA006d1e4a655F9f7B2001461f2F8aDEBaBA9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "23174",
                    "listingTime": 1650901759,
                    "expirationTime": 1658677747,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23174",
                    "makerAddr": "0x7e2225B3e54A7ad0f2D009237a4f136C02F90a52",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "15334",
                    "listingTime": 1651446285,
                    "expirationTime": 1654038257,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15334",
                    "makerAddr": "0x9F098B02634D846E4f466Ae084Af549b9c9ecF49",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "6682",
                    "listingTime": 1650806166,
                    "expirationTime": 1653398147,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6682",
                    "makerAddr": "0x71dfD2baB718F5bB30f1099494a68752B5d2f2e2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "29049",
                    "listingTime": 1651498391,
                    "expirationTime": 1654090388,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29049",
                    "makerAddr": "0x9f8534b8d266C136d52428Ec0647fa0F02EBF377",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "5008",
                    "listingTime": 1652028761,
                    "expirationTime": 1652633471,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5008",
                    "makerAddr": "0x03f065A8786eCd7dcf0F995EAAE6e5131A7E492f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "4560",
                    "listingTime": 1652068638,
                    "expirationTime": 1654365351,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4560",
                    "makerAddr": "0x3DE53471C5AADaac0beBeBB37268b7a7257d85F5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55000000000000000000",
                    "tokenId": "6046",
                    "listingTime": 1650809662,
                    "expirationTime": 1653401649,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6046",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "507",
                    "price": "55000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650872988,
                    "expirationTime": 1666425078,
                    "makerAddr": "0x3af3a432d194c16b68f1a4a96dc59d92b6e13b19",
                    "assetImageUrl": "https://img.seadn.io/files/4480721d8002e3b55a6ad7ca353a1d9f.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1283",
                    "price": "55000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1645219757,
                    "expirationTime": 1656908925,
                    "makerAddr": "0x512c579153ac6fd961a7d9e7b19281b855aafbe1",
                    "assetImageUrl": "https://img.seadn.io/files/61e65aa74397c743481e32df2dd0efc5.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1285",
                    "price": "55000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1645219734,
                    "expirationTime": 1656908712,
                    "makerAddr": "0x512c579153ac6fd961a7d9e7b19281b855aafbe1",
                    "assetImageUrl": "https://img.seadn.io/files/b0c03ed25e139c1d423a4a44c47e5d90.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1917",
                    "price": "55000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651866120,
                    "expirationTime": 1652730180,
                    "makerAddr": "0x6270d239ac3dd7ba9192367e50780031c82c6be1",
                    "assetImageUrl": "https://img.seadn.io/files/dcf21b705b4b81a1d18d4ee522a27961.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3249",
                    "price": "55000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651684807,
                    "expirationTime": 1654363294,
                    "makerAddr": "0x63588e0b5da6f0d604ef4968f6607b6b520ce3d9",
                    "assetImageUrl": "https://img.seadn.io/files/b27c80936224bd13a4c3826fe07eea7e.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55050000000000000000",
                    "tokenId": "14826",
                    "listingTime": 1650809565,
                    "expirationTime": 1653401558,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14826",
                    "makerAddr": "0xdBE2258624F94aB8EE30CeB67B2a078b24bB6d6d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55100000000000000000",
                    "tokenId": "3383",
                    "listingTime": 1651125256,
                    "expirationTime": 1653717198,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3383",
                    "makerAddr": "0xe935b40A45E60d16D99B54e001186b54EC7463A3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55200000000000000000",
                    "tokenId": "5068",
                    "listingTime": 1650948639,
                    "expirationTime": 1653491259,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5068",
                    "makerAddr": "0x870C66e74c6691894b3718Fd51216E8940197504",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55420690000000000000",
                    "tokenId": "9404",
                    "listingTime": 1650833431,
                    "expirationTime": 1653425364,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9404",
                    "makerAddr": "0xD64d9F5B9050a634ef9D0089d22766a36Ca8a2AA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55500000000000000000",
                    "tokenId": "5571",
                    "listingTime": 1650853538,
                    "expirationTime": 1653444739,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5571",
                    "makerAddr": "0x4b58F249B2B390125158237ec350C54E690AF477",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55500000000000000000",
                    "tokenId": "11480",
                    "listingTime": 1651097397,
                    "expirationTime": 1653689037,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11480",
                    "makerAddr": "0xb2AB83B605bf7b9E70cA18415A16dC6239BA14a8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55500000000000000000",
                    "tokenId": "29160",
                    "listingTime": 1651120569,
                    "expirationTime": 1653712544,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29160",
                    "makerAddr": "0x6c10E56522cafCAf220A9e5bb666E1D84102AcCb",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55500000000000000000",
                    "tokenId": "21336",
                    "listingTime": 1650799880,
                    "expirationTime": 1653391855,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21336",
                    "makerAddr": "0x119f80414C35be9b43F5b72F64708d0D9a6c4Fe9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55500000000000000000",
                    "tokenId": "6545",
                    "listingTime": 1651171049,
                    "expirationTime": 1653406914,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6545",
                    "makerAddr": "0xC778c7126678F1923f2c951488A4fE337bD15A08",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55550000000000000000",
                    "tokenId": "12690",
                    "listingTime": 1650814433,
                    "expirationTime": 1653406399,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12690",
                    "makerAddr": "0x13885436473DC4EF297dE0109F568794778d9C34",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55550000000000000000",
                    "tokenId": "11064",
                    "listingTime": 1650813096,
                    "expirationTime": 1653405091,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11064",
                    "makerAddr": "0x5fB998B8024E38867558fD5E2b87B6D13Da730f8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55550000000000000000",
                    "tokenId": "25",
                    "listingTime": 1651686312,
                    "expirationTime": 1667238300,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25",
                    "makerAddr": "0xa85fc696169A4e337F6102d8168a85A7162f5410",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "25",
                    "price": "55550000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651685552,
                    "expirationTime": 1667237564,
                    "makerAddr": "0xa85fc696169a4e337f6102d8168a85a7162f5410",
                    "assetImageUrl": "https://img.seadn.io/files/34db8783565ad9496660a109d714c91d.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55678000000000000000",
                    "tokenId": "7325",
                    "listingTime": 1651007700,
                    "expirationTime": 1653499413,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7325",
                    "makerAddr": "0xe794f5e304109E77FE7b7E6D5bD9B1D69C6560A8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55690000000000000000",
                    "tokenId": "10350",
                    "listingTime": 1651670160,
                    "expirationTime": 1659446150,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10350",
                    "makerAddr": "0x3C5F9A90393211d85300E6C0024aa13DD4229a22",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55690000000000000000",
                    "tokenId": "2054",
                    "listingTime": 1651074713,
                    "expirationTime": 1653666659,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2054",
                    "makerAddr": "0xb9b2355417d03F3716b6427E6407864F0b634744",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55900000000000000000",
                    "tokenId": "27659",
                    "listingTime": 1650850258,
                    "expirationTime": 1653442242,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27659",
                    "makerAddr": "0x2f670A086b5b426BA56086149Ca1904027E403b9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "55990000000000000000",
                    "tokenId": "20303",
                    "listingTime": 1650485738,
                    "expirationTime": 1652694617,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20303",
                    "makerAddr": "0x298d5537C181766D22a27D40b90A9c8ccA5b5aD5",
                    "assetImageUrl": ""
                }
            ],
            "total": 6.004418690000011e+21,
            "looksrare": 103,
            "opensea": 6
        },
        {
            "price": 56,
            "amount": 34,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56000000000000000000",
                    "tokenId": "6685",
                    "listingTime": 1650818346,
                    "expirationTime": 1653410322,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6685",
                    "makerAddr": "0x88889778402dcf6cF363bB9Ac47f7A2D8E77A2AF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56000000000000000000",
                    "tokenId": "9150",
                    "listingTime": 1650960215,
                    "expirationTime": 1653465468,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9150",
                    "makerAddr": "0x17b128B6771F5D26de3348db0cd222328cc1Eb47",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56000000000000000000",
                    "tokenId": "16800",
                    "listingTime": 1651196503,
                    "expirationTime": 1653788467,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16800",
                    "makerAddr": "0x2c3c28C8B928B85fe8cCb74B80BA22d300e35155",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56000000000000000000",
                    "tokenId": "100",
                    "listingTime": 1650824611,
                    "expirationTime": 1653416589,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/100",
                    "makerAddr": "0xc9ca66FB145E146812De286aC78a533718c493d4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56000000000000000000",
                    "tokenId": "15844",
                    "listingTime": 1650920043,
                    "expirationTime": 1653512002,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15844",
                    "makerAddr": "0x56E1E208fAc771d39486aCa9146B2c71C30751C5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56000000000000000000",
                    "tokenId": "27604",
                    "listingTime": 1651070407,
                    "expirationTime": 1653662309,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27604",
                    "makerAddr": "0x7599a1f7D44EEA1CFe86C75ceea54146719C9361",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56000000000000000000",
                    "tokenId": "19444",
                    "listingTime": 1651511033,
                    "expirationTime": 1654102919,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19444",
                    "makerAddr": "0xb96Bb58EBaE93765A23cdC950606a6619733F83C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56000000000000000000",
                    "tokenId": "9988",
                    "listingTime": 1650823087,
                    "expirationTime": 1653415056,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9988",
                    "makerAddr": "0x598fDd699C874440EbD31fdCF5cFfc1500735a9f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56000000000000000000",
                    "tokenId": "19114",
                    "listingTime": 1650818364,
                    "expirationTime": 1653410322,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19114",
                    "makerAddr": "0x88889778402dcf6cF363bB9Ac47f7A2D8E77A2AF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56000000000000000000",
                    "tokenId": "9688",
                    "listingTime": 1651224439,
                    "expirationTime": 1653816417,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9688",
                    "makerAddr": "0x52d53E394a5FA689359dE855aA0ABcEe4D8921bb",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56000000000000000000",
                    "tokenId": "6545",
                    "listingTime": 1650814924,
                    "expirationTime": 1653406914,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6545",
                    "makerAddr": "0xC778c7126678F1923f2c951488A4fE337bD15A08",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56000000000000000000",
                    "tokenId": "6206",
                    "listingTime": 1650814129,
                    "expirationTime": 1653406103,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6206",
                    "makerAddr": "0x4F582c02da18ce1B342C2EB38C015c8dc5C75c1c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56000000000000000000",
                    "tokenId": "27778",
                    "listingTime": 1651325438,
                    "expirationTime": 1653917279,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27778",
                    "makerAddr": "0xF6f65D23A9Fd1C907749EC9b3b34f8b838c0D278",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56000000000000000000",
                    "tokenId": "21710",
                    "listingTime": 1651119620,
                    "expirationTime": 1653711493,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21710",
                    "makerAddr": "0x9c814372667565d72306E2488B8F3BeFfAbfB867",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56000000000000000000",
                    "tokenId": "4088",
                    "listingTime": 1650832034,
                    "expirationTime": 1653407259,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4088",
                    "makerAddr": "0x3e9b7732616A6153D2Ba41f2198702299DB7cCa6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56000000000000000000",
                    "tokenId": "9961",
                    "listingTime": 1650971345,
                    "expirationTime": 1666407713,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9961",
                    "makerAddr": "0x70BAb06BA6b16387Ceb09B53F3c74D8809E3051a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56000000000000000000",
                    "tokenId": "2540",
                    "listingTime": 1651296160,
                    "expirationTime": 1653888120,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2540",
                    "makerAddr": "0x8e49718Bb7Eee02E41746AF8FF03cB7042fF0A76",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56000000000000000000",
                    "tokenId": "14730",
                    "listingTime": 1651257778,
                    "expirationTime": 1666809771,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14730",
                    "makerAddr": "0x497402DC41E79523500BE7C35193a5879298091e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56000000000000000000",
                    "tokenId": "5614",
                    "listingTime": 1650818327,
                    "expirationTime": 1653410322,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5614",
                    "makerAddr": "0x88889778402dcf6cF363bB9Ac47f7A2D8E77A2AF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56000000000000000000",
                    "tokenId": "2704",
                    "listingTime": 1650812232,
                    "expirationTime": 1653404223,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2704",
                    "makerAddr": "0x5181aAEfA633db3539382f8A19fc62E2Fb11975f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56000000000000000000",
                    "tokenId": "26420",
                    "listingTime": 1650901654,
                    "expirationTime": 1653493647,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26420",
                    "makerAddr": "0x407CAe1E3E4C30Bfd038FC8c686ACE87B59D33f4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56000000000000000000",
                    "tokenId": "8021",
                    "listingTime": 1650823117,
                    "expirationTime": 1653415081,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8021",
                    "makerAddr": "0x852E45eD1a6502406Af4c409DF4e0Eef3CC0A0E3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "63",
                    "price": "56000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651931274,
                    "expirationTime": 1652536162,
                    "makerAddr": "0x6a7efd5330e44cfed0ada70c00cbadb20ea51ec7",
                    "assetImageUrl": "https://img.seadn.io/files/4ff5cf52f714fe3e7265f1ce61568b75.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56200000000000000000",
                    "tokenId": "4398",
                    "listingTime": 1651215941,
                    "expirationTime": 1653807936,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4398",
                    "makerAddr": "0xad351c74790fB5D7ae357f27Cc04C712feBb6F11",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56235000000000000000",
                    "tokenId": "5182",
                    "listingTime": 1650832812,
                    "expirationTime": 1653424803,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5182",
                    "makerAddr": "0xE4F9a6Bb8C34166ef8D7b9bCe6B6F7177549E367",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56250000000000000000",
                    "tokenId": "3214",
                    "listingTime": 1650938104,
                    "expirationTime": 1653508499,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3214",
                    "makerAddr": "0xfAE17d7D6c277F7964c2871fC0Cb11AC91e698F6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56500000000000000000",
                    "tokenId": "1626",
                    "listingTime": 1650851921,
                    "expirationTime": 1653443769,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1626",
                    "makerAddr": "0xBe547aA1bf50FEcCa7d853adEa3aB6D5F85953F7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56500000000000000000",
                    "tokenId": "4088",
                    "listingTime": 1650815276,
                    "expirationTime": 1653407259,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4088",
                    "makerAddr": "0x3e9b7732616A6153D2Ba41f2198702299DB7cCa6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56500000000000000000",
                    "tokenId": "1122",
                    "listingTime": 1650852004,
                    "expirationTime": 1653443992,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1122",
                    "makerAddr": "0xBe547aA1bf50FEcCa7d853adEa3aB6D5F85953F7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56500000000000000000",
                    "tokenId": "9299",
                    "listingTime": 1651065707,
                    "expirationTime": 1653657671,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9299",
                    "makerAddr": "0xe7e4bfeEB83A5b752A4E90D4f89fa0B21FBB54b4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56550000000000000000",
                    "tokenId": "24448",
                    "listingTime": 1650813140,
                    "expirationTime": 1653405104,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24448",
                    "makerAddr": "0x3922B064DcC34289B2c04f82B92977c413a0dAd2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56690000000000000000",
                    "tokenId": "8914",
                    "listingTime": 1651235662,
                    "expirationTime": 1653827606,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8914",
                    "makerAddr": "0x300772D881e58A4eb2BB0844D5FC9B2e5476B31b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56850000000000000000",
                    "tokenId": "23418",
                    "listingTime": 1651066729,
                    "expirationTime": 1653658673,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23418",
                    "makerAddr": "0xf98fF587fEb37db9e6934DDaf1aB377240430753",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "56960000000000000000",
                    "tokenId": "16237",
                    "listingTime": 1651863611,
                    "expirationTime": 1652468398,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16237",
                    "makerAddr": "0x30f0149363f860Bd37015A77dA1Db8B5845545Cc",
                    "assetImageUrl": ""
                }
            ],
            "total": 1.9097349999999996e+21,
            "looksrare": 33,
            "opensea": 1
        },
        {
            "price": 57,
            "amount": 36,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "15688",
                    "listingTime": 1650879227,
                    "expirationTime": 1653471215,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15688",
                    "makerAddr": "0x7082dB9Ce4f520253217d8EB1fD6b6097B74c483",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "7564",
                    "listingTime": 1650824801,
                    "expirationTime": 1653416797,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7564",
                    "makerAddr": "0x598fDd699C874440EbD31fdCF5cFfc1500735a9f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "9783",
                    "listingTime": 1651328376,
                    "expirationTime": 1653920342,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9783",
                    "makerAddr": "0xCF827110a45441ca7CE03dF4c590A56c48B5DE2f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "907",
                    "listingTime": 1652068655,
                    "expirationTime": 1654365398,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/907",
                    "makerAddr": "0x3DE53471C5AADaac0beBeBB37268b7a7257d85F5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "5220",
                    "listingTime": 1650847250,
                    "expirationTime": 1653439247,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5220",
                    "makerAddr": "0x9f0d3E5aA86c242DbAB469486Fa4C2Ec04974A9a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "6201",
                    "listingTime": 1651004549,
                    "expirationTime": 1653596534,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6201",
                    "makerAddr": "0x5ac3cc3f4e5646125f46933acB54189871d73691",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "19762",
                    "listingTime": 1651297752,
                    "expirationTime": 1652507346,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19762",
                    "makerAddr": "0x33D4b1C41ba877E97cB366e4C53e426cc898896b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "2634",
                    "listingTime": 1649525007,
                    "expirationTime": 1656100454,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2634",
                    "makerAddr": "0xfAD18cC656a0261c154647f125cFFED27Fbfc083",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "444",
                    "listingTime": 1650845051,
                    "expirationTime": 1653437047,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/444",
                    "makerAddr": "0x450C9CE305795162b21Ae20513127B3FCa61eeD4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "7455",
                    "listingTime": 1650816856,
                    "expirationTime": 1653408852,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7455",
                    "makerAddr": "0x537AedBd6c7380392dB6458889cB463F47fc5b0e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "28930",
                    "listingTime": 1651297810,
                    "expirationTime": 1652507406,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28930",
                    "makerAddr": "0x33D4b1C41ba877E97cB366e4C53e426cc898896b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "2352",
                    "listingTime": 1652068672,
                    "expirationTime": 1654365372,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2352",
                    "makerAddr": "0x3DE53471C5AADaac0beBeBB37268b7a7257d85F5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "2108",
                    "listingTime": 1651297784,
                    "expirationTime": 1652507373,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2108",
                    "makerAddr": "0x33D4b1C41ba877E97cB366e4C53e426cc898896b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "2593",
                    "listingTime": 1650852072,
                    "expirationTime": 1653444064,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2593",
                    "makerAddr": "0xBe547aA1bf50FEcCa7d853adEa3aB6D5F85953F7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "1958",
                    "listingTime": 1650931790,
                    "expirationTime": 1653523783,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1958",
                    "makerAddr": "0xc00667d8B00f35B3565A5c4458Dff1Cd718E3527",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "3546",
                    "listingTime": 1651663284,
                    "expirationTime": 1652268079,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3546",
                    "makerAddr": "0x67c0baf8DDd82D39C844C9E62C34c42b3Da5229b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "8789",
                    "listingTime": 1651014906,
                    "expirationTime": 1653606871,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8789",
                    "makerAddr": "0x6257f35Bc3d4d2CDeA3E7D0CC02B1FA80e740eF4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "9967",
                    "listingTime": 1651280035,
                    "expirationTime": 1653871848,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9967",
                    "makerAddr": "0x440c7504C2FCaC49bA1cb429C6883F1E15819b30",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "26444",
                    "listingTime": 1650879319,
                    "expirationTime": 1653471315,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26444",
                    "makerAddr": "0x7082dB9Ce4f520253217d8EB1fD6b6097B74c483",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "5068",
                    "listingTime": 1650899318,
                    "expirationTime": 1653491259,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5068",
                    "makerAddr": "0x870C66e74c6691894b3718Fd51216E8940197504",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "11114",
                    "listingTime": 1650817497,
                    "expirationTime": 1653409494,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11114",
                    "makerAddr": "0xF83FC598fD45171d86f81ba032C46DC07aCc415f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "9745",
                    "listingTime": 1650858226,
                    "expirationTime": 1653450204,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9745",
                    "makerAddr": "0x74D8D562CafcF710C7f950Cf32501EF14b2642b1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "24455",
                    "listingTime": 1651454542,
                    "expirationTime": 1654046535,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24455",
                    "makerAddr": "0x08B9D2a0B3d4f99819e6c61cAe16ffb5A6bcd939",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "9759",
                    "listingTime": 1651381650,
                    "expirationTime": 1652591196,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9759",
                    "makerAddr": "0x4F33D206eAEccC0674158bCbCf44711B9454d7aC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "28104",
                    "listingTime": 1650817441,
                    "expirationTime": 1653409436,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28104",
                    "makerAddr": "0xF83FC598fD45171d86f81ba032C46DC07aCc415f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "11100",
                    "listingTime": 1651006470,
                    "expirationTime": 1658661900,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11100",
                    "makerAddr": "0x61a4FC9028048e562b2a49d8dccf2378B644dF03",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "9377",
                    "listingTime": 1651003949,
                    "expirationTime": 1653459459,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9377",
                    "makerAddr": "0x0B5489942688F16e6cd80C4Bd7100CBf9ecdC161",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57000000000000000000",
                    "tokenId": "16368",
                    "listingTime": 1651325485,
                    "expirationTime": 1653917473,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16368",
                    "makerAddr": "0xF6f65D23A9Fd1C907749EC9b3b34f8b838c0D278",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57500000000000000000",
                    "tokenId": "11100",
                    "listingTime": 1650955281,
                    "expirationTime": 1658661900,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11100",
                    "makerAddr": "0x61a4FC9028048e562b2a49d8dccf2378B644dF03",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57500000000000000000",
                    "tokenId": "2799",
                    "listingTime": 1650962311,
                    "expirationTime": 1653482039,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2799",
                    "makerAddr": "0xC0B956baD990CF94C46df2c2fc2BF189B89f9DcA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57500000000000000000",
                    "tokenId": "4560",
                    "listingTime": 1651763718,
                    "expirationTime": 1654365351,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4560",
                    "makerAddr": "0x3DE53471C5AADaac0beBeBB37268b7a7257d85F5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57600000000000000000",
                    "tokenId": "4976",
                    "listingTime": 1650996906,
                    "expirationTime": 1653588501,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4976",
                    "makerAddr": "0x125d1E1aa4bf5DE2222A7A26308d36BBa8253215",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57680000000000000000",
                    "tokenId": "7325",
                    "listingTime": 1650907557,
                    "expirationTime": 1653499413,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7325",
                    "makerAddr": "0xe794f5e304109E77FE7b7E6D5bD9B1D69C6560A8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57690000000000000000",
                    "tokenId": "9150",
                    "listingTime": 1650873477,
                    "expirationTime": 1653465468,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9150",
                    "makerAddr": "0x17b128B6771F5D26de3348db0cd222328cc1Eb47",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57750000000000000000",
                    "tokenId": "229",
                    "listingTime": 1650853681,
                    "expirationTime": 1653445649,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/229",
                    "makerAddr": "0x4b58F249B2B390125158237ec350C54E690AF477",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "57770000000000000000",
                    "tokenId": "9961",
                    "listingTime": 1650855742,
                    "expirationTime": 1666407713,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9961",
                    "makerAddr": "0x70BAb06BA6b16387Ceb09B53F3c74D8809E3051a",
                    "assetImageUrl": ""
                }
            ],
            "total": 2.0569900000000003e+21,
            "looksrare": 36
        },
        {
            "price": 58,
            "amount": 53,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "10736",
                    "listingTime": 1651427500,
                    "expirationTime": 1659203215,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10736",
                    "makerAddr": "0xb8adCC8E382A7C43BdFa2e1C47059a1aFEc10E06",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "3083",
                    "listingTime": 1650823099,
                    "expirationTime": 1653414972,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3083",
                    "makerAddr": "0xC344FCe3710d5971b24b11947535FAcE3eCd5655",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "9826",
                    "listingTime": 1651427333,
                    "expirationTime": 1659203215,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9826",
                    "makerAddr": "0xb8adCC8E382A7C43BdFa2e1C47059a1aFEc10E06",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "1165",
                    "listingTime": 1650848017,
                    "expirationTime": 1653440000,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1165",
                    "makerAddr": "0x8214Fc296391513943f7CA58733bb2074Bac807C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "5650",
                    "listingTime": 1650910920,
                    "expirationTime": 1653502779,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5650",
                    "makerAddr": "0x7b9CDCC6831796fCcB21b5f7241e2cEd813Efe92",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "1614",
                    "listingTime": 1651427552,
                    "expirationTime": 1659203215,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1614",
                    "makerAddr": "0xb8adCC8E382A7C43BdFa2e1C47059a1aFEc10E06",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "5639",
                    "listingTime": 1650879507,
                    "expirationTime": 1653471458,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5639",
                    "makerAddr": "0x30664a840eAA1E9D913f7eBaA3beEe6DbFEa9ceE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "2902",
                    "listingTime": 1651269523,
                    "expirationTime": 1652479116,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2902",
                    "makerAddr": "0x85FD8d14AF7bf91e2f1799f20AA64E5C1FC58357",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "2266",
                    "listingTime": 1650848053,
                    "expirationTime": 1653440044,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2266",
                    "makerAddr": "0x8214Fc296391513943f7CA58733bb2074Bac807C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "23850",
                    "listingTime": 1651427556,
                    "expirationTime": 1659203215,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23850",
                    "makerAddr": "0xb8adCC8E382A7C43BdFa2e1C47059a1aFEc10E06",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "3344",
                    "listingTime": 1651427349,
                    "expirationTime": 1659203215,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3344",
                    "makerAddr": "0xb8adCC8E382A7C43BdFa2e1C47059a1aFEc10E06",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "8443",
                    "listingTime": 1650853258,
                    "expirationTime": 1653445244,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8443",
                    "makerAddr": "0x29E21cC44b5DBC594542940D5658dEA2Cb500907",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "1935",
                    "listingTime": 1651333377,
                    "expirationTime": 1666885368,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1935",
                    "makerAddr": "0x7B220358c3EC9Ec03b08120DcCEC56F16Fbf33a4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "9675",
                    "listingTime": 1651427319,
                    "expirationTime": 1659203215,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9675",
                    "makerAddr": "0xb8adCC8E382A7C43BdFa2e1C47059a1aFEc10E06",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "7393",
                    "listingTime": 1651342355,
                    "expirationTime": 1653823312,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7393",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "2317",
                    "listingTime": 1651427463,
                    "expirationTime": 1659203215,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2317",
                    "makerAddr": "0xb8adCC8E382A7C43BdFa2e1C47059a1aFEc10E06",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "1071",
                    "listingTime": 1651276124,
                    "expirationTime": 1652485719,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1071",
                    "makerAddr": "0x3329f10842b2bD7B80Ec4E2C23C7241035e02C16",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "21970",
                    "listingTime": 1650881925,
                    "expirationTime": 1653473921,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21970",
                    "makerAddr": "0xBF21B6B77B893E11e96d79157Eb417F3e4E9538e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "964",
                    "listingTime": 1650863872,
                    "expirationTime": 1653455855,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/964",
                    "makerAddr": "0x04C074ea23094feB7B343de3f4d2AB110e4C6Dd1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "27838",
                    "listingTime": 1651258253,
                    "expirationTime": 1653850142,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27838",
                    "makerAddr": "0x58248bDF828eb44890fe2C71F292dfDBE15A1C55",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "8480",
                    "listingTime": 1651865082,
                    "expirationTime": 1659641074,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8480",
                    "makerAddr": "0x4593010f1e2fFf00573d634Ed7A931E85C1A8Aab",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "4703",
                    "listingTime": 1651352801,
                    "expirationTime": 1653944599,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4703",
                    "makerAddr": "0xdD929d6341B18f77a6a11Eb8c8db66df0C1a04D7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "4976",
                    "listingTime": 1650996551,
                    "expirationTime": 1653588501,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4976",
                    "makerAddr": "0x125d1E1aa4bf5DE2222A7A26308d36BBa8253215",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "28516",
                    "listingTime": 1650895537,
                    "expirationTime": 1653487398,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28516",
                    "makerAddr": "0x8b3477Ca7445ec524dd534fA4A596C993E93675a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "19246",
                    "listingTime": 1651427468,
                    "expirationTime": 1659203215,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19246",
                    "makerAddr": "0xb8adCC8E382A7C43BdFa2e1C47059a1aFEc10E06",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "4147",
                    "listingTime": 1651427338,
                    "expirationTime": 1659203215,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4147",
                    "makerAddr": "0xb8adCC8E382A7C43BdFa2e1C47059a1aFEc10E06",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "8323",
                    "listingTime": 1650899568,
                    "expirationTime": 1653491183,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8323",
                    "makerAddr": "0x870C66e74c6691894b3718Fd51216E8940197504",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "5383",
                    "listingTime": 1651342278,
                    "expirationTime": 1653823241,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5383",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "4329",
                    "listingTime": 1651466236,
                    "expirationTime": 1654058227,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4329",
                    "makerAddr": "0x7a19D662456b05d61E79C5f257a150A3e359101F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "3104",
                    "listingTime": 1650901857,
                    "expirationTime": 1653493849,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3104",
                    "makerAddr": "0xDfaf4022d9B6a08896569933D8F38F67E720CeB3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "28390",
                    "listingTime": 1651293738,
                    "expirationTime": 1653885527,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28390",
                    "makerAddr": "0x942510266d7c2954F74596aE61897eC3bD25eFD3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "9093",
                    "listingTime": 1651427326,
                    "expirationTime": 1659203215,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9093",
                    "makerAddr": "0xb8adCC8E382A7C43BdFa2e1C47059a1aFEc10E06",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "1135",
                    "listingTime": 1651217462,
                    "expirationTime": 1653809444,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1135",
                    "makerAddr": "0x2efBc44A485F13858A17CE33623e6C4c6AE7d01a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58000000000000000000",
                    "tokenId": "5148",
                    "listingTime": 1651350316,
                    "expirationTime": 1653942302,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5148",
                    "makerAddr": "0x75Cf2D77d40abd92756c704A963098a87b4e1aEC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58280000000000000000",
                    "tokenId": "13450",
                    "listingTime": 1651265589,
                    "expirationTime": 1653857576,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13450",
                    "makerAddr": "0xEBFb3B263f11BB1A9D6b14CaDE9A5E6C8EA4e07c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58350000000000000000",
                    "tokenId": "7907",
                    "listingTime": 1650854079,
                    "expirationTime": 1653446066,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7907",
                    "makerAddr": "0x8DD037eB27dBF4E464b1Bba1A014B956314DaD21",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58400000000000000000",
                    "tokenId": "4703",
                    "listingTime": 1651352832,
                    "expirationTime": 1653944599,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4703",
                    "makerAddr": "0xdD929d6341B18f77a6a11Eb8c8db66df0C1a04D7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58470000000000000000",
                    "tokenId": "4703",
                    "listingTime": 1651352746,
                    "expirationTime": 1653944599,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4703",
                    "makerAddr": "0xdD929d6341B18f77a6a11Eb8c8db66df0C1a04D7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58500000000000000000",
                    "tokenId": "1285",
                    "listingTime": 1650923135,
                    "expirationTime": 1653515128,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1285",
                    "makerAddr": "0x512C579153AC6fD961a7d9e7b19281B855AAfbE1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58500000000000000000",
                    "tokenId": "3613",
                    "listingTime": 1650917687,
                    "expirationTime": 1666469638,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3613",
                    "makerAddr": "0x25Ca2e79391AD17A73395f88985d33C121dF7d06",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58500000000000000000",
                    "tokenId": "4537",
                    "listingTime": 1651366746,
                    "expirationTime": 1653958648,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4537",
                    "makerAddr": "0xEd041E633f0e86500c1c25CeB75a5D4c7cb6fFf0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58500000000000000000",
                    "tokenId": "22002",
                    "listingTime": 1651308685,
                    "expirationTime": 1653900248,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22002",
                    "makerAddr": "0x5c35046B6D3440d9784D279aAe33B04A9A691BE1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58500000000000000000",
                    "tokenId": "11100",
                    "listingTime": 1650885932,
                    "expirationTime": 1658661900,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11100",
                    "makerAddr": "0x61a4FC9028048e562b2a49d8dccf2378B644dF03",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58500000000000000000",
                    "tokenId": "1283",
                    "listingTime": 1650923424,
                    "expirationTime": 1653515412,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1283",
                    "makerAddr": "0x512C579153AC6fD961a7d9e7b19281B855AAfbE1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58500000000000000000",
                    "tokenId": "9366",
                    "listingTime": 1651861343,
                    "expirationTime": 1652466028,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9366",
                    "makerAddr": "0x497008a83F34549918ED5AF7C0dd8E61eFEa44b8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58694200000000000000",
                    "tokenId": "1967",
                    "listingTime": 1650907380,
                    "expirationTime": 1653499354,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1967",
                    "makerAddr": "0x56E1E208fAc771d39486aCa9146B2c71C30751C5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58750000000000000000",
                    "tokenId": "3214",
                    "listingTime": 1650916516,
                    "expirationTime": 1653508499,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3214",
                    "makerAddr": "0xfAE17d7D6c277F7964c2871fC0Cb11AC91e698F6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58800000000000000000",
                    "tokenId": "7170",
                    "listingTime": 1650901718,
                    "expirationTime": 1653493696,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7170",
                    "makerAddr": "0x6D39B2F97bE14DeBc633C3A466A5134Bfe13a36b",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1955",
                    "price": "58880000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650863721,
                    "expirationTime": 1653455777,
                    "makerAddr": "0x138c1dc31a0c02a1f8c06159a502b3be690bbd42",
                    "assetImageUrl": "https://img.seadn.io/files/65c59ff48bed0be9cd5b0da635fa31b2.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58890000000000000000",
                    "tokenId": "7664",
                    "listingTime": 1652011880,
                    "expirationTime": 1652616671,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7664",
                    "makerAddr": "0x586AD2c218632a56057498d6aFda0bfeE5D5AD30",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58990000000000000000",
                    "tokenId": "13916",
                    "listingTime": 1650905689,
                    "expirationTime": 1653497667,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13916",
                    "makerAddr": "0xc1d0Bd227b19816b16E4956d42DAeC338e851834",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58990000000000000000",
                    "tokenId": "8565",
                    "listingTime": 1650905729,
                    "expirationTime": 1653497722,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8565",
                    "makerAddr": "0xc1d0Bd227b19816b16E4956d42DAeC338e851834",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "58990000000000000000",
                    "tokenId": "6055",
                    "listingTime": 1651319406,
                    "expirationTime": 1666871396,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6055",
                    "makerAddr": "0x02b2bab8458D1a47F9789580495284670b5f4299",
                    "assetImageUrl": ""
                }
            ],
            "total": 3.085984199999998e+21,
            "looksrare": 52,
            "opensea": 1
        },
        {
            "price": 59,
            "amount": 35,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59000000000000000000",
                    "tokenId": "5893",
                    "listingTime": 1651211347,
                    "expirationTime": 1653803334,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5893",
                    "makerAddr": "0x1FEACcB479834998BD7750754062347A6FaD8F9F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59000000000000000000",
                    "tokenId": "14673",
                    "listingTime": 1651905852,
                    "expirationTime": 1654098664,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14673",
                    "makerAddr": "0x924159Ba6142eEa6211cE1f5DD2944607376d028",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59000000000000000000",
                    "tokenId": "28137",
                    "listingTime": 1651905773,
                    "expirationTime": 1654098621,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28137",
                    "makerAddr": "0x924159Ba6142eEa6211cE1f5DD2944607376d028",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59000000000000000000",
                    "tokenId": "7097",
                    "listingTime": 1650855956,
                    "expirationTime": 1653447935,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7097",
                    "makerAddr": "0x683EbbA7A833A809071afb4819C0a2Dfd6e07333",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59000000000000000000",
                    "tokenId": "667",
                    "listingTime": 1651311647,
                    "expirationTime": 1653903639,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/667",
                    "makerAddr": "0x38d7eAd5550D20C518453C55feb8992B0F8BFfB2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59000000000000000000",
                    "tokenId": "5383",
                    "listingTime": 1651231266,
                    "expirationTime": 1653823241,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5383",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59000000000000000000",
                    "tokenId": "14320",
                    "listingTime": 1651237947,
                    "expirationTime": 1652447540,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14320",
                    "makerAddr": "0x1711D0178C219F33428614163F50275A8954793B",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59000000000000000000",
                    "tokenId": "15078",
                    "listingTime": 1650882829,
                    "expirationTime": 1653474819,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15078",
                    "makerAddr": "0x14210914D6A65Fa2C7747579A7aa287B027fD092",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59000000000000000000",
                    "tokenId": "16358",
                    "listingTime": 1651094531,
                    "expirationTime": 1666646522,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16358",
                    "makerAddr": "0x46A13b188365C40E5F4114a4CBd0D2425472d8D2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59000000000000000000",
                    "tokenId": "1330",
                    "listingTime": 1650874845,
                    "expirationTime": 1666425518,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1330",
                    "makerAddr": "0x3aF3A432D194C16b68f1a4a96dC59d92b6e13b19",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59000000000000000000",
                    "tokenId": "21424",
                    "listingTime": 1650207479,
                    "expirationTime": 1652799474,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21424",
                    "makerAddr": "0x28F8ca3B0EDdd849c93986dF0fd194252C4e4B03",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59000000000000000000",
                    "tokenId": "2799",
                    "listingTime": 1650890066,
                    "expirationTime": 1653482039,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2799",
                    "makerAddr": "0xC0B956baD990CF94C46df2c2fc2BF189B89f9DcA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59000000000000000000",
                    "tokenId": "14755",
                    "listingTime": 1651398406,
                    "expirationTime": 1666646613,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14755",
                    "makerAddr": "0x46A13b188365C40E5F4114a4CBd0D2425472d8D2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59000000000000000000",
                    "tokenId": "3926",
                    "listingTime": 1651229271,
                    "expirationTime": 1653821243,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3926",
                    "makerAddr": "0x2AD0fB45590be993f529D6365540bBA3E9ecbAc0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59000000000000000000",
                    "tokenId": "15238",
                    "listingTime": 1651233876,
                    "expirationTime": 1653825861,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15238",
                    "makerAddr": "0x512C579153AC6fD961a7d9e7b19281B855AAfbE1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59000000000000000000",
                    "tokenId": "7393",
                    "listingTime": 1651231321,
                    "expirationTime": 1653823312,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7393",
                    "makerAddr": "0x605d36F54546eaC8318deA61670e48db3A071A36",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59000000000000000000",
                    "tokenId": "23924",
                    "listingTime": 1650883584,
                    "expirationTime": 1653475571,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23924",
                    "makerAddr": "0xBF21B6B77B893E11e96d79157Eb417F3e4E9538e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59000000000000000000",
                    "tokenId": "6204",
                    "listingTime": 1650902703,
                    "expirationTime": 1653494606,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6204",
                    "makerAddr": "0xa0Be587B81F5f829f70c3329B3cA3868871a9024",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59000000000000000000",
                    "tokenId": "23699",
                    "listingTime": 1650871235,
                    "expirationTime": 1653463137,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23699",
                    "makerAddr": "0x352e32Ad9791C5cc8d8614cfD0748FA6500400fC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59000000000000000000",
                    "tokenId": "15106",
                    "listingTime": 1650892245,
                    "expirationTime": 1653463301,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15106",
                    "makerAddr": "0xc741EB22eD5500DdFb587D7bE04d22c0aDe3851C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "667",
                    "price": "59000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651311291,
                    "expirationTime": 1653903383,
                    "makerAddr": "0x38d7ead5550d20c518453c55feb8992b0f8bffb2",
                    "assetImageUrl": "https://img.seadn.io/files/80ea0d6b80d2964335b042c30eda87d0.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1330",
                    "price": "59000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650873430,
                    "expirationTime": 1666425518,
                    "makerAddr": "0x3af3a432d194c16b68f1a4a96dc59d92b6e13b19",
                    "assetImageUrl": "https://img.seadn.io/files/0c1f43504a43889b0acdd4f9d0e66e50.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59235000000000000000",
                    "tokenId": "1018",
                    "listingTime": 1650892002,
                    "expirationTime": 1653483873,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1018",
                    "makerAddr": "0x728663A4C9C5647B0cA4B8ad7F31903eEb468588",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59250000000000000000",
                    "tokenId": "1467",
                    "listingTime": 1651219926,
                    "expirationTime": 1653811913,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1467",
                    "makerAddr": "0x34f3802366A59d96565ffDEd9BB601d5e28A3b82",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59500000000000000000",
                    "tokenId": "25702",
                    "listingTime": 1651207457,
                    "expirationTime": 1653799437,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25702",
                    "makerAddr": "0xe315eB04Fc42bEa83Ed213582e66051050A92F1c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59820000000000000000",
                    "tokenId": "2482",
                    "listingTime": 1651303803,
                    "expirationTime": 1653895721,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2482",
                    "makerAddr": "0x8fa687316Ca25CC295F96806D29aa5075A4E5427",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59830000000000000000",
                    "tokenId": "9377",
                    "listingTime": 1650867480,
                    "expirationTime": 1653459459,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9377",
                    "makerAddr": "0x0B5489942688F16e6cd80C4Bd7100CBf9ecdC161",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59880000000000000000",
                    "tokenId": "2429",
                    "listingTime": 1650731814,
                    "expirationTime": 1653323790,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2429",
                    "makerAddr": "0xD665b2C7Fd357B283d19eEC7Bf00edf53E7CF4a8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59900000000000000000",
                    "tokenId": "2352",
                    "listingTime": 1651763696,
                    "expirationTime": 1654365372,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2352",
                    "makerAddr": "0x3DE53471C5AADaac0beBeBB37268b7a7257d85F5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59900000000000000000",
                    "tokenId": "18102",
                    "listingTime": 1650803549,
                    "expirationTime": 1653395493,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18102",
                    "makerAddr": "0x9Ba455Ad9aDd86D9F531593dd7c09e59D120625f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59900000000000000000",
                    "tokenId": "11895",
                    "listingTime": 1650815590,
                    "expirationTime": 1653407581,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11895",
                    "makerAddr": "0xaf3fEebE581668804064daBc8EeDbFFa485F4AFE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59900000000000000000",
                    "tokenId": "907",
                    "listingTime": 1651752032,
                    "expirationTime": 1654365398,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/907",
                    "makerAddr": "0x3DE53471C5AADaac0beBeBB37268b7a7257d85F5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "nftx",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "paymentToken": "ETH",
                    "tokenId": "25340@-@25256@-@6927@-@24896@-@3176@-@3364@-@8201@-@9725@-@12630@-@17492@-@21640@-@22488@-@24406@-@5512@-@25706@-@833@-@29676@-@16656",
                    "price": 59909919912911045000,
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2429",
                    "price": "59980000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650731501,
                    "expirationTime": 1653323566,
                    "makerAddr": "0xd665b2c7fd357b283d19eec7bf00edf53e7cf4a8",
                    "assetImageUrl": "https://img.seadn.io/files/5090c2629aa522ca70a4ee669957bb83.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "59990000000000000000",
                    "tokenId": "13835",
                    "listingTime": 1650207983,
                    "expirationTime": 1652799976,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13835",
                    "makerAddr": "0x28F8ca3B0EDdd849c93986dF0fd194252C4e4B03",
                    "assetImageUrl": ""
                }
            ],
            "total": 2.0749949199129117e+21,
            "looksrare": 31,
            "opensea": 3,
            "nftx": 1
        },
        {
            "price": 60,
            "amount": 26,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "60000000000000000000",
                    "tokenId": "4488",
                    "listingTime": 1650897372,
                    "expirationTime": 1654007700,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4488",
                    "makerAddr": "0xF7834A38c8De5539b45cE56cD3F968066Bff01dF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "60000000000000000000",
                    "tokenId": "14600",
                    "listingTime": 1651464720,
                    "expirationTime": 1659240714,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14600",
                    "makerAddr": "0x7f873eC58D567e78A2C1DCa322CbDB6d490cCB7D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "60000000000000000000",
                    "tokenId": "216",
                    "listingTime": 1652177408,
                    "expirationTime": 1654769390,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/216",
                    "makerAddr": "0x761f93a01CeE52F499C6AB0ceAE141E815Ff6db9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "60000000000000000000",
                    "tokenId": "4874",
                    "listingTime": 1651922045,
                    "expirationTime": 1662049588,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4874",
                    "makerAddr": "0x39C4B7E09Bb76eB59FB817D90057413265C1Fe51",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "60000000000000000000",
                    "tokenId": "7931",
                    "listingTime": 1652068692,
                    "expirationTime": 1654365311,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7931",
                    "makerAddr": "0x3DE53471C5AADaac0beBeBB37268b7a7257d85F5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "60000000000000000000",
                    "tokenId": "26105",
                    "listingTime": 1651377986,
                    "expirationTime": 1653969980,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26105",
                    "makerAddr": "0x02F4a053672c69a64a021d59b1B292d40F17FeBD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "60000000000000000000",
                    "tokenId": "20920",
                    "listingTime": 1651282242,
                    "expirationTime": 1653874229,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20920",
                    "makerAddr": "0xb97A377229bFf1BED964a82b09167D4CC98e2e27",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "60000000000000000000",
                    "tokenId": "19694",
                    "listingTime": 1650846653,
                    "expirationTime": 1653438548,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19694",
                    "makerAddr": "0x8C11E74e933f0348C1DEE77455b6d30F00642E56",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "60000000000000000000",
                    "tokenId": "7740",
                    "listingTime": 1652034096,
                    "expirationTime": 1652228460,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7740",
                    "makerAddr": "0x5cc0C6Bc29d7FFE87340718B5983F54af795cAcb",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "60000000000000000000",
                    "tokenId": "6373",
                    "listingTime": 1650921552,
                    "expirationTime": 1653391192,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6373",
                    "makerAddr": "0xE9FbEbb23e5CCC02EEe6E751adA3f3BbD20C630D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "60000000000000000000",
                    "tokenId": "29274",
                    "listingTime": 1651420997,
                    "expirationTime": 1654012992,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29274",
                    "makerAddr": "0xC9132a3f53C1c80C87d5d645d8dAFAe9DD3b8981",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "60000000000000000000",
                    "tokenId": "18711",
                    "listingTime": 1650906468,
                    "expirationTime": 1653498443,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18711",
                    "makerAddr": "0x45DdD82de491E3005B4D600ebC7C8b4fa6430378",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "60000000000000000000",
                    "tokenId": "2570",
                    "listingTime": 1650821361,
                    "expirationTime": 1653413357,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2570",
                    "makerAddr": "0x685Cb9E2035d915331379Dcbf09D18084f126bA7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "60000000000000000000",
                    "tokenId": "18737",
                    "listingTime": 1650821392,
                    "expirationTime": 1653413388,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/18737",
                    "makerAddr": "0x685Cb9E2035d915331379Dcbf09D18084f126bA7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "60000000000000000000",
                    "tokenId": "5566",
                    "listingTime": 1651578025,
                    "expirationTime": 1654066075,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5566",
                    "makerAddr": "0x4A5139eb46FB4De32D838D66c52846618C57Cb37",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "60000000000000000000",
                    "tokenId": "28093",
                    "listingTime": 1652103754,
                    "expirationTime": 1659879466,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28093",
                    "makerAddr": "0xD204e260ac2b6295298151E6AaadC2C6aF29f780",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "60000000000000000000",
                    "tokenId": "9230",
                    "listingTime": 1650863409,
                    "expirationTime": 1653455404,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9230",
                    "makerAddr": "0x89688542873276Cd7d9Cea936b25F37778a593fd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "496",
                    "price": "60000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652197523,
                    "expirationTime": 1652456087,
                    "makerAddr": "0x0fb3fa40850c1e472b6eced8fb10781763d42193",
                    "assetImageUrl": "https://img.seadn.io/files/18d8c2c221ce3a4a6e986cf8a7b8bb70.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1731",
                    "price": "60000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651697523,
                    "expirationTime": 1652302402,
                    "makerAddr": "0x94f2c4985cb5774a3d05b8c8eb90bd2152f3a3c2",
                    "assetImageUrl": "https://img.seadn.io/files/7102f7702e3b29d72a6fe547160ae952.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2367",
                    "price": "60000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651690656,
                    "expirationTime": 1654369147,
                    "makerAddr": "0x28a1cb02df1509a6c6de223053396e54ea15be37",
                    "assetImageUrl": "https://img.seadn.io/files/65a566a8c21b2b76de3ba6124a621742.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2932",
                    "price": "60000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650883007,
                    "expirationTime": 1653475099,
                    "makerAddr": "0x3b63410fadbda0ec0ba932fad2997edd7a842679",
                    "assetImageUrl": "https://img.seadn.io/files/3bd0894297e162db48ac4222579a2c95.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3251",
                    "price": "60000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1645225948,
                    "expirationTime": 1652717960,
                    "makerAddr": "0x963ecf0410877cac0a64a4ae076bf5499f8cd278",
                    "assetImageUrl": "https://img.seadn.io/files/d0fdc1f4e8da5cb6c3e2d4c1767c5725.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "60500000000000000000",
                    "tokenId": "497",
                    "listingTime": 1651261143,
                    "expirationTime": 1653853116,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/497",
                    "makerAddr": "0xf98fF587fEb37db9e6934DDaf1aB377240430753",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "60500000000000000000",
                    "tokenId": "8995",
                    "listingTime": 1651261140,
                    "expirationTime": 1653853116,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8995",
                    "makerAddr": "0xf98fF587fEb37db9e6934DDaf1aB377240430753",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "60690000000000000000",
                    "tokenId": "19818",
                    "listingTime": 1651875824,
                    "expirationTime": 1654467815,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19818",
                    "makerAddr": "0xA490A0346808dDA91aea6698cB19e4697D9fc5cc",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "478",
                    "price": "60690000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650311434,
                    "expirationTime": 1652903491,
                    "makerAddr": "0x89e8b62ceb9b6bd794dff3f0005784f9617662b9",
                    "assetImageUrl": "https://img.seadn.io/files/0847db31be84686334902c6af8a592b0.png?auto=format"
                }
            ],
            "total": 1.5623799999999996e+21,
            "looksrare": 20,
            "opensea": 6
        },
        {
            "price": 61,
            "amount": 2,
            "orders": [
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1361",
                    "price": "61000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650397594,
                    "expirationTime": 1652989686,
                    "makerAddr": "0x5cfba84ee736ce1fc1c84ee422d9776a45587641",
                    "assetImageUrl": "https://img.seadn.io/files/264f2e7410ae1fcc7f219a50a6016e7f.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "61190000000000000000",
                    "tokenId": "1632",
                    "listingTime": 1650443294,
                    "expirationTime": 1653035239,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1632",
                    "makerAddr": "0x06b27Bd711c680eC5ba342fb1C5364B1B8CD62fd",
                    "assetImageUrl": ""
                }
            ],
            "total": 122190000000000000000,
            "opensea": 1,
            "looksrare": 1
        },
        {
            "price": 62,
            "amount": 2,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "62000000000000000000",
                    "tokenId": "21135",
                    "listingTime": 1650548443,
                    "expirationTime": 1653140404,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21135",
                    "makerAddr": "0x221B862A33e4b6DeA8C7e5457f9C03A6852297DA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "62890000000000000000",
                    "tokenId": "6289",
                    "listingTime": 1651737063,
                    "expirationTime": 1652946659,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6289",
                    "makerAddr": "0x1324696538c238dfA5B22E019e6a0243c701a3Fc",
                    "assetImageUrl": ""
                }
            ],
            "total": 124890000000000000000,
            "looksrare": 2
        },
        {
            "price": 63,
            "amount": 3,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "63000000000000000000",
                    "tokenId": "4019",
                    "listingTime": 1650874753,
                    "expirationTime": 1666426394,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4019",
                    "makerAddr": "0x3aF3A432D194C16b68f1a4a96dC59d92b6e13b19",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "63000000000000000000",
                    "tokenId": "2976",
                    "listingTime": 1651311681,
                    "expirationTime": 1653903673,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2976",
                    "makerAddr": "0x38d7eAd5550D20C518453C55feb8992B0F8BFfB2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2976",
                    "price": "63000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651311334,
                    "expirationTime": 1653903426,
                    "makerAddr": "0x38d7ead5550d20c518453c55feb8992b0f8bffb2",
                    "assetImageUrl": "https://img.seadn.io/files/70fa78c8739133bb98e06accbf22d10f.png?auto=format"
                }
            ],
            "total": 189000000000000000000,
            "looksrare": 2,
            "opensea": 1
        },
        {
            "price": 64,
            "amount": 6,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "64000000000000000000",
                    "tokenId": "29037",
                    "listingTime": 1651827592,
                    "expirationTime": 1652432392,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29037",
                    "makerAddr": "0xCB2Df089d65dD72589C209Daf7893F03C1ab677F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "64000000000000000000",
                    "tokenId": "1595",
                    "listingTime": 1650874830,
                    "expirationTime": 1666425852,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1595",
                    "makerAddr": "0x3aF3A432D194C16b68f1a4a96dC59d92b6e13b19",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1595",
                    "price": "64000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650873802,
                    "expirationTime": 1666425852,
                    "makerAddr": "0x3af3a432d194c16b68f1a4a96dc59d92b6e13b19",
                    "assetImageUrl": "https://img.seadn.io/files/6e8819632d71670f4b439cafeb0c909f.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "64200000000000000000",
                    "tokenId": "14213",
                    "listingTime": 1650761956,
                    "expirationTime": 1653353935,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14213",
                    "makerAddr": "0x1A16c87927570239FECD343ad2654fD81682725e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "64269000000000000000",
                    "tokenId": "2358",
                    "listingTime": 1651091411,
                    "expirationTime": 1653339368,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2358",
                    "makerAddr": "0xA9Fe952EdD2958aB7Dea126c6d8B4413AfD3E771",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2358",
                    "price": "64269000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650747292,
                    "expirationTime": 1653339368,
                    "makerAddr": "0xa9fe952edd2958ab7dea126c6d8b4413afd3e771",
                    "assetImageUrl": "https://img.seadn.io/files/3105c4ab93d56995b68392b1f48e8777.png?auto=format"
                }
            ],
            "total": 384737999999999930000,
            "looksrare": 4,
            "opensea": 2
        },
        {
            "price": 65,
            "amount": 9,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "65000000000000000000",
                    "tokenId": "8548",
                    "listingTime": 1651621718,
                    "expirationTime": 1654213661,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8548",
                    "makerAddr": "0xDe112B77A1a72c726D8C914c23dCEaA53273c351",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "65000000000000000000",
                    "tokenId": "22846",
                    "listingTime": 1651740515,
                    "expirationTime": 1654332463,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22846",
                    "makerAddr": "0xf3aFc2383a0B45ae73D77B49dF7F2184B1Ad4B90",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "65000000000000000000",
                    "tokenId": "6861",
                    "listingTime": 1645119969,
                    "expirationTime": 1652892352,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6861",
                    "makerAddr": "0xe0e589E9D0049169DA99B4E8ef74A1b71adCD291",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "65000000000000000000",
                    "tokenId": "7931",
                    "listingTime": 1651763731,
                    "expirationTime": 1654365311,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7931",
                    "makerAddr": "0x3DE53471C5AADaac0beBeBB37268b7a7257d85F5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "65000000000000000000",
                    "tokenId": "1514",
                    "listingTime": 1652102402,
                    "expirationTime": 1652707199,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1514",
                    "makerAddr": "0xd7CdB08EaDd3D730540817a348b62D15BF45DF10",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "65000000000000000000",
                    "tokenId": "24679",
                    "listingTime": 1651453098,
                    "expirationTime": 1654045097,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24679",
                    "makerAddr": "0x30a5Ca69387c989b42F1Fc344762dCc6c2D728a8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "65000000000000000000",
                    "tokenId": "4228",
                    "listingTime": 1651621745,
                    "expirationTime": 1654213661,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4228",
                    "makerAddr": "0xDe112B77A1a72c726D8C914c23dCEaA53273c351",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "65000000000000000000",
                    "tokenId": "26619",
                    "listingTime": 1652104413,
                    "expirationTime": 1659880400,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26619",
                    "makerAddr": "0xD204e260ac2b6295298151E6AaadC2C6aF29f780",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "65000000000000000000",
                    "tokenId": "14091",
                    "listingTime": 1651039253,
                    "expirationTime": 1653463555,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14091",
                    "makerAddr": "0xA2e8AC1C7B5c337f540f0FD57360dD6e3840adF6",
                    "assetImageUrl": ""
                }
            ],
            "total": 585000000000000000000,
            "looksrare": 9
        },
        {
            "price": 66,
            "amount": 6,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "66000000000000000000",
                    "tokenId": "23747",
                    "listingTime": 1650555703,
                    "expirationTime": 1653147443,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23747",
                    "makerAddr": "0x44b51387773ce3581156d9accb27849a204F31DC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "66000000000000000000",
                    "tokenId": "25331",
                    "listingTime": 1651468805,
                    "expirationTime": 1653713764,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25331",
                    "makerAddr": "0xeFC126F6cc7b1A6Ac1EF5718366990D9F7266828",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "66600000000000000000",
                    "tokenId": "25789",
                    "listingTime": 1651931630,
                    "expirationTime": 1653141226,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25789",
                    "makerAddr": "0x8Bc47bE1e3ABBaBa182069C89d08a61FA6C2B292",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "66600000000000000000",
                    "tokenId": "7385",
                    "listingTime": 1651784389,
                    "expirationTime": 1652389139,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7385",
                    "makerAddr": "0x73584c8916b3AF8210913496f817394ED49ad80d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "66660000000000000000",
                    "tokenId": "478",
                    "listingTime": 1650313660,
                    "expirationTime": 1652905645,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/478",
                    "makerAddr": "0x89E8b62Ceb9b6bd794dFF3F0005784F9617662B9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2422",
                    "price": "66660000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651978780,
                    "expirationTime": 1652583661,
                    "makerAddr": "0x1a9f8946df95824679eb82a9549d1f6f1f9a4d69",
                    "assetImageUrl": "https://img.seadn.io/files/ed768c97d81b8a338313153765612839.png?auto=format"
                }
            ],
            "total": 398520000000000000000,
            "looksrare": 5,
            "opensea": 1
        },
        {
            "price": 68,
            "amount": 2,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "68000000000000000000",
                    "tokenId": "5566",
                    "listingTime": 1651565710,
                    "expirationTime": 1654066075,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5566",
                    "makerAddr": "0x4A5139eb46FB4De32D838D66c52846618C57Cb37",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "68420000000000000000",
                    "tokenId": "7215",
                    "listingTime": 1645670920,
                    "expirationTime": 1653446801,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7215",
                    "makerAddr": "0x2B465211142A911C4Ad7157F0f9Be82831669aD6",
                    "assetImageUrl": ""
                }
            ],
            "total": 136420000000000000000,
            "looksrare": 2
        },
        {
            "price": 69,
            "amount": 33,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "69000000000000000000",
                    "tokenId": "2806",
                    "listingTime": 1650509146,
                    "expirationTime": 1653101137,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2806",
                    "makerAddr": "0xBdA35C85e2E732398f0B334d6570f6c7041566a7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "69000000000000000000",
                    "tokenId": "6336",
                    "listingTime": 1651805467,
                    "expirationTime": 1667333061,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6336",
                    "makerAddr": "0xFF9c6c4472231a5a9C99B1393c96f50dad9f5DF8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "69000000000000000000",
                    "tokenId": "7069",
                    "listingTime": 1651805479,
                    "expirationTime": 1667333002,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7069",
                    "makerAddr": "0xFF9c6c4472231a5a9C99B1393c96f50dad9f5DF8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "69000000000000000000",
                    "tokenId": "7284",
                    "listingTime": 1651073265,
                    "expirationTime": 1653665120,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7284",
                    "makerAddr": "0x2d2f5cDda1e14ed817877ED05c1830FC25dDBA9a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "69000000000000000000",
                    "tokenId": "1574",
                    "listingTime": 1651683392,
                    "expirationTime": 1654275377,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1574",
                    "makerAddr": "0xB0Cd110791dB97601CfA6c38aD38952ebd739ba5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "69000000000000000000",
                    "tokenId": "14755",
                    "listingTime": 1651094618,
                    "expirationTime": 1666646613,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14755",
                    "makerAddr": "0x46A13b188365C40E5F4114a4CBd0D2425472d8D2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "69000000000000000000",
                    "tokenId": "26418",
                    "listingTime": 1650488687,
                    "expirationTime": 1653080370,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26418",
                    "makerAddr": "0x5080b717E5ed21AdC5EEFAC5476F63CF3E454a5D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "69000000000000000000",
                    "tokenId": "11025",
                    "listingTime": 1651506046,
                    "expirationTime": 1667058030,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11025",
                    "makerAddr": "0x99d81318e6dC3933Ff30325Aa2283474981c4E7D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "69000000000000000000",
                    "tokenId": "20361",
                    "listingTime": 1651697928,
                    "expirationTime": 1654289919,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20361",
                    "makerAddr": "0x69bAb6810fa99475854bca0A3DD72aE6a0728ECE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "69000000000000000000",
                    "tokenId": "10047",
                    "listingTime": 1650530264,
                    "expirationTime": 1666082247,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10047",
                    "makerAddr": "0x81FbeD0D287bFBe99A02Da937B5FCAb85C95142C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "69000000000000000000",
                    "tokenId": "19457",
                    "listingTime": 1651905644,
                    "expirationTime": 1654497640,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19457",
                    "makerAddr": "0x924159Ba6142eEa6211cE1f5DD2944607376d028",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "69000000000000000000",
                    "tokenId": "4601",
                    "listingTime": 1650509097,
                    "expirationTime": 1653101077,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4601",
                    "makerAddr": "0xBdA35C85e2E732398f0B334d6570f6c7041566a7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "69000000000000000000",
                    "tokenId": "2888",
                    "listingTime": 1650598011,
                    "expirationTime": 1653189899,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2888",
                    "makerAddr": "0x918D2cFF3c79acC9feB19461728f127D690B5C56",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "69000000000000000000",
                    "tokenId": "28137",
                    "listingTime": 1651506626,
                    "expirationTime": 1654098621,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28137",
                    "makerAddr": "0x924159Ba6142eEa6211cE1f5DD2944607376d028",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "69000000000000000000",
                    "tokenId": "4245",
                    "listingTime": 1651846760,
                    "expirationTime": 1652451559,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4245",
                    "makerAddr": "0x8a08e3Ce6CED24d376a13C544E45d4DDa02FaFEA",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "69000000000000000000",
                    "tokenId": "10445",
                    "listingTime": 1651827564,
                    "expirationTime": 1652432363,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10445",
                    "makerAddr": "0xCB2Df089d65dD72589C209Daf7893F03C1ab677F",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "69000000000000000000",
                    "tokenId": "9874",
                    "listingTime": 1650136003,
                    "expirationTime": 1652727995,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9874",
                    "makerAddr": "0x54950EEf3B0e890D0bf17Fdbbdee8e4920BE7c13",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "59",
                    "price": "69000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651951886,
                    "expirationTime": 1654630377,
                    "makerAddr": "0x918d2cff3c79acc9feb19461728f127d690b5c56",
                    "assetImageUrl": "https://img.seadn.io/files/ef718e68f36a786938774faf208d686b.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1262",
                    "price": "69000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650521452,
                    "expirationTime": 1653113505,
                    "makerAddr": "0xe79b2f2e1597c4eb0fd488a4812881634dcff5c4",
                    "assetImageUrl": "https://img.seadn.io/files/80e8b456cc67a2c6c9fe7cc4f0afe925.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1461",
                    "price": "69000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651786080,
                    "expirationTime": 1654464520,
                    "makerAddr": "0x5b83ac19637d3bd535623fc1a6e5b872d46ac120",
                    "assetImageUrl": "https://img.seadn.io/files/4031fb01150082b7d1dcf00ea898a595.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1803",
                    "price": "69000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650888615,
                    "expirationTime": 1652875860,
                    "makerAddr": "0x54877dd41efafc0dff58d304a330d7baf7b14056",
                    "assetImageUrl": "https://img.seadn.io/files/2e664f58c5323986a55fd31168886526.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2093",
                    "price": "69000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652197372,
                    "expirationTime": 1652802253,
                    "makerAddr": "0xfd849d8cf365fe98bc60025ff7fab45cef0f557b",
                    "assetImageUrl": "https://img.seadn.io/files/7ae850a8707da38c2d294c48dd7e75a4.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2888",
                    "price": "69000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650511544,
                    "expirationTime": 1653103639,
                    "makerAddr": "0x918d2cff3c79acc9feb19461728f127d690b5c56",
                    "assetImageUrl": "https://img.seadn.io/files/9d5b11cd4c11bf54454a0f3d384a021c.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "69420000000000000000",
                    "tokenId": "29486",
                    "listingTime": 1650298081,
                    "expirationTime": 1665850030,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29486",
                    "makerAddr": "0x71e73c6b428eb790C8ba3c9898601E88dd4108bC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "724",
                    "price": "69420000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651369262,
                    "expirationTime": 1653482293,
                    "makerAddr": "0xdf37cab4c874d20ce3e07171a0507aafaad794bd",
                    "assetImageUrl": "https://img.seadn.io/files/843b230796c1878fbd0243cbee2573ec.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "69690000000000000000",
                    "tokenId": "4874",
                    "listingTime": 1650924622,
                    "expirationTime": 1662049588,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4874",
                    "makerAddr": "0x39C4B7E09Bb76eB59FB817D90057413265C1Fe51",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "69690000000000000000",
                    "tokenId": "4904",
                    "listingTime": 1651263026,
                    "expirationTime": 1653854989,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4904",
                    "makerAddr": "0x50475C64BDC135D3Ca26834581776908bFd2Ab09",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "69690000000000000000",
                    "tokenId": "19781",
                    "listingTime": 1651862834,
                    "expirationTime": 1654540314,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19781",
                    "makerAddr": "0xaC0f76A8c30E23bc197D26465e7bE319713c5224",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "69690000000000000000",
                    "tokenId": "4850",
                    "listingTime": 1651763077,
                    "expirationTime": 1659539068,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4850",
                    "makerAddr": "0x35D82885ffDf96376E7c363e33676F6fF84806C2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1806",
                    "price": "69690000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650503558,
                    "expirationTime": 1653095638,
                    "makerAddr": "0x14bb253dd4aec3f1d8956a2161faacdc31a2de21",
                    "assetImageUrl": "https://img.seadn.io/files/9addccb0570033f921d6c2c8494e4a94.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2855",
                    "price": "69690000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651957404,
                    "expirationTime": 1652216691,
                    "makerAddr": "0x660d8f3e0c3169aa1dd0e2ec0bd32e7afb4f659f",
                    "assetImageUrl": "https://img.seadn.io/files/4edcdb392a3f9fd85ce7c9007ca6c954.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1314",
                    "price": "69960000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1647075804,
                    "expirationTime": 1662624290,
                    "makerAddr": "0x76b8a63a10fb88228b4910d92a7da7f8d3145921",
                    "assetImageUrl": "https://img.seadn.io/files/50e021825b8d5e07ea81ab6211da1537.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "69990000000000000000",
                    "tokenId": "2189",
                    "listingTime": 1650948629,
                    "expirationTime": 1653540623,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2189",
                    "makerAddr": "0x2b18e9c6B073F368C63D6E372380653eDd945B42",
                    "assetImageUrl": ""
                }
            ],
            "total": 2.2839299999999997e+21,
            "looksrare": 23,
            "opensea": 10
        },
        {
            "price": 70,
            "amount": 9,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "70000000000000000000",
                    "tokenId": "8680",
                    "listingTime": 1651300560,
                    "expirationTime": 1653892544,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8680",
                    "makerAddr": "0x873A9b94b5239c77188535716b1fc16c37871351",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "70000000000000000000",
                    "tokenId": "5099",
                    "listingTime": 1651558960,
                    "expirationTime": 1654150889,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5099",
                    "makerAddr": "0x2ba77503bA95CDa28A4015C73eC88B60516fb91d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "70000000000000000000",
                    "tokenId": "5840",
                    "listingTime": 1650704039,
                    "expirationTime": 1653295833,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5840",
                    "makerAddr": "0x352e32Ad9791C5cc8d8614cfD0748FA6500400fC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "70000000000000000000",
                    "tokenId": "1106",
                    "listingTime": 1650704048,
                    "expirationTime": 1653295833,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1106",
                    "makerAddr": "0x352e32Ad9791C5cc8d8614cfD0748FA6500400fC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "70000000000000000000",
                    "tokenId": "22002",
                    "listingTime": 1651308302,
                    "expirationTime": 1653900248,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22002",
                    "makerAddr": "0x5c35046B6D3440d9784D279aAe33B04A9A691BE1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "70000000000000000000",
                    "tokenId": "6440",
                    "listingTime": 1651732233,
                    "expirationTime": 1654057919,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6440",
                    "makerAddr": "0x03f609e5FdDDc7D5dDDc959bDedfe58b840C68E1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "492",
                    "price": "70000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651455987,
                    "expirationTime": 1652759072,
                    "makerAddr": "0xdb80d7b0806ae8be1c919764d641706a3ea5a474",
                    "assetImageUrl": "https://img.seadn.io/files/c72e558f3b8d8f660092b0701d2a413f.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1106",
                    "price": "70000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650704479,
                    "expirationTime": 1653296571,
                    "makerAddr": "0x352e32ad9791c5cc8d8614cfd0748fa6500400fc",
                    "assetImageUrl": "https://img.seadn.io/files/13220d3499b375b3ca9e41098d3f6c76.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2481",
                    "price": "70000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651805238,
                    "expirationTime": 1667357284,
                    "makerAddr": "0xad4176104ebf1a471db5a496f7778f7ff5fca429",
                    "assetImageUrl": "https://img.seadn.io/files/4924d08be4428ff9eb79e0be312a8c05.png?auto=format"
                }
            ],
            "total": 630000000000000000000,
            "looksrare": 6,
            "opensea": 3
        },
        {
            "price": 72,
            "amount": 3,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "72000000000000000000",
                    "tokenId": "5566",
                    "listingTime": 1651474088,
                    "expirationTime": 1654066075,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5566",
                    "makerAddr": "0x4A5139eb46FB4De32D838D66c52846618C57Cb37",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "72000000000000000000",
                    "tokenId": "724",
                    "listingTime": 1650889816,
                    "expirationTime": 1653481796,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/724",
                    "makerAddr": "0xDF37Cab4c874D20Ce3e07171A0507aAfAad794BD",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "72000000000000000000",
                    "tokenId": "17117",
                    "listingTime": 1650874816,
                    "expirationTime": 1666425956,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17117",
                    "makerAddr": "0x3aF3A432D194C16b68f1a4a96dC59d92b6e13b19",
                    "assetImageUrl": ""
                }
            ],
            "total": 216000000000000000000,
            "looksrare": 3
        },
        {
            "price": 73,
            "amount": 2,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "73000000000000000000",
                    "tokenId": "7046",
                    "listingTime": 1650874787,
                    "expirationTime": 1666426056,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7046",
                    "makerAddr": "0x3aF3A432D194C16b68f1a4a96dC59d92b6e13b19",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "73000000000000000000",
                    "tokenId": "24665",
                    "listingTime": 1650874801,
                    "expirationTime": 1666426006,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24665",
                    "makerAddr": "0x3aF3A432D194C16b68f1a4a96dC59d92b6e13b19",
                    "assetImageUrl": ""
                }
            ],
            "total": 146000000000000000000,
            "looksrare": 2
        },
        {
            "price": 74,
            "amount": 2,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "74900000000000000000",
                    "tokenId": "14673",
                    "listingTime": 1651506680,
                    "expirationTime": 1654098664,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14673",
                    "makerAddr": "0x924159Ba6142eEa6211cE1f5DD2944607376d028",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "74990000000000000000",
                    "tokenId": "15775",
                    "listingTime": 1650207902,
                    "expirationTime": 1652799891,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15775",
                    "makerAddr": "0x28F8ca3B0EDdd849c93986dF0fd194252C4e4B03",
                    "assetImageUrl": ""
                }
            ],
            "total": 149890000000000000000,
            "looksrare": 2
        },
        {
            "price": 75,
            "amount": 23,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "75000000000000000000",
                    "tokenId": "14241",
                    "listingTime": 1651225388,
                    "expirationTime": 1653817382,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14241",
                    "makerAddr": "0x7324b1202a7eB60ea969D94690ABB25f8e92c5a8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "75000000000000000000",
                    "tokenId": "8261",
                    "listingTime": 1650704027,
                    "expirationTime": 1653295833,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8261",
                    "makerAddr": "0x352e32Ad9791C5cc8d8614cfD0748FA6500400fC",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "75000000000000000000",
                    "tokenId": "2721",
                    "listingTime": 1650822318,
                    "expirationTime": 1653414286,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2721",
                    "makerAddr": "0x30b399B1900e1e2C33bDC08F0544EFb15Ae253D0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "75000000000000000000",
                    "tokenId": "14091",
                    "listingTime": 1650871637,
                    "expirationTime": 1653463555,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14091",
                    "makerAddr": "0xA2e8AC1C7B5c337f540f0FD57360dD6e3840adF6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "75000000000000000000",
                    "tokenId": "4593",
                    "listingTime": 1650646376,
                    "expirationTime": 1666198361,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4593",
                    "makerAddr": "0x72e814504928C295618Fdc14D49C572d38405263",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "75000000000000000000",
                    "tokenId": "5203",
                    "listingTime": 1651621730,
                    "expirationTime": 1654213661,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5203",
                    "makerAddr": "0xDe112B77A1a72c726D8C914c23dCEaA53273c351",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "75000000000000000000",
                    "tokenId": "3002",
                    "listingTime": 1650855452,
                    "expirationTime": 1653447426,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3002",
                    "makerAddr": "0x0cE97200C2430992844C9B13F80A217E000f3Ff6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "75000000000000000000",
                    "tokenId": "22345",
                    "listingTime": 1651488943,
                    "expirationTime": 1667040939,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22345",
                    "makerAddr": "0xf24aCe492753975d5625c1dDD393c103e8c8cE55",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "75000000000000000000",
                    "tokenId": "9229",
                    "listingTime": 1650646429,
                    "expirationTime": 1666198361,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9229",
                    "makerAddr": "0x72e814504928C295618Fdc14D49C572d38405263",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "75000000000000000000",
                    "tokenId": "28028",
                    "listingTime": 1650865578,
                    "expirationTime": 1653457514,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28028",
                    "makerAddr": "0x8354Ac9a76D4A9aCEF945039Bfc1Efc907C20A89",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "75000000000000000000",
                    "tokenId": "28399",
                    "listingTime": 1651768228,
                    "expirationTime": 1652371362,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28399",
                    "makerAddr": "0xFEFc2850B25FE1AA3e9Aa154DF61C6B9bC7AA374",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "75000000000000000000",
                    "tokenId": "27779",
                    "listingTime": 1651754506,
                    "expirationTime": 1652359301,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27779",
                    "makerAddr": "0x34b695974be06DC569188b737F723d936aF0a931",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "75000000000000000000",
                    "tokenId": "27062",
                    "listingTime": 1650863423,
                    "expirationTime": 1653455420,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27062",
                    "makerAddr": "0x89688542873276Cd7d9Cea936b25F37778a593fd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "75000000000000000000",
                    "tokenId": "20625",
                    "listingTime": 1651488923,
                    "expirationTime": 1667040797,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20625",
                    "makerAddr": "0xf24aCe492753975d5625c1dDD393c103e8c8cE55",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "75000000000000000000",
                    "tokenId": "15106",
                    "listingTime": 1650871345,
                    "expirationTime": 1653463301,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15106",
                    "makerAddr": "0xc741EB22eD5500DdFb587D7bE04d22c0aDe3851C",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "75000000000000000000",
                    "tokenId": "2256",
                    "listingTime": 1651363252,
                    "expirationTime": 1653955236,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2256",
                    "makerAddr": "0x6dAE8d3411339C9b92eB6f9D18468215bb91B8f0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "75",
                    "price": "75000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651460988,
                    "expirationTime": 1654139475,
                    "makerAddr": "0x1d20d0f28267e47cfbb2c247f2ca6527c7244495",
                    "assetImageUrl": "https://img.seadn.io/files/e488d0cef0a820d672aea6f60f431424.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "262",
                    "price": "75000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651412982,
                    "expirationTime": 1654091443,
                    "makerAddr": "0x5ea6795cd549b65fc299d297bc98720980ef76ce",
                    "assetImageUrl": "https://img.seadn.io/files/44d11eb629bf9d990eb7112661d84c52.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "673",
                    "price": "75000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651461116,
                    "expirationTime": 1654139610,
                    "makerAddr": "0x1d20d0f28267e47cfbb2c247f2ca6527c7244495",
                    "assetImageUrl": "https://img.seadn.io/files/8d77af12aea4b0706e09ab7623d04865.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1460",
                    "price": "75000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651599700,
                    "expirationTime": 1654278192,
                    "makerAddr": "0x58a216fa7cbf9d4f46ce69a4008e628b715651be",
                    "assetImageUrl": "https://img.seadn.io/files/1897b94cd5df5b7736fce1bf387a2941.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2163",
                    "price": "75000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650785112,
                    "expirationTime": 1653377200,
                    "makerAddr": "0x1f02b716be53513c40e686f94c0a6546d2d89372",
                    "assetImageUrl": "https://img.seadn.io/files/3b2873c7c36d3bbed102ac0569f15d03.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2256",
                    "price": "75000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651386857,
                    "expirationTime": 1654065309,
                    "makerAddr": "0x6dae8d3411339c9b92eb6f9d18468215bb91b8f0",
                    "assetImageUrl": "https://img.seadn.io/files/20d6a9e6c1274c8af0380a8b0fe1f3c9.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2721",
                    "price": "75000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650822318,
                    "expirationTime": 1653414407,
                    "makerAddr": "0x30b399b1900e1e2c33bdc08f0544efb15ae253d0",
                    "assetImageUrl": "https://img.seadn.io/files/8c9b70644034596f1bccded0d7eba27d.png?auto=format"
                }
            ],
            "total": 1.725e+21,
            "looksrare": 16,
            "opensea": 7
        },
        {
            "price": 77,
            "amount": 5,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "77000000000000000000",
                    "tokenId": "24226",
                    "listingTime": 1651973559,
                    "expirationTime": 1667525479,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24226",
                    "makerAddr": "0x247cAF4A0Ca130268201A00b794A326f9DbeBF86",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2100",
                    "price": "77000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651454129,
                    "expirationTime": 1654132624,
                    "makerAddr": "0xaa621b960f22911462550c078df678493c22b2ae",
                    "assetImageUrl": "https://img.seadn.io/files/c8e2cdc68ab10c9dde3adb8cbb774720.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "77700000000000000000",
                    "tokenId": "7",
                    "listingTime": 1650575598,
                    "expirationTime": 1666127591,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7",
                    "makerAddr": "0x8217cC277A8edfDa132A0123761e1D2aa795eCC6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "7",
                    "price": "77700000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1649115077,
                    "expirationTime": 1664667158,
                    "makerAddr": "0x8217cc277a8edfda132a0123761e1d2aa795ecc6",
                    "assetImageUrl": "https://img.seadn.io/files/45e87834317f733f62b3caf8e503affe.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "77770000000000000000",
                    "tokenId": "6490",
                    "listingTime": 1650690499,
                    "expirationTime": 1653282355,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6490",
                    "makerAddr": "0x964F690Def870B36f017F6127Ab1B929645333C0",
                    "assetImageUrl": ""
                }
            ],
            "total": 387170000000000000000,
            "looksrare": 3,
            "opensea": 2
        },
        {
            "price": 78,
            "amount": 2,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "78000000000000000000",
                    "tokenId": "20349",
                    "listingTime": 1652084332,
                    "expirationTime": 1652689046,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20349",
                    "makerAddr": "0xd104Ce1266e5Fa0A31F7CA6dd16DEf1d62308C46",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "78000000000000000000",
                    "tokenId": "20066",
                    "listingTime": 1650483286,
                    "expirationTime": 1658259241,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20066",
                    "makerAddr": "0x65ebb279694E4ff517a11C92c9C57a9A017eA4F0",
                    "assetImageUrl": ""
                }
            ],
            "total": 156000000000000000000,
            "looksrare": 2
        },
        {
            "price": 79,
            "amount": 4,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "79000000000000000000",
                    "tokenId": "6699",
                    "listingTime": 1650494202,
                    "expirationTime": 1653086195,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6699",
                    "makerAddr": "0xb602d49a76d99cab5744fD0CfC172D2cdF933752",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "79420000000000000000",
                    "tokenId": "1514",
                    "listingTime": 1651323487,
                    "expirationTime": 1653915476,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1514",
                    "makerAddr": "0xd7CdB08EaDd3D730540817a348b62D15BF45DF10",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "79420690000000000000",
                    "tokenId": "3096",
                    "listingTime": 1650770721,
                    "expirationTime": 1653362703,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3096",
                    "makerAddr": "0xD3E9a24fd752b74894712350025Dd57b14035bd4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "79990000000000000000",
                    "tokenId": "8885",
                    "listingTime": 1651316458,
                    "expirationTime": 1653908454,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8885",
                    "makerAddr": "0x89688542873276Cd7d9Cea936b25F37778a593fd",
                    "assetImageUrl": ""
                }
            ],
            "total": 317830690000000000000,
            "looksrare": 4
        },
        {
            "price": 80,
            "amount": 16,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "80000000000000000000",
                    "tokenId": "14701",
                    "listingTime": 1650753795,
                    "expirationTime": 1653345789,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/14701",
                    "makerAddr": "0xb8F0B59Aa99711ebBcBa08c6bE2A4910626227C4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "80000000000000000000",
                    "tokenId": "2634",
                    "listingTime": 1648324890,
                    "expirationTime": 1656100454,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2634",
                    "makerAddr": "0xfAD18cC656a0261c154647f125cFFED27Fbfc083",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "80000000000000000000",
                    "tokenId": "10515",
                    "listingTime": 1651258911,
                    "expirationTime": 1653850889,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10515",
                    "makerAddr": "0x2d8d75D4BA64EFda74eeAD05Ac6127Df4e46Efa9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "80000000000000000000",
                    "tokenId": "6373",
                    "listingTime": 1650799319,
                    "expirationTime": 1653391192,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6373",
                    "makerAddr": "0xE9FbEbb23e5CCC02EEe6E751adA3f3BbD20C630D",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "80000000000000000000",
                    "tokenId": "6440",
                    "listingTime": 1651683867,
                    "expirationTime": 1654057919,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6440",
                    "makerAddr": "0x03f609e5FdDDc7D5dDDc959bDedfe58b840C68E1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "80000000000000000000",
                    "tokenId": "17523",
                    "listingTime": 1650874736,
                    "expirationTime": 1666426516,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17523",
                    "makerAddr": "0x3aF3A432D194C16b68f1a4a96dC59d92b6e13b19",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "80000000000000000000",
                    "tokenId": "19988",
                    "listingTime": 1651829510,
                    "expirationTime": 1652434309,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19988",
                    "makerAddr": "0xb8c3E2E2a3465B74b7A57F8921A1c3F6e69302e2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "80000000000000000000",
                    "tokenId": "1943",
                    "listingTime": 1651453458,
                    "expirationTime": 1653404420,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1943",
                    "makerAddr": "0x956E6a7B73910C58B1141E8376978AeEFa0271b2",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "80000000000000000000",
                    "tokenId": "4659",
                    "listingTime": 1652028748,
                    "expirationTime": 1652633543,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4659",
                    "makerAddr": "0x03f065A8786eCd7dcf0F995EAAE6e5131A7E492f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "80000000000000000000",
                    "tokenId": "29466",
                    "listingTime": 1651745036,
                    "expirationTime": 1667296996,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/29466",
                    "makerAddr": "0x42bbf9250F73A3Be4811e3405A62EE408aB251cF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1012",
                    "price": "80000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651679819,
                    "expirationTime": 1654358297,
                    "makerAddr": "0xda1e3833e7a7045f9fabaa42ff4beff77a3fc728",
                    "assetImageUrl": "https://img.seadn.io/files/abf6400f4e9683afcc77882c1f63c177.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1417",
                    "price": "80000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651512376,
                    "expirationTime": 1654190831,
                    "makerAddr": "0x8cd76d75bbd897a74494de835891e6117e68d97b",
                    "assetImageUrl": "https://img.seadn.io/files/4504a885394a24453be70f47ca651529.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1943",
                    "price": "80000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650812328,
                    "expirationTime": 1653404420,
                    "makerAddr": "0x956e6a7b73910c58b1141e8376978aeefa0271b2",
                    "assetImageUrl": "https://img.seadn.io/files/0136988975cd99c11f7528c4672f926f.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2656",
                    "price": "80000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652145185,
                    "expirationTime": 1652225894,
                    "makerAddr": "0xed19dc5a34e3523d9cdfad74517e7725baea15e7",
                    "assetImageUrl": "https://img.seadn.io/files/d9d848ba45db39e99c4034452879cef5.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2955",
                    "price": "80000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650909748,
                    "expirationTime": 1653501836,
                    "makerAddr": "0xe0fe106d9916a474feaadfd6e879a8982e339428",
                    "assetImageUrl": "https://img.seadn.io/files/56c5b28c72ab31058aeb92ccb8038bf5.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3277",
                    "price": "80000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652197570,
                    "expirationTime": 1652456856,
                    "makerAddr": "0x0fb3fa40850c1e472b6eced8fb10781763d42193",
                    "assetImageUrl": "https://img.seadn.io/files/bacfe2f68a0b203cf02c97d13a232839.png?auto=format"
                }
            ],
            "total": 1.28e+21,
            "looksrare": 10,
            "opensea": 6
        },
        {
            "price": 82,
            "amount": 2,
            "orders": [
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "804",
                    "price": "82300000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650437669,
                    "expirationTime": 1657090366,
                    "makerAddr": "0x7bbd5fe942bc575c6027d95aeba67fb5d04e431d",
                    "assetImageUrl": "https://img.seadn.io/files/2d21cc32d1d211e7e3db5505875437e9.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "82400000000000000000",
                    "tokenId": "6320",
                    "listingTime": 1652020895,
                    "expirationTime": 1659796872,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6320",
                    "makerAddr": "0xa9902F53813bFa00b22937913a1DD7c4867D4166",
                    "assetImageUrl": ""
                }
            ],
            "total": 164700000000000000000,
            "opensea": 1,
            "looksrare": 1
        },
        {
            "price": 83,
            "amount": 2,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "83000000000000000000",
                    "tokenId": "4334",
                    "listingTime": 1651316769,
                    "expirationTime": 1653908767,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4334",
                    "makerAddr": "0x83B5654564cAEe5c8E86a314B4E62330Db99Ce16",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "83000000000000000000",
                    "tokenId": "20539",
                    "listingTime": 1651462141,
                    "expirationTime": 1652671738,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20539",
                    "makerAddr": "0x692ae92C1Ea5022A0CE9449a0aDE6ed531d7b56a",
                    "assetImageUrl": ""
                }
            ],
            "total": 166000000000000000000,
            "looksrare": 2
        },
        {
            "price": 85,
            "amount": 2,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "85000000000000000000",
                    "tokenId": "3742",
                    "listingTime": 1650874768,
                    "expirationTime": 1666426204,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3742",
                    "makerAddr": "0x3aF3A432D194C16b68f1a4a96dC59d92b6e13b19",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "85000000000000000000",
                    "tokenId": "11660",
                    "listingTime": 1651316719,
                    "expirationTime": 1653908714,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11660",
                    "makerAddr": "0x83B5654564cAEe5c8E86a314B4E62330Db99Ce16",
                    "assetImageUrl": ""
                }
            ],
            "total": 170000000000000000000,
            "looksrare": 2
        },
        {
            "price": 87,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2319",
                    "price": "87000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650175913,
                    "expirationTime": 1652767998,
                    "makerAddr": "0xdbe37b6be9e4cd306b1ac5e6ec79ff67032d69cb",
                    "assetImageUrl": "https://img.seadn.io/files/2b4a881e0cbe0a02fcb239535a4d43ae.png?auto=format"
                }
            ],
            "total": 87000000000000000000,
            "opensea": 1
        },
        {
            "price": 88,
            "amount": 10,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "88000000000000000000",
                    "tokenId": "21739",
                    "listingTime": 1650879814,
                    "expirationTime": 1653471758,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/21739",
                    "makerAddr": "0xecbf8002841897401164e12cf93ab0d53B8e8B3c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "88000000000000000000",
                    "tokenId": "8807",
                    "listingTime": 1650468995,
                    "expirationTime": 1653060759,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8807",
                    "makerAddr": "0xec385C6A991CAd9fE23e5B855526e1E44a1d5b9f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "693",
                    "price": "88000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651512237,
                    "expirationTime": 1667064308,
                    "makerAddr": "0x74362b99f5fa840caacc123e2fa0c994baf348fc",
                    "assetImageUrl": "https://img.seadn.io/files/268a85fef1125d50d492e1dd31c1ce12.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "88300000000000000000",
                    "tokenId": "15793",
                    "listingTime": 1650580658,
                    "expirationTime": 1653172492,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15793",
                    "makerAddr": "0x78678Bc5Df1ad74A54265b3c5026E29Ca0064BF1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "88690000000000000000",
                    "tokenId": "5422",
                    "listingTime": 1651222725,
                    "expirationTime": 1653814638,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5422",
                    "makerAddr": "0xd0B859776667BfeA916AbAd65aB6128b0f9156bF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "88800000000000000000",
                    "tokenId": "10379",
                    "listingTime": 1643280390,
                    "expirationTime": 1658828751,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10379",
                    "makerAddr": "0x43545039bD71D2234B6E5f0004d4d1392B32D3e3",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1091",
                    "price": "88800000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651274868,
                    "expirationTime": 1653866942,
                    "makerAddr": "0xbebcf6cddce5b5a494d7ce051ae3688a925bd10b",
                    "assetImageUrl": "https://img.seadn.io/files/0a74e044f1c7b8e3755a67fadcc51d04.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3383",
                    "price": "88800000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1649444262,
                    "expirationTime": 1665255529,
                    "makerAddr": "0xe935b40a45e60d16d99b54e001186b54ec7463a3",
                    "assetImageUrl": "https://img.seadn.io/files/40f7298451f4a33c02d40676f418a9c4.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "88880000000000000000",
                    "tokenId": "4767",
                    "listingTime": 1651896914,
                    "expirationTime": 1652501709,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4767",
                    "makerAddr": "0x40e84785b0BB9833622ACc497467E1059188Ae5c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1668",
                    "price": "88880000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651503023,
                    "expirationTime": 1654181452,
                    "makerAddr": "0x1ed9c08cd235345c7d429c4b4bbf661d20ff3459",
                    "assetImageUrl": "https://img.seadn.io/files/9b656123c5747fae3eadd19a6e1804e4.png?auto=format"
                }
            ],
            "total": 885150000000000000000,
            "looksrare": 6,
            "opensea": 4
        },
        {
            "price": 90,
            "amount": 3,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "90000000000000000000",
                    "tokenId": "7869",
                    "listingTime": 1650818284,
                    "expirationTime": 1653410273,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7869",
                    "makerAddr": "0xe126731C09E9388e0375243B91A5A9a3fcc64B8c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "90000000000000000000",
                    "tokenId": "22108",
                    "listingTime": 1650945452,
                    "expirationTime": 1653537451,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22108",
                    "makerAddr": "0x642C8Ed496a3D05A0Ed08911c01F652A0C4b84D4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1506",
                    "price": "90000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650862019,
                    "expirationTime": 1653454074,
                    "makerAddr": "0x530b6febcf60dd3b342a7af981765cbe56dc1ffc",
                    "assetImageUrl": "https://img.seadn.io/files/4acd2e5ea3c6d19b992ba44778a2d98d.png?auto=format"
                }
            ],
            "total": 270000000000000000000,
            "looksrare": 2,
            "opensea": 1
        },
        {
            "price": 95,
            "amount": 3,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "95000000000000000000",
                    "tokenId": "11183",
                    "listingTime": 1648422244,
                    "expirationTime": 1656198239,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/11183",
                    "makerAddr": "0xBF21B6B77B893E11e96d79157Eb417F3e4E9538e",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "95000000000000000000",
                    "tokenId": "20233",
                    "listingTime": 1651701231,
                    "expirationTime": 1654293230,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20233",
                    "makerAddr": "0xEBFb3B263f11BB1A9D6b14CaDE9A5E6C8EA4e07c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2263",
                    "price": "95000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651718436,
                    "expirationTime": 1652323257,
                    "makerAddr": "0xf022e7a460d088a06f94cde6e0d01b6d5ab77c44",
                    "assetImageUrl": "https://img.seadn.io/files/0fa8b67d6f6b0465d9f4923b743bbc91.png?auto=format"
                }
            ],
            "total": 285000000000000000000,
            "looksrare": 2,
            "opensea": 1
        },
        {
            "price": 98,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2066",
                    "price": "98000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650848788,
                    "expirationTime": 1656802269,
                    "makerAddr": "0xdd082b2ee9e616a6eb0e495b897fd8823460c0a2",
                    "assetImageUrl": "https://img.seadn.io/files/da049f1df14968777aadeb241b895f82.png?auto=format"
                }
            ],
            "total": 98000000000000000000,
            "opensea": 1
        },
        {
            "price": 99,
            "amount": 13,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "99000000000000000000",
                    "tokenId": "9923",
                    "listingTime": 1651622255,
                    "expirationTime": 1654214207,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9923",
                    "makerAddr": "0x787Bc734B5EB999b8175c8c6A7cd13A0d68bdc7A",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "99000000000000000000",
                    "tokenId": "20303",
                    "listingTime": 1650372907,
                    "expirationTime": 1652694617,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20303",
                    "makerAddr": "0x298d5537C181766D22a27D40b90A9c8ccA5b5aD5",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "99000000000000000000",
                    "tokenId": "28079",
                    "listingTime": 1650724718,
                    "expirationTime": 1658500703,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/28079",
                    "makerAddr": "0xD665b2C7Fd357B283d19eEC7Bf00edf53E7CF4a8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "99000000000000000000",
                    "tokenId": "13826",
                    "listingTime": 1650761889,
                    "expirationTime": 1653353887,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13826",
                    "makerAddr": "0x83B5654564cAEe5c8E86a314B4E62330Db99Ce16",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "99000000000000000000",
                    "tokenId": "9532",
                    "listingTime": 1651667231,
                    "expirationTime": 1659443226,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9532",
                    "makerAddr": "0x5205c81AdDE5DA88E715a4526182373dcA4184A0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "99000000000000000000",
                    "tokenId": "7661",
                    "listingTime": 1651864985,
                    "expirationTime": 1654456893,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7661",
                    "makerAddr": "0x4593010f1e2fFf00573d634Ed7A931E85C1A8Aab",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "99000000000000000000",
                    "tokenId": "26489",
                    "listingTime": 1650207531,
                    "expirationTime": 1657983526,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26489",
                    "makerAddr": "0x28F8ca3B0EDdd849c93986dF0fd194252C4e4B03",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "99000000000000000000",
                    "tokenId": "16210",
                    "listingTime": 1650521953,
                    "expirationTime": 1653113924,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/16210",
                    "makerAddr": "0x89688542873276Cd7d9Cea936b25F37778a593fd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "99000000000000000000",
                    "tokenId": "20139",
                    "listingTime": 1651488958,
                    "expirationTime": 1667040954,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/20139",
                    "makerAddr": "0xf24aCe492753975d5625c1dDD393c103e8c8cE55",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "934",
                    "price": "99000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651741537,
                    "expirationTime": 1652346420,
                    "makerAddr": "0x864d2437ed789ccec3107727cc0cf65b0cd0a3b9",
                    "assetImageUrl": "https://img.seadn.io/files/5f9775a2dc162ec3b1cc5d2edc586572.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1092",
                    "price": "99000000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651476243,
                    "expirationTime": 1654154733,
                    "makerAddr": "0xc0beefeed699f6d6a08397fcbf8fbadf9f83eb5d",
                    "assetImageUrl": "https://img.seadn.io/files/c60fd007d8a2f2a29065f49ae82951e1.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "99690000000000000000",
                    "tokenId": "4196",
                    "listingTime": 1652031216,
                    "expirationTime": 1652636011,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4196",
                    "makerAddr": "0x8B3638493e78ff4D245311422bc40D8a3aa6A451",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3273",
                    "price": "99690000000000000000.00000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651642741,
                    "expirationTime": 1652247629,
                    "makerAddr": "0xb8dc9d4bbeb6705fdba1f89458a4bc2a2066a6c9",
                    "assetImageUrl": "https://img.seadn.io/files/4e06fec4201c03aba36f1c1b29fa7ec4.png?auto=format"
                }
            ],
            "total": 1.2883799999999999e+21,
            "looksrare": 10,
            "opensea": 3
        },
        {
            "price": 100,
            "amount": 18,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "100000000000000000000",
                    "tokenId": "8171",
                    "listingTime": 1651934425,
                    "expirationTime": 1659049477,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8171",
                    "makerAddr": "0x6be55C75d61ECaaB7eDf7791C087858269760383",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "100000000000000000000",
                    "tokenId": "4842",
                    "listingTime": 1651222805,
                    "expirationTime": 1653814758,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4842",
                    "makerAddr": "0xd0B859776667BfeA916AbAd65aB6128b0f9156bF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "100000000000000000000",
                    "tokenId": "26861",
                    "listingTime": 1650465475,
                    "expirationTime": 1658241467,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26861",
                    "makerAddr": "0x7270d1f75c92F965Bf708C91dd06Ce1f0b65Ac04",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "100000000000000000000",
                    "tokenId": "8464",
                    "listingTime": 1649910428,
                    "expirationTime": 1652502426,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8464",
                    "makerAddr": "0x685Cb9E2035d915331379Dcbf09D18084f126bA7",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "100000000000000000000",
                    "tokenId": "25331",
                    "listingTime": 1651121776,
                    "expirationTime": 1653713764,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/25331",
                    "makerAddr": "0xeFC126F6cc7b1A6Ac1EF5718366990D9F7266828",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "100000000000000000000",
                    "tokenId": "799",
                    "listingTime": 1651079843,
                    "expirationTime": 1652289366,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/799",
                    "makerAddr": "0xe90790718B9Fe12cC6738fB8a2193775a4e7D21c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "100000000000000000000",
                    "tokenId": "10528",
                    "listingTime": 1651894936,
                    "expirationTime": 1654486923,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10528",
                    "makerAddr": "0x5F1A4100cC68bbe706f37f39755C6e3a1CF999d6",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "100000000000000000000",
                    "tokenId": "24666",
                    "listingTime": 1651521615,
                    "expirationTime": 1667073607,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24666",
                    "makerAddr": "0xAA284bDdA19B43B7080708208A424577E0805719",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "100000000000000000000",
                    "tokenId": "180",
                    "listingTime": 1650593207,
                    "expirationTime": 1658369183,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/180",
                    "makerAddr": "0xbc91d03B996C10e2DaDd802e7BfEc889640D00c0",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "100000000000000000000",
                    "tokenId": "9125",
                    "listingTime": 1651671740,
                    "expirationTime": 1659176929,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9125",
                    "makerAddr": "0x5723f0c6707a689C6DF16b74c22B23C905AD7B04",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "100000000000000000000",
                    "tokenId": "17972",
                    "listingTime": 1651405939,
                    "expirationTime": 1666957938,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17972",
                    "makerAddr": "0x805C36F06e1f1233aA9617932431C425d5385Abc",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "6",
                    "price": "100000000000000000000.0000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651545676,
                    "expirationTime": 1667097743,
                    "makerAddr": "0x1be7f61dbee0fa0ed1e5dab4ef1ff136bc348a06",
                    "assetImageUrl": "https://img.seadn.io/files/48a62a2945dfa91df2ee7fc0585b2ea0.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "180",
                    "price": "100000000000000000000.0000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650639574,
                    "expirationTime": 1658415656,
                    "makerAddr": "0xbc91d03b996c10e2dadd802e7bfec889640d00c0",
                    "assetImageUrl": "https://img.seadn.io/files/7ac218f3c067ddf43abf50fb7143f6a3.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "219",
                    "price": "100000000000000000000.0000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650812942,
                    "expirationTime": 1653405014,
                    "makerAddr": "0xe24283e2f22a7a4360dab185dfeb62190d13742f",
                    "assetImageUrl": "https://img.seadn.io/files/489527a885e572208cef57bd6e55e12e.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2313",
                    "price": "100000000000000000000.0000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651559056,
                    "expirationTime": 1654237550,
                    "makerAddr": "0xedac55a48039036b28572463da9c4b5b9178d02b",
                    "assetImageUrl": "https://img.seadn.io/files/63b9a9705b6a89dce3eb93d5bc74193a.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2434",
                    "price": "100000000000000000000.0000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652147921,
                    "expirationTime": 1654826412,
                    "makerAddr": "0x779a7ee0d32122e82e46ebdd4b993e2192d66369",
                    "assetImageUrl": "https://img.seadn.io/files/fc3d461d85c775f64bbb611e9d58b8b1.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2489",
                    "price": "100000000000000000000.0000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652073443,
                    "expirationTime": 1652332721,
                    "makerAddr": "0x50d922255318e03f803ae1dd85755e17df2afec8",
                    "assetImageUrl": "https://img.seadn.io/files/920bac184a6faec79a33c9e23ea0ab94.png?auto=format"
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3035",
                    "price": "100000000000000000000.0000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650869864,
                    "expirationTime": 1653461955,
                    "makerAddr": "0x7dd8fa29637a9e0c545a689c080093a80ce1a00b",
                    "assetImageUrl": "https://img.seadn.io/files/7be4dfa7f74fef966bb42848810e9e37.png?auto=format"
                }
            ],
            "total": 1.8e+21,
            "looksrare": 11,
            "opensea": 7
        },
        {
            "price": 102,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "102990000000000000000",
                    "tokenId": "3586",
                    "listingTime": 1650646404,
                    "expirationTime": 1666198361,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3586",
                    "makerAddr": "0x72e814504928C295618Fdc14D49C572d38405263",
                    "assetImageUrl": ""
                }
            ],
            "total": 102990000000000000000,
            "looksrare": 1
        },
        {
            "price": 105,
            "amount": 2,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "105000000000000000000",
                    "tokenId": "5063",
                    "listingTime": 1651467092,
                    "expirationTime": 1652676687,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5063",
                    "makerAddr": "0x692ae92C1Ea5022A0CE9449a0aDE6ed531d7b56a",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "572",
                    "price": "105000000000000000000.0000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650383652,
                    "expirationTime": 1652975722,
                    "makerAddr": "0x86b4d38e451c707e4914ffceab9479e3a8685f98",
                    "assetImageUrl": "https://img.seadn.io/files/f5999c16c6da3dc5f95156da04963879.png?auto=format"
                }
            ],
            "total": 210000000000000000000,
            "looksrare": 1,
            "opensea": 1
        },
        {
            "price": 107,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "107170000000000000000",
                    "tokenId": "10717",
                    "listingTime": 1645062022,
                    "expirationTime": 1657246020,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/10717",
                    "makerAddr": "0x532C3927193997F029001a7F376B9f6D0388FB91",
                    "assetImageUrl": ""
                }
            ],
            "total": 107170000000000000000,
            "looksrare": 1
        },
        {
            "price": 110,
            "amount": 4,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "110000000000000000000",
                    "tokenId": "2683",
                    "listingTime": 1651868546,
                    "expirationTime": 1654460495,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2683",
                    "makerAddr": "0xc3AA606D75Bad4CF846c8bBe12E6AA487D640839",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "110000000000000000000",
                    "tokenId": "7733",
                    "listingTime": 1650581564,
                    "expirationTime": 1653173525,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/7733",
                    "makerAddr": "0xc1B4d177DBE7577aD98579077034A95F02Ffb3C4",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "110000000000000000000",
                    "tokenId": "5142",
                    "listingTime": 1645151288,
                    "expirationTime": 1656241235,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5142",
                    "makerAddr": "0x7877c464F6601486053d3046d85Ec20167363820",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "85",
                    "price": "110000000000000000000.0000000",
                    "paymentToken": "ETH",
                    "listingTime": 1649187156,
                    "expirationTime": 1656963045,
                    "makerAddr": "0xb8ccf2439cf58da9f72c0c410cb9732c88e50b8f",
                    "assetImageUrl": "https://img.seadn.io/files/d76cc53b5a396aac7158e7005cdbbdc5.png?auto=format"
                }
            ],
            "total": 440000000000000000000,
            "looksrare": 3,
            "opensea": 1
        },
        {
            "price": 111,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "111000000000000000000",
                    "tokenId": "5839",
                    "listingTime": 1646379937,
                    "expirationTime": 1661931917,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5839",
                    "makerAddr": "0xFb239e0DF3a98F6B728200C14cB364CFC61dC4bC",
                    "assetImageUrl": ""
                }
            ],
            "total": 111000000000000000000,
            "looksrare": 1
        },
        {
            "price": 115,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "115000000000000000000",
                    "tokenId": "8171",
                    "listingTime": 1643501124,
                    "expirationTime": 1659049477,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8171",
                    "makerAddr": "0x6be55C75d61ECaaB7eDf7791C087858269760383",
                    "assetImageUrl": ""
                }
            ],
            "total": 115000000000000000000,
            "looksrare": 1
        },
        {
            "price": 118,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "118000000000000000000",
                    "tokenId": "9350",
                    "listingTime": 1652103384,
                    "expirationTime": 1652708153,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9350",
                    "makerAddr": "0x03f065A8786eCd7dcf0F995EAAE6e5131A7E492f",
                    "assetImageUrl": ""
                }
            ],
            "total": 118000000000000000000,
            "looksrare": 1
        },
        {
            "price": 120,
            "amount": 6,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "120000000000000000000",
                    "tokenId": "6440",
                    "listingTime": 1651466048,
                    "expirationTime": 1654057919,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6440",
                    "makerAddr": "0x03f609e5FdDDc7D5dDDc959bDedfe58b840C68E1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "120000000000000000000",
                    "tokenId": "9612",
                    "listingTime": 1652028809,
                    "expirationTime": 1652633604,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9612",
                    "makerAddr": "0x03f065A8786eCd7dcf0F995EAAE6e5131A7E492f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "120000000000000000000",
                    "tokenId": "9125",
                    "listingTime": 1651400934,
                    "expirationTime": 1659176929,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9125",
                    "makerAddr": "0x5723f0c6707a689C6DF16b74c22B23C905AD7B04",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "120000000000000000000",
                    "tokenId": "23551",
                    "listingTime": 1652180578,
                    "expirationTime": 1654772554,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23551",
                    "makerAddr": "0xA5836A1f1B6dbD88248F960cD83dcb2BDE1C92ae",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "120000000000000000000",
                    "tokenId": "15239",
                    "listingTime": 1652180589,
                    "expirationTime": 1654772554,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15239",
                    "makerAddr": "0xA5836A1f1B6dbD88248F960cD83dcb2BDE1C92ae",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "383",
                    "price": "120000000000000000000.0000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650426925,
                    "expirationTime": 1665201377,
                    "makerAddr": "0xa0101a99725cc766f4740a00027488e4c6969c02",
                    "assetImageUrl": "https://img.seadn.io/files/1ba6a68481b280c6d77cfc321f049e0a.png?auto=format"
                }
            ],
            "total": 720000000000000000000,
            "looksrare": 5,
            "opensea": 1
        },
        {
            "price": 123,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1655",
                    "price": "123000000000000000000.0000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651454307,
                    "expirationTime": 1654132800,
                    "makerAddr": "0xaa621b960f22911462550c078df678493c22b2ae",
                    "assetImageUrl": "https://img.seadn.io/files/952ef1d21f4382d554f50ffddd277373.png?auto=format"
                }
            ],
            "total": 123000000000000000000,
            "opensea": 1
        },
        {
            "price": 125,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "125000000000000000000",
                    "tokenId": "19765",
                    "listingTime": 1650818916,
                    "expirationTime": 1653410911,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19765",
                    "makerAddr": "0xc263Ffed908245b3FBcc3b4f8B225d4191425bAa",
                    "assetImageUrl": ""
                }
            ],
            "total": 125000000000000000000,
            "looksrare": 1
        },
        {
            "price": 128,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "128420000000000000000",
                    "tokenId": "13675",
                    "listingTime": 1650521896,
                    "expirationTime": 1653113893,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13675",
                    "makerAddr": "0x89688542873276Cd7d9Cea936b25F37778a593fd",
                    "assetImageUrl": ""
                }
            ],
            "total": 128420000000000000000,
            "looksrare": 1
        },
        {
            "price": 130,
            "amount": 3,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "130000000000000000000",
                    "tokenId": "15960",
                    "listingTime": 1651481006,
                    "expirationTime": 1652690470,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/15960",
                    "makerAddr": "0x4Fd90De08C0Fe2A57BF4369c3EF5ee3057Eb3123",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "130000000000000000000",
                    "tokenId": "2605",
                    "listingTime": 1650549410,
                    "expirationTime": 1653141405,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2605",
                    "makerAddr": "0x1ed3B397504a52Fe86bda23Cf3d4c016D680E8a9",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2605",
                    "price": "130000000000000000000.0000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650465512,
                    "expirationTime": 1653057599,
                    "makerAddr": "0x1ed3b397504a52fe86bda23cf3d4c016d680e8a9",
                    "assetImageUrl": "https://img.seadn.io/files/5964329106a7ce6866b7a6a7071e3da9.png?auto=format"
                }
            ],
            "total": 390000000000000000000,
            "looksrare": 2,
            "opensea": 1
        },
        {
            "price": 131,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "131270000000000000000",
                    "tokenId": "13127",
                    "listingTime": 1651737094,
                    "expirationTime": 1654329084,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13127",
                    "makerAddr": "0x1324696538c238dfA5B22E019e6a0243c701a3Fc",
                    "assetImageUrl": ""
                }
            ],
            "total": 131270000000000000000,
            "looksrare": 1
        },
        {
            "price": 139,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "139000000000000000000",
                    "tokenId": "23677",
                    "listingTime": 1651868599,
                    "expirationTime": 1654460586,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/23677",
                    "makerAddr": "0xc3AA606D75Bad4CF846c8bBe12E6AA487D640839",
                    "assetImageUrl": ""
                }
            ],
            "total": 139000000000000000000,
            "looksrare": 1
        },
        {
            "price": 140,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "140000000000000000000",
                    "tokenId": "26897",
                    "listingTime": 1650549372,
                    "expirationTime": 1653141332,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/26897",
                    "makerAddr": "0x1ed3B397504a52Fe86bda23Cf3d4c016D680E8a9",
                    "assetImageUrl": ""
                }
            ],
            "total": 140000000000000000000,
            "looksrare": 1
        },
        {
            "price": 145,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "257",
                    "price": "145000000000000000000.0000000",
                    "paymentToken": "ETH",
                    "listingTime": 1650930701,
                    "expirationTime": 1653522787,
                    "makerAddr": "0x3e79c8a3caa37bee628ee55b1ff15bff429a44ea",
                    "assetImageUrl": "https://img.seadn.io/files/2912f5b3e73c58196ca896ea78d0ae7e.png?auto=format"
                }
            ],
            "total": 145000000000000000000,
            "opensea": 1
        },
        {
            "price": 150,
            "amount": 6,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "150000000000000000000",
                    "tokenId": "3650",
                    "listingTime": 1651224474,
                    "expirationTime": 1653816464,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3650",
                    "makerAddr": "0xd7CdB08EaDd3D730540817a348b62D15BF45DF10",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "150000000000000000000",
                    "tokenId": "9097",
                    "listingTime": 1650521948,
                    "expirationTime": 1653113924,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9097",
                    "makerAddr": "0x89688542873276Cd7d9Cea936b25F37778a593fd",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "150000000000000000000",
                    "tokenId": "12802",
                    "listingTime": 1650761861,
                    "expirationTime": 1653353857,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/12802",
                    "makerAddr": "0x83B5654564cAEe5c8E86a314B4E62330Db99Ce16",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "150000000000000000000",
                    "tokenId": "22650",
                    "listingTime": 1652011087,
                    "expirationTime": 1652270278,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/22650",
                    "makerAddr": "0x9C7b82c0302C7c945F9Cf45A5c73E6f48Ab84B14",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "150000000000000000000",
                    "tokenId": "4037",
                    "listingTime": 1651476245,
                    "expirationTime": 1654068194,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4037",
                    "makerAddr": "0x00Bd53913A82F36E5796Ed7D30F1B2a15cd31C20",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "150000000000000000000",
                    "tokenId": "13823",
                    "listingTime": 1651429065,
                    "expirationTime": 1654021060,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/13823",
                    "makerAddr": "0x1E7dff312eB06F1A314BF8e7FFb5CdDe32A1821B",
                    "assetImageUrl": ""
                }
            ],
            "total": 900000000000000000000,
            "looksrare": 6
        },
        {
            "price": 168,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "168690000000000000000",
                    "tokenId": "27709",
                    "listingTime": 1649921306,
                    "expirationTime": 1652513282,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27709",
                    "makerAddr": "0xDE88C5F654E43083AdAF91206A8a8e225f3605f4",
                    "assetImageUrl": ""
                }
            ],
            "total": 168690000000000000000,
            "looksrare": 1
        },
        {
            "price": 180,
            "amount": 2,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "180000000000000000000",
                    "tokenId": "24973",
                    "listingTime": 1650840309,
                    "expirationTime": 1653432292,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24973",
                    "makerAddr": "0x744f7392aeeA177E430b51BE004029Dca1846B17",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "180000000000000000000",
                    "tokenId": "9515",
                    "listingTime": 1651087594,
                    "expirationTime": 1653679586,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/9515",
                    "makerAddr": "0x2d8d75D4BA64EFda74eeAD05Ac6127Df4e46Efa9",
                    "assetImageUrl": ""
                }
            ],
            "total": 360000000000000000000,
            "looksrare": 2
        },
        {
            "price": 199,
            "amount": 2,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "199000000000000000000",
                    "tokenId": "4222",
                    "listingTime": 1652180729,
                    "expirationTime": 1654772554,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4222",
                    "makerAddr": "0xA5836A1f1B6dbD88248F960cD83dcb2BDE1C92ae",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "199990000000000000000",
                    "tokenId": "1227",
                    "listingTime": 1652153119,
                    "expirationTime": 1654745110,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1227",
                    "makerAddr": "0x8B3638493e78ff4D245311422bc40D8a3aa6A451",
                    "assetImageUrl": ""
                }
            ],
            "total": 398990000000000000000,
            "looksrare": 2
        },
        {
            "price": 200,
            "amount": 3,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "200000000000000000000",
                    "tokenId": "4767",
                    "listingTime": 1649564132,
                    "expirationTime": 1653711300,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4767",
                    "makerAddr": "0x40e84785b0BB9833622ACc497467E1059188Ae5c",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "200000000000000000000",
                    "tokenId": "19437",
                    "listingTime": 1651223165,
                    "expirationTime": 1653815161,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19437",
                    "makerAddr": "0xd0B859776667BfeA916AbAd65aB6128b0f9156bF",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "200000000000000000000",
                    "tokenId": "19436",
                    "listingTime": 1651223120,
                    "expirationTime": 1653815111,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/19436",
                    "makerAddr": "0xd0B859776667BfeA916AbAd65aB6128b0f9156bF",
                    "assetImageUrl": ""
                }
            ],
            "total": 600000000000000000000,
            "looksrare": 3
        },
        {
            "price": 222,
            "amount": 2,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "222000000000000000000",
                    "tokenId": "2",
                    "listingTime": 1651863429,
                    "expirationTime": 1659639420,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2",
                    "makerAddr": "0x54f147e73b5d0C47E6E4DF601026d19bbBC94bfB",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2",
                    "price": "222000000000000000000.0000000",
                    "paymentToken": "ETH",
                    "listingTime": 1652002086,
                    "expirationTime": 1659778172,
                    "makerAddr": "0x54f147e73b5d0c47e6e4df601026d19bbbc94bfb",
                    "assetImageUrl": "https://img.seadn.io/files/d9dedbb6c8e7424bd61b96ff0b9ab20d.png?auto=format"
                }
            ],
            "total": 444000000000000000000,
            "looksrare": 1,
            "opensea": 1
        },
        {
            "price": 250,
            "amount": 4,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "250000000000000000000",
                    "tokenId": "3480",
                    "listingTime": 1651541315,
                    "expirationTime": 1652750906,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3480",
                    "makerAddr": "0xbbc5E17749a63E5B799469A225cEb1683257c8A8",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "250000000000000000000",
                    "tokenId": "27492",
                    "listingTime": 1650761909,
                    "expirationTime": 1653353906,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27492",
                    "makerAddr": "0x83B5654564cAEe5c8E86a314B4E62330Db99Ce16",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "250000000000000000000",
                    "tokenId": "8429",
                    "listingTime": 1650775595,
                    "expirationTime": 1653367434,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/8429",
                    "makerAddr": "0xD66e64d1A52E93e67B1DBcD4383Ca61975Ca3Ec1",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2978",
                    "price": "250000000000000000000.0000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651373452,
                    "expirationTime": 1653965550,
                    "makerAddr": "0x5e410258a1e5e66a8dd9edc583593a90505b0978",
                    "assetImageUrl": "https://img.seadn.io/files/30d46195356b95992e905696ce816107.png?auto=format"
                }
            ],
            "total": 1e+21,
            "looksrare": 3,
            "opensea": 1
        },
        {
            "price": 300,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "300000000000000000000",
                    "tokenId": "4578",
                    "listingTime": 1650586637,
                    "expirationTime": 1653178611,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4578",
                    "makerAddr": "0x0424fB42a8Fc3F6460a0F012E77Ff4303Bdc3Dc9",
                    "assetImageUrl": ""
                }
            ],
            "total": 300000000000000000000,
            "looksrare": 1
        },
        {
            "price": 350,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3279",
                    "price": "350000000000000000000.0000000",
                    "paymentToken": "ETH",
                    "listingTime": 1651191082,
                    "expirationTime": 1653783143,
                    "makerAddr": "0x68b90cbbc46edbd72380415ab4f90ecfc5c0987a",
                    "assetImageUrl": "https://img.seadn.io/files/df3539b5991ea1d8cc1540d67542b680.png?auto=format"
                }
            ],
            "total": 350000000000000000000,
            "opensea": 1
        },
        {
            "price": 420,
            "amount": 3,
            "orders": [
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "212",
                    "price": "420000000000000000000.0000000",
                    "paymentToken": "ETH",
                    "listingTime": 1645834417,
                    "expirationTime": 1656307466,
                    "makerAddr": "0xea2ae0658f0bd1113ff558a17fd547875453f325",
                    "assetImageUrl": "https://img.seadn.io/files/38a0ef85d0b8904dcb9d9395beefe1cc.png?auto=format"
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "420690000000000000000",
                    "tokenId": "24035",
                    "listingTime": 1651698003,
                    "expirationTime": 1654290000,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/24035",
                    "makerAddr": "0x69bAb6810fa99475854bca0A3DD72aE6a0728ECE",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1",
                    "price": "420690000000000000000.0000000",
                    "paymentToken": "ETH",
                    "listingTime": 1648707005,
                    "expirationTime": 1664259049,
                    "makerAddr": "0x465092bbe4ca9675c1cf9c7bf2620b2eefc77e25",
                    "assetImageUrl": "https://img.seadn.io/files/3ab725edf59d2a65b6542983abf3485e.png?auto=format"
                }
            ],
            "total": 1.26138e+21,
            "opensea": 2,
            "looksrare": 1
        },
        {
            "price": 500,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "2416",
                    "price": "500000000000000000000.0000000",
                    "paymentToken": "ETH",
                    "listingTime": 1645235276,
                    "expirationTime": 1660873676,
                    "makerAddr": "0xdd72527cd9265013952ad33825b35ab66e93bf3b",
                    "assetImageUrl": "https://img.seadn.io/files/3265ace5acf182c7c40d8b996f08cb90.png?auto=format"
                }
            ],
            "total": 500000000000000000000,
            "opensea": 1
        },
        {
            "price": 666,
            "amount": 2,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "666000000000000000000",
                    "tokenId": "2657",
                    "listingTime": 1647619817,
                    "expirationTime": 1663171808,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/2657",
                    "makerAddr": "0x4299885E97668415CD67484d4a2c5214480Ff76d",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "1177",
                    "price": "666000000000000000000.0000000",
                    "paymentToken": "ETH",
                    "listingTime": 1647494878,
                    "expirationTime": 1663046956,
                    "makerAddr": "0xf331fdd7b261464f6dce3cb1adf40dacc90b8eb7",
                    "assetImageUrl": "https://img.seadn.io/files/56ca72275d5fa06ba4946ae213110df7.png?auto=format"
                }
            ],
            "total": 1.332e+21,
            "looksrare": 1,
            "opensea": 1
        },
        {
            "price": 694,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "3057",
                    "price": "694000000000000000000.0000000",
                    "paymentToken": "ETH",
                    "listingTime": 1647744154,
                    "expirationTime": 1663296105,
                    "makerAddr": "0xb1a1cb7449d067e972ecd464726138e1977a3c52",
                    "assetImageUrl": "https://img.seadn.io/files/be529364075966acbaf0c38eeab28740.png?auto=format"
                }
            ],
            "total": 694000000000000000000,
            "opensea": 1
        },
        {
            "price": 750,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "750000000000000000000",
                    "tokenId": "17499",
                    "listingTime": 1651695717,
                    "expirationTime": 1654287707,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/17499",
                    "makerAddr": "0x2d8d75D4BA64EFda74eeAD05Ac6127Df4e46Efa9",
                    "assetImageUrl": ""
                }
            ],
            "total": 750000000000000000000,
            "looksrare": 1
        },
        {
            "price": 777,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "777000000000000000000",
                    "tokenId": "5082",
                    "listingTime": 1646726655,
                    "expirationTime": 1662278624,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/5082",
                    "makerAddr": "0xE2dAD712978D8fCfb371aA6c2d1aB5B51Ef540ae",
                    "assetImageUrl": ""
                }
            ],
            "total": 777000000000000000000,
            "looksrare": 1
        },
        {
            "price": 888,
            "amount": 2,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "888000000000000000000",
                    "tokenId": "27189",
                    "listingTime": 1650499973,
                    "expirationTime": 1653091942,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/27189",
                    "makerAddr": "0x6C8Ee01F1f8B62E987b3D18F6F28b22a0Ada755f",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "888000000000000000000",
                    "tokenId": "3067",
                    "listingTime": 1650499892,
                    "expirationTime": 1653091824,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/3067",
                    "makerAddr": "0x6C8Ee01F1f8B62E987b3D18F6F28b22a0Ada755f",
                    "assetImageUrl": ""
                }
            ],
            "total": 1.776e+21,
            "looksrare": 2
        },
        {
            "price": 999,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "999000000000000000000",
                    "tokenId": "4222",
                    "listingTime": 1652180620,
                    "expirationTime": 1654772554,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4222",
                    "makerAddr": "0xA5836A1f1B6dbD88248F960cD83dcb2BDE1C92ae",
                    "assetImageUrl": ""
                }
            ],
            "total": 999000000000000000000,
            "looksrare": 1
        },
        {
            "price": 1000,
            "amount": 3,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "1000000000000000000000",
                    "tokenId": "6646",
                    "listingTime": 1651505310,
                    "expirationTime": 1667057208,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/6646",
                    "makerAddr": "0xb3f1ba3Cb0d8Af7ac4Ed76FDa92208c59C1C0192",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "1000000000000000000000",
                    "tokenId": "1077",
                    "listingTime": 1651547297,
                    "expirationTime": 1654139151,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/1077",
                    "makerAddr": "0x5b7B8796B9aC79c756416384b137510C63135F88",
                    "assetImageUrl": ""
                },
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "1000000000000000000000",
                    "tokenId": "4257",
                    "listingTime": 1643603945,
                    "expirationTime": 1659159344,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4257",
                    "makerAddr": "0x0C14E16d54e61975E85Ad449aD5750B89363b909",
                    "assetImageUrl": ""
                }
            ],
            "total": 3e+21,
            "looksrare": 3
        },
        {
            "price": 9999,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "opensea",
                    "collectionAddr": "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                    "tokenId": "147",
                    "price": "9999000000000000000000.000000",
                    "paymentToken": "ETH",
                    "listingTime": 1648918658,
                    "expirationTime": 1664470713,
                    "makerAddr": "0xbf30777120608688354908d36b4fd29aad13e084",
                    "assetImageUrl": "https://img.seadn.io/files/5e763e18f188fefef3a95045d972e5c0.png?auto=format"
                }
            ],
            "total": 9.999e+21,
            "opensea": 1
        },
        {
            "price": 24999,
            "amount": 1,
            "orders": [
                {
                    "marketplaceName": "looksrare",
                    "paymentToken": "ETH",
                    "collectionAddr": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
                    "price": "25000000000000000000000",
                    "tokenId": "4849",
                    "listingTime": 1650750797,
                    "expirationTime": 1666302752,
                    "marketplaceAssetLink": "https://looksrare.org/collections/0x60E4d786628Fea6478F785A6d7e704777c86a7c6/4849",
                    "makerAddr": "0x50dd57F50A17d57304e7A4F262Da30bEB31C2E87",
                    "assetImageUrl": ""
                }
            ],
            "total": 2.5e+22,
            "looksrare": 1
        }
    ]
};

let buySources = [];//["looksrare", "nftx"];//["uniswap", "other"];
let sellSources = [];//["looksrare", "nftx", "opensea"];//["uniswap", "other", "curve", "0x"];

function StackedDepthChart({ sellOrderBook = [], buyOrderBook = [] }: Props) {
    var canvas: any, context: any;
    useEffect(() => {
        drawChart();

        // cleanup this component
        return () => {
            d3.select("#stacked-depth-chart_canvas").remove();
        };

    }, []);

    function completeData(data) {
        var newData = [];

        if (data.asks.length > data.bids.length) {
            newData = JSON.parse(JSON.stringify(data));
            //var lastNodeBackup = JSON.parse(JSON.stringify(data.bids[data.bids.length - 1]));
            var numberOfNodesToAdd = data.asks.length - data.bids.length;

            for (var i = 0; i < numberOfNodesToAdd; i++) {
                var lastNodeBackup = JSON.parse(JSON.stringify(data.bids[data.bids.length - 1]));
                lastNodeBackup.orders = [];
                lastNodeBackup.price = +lastNodeBackup.price - 1-i;
                lastNodeBackup.amount = 0;
                for (var j = 0; j < buySources.length; j++) {
                    lastNodeBackup[buySources[j]] = 0;
                }
                newData.bids.push(lastNodeBackup);
            }
        }
        else {
            newData = JSON.parse(JSON.stringify(data));
            //var lastNodeBackup = JSON.parse(JSON.stringify(data.bids[data.bids.length - 1]));
            var numberOfNodesToAdd = data.bids.length - data.asks.length;

            for (var i = 0; i < numberOfNodesToAdd; i++) {
                var lastNodeBackup = JSON.parse(JSON.stringify(data.asks[data.asks.length - 1]));
                lastNodeBackup.orders = [];
                lastNodeBackup.price = +lastNodeBackup.price + 1+i; // as every new number price is different than earlier point
                lastNodeBackup.amount = 0;
                for (var j = 0; j < sellSources.length; j++) {
                    lastNodeBackup[sellSources[j]] = 0;
                }
                newData.asks.push(lastNodeBackup);
            }
        }

        // removed outliers
        var asksWithoutOutliers = [];
        for (var i = 0; i < newData.asks.length; i++) {
            if (newData.asks[i].price >= newData.bids[0].price) {
                asksWithoutOutliers.push(newData.asks[i]);
            }
        }

        // after removing of outliers, no. of dataPoints of bids and asks will be vary, so add nodes at the ends
        if (newData.asks.length > asksWithoutOutliers.length) {
            var numberOfNodesToAdd = newData.asks.length - asksWithoutOutliers.length;

            for (var i = 0; i < numberOfNodesToAdd; i++) {
                var lastNodeBackup = JSON.parse(JSON.stringify(newData.asks[newData.asks.length - 1]));
                lastNodeBackup.orders = [];
                lastNodeBackup.price = +lastNodeBackup.price + 1+i;
                lastNodeBackup.amount = 0;
                for (var j = 0; j < sellSources.length; j++) {
                    lastNodeBackup[sellSources[j]] = 0;
                }
                asksWithoutOutliers.push(lastNodeBackup);
            }
        }

        newData.asks = asksWithoutOutliers;

        // Adding missing attributes
        for (var i = 0; i < newData.bids.length; i++) {
            for (var j = 0; j < buySources.length; j++) {
                /*
                if (newData.bids[i][buySources[j]] === undefined) {
                  newData.bids[i][buySources[j]] = 0;
                }*/

                if (!newData.bids[i].hasOwnProperty(buySources[j])) {
                    newData.bids[i][buySources[j]] = 0;
                }
            }
        }

        // Adding missing attributes
        for (var i = 0; i < newData.asks.length; i++) {
            for (var j = 0; j < sellSources.length; j++) {
                /*
                if (newData.asks[i][sellSources[j]] === undefined) {
                  newData.asks[i][sellSources[j]] = 0;
                }*/
                if (!newData.asks[i].hasOwnProperty(sellSources[j])) {
                    newData.asks[i][sellSources[j]] = 0;
                }
            }
        }

        //console.log(" complete newData=> " + JSON.stringify(newData));

        return newData;
    }

    function arrayUnique(array: any) {
        var a = array.concat();
        for (var i = 0; i < a.length; ++i) {
            for (var j = i + 1; j < a.length; ++j) {
                if (a[i] === a[j])
                    a.splice(j--, 1);
            }
        }

        return a;
    }

    function populateSourcesArrays() {
        if (data.bids[0].orders != undefined) { // make sure that sub orders array exists in each datapoint
            var repeatedBuyingMarketPlaces = [];
            for (var i = 0; i < data.bids.length; i++) {
                for (var j = 0; j < data.bids[i].orders.length; j++) {
                    repeatedBuyingMarketPlaces.push(data.bids[i].orders[j].marketplaceName);
                }
            }
            buySources = arrayUnique(repeatedBuyingMarketPlaces);
            buySources.sort();
        }

        if (data.asks[0].orders != undefined) {

            var repeatedSellingMarketPlaces = [];
            for (var i = 0; i < data.asks.length; i++) {
                for (var j = 0; j < data.asks[i].orders.length; j++) {
                    repeatedSellingMarketPlaces.push(data.asks[i].orders[j].marketplaceName);
                }
            }
            sellSources = arrayUnique(repeatedSellingMarketPlaces);
            sellSources.sort();
        }
    }

    function drawChart() {
        populateSourcesArrays();
        //const __order_book = data;
        const __order_book = completeData(data);

        const highlightIntersectionArea: any = false;
        const _ = require("lodash");
        let initialWidth = 1000;
        let initialHeight = 500;


        // depth chart code
        // set the dimensions and margins of the graph, margin right to show indicator values
        let canvasWidth: number = initialWidth;
        let canvasHeight: number = initialHeight;

        var rightPadding = 55;
        var margin = { top: 5, right: 0, bottom: 20, left: 45 },
            width = canvasWidth - margin.left - margin.right - rightPadding,
            height = canvasHeight - margin.top - margin.bottom;

        var yAxisScale: any = null, xAxisScale: any = null,
            bidsSectionXAxisScale: any = null, asksSectionXAxisScale: any = null;
        var canvasChart: any = null;
        var area: any = null, line: any = null, areaForSellSection: any = null,
            lineForSellSection: any = null;

        var depthChartOptions = {
            w: width,
            h: height,
            margin: margin,
            maxValue: 0.5,
            levels: 7,
            roundStrokes: true,
            buyRegionColor: "green",
            sellRegionColor: "red",
            centeredLineColor: "#D3D3D3",
            axisTicksColor: "#4C4E52",
            valueBoxFillColor: "white",
            valueBoxDefaultWidth: 50,
            valueBoxDefaultHeight: 10
        }

        var getNumberOfDatapointsForDefaultZoom = __order_book.bids.length;

        DrawDepthChart("stacked-depth-chart", __order_book, depthChartOptions, initialWidth,
            initialHeight);

        function DrawDepthChart(id: any, data: any, options: any, userProvidedWidth: any, userProvidedHeight: any) {

            // used to clear and add canvas again
            function clearAndAddCanvas() {
                /*
                const chartElement = document.getElementById(id);
                if (chartElement) {
                  chartElement.innerHTML = "";
                }*/

                d3.select("#" + id).select("#" + id + "_canvas").remove();
                canvasChart = d3.select("#" + id).append('canvas')
                    .attr('width', canvasWidth)
                    .attr('height', canvasHeight)
                    .attr('id', id + "_canvas");

                /*                              

                .on("wheel", function (event: any) {
                  event.stopPropagation();
                });
                
                */

                canvasChart.call(zoom_function);
            }

            let inZoom = false;
            var canvas, context;
            var canvasCopy: any, canvasCopyContext: any;

            // Zoom/Drag handler
            const zoom_function = d3.zoom().scaleExtent([1, (__order_book.bids.length - 2)])
                .on('zoom', (event) => {

                    inZoom = true;

                    getNumberOfDatapointsForDefaultZoom = __order_book.bids.length - Math.ceil(event.transform.k);
                    if (event.transform.k < 1) {
                        getNumberOfDatapointsForDefaultZoom = __order_book.bids.length;
                    }
                    //console.log("getNumberOfDatapointsForDefaultZoom=" + getNumberOfDatapointsForDefaultZoom + " event.transform.k= " + event.transform.k + " [Math.ceil(event.transform.k)]=" + Math.ceil(event.transform.k));
                    context.save();
                    generateCanvasChartFromScratch();
                    context.restore();
                });

            function createXAxisScale() {
                /*
                xAxisScale = d3.scaleBand()
                  .range([0, width])
                  .padding(0);*/

                xAxisScale = d3.scalePoint()
                    .range([0, width]);

                var middleLine = width / 2;
                var paddingFromCenterLine = 4;

                bidsSectionXAxisScale = d3.scalePoint()
                    .range([0, middleLine - paddingFromCenterLine]);

                asksSectionXAxisScale = d3.scalePoint()
                    .range([middleLine + paddingFromCenterLine, width]);
            }

            function createColorScale() {

                var dataForColorScale = arrayUnique(buySources.concat(sellSources));
                colorScale = d3.scaleOrdinal().domain(dataForColorScale)
                    .range(["#EDAE35", "#EB4EFA", "#CED2D6", "#4FDDA5", "gold", "blue", "green", "yellow", "black", "grey", "darkgreen", "pink", "brown", "slateblue", "grey1", "orange"]);
            }

            function createYAxisScale() {
                yAxisScale = d3.scaleLinear()
                    .range([height, 0]);
            }

            function generateLineAreaObjects(context: any) {
                area = d3
                    .area()
                    .x(function (d: any, i: any) {
                        return bidsSectionXAxisScale(d.idx);
                    })
                    .y0(yAxisScale(0))
                    .y1(function (d: any) {
                        return yAxisScale(d.orders);
                    })
                    .curve(d3.curveStepBefore)
                    .context(context);

                line = d3
                    .line()
                    .x(function (d: any, i: any) {
                        return bidsSectionXAxisScale(d.idx);
                    })
                    .y(function (d: any) {
                        return yAxisScale(d.orders);
                    })
                    .curve(d3.curveStepBefore)
                    .context(context);

                areaForSellSection = d3
                    .area()
                    .x(function (d: any, i: any) {
                        return asksSectionXAxisScale(d.idx);
                    })
                    .y0(yAxisScale(0))
                    .y1(function (d: any) {
                        return yAxisScale(d.orders);
                    })
                    .curve(d3.curveStepAfter)
                    .context(context);

                lineForSellSection = d3
                    .line()
                    .x(function (d: any, i: any) {
                        return asksSectionXAxisScale(d.idx);
                    })
                    .y(function (d: any) {
                        return yAxisScale(d.orders);
                    })
                    .curve(d3.curveStepAfter)
                    .context(context);
            }

            var prefixSum = function (arr: any) {
                var builder = function (acc: any, n: any) {
                    var lastNum = acc.length > 0 ? acc[acc.length - 1] : 0;
                    acc.push(lastNum + n);
                    return acc;
                };
                return _.reduce(arr, builder, []);
            }

            var __cum_data = [];
            var buyDepthData: any = [];
            var sellDepthData: any = [];
            var stackedData: any = []; // contains data for stacked buy datapoints
            var stackedDataForSelling: any = []; // contains data for stacked selling datapoints
            var colorScale = null; // color scale

            function getDatapointsByZoomLevel(pointType: any) {
                // getNumberOfDatapointsForDefaultZoom
                var points = [];
                var i = 0
                if (pointType == "bids") {
                    for (i = 0; i < __order_book.bids.length; i++) {
                        if (i < getNumberOfDatapointsForDefaultZoom) {
                            points.push(__order_book.bids[i]);
                        }
                        else {
                            break;
                        }
                    }
                    return points;
                }
                else {
                    for (i = 0; i < __order_book.asks.length; i++) {
                        if (i < getNumberOfDatapointsForDefaultZoom) {
                            points.push(__order_book.asks[i]);
                        }
                        else {
                            break;
                        }
                    }
                    return points;
                }
            }

            function getBuyDepthData() {
                var data = [];

                // create cumulative data array
                __cum_data = [];
                var noOfBidsDatapointsByZoomLevel = getDatapointsByZoomLevel("bids");
                for (var i = 0; i < noOfBidsDatapointsByZoomLevel.length; i++) {
                    __cum_data.push(noOfBidsDatapointsByZoomLevel[i].amount);
                }

                var cum_data_array = prefixSum(__cum_data);

                for (var i = 0; i < noOfBidsDatapointsByZoomLevel.length; i++) {
                    data.push({
                        idx: noOfBidsDatapointsByZoomLevel[i].price,
                        orders: cum_data_array[i],
                    });
                }

                // buySources array
                for (var i = 0; i < buySources.length; i++) {
                    var __cum_data = [];
                    for (var j = 0; j < noOfBidsDatapointsByZoomLevel.length; j++) {
                        __cum_data.push(noOfBidsDatapointsByZoomLevel[j][buySources[i]]);
                    }
                    var cum_data_array_uniswap = prefixSum(__cum_data);
                    for (var j = 0; j < data.length; j++) {
                        data[j][buySources[i]] = cum_data_array_uniswap[j];
                    }
                }

                // reverse data for bids
                data = _.reverse(data);

                data.forEach(function (d) {
                    d.orders = +d.orders
                });

                return data;
            }

            function getSellDepthData() {
                var data = [];
                // create cumulative data array
                __cum_data = [];
                var noOfAsksDatapointsByZoomLevel = getDatapointsByZoomLevel("asks");
                for (var i = 0; i < noOfAsksDatapointsByZoomLevel.length; i++) {
                    __cum_data.push(noOfAsksDatapointsByZoomLevel[i].amount);
                }

                var cum_data_array = prefixSum(__cum_data);

                for (var i = 0; i < noOfAsksDatapointsByZoomLevel.length; i++) {
                    data.push({
                        idx: noOfAsksDatapointsByZoomLevel[i].price,
                        orders: cum_data_array[i],
                    });
                }

                // sellSources array
                for (var i = 0; i < sellSources.length; i++) {
                    var __cum_data = [];
                    for (var j = 0; j < noOfAsksDatapointsByZoomLevel.length; j++) {
                        __cum_data.push(noOfAsksDatapointsByZoomLevel[j][sellSources[i]]);
                    }
                    var cum_data_array_uniswap = prefixSum(__cum_data);
                    for (var j = 0; j < data.length; j++) {
                        data[j][sellSources[i]] = cum_data_array_uniswap[j];
                    }
                }

                data.forEach(function (d: any) {
                    d.orders = +d.orders
                });

                return data;
            }



            function generateCanvasChartFromScratch() {
                if (!inZoom) {
                    clearAndAddCanvas();

                    // get canvas exists from another div
                    // Todo: find way to get it
                    canvas = document.querySelector("#" + id + "_canvas");
                    context = canvas.getContext("2d", { alpha: true });
                }
                else {
                    //canvas = document.querySelector("canvas");
                    //context = canvas.getContext("2d");
                    context.clearRect(-margin.left, -margin.top, canvas.width, canvas.height);
                }


                createXAxisScale();

                buyDepthData = getBuyDepthData();
                sellDepthData = getSellDepthData();

                createColorScale();
                createYAxisScale();

                // Scale the range of the data in the domains
                /*
                xAxisScale.domain(buyDepthData.map(function (d) {
                    return d.idx
                }));*/

                var buyDepthArray = buyDepthData.map(function (d: any) {
                    return d.idx;
                }).concat(sellDepthData.map(function (d: any) {
                    return d.idx;
                })
                );

                xAxisScale.domain(buyDepthArray);//[buyDepthData[0].idx,sellDepthData[sellDepthData.length-1].idx]);

                bidsSectionXAxisScale.domain(buyDepthData
                    .map(function (d: any) {
                        return d.idx;
                    }));
                asksSectionXAxisScale.domain(sellDepthData
                    .map(function (d: any) {
                        return d.idx;
                    }));

                var scaleMax = d3.max([d3.max(buyDepthData, function (d: any) {
                    return d.orders;
                }),
                d3.max(sellDepthData, function (d: any) {
                    return d.orders;
                })
                ]);

                scaleMax = scaleMax + scaleMax * 0.4;
                yAxisScale.domain([0, scaleMax]);
                generateLineAreaObjects(context);

                context.translate(margin.left, margin.top);

                context.globalAlpha = 1;

                // add mouseover event
                canvas.addEventListener("mousemove", function (e: any) {

                    const chartElement = document.getElementById(id + "_canvas");

                    if (chartElement) {
                        var rect = chartElement.getBoundingClientRect(),
                            x = e.clientX - rect.left - margin.left,
                            y = e.clientY - rect.top,
                            i = 0, r;

                        if (x >= xAxisScale(buyDepthData[0].idx) && x <= xAxisScale(sellDepthData[sellDepthData.length - 1].idx)) {
                            //console.log("mousemove called=>");
                            /*
                              canvasCopy = d3
                                .select("#" + id)
                                .append("canvas")
                                .attr("width", canvasWidth)
                                .attr("height", canvasHeight)
                                ;//.attr("id", id + "_canvas");              
                            */

                            canvasCopy = document.querySelector("#" + id + "_canvas_copy");
                            //canvasCopy = document.createElement('canvas'); // Create a new canvas as cache canvas
                            canvasCopy.width = canvasWidth; canvasCopy.height = canvasHeight; // setting width and height

                            canvasCopyContext = canvasCopy.getContext('2d', { alpha: true });
                            canvasCopyContext.translate(margin.left, margin.top);

                            //context.clearRect(-margin.left, -margin.top, canvas.width, canvas.height);
                            // reset dashed lines
                            context.setLineDash([]);
                            redrawDepthChart(canvasCopyContext);
                            drawIntersectionLines(x, y, canvasCopyContext);
                            context.clearRect(-margin.left, -margin.top, canvas.width, canvas.height);
                            context.drawImage(canvasCopy, -margin.left, -margin.top); //Copy the cache canvas to the old canvas
                        }
                    }

                }, false);

                function getPriceOfMid() {
                    var centerPointValue = (+sellDepthData[0].idx + (+buyDepthData[buyDepthData.length - 1].idx)) / 2;
                    return centerPointValue;
                }

                function drawIntersectionLines(x: any, y: any, context: any) {
                    let maxWidthOfChart = xAxisScale(sellDepthData[sellDepthData.length - 1].idx);
                    var tickFormat = d3.format(",.2f");
                    context.font = 'bold 11px Arial';

                    var indexOfIntersectionDataPoint = -1;
                    var indexOfIntersectionDataPointBuy, indexOfIntersectionDataPointSell;
                    var bandWidth = bidsSectionXAxisScale.step();//(buyDepthData[1].idx)-xAxisScale(buyDepthData[0].idx);
                    var mouseInSection = "buy";
                    var middleLinePosition = width / 2;

                    // get vertical line region, in buy or sell section
                    if (
                        x >= +bidsSectionXAxisScale.domain()[0] &&
                        x <= middleLinePosition
                    ) {
                        // draw buy box
                        context.globalAlpha = 0.1;
                        context.fillStyle = options.buyRegionColor;

                        let buySelRegionWidth = x - bidsSectionXAxisScale(buyDepthData[0].idx);
                        let startX = 0;

                        if (highlightIntersectionArea == true || highlightIntersectionArea == "true") {
                            context.fillRect(0, 0, buySelRegionWidth, height);
                            context.stroke();
                        }

                        var dataPoint = null;

                        // find underlying data point on which currently user pointer is
                        for (var i = 0; i < buyDepthData.length; i++) {
                            if (x <= bidsSectionXAxisScale(buyDepthData[i].idx) && x > (bidsSectionXAxisScale(buyDepthData[i].idx) - bandWidth)) {
                                dataPoint = buyDepthData[i];
                                indexOfIntersectionDataPoint = i;
                                indexOfIntersectionDataPointBuy = i;
                                break;
                            }
                        }

                        if (dataPoint != null) {

                            // draw buy depth line
                            context.globalAlpha = 1;
                            context.beginPath();
                            context.strokeStyle = options.buyRegionColor;
                            context.moveTo(x, 0);
                            context.lineTo(x, height);
                            context.setLineDash([3, 2])
                            context.lineWidth = "1";
                            context.stroke();


                            var buySectionTopMostStackedSeriesOrders = stackedData[stackedData.length - 1][indexOfIntersectionDataPointBuy].values[1];
                            // add buy dotted horizontal line
                            context.globalAlpha = 1;
                            context.beginPath();
                            context.strokeStyle = options.buyRegionColor;
                            context.moveTo(0, yAxisScale(buySectionTopMostStackedSeriesOrders));
                            context.lineTo(bidsSectionXAxisScale(buyDepthData[buyDepthData.length - 1].idx), yAxisScale(buySectionTopMostStackedSeriesOrders));
                            context.setLineDash([3, 2]);
                            context.lineWidth = "1";
                            context.stroke();

                            // show current selected bar value in bottom axis
                            context.fillStyle = depthChartOptions.valueBoxFillColor;
                            context.fillRect(x - depthChartOptions.valueBoxDefaultWidth / 2, height + 5, depthChartOptions.valueBoxDefaultWidth, depthChartOptions.valueBoxDefaultHeight);
                            context.stroke();

                            context.textAlign = "center";
                            context.fillStyle = depthChartOptions.buyRegionColor;
                            context.textBaseline = "top";

                            context.fillText(tickFormat(dataPoint.idx), x, height + 6);

                            // show orders label in vetical axis
                            context.textAlign = "end";
                            context.fillStyle = depthChartOptions.buyRegionColor;
                            context.textBaseline = "middle";
                            context.fillText(tickFormat(buySectionTopMostStackedSeriesOrders), bidsSectionXAxisScale(buyDepthData[0].idx) - 5, yAxisScale(buySectionTopMostStackedSeriesOrders));

                            // show the %
                            // show current selected bar value in bottom axis
                            var boxWidth = 30;/*
                            context.fillStyle = depthChartOptions.valueBoxFillColor;
                            context.fillRect(xAxisScale(buyDepthData[buyDepthData.length - 1].idx) - 30 - boxWidth, yAxisScale(dataPoint.orders) - 6, boxWidth, depthChartOptions.valueBoxDefaultHeight);
                            context.stroke();*/

                            // find buy percentage
                            var centerPointValue = getPriceOfMid();
                            var percentageValue = ((centerPointValue - (+dataPoint.idx)) * 100) / centerPointValue;
                            context.textAlign = "center";
                            context.fillStyle = depthChartOptions.buyRegionColor;
                            context.textBaseline = "bottom";
                            context.font = 'bold 11px Arial';
                            context.fillText("+" + percentageValue.toFixed(2) + "%", bidsSectionXAxisScale(buyDepthData[buyDepthData.length - 1].idx) - 30 - boxWidth / 2, yAxisScale(buySectionTopMostStackedSeriesOrders));
                        }

                        context.fillStyle = options.sellRegionColor;
                        context.globalAlpha = 0.1;

                        startX = maxWidthOfChart - buySelRegionWidth;
                        if (highlightIntersectionArea == true || highlightIntersectionArea == "true") {
                            context.fillRect(startX, 0, buySelRegionWidth, height);
                            context.stroke();
                        }

                        dataPoint = sellDepthData[sellDepthData.length - 1 - indexOfIntersectionDataPoint]; // -2 
                        indexOfIntersectionDataPointSell = sellDepthData.length - 1 - indexOfIntersectionDataPoint; // -2 as we needs to take datapoint from its left side

                        //console.log("indexOfIntersectionDataPoint=>" + indexOfIntersectionDataPoint);
                        //console.log("indexOfIntersectionDataPointSell=>" + indexOfIntersectionDataPointSell);

                        // add sell dotted horizontal line
                        if (dataPoint != null) {

                            // sell vertical line
                            context.globalAlpha = 1;
                            context.beginPath();
                            context.strokeStyle = options.sellRegionColor;
                            context.moveTo(width - x, 0);
                            context.lineTo(width - x, height);
                            context.setLineDash([3, 2]);
                            context.lineWidth = "1";
                            context.stroke();

                            // set horizontal line
                            var sellSectionTopMostStackedSeriesOrders = stackedDataForSelling[stackedDataForSelling.length - 1][indexOfIntersectionDataPointSell].values[1];

                            context.globalAlpha = 1;
                            context.beginPath();
                            context.strokeStyle = options.sellRegionColor;
                            context.setLineDash([3, 2]);
                            context.moveTo(asksSectionXAxisScale(sellDepthData[0].idx), yAxisScale(sellSectionTopMostStackedSeriesOrders));
                            context.lineTo(asksSectionXAxisScale(sellDepthData[sellDepthData.length - 1].idx), yAxisScale(sellSectionTopMostStackedSeriesOrders));

                            context.lineWidth = "1";
                            context.stroke();

                            // show current selected bar value in bottom axis
                            context.fillStyle = depthChartOptions.valueBoxFillColor;
                            context.fillRect((width - x) - depthChartOptions.valueBoxDefaultWidth / 2, height + 5, depthChartOptions.valueBoxDefaultWidth, depthChartOptions.valueBoxDefaultHeight);
                            context.stroke();

                            context.textAlign = "center";
                            context.fillStyle = depthChartOptions.sellRegionColor;
                            context.textBaseline = "top";
                            context.fillText(tickFormat(dataPoint.idx), (width - x), height + 6);

                            // show orders label in vetical axis
                            context.textAlign = "start";
                            context.fillStyle = depthChartOptions.sellRegionColor;
                            context.textBaseline = "middle";
                            context.fillText(tickFormat(dataPoint.orders), asksSectionXAxisScale(sellDepthData[sellDepthData.length - 1].idx) + 5, yAxisScale(sellSectionTopMostStackedSeriesOrders));

                            // show the %
                            // show current selected bar value in bottom axis

                            var boxWidth = 30;/*
                            context.fillStyle = depthChartOptions.valueBoxFillColor;
                            context.fillRect(xAxisScale(sellDepthData[0].idx) + 30, yAxisScale(dataPoint.orders) - 6, boxWidth, depthChartOptions.valueBoxDefaultHeight);
                            context.stroke();*/

                            var centerPointValue = getPriceOfMid();
                            var percentageValue = (((+dataPoint.idx) - centerPointValue) * 100) / centerPointValue;
                            context.textAlign = "center";
                            context.font = 'bold 11px Arial';
                            context.fillStyle = depthChartOptions.sellRegionColor;
                            context.textBaseline = "bottom";
                            context.fillText("+" + percentageValue.toFixed(2) + "%", asksSectionXAxisScale(sellDepthData[0].idx) + 30 + boxWidth / 2, yAxisScale(sellSectionTopMostStackedSeriesOrders));
                        }
                        mouseInSection = "buy";
                    }
                    else {
                        mouseInSection = "sell";
                        context.globalAlpha = 1;
                        // sell box
                        context.fillStyle = options.sellRegionColor;
                        context.globalAlpha = 0.1;
                        let buySelRegionWidth = maxWidthOfChart - x;
                        let startX = x;
                        if (highlightIntersectionArea == true || highlightIntersectionArea == "true") {
                            context.fillRect(startX, 0, buySelRegionWidth, height);
                            context.stroke();
                        }

                        var dataPoint = null;
                        for (var i = 0; i < sellDepthData.length; i++) {
                            if (x >= asksSectionXAxisScale(sellDepthData[i].idx) && x < (asksSectionXAxisScale(sellDepthData[i].idx) + bandWidth)) {
                                dataPoint = sellDepthData[i];
                                indexOfIntersectionDataPoint = i;
                                indexOfIntersectionDataPointSell = i;
                                break;
                            }

                        }

                        // add sell dotted horizontal line
                        if (dataPoint != null && dataPoint != undefined) {
                            context.globalAlpha = 1;
                            // draw sell depth line
                            context.beginPath();
                            context.strokeStyle = options.sellRegionColor;
                            context.moveTo(x, 0);
                            context.lineTo(x, height);
                            context.setLineDash([3, 2]);
                            context.lineWidth = "1";
                            context.stroke();

                            // add sell horizontal line
                            var sellSectionTopMostStackedSeriesOrders = stackedDataForSelling[stackedDataForSelling.length - 1][indexOfIntersectionDataPointSell].values[1];

                            context.globalAlpha = 1;
                            context.beginPath();
                            context.strokeStyle = options.sellRegionColor;
                            context.moveTo(asksSectionXAxisScale(sellDepthData[0].idx), yAxisScale(sellSectionTopMostStackedSeriesOrders));
                            context.lineTo(asksSectionXAxisScale(sellDepthData[sellDepthData.length - 1].idx), yAxisScale(sellSectionTopMostStackedSeriesOrders));
                            context.setLineDash([3, 2]);
                            context.lineWidth = "1";
                            context.stroke();

                            // show value in bottom axis
                            // show current selected bar value in bottom axis
                            context.fillStyle = depthChartOptions.valueBoxFillColor;
                            context.fillRect(x - depthChartOptions.valueBoxDefaultWidth / 2, height + 5, depthChartOptions.valueBoxDefaultWidth, depthChartOptions.valueBoxDefaultHeight);
                            context.stroke();

                            context.textAlign = "center";
                            context.fillStyle = depthChartOptions.sellRegionColor;
                            context.textBaseline = "top";
                            context.fillText(tickFormat(dataPoint.idx), x, height + 6);

                            // show orders label in vetical axis
                            context.textAlign = "start";
                            context.fillStyle = depthChartOptions.sellRegionColor;
                            context.textBaseline = "middle";
                            context.fillText(tickFormat(sellSectionTopMostStackedSeriesOrders), asksSectionXAxisScale(sellDepthData[sellDepthData.length - 1].idx) + 5, yAxisScale(sellSectionTopMostStackedSeriesOrders));

                            // show the %
                            // show current selected bar value in bottom axis

                            var boxWidth = 30; /*
                            context.fillStyle = depthChartOptions.valueBoxFillColor;
                            context.fillRect(xAxisScale(sellDepthData[0].idx) + 30, yAxisScale(dataPoint.orders) - 6, boxWidth, depthChartOptions.valueBoxDefaultHeight);
                            context.stroke();*/

                            var centerPointValue = getPriceOfMid();
                            var percentageValue = (((+dataPoint.idx) - centerPointValue) * 100) / centerPointValue;
                            context.textAlign = "center";
                            context.font = 'bold 11px Arial';
                            context.fillStyle = depthChartOptions.sellRegionColor;
                            context.textBaseline = "bottom";
                            context.fillText("+" + percentageValue.toFixed(2) + "%", asksSectionXAxisScale(sellDepthData[0].idx) + 30 + boxWidth / 2, yAxisScale(sellSectionTopMostStackedSeriesOrders));
                        }

                        // draw buy box
                        context.globalAlpha = 0.1;
                        context.fillStyle = options.buyRegionColor;

                        if (highlightIntersectionArea == true || highlightIntersectionArea == "true") {
                            context.fillRect(0, 0, buySelRegionWidth, height);
                            context.stroke();
                        }
                        dataPoint = buyDepthData[buyDepthData.length - 1 - indexOfIntersectionDataPoint];
                        indexOfIntersectionDataPointBuy = buyDepthData.length - 1 - indexOfIntersectionDataPoint;
                        //console.log("indexOfIntersectionDataPointBuy=>"+indexOfIntersectionDataPointBuy);
                        // add buy dotted horizontal line
                        if (dataPoint != null && dataPoint != undefined) {

                            // draw buy depth line
                            context.globalAlpha = 1;
                            context.beginPath();
                            context.strokeStyle = options.buyRegionColor;
                            context.moveTo(width - x, 0);
                            context.lineTo(width - x, height);
                            context.setLineDash([3, 2])
                            context.lineWidth = "1";
                            context.stroke();

                            // draw horizontal line
                            var buySectionTopMostStackedSeriesOrders = stackedData[stackedData.length - 1][indexOfIntersectionDataPointBuy].values[1];

                            context.globalAlpha = 1;
                            context.beginPath();
                            context.strokeStyle = options.buyRegionColor;
                            context.moveTo(bidsSectionXAxisScale(buyDepthData[0].idx), yAxisScale(buySectionTopMostStackedSeriesOrders));
                            context.lineTo(bidsSectionXAxisScale(buyDepthData[buyDepthData.length - 1].idx), yAxisScale(buySectionTopMostStackedSeriesOrders));
                            context.setLineDash([3, 2]);
                            context.lineWidth = "1";
                            context.stroke();

                            // show current selected bar value in bottom axis
                            context.fillStyle = depthChartOptions.valueBoxFillColor;
                            context.fillRect(width - x - depthChartOptions.valueBoxDefaultWidth / 2, height + 5, depthChartOptions.valueBoxDefaultWidth, depthChartOptions.valueBoxDefaultHeight);
                            context.stroke();

                            context.textAlign = "center";
                            context.fillStyle = depthChartOptions.buyRegionColor;
                            context.textBaseline = "top";
                            context.fillText(tickFormat(dataPoint.idx), (width - x), height + 6);

                            // show orders label in vetical axis
                            context.textAlign = "end";
                            context.fillStyle = depthChartOptions.buyRegionColor;
                            context.textBaseline = "middle";
                            context.fillText(tickFormat(buySectionTopMostStackedSeriesOrders), bidsSectionXAxisScale(buyDepthData[0].idx) - 5, yAxisScale(buySectionTopMostStackedSeriesOrders));

                            // show the %
                            // show current selected bar value in bottom axis
                            var boxWidth = 30;

                            var centerPointValue = getPriceOfMid();
                            var percentageValue = ((centerPointValue - (+dataPoint.idx)) * 100) / centerPointValue;
                            context.textAlign = "center";
                            context.fillStyle = depthChartOptions.buyRegionColor;
                            context.textBaseline = "bottom";
                            context.font = 'bold 11px Arial';
                            context.fillText("+" + percentageValue.toFixed(2) + "%", bidsSectionXAxisScale(buyDepthData[buyDepthData.length - 1].idx) - 30 - boxWidth / 2, yAxisScale(buySectionTopMostStackedSeriesOrders));
                        }
                    }
                    context.globalAlpha = 1;
                    // construct data for legend

                    if (indexOfIntersectionDataPointBuy != buyDepthData.length && indexOfIntersectionDataPointBuy != -1
                        && indexOfIntersectionDataPointSell != sellDepthData.length && indexOfIntersectionDataPointSell != -1) {
                        generateLegend(context, indexOfIntersectionDataPointBuy, indexOfIntersectionDataPointSell);
                        generateLinePointsOnIntersectedLine(x, y, context, indexOfIntersectionDataPointBuy, indexOfIntersectionDataPointSell, mouseInSection);
                    }
                }

                function generateLinePointsOnIntersectedLine(x: any, y: any, context: any, indexOfIntersectionDataPointBuy: any, indexOfIntersectionDataPointSell: any, mouseInSection: any) {
                    if (indexOfIntersectionDataPointBuy >= 0 && indexOfIntersectionDataPointBuy < stackedData[0].length) {
                        for (var i = 0; i < stackedData.length; i++) {
                            context.beginPath();
                            context.fillStyle = options.buyRegionColor;

                            if (mouseInSection == "sell") {
                                context.arc(width - x, yAxisScale(stackedData[i][indexOfIntersectionDataPointBuy].values[1]), 3, 0, 2 * Math.PI);
                            }
                            else {
                                context.arc(x, yAxisScale(stackedData[i][indexOfIntersectionDataPointBuy].values[1]), 3, 0, 2 * Math.PI);
                            }

                            //context.lineWidth = "4";
                            context.strokeStyle = "white";
                            context.stroke();
                            context.fill();
                        }
                    }

                    if (indexOfIntersectionDataPointSell >= 0 && indexOfIntersectionDataPointSell < stackedDataForSelling[0].length) {
                        for (var i = 0; i < stackedDataForSelling.length; i++) {
                            context.beginPath();
                            context.fillStyle = options.sellRegionColor;

                            if (mouseInSection == "sell") {
                                context.arc(x, yAxisScale(stackedDataForSelling[i][indexOfIntersectionDataPointSell].values[1]), 3, 0, 2 * Math.PI);
                            }
                            else {
                                context.arc(width - x, yAxisScale(stackedDataForSelling[i][indexOfIntersectionDataPointSell].values[1]), 3, 0, 2 * Math.PI);
                            }
                            //context.lineWidth = "4";
                            context.strokeStyle = "white";
                            context.stroke();
                            context.fill();
                        }
                    }
                }

                function redrawDepthChart(context: any) {
                    context.restore();
                    context.fillStyle = options.axisTicksColor;
                    context.globalAlpha = 1;
                    context.setLineDash([]);
                    generateXAxis(context);

                    context.globalAlpha = 1;
                    context.setLineDash([]);



                    // stacked data construction for buy 
                    const stack = d3.stack().keys(buySources);//["uniswap", "other", "curve", "0x"]);

                    // stacked areas construction for buyDepthData
                    const stackedValues = stack(buyDepthData);
                    stackedData = [];
                    // Copy the stack offsets back into the data.
                    stackedValues.forEach((layer: any, index: any) => {
                        const currentStack = [];
                        layer.forEach((d, i) => {
                            currentStack.push({
                                values: d,
                                year: d.data.idx
                            });
                        });
                        stackedData.push(currentStack);
                    });

                    // stacked areas construction for sellDepthData
                    const stackSell = d3.stack().keys(sellSources);
                    const stackedValuesForSelling = stackSell(sellDepthData);
                    stackedDataForSelling = [];
                    // Copy the stack offsets back into the data.
                    stackedValuesForSelling.forEach((layer: any, index: any) => {
                        const currentStack = [];
                        layer.forEach((d, i) => {
                            currentStack.push({
                                values: d,
                                year: d.data.idx
                            });
                        });
                        stackedDataForSelling.push(currentStack);
                    });

                    // find max values for y scale
                    var yScaleMaxValue = d3.max([d3.max(stackedValues[stackedValues.length - 1], dp => dp[1]),
                    d3.max(stackedValuesForSelling[stackedValuesForSelling.length - 1], dp => dp[1])]);

                    // add padding in yScale
                    yScaleMaxValue = yScaleMaxValue + yScaleMaxValue * 0.3;

                    // Create scales                   
                    yAxisScale.domain([0, yScaleMaxValue]);

                    generateYAxis(context);
                    context.globalAlpha = 1;
                    context.setLineDash([]);

                    // draw area objects
                    const area = d3
                        .area()
                        .x(dataPoint => {
                            return bidsSectionXAxisScale(dataPoint.year);
                        })
                        .y0(dataPoint => {
                            return yAxisScale(dataPoint.values[0]);
                        })
                        .y1(dataPoint => {
                            return yAxisScale(dataPoint.values[1]);
                        })
                        //.curve(d3.curveLinear)
                        .curve(d3.curveStepBefore)
                        .context(context);

                    // draw selling area objects
                    const areaForAsksSection = d3
                        .area()
                        .x(dataPoint => {
                            return asksSectionXAxisScale(dataPoint.year);
                        })
                        .y0(dataPoint => {
                            return yAxisScale(dataPoint.values[0]);
                        })
                        .y1(dataPoint => {
                            return yAxisScale(dataPoint.values[1]);
                        })
                        //.curve(d3.curveLinear)
                        .curve(d3.curveStepAfter)
                        .context(context);

                    // generate stacked area for buy section
                    for (var i = 0; i < stackedData.length; i++) {
                        context.beginPath();
                        area(stackedData[i]);
                        context.lineWidth = "2";
                        context.strokeStyle = "white";
                        context.stroke();
                        context.globalAlpha = 1;
                        context.fillStyle = colorScale(buySources[i]);
                        context.fill();
                    }

                    // Draw stacked lines for asks/selling section
                    for (var i = 0; i < stackedDataForSelling.length; i++) {
                        context.beginPath();
                        areaForAsksSection(stackedDataForSelling[i]);
                        context.lineWidth = "2";
                        context.strokeStyle = "white";
                        context.stroke();
                        context.globalAlpha = 1;
                        context.fillStyle = colorScale(sellSources[i]);//color[i];
                        context.fill();
                    }

                    // start timer to slide chart
                    //setInterval(slideChartToLeft, slideDuration);
                    context.globalAlpha = 1;

                    var middleLine = width / 2;
                    var paddingFromCenterLine = 4;
                    var middleLinePoint = middleLine;

                    // generate centered vertical line
                    context.beginPath();
                    context.strokeStyle = options.centeredLineColor;
                    context.moveTo(middleLinePoint, 0);
                    context.lineTo(middleLinePoint, height);
                    context.lineWidth = "1";
                    context.stroke();
                }

                redrawDepthChart(context);
                // first time show legend of first and last datapoint
                generateLegend(context, 0, sellDepthData.length - 1);
            }

            function generateXAxis(context: any) {
                var tickCount = 5;
                var tickFormat = d3.format(",.2f"); 

                context.textAlign = "center";
                context.textBaseline = "top";
                context.strokeStyle = depthChartOptions.axisTicksColor;
                context.font = 'bold 11px Arial';

                var recentTickXLocation = 0;

                bidsSectionXAxisScale.domain().forEach(function (d: any, i: any) {
                    if (+d > 0 && i != buyDepthData.length - 1) {
                        if (i == 0) {
                            recentTickXLocation = bidsSectionXAxisScale(d);
                            context.fillText(tickFormat(+d), bidsSectionXAxisScale(d), height + 6);
                        }
                        else {
                            var currentTickX = bidsSectionXAxisScale(d);
                            if (currentTickX - 70 > recentTickXLocation) {
                                recentTickXLocation = currentTickX;
                                context.fillText(tickFormat(+d), bidsSectionXAxisScale(d), height + 6);
                            }
                        }
                    }
                });

                // set ticks labels
                asksSectionXAxisScale.domain().forEach(function (d: any, i: any) {
                    if (+d > 0 && i > 0) { // Do not show ticks which has -ve values or generated from data construction algorithm
                        if (i == 0) {
                            recentTickXLocation = asksSectionXAxisScale(d);
                            context.fillText(
                                tickFormat(+d),
                                asksSectionXAxisScale(d),
                                height + 6
                            );
                        } else {
                            var currentTickX = asksSectionXAxisScale(d);
                            if (currentTickX - 70 > recentTickXLocation) {
                                recentTickXLocation = currentTickX;
                                context.fillText(
                                    tickFormat(+d),
                                    asksSectionXAxisScale(d),
                                    height + 6
                                );
                            }
                        }
                    }
                });
            }

            function getBuyingLegendData(indexOfIntersectionDataPoint: any) {
                var buySectionLegenedData = [
                ];

                for (var i = 0; i < buySources.length; i++) {
                    buySectionLegenedData.push({ "lable": buySources[i], "value": 0 });
                }
                // stacked Data for buying
                var totalOrders = stackedData[stackedData.length - 1][indexOfIntersectionDataPoint].values[1];

                for (var i = 0; i < stackedData.length; i++) {
                    var seriesSelectedPriceOrders = stackedData[i][indexOfIntersectionDataPoint].values[1] - stackedData[i][indexOfIntersectionDataPoint].values[0];
                    if (totalOrders != 0) {
                        buySectionLegenedData[i].value = (seriesSelectedPriceOrders * 100 / totalOrders).toFixed(0);
                    }
                }

                return buySectionLegenedData;
            }

            function getSellingLegendData(indexOfIntersectionDataPoint: any) {
                var sellSectionLegenedData = [];

                for (var i = 0; i < sellSources.length; i++) {
                    sellSectionLegenedData.push({ "lable": sellSources[i], "value": 0 });
                }

                // stacked Data for buying
                var totalOrders = stackedDataForSelling[stackedDataForSelling.length - 1][indexOfIntersectionDataPoint].values[1];
                for (var i = 0; i < stackedDataForSelling.length; i++) {
                    var seriesSelectedPriceOrders = stackedDataForSelling[i][indexOfIntersectionDataPoint].values[1] - stackedDataForSelling[i][indexOfIntersectionDataPoint].values[0];
                    if (totalOrders != 0) {
                        sellSectionLegenedData[i].value = (seriesSelectedPriceOrders * 100 / totalOrders).toFixed(0);
                    }
                }

                return sellSectionLegenedData;
            }

            function generateLegend(context: any, buySectionHoveredDataPointIndex: any, askSectionHoveredDataPointIndex: any) {

                const color = ["#EDAE35", "#EB4EFA", "#CED2D6", "#4FDDA5"];

                var buySectionLegenedData = [];

                if (buySectionHoveredDataPointIndex == undefined) return;

                buySectionLegenedData = getBuyingLegendData(buySectionHoveredDataPointIndex);

                var askSectionLegenedData = [];

                if (askSectionHoveredDataPointIndex == undefined) return;
                askSectionLegenedData = getSellingLegendData(askSectionHoveredDataPointIndex);

                context.textAlign = "start";
                context.textBaseline = "middle";
                context.font = 'bold 12px Arial';

                context.fillStyle = depthChartOptions.axisTicksColor;
                var y = 30;

                buySectionLegenedData.forEach(function (d: any, i: any) {
                    context.fillStyle = colorScale(d.lable);
                    context.fillText(d.value + "%", 45, y);
                    y += 20;
                });
                y = 30;
                context.fillStyle = "black";
                buySectionLegenedData.forEach(function (d: any, i: any) {
                    context.fillText(d.lable, 80, y);
                    y += 20;
                });

                context.textAlign = "start";
                context.textBaseline = "middle";
                context.fillStyle = depthChartOptions.axisTicksColor;

                y = 30;
                askSectionLegenedData.forEach(function (d: any, i: any) {
                    context.fillStyle = colorScale(d.lable);
                    context.fillText(d.value + "%", width - 155, y);
                    y += 20;
                });
                y = 30;
                context.fillStyle = "black";
                askSectionLegenedData.forEach(function (d: any, i: any) {
                    context.fillText(d.lable, width - 120, y);
                    y += 20;
                });

                context.font = 'normal 10px Arial';
            }

            function generateYAxis(context: any) {
                var tickCount = 10,
                    tickSize = 6,
                    tickPadding = 3,
                    ticks = yAxisScale.ticks(tickCount),
                    tickFormat = yAxisScale.tickFormat(tickCount);

                context.textAlign = "start";
                context.textBaseline = "middle";
                context.fillStyle = depthChartOptions.axisTicksColor;
                context.font = 'bold 11px Arial';
                ticks.forEach(function (d: any) {
                    context.fillText(tickFormat(d), width + 5, yAxisScale(d));
                });

                context.stroke();
                /*
                // hide ticks
                context.beginPath();
                context.strokeStyle = depthChartOptions.axisTicksColor;
                context.lineWidth = "1";
                ticks.forEach(function (d) {
                    context.moveTo(width, yAxisScale(d));
                    context.lineTo(width - 6, yAxisScale(d));
                    context.stroke();
                });
                */

                // horizontal grid lines as rows
                context.beginPath();
                ticks.forEach(function (d: any) {
                    //context.setLineDash([3, 2])
                    context.globalAlpha = 0.1;
                    context.moveTo(0, yAxisScale(d))
                    context.lineTo(width, yAxisScale(d));
                });

                context.lineWidth = "1";
                context.strokeStyle = "grey";
                context.stroke();

                //context.save();
                //context.restore();
            }

            generateCanvasChartFromScratch();
        }//DepthChart
    }

    return (
        <div>
            <canvas id="stacked-depth-chart_canvas_copy" style={{ display: "none" }}></canvas>
        </div>
    );
}

export default StackedDepthChart;

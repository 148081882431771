import React, { useState, useEffect } from "react";

function Header() {
  return (
    <header className="flex content-center justify-between w-full h-16 px-32 py-3 border border-b-400">
      <div className="flex items-center content-center w-full">Logo</div>
      <div className="flex items-center content-center justify-end w-full">
        <div className="mx-4 text-center">Markets</div>
      </div>
    </header>
  );
}

export default Header;

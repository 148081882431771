import React, { useState, useEffect } from "react";

function CollectionDetails() {
  return (
    <div className="w-full h-20 mt-5 border border-b-400">
      Collection details
    </div>
  );
}

export default CollectionDetails;

import React, { useState, useEffect } from "react";

function Description() {
  return (
    <div className="flex flex-row w-full mt-5">
      <div className="w-40 h-40 border border-b-400">image</div>
      <div className="w-full h-40 border border-b-400">Content</div>
    </div>
  );
}

export default Description;
